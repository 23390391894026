import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { InstDataStudio } from 'types/models';

import { SectionTitle, LoadingButton, DataTable, TableHeading, Spinner } from 'components';
import { installationServiceInstance, WattsConverterService } from 'services';
import customToast from 'components/CustomToast/CustomToast';

import { FormGroup, FormGroupProps, I18n } from 'core';

import Button from 'components/Button/Button';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { useDispatch } from 'react-redux';
import { fetchInstallation, fetchInstallationSuccess } from 'redux/actions/InstallationsActions';
import ProductionGuarantee from './ProductionGuarantee/ProductionGuarantee';
import TotalsDataTable from './TotalsDataTable/TotalDataTable';

import styles from './InstallationStudyPage.module.scss';

export interface EstimationDataI {
  id: string;
  fecha: string;
  estimacion: number;
  garantia: number;
}

const InstallationStudyPage = (props: any): JSX.Element => {
  // PROPS
  const {
    match: {
      params: { installationID },
    },
  } = props;

  // Hooks
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [dataStudio, setDataStudio] = useState<InstDataStudio>();
  const [estimationRows, setEstimationRows] = useState<Array<EstimationDataI>>();
  const [currentYear, setCurrentYear] = useState<number>(1);
  const [firstEstimationYear, setFirstEstimationYear] = useState<number>();
  const [totalEstimate, setTotalEstimate] = useState<number>(0);
  const [totalGuarantee, setTotalGuarantee] = useState<number>(0);
  const ESTIMATES_PER_PAGE = 12;

  const calculoDeGarantiaFormGroup: FormGroupProps = {
    label: I18n.t('Production Guarantee calculation coefficient'),
    type: 'number',
    id: 'coeficienteCalculoDeGarantia',
    path: 'coeficienteCalculoDeGarantia',
    name: 'coeficienteCalculoDeGarantia',
    placeholder: I18n.t('Guarantee coefficient'),
    required: true,
    value: dataStudio ? dataStudio.coeficienteCalculoDeGarantia : 0,
    min: 0,
    max: 100,
    step: 1,
    className: dataStudio?.coeficienteCalculoDeGarantia ? '' : 'hidden',
  };

  function onCoeficienteDeGarantiaChange<T>(path: string, value: T): void {
    if (dataStudio === undefined) {
      return;
    }
    let coeficiente: number | null = Math.round(Number(value) * 100) / 100 || null;

    if (coeficiente === 0) {
      coeficiente = null;
    }

    setDataStudio({
      ...dataStudio,
      coeficienteCalculoDeGarantia: coeficiente,
    });
  }

  const downPage = (): void => {
    if (currentYear > 1) {
      setCurrentYear(currentYear - 1);
    }
  };

  const upPage = (): void => {
    setCurrentYear(currentYear + 1);
  };

  function handleEstimationChange<T>(path: string, value: T, id: string): void {
    if (!estimationRows || !dataStudio) {
      return;
    }

    const { estimaciones } = dataStudio;
    const estimationIndex = estimaciones.findIndex((e) => e.fecha === id);
    const newEstimaciones = [...estimaciones];
    const estimacion = WattsConverterService.convertKwattsToWatts(Number(value));

    if (estimationIndex >= 0) {
      newEstimaciones[estimationIndex].estimacion = estimacion;
      dataStudio.estimaciones = newEstimaciones;
    } else {
      newEstimaciones.push({
        fecha: id,
        estimacion,
      });
    }

    setDataStudio({
      ...dataStudio,
      estimaciones: newEstimaciones,
    });
  }

  const onSubmit = async (): Promise<boolean> => {
    if (!installationID || !dataStudio) {
      return false;
    }

    setLoading(true);
    setSubmitDisabled(true);

    try {
      await installationServiceInstance.updateInstallationDataStudy(
        installationID,
        dataStudio.coeficienteCalculoDeGarantia,
        dataStudio.estimaciones,
      );
      customToast.success(I18n.t('Data studio saved!'));
    } catch (error) {
      toast.error(I18n.t('Error Updating Data studio.'));
    }

    setLoading(false);
    setSubmitDisabled(false);

    return true;
  };

  const headings: Array<TableHeading> = [
    { label: I18n.t('Month'), key: 'fecha' },
    {
      label: I18n.t('Project Estimation (kWh/month)'),
      type: 'input',
      inputType: 'number',
      key: 'estimacion',
      handleInput: handleEstimationChange,
    },
    { label: I18n.t('Production guarantee (kWh/month)'), key: 'garantia' },
  ];

  useEffect(() => {
    const getStudyData = async (): Promise<void> => {
      if (!installationID) {
        return;
      }

      const studyData = await installationServiceInstance.getInstallationDataStudy(installationID);

      const instData = await installationServiceInstance.getInstallation(installationID);

      dispatch(fetchInstallationSuccess(instData));

      const { estimaciones } = studyData;
      let totalPages = 1;
      let yearOfFirstEstimation = new Date(instData.fechaInicioSeguimientoProduccion).getFullYear();

      if (estimaciones && estimaciones.length) {
        yearOfFirstEstimation = studyData.getEstimationYear(estimaciones[0]);
        const yearOflastEstimation = studyData.getEstimationYear(estimaciones[estimaciones.length - 1]);
        const yearsDiff = yearOflastEstimation - yearOfFirstEstimation;

        totalPages = yearsDiff + 1;

        setCurrentYear(totalPages);
        setFirstEstimationYear(yearOfFirstEstimation);
      } else {
        setCurrentYear(totalPages);
        setFirstEstimationYear(yearOfFirstEstimation);
      }

      setDataStudio(studyData);
    };
    dispatch(fetchInstallation());
    getStudyData();
  }, [installationID]);

  useEffect(() => {
    const createEstimationsTable = (): void => {
      if (!firstEstimationYear || !dataStudio) {
        return;
      }

      const { estimaciones, coeficienteCalculoDeGarantia, mesInicioSeguimiento } = dataStudio;
      const creatingEstimationRows = new Array<EstimationDataI>();
      const currentEstimationYear = firstEstimationYear + currentYear - 1;
      let currentTotalEstimate = 0;
      let currentTotalGuarantee = 0;

      for (let i = 0; i < ESTIMATES_PER_PAGE; i += 1) {
        const estimationDate = new Date(currentEstimationYear, mesInicioSeguimiento + i - 1);
        const estimationDateFormated = moment(estimationDate).format('YYYY-MM');
        const estimation = estimaciones.find((e) => e.fecha === estimationDateFormated);

        let garantia = 0;
        let estimacion = 0;

        if (estimation && coeficienteCalculoDeGarantia) {
          estimacion = WattsConverterService.convertWattsToKwatts(estimation.estimacion);
          garantia = WattsConverterService.convertWattsToKwatts(
            estimation.estimacion * (coeficienteCalculoDeGarantia / 100),
          );
        }

        creatingEstimationRows.push({
          id: estimationDateFormated,
          fecha: I18n.t(moment(estimationDate).format('MMMM')) + moment(estimationDate).format(' YYYY'),
          garantia,
          estimacion,
        });

        currentTotalEstimate += estimacion;
        currentTotalGuarantee += garantia;
      }

      setTotalEstimate(currentTotalEstimate);
      setTotalGuarantee(currentTotalGuarantee);
      setEstimationRows(creatingEstimationRows);
    };

    createEstimationsTable();
  }, [dataStudio, firstEstimationYear, currentYear]);

  if (!installationID) {
    return <div className={styles.nodata}>{I18n.t('installationNotSelected')}.</div>;
  }

  if (!dataStudio || dataStudio.coeficienteCalculoDeGarantia === undefined) {
    return (
      <div className={styles.loading_wrapper}>
        <Spinner icon />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.forms_wrapper}>
        <SectionWrapper>
          <div className={styles.estimation_container}>
            <SectionTitle text={I18n.t('projectEstimation')} />
            <FormGroup
              key={calculoDeGarantiaFormGroup.id}
              label={calculoDeGarantiaFormGroup.label}
              type={calculoDeGarantiaFormGroup.type}
              id={calculoDeGarantiaFormGroup.id}
              path={calculoDeGarantiaFormGroup.path}
              name={calculoDeGarantiaFormGroup.name}
              placeholder={calculoDeGarantiaFormGroup.placeholder}
              value={calculoDeGarantiaFormGroup.value || ''}
              required={calculoDeGarantiaFormGroup.required}
              min={calculoDeGarantiaFormGroup.min}
              max={calculoDeGarantiaFormGroup.max}
              step={calculoDeGarantiaFormGroup.step}
              className={calculoDeGarantiaFormGroup.className}
              onChange={onCoeficienteDeGarantiaChange}
            />
            <ProductionGuarantee totalGuarantee={totalGuarantee} />
          </div>
        </SectionWrapper>
        <SectionWrapper>
          <div className={styles.study_container}>
            <SectionTitle text={I18n.t('study')} />
            <div className={styles.paginator}>
              <button disabled={currentYear === 1} type="button" onClick={downPage} className={styles.button}>
                <FontAwesomeIcon icon={faChevronLeft} className="green" />
              </button>
              <div className={styles.value}>
                {I18n.t('Year')}
                <span className={styles.year}>{currentYear}</span>
              </div>
              <button type="button" onClick={upPage} className={styles.button}>
                <FontAwesomeIcon icon={faChevronRight} className="green" />
              </button>
            </div>
            <div>
              <DataTable headings={headings} rows={estimationRows || []} />
            </div>
            <TotalsDataTable totalEstimation={totalEstimate} totalGuarantee={totalGuarantee} />
          </div>
        </SectionWrapper>
      </div>
      <div className={styles.actions_wrapper}>
        <div className={styles.action}>
          <Button block type="button" variant="secondary" text="Cancelar" />
        </div>
        <div className={styles.action}>
          <LoadingButton
            block
            type="submit"
            text="Guardar"
            onClick={onSubmit}
            disabled={submitDisabled}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default InstallationStudyPage;
