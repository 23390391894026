import { Serializable } from '../interfaces';

export interface InstDataStudyEstimate {
  fecha: string;
  estimacion: number;
}

export interface InstDataStudyAPI {
  coeficienteCalculoDeGarantia: number;
  mesInicioSeguimiento: number;
  estimaciones: Array<InstDataStudyEstimate>;
}

export default class InstDataStudy implements Serializable<InstDataStudy> {
  coeficienteCalculoDeGarantia: number;

  mesInicioSeguimiento: number;

  estimaciones: Array<InstDataStudyEstimate>;

  deserialize(input: InstDataStudyAPI): InstDataStudy {
    if (!input) {
      return this;
    }
    this.coeficienteCalculoDeGarantia = input.coeficienteCalculoDeGarantia;
    this.mesInicioSeguimiento = input.mesInicioSeguimiento;
    this.estimaciones = input.estimaciones;

    return this;
  }

  serialize(): InstDataStudyAPI {
    return {
      coeficienteCalculoDeGarantia: this.coeficienteCalculoDeGarantia,
      mesInicioSeguimiento: this.mesInicioSeguimiento,
      estimaciones: this.estimaciones,
    } as InstDataStudyAPI;
  }

  setAttr<T>(key: string, value: T): void {
    Object.assign(this, { [key]: value });
  }

  getEstimationYear(estimation: InstDataStudyEstimate): number {
    const year = estimation.fecha.split('-')[0];

    return +year;
  }

  // deserializeArray(inputArray: Array<InstDataStudyAPI>): Array<InstDataStudy> {
  //   const InstDataStudies: Array<InstDataStudy> = inputArray.map(
  //     (input) => new InstDataStudy().deserialize(input),
  //   );

  //   return InstDataStudies;
  // }
}
