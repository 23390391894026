import { Card } from 'components';
import React from 'react';
import { UtilsService } from 'services';
import { I18n } from 'core';
import { ReactComponent as SpainIcon } from 'assets/icons/Spain.svg';
import { Colors } from 'styles';
import { ReactComponent as EuropeIcon } from 'assets/icons/Europe.svg';
import { SustainabilityObject } from 'types/models';
import styles from './EuropeWidget.module.scss';

interface EuropeWidgetPropsI {
  sustainabilityData: SustainabilityObject;
}

const EuropeWidget = (props: EuropeWidgetPropsI): JSX.Element => {
  const { sustainabilityData } = props;
  return (
    <>
      <Card
        fullHeight
        title={I18n.t('parisAgreement2015')}
        subtitle={I18n.t('whatAreParisAgreementObjectives')}
        body={[
          <div className={styles.paris_card} key={0}>
            <div className={styles.ue_container}>
              <p className={styles.title}>{I18n.t('UEObjective')}</p>
              <EuropeIcon width={75} height={75} fill={Colors.COLOR_ELECSUMGREEN} />
              <div className={styles.data_container}>
                <p className={styles.data}>
                  {`${UtilsService.formatTwoDecimal(sustainabilityData.parisAgreement2015.UEGoal).slice(0, -3)}`}
                </p>
                <p className={styles.text}>{I18n.t('milionTones')}</p>
              </div>
              <p className={styles['text-bold']}>{I18n.t('emmited')}</p>
            </div>
            <div className={styles.spain_container}>
              <p className={styles.title}>{I18n.t('ESObjective')}</p>
              <SpainIcon width={70} height={70} fill={Colors.COLOR_ELECSUMGREEN} />
              <div className={styles.data_container}>
                <p className={styles.data}>
                  {`${UtilsService.formatTwoDecimal(sustainabilityData.parisAgreement2015.ESGoal).slice(0, -3)}`}
                </p>
                <p className={styles.text}>{I18n.t('milionTones')}</p>
              </div>
              <p className={styles['text-bold']}>{I18n.t('emmited')}</p>
            </div>
          </div>,
        ]}
      />
    </>
  );
};

export default EuropeWidget;
