import React, { useState, useEffect } from 'react';

import classNames from 'classnames';
import styles from './Card.module.scss';

interface CardI {
  title?: string | Array<string>;
  subtitle?: string | Array<string>;
  rightCorner?: string | Array<string> | React.ReactNode;
  image?: JSX.Element;
  className?: string;
  body: JSX.Element[];
  footer?: JSX.Element;
  noshadow?: boolean;
  fullHeight?: boolean;
  switcher?: React.ReactNode;
}

interface CardPagerI {
  pages: JSX.Element[];
  currentPageIndex: number;
  onPageClick: CallableFunction;
}

const CardPager = (props: CardPagerI): JSX.Element => {
  const { pages, currentPageIndex, onPageClick } = props;

  return (
    <div className={styles.paginator}>
      {pages.map((page, index) => (
        <span
          onClick={(): void => {
            onPageClick(index);
          }}
          className={classNames(styles['badge-pill'], {
            [styles.active]: currentPageIndex === index,
          })}
          key={page.key || index}
        >
          &nbsp;
        </span>
      ))}
    </div>
  );
};

const Card = (props: CardI): JSX.Element => {
  const { title, subtitle, rightCorner, image, className, body, footer, noshadow, fullHeight, switcher } = props;

  const [currentPage, setCurrentPage] = useState(body[0]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [cardTitleText, setCardTitleText] = useState(Array.isArray(title) ? title[0] : title);
  const [cardSubtitleText, setCardSubtitleText] = useState(Array.isArray(subtitle) ? subtitle[0] : subtitle);
  const [cardRightInfoText, setCardRightInfoText] = useState(Array.isArray(rightCorner) ? rightCorner[0] : rightCorner);

  useEffect(() => {
    if (currentPageIndex < 0 || currentPageIndex >= body.length) {
      return;
    }

    setCurrentPage(body[currentPageIndex]);

    setCardTitleText(Array.isArray(title) ? title[currentPageIndex] : title);
    setCardSubtitleText(Array.isArray(subtitle) ? subtitle[currentPageIndex] : subtitle);
    setCardRightInfoText(Array.isArray(rightCorner) ? rightCorner[currentPageIndex] : rightCorner);
  }, [body, currentPageIndex, rightCorner, subtitle, title]);

  const renderPager = (): boolean => body.length > 1;

  return (
    <div
      className={classNames(className, styles.root, {
        [styles.noshadow]: noshadow,
        [styles.full_height]: fullHeight,
      })}
    >
      <div className={styles.body}>
        <div className={styles.top}>
          <div className={styles.info}>
            {cardTitleText ? <h5 className={styles.title}>{cardTitleText}</h5> : null}
            {cardSubtitleText ? <h6 className={styles.subtitle}>{cardSubtitleText}</h6> : null}
          </div>
          <div>{image || null}</div>
          <div className={styles.rightCorner}>
            {switcher ? <div>{switcher}</div> : null}
            <p>{cardRightInfoText}</p>
          </div>
        </div>

        <div className={styles.content}>{currentPage}</div>

        {renderPager() && !footer ? (
          <CardPager pages={body} currentPageIndex={currentPageIndex} onPageClick={setCurrentPageIndex} />
        ) : null}
      </div>
      {footer ? (
        <div className={styles.footer}>
          {footer}
          {renderPager() ? (
            <CardPager pages={body} currentPageIndex={currentPageIndex} onPageClick={setCurrentPageIndex} />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default Card;
