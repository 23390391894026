import React, { useState } from 'react';
import cx from 'classnames';
import { ReactComponent as TickIcon } from 'assets/icons/Tick_Thin.svg';
import { Colors } from 'styles';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import ControlledInput from 'components/Input/NewInputs';
import Button from 'components/Button/Button';
import * as Yup from 'yup';
import { I18n } from 'core';
import { authServiceInstance } from 'services';
import logo from 'assets/images/logo.png';
import { ReactComponent as LikeIcon } from 'assets/icons/LikeIcon.svg';
import { navigate } from 'navigation';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import styles from './SignUpPage.module.scss';
import { CupsTooltip } from './components';

interface StepPillPropsI {
  value: string;
  status: 'active' | 'completed' | 'pending';
  label: string;
}

interface StepDividerPropsI {
  status: 'current' | 'completed' | 'pending';
}

const StepPill = (props: StepPillPropsI): JSX.Element => {
  const { value, status, label } = props;
  return (
    <div className={styles.pill_wrapper}>
      <div
        className={cx(
          styles.pill,
          { [styles.active]: status === 'active' },
          { [styles.completed]: status === 'completed' },
          { [styles.pending]: status === 'pending' },
        )}
      >
        {status === 'completed' ? <TickIcon fill={Colors.COLOR_ELECSUMGREEN} height={16} width={16} /> : value}
      </div>
      <span
        className={cx(
          styles.label,
          { [styles.active]: status === 'active' },
          { [styles.completed]: status === 'completed' },
          { [styles.pending]: status === 'pending' },
        )}
      >
        {label}
      </span>
    </div>
  );
};

const StepDivider = (props: StepDividerPropsI) => {
  const { status } = props;
  return (
    <div
      className={cx(
        styles.divider,
        { [styles.current]: status === 'current' },
        { [styles.pending]: status === 'pending' },
        { [styles.completed]: status === 'completed' },
      )}
    />
  );
};

const stepOneValidationSchema = Yup.object({
  NIF: Yup.string().required(I18n.t('SignUpPage.errors.nifRequired')).label('NIF'),
  CUPS: Yup.string().required(I18n.t('SignUpPage.errors.cupsRequired')).label('CUPS'),
});

const StepOne = (props: any) => {
  const { next, data } = props;
  const [isUserError, setIsUserError] = useState<string | null>(null);

  const handleSubmit = (values: any) => {
    authServiceInstance.checkIfClient(values.NIF, values.CUPS).then((response) => {
      if (response) {
        next(values);
        setIsUserError(null);
        return;
      }
      setIsUserError(I18n.t('SignUpPage.errors.cupsNIFNoExist'));
    });
  };

  return (
    <Formik validationSchema={stepOneValidationSchema} initialValues={data} onSubmit={handleSubmit}>
      {({ isValid }) => (
        <Form className={styles.form}>
          <div className={styles.input}>
            <Field name="NIF">
              {({ field }: any) => (
                <ControlledInput
                  type="text"
                  value={field.value}
                  placeholder={I18n.t('SignUpPage.form.nifPlaceholder')}
                  label={I18n.t('SignUpPage.form.nifLabel')}
                  onChange={field.onChange}
                  id="NIF"
                  name={field.name}
                />
              )}
            </Field>
            <ErrorMessage component="div" className={styles.error} name="NIF" />
          </div>

          <div className={styles.input}>
            <Field name="CUPS">
              {({ field }: any) => (
                <ControlledInput
                  type="text"
                  value={field.value}
                  placeholder={I18n.t('SignUpPage.form.cupsPlaceholder')}
                  label={I18n.t('SignUpPage.form.cupsLabel')}
                  onChange={field.onChange}
                  id="CUPS"
                  name={field.name}
                />
              )}
            </Field>
            <ErrorMessage component="div" className={styles.error} name="CUPS" />
            <CupsTooltip />
          </div>
          {isUserError && <div className={styles.error_high}>{isUserError}</div>}
          <div className={styles.next_step_wrapper}>
            <Button disabled={!isValid} type="submit" variant="primary" text={I18n.t('SignUpPage.form.continue')} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

const stepTwoValidationSchema = Yup.object({
  email: Yup.string()
    .required(I18n.t('SignUpPage.errors.emailRequired'))
    .email(I18n.t('SignUpPage.errors.emailBad'))
    .label('email'),
  email2: Yup.string()
    .required(I18n.t('SignUpPage.errors.emailRequired'))
    .email(I18n.t('SignUpPage.errors.emailBad'))
    .when('email', {
      is: (val: any) => val && val.length > 0,
      then: Yup.string().oneOf([Yup.ref('email')], I18n.t('SignUpPage.errors.emailNotSame')),
    })
    .label('email2'),
});

const StepTwo = (props: any) => {
  const { next, data, previous } = props;
  const handleSubmit = (values: any) => {
    next(values);
  };
  const handlePrev = () => {
    previous();
  };
  return (
    <Formik validationSchema={stepTwoValidationSchema} initialValues={data} onSubmit={handleSubmit}>
      {({ isValid }) => (
        <Form className={styles.form}>
          <div className={styles.input}>
            <Field name="email">
              {({ field }: any) => (
                <ControlledInput
                  type="email"
                  value={field.value}
                  placeholder="Email"
                  label="Email"
                  onChange={field.onChange}
                  id="email"
                  name={field.name}
                />
              )}
            </Field>
            <ErrorMessage component="div" className={styles.error} name="email" />
          </div>
          <div className={styles.input}>
            <Field name="email2">
              {({ field }: any) => (
                <ControlledInput
                  type="email"
                  value={field.value}
                  placeholder="Repeteix Email"
                  label="Repeteix Email"
                  onChange={field.onChange}
                  id="email2"
                  name={field.name}
                />
              )}
            </Field>
            <ErrorMessage component="div" className={styles.error} name="email2" />
          </div>
          <div className={styles.next_step_wrapper}>
            <Button type="button" onClick={handlePrev} variant="tertiary" text={I18n.t('SignUpPage.form.back')} />
            <Button disabled={!isValid} type="submit" variant="primary" text={I18n.t('SignUpPage.form.continue')} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

const stepThreeValidationSchema = Yup.object({
  pwd: Yup.string()
    .required(I18n.t('SignUpPage.errors.passwordRequired'))
    .min(8, I18n.t('SignUpPage.errors.passwordLength'))
    .label('pwd'),
  pwd2: Yup.string()
    .required(I18n.t('SignUpPage.errors.passwordRequired'))
    .when('pwd', {
      is: (val: any) => val && val.length > 0,
      then: Yup.string().oneOf([Yup.ref('pwd')], I18n.t('SignUpPage.errors.passwordNotSame')),
    })
    .label('pwd2'),
});

const StepThree = (props: any) => {
  const { next, data, previous } = props;
  const handleSubmit = (values: any) => {
    next(values, true);
  };

  const handlePrev = () => {
    previous();
  };

  return (
    <Formik validationSchema={stepThreeValidationSchema} initialValues={data} onSubmit={handleSubmit}>
      {({ dirty }) => (
        <Form className={styles.form}>
          <div className={styles.input}>
            <Field name="pwd">
              {({ field }: any) => (
                <ControlledInput
                  type="password"
                  value={field.value}
                  placeholder={I18n.t('SignUpPage.form.passwordPlaceholder')}
                  label={I18n.t('SignUpPage.form.passwordLabel')}
                  onChange={field.onChange}
                  id="pwd"
                  name={field.name}
                />
              )}
            </Field>
            <ErrorMessage component="div" className={styles.error} name="pwd" />
          </div>
          <div className={styles.input}>
            <Field name="pwd2">
              {({ field }: any) => (
                <ControlledInput
                  type="password"
                  value={field.value}
                  placeholder={I18n.t('SignUpPage.form.passwordRepeatPlaceholder')}
                  label={I18n.t('SignUpPage.form.passwordRepeatLabel')}
                  onChange={field.onChange}
                  id="pwd2"
                  name={field.name}
                />
              )}
            </Field>
            <ErrorMessage component="div" className={styles.error} name="pwd2" />
          </div>
          <div className={styles.next_step_wrapper}>
            <Button type="button" onClick={handlePrev} variant="tertiary" text={I18n.t('SignUpPage.form.back')} />
            <Button disabled={!dirty} type="submit" variant="primary" text={I18n.t('SignUpPage.form.finish')} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

const SignUpPage = (): JSX.Element => {
  const [step, setStep] = useState(0);
  const [registerSuccess, setRegisterSuccess] = useState<boolean>(false);
  const [formData, setFormData] = useState({ NIF: '', CUPS: '', email: '', email2: '', pwd: '', pwd2: '' });

  const handleNextStep = (newData: any, final = false) => {
    if (final) {
      const dataToSubmit = { ...formData, ...newData };
      const { email, NIF, pwd, CUPS } = dataToSubmit;
      authServiceInstance
        .register(email, pwd, CUPS, NIF)
        .then((response) => {
          if (response.status === 201) {
            setRegisterSuccess(true);
          }
        })
        .catch((error) => {
          const { response } = error;
          const { data } = response;
          if (data.includes('email')) {
            toast.error(I18n.t('SignUpPage.errors.submitEmail'));
            return;
          }
          if (data.includes('nif')) {
            toast.error(I18n.t('SignUpPage.errors.submitNIF'));
            return;
          }
          toast.error(data);
        });
      return;
    }
    setFormData((prev) => ({ ...prev, ...newData }));
    setStep((prev) => prev + 1);
  };

  const handlePrevStep = (start = false) => {
    if (start) {
      return;
    }
    setStep((prev) => prev - 1);
  };

  const getPillStatus = (value: number): 'active' | 'completed' | 'pending' => {
    if (step === value) return 'active';
    if (value < step) return 'completed';
    return 'pending';
  };

  const getDividerStatus = (value: number): 'current' | 'completed' | 'pending' => {
    if (value === step) return 'current';
    if (value < step) return 'completed';
    return 'pending';
  };

  const steps: Array<React.ReactNode> = [
    <StepOne next={handleNextStep} data={formData} />,
    <StepTwo previous={handlePrevStep} next={handleNextStep} data={formData} />,
    <StepThree previous={handlePrevStep} next={handleNextStep} data={formData} />,
  ];
  return (
    <div className={styles.root}>
      {registerSuccess ? (
        <div className={styles.success_container}>
          <LikeIcon fill={Colors.COLOR_ELECSUMGREEN} height={64} width={64} />
          <h4>{I18n.t('SignUpPage.success.title')}</h4>
          <p>{I18n.t('SignUpPage.success.subtitle')}</p>
          <Button
            className={styles.viewmore_button}
            variant="primary"
            type="button"
            onClick={(): Promise<void> => navigate('/auth/login')}
            text={I18n.t('SignUpPage.success.login')}
          />
        </div>
      ) : (
        <div className={styles.form_container}>
          <Link to="/auth/login">
            <img src={logo} className={styles.logo} alt="" />
          </Link>
          <div className={styles.steps_container}>
            <StepPill label={I18n.t('SignUpPage.steps.user')} value="1" status={getPillStatus(0)} />
            <StepDivider status={getDividerStatus(0)} />
            <StepPill label={I18n.t('SignUpPage.steps.email')} value="2" status={getPillStatus(1)} />
            <StepDivider status={getDividerStatus(1)} />
            <StepPill label={I18n.t('SignUpPage.steps.password')} value="3" status={getPillStatus(2)} />
          </div>
          <div>{steps[step]}</div>
        </div>
      )}
    </div>
  );
};

export default React.memo(SignUpPage);
