/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/ArrowDown.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/ArrowUp.svg';
import { Colors } from 'styles';
import cx from 'classnames';
import styles from './DropdownBase.module.scss';

interface DropdownBaseItem {
  id: string | number;
  label: string | number;
  value: string | number;
}

interface DropdownBasePropsI {
  label?: string;
  values: Array<DropdownBaseItem>;
  value: DropdownBaseItem;
  onChange(element: DropdownBaseItem): void;
  closeOnClick?: boolean;
  fullWidth?: boolean;
  maxHeightList?: number;
}

const DropdownBase = (props: DropdownBasePropsI): JSX.Element => {
  const {
    maxHeightList = 30,
    fullWidth = false,
    label = 'Label',
    values = [],
    onChange,
    value,
    closeOnClick = true,
  } = props;
  const [open, setOpen] = useState(false);
  const toggleDropdown = () => {
    setOpen(!open);
  };
  return (
    <div className={cx(styles.root, { [styles.fullWidth]: fullWidth })}>
      <span className={styles.label}>{label}</span>
      <button onClick={() => toggleDropdown()} type="button" className={styles.button}>
        <span>{value.label}</span>
        {open ? (
          <ArrowUpIcon height={15} width={15} fill={Colors.COLOR_GRAY_200} />
        ) : (
          <ArrowDownIcon height={15} width={15} fill={Colors.COLOR_GRAY_200} />
        )}
      </button>
      <ul style={{ maxHeight: `${maxHeightList}rem` }} className={cx({ [styles.hide]: !open })}>
        {values.map((item) => (
          <li
            key={item.id}
            className={cx({ [styles.active]: item.id === value.id })}
            onClick={(e) => {
              e.preventDefault(); // Prevent the selection from changing

              onChange(item);
              if (closeOnClick) {
                setOpen(false);
              }
            }}
          >
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default React.memo(DropdownBase);
