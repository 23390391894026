import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import React, { createRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PromotorCustomOnboarding } from 'types/models';
import { I18n } from 'core';
import { toast } from 'react-toastify';
import { LoadingButton } from 'components';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import customToast from 'components/CustomToast/CustomToast';

import PromotorCustomOnboardingServiceInstance from 'services/remote/PromotorCustomOnboardingService';
import styles from './promotor-custom-onboarding-process-page.module.scss';
import { PromotoresCustomOnboardingImageForm } from './promotor-custom-onboarding-image-form';
import PromotorCustomOnboardingForm from './promotor-custom-onboarding-form/promotor-custom-onboarding-form';

interface CustomImagesFormFieldsI {
  id: string;
  url: string;
}

export type SaveDataHandle = {
  getData: () => void;
};

export const PromotorCustomPersonalization = (): JSX.Element => {
  const { userID } = useParams<{ userID: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [promotorCustomOnboarding, setPromotorCustomOnboarding] = useState<PromotorCustomOnboarding | undefined>(
    undefined,
  );

  const [customImagesFormFields, setCustomImagesFormFields] = useState<CustomImagesFormFieldsI[]>([]);
  const customOnboardingRef = createRef<SaveDataHandle>();
  // const customOnboardingRefFaqs = createRef<SaveDataHandle>();
  // const customOnboardingRefipoDeContacto = createRef<SaveDataHandle>();

  const getCustomOnboardingData = async (id: string): Promise<void> => {
    setLoading(true);
    try {
      const customOnboarding = await PromotorCustomOnboardingServiceInstance.getPromotorCustomOnboarding(id);
      if (customOnboarding) {
        setPromotorCustomOnboarding(customOnboarding);
        setCustomImagesFormFields(customOnboarding.logos);
      }
    } catch (error) {
      toast.error(I18n.t('ComunidadesCustomOnboardingProcess.errors.errorGettingCustomOnboardingData'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userID) return;
    getCustomOnboardingData(userID);
  }, []);

  const updateCustomOnboarding = async (promotorCustomOnboardingUpdate: PromotorCustomOnboarding): Promise<void> => {
    const update = promotorCustomOnboardingUpdate.serialize();
    try {
      await PromotorCustomOnboardingServiceInstance.updatePromotorCustomOnboarding(userID, update);

      customToast.success(I18n.t('ComunidadesCustomOnboardingProcess.updateSuccess'));
      getCustomOnboardingData(userID);
    } catch (error) {
      toast.error(I18n.t('ComunidadesCustomOnboardingProcess.errors.updating'));
    }
  };

  if (loading) return <FullPageSyncLoader icon />;
  return (
    <div className={styles.container}>
      <SectionWrapper>
        <>
          {promotorCustomOnboarding && (
            <PromotorCustomOnboardingForm
              ref={customOnboardingRef}
              promotorId={userID}
              promotorCustomOnboarding={promotorCustomOnboarding}
              saveDetails={(customOnboarding) => updateCustomOnboarding(customOnboarding)}
            />
          )}
        </>
        <div className={styles.margenBotImagen}>
          {promotorCustomOnboarding && (
            <PromotoresCustomOnboardingImageForm
              key="1"
              communitiyId={userID}
              images={customImagesFormFields}
              afterSubmitAction={getCustomOnboardingData}
            />
          )}
        </div>
        <div className={styles.positionBotonoes}>
          <div className={styles.divBoton}>
            <LoadingButton type="submit" text={I18n.t('Save')} onClick={() => customOnboardingRef.current?.getData()} />
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
};
