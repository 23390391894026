/* eslint-disable no-param-reassign */
import { LoadingButton, SectionTitle, Spinner } from 'components';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { FormGroup, FormGroupProps, I18n } from 'core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUser } from 'redux/actions/UsersActions';
import customToast from 'components/CustomToast/CustomToast';
import { toast } from 'react-toastify';

import roleService from 'services/remote/RolesService';
import { RolInfo } from 'types/models';

import RolesDataSection from './components/RolesDataSection';
import styles from './RolesUserPage.module.scss';

const RolDetailPage = (): JSX.Element => {
  // Props

  // Hooks
  const dispatch = useDispatch();

  // State
  const [rolData, setRolData] = useState<RolInfo[]>();

  const [loadingData, setLoadingData] = useState(false);
  const [rolInfoForm, setRolInfoForm] = useState({ nombre: '' });
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [submitDisabledDelete, setSubmitDisabledDelete] = useState<boolean>(false);
  const fetchData = () => {
    roleService.getRoles().then((response) => {
      setRolData(response);
    });
  };

  // Effects
  useEffect(() => {
    setLoadingData(true);
    dispatch(fetchUser());
    fetchData();
    setLoadingData(false);
  }, []);

  if (loadingData) {
    return (
      <div className={styles.loading_container}>
        <Spinner icon />
      </div>
    );
  }

  const formFields: Array<FormGroupProps> = [
    {
      label: I18n.t('RolDetailPage.RolData.labels.name'),
      type: 'text',
      id: 'nombre',
      path: 'nombre',
      name: 'nombre',
      value: rolInfoForm?.nombre,
      className: styles.field,
      placeholder: '',
    },
  ];

  const addRol = async () => {
    setLoading(true);
    setSubmitDisabled(true);

    try {
      if (rolData) {
        const response = await roleService.createRol(rolInfoForm);
        if (response) {
          if (response) {
            roleService.getRoles().then((roles) => {
              setRolData(roles);
            });
          }
        }
      }
    } catch (error) {
      toast.error(I18n.t('RolDetailPage.RolData.error'));
    } finally {
      setLoading(false);
      setSubmitDisabled(false);
      setRolInfoForm({ nombre: '' }); // Establecer el estado a un objeto vacío
    }
  };
  function handleChange(path: string, value: string): void {
    setRolInfoForm((prev) => ({
      ...prev,
      [path]: value,
    }));
  }
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    addRol();
  };
  const handleDeleteRole = async (id: number) => {
    setLoadingDelete(true);
    setSubmitDisabledDelete(true);
    try {
      // Call the delete role function from your service
      await roleService.deleteRol(id);
      // After successfully deleting the role, fetch the updated roles data
      const roles = await roleService.getRoles();
      setRolData(roles);
      customToast.success(I18n.t('Role deleted successfully'));
    } catch (error) {
      toast.error(I18n.t('Error deleting role'));
    } finally {
      setLoadingDelete(false);
      setSubmitDisabledDelete(false);
    }
  };
  return (
    <div className={styles.root}>
      <SectionWrapper>
        <SectionTitle text={I18n.t('RolDetailPage.RolData.title')} />
        <form onSubmit={handleSubmit}>
          <div className={styles.user_info}>
            {formFields.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                placeholder={formGroup.placeholder}
                value={formGroup.value}
                required={formGroup.required}
                readOnly={formGroup.readOnly}
                className={formGroup.className}
                onChange={handleChange}
              />
            ))}
          </div>
          <div className={styles.saveContainer}>
            <LoadingButton
              type="submit"
              text={I18n.t('RolDetailPage.RolData.crear')}
              disabled={submitDisabled}
              loading={loading}
            />
          </div>
        </form>
        <div>
          {rolData && (
            <RolesDataSection
              rolData={rolData}
              onDeleteRole={handleDeleteRole}
              submitDisabled={submitDisabledDelete}
              loading={loadingDelete}
              fetchData={fetchData}
            />
          )}
        </div>
      </SectionWrapper>
    </div>
  );
};

export default React.memo(RolDetailPage);
