import configuration from 'config';
import { AxiosResponse } from 'axios';
import { RolInfo } from 'types/models';

import { RolInfoAPI, RolInfoUpdateAPI } from 'types/models/rolinfo';
import apiService from './ApiService';

class RolesService {
  private readonly http = apiService;

  private apiUrl = configuration.apiUrl;

  public async getRoles() {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/auth/roles/`);
    return new RolInfo().deserializeArray(response.data.datos);
  }

  public async getRolesById(id: string) {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/auth/roles/${id}/`);
    return new RolInfo().deserialize(response.data.datos);
  }

  public async createRol(userInfoObject: RolInfoUpdateAPI) {
    const url = `${this.apiUrl}/auth/roles/`;
    const userUpdate: RolInfoUpdateAPI = {
      nombre: userInfoObject.nombre,
    };

    const response: AxiosResponse<string> = await this.http.post(url, userUpdate);

    return response.data;
  }

  public async updateRol(userInfoObject: RolInfoAPI) {
    const url = `${this.apiUrl}/auth/roles/${userInfoObject.id}/`;
    const userUpdate: RolInfoUpdateAPI = {
      nombre: userInfoObject.nombre,
    };

    const response: AxiosResponse<string> = await this.http.patch(url, userUpdate);

    return response.data;
  }

  public async updateRolInPermisio(id: number, nombre: string) {
    const url = `${this.apiUrl}/auth/roles/${id}/`;
    const userUpdate: RolInfoUpdateAPI = {
      nombre,
    };

    const response: AxiosResponse<string> = await this.http.patch(url, userUpdate);

    return response.data;
  }

  public async deleteRol(id: number) {
    const url = `${this.apiUrl}/auth/roles/${id}/`;
    const response: AxiosResponse<number> = await this.http.delete(url);

    return response;
  }

  public async updateRolPermisos(id: string, userInfoObject: any) {
    const url = `${this.apiUrl}/auth/roles/${id}/permisos/`;
    const permisosID = userInfoObject.map((x: any) => x.id);
    const objectValue = {
      permisos: permisosID,
    };

    const response: AxiosResponse<string> = await this.http.patch(url, objectValue);

    return response.data;
  }
}

const roleService: RolesService = new RolesService();

export default roleService;
