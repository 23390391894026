// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState } from 'react';
import { NavItemI, I18n } from 'core';
import User from 'types/models/user';

import { useParams } from 'react-router-dom';
import UserInfo from 'types/models/userinfo';

const createNavigationLinks = (user: User | null, selectedUser: UserInfo | null): Array<NavItemI> => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { userID } = useParams<{ userID: string }>();
  const links: Array<NavItemI> = [
    {
      name: I18n.t('Sidenav.Tecnic.User_Data'),
      url: `/tecnic/user/${userID}/data`,
    },
    {
      name: I18n.t('Sidenav.Tecnic.User_CUPS'),
      url: `/tecnic/user/${userID}/cups`,
    },
    {
      name: I18n.t('Sidenav.Tecnic.User_Egreen'),
      url: `/tecnic/user/${userID}/elecsum-green`,
    },
    ...(user?.tipoDeUsuario === 'admin'
      ? [
          {
            name: I18n.t('Sidenav.Tecnic.RolesPermisions'),
            url: `/tecnic/user/${userID}/rol`,
          },
          // Solo mostrar "ConfigPromotor" si `selectedUser` es null o undefined
          ...(selectedUser?.tipoDeUsuario === 'user'
            ? []
            : [
                {
                  name: I18n.t('Sidenav.Tecnic.ConfigPromotor'),
                  url: `/tecnic/user/${userID}/config-promotor`,
                },
                {
                  name: I18n.t('Sidenav.Tecnic.SolicitudesPendiente'),
                  url: `/tecnic/user/${userID}/solicitudes-pendientes`,
                },
              ]),
        ]
      : []),
  ];
  return links;
};

export default createNavigationLinks;
