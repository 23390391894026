import { ProdEnergyProductionDataDailyAPII } from '../types/models/widgetsData/prodEnergyProductionDataDaily';

const PROD_ENERGY_PRODUCTION_DATA_MOCK: ProdEnergyProductionDataDailyAPII = {
  generacion: 200,
  excedente: 50,
  autoconsumo: 2000,
  red: 1500,
  diferenciaGeneracion: -0.5,
  horas: [
    {
      hora: 1,
      a: 10,
      e: 5,
      g: 0,
      r: 3500,
    },
    {
      hora: 2,
      a: 8,
      e: 3,
      g: 0,
      r: 3100,
    },
    {
      hora: 3,
      a: 9,
      e: 6,
      g: 0,
      r: 2700,
    },
    {
      hora: 4,
      a: 8,
      e: 3,
      g: 0,
      r: 3200,
    },
    {
      hora: 5,
      a: 10,
      e: 5,
      g: 0,
      r: 3700,
    },
    {
      hora: 6,
      a: 10,
      e: 5,
      g: 0,
      r: 4000,
    },
    {
      hora: 7,
      a: 3800,
      e: 500,
      g: 4300,
      r: 5,
    },
    {
      hora: 8,
      a: 4200,
      e: 800,
      g: 5000,
      r: 4,
    },
    {
      hora: 9,
      a: 3700,
      e: 1200,
      g: 4900,
      r: 4,
    },
    {
      hora: 10,
      a: 4000,
      e: 500,
      g: 4500,
      r: 4,
    },
    {
      hora: 11,
      a: 4000,
      e: 900,
      g: 4900,
      r: 5,
    },
    {
      hora: 12,
      a: 4000,
      e: 200,
      g: 4200,
      r: 4,
    },
    {
      hora: 13,
      a: 4500,
      e: 2000,
      g: 6500,
      r: 5,
    },
    {
      hora: 14,
      a: 4200,
      e: 1400,
      g: 5600,
      r: 4,
    },
    {
      hora: 15,
      a: 3800,
      e: 1300,
      g: 5200,
      r: 4,
    },
    {
      hora: 16,
      a: 3000,
      e: 1100,
      g: 4100,
      r: 5,
    },
    {
      hora: 17,
      a: 2500,
      e: 500,
      g: 3000,
      r: 4,
    },
    {
      hora: 18,
      a: 1000,
      e: 200,
      g: 1200,
      r: 4,
    },
    {
      hora: 19,
      a: 8,
      e: 3,
      g: 0,
      r: 500,
    },
    {
      hora: 20,
      a: 10,
      e: 5,
      g: 0,
      r: 700,
    },
    {
      hora: 21,
      a: 8,
      e: 3,
      g: 0,
      r: 1200,
    },
    {
      hora: 22,
      a: 10,
      e: 5,
      g: 0,
      r: 2500,
    },
    {
      hora: 23,
      a: 10,
      e: 5,
      g: 0,
      r: 1200,
    },
    {
      hora: 24,
      a: 10,
      e: 5,
      g: 0,
      r: 300,
    },
  ],
};

export default PROD_ENERGY_PRODUCTION_DATA_MOCK;
