import configuration from 'config';
import { AxiosResponse } from 'axios';
import PromotorInfo from 'types/models/promotorInfo';
import { PromotorObject, TeledetectionData } from 'types/models';
import PromotorSharedEnergyObj from 'types/models/widgetsData/promotorSharedEnergyObj';
import TeledectionData from 'types/models/teledetectionData';
import SavingObjectPromotor from 'types/models/widgetsData/savingObjPromotor';
import { PromotorUpdatedAPI } from 'types/models/promotor';
import apiService from './ApiService';

export const dataFrequencies = {
  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year',
  HISTORIC: 'historic',
};
class PromotorService {
  private readonly http = apiService;

  private apiUrl = configuration.apiUrl;

  registerPromotor(
    nif: string,
    email: string,
    nombre: string,
    apellidos: string,
    password: string,
    tipoDeUsuario: string,
  ): Promise<AxiosResponse> {
    return this.http.post(`${this.apiUrl}/usuario/`, {
      nif,
      email,
      nombre,
      apellidos,
      password,
      tipoDeUsuario,
    });
  }

  public async promotorListaComunidad(nif: string) {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/promotor/${nif}/lista-comunidades/`);
    return new PromotorInfo().deserializeArray(response.data.datos);
  }

  public async getPromotor(nif: string) {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/promotor/${nif}/`);
    return new PromotorObject().deserialize(response.data.datos);
  }

  public async patchPromotor(nif: string, promotorUpdated: PromotorUpdatedAPI) {
    const url = `${this.apiUrl}/promotor/${nif}/`;
    const response: AxiosResponse<{ status: number }> = await this.http.patch(url, promotorUpdated);

    return response.status;
  }

  async promotorAsociarComunidad(nif: string, tipoAccion: string, id: number): Promise<number> {
    const url = `${this.apiUrl}/promotor/${nif}/asociar-comunidad/`;

    const object = {
      accion: tipoAccion,
      id_comunidad: id,
    };
    const response: AxiosResponse<{ status: number }> = await this.http.post(url, object);

    return response.status;
  }

  async promotorAsociarComunidadPrincipal(nif: string, id: number | null): Promise<number> {
    const url = `${this.apiUrl}/promotor/${nif}/marcar-comunidad-principal/?nocache=${new Date(Date.now())}`;

    const object = {
      idComunidad: id,
    };
    const response: AxiosResponse<{ status: number }> = await this.http.patch(url, object);

    return response.status;
  }

  public async getSharedEnergyData(nif?: string, cau?: string): Promise<PromotorSharedEnergyObj> {
    if (!cau) {
      return new PromotorSharedEnergyObj();
    }
    const response: AxiosResponse = await this.http.get(
      `${this.apiUrl}/promotor/${nif}/widget/generacion-repartida/${cau}/`,
    );
    return new PromotorSharedEnergyObj().deserialize(response.data);
  }

  public async getFechaUltimosDatos(nif: string, caus: Array<PromotorInfo>) {
    const queryString = caus.map((c) => `caus=${c.cau}`).join('&');

    return this.http.get(`${this.apiUrl}/promotor/${nif}/teledeteccion/fecha-ultimos-datos/?${queryString}`);
  }

  public async getTeledetectionData(
    freq: string,
    nif: string,
    caus: Array<PromotorInfo>,
    year?: number,
    month?: number,
    day?: number,
  ): Promise<TeledetectionData> {
    let teledetectionData = new TeledectionData();
    if (dataFrequencies.MONTH === freq && year && month) {
      teledetectionData = await this.getTeledetectionMonthDataPromotor(nif, caus, year, month);
    }

    if (dataFrequencies.YEAR === freq && year) {
      teledetectionData = await this.getTeledetectionYearDataPromotor(nif, caus, year);
    }

    if (dataFrequencies.HISTORIC === freq) {
      teledetectionData = await this.getTeledetectionHistoricDataPromotor(nif, caus);
    }
    if (dataFrequencies.DAY === freq && year && month && day) {
      teledetectionData = await this.getTeledetectionDayDataPromotor(nif, caus, year, month, day);
    }
    return teledetectionData;
  }

  private async getTeledetectionDayDataPromotor(
    nif: string,
    caus: Array<PromotorInfo>,
    year: number,
    month: number,
    day: number,
  ): Promise<TeledetectionData> {
    const queryString = caus.map((c) => `caus=${c.cau}`).join('&');

    const response: AxiosResponse = await this.http.get(
      `${this.apiUrl}/promotor/${nif}/teledeteccion/diaria/?${queryString}&fecha=${year}-${month}-${day}`,
    );

    return new TeledetectionData().deserialize(response.data.datos);
  }

  private async getTeledetectionMonthDataPromotor(
    nif: string,
    caus: Array<PromotorInfo>,
    year: number,
    month: number,
  ): Promise<TeledetectionData> {
    const queryString = caus.map((c) => `caus=${c.cau}`).join('&');

    const response: AxiosResponse = await this.http.get(
      `${this.apiUrl}/promotor/${nif}/teledeteccion/mensual/?${queryString}&year=${year}&month=${month}`,
    );
    return new TeledetectionData().deserialize(response.data.datos);
  }

  private async getTeledetectionYearDataPromotor(
    nif: string,
    caus: Array<PromotorInfo>,
    year: number,
  ): Promise<TeledetectionData> {
    const queryString = caus.map((c) => `caus=${c.cau}`).join('&');

    const response: AxiosResponse = await this.http.get(
      `${this.apiUrl}/promotor/${nif}/teledeteccion/anual/?${queryString}&year=${year}`,
    );

    return new TeledetectionData().deserialize(response.data.datos);
  }

  private async getTeledetectionHistoricDataPromotor(
    nif: string,
    caus: Array<PromotorInfo>,
  ): Promise<TeledetectionData> {
    const queryString = caus.map((c) => `caus=${c.cau}`).join('&');
    const response: AxiosResponse = await this.http.get(
      `${this.apiUrl}/promotor/${nif}/teledeteccion/historica/?${queryString}`,
    );

    return new TeledetectionData().deserialize(response.data.datos);
  }

  public async getSavingData(nif: string, caus: Array<PromotorInfo> | string): Promise<SavingObjectPromotor> {
    if (!nif) {
      return new SavingObjectPromotor();
    }
    let queryString = '';
    if (Array.isArray(caus)) {
      queryString = caus.map((c) => `caus=${c.cau}`).join('&');
    } else {
      queryString = `caus=${caus}`;
    }

    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/promotor/${nif}/widget/ahorro/?${queryString}`);
    return new SavingObjectPromotor().deserialize(response.data);
  }

  async validarPromotorSMTPData(
    smtpHost: string,
    smtpPort: number,
    username: string,
    password: string,
    useTls: string,
    useSsl: string,
  ): Promise<number> {
    const url = `${this.apiUrl}/promotor/smtp-data/validar/`;

    const object = {
      smtpHost,
      smtpPort,
      username,
      password,
      useTls,
      useSsl,
    };
    const response: AxiosResponse<{ status: number }> = await this.http.post(url, object);

    return response.status;
  }
}

const promotorService: PromotorService = new PromotorService();

export default promotorService;
