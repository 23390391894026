import { GuardInterface } from 'types/interfaces';

import { authServiceInstance, AuthService } from 'services';

class NotLoggedInGuard implements GuardInterface {
  private authService: AuthService;

  private redirectUrl = '/';

  constructor() {
    this.authService = authServiceInstance;
  }

  canActivate(): boolean {
    const isUserLogged = this.authService.isLoggedIn();
    return !isUserLogged;
  }

  getRedirectUrl(): string | null {
    return this.redirectUrl;
  }
}

const notLoggedInGuard = new NotLoggedInGuard();

export default notLoggedInGuard;
