import React from 'react';
import { WattsConverterService } from 'services';

import cx from 'classnames';
import styles from './ChartDataList.module.scss';

interface ListItemsPropsI {
  color: string;
  name: string;
  data: number;
  textColor?: string;
}

const ChartDataList = (props: ListItemsPropsI): JSX.Element => {
  const { color, name, data, textColor } = props;
  let dataToShow = data.toString();
  let unitToShow = '%';

  if (color !== '') {
    dataToShow = WattsConverterService.convertWatts(data).num;
    unitToShow = WattsConverterService.convertWatts(data).unitHour;
  }
  return (
    <div className={styles.list_item}>
      <div className={styles.type_container}>
        <div className={cx(styles.dot, color)} />
        <p className={styles.text}>{name}</p>
      </div>
      <div className={styles.value_container}>
        <p className={cx(styles.data, textColor, data > 0 ? 'sickGreen' : null, data < 0 ? 'red' : null)}>
          {dataToShow}
        </p>
        <p className={cx(styles.unit, textColor, data > 0 ? 'sickGreen' : null, data < 0 ? 'red' : null)}>
          {unitToShow}
        </p>
      </div>
    </div>
  );
};

export default ChartDataList;
