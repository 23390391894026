import React, { useState, ChangeEvent, useEffect } from 'react';

import cx from 'classnames';
import { ImageTypes } from 'types/enums';
import DropdownBase from 'components/DropdownBase';
import styles from './ImageInput.module.scss';

export interface ImageInputInterface {
  id: string;
  label: string;
  tituloSeleccion: string;
  opcion1: string;
  opcion2: string;
  note: string;
  fileName: string;
  icon: JSX.Element;
  action: CallableFunction;
  mostrar: CallableFunction;
  defaultSelect: string;
  type?: string;
  className?: string;
}

const ImageInputWithOptions = (
  props: ImageInputInterface,
  // Use ForwardedRef to type the ref
): JSX.Element => {
  const {
    id,
    tituloSeleccion,
    label,
    note,
    fileName,
    icon,
    action,
    type,
    className = '',
    mostrar,
    opcion2,
    opcion1,
  } = props;

  const [filePlace, setFilePlace] = useState<string>('');
  const [fileNamePdf, setFileNamePdf] = useState<string>(fileName);
  const [itsImage, setItsImage] = useState<boolean>(false);

  const typeOptions = [
    { id: ImageTypes.imagen, label: opcion1, value: ImageTypes.imagen },
    { id: ImageTypes.logo, label: opcion2, value: ImageTypes.logo },
  ];
  const [chartType, setChartType] = useState(typeOptions[0]);

  function isImage(url: any) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;
    const file1: File = (target.files as FileList)[0];

    if (e && file1) {
      action(file1, type);

      setFilePlace(URL.createObjectURL(file1));
      setFileNamePdf(file1.name);

      if (isImage(file1.name) === true) {
        setItsImage(true);
      } else {
        setItsImage(false);
      }
    }
  };

  useEffect(() => {
    const quitImagePreview = (): void => {
      if (!fileNamePdf) {
        setFilePlace('');
      }
    };

    quitImagePreview();
  }, [fileNamePdf]);

  return (
    <div className={styles['image-file']}>
      <input
        type="file"
        accept={type === 'all' ? 'image/*,application/pdf' : 'image/*'}
        className={styles['image-file-input']}
        id={id}
        onChange={handleChange}
      />
      <label className={styles['image-file-label']} htmlFor={id}>
        <div className={styles['image-placeholder']}>
          <div className={cx(styles['icon-wrapper'], className)}>
            <div className={styles['icon-wrapp']}>{icon}</div>
            {filePlace &&
              (itsImage === true ? (
                <div className={styles['img-preview']}>
                  <img id="imagenSelecionada" src={filePlace} alt="imageToUpload" />
                </div>
              ) : null)}
          </div>
          <div className={styles['text-placeholder']}>
            <div className={styles['placeholder-label']}>{label}</div>
            <div className={styles['placeholder-note']}>{note}</div>
            {fileNamePdf && <div className={styles['placeholder-picked']}>{fileNamePdf}</div>}

            {/* Agregar el selector "Logo o Imagen" */}
            <div className={styles.prueba}>
              <DropdownBase
                fullWidth
                label={tituloSeleccion}
                value={chartType}
                onChange={(element: any) => {
                  setChartType(element);
                  mostrar(element.id);
                }}
                values={typeOptions}
              />
            </div>
          </div>
        </div>
      </label>
    </div>
  );
};

export default React.forwardRef(ImageInputWithOptions); // Forward the ref
