import { Serializable } from 'types/interfaces';

export interface MeasuringEquipmentDTO {
  datos: {
    medida: string | null;
    numeroDeContador: number | null;
    numeroDeModem: number | null;
    propiedad: string | null;
    telegestionado: number | null;
    tipoDeMedicion: number | null;
  };
  habilitado: string;
}

export default class MeasuringEquipmentObject implements Serializable<MeasuringEquipmentObject> {
  datos: {
    medida: string | null;
    numeroDeContador: number | null;
    numeroDeModem: number | null;
    propiedad: string | null;
    telegestionado: number | null;
    tipoDeMedicion: number | null;
  };

  habilitado: string;

  deserialize(input: MeasuringEquipmentDTO): MeasuringEquipmentObject {
    if (!input) return this;
    this.datos = {
      medida: input.datos.medida || null,
      numeroDeContador: input.datos.numeroDeContador || null,
      numeroDeModem: input.datos.numeroDeModem || null,
      propiedad: input.datos.propiedad || null,
      telegestionado: input.datos.telegestionado || null,
      tipoDeMedicion: input.datos.tipoDeMedicion || null,
    };
    return this;
  }
}
