import React, { useState } from 'react';
import { InvoiceObj, LineI } from 'types/models';
import { I18n } from 'core';
import moment from 'moment';
import { ReactComponent as DownloadIcon } from 'assets/icons/Download.svg';
import { ReactComponent as ElecsumProIcon } from 'assets/icons/Elecsum100.svg';
import { Colors } from 'styles';
import { FormatNumberService, invoiceService } from 'services';
import SyncLoader from 'react-spinners/ClipLoader';
import cx from 'classnames';
import { ReactComponent as TickIcon } from 'assets/icons/Tick.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/Clock.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/Close.svg';

import styles from './InvoiceCard.module.scss';

interface InvoiceCardPropsI {
  invoice: InvoiceObj;
}

const InvoiceCard = (props: InvoiceCardPropsI): JSX.Element => {
  const { invoice } = props;
  const language = I18n.currentLocale();
  const [loading, setLoading] = useState(false);

  const lineLanguage = (line: LineI): string => {
    if (language.substring(0, 2) === 'ca') {
      return line.concept.ca_ES;
    }
    return line.concept.es_ES;
  };

  const handleInvoiceClick = (invoiceNumber: number) => {
    setLoading(true);

    invoiceService.downloadInvoice(invoiceNumber).then((response: any) => {
      const data = response.data.datos.base64;
      const a = document.createElement('a');
      a.href = `data:application/octet-stream;base64,${data}`;
      a.download = `${invoice.date.split('-').join('')}_${invoice.invoiceNumber}.pdf`;
      a.click();
      setLoading(false);
    });

    invoiceService.downloadInvoiceContabilidad(invoiceNumber).then((response: any) => {
      const data = response.data.datos.base64;
      const a = document.createElement('a');
      a.href = `data:application/octet-stream;base64,${data}`;
      a.download = `${invoice.date.split('-').join('')}_${invoice.invoiceNumber}.pdf`;
      a.click();
      setLoading(false);
    });
  };

  const getStatusStamp = (): JSX.Element | null => {
    if (!invoice.estado) {
      return null;
    }
    const ICON_SIZE = 15;
    return (
      <div
        className={cx(styles.stamp, {
          [styles.pagada]: invoice.estado === 'pagada',
          [styles.no_pagada]: invoice.estado === 'impagada',
          [styles.pendent]: invoice.estado === 'pendiente',
        })}
      >
        {invoice.estado === 'pagada' && (
          <TickIcon height={ICON_SIZE} width={ICON_SIZE} fill={Colors.COLOR_ELECSUMGREEN} />
        )}
        {invoice.estado === 'pendiente' && (
          <ClockIcon height={ICON_SIZE} width={ICON_SIZE} fill={Colors.COLOR_GRAY_600} />
        )}
        {invoice.estado === 'impagada' && <CloseIcon height={ICON_SIZE} width={ICON_SIZE} fill={Colors.COLOR_ERROR} />}
        <span>{I18n.t(`InvoiceCard.status.${invoice.estado}`)}</span>
      </div>
    );
  };

  return (
    <div className={styles.invoice_wrapper} key={invoice.invoiceNumber}>
      <div className={styles.resume}>
        <div className={styles.invoice_period_wrapper}>
          <div className={styles.period}>
            <span>{`${I18n.t('period')}:`}</span>
            <p className={styles.date}>
              {`${moment(invoice.periodInvoicedStart).format('l')} - ${moment(invoice.periodInvoicedEnd).format('l')}`}
            </p>
          </div>
          <div className={styles.logo}>
            <ElecsumProIcon height={30} width={90} fill={Colors.COLOR_ELECSUMGREEN} />
          </div>
        </div>
        <div className={styles.invoice_date_status}>
          <div className={styles.invoice_date_wrapper}>
            <p className={styles.date_label}>{`${I18n.t('invoice_date')}:`}</p>
            <p className={styles.date}>{invoice.date}</p>
          </div>
          <div className={styles.invoice_status_wrapper}>{getStatusStamp()}</div>
        </div>
        <div className={styles.invoice_number_wrapper}>
          <p className={styles.number_label}>{`${I18n.t('invoice_number')}: `}</p>
          <p className={styles.number}>{invoice.invoiceNumber}</p>
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.concepts}>
        {invoice.lines.map((line) => (
          <div key={line.lineNumber} className={styles.concept}>
            <p className={styles.data}>{lineLanguage(line)}</p>
            <p className={styles.value}>{FormatNumberService.formatTwoDecimal(line.cost)}€</p>
          </div>
        ))}
      </div>
      <>
        <div className={styles.divider} />
        <div className={styles.footer_invoice}>
          <p className={styles.total}>{I18n.t('invoiceTotal')}</p>
          <p className={styles.value}>{new Intl.NumberFormat('de-DE').format(invoice.totalCost)}€</p>
        </div>
        <div className={styles.download}>
          <button type="button" onClick={() => handleInvoiceClick(invoice.invoiceNumber)}>
            <p>{I18n.t('downloadInvoice')}</p>
            {loading ? (
              <SyncLoader color="#98c832" size={25} />
            ) : (
              <DownloadIcon height={25} width={25} fill={Colors.COLOR_ELECSUMGREEN} />
            )}
          </button>
        </div>
      </>
    </div>
  );
};

export default React.memo(InvoiceCard);
