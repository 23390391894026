import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchCommunity, fetchCommunitySuccess } from 'redux/actions/communities-actions';
import { CommunitiesService } from 'services/remote/communities-service';
import { ProcessStatus } from 'types/enums';
import { GlobalStoreState } from 'types/state';

const ComunidadesDetalleAnalyticsPage = (): JSX.Element => {
  const { communityID } = useParams<{ communityID: string }>();
  const dispatch = useDispatch();
  const communitiesState = useSelector((state: GlobalStoreState) => state.communities);
  const { selectedCommunity, selectedCommunityStatus } = communitiesState;

  useEffect(() => {
    if (!communityID) return;
    dispatch(fetchCommunity());
    new CommunitiesService().getCommunity(communityID).then((response) => {
      dispatch(fetchCommunitySuccess(response));
    });
  }, []);

  if (selectedCommunityStatus === ProcessStatus.LOADING) return <div>Loading</div>;
  return (
    <div>
      <p>{JSON.stringify(selectedCommunity)}</p>
    </div>
  );
};

export { ComunidadesDetalleAnalyticsPage };
