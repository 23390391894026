import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import React, { createRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CommunityCustomOnboarding,
  CustomizableImage,
  CustomizableImageType,
  CUSTOMIZABLE_IMAGE_TYPE_VALUES,
} from 'types/models';
import { I18n } from 'core';
import { navigate } from 'navigation';
import { toast } from 'react-toastify';
import { CommunityCustomOnboardingServiceInstance } from 'services';
import { LoadingButton } from 'components';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import Button from 'components/Button/Button';
import customToast from 'components/CustomToast/CustomToast';

import { useFetchCommunity } from 'hooks';
import styles from './comunidades-custom-onboarding-process-page.module.scss';
import { ComunidadesCustomOnboardingForm, ComunidadesCustomOnboardingImageForm } from './components';

interface CustomImagesFormFieldsI {
  imageFormTitle: string;
  addImageText: string;
  imageNote: string;
  buttonText: string;
  imageType: CustomizableImageType;
  images: CustomizableImage[];
}

export type SaveDataHandle = {
  getData: () => void;
};

export const ComunidadesCustomOnboardingProcessPageOnboarding = (): JSX.Element => {
  const { communityID } = useParams<{ communityID: string }>();

  const [loading, setLoading] = useState<boolean>(false);
  const [communityCustomOnboarding, setCommunityCustomOnboarding] = useState<CommunityCustomOnboarding | undefined>(
    undefined,
  );

  const [customImagesFormFields, setCustomImagesFormFields] = useState<CustomImagesFormFieldsI[]>([]);
  const customOnboardingRef = createRef<SaveDataHandle>();
  // const customOnboardingRefFaqs = createRef<SaveDataHandle>();
  // const customOnboardingRefipoDeContacto = createRef<SaveDataHandle>();
  const getImageInputTexts = (
    imageType: CustomizableImageType,
  ): { imageFormTitle: string; addImageText: string; imageNote: string; buttonText: string } => {
    switch (imageType) {
      case CustomizableImageType.LOGO:
        return {
          imageFormTitle: I18n.t('ComunidadesCustomOnboardingProcess.form.logo.title'),
          addImageText: I18n.t('ComunidadesCustomOnboardingProcess.form.logo.text'),
          imageNote: I18n.t('ComunidadesCustomOnboardingProcess.form.logo.note'),
          buttonText: I18n.t('ComunidadesCustomOnboardingProcess.form.logo.button'),
        };
      case CustomizableImageType.IMAGE_STEP_01:
        return {
          imageFormTitle: I18n.t('ComunidadesCustomOnboardingProcess.form.image01.title'),
          addImageText: I18n.t('ComunidadesCustomOnboardingProcess.form.image01.text'),
          imageNote: I18n.t('ComunidadesCustomOnboardingProcess.form.image01.note'),
          buttonText: I18n.t('ComunidadesCustomOnboardingProcess.form.image01.button'),
        };
      case CustomizableImageType.IMAGE_STEP_02:
        return {
          imageFormTitle: I18n.t('ComunidadesCustomOnboardingProcess.form.image02.title'),
          addImageText: I18n.t('ComunidadesCustomOnboardingProcess.form.image02.text'),
          imageNote: I18n.t('ComunidadesCustomOnboardingProcess.form.image02.note'),
          buttonText: I18n.t('ComunidadesCustomOnboardingProcess.form.image02.button'),
        };
      case CustomizableImageType.IMAGE_STEP_03:
        return {
          imageFormTitle: I18n.t('ComunidadesCustomOnboardingProcess.form.image03.title'),
          addImageText: I18n.t('ComunidadesCustomOnboardingProcess.form.image03.text'),
          imageNote: I18n.t('ComunidadesCustomOnboardingProcess.form.image03.note'),
          buttonText: I18n.t('ComunidadesCustomOnboardingProcess.form.image03.button'),
        };
      case CustomizableImageType.IMAGE_STEP_04:
        return {
          imageFormTitle: I18n.t('ComunidadesCustomOnboardingProcess.form.image04.title'),
          addImageText: I18n.t('ComunidadesCustomOnboardingProcess.form.image04.text'),
          imageNote: I18n.t('ComunidadesCustomOnboardingProcess.form.image04.note'),
          buttonText: I18n.t('ComunidadesCustomOnboardingProcess.form.image04.button'),
        };
      case CustomizableImageType.IMAGE_STEP_05:
        return {
          imageFormTitle: I18n.t('ComunidadesCustomOnboardingProcess.form.image05.title'),
          addImageText: I18n.t('ComunidadesCustomOnboardingProcess.form.image05.text'),
          imageNote: I18n.t('ComunidadesCustomOnboardingProcess.form.image05.note'),
          buttonText: I18n.t('ComunidadesCustomOnboardingProcess.form.image05.button'),
        };
      default:
        return { imageFormTitle: ' ', addImageText: ' ', imageNote: ' ', buttonText: ' ' };
    }
  };

  const getCustomOnboardingData = async (id: string): Promise<void> => {
    setLoading(true);
    try {
      const customOnboarding = await CommunityCustomOnboardingServiceInstance.getCommunityCustomOnboarding(id);
      if (customOnboarding) {
        setCommunityCustomOnboarding(customOnboarding);

        const imageForms = CUSTOMIZABLE_IMAGE_TYPE_VALUES.map(
          (imageType: CustomizableImageType): CustomImagesFormFieldsI => {
            const images = customOnboarding.imagenes.filter((imagen) => imagen.tipo === imageType);
            const { imageFormTitle, addImageText, imageNote, buttonText } = getImageInputTexts(imageType);
            return {
              imageFormTitle,
              addImageText,
              imageNote,
              buttonText,
              imageType,
              images,
            };
          },
        );
        setCustomImagesFormFields(imageForms);
      }
    } catch (error) {
      toast.error(I18n.t('ComunidadesCustomOnboardingProcess.errors.errorGettingCustomOnboardingData'));
    } finally {
      setLoading(false);
    }
  };

  const fetchCommunityData = useFetchCommunity(communityID);

  useEffect(() => {
    fetchCommunityData();
  }, [fetchCommunityData]);

  useEffect(() => {
    if (!communityID) return;
    getCustomOnboardingData(communityID);
  }, []);

  const updateCustomOnboarding = async (communityCustomOnboardingUpdate: CommunityCustomOnboarding): Promise<void> => {
    const update = communityCustomOnboardingUpdate.serialize();

    try {
      await CommunityCustomOnboardingServiceInstance.updateCommunityCustomOnboarding(communityID, update);

      customToast.success(I18n.t('ComunidadesCustomOnboardingProcess.updateSuccess'));
      getCustomOnboardingData(communityID);
    } catch (error) {
      toast.error(I18n.t('ComunidadesCustomOnboardingProcess.errors.updating'));
    }
  };

  if (loading) return <FullPageSyncLoader icon />;
  return (
    <div className={styles.container}>
      <SectionWrapper>
        <>
          {communityCustomOnboarding && (
            <ComunidadesCustomOnboardingForm
              ref={customOnboardingRef}
              communityId={communityID}
              communityCustomOnboarding={communityCustomOnboarding}
              saveDetails={(customOnboarding) => updateCustomOnboarding(customOnboarding)}
            />
          )}
        </>

        <div className={styles.positionBotonoes}>
          <div className={styles.divBoton}>
            <Button
              type="button"
              onClick={(): Promise<void> => navigate('/tecnic/communities')}
              variant="secondary"
              text={I18n.t('Cancel')}
            />
            <LoadingButton type="submit" text={I18n.t('Save')} onClick={() => customOnboardingRef.current?.getData()} />
          </div>
        </div>

        <div className={styles.margenBotImagen}>
          {communityCustomOnboarding &&
            customImagesFormFields.map((imageFormField) => (
              <ComunidadesCustomOnboardingImageForm
                key={imageFormField.imageType}
                communitiyId={communityID}
                images={imageFormField.images}
                afterSubmitAction={getCustomOnboardingData}
                imageType={imageFormField.imageType}
                imageFormTitle={imageFormField.imageFormTitle}
                addImageText={imageFormField.addImageText}
                imageNote={imageFormField.imageNote}
                buttonText={imageFormField.buttonText}
              />
            ))}
        </div>
      </SectionWrapper>
    </div>
  );
};
