import React from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import styles from './search-input.module.scss';

export const SearchInput = (props: React.InputHTMLAttributes<HTMLInputElement>): JSX.Element => (
  <div className={styles.container}>
    <input type="text" {...props} />
    <SearchIcon />
  </div>
);
