import React from 'react';

import styles from './Footer.module.scss';

function Footer(): JSX.Element {
  return (
    <footer id="sticky-footer" className={styles.root}>
      <div className={styles.info}>
        <small>&copy; {new Date().getFullYear()} - Elecsum Fotovoltaica SL</small>
      </div>
    </footer>
  );
}

export default Footer;
