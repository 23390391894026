import { Card } from 'components';
import React from 'react';
import { I18n } from 'core';
import { t } from 'i18n-js';
import { useHistory } from 'react-router-dom';
import styles from './matcher-dashboard-widget.module.scss';

export const MatcherDashboardWidget = (): JSX.Element => {
  const i18nPrefix = `DashboardPage.MatcherDashboardWidget`;
  const history = useHistory();

  return (
    <Card
      title={I18n.t(`${i18nPrefix}.title`)}
      body={[
        <div className={styles.root}>
          <div className={styles.section}>
            <p className={styles.label}>{t(`${i18nPrefix}.pending`)}</p>
            <button
              className={styles.viewmore_button}
              type="button"
              onClick={() => {
                history.push('/tecnic/matcher');
              }}
            >
              {I18n.t(`${i18nPrefix}.viewmore`)}
            </button>
          </div>
        </div>,
      ]}
    />
  );
};
