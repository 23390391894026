import React from 'react';

import { Header, Sidenav } from 'components';
import { RoutesInterface } from 'types/interfaces';
import styles from './BaseLayout.module.scss';

const BaseLayout = (props: RoutesInterface): JSX.Element | null => {
  const { component: Component } = props;

  return (
    <>
      <Header />
      <div className={styles.root}>
        <div className={styles.sidenav}>
          <Sidenav />
        </div>
        <main role="main" className={styles.main}>
          {Component ? <Component {...props} /> : null}
        </main>
      </div>
    </>
  );
};
export default BaseLayout;
