import React from 'react';
import classNames from 'classnames';
import styles from './SectionTitle.module.scss';

export interface SectionTitleProps {
  text: string;
  className?: string;
}

const SectionTitle = (props: SectionTitleProps): JSX.Element => {
  const { text, className = '' } = props;

  return <div className={classNames(className, styles.root)}>{text}</div>;
};

export default SectionTitle;
