import React, { useEffect, useState } from 'react';
import { LoadingButton, SectionTitle, Spinner } from 'components';
import Button from 'components/Button/Button';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { I18n } from 'core';
import { navigate } from 'navigation';
import { ReactComponent as DisabledIcon } from 'assets/icons/Disabled.svg';
import { Colors } from 'styles';
import usersService from 'services/remote/UsersService';
import { UserInfoObject } from 'types/models';
import { authServiceInstance } from 'services';
import styles from './UsersPage.module.scss';

const UsersPage = (): JSX.Element => {
  const [usersData, setUsersData] = useState<Array<UserInfoObject> | null>(null);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  useEffect(() => {
    usersService.getUsers().then((response) => {
      setUsersData(response);
    });
  }, []);

  const headers = ['nif', 'alias', 'email', 'tipoDeUsuario', 'apps'];

  const handleClick = (usr: UserInfoObject) => {
    navigate(`/tecnic/user/${usr.nif}/data`);
  };
  const handleExport = () => {
    setLoadingExport(true);
    usersService.exportUsersData().then((response) => {
      const { data } = response;
      const a = document.createElement('a');
      a.href = `data:text/csv;charset=utf-8,${data}`;
      a.textContent = 'download';
      a.download = `${new Date().toLocaleDateString()}_Usuarios.csv`;
      a.click();
      // a.remove();
      setLoadingExport(false);
    });
  };
  const isAdmin = authServiceInstance.isAdmin();
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Button
          className={styles.viewmore_button}
          variant="tertiary"
          type="button"
          onClick={(): Promise<void> => navigate('/tecnic/dashboard')}
          text={I18n.t('UsersPage.goDashboard')}
        />
      </div>
      <SectionWrapper>
        <div className={styles.titleWrapper}>
          <SectionTitle text={I18n.t('UsersPage.tableTitle')} />

          {isAdmin ? (
            <div className={styles.loadingButtonFlex}>
              <LoadingButton
                className={styles.loadingButtonAddUser}
                type="submit"
                text={I18n.t('UsersPage.gestionRols')}
                onClick={(): Promise<void> => navigate('/tecnic/roles')}
              />
              <LoadingButton
                className={styles.loadingButtonAddUser}
                type="submit"
                text={I18n.t('UsersPage.userLabel')}
                onClick={(): Promise<void> => navigate('/tecnic/adduser')}
              />

              <LoadingButton
                className={styles.loadingButton}
                type="button"
                loading={loadingExport}
                onClick={() => handleExport()}
                text={I18n.t('UsersPage.csvLabel')}
              />
            </div>
          ) : null}
        </div>
        <div className={styles.table_wrapper}>
          {(() => {
            if (usersData) {
              if (usersData.length > 0) {
                return (
                  <table className={styles.table}>
                    <thead>
                      <tr>
                        {headers.map((header) => (
                          <th>{I18n.t(`UsersPage.tableHeaders.${header}`)}</th>
                        ))}
                        <th className={styles.status} aria-label="status" />
                      </tr>
                    </thead>
                    <tbody>
                      {usersData.map((user) => (
                        <tr onClick={() => handleClick(user)}>
                          <td>{user.nif}</td>
                          <td>{`${user.nombre} ${user.apellidos}`}</td>
                          <td>{user.email}</td>
                          <td className={styles.sistemas}>{user.tipoDeUsuario}</td>
                          <td className={styles.sistemas}>
                            {(() => {
                              const values = user.dispositivos.map((item) => item.sistemaOperativo);

                              const unique = values.filter((item, pos) => values.indexOf(item) === pos);
                              return (
                                <span>
                                  {unique.map(
                                    (item, index) => `${index === 0 && unique.length > 0 ? '' : ' - '} ${item} `,
                                  )}
                                </span>
                              );
                            })()}
                          </td>
                          <td className={styles.status}>
                            {user.activo === 'no' ? (
                              <DisabledIcon height={16} width={16} fill={Colors.COLOR_GRAY_200} />
                            ) : null}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                );
              }
              return <span>No data</span>;
            }
            return (
              <div className={styles.loading_wrapper}>
                <Spinner icon />
              </div>
            );
          })()}
        </div>
      </SectionWrapper>
    </div>
  );
};

export default UsersPage;
