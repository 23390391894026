/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useRef, useState } from 'react';
import cx from 'classnames';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/TriangleUp.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/TriangleDown.svg';
import { Colors } from 'styles';
import { useOutsideClick } from 'hooks';
import PromotorInfo from 'types/models/promotorInfo';
import styles from './PromotorDropDown.module.scss';

interface PromotorDropDownPropsI {
  items: Array<PromotorInfo>;
  selected: PromotorInfo | null;
  onOptionChange: CallableFunction;
}

const PromotorDropDown = (props: PromotorDropDownPropsI): JSX.Element => {
  // Props
  const { selected, items, onOptionChange } = props;
  // Refs
  const dropdownRef = useRef(null);
  // State
  const [show, setShow] = useState(false);

  const toggleDropdown = () => {
    setShow(!show);
  };

  useOutsideClick(dropdownRef, () => {
    setShow(false);
  });

  const handleChange = (value: PromotorInfo | null) => {
    onOptionChange(value);
    setShow(false);
  };

  return (
    <div ref={dropdownRef} className={styles.root}>
      <button onClick={() => toggleDropdown()} type="button" className={cx(styles.header, { [styles.show]: show })}>
        <span className={styles.value}>{selected ? selected.nombre : 'Global'}</span>
        <span className={styles.icon}>
          {show ? (
            <ArrowDownIcon height={16} width={16} fill={Colors.COLOR_GRAY_200} />
          ) : (
            <ArrowUpIcon height={16} width={16} fill={Colors.COLOR_GRAY_200} />
          )}
        </span>
      </button>
      <ul className={cx(styles.items, { [styles.show]: show })}>
        <li className={styles.item}>
          <input
            className="form-check-input"
            type="radio"
            name="exampleRadios"
            id="global"
            checked={selected === null}
            onClick={() => handleChange(null)}
          />
          <label
            className={cx({
              [styles.selected]: selected === null,
            })}
            htmlFor="global"
          >
            Global
          </label>
        </li>
        {items.map((client) => (
          <li role="button" className={styles.item} key={client.cau}>
            <input
              onClick={() => handleChange(client)}
              className="form-check-input"
              type="radio"
              name="client"
              id={client.cau}
              checked={selected?.cau === client.cau}
            />
            <label
              className={cx({
                [styles.selected]: selected?.cau === client.cau,
              })}
              htmlFor={client.cau}
            >
              {client.nombre}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default React.memo(PromotorDropDown);
