import React, { Dispatch, SetStateAction } from 'react';

import { I18n } from 'core';
import cx from 'classnames';
import styles from './FreqDataChartPicker.module.scss';

const NAV_HISTORIC = 'historic';
const NAV_YEAR = 'year';
const NAV_MONTH = 'month';
const NAV_DAY = 'day';

interface SelectorPropsI {
  name: string;
  currentTab: string;
  changeCurrentTab: () => void;
  value: string;
}

const Selector = (props: SelectorPropsI): JSX.Element => {
  const { name, currentTab, value, changeCurrentTab } = props;

  const toogleCurrentTab = (): void => {
    changeCurrentTab();
  };

  return (
    <>
      <button
        onClick={toogleCurrentTab}
        type="button"
        className={cx(styles.item, { [styles.active]: currentTab === value })}
      >
        {I18n.t(name)}
      </button>
    </>
  );
};
interface FreqDataChartPickerPropsI {
  dateComponent: JSX.Element;
  currentTab: string;
  setCurrentTabFunction: Dispatch<SetStateAction<string>>;
}

const FreqDataChartPicker = (props: FreqDataChartPickerPropsI): JSX.Element => {
  const { dateComponent, currentTab, setCurrentTabFunction } = props;
  return (
    <div className={styles.root}>
      <div className={styles.options}>
        <Selector
          changeCurrentTab={(): void => setCurrentTabFunction(NAV_DAY)}
          name="day"
          value={NAV_DAY}
          currentTab={currentTab}
        />
        <Selector
          changeCurrentTab={(): void => setCurrentTabFunction(NAV_MONTH)}
          name="month"
          value={NAV_MONTH}
          currentTab={currentTab}
        />
        <Selector
          changeCurrentTab={(): void => setCurrentTabFunction(NAV_YEAR)}
          name="year"
          value={NAV_YEAR}
          currentTab={currentTab}
        />
        <Selector
          changeCurrentTab={(): void => setCurrentTabFunction(NAV_HISTORIC)}
          name="historic"
          value={NAV_HISTORIC}
          currentTab={currentTab}
        />
      </div>
      <div className={styles.value}>{dateComponent}</div>
    </div>
  );
};

export default FreqDataChartPicker;
