import { CommunityEnrolledAPII } from 'types/models/communityEnrolled';

export const COMMUNITIES_ENROLLED_MOCK: Array<CommunityEnrolledAPII> = [
  {
    id: 111,
    nombre: 'Jaia Mena',
    community: 'Elecsum Energy',
  },
  {
    id: 222,
    nombre: 'Edna Mena',
    community: 'Sitges Solar',
  },
  {
    id: 333,
    nombre: 'Maria Calvo',
    community: 'Ripoll Community',
  },
];
