/* eslint-disable no-return-assign */
import React, { useEffect, useRef, useState } from 'react';

import { SectionTitle, LoadingButton, Spinner, Toggle, SectionSubTitle } from 'components';
import Button from 'components/Button/Button';
import ButtonCsv from 'components/ButtonCsv/ButtonCsv';
// import { DateInterval } from 'components/DateIntervalPicker/DateIntervalPicker';
import { FormGroupProps, FormGroup } from 'core';
import I18n from 'i18n-js';
// import moment from 'moment';
import { YearPickerWithMinYear } from 'components/Input';
import CommunitiesServiceIntance from 'services/remote/communities-service';
import { CommunityModel } from 'types/models/community';
import { ElecsumComunidadSelect, RoundedButton } from 'components/NewInputs';

import { InscritosModel } from 'types/models/inscritos';
import { ConfiguracionMatcherAPI } from 'types/models/ConfiguracionMatcher';
import MatcherConfiguracionInstance from 'services/remote/MatcherConfiguracionService';
import ConfiguracionMiembro from 'types/models/configuracionMiembrosMatcher';
import { useToggle } from 'hooks/useToogle';
import ModalMatcher from 'components/ModalMatcher/ModalMatcher';
import customToast from 'components/CustomToast/CustomToast';
import MatcherConfiguracionMiembrosInstance from 'services/remote/MatcherConfiguracionMiembrosService';
import { toast } from 'react-toastify';
import { WattsConverterService } from 'services';
import { AxiosError } from 'axios';
// import { useIsFirstRender } from 'hooks';
import InscritosServicentance from 'services/remote/Inscritos';
import CupsTooltipOferta from 'pages/SignUpPage/components/CupsTooltip/CupsTooltipOferta';
import CupsTooltipInputMatcher from 'pages/SignUpPage/components/CupsTooltip/CupsTooltipInputMatcher';
import configuration from 'config';
import MatcherPerfilesConsumoInstance from 'services/remote/MatcherPerfilesConsumoService';
import ElecsumSelect, { SelectItemI } from 'components/NewInputs/Select/ElecsumSelect';
import stylesDropdown from './matcher-dropdown.module.scss';
import styles from '../../AddMatcherPage.module.scss';
import EditMemberPopUp from './EditMemberPopUp';
import TableMatcherWithFilter from '../Table/TableMatcherWithFilter';
import GraficoCurvaConsumo from './graficCurvaConsumo';

interface AddConfiguracionProps {
  onUpdateModal: () => void;
  onLoadComunidades: () => void;
  onLoadConsumo: () => void;
  onUpdateModalLoadCSV: () => void;
  configuracionDetalle: ConfiguracionMatcherAPI;
  setSelectedComunidadDad: React.Dispatch<React.SetStateAction<CommunityModel | undefined>>;
  communitiesEnrolled: InscritosModel[];
  onChange: CallableFunction;
  getConfiguracionMiembros: ConfiguracionMiembro[];
  onChangeTable: CallableFunction;
  onChangeUpdate: CallableFunction;
  updateDate: CallableFunction;
  date: {
    day: number;
    month: number;
    year: number;
  };
  añoSeleccionado: number;
}

const AddConfiguracion = ({
  onUpdateModal,
  onLoadComunidades,
  onLoadConsumo,
  onUpdateModalLoadCSV,
  configuracionDetalle,
  setSelectedComunidadDad,
  getConfiguracionMiembros,
  onChange,
  onChangeTable,
  onChangeUpdate,
  updateDate, // comentado para no actualizar fecha
  date,
  añoSeleccionado,
}: AddConfiguracionProps) => {
  const [input, setInputs] = useState({
    nombre: configuracionDetalle.nombre || '',
    betaMax: configuracionDetalle.betaMax || 0,
    betaMin: configuracionDetalle.betaMin || 0,
    metodoDeCalculos: configuracionDetalle.metodoDeCalculos || '',
    step: configuracionDetalle.step || 0,
  });
  const [isSwitchOn, toggleSwitch] = useToggle();
  const [isEdit, toggleEdit] = useToggle();

  // const isFirst = useIsFirstRender();

  const [loadingSelect, setLoadingSelect] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const [comunidadCsv, setComunidadCsv] = useState<File | undefined>();
  const [miembroCSV, setMiembroCSV] = useState<File | undefined>();

  const [isActive, setIsActive] = useState(false);
  const [selectedComunidad, setSelectedComunidad] = useState<CommunityModel | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [communities, setCommunities] = useState<CommunityModel[]>([]);
  // seran los datos de los miembros de la configuracion
  // const [configuraciones, setConfiguraciones] = useState<Array<ConfiguracionMatcher>>([]);
  // const [dateInterval, setDateInterval] = useState<DateInterval>({
  //   start: moment().subtract(1, 'year'),
  //   end: moment(),
  // });

  const [mensajeError, setMensajeError] = useState({
    codigo: '',
    mensaje: '',
  });

  const [mensajeErrorMiembro, setMensajeErrorMiembro] = useState({
    codigo: '',
    mensaje: '',
  });
  const [isGraphModalOpen, setIsGraphModalOpen] = useState<boolean[]>([]); // Estado para controlar cuándo mostrar el modal de gráficos

  const [perfilesDeConsumo, setPerfilesDeConsumo] = useState<Array<SelectItemI>>([]);
  const [selectedPerfilConsumo, setSelectedPerfilConsumo] = useState<SelectItemI>();
  const [isOpen, setIsOpen] = useState<boolean[]>([]); // Arreglo para controlar la apertura/cierre de los dropdowns
  const [isOpenModal, setIsOpenModal] = useState<boolean[]>([]); // Arreglo para controlar la apertura/cierre de los dropdowns
  const [isOpenDelete, setIsOpenDelete] = useState<boolean[]>([]); // Arreglo para controlar la apertura/cierre de los dropdowns

  const dropdownRefs = useRef<(HTMLElement | null)[]>([]); // Arreglo de referencias para los dropdowns
  const [configuracionSelected, setConfiguracionSelected] = useState<ConfiguracionMiembro>();

  const [selectedOptionMax, setSelectedOptionMax] = useState(configuracionDetalle.maximizarMiembros === 'si');
  const [selectedOptionMin, setSelectedOptionMin] = useState(configuracionDetalle.minimizarMiembros === 'si');
  const [search, setSearch] = useState<string>('');

  // const BETAS_TYPE = [
  //   `${I18n.t('MatcherListPage.addMatcher.betamax')}`,
  //   `${I18n.t('MatcherListPage.addMatcher.betamin')}`,
  // ];

  const formGroupUserDataLeft: Array<FormGroupProps> = [
    {
      label: I18n.t('Name'),
      type: 'text',
      id: 'nombre',
      path: 'nombre',
      name: 'nombre',
      value: input.nombre,
      placeholder: I18n.t('Name'),
      tabindex: 1,
    },
  ];

  const formGroupMetodoDeCalculos: Array<FormGroupProps> = [
    {
      label: I18n.t('MatcherListPage.metodoDeCalculos'),
      type: 'select',
      id: 'metodoDeCalculos',
      path: 'metodoDeCalculos',
      name: 'metodoDeCalculos',
      placeholder: 'wwww',
      value: input.metodoDeCalculos,

      options: [
        { label: 'MatcherListPage.BETAS_AGILES', value: 'Betas ágiles' },
        { label: 'MatcherListPage.BETAS_AGILES_NORMALIZADAS', value: 'Betas ágiles con normalización' },
        { label: 'MatcherListPage.BETAS_FIJAS', value: 'Betas fijas' },
        { label: 'MatcherListPage.BETAS_FIJAS_3_TIPOS', value: 'Betas fijas 3 tipos' },
      ],
    },
  ];

  const FormFieldsInformeExcedente: Array<FormGroupProps> = [
    {
      label: I18n.t('MatcherListPage.stepDeCalculos'),
      type: 'number',
      id: 'step',
      path: 'step',
      name: 'step',
      placeholder: '0',
      value: input?.step,
    },
  ];

  // const handleChangeDate = (dates: DateInterval) => {
  //   const endDate = dates.start ? moment(dates.start).add(1, 'year') : null;

  //   setDateInterval({ start: dates.start, end: endDate });
  // };
  function handleChange(path: string, value: string): void {
    setInputs((prev) => ({
      ...prev,
      [path]: value,
    }));
  }

  const handleFileSelect = (file: File) => {
    setComunidadCsv(file);
  };
  useEffect(() => {
    if (input.nombre.length !== 0) {
      setSubmitDisabled(false);
    }
  }, [input.nombre]);

  const getCommunities = () => {
    if (search === undefined) {
      setLoadingSelect(true);
    }

    CommunitiesServiceIntance.getCommunities(search)
      .then((response) => {
        setCommunities(response.filter((a) => a.nombre !== '' && a.cau !== ''));
      })
      .finally();
  };

  useEffect(() => {
    getCommunities();
  }, [search]);

  const toggleDropdown = (index: number): void => {
    const newIsOpen = [...isOpen];
    newIsOpen[index] = !newIsOpen[index];
    setIsOpen(newIsOpen);
  };

  const toggleDropdownModal = (index: number): void => {
    const newIsOpen = [...isOpenModal];
    newIsOpen[index] = !isEdit;
    setIsOpenModal(newIsOpen);
  };

  const toggleDropdownDelete = (index: number): void => {
    const newIsOpen = [...isOpenDelete];
    newIsOpen[index] = !newIsOpen[index];
    setIsOpenDelete(newIsOpen);
  };

  const deleteConfiguracion = async (idConfiguracion: number, id: number): Promise<void> => {
    // eslint-disable-next-line no-alert
    setLoadingDelete(true);
    try {
      await MatcherConfiguracionMiembrosInstance.deleteConfiguracionMiembro(idConfiguracion, id);
      customToast.success(`${I18n.t('PerfilListPage.EliminarPerfilesConsumo_fetching')}!`);
    } catch (error) {
      toggleSwitch();
    }

    onChangeTable('DELETE');
    setLoadingDelete(false);
    toggleSwitch();
  };

  const UpdateMiembro = async (
    idConfiguracion: number,
    id: number,
    configuracionUpdate: ConfiguracionMiembro,
  ): Promise<void> => {
    setLoadingUpdate(true);
    try {
      const object = {
        apellidos: configuracionUpdate.apellidos,
        cups: configuracionUpdate.cups,
        direccionCompleta: configuracionUpdate.direccionCompleta,
        email: configuracionUpdate.email,
        id: configuracionUpdate.idInscrito,
        nif: configuracionUpdate.nif,
        nombre: configuracionUpdate.nombre,
        precioCompensacion: configuracionUpdate.precioCompensacion,
        telefono: configuracionUpdate.telefono,
        terminoEnergiaP1: configuracionUpdate.terminoEnergiaP1,
        terminoEnergiaP2: configuracionUpdate.terminoEnergiaP2,
        terminoEnergiaP3: configuracionUpdate.terminoEnergiaP3,
        terminoEnergiaP4: configuracionUpdate.terminoEnergiaP4,
        terminoEnergiaP5: configuracionUpdate.terminoEnergiaP5,
        terminoEnergiaP6: configuracionUpdate.terminoEnergiaP6,
        tipoConsumo: configuracionUpdate.tipoConsumo,
      };
      await MatcherConfiguracionMiembrosInstance.patchConfiguracionMiembro(idConfiguracion, id, configuracionUpdate);
      await InscritosServicentance.putInscritoMatcher(object as InscritosModel);

      let addCSV;
      if (miembroCSV) {
        addCSV = await MatcherConfiguracionMiembrosInstance.subirCsvConfiguracionMatcherMiembros(
          idConfiguracion,
          id,
          miembroCSV,
        );
        if (addCSV === 200) {
          toggleEdit();
          setMensajeErrorMiembro({ codigo: '', mensaje: '' });
          setMensajeErrorMiembro({ codigo: '', mensaje: '' });
        } else {
          setMensajeErrorMiembro(addCSV);
        }
      }
      if (!addCSV) {
        toggleEdit();
        onChangeTable('UPDATE FULL');
      }
    } catch (error) {
      toast.error(I18n.t('PerfilListPage.UpdatePerfilesConsumo_fetching_error'));
    } finally {
      setLoadingUpdate(false);
    }

    // cosas
  };

  const updateConfiguracion = async (): Promise<void> => {
    // Verificar si existe idComunidad, si no existe, no lanzar el patch
    const idComunidad = selectedComunidad !== undefined ? selectedComunidad.id : configuracionDetalle.idComunidad;

    if (!idComunidad) {
      return;
    }

    const PostConfiguracionMatcher: any = {
      nombre: input.nombre,
      // Mockeado por cliente
      fechaInicio: `${date.year}-01-01`,
      fechaFin: `${date.year}-12-31`,
      // fechaInicio: `2023-01-01`,
      // fechaFin: `2023-12-31`,
      idComunidad,
      betaMax: input.betaMax,
      betaMin: input.betaMin,
      minimizarMiembros: selectedOptionMax === true ? 'no' : 'si',
      maximizarMiembros: selectedOptionMax === true ? 'si' : 'no',
      metodoDeCalculos: input.metodoDeCalculos,
    };

    // Añadir 'step' solo si configuration.matcher2 no es true
    if (configuration.matcher2) {
      PostConfiguracionMatcher.step = input.step;
    }
    if (selectedPerfilConsumo) {
      if (configuration.matcher2) {
        PostConfiguracionMatcher.perfilDeConsumo = selectedPerfilConsumo.id;
      }
    }
    try {
      await MatcherConfiguracionInstance.patchConfiguracionMatcher(configuracionDetalle.id, PostConfiguracionMatcher);
      if (comunidadCsv) {
        const addCSV = await MatcherConfiguracionInstance.subirCsvConfiguracionMatcher(
          configuracionDetalle.id,
          comunidadCsv,
        );
        if (addCSV === 200) {
          onChange('Update Config');
          setMensajeError({ codigo: '', mensaje: '' });
        } else {
          setMensajeError(addCSV);
        }
      }
    } catch (error) {
      if ((error as AxiosError)?.response?.data && (error as AxiosError).response?.status === 400) {
        toast.error((error as AxiosError)?.response?.data.errores[0], {
          autoClose: 8000, // Duración de 8 segundos
        });
      } else {
        toast.error(I18n.t('Error updating report!'));
      }
    }
    setSubmitDisabled(true);
    // cosas
  };

  useEffect(() => {
    // if (!isFirst) {
    updateConfiguracion();
    //  }
  }, [input, date, selectedComunidad, selectedOptionMin, selectedOptionMax, comunidadCsv, selectedPerfilConsumo]);
  const handleDocumentClick = (event: MouseEvent) => {
    dropdownRefs.current.forEach((ref, index) => {
      if (ref && !ref.contains(event.target as Node)) {
        setIsOpen((prevIsOpen) => {
          const newIsOpen = [...prevIsOpen];
          newIsOpen[index] = false;
          return newIsOpen;
        });
      }
    });
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, true);
    return () => {
      document.removeEventListener('click', handleDocumentClick, true);
    };
  }, []);

  const TextoBetamax = [
    I18n.t('TooltipOferta.BetaMaxTitulo'),
    I18n.t('TooltipOferta.BetaMaxLista1'),
    I18n.t('TooltipOferta.BetaMaxLista2'),
    I18n.t('TooltipOferta.BetaMaxLista3'),
  ];
  const TextoBetamin = [
    I18n.t('TooltipOferta.BetaMinTitulo'),
    I18n.t('TooltipOferta.BetaMinLista1'),
    I18n.t('TooltipOferta.BetaMinLista2'),
    I18n.t('TooltipOferta.BetaMinLista3'),
  ];

  const exportInforme = async (): Promise<void> => {
    try {
      const ejecutarInforme = await MatcherConfiguracionInstance.descargarInforme(configuracionDetalle.id);
      const a = document.createElement('a');
      a.href = `data:text/csv;charset=utf-8,${ejecutarInforme}`;
      a.textContent = 'download';
      a.download = `${new Date().toLocaleDateString()}_ExportData.csv`;
      a.click();
      a.remove();
    } catch (error) {
      console.log(error);
    }
  };
  const getAllPerfiles = () => {
    MatcherPerfilesConsumoInstance.getAllPerfilesDeConsumo().then((response) => {
      const perfilesSelect = response
        .filter((perfil) => perfil.infoDeConsumo.año === añoSeleccionado)
        .map((perfil) => ({
          id: perfil.id.toString(),
          value: perfil.nombre,
          label: perfil.nombre,
        }));
      setPerfilesDeConsumo(perfilesSelect);
      setSelectedPerfilConsumo(
        perfilesSelect.find((e) => e.id === configuracionDetalle.perfilDeConsumo?.id.toString()),
      );
    });
  };
  useEffect(() => {
    getAllPerfiles();
  }, []);
  const toggleGraphModal = (index: number): void => {
    const newIsGraphModalOpen = [...isGraphModalOpen];
    newIsGraphModalOpen[index] = !newIsGraphModalOpen[index];
    setIsGraphModalOpen(newIsGraphModalOpen);
  };
  const handleCurvaConsumoClick = (miembro: ConfiguracionMiembro) => {
    setConfiguracionSelected(miembro); // Selecciona el miembro actual
    toggleGraphModal(miembro.id); // Cambia el estado para abrir el modal de gráficos
  };

  return (
    <div>
      <div className={styles.flexContainer}>
        <div className={styles.containerForms}>
          <div className={styles.containerForm}>
            {formGroupUserDataLeft.map((formGroup: FormGroupProps) => (
              <div key={formGroup.id} style={{ width: '100%' }}>
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  placeholder={formGroup.placeholder}
                  value={formGroup.value}
                  onChange={handleChange}
                  options={formGroup.options}
                  disabled={formGroup.disabled}
                  tabindex={formGroup.tabindex}
                  error={formGroup.error}
                />
              </div>
            ))}
            <div style={{ width: '100%' }}>
              {loadingSelect ? (
                <div className={styles.loading}>
                  <Spinner size={100} icon />
                </div>
              ) : (
                <ElecsumComunidadSelect
                  disabled={!!configuracionDetalle.idComunidad || selectedComunidad !== undefined}
                  minWidth="15rem"
                  values={communities}
                  defaultSelect={configuracionDetalle.idComunidad}
                  selected={selectedComunidad}
                  label={
                    configuracionDetalle.nombreComunidad
                      ? configuracionDetalle.nombreComunidad
                      : I18n.t('MatcherListPage.addMatcher.comunidad')
                  }
                  search={setSearch}
                  onChange={(el) => {
                    setSelectedComunidad(el);
                    setSelectedComunidadDad(el);
                    // getInsncritos(el.id.toString());
                    setSearch('');
                  }}
                />
              )}
            </div>
          </div>
          <div className={styles.containerGeneracio}>
            {configuracionDetalle.infoDeGeneracion.generacionAnual === 0 ? (
              <>
                <SectionTitle text={I18n.t('MatcherListPage.addMatcher.generacio')} />
                <div className={styles.divToooltip}>
                  <CupsTooltipOferta title={I18n.t('MatcherListPage.addMatcher.generacioDesc')} />
                </div>
                <ButtonCsv className={styles.csv_button} onFileSelect={handleFileSelect} />

                {mensajeError.codigo === '' ? null : (
                  <div className={styles.errorMessage}>
                    <p>{I18n.t(mensajeError.codigo)}</p>
                    <p>{mensajeError.mensaje}</p>
                  </div>
                )}
              </>
            ) : (
              <>
                <>
                  <div className={styles.toogle_wrapper}>
                    <p>{I18n.t('MatcherListPage.addMatcher.generacioDescExist')}</p>
                    <Toggle value={isActive} onChange={setIsActive} />
                  </div>

                  <div className={styles.datosPerfilConsumo}>
                    <p className={styles.año}>{`${I18n.t('Year')} : ${configuracionDetalle.infoDeGeneracion.año}`}</p>
                    <p className={styles.consumoAnual}>
                      {`${I18n.t('MatcherListPage.addMatcher.generacioSolar')} : ${
                        WattsConverterService.convertWatts(configuracionDetalle.infoDeGeneracion.generacionAnual).num
                      }  ${
                        WattsConverterService.convertWatts(configuracionDetalle.infoDeGeneracion.generacionAnual)
                          .unitHour
                      }`}
                    </p>
                  </div>
                  <div className={styles.toogle_wrapper} onClick={exportInforme}>
                    <p className={styles.link_paragraph}>
                      {I18n.t('MatcherListPage.addMatcher.exportGeneracioDescExist')}
                    </p>
                  </div>

                  {isActive ? (
                    <>
                      <SectionTitle text={I18n.t('MatcherListPage.addMatcher.generacio')} />
                      <div className={styles.divToooltip}>
                        <CupsTooltipOferta title={I18n.t('MatcherListPage.addMatcher.generacioDesc')} />
                      </div>
                      <ButtonCsv className={styles.csv_button} onFileSelect={handleFileSelect} />
                      {mensajeError.codigo === '' ? null : (
                        <div className={styles.errorMessage}>
                          <p>{I18n.t(mensajeError.codigo)}</p>
                          <p>{mensajeError.mensaje}</p>
                        </div>
                      )}
                    </>
                  ) : null}
                </>
              </>
            )}
          </div>

          <div className={styles.containerPorfile}>
            {' '}
            {configuration.matcher2 === true && (
              <>
                <SectionTitle text={I18n.t('MatcherListPage.addMatcher.selectPerfilConfig')} />
                <div className={styles.perfil}>
                  <SectionSubTitle text={I18n.t('MatcherListPage.addMatcher.perfilSelected')} />
                  <ElecsumSelect
                    minWidth="17.5rem"
                    values={perfilesDeConsumo}
                    selected={selectedPerfilConsumo}
                    label={I18n.t('MatcherListPage.addMatcher.selectPerfil')}
                    onChange={(el) => {
                      // onUpdatePerfilSelect(el);
                      setSelectedPerfilConsumo(el);
                    }}
                  />
                </div>
              </>
            )}
          </div>
          <div className={styles.containerStep}>
            {configuration.matcher2 === true &&
              formGroupMetodoDeCalculos.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  placeholder={formGroup.placeholder}
                  value={formGroup.value}
                  onChange={handleChange}
                  options={formGroup.options}
                  disabled={formGroup.disabled}
                  tabindex={formGroup.tabindex}
                  error={formGroup.error}
                />
              ))}

            {configuration.matcher2 === true &&
              input.metodoDeCalculos === 'Betas ágiles con normalización' &&
              FormFieldsInformeExcedente.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  placeholder={formGroup.placeholder}
                  name={formGroup.name}
                  value={formGroup.value}
                  checked={formGroup.checked}
                  onChange={handleChange}
                  step={0.0001} // Cambiado para permitir incrementos más precisos
                  min={0}
                  max={0.1}
                />
              ))}
          </div>
        </div>

        <div className={styles.containerForms}>
          <div>
            {/* <DateIntervalPicker onChangeDates={handleChangeDate} datesInterval={dateInterval} label="Fecha" /> */}
            <p className={styles.tituloFecha}>{I18n.t('Date')} </p>

            <YearPickerWithMinYear
              anchorSelector="right"
              value={new Date(`${date.month}/${date.day}/${date.year}`)}
              // value={new Date(`01/01/2023`)} // comentado para no actualizar fecha
              onChange={updateDate}
            />
          </div>
          <div className={styles.containerGeneracio}>
            <SectionTitle
              className={styles.SectionTitleWithoutPadding}
              text={I18n.t('MatcherListPage.addMatcher.comportamiento')}
            />
            <div className={styles.betas_wrapper}>
              <div className={styles.betas_section}>
                <div className={styles.containerBeta}>
                  <label htmlFor="betamax">
                    <CupsTooltipInputMatcher
                      title={`${I18n.t('MatcherListPage.addMatcher.betamax')}`}
                      descripcion={TextoBetamax}
                    />
                  </label>
                  <input
                    type="number"
                    value={input.betaMax}
                    placeholder="0"
                    id="betaMax"
                    name="betamax"
                    onChange={(e) =>
                      setInputs((prev) => ({
                        ...prev,
                        betaMax: e.target.valueAsNumber,
                      }))
                    }
                    min="0"
                    max="1"
                    step="0.001"
                  />
                </div>
                <div className={styles.containerBeta}>
                  <label htmlFor="betamin">
                    <div className={styles.divToooltip}>
                      <CupsTooltipInputMatcher
                        title={`${I18n.t('MatcherListPage.addMatcher.betamin')}`}
                        descripcion={TextoBetamin}
                      />
                    </div>
                  </label>

                  <input
                    type="number"
                    value={input.betaMin}
                    placeholder="0"
                    id="betaMin"
                    name="betaMin"
                    onChange={(e) =>
                      setInputs((prev) => ({
                        ...prev,
                        betaMin: e.target.valueAsNumber,
                      }))
                    }
                    min="0"
                    max="1"
                    step="0.001"
                  />
                </div>
                {/* {BETAS_TYPE.map((item, index) => (
                      <Field name={`beta${index + 1}`}>
                        {({ field }: any) => (
                          <div className={styles.containerBeta}>
                            <label htmlFor={`beta${index + 1}`}>{item}</label>
                            <input
                              type="number"
                              value={field.value}
                              placeholder="0"
                              id={`beta${index + 1}`}
                              name={field.name}
                              onChange={field.onChange}
                              min="0"
                              max="1"
                              step="0.01"
                            />
                          </div>
                        )}
                      </Field>
                    ))} */}
              </div>
              <SectionTitle
                className={styles.SectionTitleWithoutPadding}
                text={I18n.t('ComunidadesDetallePage.members.title')}
              />
              <div className={styles.checkdotsContainer}>
                <label>
                  <input
                    type="radio"
                    name="option"
                    defaultChecked={selectedOptionMax}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setSelectedOptionMax(e.target.checked);
                        setSelectedOptionMin(false);
                      }
                    }}
                  />
                  <span className={styles.checkdot} />
                  {I18n.t('MatcherListPage.addMatcher.maxMiembros')}
                </label>
                <label>
                  <input
                    type="radio"
                    name="option"
                    defaultChecked={selectedOptionMin}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setSelectedOptionMax(false);
                        setSelectedOptionMin(e.target.checked);
                      }
                    }}
                  />

                  <span className={styles.checkdot} />
                  {I18n.t('MatcherListPage.addMatcher.minMiembros')}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      {configuracionDetalle.idComunidad !== undefined ? (
        <div className={styles.containerGeneracioTable}>
          <div className={styles.addMemberWrapper}>
            <SectionTitle className={styles.paddingTitle} text={I18n.t('MatcherListPage.addMatcher.miembros')} />
            {configuration.matcher2 === true && (
              <LoadingButton
                disabled={!configuracionDetalle.idComunidad && !selectedComunidad}
                type="button"
                text={I18n.t('MatcherListPage.loadComunidad')}
                onClick={onLoadComunidades}
              />
            )}
            {configuration.matcher2 === true && (
              <LoadingButton
                disabled={!configuracionDetalle.idComunidad && !selectedComunidad}
                type="button"
                text={I18n.t('MatcherListPage.loadConsumo')}
                onClick={onLoadConsumo}
              />
            )}

            <LoadingButton
              disabled={!configuracionDetalle.idComunidad && !selectedComunidad}
              type="button"
              text={I18n.t('MatcherListPage.addMember')}
              onClick={onUpdateModal}
              className={styles.loadingButtonSameSize}
            />

            <LoadingButton
              disabled={!configuracionDetalle.idComunidad && !selectedComunidad}
              type="button"
              text={I18n.t('MatcherListPage.loadMember')}
              onClick={onUpdateModalLoadCSV}
              className={styles.loadingButtonSameSize}
            />
          </div>
          {getConfiguracionMiembros && (
            <TableMatcherWithFilter
              headerMatcherHeader={[
                I18n.t('MatcherListPage.addMatcher.Incluir'),
                'CUPS',
                `${I18n.t('MatcherListPage.addMatcher.name')} ${I18n.t('MatcherListPage.addMatcher.apellidos')}`,

                I18n.t('MatcherListPage.addMatcher.nif'),

                I18n.t('MatcherListPage.addMatcher.vip'),
                I18n.t('MatcherListPage.addMatcher.preferent'),
                ...(configuration.matcher2 ? [I18n.t('MatcherListPage.addMatcher.fuente')] : []),
                ...(configuration.matcher2 ? [I18n.t('MatcherListPage.addMatcher.curva'), '% Perfil'] : []),
                I18n.t('MatcherListPage.addMatcher.consumo'),
                '',
              ]}
              miembrosMatcher={getConfiguracionMiembros.slice().sort((a, b) => {
                if (a.consumoAnual && b.consumoAnual) {
                  return a.consumoAnual - b.consumoAnual;
                }
                return 0;
              })}
              renderRow={(miembro: any) => (
                <tr key={miembro.id}>
                  <td style={{ textAlign: 'left' }}>
                    <label className={styles.checkboxTable}>
                      <input
                        id="seleccionado"
                        type="checkbox"
                        name="seleccionado"
                        defaultChecked={miembro.seleccionado === 'si'}
                        onChange={(e) =>
                          MatcherConfiguracionMiembrosInstance.patchConfiguracionMiembro(
                            miembro.idConfiguracion,
                            miembro.id,
                            {
                              seleccionado: e.target.checked ? 'si' : 'no',
                            },
                          ).then(() => {
                            onChangeTable('seleccionado');
                          })
                        }
                      />
                    </label>
                  </td>
                  <td style={{ textAlign: 'left' }}>{miembro.cups}</td>
                  <td className={styles.collapseText} style={{ textAlign: 'left' }}>
                    {miembro.nombre} {miembro.apellidos}{' '}
                  </td>
                  <td style={{ textAlign: 'center' }}>{miembro.nif}</td>

                  <td style={{ textAlign: 'center' }}>
                    <label className={styles.checkboxTable}>
                      <input
                        id="vip"
                        type="checkbox"
                        name="vip"
                        defaultChecked={miembro.miembroVip === 'si'}
                        onChange={(e) => {
                          MatcherConfiguracionMiembrosInstance.patchConfiguracionMiembro(
                            miembro.idConfiguracion,
                            miembro.id,
                            {
                              miembroVip: e.target.checked ? 'si' : 'no',
                              // miembroPreferente: e.target.checked === true ? 'no' : 'si',
                            },
                          )
                            .then(() => {})
                            .finally(() => {
                              onChangeUpdate('vip');
                            });
                        }}
                      />
                    </label>
                  </td>
                  <td style={{ textAlign: 'left' }}>
                    {' '}
                    <label className={styles.checkboxTable}>
                      <input
                        id="preferent"
                        type="checkbox"
                        name="preferent"
                        defaultChecked={miembro.miembroPreferente === 'si'}
                        onChange={(e) => {
                          MatcherConfiguracionMiembrosInstance.patchConfiguracionMiembro(
                            miembro.idConfiguracion,
                            miembro.id,
                            {
                              miembroPreferente: e.target.checked ? 'si' : 'no',
                              // miembroVip: e.target.checked === true ? 'no' : 'si',
                            },
                          )
                            .then(() => {})
                            .finally(() => {
                              onChangeUpdate('preferente');
                            });
                        }}
                      />
                    </label>
                  </td>
                  {configuration.matcher2 && <td style={{ textAlign: 'center' }}>{miembro.fuente}</td>}
                  {configuration.matcher2 && (
                    <td style={{ textAlign: 'left' }}>
                      {/* Mostrar el estado actual de curvaConsumo */}
                      {miembro.curvaConsumo === false ? null : (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <RoundedButton onClick={() => handleCurvaConsumoClick(miembro)}>
                            {I18n.t(`ComunitiesAutoReportListPage.detalles`)}
                          </RoundedButton>
                        </div>
                      )}
                    </td>
                  )}
                  {configuration.matcher2 && (
                    <td style={{ textAlign: 'center' }}>{miembro.porcentajeDatosAproximados}</td>
                  )}
                  <td style={{ textAlign: 'center' }}>
                    {` ${WattsConverterService.convertWatts(miembro.consumoAnual).num}  ${
                      WattsConverterService.convertWatts(miembro.consumoAnual).unitHour
                    }`}
                  </td>
                  <td>
                    <div className={stylesDropdown.dropdown} ref={(ref) => (dropdownRefs.current[miembro.id] = ref)}>
                      <RoundedButton
                        onClick={() => {
                          toggleDropdown(miembro.id);
                        }}
                      >
                        {I18n.t(`Actions`)}
                      </RoundedButton>

                      {isOpen[miembro.id] && (
                        <div className={stylesDropdown.dropdownMenu}>
                          <Button
                            className={styles.viewmore_button}
                            variant="tertiary"
                            type="button"
                            onClick={() => {
                              setConfiguracionSelected(miembro);
                              toggleEdit();
                              toggleDropdownModal(miembro.id);
                            }}
                            text="edit"
                          />
                          <Button
                            className={styles.viewmore_button}
                            variant="tertiary"
                            type="button"
                            onClick={() => {
                              setConfiguracionSelected(miembro);
                              toggleDropdown(miembro.id);
                              toggleDropdownDelete(miembro.id);
                              toggleSwitch();
                            }}
                            text={I18n.t(`PerfilListPage.actions.delete`)}
                          />
                        </div>
                      )}
                    </div>
                  </td>
                  {isOpenDelete[miembro.id] && isSwitchOn && configuracionSelected && (
                    <ModalMatcher
                      title={`${I18n.t('MatcherListPage.mensajeModalDelete')} ${configuracionSelected.nombre}  ${
                        configuracionSelected.id
                      }`}
                      body={`${I18n.t('MatcherListPage.mensajeModalDelete')} ${configuracionSelected.nombre}`}
                      onClick={() =>
                        deleteConfiguracion(configuracionSelected.idConfiguracion, configuracionSelected.id)
                      }
                      testbutton={I18n.t('Confirmar')}
                      loading={loadingDelete}
                      onClick2={toggleSwitch}
                    />
                  )}
                  {isOpenModal[miembro.id] && isEdit && configuracionSelected && (
                    <ModalMatcher
                      title={I18n.t('MatcherListPage.updateMember')}
                      body={
                        <EditMemberPopUp
                          mensajeError={mensajeErrorMiembro}
                          miembroConfiguracion={configuracionSelected}
                          changeValue={setConfiguracionSelected}
                          setFile={setMiembroCSV}
                          añoSeleccionado={añoSeleccionado}
                        />
                      }
                      testbutton={I18n.t('Confirmar')}
                      onClick={() =>
                        UpdateMiembro(
                          configuracionSelected.idConfiguracion,
                          configuracionSelected.id,
                          configuracionSelected,
                        )
                      }
                      loading={loadingUpdate}
                      onClick2={() => {
                        toggleEdit();
                        toggleDropdownModal(miembro.id);
                        setMiembroCSV(undefined);
                        setMensajeErrorMiembro({ codigo: '', mensaje: '' });
                      }}
                    />
                  )}
                  {isOpenDelete[miembro.id] && isSwitchOn && configuracionSelected && (
                    <ModalMatcher
                      title={`${I18n.t('MatcherListPage.mensajeModalDelete')} ${configuracionSelected.nombre}  ${
                        configuracionSelected.id
                      }`}
                      body={`${I18n.t('MatcherListPage.mensajeModalDelete')} ${configuracionSelected.nombre}`}
                      onClick={() =>
                        deleteConfiguracion(configuracionSelected.idConfiguracion, configuracionSelected.id)
                      }
                      testbutton={I18n.t('Confirmar')}
                      loading={loadingDelete}
                      onClick2={toggleSwitch}
                    />
                  )}
                  {isGraphModalOpen[miembro.id] && configuracionSelected && (
                    <div>
                      <ModalMatcher
                        title={`Gráfico de Consumo para ${configuracionSelected.nombre} ${configuracionSelected.apellidos}`}
                        body={<GraficoCurvaConsumo id={miembro.idConfiguracion} miembroId={miembro.id} />}
                        // testbutton={I18n.t('Close')}
                        onClick={() => toggleGraphModal(miembro.id)} // Cierra el modal
                        onClick2={() => toggleGraphModal(miembro.id)}
                      />
                    </div>
                  )}
                </tr>
              )}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default AddConfiguracion;
