import { Card } from 'components';
import React from 'react';

import { I18n } from 'core';
import cx from 'classnames';
import { VictoryPie } from 'victory';
import { Colors } from 'styles';
import wattsConverterService from 'services/local/WattsConverter';
import { ReactComponent as GenerationIcon } from 'assets/icons/Generation.svg';
import { ReactComponent as LightingIcon } from 'assets/icons/Lighting.svg';
import styles from './EnergyFotovoltaicWidget.module.scss';

interface EnergyFotovoltaicCardPropsI {
  date: string;
  generationData: number;
  shrinkData: number;
  networkData: number;
  selfConsumptionData: number;
  consumptionData: number;
}
interface CircleCardPropsI {
  name: string;
  color: string;
  data: string;
  unit?: string;
}

const CircleInfo = (props: CircleCardPropsI): JSX.Element => {
  const { name, color, data, unit = 'kWh' } = props;
  return (
    <div className={styles.circle_info}>
      <div className={styles.type_container}>
        <div className={cx(styles.dot, color)} />
        <p className={styles.text}>{I18n.t(name)}</p>
      </div>
      <div className={styles.value_container}>
        <p className={styles.data}>{data}</p>
        <p className={styles.unit}>{unit}</p>
      </div>
    </div>
  );
};

const EnergyFotovoltaicWidget = (props: EnergyFotovoltaicCardPropsI) => {
  const { date, shrinkData, networkData, selfConsumptionData, generationData } = props;

  const getMonth = (): string => {
    const monthArray = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const month = new Date(date).getMonth();
    const monthTranslated = I18n.t(monthArray[month]);
    return monthTranslated;
  };
  return (
    <Card
      fullHeight
      title={I18n.t('energy')}
      subtitle={getMonth().toUpperCase()}
      body={[
        <div className={styles.root}>
          <div className={styles.charts_wrapper}>
            <div className={styles.chart_container}>
              <svg viewBox="0 0 400 400" height="100%" width="100%">
                <LightingIcon height={70} width={80} x={160} y={130} fill={Colors.COLOR_GRAY_500} />
                <text x="10" y="0" className={styles.title} transform="translate(125, 260)">
                  {I18n.t('consumption')}
                </text>
                <VictoryPie
                  standalone={false}
                  innerRadius={150}
                  radius={125}
                  labels={(): string => ''}
                  colorScale={[Colors.COLOR_ELECSUMGREEN, Colors.COLOR_GRAY_100]}
                  data={[
                    { x: 1, y: selfConsumptionData },
                    { x: 2, y: selfConsumptionData + networkData },
                  ]}
                />
              </svg>
            </div>
            <div className={styles.chart_container}>
              <svg viewBox="0 0 400 400" height="100%" width="100%">
                <GenerationIcon height={80} width={80} x={150} y={120} />
                <text x="0" y="0" className={styles.title} transform="translate(125, 260)">
                  {I18n.t('generation')}
                </text>
                <VictoryPie
                  standalone={false}
                  innerRadius={150}
                  radius={125}
                  labels={(): string => ''}
                  colorScale={[Colors.COLOR_ELECSUMGREEN, Colors.COLOR_BLUE_200]}
                  data={[
                    { x: 1, y: selfConsumptionData !== 0 ? selfConsumptionData : 0 },
                    {
                      x: 2,
                      y: shrinkData !== 0 ? shrinkData : 0,
                    },
                  ]}
                />
              </svg>
            </div>
          </div>
          <div className={styles.circles_wrapper}>
            <div className={styles.column}>
              <CircleInfo
                name="selfConsumption"
                color="bck-green"
                data={
                  generationData > 0 ? wattsConverterService.toSelectedMagnitudeString(selfConsumptionData, 'kWh') : ''
                }
                unit={generationData > 0 ? 'kWh' : I18n.t('noData')}
              />
              <CircleInfo
                name="network"
                color="bck-grey"
                data={wattsConverterService.toSelectedMagnitudeString(networkData, 'kWh')}
              />
            </div>
            <div className={styles.column}>
              <CircleInfo
                name="selfConsumption"
                color="bck-green"
                data={
                  generationData > 0 ? wattsConverterService.toSelectedMagnitudeString(selfConsumptionData, 'kWh') : ''
                }
                unit={generationData > 0 ? 'kWh' : I18n.t('noData')}
              />
              <CircleInfo
                name="shrinking"
                color="bck-blue"
                data={wattsConverterService.toSelectedMagnitudeString(shrinkData, 'kWh')}
              />
            </div>
          </div>
        </div>,
      ]}
    />
  );
};

export default React.memo(EnergyFotovoltaicWidget);
