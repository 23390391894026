// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { NavItemI, I18n } from 'core';
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/Home.svg';
import { ReactComponent as DocumentsIcon } from 'assets/icons/Documents.svg';

import { Colors } from 'styles';

const ICON_SIZE = 20;
const iconColor = Colors.COLOR_GRAY_100;
const activeIconColor = Colors.COLOR_ELECSUMGREEN;

const createMatcherNavigationLinks = (): Array<NavItemI> => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const links: Array<NavItemI> = [
    {
      name: I18n.t('Sidenav.Tecnic.CUPS_Dashboard'),
      url: `/tecnic/`,
      icon: <HomeIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
      activeIcon: <HomeIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
    },
    {
      name: I18n.t('Sidenav.Tecnic.Matcher'),
      url: `/tecnic/matcher`,
      icon: <DocumentsIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
      activeIcon: <DocumentsIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
    },
    {
      name: I18n.t('Sidenav.Tecnic.Preferencias'),
      url: `/tecnic/matcher/preferencias`,
      icon: <MoreIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
      activeIcon: <MoreIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
      children: [
        {
          name: I18n.t('Sidenav.Tecnic.Matcher_Perfil'),
          url: `/tecnic/matcher/preferencias/perfiles-consumo`,
        },
      ],
    },
  ];
  return links;
};

export default createMatcherNavigationLinks;
