import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCommunity, fetchCommunitySuccess } from 'redux/actions/communities-actions';
import { CommunitiesService } from 'services/remote/communities-service';

export default function useFetchCommunity(communityID: string | null): () => void {
  const dispatch = useDispatch();

  const fetchCommunityData = async () => {
    if (!communityID) {
      return; // Do nothing if communityID is null
    }
    dispatch(fetchCommunity());

    const response = await new CommunitiesService().getCommunity(communityID);
    dispatch(fetchCommunitySuccess(response));
  };

  useEffect(() => {
    if (!communityID) return;
    fetchCommunityData();
  }, [communityID, dispatch]);

  return fetchCommunityData;
}
