import { SelectItemI } from 'components/NewInputs/Select/ElecsumSelect';
import { translatableLangs } from 'config/translatable-langs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchCommunity, fetchCommunitySuccess } from 'redux/actions/communities-actions';
import { CommunitiesService } from 'services/remote/communities-service';
import { ProcessStatus } from 'types/enums';
import { GlobalStoreState } from 'types/state';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
// import { I18n } from 'core';
import Button from 'components/Button/Button';
import { navigate } from 'navigation';
import I18n from 'i18n-js';
import { LoadingButton } from 'components';
import styles from './comunidades-detalle-page.module.scss';
import { ToolsBar } from './tools-bar/tools-bar';
import { ComunidadesDetalleImageFormPage } from './components/comunidades-detalle-image-form/comunidades-detalle-image-form';
import ComunidadesDetalleDetailsFormPage from './components/comunidades-detalle-details-form/comunidades-detalle-details-form';
import { ComunidadesDetalleImageFormPageLogo } from './components/comunidades-detalle-image-form/comunidades-detalle-image-logo-form';
import ComunidadesDetalleCaracteristicas from './components/comunidades-detalle-Caracteristicas/comunidades-detalle-Caracteristicas';
import ComunidadesDetalleCsv from './components/comunidades-detalle-csv/comunidades-detalle-csv';

export const ComunidadesDetallePage = (): JSX.Element => {
  const [currentLang, setCurrentLang] = useState<SelectItemI>(translatableLangs[0]);
  const { communityID } = useParams<{ communityID: string }>();
  const dispatch = useDispatch();
  const communitiesState = useSelector((state: GlobalStoreState) => state.communities);
  const { selectedCommunity, selectedCommunityStatus } = communitiesState;
  const [clicked, setclicked] = useState<boolean>(false);
  useEffect(() => {
    if (!communityID) return;
    dispatch(fetchCommunity());
    new CommunitiesService().getCommunity(communityID).then((response) => {
      dispatch(fetchCommunitySuccess(response));
    });
  }, []);
  const fetchStamp = async () => {
    dispatch(fetchCommunity());
    new CommunitiesService().getCommunity(communityID).then((response) => {
      dispatch(fetchCommunitySuccess(response));
    });
  };
  const setearvariable = () => {
    setclicked(false);
  };
  useEffect(() => {
    setearvariable();
    fetchStamp();
  }, [communityID]);

  if (selectedCommunityStatus === ProcessStatus.LOADING) return <div className={styles.root} />;
  return (
    <div className={styles.container}>
      <div>
        {selectedCommunity && (
          <ToolsBar
            communityID={communityID || ''}
            selectedCommunity={selectedCommunity}
            langs={translatableLangs}
            selectedLang={currentLang}
            onChangeLanguage={(x) => setCurrentLang(x)}
          />
        )}
      </div>
      <SectionWrapper>
        <div>
          {selectedCommunity && (
            <ComunidadesDetalleDetailsFormPage
              selectedCommunity={selectedCommunity}
              currentLang={currentLang}
              clicked={clicked}
              afterSubmitAction={setearvariable}
            />
          )}
        </div>
        <div>
          {selectedCommunity ? (
            <ComunidadesDetalleCaracteristicas
              nif={communityID}
              currentLang={currentLang}
              news={selectedCommunity.caracteristicas}
              afterSubmitAction={fetchStamp}
            />
          ) : null}
        </div>
        <div>
          {selectedCommunity ? (
            <>
              <ComunidadesDetalleCsv
                hasCurva={selectedCommunity.curvaGeneracion ? selectedCommunity.curvaGeneracion : 'no'}
                communityId={communityID}
                currentLang={currentLang}
                afterSubmitAction={fetchStamp}
                title={I18n.t('MatcherListPage.addMatcher.generacioTitle')}
                textButton={I18n.t('add curva')}
                textTooltip={I18n.t('MatcherListPage.addPerfilGeneracion')}
                textButtonDownload={I18n.t('ComunidadesDetallePage.detailData.curva.exportCurva')}
                typeCurva="curva-generacion"
                selectedCommunity={selectedCommunity}
              />

              <ComunidadesDetalleCsv
                hasCurva={selectedCommunity.curvaConsumo ? selectedCommunity.curvaConsumo : 'no'}
                communityId={communityID}
                currentLang={currentLang}
                afterSubmitAction={fetchStamp}
                title={I18n.t('MatcherListPage.addMatcher.subirCurva')}
                textButton={I18n.t('add curva')}
                textTooltip={I18n.t('MatcherListPage.addPerfilConsumo')}
                textButtonDownload={I18n.t('ComunidadesDetallePage.detailData.curva.exportCurvaConsumo')}
                typeCurva="curva-consumo"
                selectedCommunity={selectedCommunity}
              />
            </>
          ) : null}
        </div>
        <div className={styles.margenBotImagen}>
          {selectedCommunity && (
            <ComunidadesDetalleImageFormPage
              id={communityID}
              images={selectedCommunity.imagenes}
              afterSubmitAction={fetchStamp}
            />
          )}
        </div>
        <div className={styles.margenBotImagen}>
          {selectedCommunity && (
            <ComunidadesDetalleImageFormPageLogo
              id={communityID}
              logo={selectedCommunity.logos}
              afterSubmitAction={fetchStamp}
            />
          )}
        </div>
        <div className={styles.positionBotonoes}>
          <div className={styles.divBoton}>
            <Button
              type="button"
              onClick={(): Promise<void> => navigate('/tecnic/communities')}
              variant="secondary"
              text={I18n.t('Cancel')}
            />
            <LoadingButton type="submit" text={I18n.t('Save')} onClick={() => setclicked(true)} />
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
};
