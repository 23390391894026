import configuration from 'config';
import { AxiosResponse } from 'axios';

import { of, BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

import { CLIENTS_MOCK, CLIENTINFO_MOCK } from 'mocks';
import ClientAPI from 'types/models/client';
import ContractSummary from 'types/models/widgetsData/contractSummaryObj';
import ClientResume from 'types/models/clientResume';
import ComercialitzationSummary from 'types/models/widgetsData/comercializationSummaryObj';
import apiService from './ApiService';

import {
  Client,
  SavingObject,
  EnergyObject,
  SustainabilityObject,
  AmortizationObject,
  Alert,
  ContractsData,
  PowersList,
} from '../../types/models';

import GuaranteeObject from '../../types/models/widgetsData/guaranteeObj';
import SharedEnergyObject from '../../types/models/widgetsData/sharedEnergyObj';

export interface ClientUpdate {
  informeFotovoltaicoMensual: string;
  informeFotovoltaicoAnual: string;
  informeCompletoMasDesviacionMensual: string;
  informeCompletoMasDesviacionAnual: string;
  informeGarantiaDeProduccionMensual: string;
  informeGarantiaDeProduccionAnual: string;
  porcentajeTitularidadInstalacion: number;
  costeAcceso: number;
}

export interface ClientInfo {
  cups: string;
  nif?: string;
  nombre?: string;
  garantiaDeProduccion?: number;
  generacion?: number;
  porcentajeDeReparto?: number;
  domicilio?: string;
  poblacion?: string;
  esComunidad?: string;
}
export interface InstallationClientsI {
  nombre: string;
  domicilio: string;
  poblacion: string;
  provincia: string;
  codigoPostal: string;
  garantiaDeProduccion: number;
  generacion: number;
  clientes: Array<ClientInfo>;
}

export class ClientService {
  private readonly CLIENT_KEY = 'clientKey';

  private clientSubject = new BehaviorSubject<ClientInfo | null>(null);

  readonly client$: Observable<ClientInfo | null> = this.clientSubject.asObservable();

  private clientsSubject = new BehaviorSubject<ClientInfo[]>([]);

  readonly clients$: Observable<ClientInfo[]> = this.clientsSubject.asObservable();

  private apiUrl = configuration.apiUrl;

  readonly http = apiService;

  constructor() {
    this.init();
  }

  private init(): void {
    this.client = this.getStoredClient();
  }

  private getStoredClient(): ClientInfo | null {
    let client = null;
    const data = localStorage.getItem(this.CLIENT_KEY);

    if (data) {
      try {
        client = JSON.parse(data) as ClientInfo;
      } catch (error) {
        client = null;
      }
    }

    return client;
  }

  get client(): ClientInfo | null {
    return this.clientSubject.getValue();
  }

  set client(client: ClientInfo | null) {
    if (client) {
      this.clientSubject.next(client);
      localStorage.setItem(this.CLIENT_KEY, JSON.stringify(client));
    } else {
      localStorage.removeItem(this.CLIENT_KEY);
      this.clientSubject.next(null);
    }
  }

  get clients(): ClientInfo[] {
    return this.clientsSubject.getValue();
  }

  set clients(clients: ClientInfo[]) {
    this.clientsSubject.next(clients);
  }

  async getAllClients(): Promise<Array<Client>> {
    const clients: Array<Client> = new Client().deserializeArray(CLIENTS_MOCK);

    return clients;
  }

  async getClientsFromInstallation(installationId: string): Promise<Array<ClientInfo>> {
    const url = `${this.apiUrl}/fv/instalacion/${installationId}/clientes/`;

    const response: AxiosResponse<{
      datos: InstallationClientsI;
    }> = await this.http.get(url);

    const installationClients = response.data.datos;

    return installationClients.clientes;
  }

  // CLIENT_MOCK
  async getClientMock(clientId: string): Promise<Client> {
    // CLIENTINFO_MOCK ??
    const client: Client = new Client().deserialize({
      id: clientId,
      // ...CLIENTS_MOCK[0],
      ...CLIENTINFO_MOCK[0],
    });

    await of([]).pipe(delay(1000)).toPromise();

    return client;
  }

  // CLIENTS RESUME
  async getClientsResume(nif: string): Promise<Array<ClientResume>> {
    const url = `${this.apiUrl}/fv/cliente`;
    const response: AxiosResponse<{ datos: Array<ClientResume> }> = await this.http.get(url, { params: { nif } });
    const clients = new ClientResume().deserializeArray(response.data.datos);
    return clients;
  }

  // GET CLIENT
  async getClient(cups: string): Promise<Client> {
    const url = `${this.apiUrl}/fv/cliente/${cups}/`;

    const response: AxiosResponse<{ datos: ClientAPI }> = await this.http.get(url);

    const client = new Client().deserialize(response.data.datos);

    return client;
  }

  async searchClient(text: string): Promise<Array<Client>> {
    const url = `${this.apiUrl}/fv/cliente/buscar/${text}/`;

    const response: AxiosResponse<{
      datos: Array<Client>;
    }> = await this.http.get(url);

    const clients = new Client().deserializeArray(response.data.datos);

    return clients;
  }

  async putClientReport(client: Client): Promise<string> {
    const url = `${this.apiUrl}/fv/cliente/${client.cups}/`;

    const clientUpdate: ClientUpdate = {
      informeFotovoltaicoMensual: client.informeFotovoltaicoMensual || '',
      informeFotovoltaicoAnual: client.informeFotovoltaicoAnual || '',
      informeCompletoMasDesviacionMensual: client.informeCompletoMasDesviacionMensual || '',
      informeCompletoMasDesviacionAnual: client.informeCompletoMasDesviacionAnual || '',
      informeGarantiaDeProduccionMensual: client.informeGarantiaDeProduccionMensual || '',
      informeGarantiaDeProduccionAnual: client.informeGarantiaDeProduccionAnual || '',
      porcentajeTitularidadInstalacion: client.porcentajeTitularidadInstalacion || 0,
      costeAcceso: client.costeAcceso || 0,
    };

    const response: AxiosResponse<string> = await this.http.put(url, clientUpdate);

    return response.data;
  }

  public async getEnergyData(cups?: string): Promise<EnergyObject> {
    if (!cups) {
      return new EnergyObject();
    }
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/widget/energia/${cups}/`);

    return new EnergyObject().deserialize(response.data);
  }

  public async getSavingData(cups?: string): Promise<SavingObject> {
    if (!cups) {
      return new SavingObject();
    }
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/widget/ahorro/${cups}/`);
    return new SavingObject().deserialize(response.data);
  }

  public async getAmortizationData(cups?: string): Promise<AmortizationObject> {
    if (!cups) {
      return new AmortizationObject();
    }
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/widget/amortizacion/${cups}/`);

    return new AmortizationObject().deserialize(response.data);
  }

  public async getSharedEnergyData(cups?: string): Promise<SharedEnergyObject> {
    if (!cups) {
      return new SharedEnergyObject();
    }
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/widget/generacion-repartida/${cups}/`);
    return new SharedEnergyObject().deserialize(response.data);
  }

  public async getSustainabilityData(cups?: string): Promise<SustainabilityObject> {
    if (!cups) {
      return new SustainabilityObject();
    }
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/sostenibilidad/${cups}/`);

    return new SustainabilityObject().deserialize(response.data.datos);
  }

  public async getContractsData(cups?: string): Promise<Array<ContractsData | null>> {
    if (!cups) {
      return [];
    }

    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/contratos/${cups}/`);

    return new ContractsData().deserializeArray(response.data.datos);
  }

  public async getGuaranteeData(cups?: string): Promise<GuaranteeObject> {
    if (!cups) {
      return new GuaranteeObject();
    }

    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/garantia-de-produccion/${cups}/`);

    return new GuaranteeObject().deserialize(response.data.datos);
  }

  public async getAlerts(): Promise<Array<Alert>> {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/alertas/`);
    return new Alert().deserializeArray(response.data.datos);
  }

  public async getAlertsCount(): Promise<number> {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/alertas/count/`);
    return response.data.datos.numeroAlertas;
  }

  public async modifyAlert(alertId: string, newStatus: number): Promise<AxiosResponse> {
    const response: AxiosResponse = await this.http.put(`${this.apiUrl}/fv/alerta/${alertId}/`, { estado: newStatus });

    return response;
  }

  public async getContractSummary(cups?: string): Promise<ContractSummary> {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/widget/resumen-contrato/${cups}/`);
    return new ContractSummary().deserialize(response.data.datos);
  }

  public async getComercializationSummary(cups?: string): Promise<ComercialitzationSummary> {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/widget/resumen-comercializacion/${cups}/`);
    return new ComercialitzationSummary().deserialize(response.data.datos);
  }

  public async getPowersHired(cups?: string): Promise<PowersList> {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/cliente/potencias-contratadas/${cups}/`);
    return new PowersList().deserialize(response.data);
  }
}

const clientServiceInstance = new ClientService();

export default clientServiceInstance;
