import { Serializable } from 'types/interfaces';

interface ClientResumeAPI {
  cups: string;
  cau: string;
  nif: string;
  instalacionID: number;
  nombre: string;
  apellidos: string;
  domicilio: string;
  poblacion: string;
  provincia: string;
  telefono: string;
}

export default class ClientResume implements Serializable<ClientResume> {
  cups: string;

  cau: string;

  nif: string;

  instalacionID: number;

  nombre: string;

  apellidos: string;

  domicilio: string;

  poblacion: string;

  provincia: string;

  telefono: string;

  constructor() {
    this.deserialize({
      instalacionID: -1,
      cau: '',
      cups: '',
      nif: '',
      nombre: '',
      apellidos: '',
      domicilio: '',
      poblacion: '',
      provincia: '',
      telefono: '',
    });
  }

  deserialize(input: ClientResumeAPI): ClientResume {
    if (!input) {
      return this;
    }
    this.cau = input.cau || '';
    this.cups = input.cups || '';
    this.nif = input.nif || '';
    this.nombre = input.nombre || '';
    this.apellidos = input.apellidos || '';
    this.domicilio = input.domicilio || '';
    this.poblacion = input.poblacion || '';
    this.provincia = input.provincia || '';
    this.telefono = input.telefono || '';
    this.instalacionID = input.instalacionID || -1;
    return this;
  }

  deserializeArray(inputArray: Array<ClientResumeAPI>): Array<ClientResume> {
    const items: Array<ClientResume> = inputArray.map((input) => new ClientResume().deserialize(input));
    return items;
  }
}
