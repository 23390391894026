import FullPageSyncLoader from 'components/FullPageSyncLoader';
import React, { useEffect, useState } from 'react';
import CommunitiesServiceIntance from 'services/remote/communities-service';
import { useDebounce } from 'hooks';
import { I18n } from 'core';

import Button from 'components/Button/Button';
import { navigate } from 'navigation';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { CommunityModel } from 'types/models/community';
import styles from './comunidades-list-page.module.scss';
import { CommunitiesListTable } from './components/communities-list-table/communities-list-table';
import { CommunitiesListFilters } from './components/communities-list-filters/communities-list-filters';
// import { CommunitiesListPaginator } from './components/communities-list-paginator/communities-list-paginator';

export const ComunidadesListPage = (): JSX.Element => {
  const [communities, setCommunities] = useState<CommunityModel[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [search, setSearch] = useState<string>('');
  const debouncedSearchText = useDebounce(search, 500);

  const getCommunities = () => {
    CommunitiesServiceIntance.getCommunities(search)
      .then((response) => {
        setCommunities(response);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    getCommunities();
  }, []);

  useEffect(() => {
    setLoadingData(true);
    getCommunities();
  }, [debouncedSearchText]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Button
          className={styles.viewmore_button}
          variant="tertiary"
          type="button"
          onClick={(): Promise<void> => navigate('/tecnic/dashboard')}
          text={I18n.t('UsersPage.goDashboard')}
        />
      </div>
      <SectionWrapper>
        <CommunitiesListFilters search={setSearch} />

        <div className={styles.table_wrapper}>
          {loadingData ? <FullPageSyncLoader icon /> : <CommunitiesListTable data={communities} />}
        </div>
        <div>
          {/* Comentado */}
          {/* <CommunitiesListPaginator current={0} max={1} handleNext={() => {}} handlePrev={() => {}} /> */}
        </div>
      </SectionWrapper>
    </div>
  );
};
