import I18n from 'i18n-js';
import React from 'react';
import { InscritosModel } from 'types/models/inscritos';
import { CommunityEnrolledListRow } from '../community-enrolled-list-row/community-enrolled-list-row';
import styles from './communities-enrolled-list-table.module.scss';

interface CommunitiesEnrolledListTableProps {
  data: InscritosModel[];
  communityID: string;
}

export const CommunitiesEnrolledListTable = (props: CommunitiesEnrolledListTableProps): JSX.Element => {
  const { data, communityID } = props;
  const { t } = I18n;

  const arraysinvalidar = data.filter((nume) => nume.validado === 'no');
  return (
    <table className={styles.table_wrapper}>
      {data.length > 0 ? (
        <>
          {communityID === undefined ? (
            <>
              {arraysinvalidar.length > 0 ? (
                <>
                  <thead>
                    <tr>
                      <th>{t(`ComunitiesEnrolledListPage.headers.name`)}</th>
                      <th>{t(`ComunitiesEnrolledListPage.headers.direccion`)}</th>
                      <th>{t(`ComunitiesEnrolledListPage.headers.telefono`)}</th>
                      <th>{t(`ComunitiesEnrolledListPage.headers.nombreComunidad`)}</th>
                      <th style={{ textAlign: 'center' }}> OTC </th>
                      <th>{t(`ComunitiesEnrolledListPage.headers.CUPS`)}</th>
                      <th>{t(`ComunitiesEnrolledListPage.headers.Data`)}</th>
                      <th>{t(`ComunitiesEnrolledListPage.headers.status`)}</th>
                      <th aria-label="Detail" />
                    </tr>
                  </thead>
                  <tbody>
                    {arraysinvalidar.map((row) => (
                      <CommunityEnrolledListRow key={row.id} row={row} communityID={communityID} />
                    ))}
                  </tbody>
                </>
              ) : (
                <p>{t(`ComunitiesEnrolledListPage.headers.notResult`)}</p>
              )}
            </>
          ) : (
            <>
              <thead>
                <tr>
                  <th>{t(`ComunitiesEnrolledListPage.headers.name`)}</th>
                  <th>{t(`ComunitiesEnrolledListPage.headers.direccion`)}</th>
                  <th>{t(`ComunitiesEnrolledListPage.headers.telefono`)}</th>
                  <th>{t(`ComunitiesEnrolledListPage.headers.nombreComunidad`)}</th>
                  <th style={{ textAlign: 'center' }}> OTC </th>
                  <th>{t(`ComunitiesEnrolledListPage.headers.CUPS`)}</th>
                  <th>{t(`ComunitiesEnrolledListPage.headers.Data`)}</th>
                  <th>{t(`ComunitiesEnrolledListPage.headers.status`)}</th>
                  <th aria-label="Detail" />
                </tr>
              </thead>
              <tbody>
                {data.map((row) => (
                  <CommunityEnrolledListRow key={row.id} row={row} communityID={communityID} />
                ))}
              </tbody>
            </>
          )}
        </>
      ) : (
        <p>{t(`ComunitiesEnrolledListPage.headers.notResult`)}</p>
      )}
    </table>
  );
};
