import React, { useState } from 'react';
import { DateRangePicker } from 'react-dates';
import 'moment/min/locales';
import moment from 'moment';
import './DateIntervalPicker.scss';
import { I18n } from 'core';

export interface DateInterval {
  start: moment.Moment | null;
  end: moment.Moment | null;
}

interface DateIntervalPickerPropsI {
  label: string;
  datesInterval: DateInterval;
  onChangeDates(dates: DateInterval): void;
}

const DateIntervalPicker = (props: DateIntervalPickerPropsI): JSX.Element => {
  const { label, datesInterval, onChangeDates } = props;
  moment.locale(I18n.currentLocale().substring(0, 2));
  const [focusInput, setFocusInput] = useState<any>(null);
  return (
    <div className="DateIntervalPicker">
      <p className="label">{label}</p>
      <DateRangePicker
        startDate={datesInterval.start}
        enableOutsideDays
        isOutsideRange={() => false}
        startDateId="startDate"
        endDate={datesInterval.end}
        endDateId="endDate"
        focusedInput={focusInput}
        startDatePlaceholderText={I18n.t('DateIntervalPicker.start_date')}
        endDatePlaceholderText={I18n.t('DateIntervalPicker.end_date')}
        displayFormat="DD/MM/YYYY"
        hideKeyboardShortcutsPanel
        onFocusChange={(focusedInput) => setFocusInput(focusedInput)}
        onDatesChange={({ startDate, endDate }) => {
          onChangeDates({ start: startDate, end: endDate });
        }}
      />
    </div>
  );
};

export default React.memo(DateIntervalPicker);
