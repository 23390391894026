/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, FormEvent, useEffect } from 'react';

import { FormGroup, FormGroupProps, I18n } from 'core';

import { SectionTitle, LoadingButton } from 'components';

import {
  authServiceInstance,
  clientServiceInstance,
  cupsServiceInstance,
  installationServiceInstance,
  LoggingService,
} from 'services';

import { User } from 'types/models';
import { toast } from 'react-toastify';
import customToast from 'components/CustomToast/CustomToast';
import { navigate } from 'navigation';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import usersService from 'services/remote/UsersService';
import styles from './ChangePasswordPage.module.scss';

const ChangePasswordPage = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [user, setUser] = useState<User | null>(authServiceInstance.user);
  const [password, setPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newRePassword, setNewRePassword] = useState<string>('');
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [loadingEmail, setLoadingemail] = useState(false);
  const [errorEmail, setError] = useState<string | null>(null);
  const [submitDisabledEmail, setSubmitDisabledEmail] = useState<boolean>(true);
  const [errorPassword, seterrorPassword] = useState<string | undefined>(undefined);

  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const formGroupUserData: Array<FormGroupProps> = [
    {
      label: I18n.t('Name'),
      type: 'text',
      id: 'name',
      path: 'name',
      name: 'name',
      value: user?.nombre,
      readOnly: true,
      placeholder: '',
    },
    {
      label: I18n.t('Surname'),
      type: 'text',
      id: 'surname',
      path: 'surname',
      name: 'surname',
      value: user?.apellidos,
      readOnly: true,
      placeholder: '',
    },
    {
      label: I18n.t('NIF'),
      type: 'text',
      id: 'nif',
      path: 'nif',
      name: 'nif',
      value: user?.nif,
      readOnly: true,
      placeholder: '',
    },
    {
      label: I18n.t('Email'),
      type: 'text',
      id: 'email',
      path: 'email',
      name: 'email',
      value: user?.email,
      placeholder: '',
    },
  ];

  const formGroupsUserPasswordChange: Array<FormGroupProps> = [
    {
      label: I18n.t('Actual Password'),
      type: 'password',
      id: 'password',
      path: 'password',
      name: 'password',
      value: password,
      placeholder: I18n.t('min 8 characters'),
    },
    {
      label: I18n.t('New Password'),
      type: 'password',
      id: 'newPassword',
      path: 'newPassword',
      name: 'newPassword',
      value: newPassword,
      placeholder: I18n.t('min 8 characters'),
    },
    {
      label: I18n.t('Repeat New Password'),
      type: 'password',
      id: 'newRePassword',
      path: 'newRePassword',
      name: 'newRePassword',
      value: newRePassword,
      error: errorPassword,
      placeholder: I18n.t('min 8 characters'),
    },
  ];
  const handleChange = (path: string, value: string): void => {
    if (path === 'password') {
      setPassword(value);
    } else if (path === 'newPassword') {
      setNewPassword(value);
    } else if (path === 'newRePassword') {
      setNewRePassword(value);
    } else if (path === 'email') {
      if (user) {
        setUser(new User().deserialize({ ...user, [path]: value }));
        if (!isValidEmail(user.email)) {
          setSubmitDisabledEmail(true);

          setError('Email is invalid');
        } else {
          setSubmitDisabledEmail(false);
          setError(null);
        }
      }
    }
  };

  const handleChangePassword = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await authServiceInstance.changePassword(password, newPassword);
      LoggingService.log('response', response);
      if (response) {
        authServiceInstance.logOut();
        installationServiceInstance.installation = null;
        clientServiceInstance.client = null;
        clientServiceInstance.clients = [];
        cupsServiceInstance.cups = null;
        cupsServiceInstance.cupsList = [];
        navigate('/auth/login');
        customToast.success(I18n.t('Password Changed'));
      }
    } catch (error) {
      toast.error(I18n.t('errorNewPasswordNotValid'));
    }
    setPassword('');
    setNewPassword('');
    setNewRePassword('');
    setLoading(false);
  };
  const handleChangeEmail = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setLoadingemail(true);
    try {
      if (user) {
        const response = await usersService.changeEmail(user);
        LoggingService.log('response', response);
        if (response) {
          // installationServiceInstance.installation = null;
          // clientServiceInstance.client = null;
          // clientServiceInstance.clients = [];
          // cupsServiceInstance.cups = null;
          // cupsServiceInstance.cupsList = [];
          // navigate('/profile');
          authServiceInstance.setUser(user);
          customToast.success(I18n.t('Cliente actualizado correctamente'));
        }
      }
    } catch (error) {
      toast.error(I18n.t('Error updating report!'));
    }
    setLoadingemail(false);
  };
  const handleEliminarCuenta = async (): Promise<void> => {
    // eslint-disable-next-line no-alert
    const accept = window.confirm(I18n.t('Are you sure you want to delete the account?'));

    if (!accept) {
      return;
    }

    try {
      const localUser = user?.nif;
      if (localUser !== undefined) {
        const response = await authServiceInstance.eliminarCuenta(localUser);

        LoggingService.log('response', response);
        if (response) {
          authServiceInstance.logOut();
          installationServiceInstance.installation = null;
          clientServiceInstance.client = null;
          clientServiceInstance.clients = [];
          cupsServiceInstance.cups = null;
          cupsServiceInstance.cupsList = [];
          navigate('/auth/login');
          customToast.success(I18n.t('Delete Account'));
        }
      }
    } catch (error) {
      toast.error(I18n.t('errorNewPasswordNotValid'));
    }
  };
  useEffect(() => {
    const validateNewPasswords = (): boolean => {
      let validated = false;
      if (newPassword.trim() !== '' && newRePassword.trim() !== '') {
        if (newPassword.trim() === newRePassword.trim() && newPassword.trim().length >= 8) {
          validated = true;
          seterrorPassword(undefined);
        } else {
          seterrorPassword(I18n.t('UsuarioDetailPage.Promotor.errorContra'));

          validated = false;
        }
      } else {
        validated = false;
      }
      return validated;
    };
    const canSubmitForm = validateNewPasswords();
    setSubmitDisabled(!canSubmitForm);
  }, [password, newPassword, newRePassword]);

  return (
    <div className={styles.root}>
      <SectionWrapper>
        <SectionTitle text={I18n.t('My Info')} />
        <form onSubmit={handleChangeEmail}>
          <div className={styles.fields}>
            {formGroupUserData.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                placeholder={formGroup.placeholder}
                value={formGroup.value}
                readOnly={formGroup.readOnly}
                onChange={handleChange}
              />
            ))}
            {errorEmail && <p style={{ color: 'red' }}>{errorEmail}</p>}
          </div>

          <div className={styles['save-container']}>
            <LoadingButton
              type="submit"
              text={I18n.t('Email Cuenta')}
              disabled={submitDisabledEmail}
              loading={loadingEmail}
            />
          </div>
        </form>
      </SectionWrapper>
      <SectionWrapper>
        <SectionTitle text={I18n.t('Change Password')} />
        <form onSubmit={handleChangePassword}>
          <div className={styles.fields}>
            {formGroupsUserPasswordChange.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                placeholder={formGroup.placeholder}
                value={formGroup.value}
                required={formGroup.required}
                readOnly={formGroup.readOnly}
                onChange={handleChange}
                error={formGroup.error}
              />
            ))}
          </div>
          <div className={styles['save-container']}>
            <LoadingButton type="submit" text={I18n.t('Change Password')} disabled={submitDisabled} loading={loading} />
          </div>
        </form>
      </SectionWrapper>
      <SectionWrapper>
        <div className={styles['save-container-delete']}>
          <p onClick={handleEliminarCuenta}>{I18n.t('Are you sure you want to delete the profile?')} </p>
          {/* <Button
            type="submit"
            variant="reddelete"
            text={I18n.t('Delete Cuenta')}
            onClick={handleEliminarCuenta}
            loading={loading}
          /> */}
        </div>
      </SectionWrapper>
    </div>
  );
};

export default ChangePasswordPage;
