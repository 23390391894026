/* eslint-disable arrow-body-style */
import React from 'react';
import cx from 'classnames';
import styles from './RoundedButton.module.scss';

interface ButtonPropsI {
  className?: string;
  color?: 'primary' | 'secondary' | 'reddelete';
  variant?: 'solid' | 'outlined' | 'ghost' | 'clear';
  type?: 'button' | 'submit' | 'reset' | 'link';
  loading?: boolean;
  href?: string;
  fit?: 'stretch' | 'block';
}

const RoundedButton = (props: React.HTMLProps<HTMLButtonElement> & ButtonPropsI): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, onClick, className, color = 'primary', variant = 'solid', loading = false, fit = 'block' } = props;
  const cxComputed = cx(styles.btn, className, styles[`btn-${color}__${variant}`], styles[`btn-${fit}`]);

  return (
    <button className={cxComputed} onClick={onClick} type="button">
      {children}
    </button>
  );
};

export default RoundedButton;
