import { IsClientGuard, LoggedInGuard } from 'navigation/guards';
import { BaseLayout } from 'layouts';
import {
  ClientChartsPage,
  ClientDocumentsPage,
  ClientElecsumGreenPage,
  ClientHomePage,
  ClientInvoicesPage,
  ClientSustainabilityPage,
} from 'pages';
import {
  CIMClosuresPage,
  CIMDashboardPage,
  CIMDataExportPage,
  CIMDeviceInfoPage,
  CIMInvoiceSimulationPage,
  CIMMeasurementRegistersPage,
  CIMPowerOptimitzationPage,
} from 'pages/Common/CIM';

import { ClientUpdateTarifaPage } from 'pages/ModeClient';
import SolicitudCupsTerceroPage from 'pages/ModeClient/SolicitudCupsTerceroPage';
import { RouteInterface } from '../../types/interfaces';

const routes: Array<RouteInterface> = [
  {
    path: '/client',
    name: 'Client',
    guards: [LoggedInGuard, IsClientGuard],
    children: [
      {
        path: '/home',
        layout: BaseLayout,
        component: ClientHomePage,
        name: 'Home',
        exact: true,
        guards: [LoggedInGuard, IsClientGuard],
      },
      {
        path: '/:cupsID/home',
        layout: BaseLayout,
        component: ClientHomePage,
        name: 'Home',
        exact: true,
        guards: [LoggedInGuard, IsClientGuard],
      },
      {
        path: '/charts',
        layout: BaseLayout,
        component: ClientChartsPage,
        name: 'Charts',
        guards: [LoggedInGuard, IsClientGuard],
      },
      {
        path: '/:cupsID/charts',
        layout: BaseLayout,
        component: ClientChartsPage,
        name: 'Charts',
        guards: [LoggedInGuard, IsClientGuard],
      },
      {
        path: '/invoices',
        layout: BaseLayout,
        component: ClientInvoicesPage,
        name: 'invoices',
        guards: [LoggedInGuard, IsClientGuard],
      },
      {
        path: '/:cupsID/invoices',
        layout: BaseLayout,
        component: ClientInvoicesPage,
        name: 'invoices',
        guards: [LoggedInGuard, IsClientGuard],
      },
      {
        path: '/documents',
        layout: BaseLayout,
        component: ClientDocumentsPage,
        name: 'documents',
        guards: [LoggedInGuard, IsClientGuard],
      },
      {
        path: '/:cupsID/documents',
        layout: BaseLayout,
        component: ClientDocumentsPage,
        name: 'documents',
        guards: [LoggedInGuard, IsClientGuard],
      },
      {
        path: '/:cupsID/sustainability',
        layout: BaseLayout,
        component: ClientSustainabilityPage,
        name: 'Sustainability',
        guards: [LoggedInGuard, IsClientGuard],
      },
      {
        path: '/elecsum-green',
        layout: BaseLayout,
        component: ClientElecsumGreenPage,
        name: 'Elecsum green',
        guards: [LoggedInGuard, IsClientGuard],
      },
      {
        path: '/cim',
        name: 'CIM Default',
        exact: true,
        redirect: '/home',
      },
      {
        path: '/cim/:cupsID',
        name: 'CIM Default',
        exact: true,
        redirect: '/home',
      },
      {
        path: '/cim/:cupsID/dashboard',
        name: 'CIM Dashboard',
        exact: true,
        layout: BaseLayout,
        component: CIMDashboardPage,
        guards: [LoggedInGuard, IsClientGuard],
      },
      {
        path: '/cim/:cupsID/device-info',
        name: 'CIM Device Info',
        exact: true,
        layout: BaseLayout,
        component: CIMDeviceInfoPage,
        guards: [LoggedInGuard, IsClientGuard],
      },
      {
        path: '/cim/:cupsID/measurement-registers',
        name: 'CIM Measurement Registers',
        exact: true,
        layout: BaseLayout,
        component: CIMMeasurementRegistersPage,
        guards: [LoggedInGuard, IsClientGuard],
      },
      {
        path: '/cim/:cupsID/invoice-simulation',
        name: 'CIM Invoice Simulation',
        exact: true,
        layout: BaseLayout,
        component: CIMInvoiceSimulationPage,
        guards: [LoggedInGuard, IsClientGuard],
      },
      {
        path: '/cim/:cupsID/measurement-devices',
        name: 'CIM Measurement Devices',
        exact: true,
        layout: BaseLayout,
        component: CIMDeviceInfoPage,
        guards: [LoggedInGuard, IsClientGuard],
      },
      {
        path: '/cim/:cupsID/power-optimization',
        name: 'CIM Power Optimization',
        exact: true,
        layout: BaseLayout,
        component: CIMPowerOptimitzationPage,
        guards: [LoggedInGuard, IsClientGuard],
      },
      {
        path: '/cim/:cupsID/closures',
        name: 'CIM Closures',
        exact: true,
        layout: BaseLayout,
        component: CIMClosuresPage,
        guards: [LoggedInGuard, IsClientGuard],
      },
      {
        path: '/cim/:cupsID/data-export',
        name: 'CIM Data Export',
        exact: true,
        layout: BaseLayout,
        component: CIMDataExportPage,
        guards: [LoggedInGuard, IsClientGuard],
      },
      {
        path: '/:cupsID/updateTarifa',
        layout: BaseLayout,
        component: ClientUpdateTarifaPage,
        guards: [LoggedInGuard, IsClientGuard],
      },
      {
        path: '/:cupsID/solicitud-autorizacion-cups-terceros',
        layout: BaseLayout,
        component: SolicitudCupsTerceroPage,
        guards: [LoggedInGuard, IsClientGuard],
      },
    ],
  },
];

export default routes;
