import { useEffect } from 'react';

const useSlider = (slideImage: any, images: any) => {
  let slideCounter = 0;

  const startSlider = () => {
    const slideImageIn = slideImage;
    slideImageIn.current.style.backgroundImage = ` url(${images[0].src})`;
  };
  useEffect(() => startSlider());

  const handleSlide = (slide: any) => {
    const slideImageIn = slideImage;

    slideImageIn.current.style.backgroundImage = ` url(${images[slide - 1].src})`;

    // slideImageIn;
  };

  const animateSlide = (slideImage2: any) => {
    const slideImageIn = slideImage2;

    slideImageIn.current.classList.add('fadeIn');
    setTimeout(() => {
      slideImageIn.current.classList.remove('fadeIn');
    }, 100);
  };

  const goToPreviousSlide = () => {
    if (slideCounter === 0) {
      handleSlide(images.length);
      slideCounter = images.length;
    }

    handleSlide(slideCounter);
    slideCounter -= 1;
  };

  const goToNextSlide = () => {
    const slideImageIn = slideImage;

    if (slideCounter === images.length - 1) {
      startSlider();
      slideCounter = -1;
      animateSlide(slideImageIn);
    }

    slideImageIn.current.style.backgroundImage = `url(${images[slideCounter + 1].src})`;
    slideCounter += 1;
    animateSlide(slideImageIn);
  };

  return { goToPreviousSlide, goToNextSlide };
};

export default useSlider;
