// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DataTable, SectionTitle, TableHeading } from 'components';

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { FormGroup, FormGroupProps, I18n } from 'core';
import { ReactComponent as DocumentsIcon } from 'assets/icons/Documents.svg';

import { Colors } from 'styles';

import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg';
import { LoggingService } from 'services';
import { FaqsApi } from 'types/models/communityCustomOnboarding';
import { FORMGROUP_TYPES } from 'core/form/FormGroup/FormGroup';
import FaqsCustomOnboarding from 'types/models/faqsCustomOnboarding';
import styles from './comunidades-custom-onboarding-form.module.scss';
import { SaveDataHandle } from '../../comunidades-custom-onboarding-process-page';
// import { ColorPicker } from '../comunidades-color-picker/comunidades-color-picker';

interface ComunidadesCustomOnboardingFaqsProps {
  communityId: string;
  communityCustomOnboarding: FaqsCustomOnboarding[];
  saveDetails: (customOnboarding: any) => void;
  deleteFaqs: (customOnboarding: any) => void;
}
const ComunidadesCustomOnboardingFaqs = forwardRef<SaveDataHandle, ComunidadesCustomOnboardingFaqsProps>(
  (props: ComunidadesCustomOnboardingFaqsProps, ref) => {
    const { communityCustomOnboarding, saveDetails, deleteFaqs } = props;
    const [errors, setErrors] = useState({});
    const [environmentNew, setenvironmentNew] = useState({ pregunta: '', respuesta: '', idioma: '' });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [environmentNews, setEnvironmentNews] = useState<any>(communityCustomOnboarding);
    const validateForm = (): boolean => {
      setErrors({});
      return true;
    };

    const handleChange = async (path: string, value: string) => {
      setenvironmentNew((prev) => ({
        ...prev,
        [path]: value,
      }));
    };
    const deleteFaq = async (faq: FaqsApi): Promise<void> => {
      deleteFaqs(faq);
    };
    const handleSubmit = (): void => {
      if (validateForm()) {
        LoggingService.log('handleSubmit :: ok', { client: environmentNew });
        saveDetails(environmentNew);
      } else {
        LoggingService.log('handleSubmit :: errors', { client: environmentNew, errors });
      }
    };

    const formFields: Array<FormGroupProps> = [
      {
        label: I18n.t('PreguntaHead'),
        type: 'textarea',
        id: 'pregunta',
        path: 'pregunta',
        name: 'pregunta',
        placeholder: 'pregunta',
        value: environmentNew?.pregunta,
      },
      {
        label: I18n.t('RespuestaHead'),
        type: 'textarea',
        id: 'respuesta',
        path: 'respuesta',
        name: 'respuesta',
        placeholder: 'respuesta',
        value: environmentNew?.respuesta,
      },
      {
        label: I18n.t('IdiomaHead'),
        type: FORMGROUP_TYPES.select,
        id: 'idioma',
        path: 'idioma',
        name: 'idioma',
        value: environmentNew?.idioma,
        options: [
          { label: 'es', value: 'es' },
          { label: 'ca', value: 'ca' },
        ],
      },
    ];

    useImperativeHandle(ref, () => ({
      getData: () => {
        handleSubmit();
      },
    }));

    const headings: Array<TableHeading> = [
      { label: I18n.t('PreguntaHead'), key: 'pregunta' },
      { label: I18n.t('RespuestaHead'), key: 'respuesta' },
      { label: I18n.t('IdiomaHead'), key: 'idioma' },
      {
        label: I18n.t('Actions'),
        type: 'actions',
        key: '',
        actions: [
          {
            label: I18n.t('Delete'),
            // eslint-disable-next-line react/jsx-no-undef
            icon: <TrashIcon height={25} width={25} fill={Colors.COLOR_ELECSUMGREEN} />,
            onActionClick: deleteFaq,
          },
        ],
      },
    ];

    return (
      <div className={styles.root}>
        <div className={styles.flex}>
          <DocumentsIcon height={20} width={20} fill={Colors.COLOR_BLACK} className={styles.DocumentsIcon} />
          <SectionTitle text={I18n.t('ComunidadesCustomOnboardingProcess.form.faqTilte')} />
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.containerForms}>
            <div className={styles.containerForm}>
              {formFields.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  placeholder={formGroup.placeholder}
                  value={formGroup.value}
                  required={formGroup.required}
                  onChange={handleChange}
                  readOnly={formGroup.readOnly}
                  className={formGroup.className}
                  options={formGroup.options}
                  maxLenght={20000}
                />
              ))}
            </div>
          </div>
        </form>

        <div className={styles.tablenews}>
          {communityCustomOnboarding.length !== 0 ? <DataTable headings={headings} rows={environmentNews} /> : null}
        </div>
      </div>
    );
  },
);

export default ComunidadesCustomOnboardingFaqs;
