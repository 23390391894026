import { BehaviorSubject } from 'rxjs';

import configuration from 'config';
import { User, UserAPI } from 'types/models';
import { LoggingService } from 'services';
import { AxiosResponse } from 'axios';
import apiService from './ApiService';
import usersService from './UsersService';

export class AuthService {
  private readonly USER_KEY = 'userKey';

  private readonly PERMISOS_KEY = 'permisosKey';

  private apiUrl = configuration.apiUrl;

  private userSubject = new BehaviorSubject<User | null>(null);

  private permisosSubject = new BehaviorSubject<string[]>([]);

  readonly user$ = this.userSubject.asObservable();

  readonly permisos$ = this.permisosSubject.asObservable();

  readonly http = apiService;

  constructor() {
    this.init();
  }

  private init(): void {
    this.setUser(this.getStoredUser());
    this.setUserPermisos(this.getStoredPermisos());
  }

  private getStoredUser(): User | null {
    let user = null;
    const data = localStorage.getItem(this.USER_KEY);

    if (data) {
      try {
        user = new User().deserialize(JSON.parse(data));
      } catch (error) {
        user = null;
        LoggingService.error({ error });
      }
    }

    return user;
  }

  private getStoredPermisos(): string[] {
    let permisos = null;
    const data = localStorage.getItem(this.PERMISOS_KEY);

    if (data) {
      try {
        permisos = JSON.parse(data);
      } catch (error) {
        permisos = null;
        LoggingService.error({ error });
      }
    }

    return permisos;
  }

  get user(): User | null {
    return this.userSubject.getValue();
  }

  get permisos(): string[] {
    return this.permisosSubject.getValue();
  }

  setUser(user: User | null): void {
    if (user) {
      localStorage.setItem(this.USER_KEY, JSON.stringify(user));
      this.userSubject.next(user);
      this.http.setUser(user);
    } else {
      localStorage.removeItem(this.USER_KEY);
      this.userSubject.next(null);
      this.http.setUser(null);
    }
  }

  setUserPermisos(permisos: string[]): void {
    localStorage.setItem(this.PERMISOS_KEY, JSON.stringify(permisos));

    this.permisosSubject.next(permisos);
    this.http.setPermisos(permisos);
  }

  isLoggedIn(): boolean {
    return this.user !== null;
  }

  isAdmin(): boolean {
    return this.user?.tipoDeUsuario === 'admin';
  }

  isPromotor(): boolean {
    return this.user?.tipoDeUsuario === 'promotor';
  }

  async checkIfClient(nif: string, cups: string): Promise<boolean> {
    const response = await this.http.get(`${this.apiUrl}/auth/es-cliente/${cups}/${nif}/`);

    return response.data === 'si';
  }

  /* signUp(nif: string, cups: string, email: string, password: string): Promise<unknown> {
    return this.http.post(`${this.apiUrl}/auth/register/`, {
      email, password, nif, cups,
    });
  } */

  async logIn(requestNif: string, password: string): Promise<{ user: User | null; permisos: string[] | null }> {
    let user: User | null = null;
    let permisos: string[] = [];

    const response: AxiosResponse<UserAPI> = await this.http.post(`${this.apiUrl}/auth/login/`, {
      username: requestNif,
      password,
    });

    const { token, email, nombre, apellidos, tipoDeUsuario, nif } = response.data;
    if (token) {
      user = new User().deserialize({
        token,
        email,
        nombre,
        apellidos,
        tipoDeUsuario,
        nif,
      });

      this.setUser(user);

      if (tipoDeUsuario !== 'admin') {
        permisos = await usersService.getPermisos(nif);
        this.setUserPermisos(permisos);
      }
    }

    return { user, permisos };
  }

  register(email: string, password: string, cups: string, nif: string): Promise<AxiosResponse> {
    return this.http.post(`${this.apiUrl}/auth/register/`, {
      email,
      password,
      cups,
      nif,
    });
  }

  changePassword(password: string, nuevaPassword: string): Promise<unknown> {
    return this.http.post(`${this.apiUrl}/auth/cambiar-password/`, {
      password,
      nuevaPassword,
    });
  }

  recoverPassword(password: string, token: string): Promise<AxiosResponse> {
    return this.http.post(`${this.apiUrl}/auth/recuperar-password/`, {
      token,
      password,
    });
  }

  solicitarRecoverPassword(nif: string): Promise<AxiosResponse> {
    return this.http.post(`${this.apiUrl}/auth/solicitar-recuperar-password/`, {
      nif,
    });
  }

  eliminarCuenta(nif: string): Promise<AxiosResponse> {
    return this.http.delete(`${this.apiUrl}/usuario/${nif}/`);
  }

  logOut(): void {
    this.setUser(null);
    this.setUserPermisos([]);
  }
}

const authServiceInstance = new AuthService();

export default authServiceInstance;
