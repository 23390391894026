import React from 'react';

import { NavItemI, I18n } from 'core';

import { Colors } from 'styles';

import { ReactComponent as HomeIcon } from 'assets/icons/Home.svg';
import { ReactComponent as ChartsIcon } from 'assets/icons/Charts.svg';
import { ReactComponent as InvoicesIcon } from 'assets/icons/Invoices.svg';
import { ReactComponent as SustainabilityIcon } from 'assets/icons/Sustainability.svg';
import { ReactComponent as DocumentsIcon } from 'assets/icons/Documents.svg';
// import {ReactComponent as EgreenIcon } from "assets/icons/Egreen.svg";

const ICON_SIZE = 20;
const iconColor = Colors.COLOR_GRAY_100;
const activeIconColor = Colors.COLOR_ELECSUMGREEN;

const navigation: Array<NavItemI> = [
  {
    name: I18n.t('Data'),
    url: '/data',

    admin: true,
    children: [
      {
        name: I18n.t('Clients'),
        url: '/data/clients',
      },
      {
        name: I18n.t('Installation'),
        url: '/data/installation',
      },
      {
        name: I18n.t('Study'),
        url: '/data/study',
      },
    ],
  },
  {
    name: I18n.t('Production'),
    url: '/production',
    admin: true,
  },
  // Client nav items
  {
    name: I18n.t('home'),
    url: '/home',
    icon: <HomeIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
    activeIcon: <HomeIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
    client: true,
  },
  {
    name: I18n.t('charts'),
    url: '/charts',
    icon: <ChartsIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
    activeIcon: <ChartsIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
    client: true,
  },
  {
    name: I18n.t('invoices'),
    url: '/invoices',
    icon: <InvoicesIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
    activeIcon: <InvoicesIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
    client: true,
  },
  {
    name: I18n.t('documents'),
    url: '/documents',
    icon: <DocumentsIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
    activeIcon: <DocumentsIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
    client: true,
  },
  {
    name: I18n.t('sustainability'),
    url: '/sustainability',
    icon: <SustainabilityIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
    activeIcon: <SustainabilityIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
    client: true,
  },
  /* {
    name: I18n.t('egreen'),
    url: '/elecsum-green',
    icon: <EgreenIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
    activeIcon: (
      <EgreenIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />
    ),
    client: true,
  }, */
];

export default navigation;
