import React from 'react';
import { ClientInfo } from 'services';
import styles from './ClientsTable.module.scss';

interface HeadingItem {
  label: string;
  key: string;
}
interface ClientsTablePropsI {
  headings: Array<HeadingItem>;
  rows: Array<ClientInfo>;
  onRowClick?: CallableFunction;
}

const ClientsTable = (props: ClientsTablePropsI): JSX.Element => {
  const { headings, rows, onRowClick } = props;
  const handleRowClick = (data: ClientInfo): void => {
    if (onRowClick) {
      onRowClick(data);
    }
  };
  return (
    <div className={styles.root}>
      <table>
        <thead>
          <tr>
            {headings.map((heading) => (
              <th key={heading.label}>{heading.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((clientItem) => (
            <tr
              onClick={(): void => {
                handleRowClick(clientItem);
              }}
            >
              <td>{clientItem.nombre || ''}</td>
              <td>{`${clientItem.garantiaDeProduccion} kWh`}</td>
              <td>{`${clientItem.generacion} kWh`}</td>
              <td>{`${clientItem.porcentajeDeReparto} %`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClientsTable;
