import React from 'react';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { I18n } from 'core';
import UserInfo from 'types/models/userinfo';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import styles from '../UsuarioDetailPage.module.scss';

interface DevicesDataSectionProps {
  userData: UserInfo; // Define the userData prop
}

function DevicesDataSection({ userData }: DevicesDataSectionProps) {
  return (
    <SectionWrapper customClassname={styles.table_wrapper}>
      <SectionTitle text={I18n.t('UsuarioDetailPage.DevicesData.title')} />
      <div>
        <table>
          <thead>
            <tr>
              <th>{I18n.t('UsuarioDetailPage.DevicesData.headers.devices')}</th>
              <th>{I18n.t('UsuarioDetailPage.DevicesData.headers.system')}</th>
            </tr>
          </thead>
          <tbody>
            {(() => {
              if (userData && userData.dispositivos.length > 0) {
                return userData.dispositivos.map((disp: any) => (
                  <tr key={disp.identificador}>
                    <td>{disp.modelo}</td>
                    <td className={styles.sistemaOperativo}>{disp.sistemaOperativo}</td>
                  </tr>
                ));
              }
              return (
                <tr className={styles.Sinboder}>
                  <td className={styles.nodata}>{I18n.t('UsuarioDetailPage.DevicesData.noData')}</td>
                </tr>
              );
            })()}
          </tbody>
        </table>
      </div>
    </SectionWrapper>
  );
}

export default DevicesDataSection;
