import Button from 'components/Button/Button';
import React, { useRef, useState } from 'react';
import cx from 'classnames';
import { I18n } from 'core';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/ArrowLeft_Thin.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight_Thin.svg';
import { Colors } from 'styles';
import { useOutsideClick } from 'hooks';
import { UtilsService } from 'services';
import styles from './MonthPicker.module.scss';

interface MonthPickerPropsI {
  value: Date;
  onChange: CallableFunction;
  customInput?: React.ReactNode;
  anchorSelector: 'left' | 'right';
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MONTHS_KEYS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const MonthPicker = (props: MonthPickerPropsI) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange, value, customInput, anchorSelector } = props;

  const dropdownRef = useRef(null);

  const [show, setShow] = useState<boolean>(false);
  const [selectedYear, setSelectedYear] = useState<number>(value.getFullYear());

  useOutsideClick(dropdownRef, () => {
    setShow(false);
  });

  const handleMonthClicked = (month: number) => {
    const dateSelected = new Date(selectedYear, month);
    onChange(dateSelected);
    setShow(false);
  };

  const generateGrid = () => {
    const rows = [];
    for (let i = 0; i < 4; i += 1) {
      const row = [];
      for (let j = 0; j < 3; j += 1) {
        const monthNumber = i * 3 + j;
        row.push(
          <button onClick={() => handleMonthClicked(monthNumber)} type="button" className={styles.month}>
            {I18n.t(MONTHS_KEYS[monthNumber])}
          </button>,
        );
      }
      rows.push(<div className={styles.monthRow}>{row}</div>);
    }
    return rows;
  };

  return (
    <div ref={dropdownRef} className={styles.root}>
      <div className={styles.header}>
        {customInput || (
          <Button
            className={styles.picker_button}
            type="button"
            variant="secondary"
            text={UtilsService.dateToLocaleString(value, { month: 'short', year: 'numeric' }, true)}
            onClick={(): void => {
              setShow(!show);
            }}
          />
        )}
      </div>
      <div
        style={{
          left: anchorSelector === 'left' ? 0 : undefined,
          right: anchorSelector === 'right' ? 0 : undefined,
        }}
        className={cx(styles.months_selector, { [styles.show]: show })}
      >
        <div className={styles.months_header}>
          <button type="button" className={styles.arrow_button} onClick={() => setSelectedYear(selectedYear - 1)}>
            <ArrowLeftIcon height={20} width={20} fill={Colors.COLOR_ELECSUMGREEN} />
          </button>
          <span className={styles.year}>{selectedYear}</span>
          <button type="button" className={styles.arrow_button} onClick={() => setSelectedYear(selectedYear + 1)}>
            <ArrowRightIcon height={20} width={20} fill={Colors.COLOR_ELECSUMGREEN} />
          </button>
        </div>
        <div className={cx(styles.months_grid)}>{generateGrid()}</div>
      </div>
    </div>
  );
};

export default React.memo(MonthPicker);
