import { SectionTitle, Spinner } from 'components';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import React, { useEffect, useState } from 'react';
import { I18n } from 'core';
import { SolicitudesService, authServiceInstance } from 'services';
import Solicitudes from 'types/models/solicitudes';
import { User } from '@sentry/react';
import { toast } from 'react-toastify';
import WidgetPermission from 'types/enums/WidgetPermission';
import usersService from 'services/remote/UsersService';
import SolicitudForm from './SolicitudForm';
import SolicitudesRecividas from './SolicitudesRecividas';
import TableSolicitud from './TableSolicitud';
import styles from './SolicitudCupsTerceroPage.module.scss';

const SolicitudCupsTerceroPage = (): JSX.Element => {
  const [user] = useState<User | null>(authServiceInstance.user);

  const [solicitudEnviada, setSolicitudEnviada] = useState(false);
  const [listSolicitudes, setSolicitudes] = useState<Solicitudes[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [permisos, setPermisos] = useState<string[]>([]);

  const fetchSolicitudes = async () => {
    if (!user) return;
    const response = await SolicitudesService.getSolicitudesByNIF(user.nif);
    setSolicitudes(response);
    setIsLoading(false);
  };

  const createSolicitudes = async (cups: string) => {
    if (!user) return;
    try {
      await SolicitudesService.createSolicitud(user.nif, cups);
    } catch (e) {
      toast.error(I18n.t('SolicitudCupsTerceroPage.error'));
    } finally {
      fetchSolicitudes();
      setSolicitudEnviada(false);
    }
  };

  const updateSolicitud = async (solicitudId: string, decision: 'si' | 'no') => {
    if (!user) return;
    try {
      await SolicitudesService.updateSolicitud(user.nif, solicitudId, decision);
    } catch (e) {
      toast.error(I18n.t('SolicitudCupsTerceroPage.error'));
    } finally {
      fetchSolicitudes();
      setSolicitudEnviada(false);
    }
  };

  const handleSubmit = (values: any, { resetForm }: { resetForm: () => void }) => {
    // Start loading
    setSolicitudEnviada(true);

    createSolicitudes(values.cups);
    // Clear the form
    resetForm();
  };

  const handleAccept = async (id: string) => {
    try {
      await updateSolicitud(id, 'si');
    } catch (error) {
      toast.error(I18n.t('SolicitudCupsTerceroPage.error'));
    }
  };

  const handleCancel = async (id: string) => {
    try {
      await updateSolicitud(id, 'no');
    } catch (error) {
      toast.error(I18n.t('SolicitudCupsTerceroPage.error'));
    }
  };

  const getPermisosUser = async () => {
    if (user) {
      const response = await usersService.getPermisos(user.nif);
      // const permisos = ['acceso_web_tecnicos'];
      authServiceInstance.setUserPermisos(response);
      setPermisos(response);
    }
    return null; // Devolver null si no hay un usuario definido
  };

  useEffect(() => {
    getPermisosUser();

    fetchSolicitudes();
  }, []);
  const solicitudesEnviadas = (listSolicitudes || []).filter((solicitud) => solicitud.propietario === 'no');
  const solicitudesRecibidas = (listSolicitudes || []).filter((solicitud) => solicitud.propietario !== 'no');

  const hasPermissionCupsAutorizados =
    (user && user.tipoDeUsuario === 'admin') ||
    (permisos && permisos.includes(WidgetPermission.ACCESO_CUPS_AUTORIZADOS));
  return (
    <div>
      <SectionWrapper>
        <SectionTitle text={I18n.t('SolicitudCupsTerceroPage.title')} />

        <SolicitudForm solicitudEnviada={solicitudEnviada} handleSubmit={handleSubmit} />
        {hasPermissionCupsAutorizados && (
          <SectionWrapper>
            <SectionTitle text={I18n.t('SolicitudCupsTerceroPage.titleEnviada')} />

            {isLoading ? (
              <div className={styles.loading_wrapper}>
                <Spinner icon />
              </div>
            ) : (
              listSolicitudes && <TableSolicitud solicitudes={solicitudesEnviadas} />
            )}
          </SectionWrapper>
        )}

        <SectionWrapper>
          <SectionTitle text={I18n.t('SolicitudCupsTerceroPage.titleRecivida')} />
          {isLoading ? (
            <div className={styles.loading_wrapper}>
              <Spinner icon />
            </div>
          ) : (
            listSolicitudes && (
              <SolicitudesRecividas
                solicitudes={solicitudesRecibidas}
                onAccept={handleAccept}
                onCancel={handleCancel}
              />
            )
          )}
        </SectionWrapper>
      </SectionWrapper>
    </div>
  );
};

export default SolicitudCupsTerceroPage;
