import React from 'react';
import { I18n } from 'core';
import { CompanyObjectI, NewsObjectI } from 'types/models/elecsumGreenWeb';
import { Carousel } from 'react-responsive-carousel';
import styles from './ElecsumGreenCompany.module.scss';
import CompanyNew from '../CompanyNew/CompanyNew';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

interface ElecsumGreenCompanyI {
  name: string;
  company: CompanyObjectI;
}

const ElecsumGreenCompany = (props: ElecsumGreenCompanyI): JSX.Element => {
  const { company, name } = props;

  function getTextAlignment(description: string) {
    return description.length < 100 ? 'center' : 'justify';
  }

  return (
    <div className={styles.root}>
      {company.description && (
        <>
          <div className="row mx-0">
            <div className="col text-center px-0">
              <div className={styles.divider} />
              <h2 className={styles.about}>
                {I18n.t('about')}
                {name}
              </h2>
            </div>
          </div>
          <div className={styles.carousel_wrapper}>
            <Carousel
              dynamicHeight
              className={styles.carousel_inner}
              infiniteLoop
              showStatus={false}
              emulateTouch
              centerMode
              centerSlidePercentage={100}
              showThumbs={false}
              showArrows
            >
              {company.images.map((image) => (
                <img src={image} alt="" key={image} />
              ))}
            </Carousel>
          </div>
          <div className={`${styles['company-description']} ${getTextAlignment(company.description)}`}>
            <p className={`${styles.description}`} style={{ textAlign: getTextAlignment(company.description) }}>
              <div dangerouslySetInnerHTML={{ __html: company.description }} />
            </p>
          </div>
        </>
      )}

      {company.news.length > 0 && (
        <>
          <div className="row mx-0">
            <div className="col text-center">
              <div className={styles.divider} />
              <h2 className={styles.environment}>{I18n.t('Our commitment to the Environment')}</h2>
            </div>
          </div>
          <div className="container mt-4">
            {company.news.map(
              (companyNewItem: NewsObjectI): JSX.Element => (
                <CompanyNew key={companyNewItem.headline} companyNew={companyNewItem} />
              ),
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ElecsumGreenCompany;
