import React from 'react';
import { I18n } from 'core';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import { LoadingButton, SectionTitle } from 'components';
import styles from '../UsuarioDetailPage.module.scss';

type ComunidadSelectAndButtonProps = {
  AllCommunities: Array<any> | null;
  CommunitiesPromotor: Array<any> | null;
  onOptionChangeHandler: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  addComunidad: () => void;
  disabledComunidad: boolean;
  loadingAsociarComunidad: boolean;
};

function ComunidadSelectAndButton({
  AllCommunities,
  CommunitiesPromotor,
  onOptionChangeHandler,
  addComunidad,
  disabledComunidad,
  loadingAsociarComunidad,
}: ComunidadSelectAndButtonProps) {
  if (AllCommunities && AllCommunities.length > 0) {
    return (
      <div className={styles.comunidadesMarginTop}>
        <SectionTitle text={I18n.t('Comunidad Data')} />
        <div className={styles.rootSelect}>
          <select onChange={onOptionChangeHandler}>
            <option value="Selecciona Estación" disabled selected>
              {I18n.t('SelecionaComunidad')}
            </option>
            {CommunitiesPromotor && CommunitiesPromotor.length > 0 ? (
              <>
                {AllCommunities.filter((item1) => !CommunitiesPromotor.some((item2) => item2.cau === item1.cau)).map(
                  (option) => (
                    <option value={option.id} key={option.id} id={option.id.toString()} className={styles.option}>
                      {option.nombre}
                    </option>
                  ),
                )}
              </>
            ) : (
              <>
                {AllCommunities.map((option) => (
                  <option value={option.id} key={option.id} id={option.id.toString()} className={styles.option}>
                    {option.nombre}
                  </option>
                ))}
              </>
            )}
          </select>
        </div>
        <div className={styles.saveContainer}>
          <LoadingButton
            type="submit"
            onClick={() => addComunidad()}
            text={I18n.t('Insert comunidad')}
            disabled={disabledComunidad}
            loading={loadingAsociarComunidad}
          />
        </div>
      </div>
    );
  }
  return <FullPageSyncLoader icon />;
}

export default ComunidadSelectAndButton;
