import EnergyData from './energyData';

interface EnergyDataProperties {
  fecha: string;
  generacion: number;
  excedente: number;
  red: number;
  autoconsumo: number;
  consumo: number;
}

interface EnergyDataAPII {
  datos: {
    individual: EnergyDataProperties;
    comunidad: EnergyDataProperties;
  };
  habilitado: 'si' | 'no';
}

export default class EnergyObj {
  individual: EnergyData;

  comunity: EnergyData;

  showWidget: boolean;

  deserialize(input: EnergyDataAPII): EnergyObj {
    if (!input) {
      this.showWidget = false;
      return this;
    }

    this.individual = new EnergyData();
    this.comunity = new EnergyData();

    if (input.habilitado === 'si') {
      this.individual = new EnergyData().deserialize(input.datos.individual);
      this.comunity = new EnergyData().deserialize(input.datos.comunidad);
    }

    this.showWidget = input.habilitado === 'si';

    return this;
  }
}
