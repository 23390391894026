import React, { useEffect, useState } from 'react';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import { useParams } from 'react-router-dom';
import { I18n } from 'core';

import Button from 'components/Button/Button';
import { navigate } from 'navigation';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import UpdateTarifaServiceInstance from 'services/remote/UpdateTarifaService';
import ActualizarTarifaAll from 'types/models/updateTarifaAll';
import styles from './TarifasListPage.module.scss';
import { TarifasListTable } from './tarifas-list-table/tarifas-list-table';

interface ParameterDetalles {
  solicitudID: string;
}

export const TarifasListPage = (): JSX.Element => {
  const [AllSolicitudes, setAllSolicitudes] = useState<ActualizarTarifaAll[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const { solicitudID } = useParams<ParameterDetalles>();

  useEffect(() => {
    if (solicitudID === undefined) {
      UpdateTarifaServiceInstance.getAllSolicitudActualizacion('si', 'no')
        .then((response) => {
          setAllSolicitudes(response);
        })
        .finally(() => {
          setLoadingData(false);
        });
    }
  }, []);

  return (
    <div className={solicitudID !== undefined ? styles.container : styles.container2}>
      {solicitudID !== undefined ? null : (
        <div className={styles.header}>
          <Button
            className={styles.viewmore_button}
            variant="tertiary"
            type="button"
            onClick={(): Promise<void> => navigate('/tecnic/dashboard')}
            text={I18n.t('UsersPage.goDashboard')}
          />
        </div>
      )}
      <SectionWrapper>
        <div className={styles.table_wrapper}>
          {loadingData ? (
            <FullPageSyncLoader icon />
          ) : (
            <TarifasListTable data={AllSolicitudes} solicitudID={solicitudID} />
          )}
        </div>
      </SectionWrapper>
    </div>
  );
};
