import React from 'react';

import { I18n } from 'core';

import styles from './TotalDataTable.module.scss';

interface TotalDataTableI {
  totalEstimation: number;
  totalGuarantee: number;
}
const TotalDataTable = (props: TotalDataTableI): JSX.Element => {
  const { totalEstimation, totalGuarantee } = props;
  return (
    <div className={styles.root}>
      <div className={styles.total_text}>{I18n.t('Total')}</div>
      <div className={styles.total_estimate}>{totalEstimation}</div>
      <div className={styles.total_guarantee}>{totalGuarantee}</div>
    </div>
  );
};

export default TotalDataTable;
