/* eslint-disable react/no-danger */
import React from 'react';

import { FormGroup } from 'core';

import SyncLoader from 'react-spinners/ClipLoader';

import cx from 'classnames';
import { Colors } from 'styles';
import styles from './DataTable.module.scss';

import { OptionsPropsI } from '../Input';
import Button from '../Button/Button';

// import TableClients from './TableClients';

export interface TableItem {
  id: string;
  [key: string]: any;
}

export interface TableHeadingAction {
  onActionClick?: CallableFunction;
  icon?: JSX.Element;
  label?: string;
}

export interface TableHeading {
  label: string;
  type?: 'value' | 'actions' | 'image' | 'input' | 'link' | 'pdf' | 'html';
  actions?: Array<TableHeadingAction>;
  inputType?: string;
  handleLink?: CallableFunction;
  handleInput?: CallableFunction;
  key: string;
  itemsArray?: Array<OptionsPropsI>;
  showDots?: boolean;
}

interface DataTableProps {
  headings: Array<TableHeading>;
  rows: Array<TableItem>;
  loading?: boolean;
  onRowClick?: CallableFunction;
  test?: boolean;
  currentLang?: string;
}

interface CellProps {
  cellKey: string;
  tableItem: TableItem;
  actions?: Array<TableHeadingAction>;
  handleInput?: CallableFunction;
  handleLink?: CallableFunction;
  inputType?: string;
  value?: any;
  itemsArray?: Array<OptionsPropsI>;
  showDots?: boolean;
  currentLang?: string;
}

const FormCell = (props: CellProps): JSX.Element => {
  const { cellKey, tableItem, handleInput, inputType, itemsArray, showDots } = props;

  return (
    <td className={styles.t_content} key={cellKey}>
      <FormGroup
        key={tableItem.id}
        type={inputType || 'text'}
        id={tableItem.id}
        path={cellKey}
        name={tableItem.id}
        placeholder={tableItem.placeholder}
        value={tableItem[cellKey]}
        required={tableItem.required}
        onChange={handleInput}
        options={itemsArray}
        showDots={showDots}
        className={styles.picker}
      />
    </td>
  );
};

const ImageCell = (props: CellProps): JSX.Element => {
  const { cellKey, tableItem } = props;

  return (
    <td className={styles.t_content} key={cellKey}>
      <img src={tableItem[cellKey]} className={styles.image_cell} alt="" />
    </td>
  );
};
const PDFCell = (props: CellProps): JSX.Element => {
  const { cellKey, tableItem } = props;

  return (
    <td className={styles.t_content} key={cellKey}>
      <p className={styles.colorpdf}> {tableItem.nombre}</p>
    </td>
  );
};

const ActionsCell = (props: CellProps): JSX.Element => {
  const { cellKey, tableItem, actions } = props;

  const onActionClick = (action: TableHeadingAction): void => {
    if (action.onActionClick) {
      action.onActionClick(tableItem);
    }
  };

  if (!actions) {
    return <td className={styles.t_content} key={cellKey} />;
  }

  return (
    <td className={styles.t_content} key={cellKey}>
      {actions.map((action: TableHeadingAction, index: number) => {
        if (action.icon) {
          return (
            <div
              key={`${cellKey + index}`}
              className={styles.clicable}
              onClick={(): void => {
                onActionClick(action);
              }}
            >
              {action.icon}
            </div>
          );
        }

        return (
          <>
            <Button
              type="button"
              variant="primary"
              onClick={(): void => {
                onActionClick(action);
              }}
              text={action.label ? action.label : ''}
            />
          </>
        );
      })}
    </td>
  );
};

const DataCell = (props: CellProps): JSX.Element => {
  const { cellKey, tableItem } = props;
  return (
    <td className={styles.t_content} key={cellKey}>
      {tableItem[cellKey]}
    </td>
  );
};
const DataCellHtml = (props: CellProps): JSX.Element => {
  const { cellKey, tableItem } = props;
  return (
    <td className={styles.t_content_html} key={cellKey} dangerouslySetInnerHTML={{ __html: tableItem[cellKey] }} />
  );
};
const DataCellTest = (props: CellProps): JSX.Element => {
  const { cellKey, tableItem, currentLang } = props;
  if (cellKey === 'emailContacto') {
    return (
      <td className={styles.t_content} key={cellKey}>
        {tableItem[cellKey]}
      </td>
    );
  }
  if (cellKey === 'idioma') {
    return (
      <td className={styles.t_content} key={cellKey}>
        {currentLang}
      </td>
    );
  }
  return (
    <td className={styles.t_content} key={cellKey}>
      {currentLang === 'es' ? tableItem[cellKey].es : tableItem[cellKey].ca}
    </td>
  );
};
const DataCellLink = (props: CellProps): JSX.Element => {
  const { cellKey, tableItem, actions, handleLink } = props;

  const onLinkClick = (): void => {
    if (handleLink) {
      handleLink(tableItem);
    }
  };

  if (!actions) {
    return (
      <td className={styles.t_content} key={cellKey}>
        <button type="button" onClick={onLinkClick}>
          {tableItem[cellKey]}
        </button>
      </td>
    );
  }

  return (
    <td className={styles.t_content} key={cellKey}>
      <button type="button" onClick={onLinkClick}>
        {tableItem[cellKey]}
      </button>
    </td>
  );
};
function isImage(url: any) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}
const DataTable = (props: DataTableProps): JSX.Element => {
  const { headings, rows, loading, onRowClick, test = false, currentLang } = props;

  const handleRowClick = (data: TableItem): void => {
    if (onRowClick) {
      onRowClick(data);
    }
  };
  if (loading) {
    return (
      <div className={styles.loading}>
        <SyncLoader size={100} color={Colors.COLOR_ELECSUMGREEN} />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <table>
        <thead>
          <tr>
            {headings.map((heading: TableHeading) => (
              <td className={styles.title} key={heading.key}>
                {heading.label}
              </td>
            ))}
          </tr>
        </thead>

        <tbody className={styles.t_body}>
          {rows.map((tableItem) => (
            <tr
              onClick={(): void => {
                handleRowClick(tableItem);
              }}
              className={cx(styles.t_row, { [styles.clicable]: onRowClick })}
              key={tableItem.id}
            >
              {headings.map((heading: TableHeading) => {
                if (heading.type === 'image') {
                  return isImage(tableItem[heading.key]) ? (
                    <ImageCell key={heading.key} cellKey={heading.key} tableItem={tableItem} />
                  ) : (
                    <PDFCell key={heading.key} cellKey={heading.key} tableItem={tableItem} />
                  );
                }

                if (heading.type === 'actions') {
                  return (
                    <ActionsCell
                      key={heading.key}
                      cellKey={heading.key}
                      tableItem={tableItem}
                      actions={heading.actions}
                    />
                  );
                }

                if (heading.type === 'input') {
                  return (
                    <FormCell
                      key={heading.key}
                      cellKey={heading.key}
                      tableItem={tableItem}
                      handleInput={heading.handleInput}
                      inputType={heading.inputType}
                      showDots={heading.showDots}
                      itemsArray={heading.itemsArray}
                    />
                  );
                }

                if (heading.type === 'link') {
                  return (
                    <DataCellLink
                      key={heading.key}
                      cellKey={heading.key}
                      tableItem={tableItem}
                      handleLink={heading.handleLink}
                    />
                  );
                }

                if (heading.type === 'html') {
                  return <DataCellHtml key={heading.key} cellKey={heading.key} tableItem={tableItem} />;
                }

                if (test !== false) {
                  return (
                    <DataCellTest
                      key={heading.key}
                      cellKey={heading.key}
                      tableItem={tableItem}
                      currentLang={currentLang}
                    />
                  );
                }
                return <DataCell key={heading.key} cellKey={heading.key} tableItem={tableItem} />;
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
