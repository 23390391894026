import { Serializable } from '../interfaces';

export interface InstallationAPI {
  id?: string;
  cau?: string;
  nombre: string;
  coste: number;
  domicilio: string;
  numeroDePaneles: number;
  potencia: number;
  numeroDeInversores: number;
  potenciaInversor: number;
  potenciaTotal: number;
  orientacion: string;
  inclinacion: string;
  ratioEnergetico: number;
  garantia: string;
  garantiaAños: number; // REVISAR ENUM API
  contratoMantenimiento: string;
  tipoDeInstalacionLegalizacion: string; // REVISAR ENUM API
  fechaRecepcionObra: string;
  fechaInicioSeguimientoProduccion: string;
  numeroDeSerieContadorDeConsumo: string;
  panelFabricante: string;
  panelModelo: string;
  panelInversor: number;
  panelDegradacion0Años: number;
  panelDegradacion1Años: number;
  panelDegradacion2Años: number;
  panelDegradacion10Años: number;
  panelDegradacion25Años: number;
  inversorFabricante: string;
  inversorModelo: string;
  inversorInversor: number;
  inversorDegradacion0Años: number;
  latitud: number;
  longitud: number;
  seguimientoRadioInstalaciones: number;
  seguimientoDesviacion: number;
  notas: string;
  poblacion: string;
  provincia: string;
  idElecsum: number;
  cil: string;
  nombreInstalacion: string;
  nombreTitular: string;
  apellidosTitular: string;
  domicilioTitular: string;
  poblacionTitular: string;
  provinciaTitular: string;
  codigoPostarTitular: string;
}

export default class Installation implements Serializable<Installation> {
  cau?: string;

  contratoMantenimiento: string;

  coste: number;

  domicilio: string;

  fechaInicioSeguimientoProduccion: string;

  fechaRecepcionObra: string;

  garantia: string;

  garantiaAños: number; // REVISAR ENUM API

  id: string;

  inclinacion: string;

  inversorDegradacion0Años: number;

  inversorFabricante: string;

  inversorInversor: number;

  inversorModelo: string;

  latitud: number;

  longitud: number;

  nombre: string;

  notas: string;

  numeroDeInversores: number;

  numeroDePaneles: number;

  numeroDeSerieContadorDeConsumo: string;

  orientacion: string;

  panelDegradacion0Años: number;

  panelDegradacion10Años: number;

  panelDegradacion1Años: number;

  panelDegradacion25Años: number;

  panelDegradacion2Años: number;

  panelFabricante: string;

  panelInversor: number;

  panelModelo: string;

  poblacion: string;

  potencia: number;

  potenciaInversor: number;

  potenciaTotal: number;

  provincia: string;

  ratioEnergetico: number;

  seguimientoDesviacion: number;

  seguimientoRadioInstalaciones: number;

  tipoDeInstalacionLegalizacion: string; // REVISAR ENUM API

  // NEW

  idElecsum: number;

  cil: string;

  nombreInstalacion: string;

  nombreTitular: string;

  apellidosTitular: string;

  domicilioTitular: string;

  poblacionTitular: string;

  provinciaTitular: string;

  codigoPostarTitular: string;

  deserialize(input: InstallationAPI): Installation {
    if (!input) {
      return this;
    }
    this.cau = input.cau || '';
    this.contratoMantenimiento = input.contratoMantenimiento || '';
    this.coste = input.coste || 0;
    this.domicilio = input.domicilio || '';
    this.garantia = input.garantia || '';
    this.garantiaAños = input.garantiaAños || 0;
    this.id = input.id || '';
    this.inclinacion = input.inclinacion || '';
    this.nombre = input.nombre || '';
    this.nombre = input.nombre || '';
    this.numeroDeInversores = input.numeroDeInversores || 0;
    this.numeroDePaneles = input.numeroDePaneles || 0;
    this.orientacion = input.orientacion || '';
    this.potencia = input.potencia || 0;
    this.potenciaInversor = input.potenciaInversor || 0;
    this.potenciaTotal = input.potenciaTotal || 0;
    this.ratioEnergetico = input.ratioEnergetico || 0;
    this.tipoDeInstalacionLegalizacion = input.tipoDeInstalacionLegalizacion || '';
    this.fechaRecepcionObra = input.fechaRecepcionObra || '';
    this.fechaInicioSeguimientoProduccion = input.fechaInicioSeguimientoProduccion || '';
    this.numeroDeSerieContadorDeConsumo = input.numeroDeSerieContadorDeConsumo || '';
    this.panelFabricante = input.panelFabricante || '';
    this.panelModelo = input.panelModelo || '';
    this.panelInversor = input.panelInversor || 0;
    this.panelDegradacion0Años = input.panelDegradacion0Años || 0;
    this.panelDegradacion1Años = input.panelDegradacion1Años || 0;
    this.panelDegradacion25Años = input.panelDegradacion2Años || 0;
    this.panelDegradacion10Años = input.panelDegradacion10Años || 0;
    this.panelDegradacion25Años = input.panelDegradacion25Años || 0;
    this.inversorFabricante = input.inversorFabricante || '';
    this.inversorModelo = input.inversorModelo || '';
    this.inversorInversor = input.inversorInversor || 0;
    this.inversorDegradacion0Años = input.inversorDegradacion0Años || 0;
    this.latitud = input.latitud || 0;
    this.longitud = input.longitud || 0;
    this.seguimientoRadioInstalaciones = input.seguimientoRadioInstalaciones || 0;
    this.seguimientoDesviacion = input.seguimientoDesviacion || 0;
    this.notas = input.notas || '';
    this.poblacion = input.poblacion || '';
    this.provincia = input.provincia || '';
    this.idElecsum = input.idElecsum || 0;
    this.cil = input.cil || '';
    this.nombreInstalacion = input.nombreInstalacion || '';
    this.nombreTitular = input.nombreTitular || '';
    this.apellidosTitular = input.apellidosTitular || '';
    this.domicilioTitular = input.domicilioTitular || '';
    this.poblacionTitular = input.poblacionTitular || '';
    this.provinciaTitular = input.provinciaTitular || '';
    this.codigoPostarTitular = input.codigoPostarTitular || '';
    return this;
  }

  serialize(): InstallationAPI {
    return {
      cau: this.cau,
      contratoMantenimiento: this.contratoMantenimiento,
      coste: this.coste,
      fechaInicioSeguimientoProduccion: this.fechaInicioSeguimientoProduccion,
      fechaRecepcionObra: this.fechaRecepcionObra,
      garantia: this.garantia,
      garantiaAños: this.garantiaAños,
      inclinacion: this.inclinacion,
      inversorDegradacion0Años: this.inversorDegradacion0Años,
      inversorFabricante: this.inversorFabricante,
      inversorInversor: this.inversorInversor,
      inversorModelo: this.inversorModelo,
      latitud: this.latitud,
      longitud: this.longitud,
      nombre: this.nombre,
      notas: this.notas,
      numeroDeInversores: this.numeroDeInversores,
      numeroDePaneles: this.numeroDePaneles,
      numeroDeSerieContadorDeConsumo: this.numeroDeSerieContadorDeConsumo,
      orientacion: this.orientacion,
      panelDegradacion0Años: this.panelDegradacion0Años,
      panelDegradacion10Años: this.panelDegradacion10Años,
      panelDegradacion1Años: this.panelDegradacion1Años,
      panelDegradacion25Años: this.panelDegradacion25Años,
      panelDegradacion2Años: this.panelDegradacion2Años,
      panelFabricante: this.panelFabricante,
      panelInversor: this.panelInversor,
      panelModelo: this.panelModelo,
      potencia: this.potencia,
      potenciaInversor: this.potenciaInversor,
      potenciaTotal: this.potenciaTotal,
      ratioEnergetico: this.ratioEnergetico,
      seguimientoDesviacion: this.seguimientoDesviacion,
      seguimientoRadioInstalaciones: this.seguimientoRadioInstalaciones,
      tipoDeInstalacionLegalizacion: this.tipoDeInstalacionLegalizacion,
      idElecsum: this.idElecsum,
      cil: this.cil,
      nombreInstalacion: this.nombreInstalacion,
      nombreTitular: this.nombreTitular,
      apellidosTitular: this.apellidosTitular,
      domicilioTitular: this.domicilioTitular,
      poblacionTitular: this.poblacionTitular,
      provinciaTitular: this.provinciaTitular,
      codigoPostarTitular: this.codigoPostarTitular,
    } as InstallationAPI;
  }

  setAttr<T>(key: string, value: T): void {
    Object.assign(this, { [key]: value });
  }

  deserializeArray(inputArray: Array<InstallationAPI>): Array<Installation> {
    // eslint-disable-next-line max-len
    const installations: Array<Installation> = inputArray.map((input) => new Installation().deserialize(input));

    return installations;
  }
}
