import FullPageSyncLoader from 'components/FullPageSyncLoader';
import ContractSummary from 'types/models/widgetsData/contractSummaryObj';
import React, { useEffect, useState } from 'react';
import { clientServiceInstance } from 'services';
import { toast } from 'react-toastify';
import { I18n } from 'core';
import {
  ContractSummaryWidget,
  MaximetersWidget,
  MensualConsumptionWidget,
  MensualConsumptionWidgetTest,
} from 'components/widgets';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCUPS, fetchCUPSSuccess } from 'redux/actions/CupsActions';
import CIMServiceInstance from 'services/remote/CIMService';
import { Maximeters, MonthlyConsumption } from 'types/models/widgetsCIM';

import { GlobalStoreState } from 'types/state';
import { navigate } from 'navigation';
import { useRole } from 'hooks';
import { Roles } from 'types/enums';
import ComercialitzationSummary from 'types/models/widgetsData/comercializationSummaryObj';
import ComercializationSummaryWidget from 'components/widgets/ComercializationSummaryWidget';
import styles from './CIMDashboardPage.module.scss';

const CIMDashboardPage = (props: any): JSX.Element => {
  // Props
  const {
    match: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      params: { cupsID },
    },
  } = props;

  // Hooks
  const dispatch = useDispatch();
  const role = useRole();
  const cupsSelected = useSelector((state: GlobalStoreState) => state.clientCUPS.selectedCups);

  // State
  const [resumenContrato, setResumenContrato] = useState<ContractSummary | null>(null);
  const [resumenComercializacion, setResumenComercializacion] = useState<ComercialitzationSummary | null>(null);
  const [maximeters, setMaximeters] = useState<Maximeters | null>(null);

  const [monthlyConsumption, setMonthlyConsumption] = useState<MonthlyConsumption | null>(null);

  const [monthlyConsumptionYear, setMonthlyConsumptionYear] = useState<number>(new Date().getFullYear());
  const [monthlyConsumptionTarifa, setMonthlyConsumptionTarifa] = useState<MonthlyConsumption['datos']>();

  const [loadingMonthlyConsumption, setLoadingMonthlyConsumption] = useState<boolean>(false);
  // Effects
  useEffect(() => {
    dispatch(fetchCUPS());
    clientServiceInstance.getClient(cupsID).then((response) => {
      response.setAttr('cups', cupsID);
      dispatch(fetchCUPSSuccess(response));
    });
  }, [cupsID]);

  // useEffect(() => {
  //   if (role !== Roles.CLIENT) return;
  //   if (!authServiceInstance.user?.nif) return;
  //   cupsServiceInstance.getCupsFromUserId(authServiceInstance.user?.nif).then((response) => {
  //     if (response.length > 0) {
  //       const [firstCups] = response;
  //       if (!cupsID) {
  //         navigate(`/client/${firstCups.cups}/home`);
  //       }
  //       const cupsById = response.find((item) => item.cups === cupsID);
  //       dispatch(updateClientCUPS(cupsById || firstCups));
  //       dispatch(fetchClientCUPSListSuccess(response));
  //     }
  //   });
  // }, [cupsID, role]);

  useEffect(() => {
    if (role !== Roles.CLIENT) return;
    if (cupsSelected) {
      if (cupsSelected.cups !== cupsID) {
        navigate(`/client/cim/${cupsSelected.cups}/dashboard`);
      }
    }
  }, [cupsSelected, role]);

  // Fetch Resumen contrato
  useEffect(() => {
    clientServiceInstance
      .getContractSummary(cupsID)
      .then((response: ContractSummary) => {
        setResumenContrato(response);
      })
      .catch(() => {
        toast.error(I18n.t('CIM.DashboardPage.errors.contractSummaryError'));
      });
  }, [cupsID]);

  // Fetch Maximeters
  useEffect(() => {
    CIMServiceInstance.getMaximeters(cupsID)
      .then((response) => {
        setMaximeters(response);
      })
      .catch(() => {
        toast.error(I18n.t('CIM.DashboardPage.errors.maximetersError'));
      });
  }, [cupsID]);

  useEffect(() => {
    clientServiceInstance
      .getComercializationSummary(cupsID)
      .then((response) => {
        setResumenComercializacion(response);
      })
      .catch(() => {
        toast.error('Error');
      });
  }, [cupsID]);

  // Fetch Monthly Consumption
  useEffect(() => {
    setLoadingMonthlyConsumption(true);
    CIMServiceInstance.getMonthlyConsumption(cupsID, monthlyConsumptionYear.toString()).then((response) => {
      setMonthlyConsumption(response);
      setLoadingMonthlyConsumption(false);
    });
  }, [cupsID, monthlyConsumptionYear]);

  useEffect(() => {
    CIMServiceInstance.getMonthlyConsumptionTarifa(cupsID, monthlyConsumptionYear.toString()).then((response) => {
      if (response.datos) {
        setMonthlyConsumptionTarifa(response.datos);
      }
    });
  }, [cupsID, monthlyConsumptionYear]);
  return (
    <div className={styles.root}>
      {monthlyConsumptionTarifa &&
      resumenContrato &&
      resumenContrato.tarifa === '2.0TD' &&
      resumenContrato.tarifaComercializadora !== 'FLEXA3' ? (
        <div className={styles.widget_consum_mensual_Tarifa}>
          <MensualConsumptionWidgetTest cupsID={cupsID} title={I18n.t('MensualConsumptionWidget.titleTarifa')} />
        </div>
      ) : null}

      <div className={styles.widget_consum_mensual}>
        {monthlyConsumption ? (
          <MensualConsumptionWidget
            loading={loadingMonthlyConsumption}
            handleNextYear={() => setMonthlyConsumptionYear(monthlyConsumptionYear + 1)}
            handlePrevYear={() => setMonthlyConsumptionYear(monthlyConsumptionYear - 1)}
            dataset={monthlyConsumption.datos}
            title={I18n.t('MensualConsumptionWidget.title')}
            yearPicked={monthlyConsumptionYear}
          />
        ) : (
          <FullPageSyncLoader icon />
        )}
      </div>

      <div className={styles.widget_contract}>
        {resumenContrato ? (
          <ContractSummaryWidget
            codigoCliente={resumenContrato.codigoCliente}
            tarifa={resumenContrato.tarifa}
            tarifaComercializadora={resumenContrato.tarifaComercializadora}
            porcentajeExencionImpuesto={resumenContrato.porcentajeExencionImpuesto}
            porcentajeElectricidadBonificacion={resumenContrato.porcentajeElectricidadBonificacion}
            p1={resumenContrato.potencias.potencia1}
            p2={resumenContrato.potencias.potencia2}
            p3={resumenContrato.potencias.potencia3}
            p4={resumenContrato.potencias.potencia4}
            p5={resumenContrato.potencias.potencia5}
            p6={resumenContrato.potencias.potencia6}
            eolica={resumenContrato.tecnologia.eolica === 'si'}
            fotovoltaica={resumenContrato.tecnologia.fotovoltaica === 'si'}
            instalaciones={resumenContrato.instalaciones || []}
            comercializado={resumenContrato.comercializado === 'si'}
          />
        ) : (
          <FullPageSyncLoader icon />
        )}
      </div>
      {(() => {
        if (resumenContrato) {
          if (resumenContrato.comercializado === 'si') {
            if (resumenComercializacion) {
              return (
                <div className={styles.widget_maxim}>
                  <ComercializationSummaryWidget data={resumenComercializacion} />
                </div>
              );
            }
          }
        }
        return (
          <div className={styles.widget_maxim}>
            <FullPageSyncLoader icon />
          </div>
        );
      })()}
      {(() => {
        if (maximeters) {
          if (maximeters.datos.length > 0) {
            return (
              <div className={styles.widget_maxim}>
                <MaximetersWidget maximeters={maximeters} />
              </div>
            );
          }
          return null;
        }

        return (
          <div className={styles.widget_maxim}>
            <FullPageSyncLoader icon />
          </div>
        );
      })()}
    </div>
  );
};

export default CIMDashboardPage;
