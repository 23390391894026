import { AxiosResponse } from 'axios';
import configuration from 'config';
import { TranslatableJSON } from 'types/interfaces/polyglot';

import { PromotorCustomOnboarding, PromotorCustomOnboardingAPII } from 'types/models';
import apiService from './ApiService';

export type PromotorCustomOnboardingUpdate = Omit<PromotorCustomOnboardingAPII, 'imagenes'>;
export interface TipodecontactoPost {
  emailContacto: string;
  tipoContacto: TranslatableJSON<string>;
}
export class PromotorCustomOnboardingService {
  readonly http = apiService;

  private apiUrl = configuration.apiUrl;

  async getPromotorCustomOnboarding(nif: string): Promise<PromotorCustomOnboarding> {
    const url = `${this.apiUrl}/promotor/${nif}/personalizacion/`;
    const response: AxiosResponse<{ datos: PromotorCustomOnboardingAPII }> = await this.http.get(url);
    const result = new PromotorCustomOnboarding().deserialize(response.data.datos);

    return result;
  }

  async updatePromotorCustomOnboarding(nif: string, customOnboarding: PromotorCustomOnboardingUpdate): Promise<string> {
    const url = `${this.apiUrl}/promotor/${nif}/personalizacion/`;
    const response: AxiosResponse<string> = await this.http.patch(url, customOnboarding);

    return response.data;
  }

  async addImage(nif: string, file: File): Promise<number> {
    const url = `${this.apiUrl}/promotor/${nif}/personalizacion/logo/`;

    const imageData = new window.FormData();

    imageData.append('logo', file);
    const response: AxiosResponse<{ status: number }> = await this.http.post(url, imageData);

    return response.status;
  }

  async deleteImage(nif: string, imgId: string): Promise<unknown> {
    const url = `${this.apiUrl}/promotor/${nif}/personalizacion/logo/${imgId}/`;

    const response: AxiosResponse<number> = await this.http.delete(url);

    return response;
  }
}

const PromotorCustomOnboardingServiceInstance = new PromotorCustomOnboardingService();
export default PromotorCustomOnboardingServiceInstance;
