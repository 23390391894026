import { I18n } from 'core';

export function getMonthFromStringDate(dateString: string, includeYear: boolean): string {
  const [year, month] = dateString.split('-');
  const monthIndex = parseInt(month, 10) - 1;
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const monthTranslated = I18n.t(months[monthIndex]);

  if (includeYear) {
    return `${monthTranslated} ${year}`;
  }
  return monthTranslated;
}
