/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

import { ReactComponent as LandscapeIcon } from 'assets/icons/Landscape.svg';
import { FormGroupProps, FormGroup, I18n } from 'core';
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg';

import { SectionSubTitle, LoadingButton, DataTable, TableHeading, EunoiaImageInput } from 'components';
import { LoggingService, elecsumGreenServiceInstance } from 'services';
import { ElecsumGreenNew, GreenNews } from 'types/models';

import customToast from 'components/CustomToast/CustomToast';
import { Colors } from 'styles';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import styles from './EnvironmentalNews.module.scss';

interface ENPropsI {
  nif: string;
  news: Array<GreenNews>;
  afterSubmitAction?: CallableFunction;
}
const EnvironmentalNews = ({ news, nif, afterSubmitAction }: ENPropsI): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [environmentNew, setEnvironmentNew] = useState<ElecsumGreenNew>(new ElecsumGreenNew());
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [environmentNews, setEnvironmentNews] = useState<Array<GreenNews>>(new Array<GreenNews>());
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState<string>('');

  const formGroupsEnvironmentNewData: Array<FormGroupProps> = [
    {
      label: I18n.t('Headline'),
      type: 'text',
      id: 'titular',
      path: 'titular',
      name: 'titular',
      placeholder: '',
      value: environmentNew.titular,
    },
    {
      label: I18n.t('Date'),
      type: 'date',
      id: 'date',
      path: 'date',
      name: 'date',
      placeholder: I18n.t('News date (yyyy-mm-dd)'),
      value: environmentNew.date,
    },
  ];

  const formGroupsEnvironmentNewDescription: Array<FormGroupProps> = [
    {
      label: I18n.t('News description (max 1000 characters)'),
      type: 'textarea',
      id: 'description',
      path: 'description',
      name: 'description',
      rows: 15,
      cols: 30,
      maxLenght: 1000,
      placeholder: I18n.t('News description'),
      value: environmentNew.description,
    },
  ];

  const validateForm = useCallback((): boolean => {
    // Destructuring environmentNew for easier readability
    const { titular, date, description } = environmentNew;

    // Check if file is present and required fields are defined and not empty strings
    if (!file || !titular || !titular.trim() || !date || !date.trim() || !description || !description.trim()) {
      setErrors({ error: 'Some empty field' });
      setSubmitDisabled(true);
      return false;
    }

    // If all validations pass
    setSubmitDisabled(false);
    setErrors({});
    return true;
  }, [file, environmentNew]);

  const settingFile = (file1: File): void => {
    if (file1) {
      setFile(file1);
      setFileName(file1.name);
    }
  };

  const submitForm = async (): Promise<boolean> => {
    setLoading(true);
    setSubmitDisabled(true);
    // await of([]).pipe(delay(1000)).toPromise();
    try {
      if (file) {
        const response = await elecsumGreenServiceInstance.addElecsumGreenNews(nif, environmentNew, file);
        if (response === 201) {
          customToast.success(I18n.t('NEW saved!'));

          if (afterSubmitAction) {
            afterSubmitAction();
          }
        }
      }
      setLoading(false);
      setSubmitDisabled(false);
    } catch (error) {
      setLoading(false);
      setSubmitDisabled(false);
      toast.error(I18n.t('Error saving new!'));
    }
    setFile(undefined);
    setFileName('');
    return true;
  };
  function isValidDate(dateString: string): boolean {
    const date = new Date(dateString);
    const maxDate = new Date('9999-12-31');
    return !isNaN(date.getTime()) && date <= maxDate;
  }

  function handleChange<T>(path: string, value: T): void {
    // setEnvironmentNew(new EnvironmentNew().deserialize({
    //   ...environmentNew,
    //   [path]: value,
    // }));

    if (path === 'date' && !isValidDate(value as unknown as string)) {
      return;
    }

    environmentNew.setAttr<T>(path, value);
    validateForm();
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (validateForm()) {
      LoggingService.log('handleSubmit :: ok', { environmentNew });
      submitForm();

      setEnvironmentNew(
        new ElecsumGreenNew().deserialize({
          ...environmentNew,
          titular: '',
          fecha: '',
          descripcion: '',
          imagenUrl: '',
        }),
      );
    } else {
      LoggingService.log('handleSubmit :: errors', { environmentNew, errors });
      toast.error(I18n.t('Error: Some empty field'));
    }
  };

  useEffect(() => {
    // const getEnvironmentalNews = async (): Promise<void> => {
    //   const news = await environmentNewServiceInstance.getAllEnvironmetalNews();
    //   setEnvironmentNews(news || new Array<GreenNews>());
    // };
    // getEnvironmentalNews();

    if (news.length > 0) {
      setEnvironmentNews(news);
    } else {
      setEnvironmentNews([]);
    }
    setSubmitDisabled(!validateForm());
  }, [news, validateForm]);

  const deleteEnvironmentNew = async (eGreenNew: GreenNews): Promise<void> => {
    // eslint-disable-next-line no-alert
    const accept = window.confirm(I18n.t('Are you sure you want to delete the new?'));

    if (!accept) {
      return;
    }

    try {
      await elecsumGreenServiceInstance.deleteElecsumGreenNews(nif, eGreenNew.id);

      customToast.success(`${I18n.t('News deleted successfully')}!`);

      if (afterSubmitAction) {
        afterSubmitAction();
      }

      setSubmitDisabled(false);
    } catch (error) {
      toast.error(`${I18n.t('Error deleting new')}.`);
    }
  };

  const headings: Array<TableHeading> = [
    { label: I18n.t('Image'), type: 'image', key: 'imagenUrl' },
    { label: I18n.t('Headline'), key: 'titular' },
    { label: I18n.t('Date'), key: 'fecha' },
    { label: I18n.t('Description'), type: 'html', key: 'descripcion' },
    {
      label: I18n.t('Actions'),
      type: 'actions',
      key: '',
      actions: [
        {
          label: I18n.t('Delete'),
          icon: <TrashIcon height={25} width={25} fill={Colors.COLOR_ELECSUMGREEN} />,
          onActionClick: deleteEnvironmentNew,
        },
      ],
    },
  ];

  return (
    <SectionWrapper customClassname={styles.root}>
      <SectionSubTitle text={I18n.t('Environment news')} />

      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.fields_container}>
          <div className={styles.fields_group}>
            {formGroupsEnvironmentNewData.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                placeholder={formGroup.placeholder}
                value={formGroup.value}
                required={formGroup.required}
                onChange={handleChange}
              />
            ))}
            <div className="image mt-5">
              <EunoiaImageInput
                id="img-news"
                label={I18n.t('add galery image')}
                note={I18n.t('We recommend 4 3 ratio and max 4MB')}
                fileName={fileName}
                icon={<LandscapeIcon height={20} width={20} fill={Colors.COLOR_GRAY_400} />}
                action={settingFile}
              />
            </div>
          </div>
          <div className={styles.fields_group}>
            {formGroupsEnvironmentNewDescription.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                value={formGroup.value}
                required={formGroup.required}
                placeholder={formGroup.placeholder}
                onChange={handleChange}
              />
            ))}
          </div>
        </div>
        <div className={styles.actions}>
          <LoadingButton type="submit" text={I18n.t('Insert new')} disabled={submitDisabled} loading={loading} />
        </div>
      </form>

      <div className="table-news">
        {environmentNews && environmentNews.length > 0 ? (
          <DataTable headings={headings} rows={environmentNews} loading={loading} />
        ) : null}
      </div>
    </SectionWrapper>
  );
};

export default EnvironmentalNews;
