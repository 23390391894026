import { DataTable, EunoiaImageInput, LoadingButton, SectionTitle, TableHeading } from 'components';
import React, { useState } from 'react';
import { I18n } from 'core';
import { ReactComponent as LandscapeIcon } from 'assets/icons/Landscape.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg';
import { Colors } from 'styles';
import CommunitiesServiceIntance from 'services/remote/communities-service';
import customToast from 'components/CustomToast/CustomToast';
import { toast } from 'react-toastify';
import { LoggingService } from 'services';
import styles from './comunidades-detalle-image-form.module.scss';

interface ImageI {
  id: string;
  url: string;
}

interface ComunidadesDetalleImageFormPageLogoProps {
  logo: Array<ImageI>;
  afterSubmitAction?: CallableFunction;
  id: string;
}
export const ComunidadesDetalleImageFormPageLogo = ({
  id,
  logo,
  afterSubmitAction,
}: ComunidadesDetalleImageFormPageLogoProps): JSX.Element => {
  // const [image] = logo;
  // const [fileName, setFileName] = useState<string>(image?.url);
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const validateForm = (): boolean => {
    if (!file) {
      setSubmitDisabled(true);
      setErrors({});
      return false;
    }
    setSubmitDisabled(false);
    setErrors({});
    return true;
  };
  const submitForm = async (type: string): Promise<boolean> => {
    setLoading(true);
    setSubmitDisabled(true);

    try {
      if (file) {
        const response = await CommunitiesServiceIntance.addGaleryImageLogo(id, file, type);
        if (response === 201) {
          customToast.success(I18n.t('Logo saved!'));

          if (afterSubmitAction) {
            afterSubmitAction();
          }

          setSubmitDisabled(false);
        }
      }
    } catch (error) {
      setLoading(false);
      setSubmitDisabled(false);

      toast.error(I18n.t('Error Uploading gallery Logo'));
    }

    setLoading(false);
    setFile(undefined);
    return true;
  };
  const settingFile = (file1: File): void => {
    if (file1) {
      setFile(file1);
      setIsButtonDisabled(false);
    }
  };
  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (validateForm()) {
      LoggingService.log('handleSubmit :: ok', { file });
      submitForm('galeria');
    } else {
      LoggingService.log('handleSubmit :: errors', { file, errors });
    }
  };

  const deleteImage = async (imagedel: any): Promise<void> => {
    // eslint-disable-next-line no-alert
    const accept = window.confirm(I18n.t('Are you sure you want to delete the logo?'));

    if (!accept) {
      return;
    }

    try {
      await CommunitiesServiceIntance.deleteImageLogo(id, imagedel.id);

      customToast.success(`${I18n.t('Logo deleted successfully')}!`);

      if (afterSubmitAction) {
        afterSubmitAction();
      }
      setSubmitDisabled(false);
    } catch (error) {
      toast.error(`${I18n.t('Error deleting logo')}.`);
    }
  };

  const headings: Array<TableHeading> = [
    { label: I18n.t('Logo'), type: 'image', key: 'url' },

    {
      label: I18n.t('Actions'),
      type: 'actions',
      key: '',
      actions: [
        {
          label: I18n.t('Delete'),
          icon: <TrashIcon height={25} width={25} fill={Colors.COLOR_ELECSUMGREEN} />,
          onActionClick: deleteImage,
        },
      ],
    },
  ];

  return (
    <>
      <div className={styles.flex}>
        <LandscapeIcon height={20} width={20} fill={Colors.COLOR_BLACK} className={styles.LandscapeIcon} />
        <SectionTitle text={I18n.t('ComunidadesDetallePage.imageData.Logo')} />
      </div>
      {logo.length <= 0 ? (
        <form className={styles.form} onSubmit={onSubmit}>
          <div className={styles.fields}>
            <EunoiaImageInput
              id="img-galeria-logo"
              label={I18n.t('add galery logo')}
              note={I18n.t('(Must be 160x200px *)')}
              fileName=""
              icon={<LandscapeIcon height={20} width={20} fill={Colors.COLOR_GRAY_400} />}
              type="galeria"
              action={settingFile}
            />
          </div>
          <div className={styles.actions}>
            <LoadingButton
              type="submit"
              text={I18n.t('add logo')}
              disabled={isButtonDisabled || submitDisabled}
              loading={loading}
            />
          </div>
        </form>
      ) : null}

      <div className="col-12">
        <div className="table-news">
          {logo && logo.length > 0 ? <DataTable headings={headings} rows={logo} loading={loading} /> : null}
        </div>
      </div>
    </>
  );
};
