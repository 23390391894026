import React from 'react';
import styles from './ElecsumCheckbox.module.scss';

interface ElecsumCheckboxPropsI {
  label?: string;
}

const ElecsumCheckbox = (
  props: JSX.IntrinsicAttributes &
    React.ClassAttributes<HTMLInputElement> &
    ElecsumCheckboxPropsI &
    React.InputHTMLAttributes<HTMLInputElement>,
): JSX.Element => {
  const { label } = props;

  return (
    <label className={styles.container}>
      {label}
      <input type="checkbox" {...props} />
      <span className={styles.checkmark} />
    </label>
  );
};

export default ElecsumCheckbox;
