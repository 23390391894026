import { ProdEnergyProductionDataMonthlyAPII } from '../types/models/widgetsData/prodEnergyProductionDataMonthly';

const PROD_ENERGY_PRODUCTION_M_DATA_MOCK: ProdEnergyProductionDataMonthlyAPII = {
  generacion: 200,
  garantia: 50,
  estimacion: 2000,
  diferenciaGeneracion: -0.5,
  dias: [
    {
      dia: 1,
      generacion: 10,
      garantia: 5,
      estimacion: 0,
    },
    {
      dia: 2,
      generacion: 8,
      garantia: 3,
      estimacion: 0,
    },
    {
      dia: 3,
      generacion: 9,
      garantia: 6,
      estimacion: 0,
    },
    {
      dia: 4,
      generacion: 8,
      garantia: 3,
      estimacion: 0,
    },
    {
      dia: 5,
      generacion: 10,
      garantia: 5,
      estimacion: 0,
    },
    {
      dia: 6,
      generacion: 10,
      garantia: 5,
      estimacion: 0,
    },
    {
      dia: 7,
      generacion: 3800,
      garantia: 500,
      estimacion: 300,
    },
    {
      dia: 8,
      generacion: 4200,
      garantia: 800,
      estimacion: 1000,
    },
    {
      dia: 9,
      generacion: 3700,
      garantia: 1200,
      estimacion: 900,
    },
    {
      dia: 10,
      generacion: 4000,
      garantia: 500,
      estimacion: 500,
    },
    {
      dia: 11,
      generacion: 4000,
      garantia: 900,
      estimacion: 900,
    },
    {
      dia: 12,
      generacion: 4000,
      garantia: 200,
      estimacion: 200,
    },
    {
      dia: 13,
      generacion: 4500,
      garantia: 2000,
      estimacion: 500,
    },
    {
      dia: 14,
      generacion: 4200,
      garantia: 1400,
      estimacion: 600,
    },
    {
      dia: 15,
      generacion: 3800,
      garantia: 1300,
      estimacion: 200,
    },
    {
      dia: 16,
      generacion: 3000,
      garantia: 1100,
      estimacion: 100,
    },
    {
      dia: 17,
      generacion: 2500,
      garantia: 500,
      estimacion: 1000,
    },
    {
      dia: 18,
      generacion: 1000,
      garantia: 200,
      estimacion: 200,
    },
    {
      dia: 19,
      generacion: 8,
      garantia: 3,
      estimacion: 0,
    },
    {
      dia: 20,
      generacion: 10,
      garantia: 5,
      estimacion: 0,
    },
    {
      dia: 21,
      generacion: 8,
      garantia: 3,
      estimacion: 0,
    },
    {
      dia: 22,
      generacion: 10,
      garantia: 5,
      estimacion: 0,
    },
    {
      dia: 23,
      generacion: 10,
      garantia: 5,
      estimacion: 0,
    },
    {
      dia: 24,
      generacion: 10,
      garantia: 5,
      estimacion: 0,
    },
    {
      dia: 25,
      generacion: 10,
      garantia: 5,
      estimacion: 0,
    },
    {
      dia: 26,
      generacion: 10,
      garantia: 5,
      estimacion: 0,
    },
    {
      dia: 27,
      generacion: 10,
      garantia: 5,
      estimacion: 0,
    },
    {
      dia: 28,
      generacion: 10,
      garantia: 5,
      estimacion: 0,
    },
    {
      dia: 29,
      generacion: 10,
      garantia: 5,
      estimacion: 0,
    },
    {
      dia: 30,
      generacion: 10,
      garantia: 5,
      estimacion: 0,
    },
    {
      dia: 31,
      generacion: 10,
      garantia: 5,
      estimacion: 0,
    },
  ],
};

export default PROD_ENERGY_PRODUCTION_M_DATA_MOCK;
