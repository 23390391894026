import { Serializable } from '../../interfaces/serializable';

export interface MeasuremenRegistersAPII {
  datos: Array<Array<string | number | Date>>;
  habilitado: string;
}

export default class MeasuremenRegistersObject implements Serializable<MeasuremenRegistersObject> {
  datos: Array<{
    fecha: Date;
    activa: number;
    inductiva: number;
    capacitiva: number;
    exportada: number;
    neta: number;
    excedente: number;
  }>;

  habilitado: string;

  deserialize(input: MeasuremenRegistersAPII): MeasuremenRegistersObject {
    if (!input) {
      return this;
    }

    this.datos = input.datos
      ? input.datos.map((item) => {
          const inputFecha = item[0] as Date;
          const inputActiva = item[1] as number;
          const inputInductiva = item[2] as number;
          const inputCapacitiva = item[3] as number;
          const inputExportada = item[4] as number;
          const inputNeta = item[5] as number;
          const inputExcedente = item[6] as number;

          return {
            fecha: inputFecha || new Date(),
            activa: inputActiva || 0,
            inductiva: inputInductiva || 0,
            capacitiva: inputCapacitiva || 0,
            exportada: inputExportada || 0,
            neta: inputNeta || 0,
            excedente: inputExcedente || 0,
          };
        })
      : [];

    this.habilitado = input.habilitado || 'no';

    return this;
  }
}
