import React, { useEffect, useState } from 'react';
import InscritosServicentance from 'services/remote/Inscritos';
import { InscritosModel } from 'types/models/inscritos';
import { useParams } from 'react-router-dom';
import { I18n } from 'core';

import Button from 'components/Button/Button';
import { navigate } from 'navigation';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { CommunitiesEnrolledListTable } from './components/communities-enrolled-list-table/communities-enrolled-list-table';
import { CommunitiesEnrolledListFilters } from './components/communities-enrolled-list-filters/communities-enrolled-list-filters';
// import { CommunitiesEnrolledListPaginator } from './components/communities-enrolled-list-paginator/communities-enrolled-list-paginator';

import styles from './comunities-enrolled-list-page.module.scss';

interface ParameterDetalles {
  communityID: string;
}

export const ComunitiesEnrolledListPage = (): JSX.Element => {
  const [communitiesEnrolled, setCommunitiesEnrolled] = useState<InscritosModel[]>([]);
  const { communityID } = useParams<ParameterDetalles>();
  const [filters, setFilters] = useState<string>('');
  const handleFilterChange = (value: any) => {
    setFilters(value);
  };

  const orderArray = (array: InscritosModel[]) =>
    // se ordena primero los no validados
    array.sort((row1, row2) => {
      if (row1.validado === 'no' && row2.validado !== 'no') {
        return -1; // row1 aparece primero
      }
      if (row1.validado !== 'no' && row2.validado === 'no') {
        return 1; // row2 aparece primero
      }
      // Si los valores de validado son iguales, se ordena por fechaInscripcion
      const fecha1 = new Date(row1.fechaInscripcion);
      const fecha2 = new Date(row2.fechaInscripcion);

      if (fecha1 > fecha2) {
        return -1;
      }
      if (fecha1 < fecha2) {
        return 1;
      }

      // Si también tienen la misma fecha de inscripción, se mantiene el orden original
      return 0;
    });
  useEffect(() => {
    if (communityID === undefined) {
      InscritosServicentance.getCommunitiesEnrolled()
        .then((response) => {
          const orderedArray = orderArray(response);
          setCommunitiesEnrolled(orderedArray);
        })
        .finally(() => {});
    } else {
      InscritosServicentance.getInscritosByComunity(communityID)
        .then((response) => {
          const orderedArray = orderArray(response);
          setCommunitiesEnrolled(orderedArray);
        })
        .finally(() => {});
    }
  }, []);
  useEffect(() => {
    if (communityID !== undefined) {
      InscritosServicentance.getInscritosByComunityInscritos(communityID, filters)
        .then((response) => {
          if (filters === '') {
            const orderedArray = orderArray(response);
            setCommunitiesEnrolled(orderedArray);
          } else setCommunitiesEnrolled(response);
        })
        .finally(() => {});
    } else {
      InscritosServicentance.getInscritosByComunityAllInscritos(filters)
        .then((response) => {
          if (filters === '') {
            const orderedArray = orderArray(response);
            setCommunitiesEnrolled(orderedArray);
          } else setCommunitiesEnrolled(response);
        })
        .finally(() => {});
    }
  }, [filters]);

  return (
    <div className={communityID !== undefined ? styles.container : styles.container2}>
      {communityID !== undefined ? null : (
        <div className={styles.header}>
          <Button
            className={styles.viewmore_button}
            variant="tertiary"
            type="button"
            onClick={(): Promise<void> => navigate('/tecnic/dashboard')}
            text={I18n.t('UsersPage.goDashboard')}
          />
        </div>
      )}
      <SectionWrapper>
        <CommunitiesEnrolledListFilters handleChange={handleFilterChange} />

        <div className={styles.table_wrapper}>
          <CommunitiesEnrolledListTable data={communitiesEnrolled} communityID={communityID} />
        </div>
        <div>
          {/* <CommunitiesEnrolledListPaginator current={0} max={1} handleNext={() => {}} handlePrev={() => {}} /> */}
        </div>
      </SectionWrapper>
    </div>
  );
};
