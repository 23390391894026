import { USER_ACTIONS } from 'redux/actions/types';
import { ProcessStatus } from 'types/enums';
import { UsersState } from 'types/state';

const initialState: UsersState = { selectedUser: null, selectedUserStatus: ProcessStatus.STANDBY };

function UsersReducer(state = initialState, action: any): UsersState {
  switch (action.type) {
    case USER_ACTIONS.FETCH_USER:
      return {
        ...state,
        selectedUser: null,
        selectedUserStatus: ProcessStatus.LOADING,
      };

    case USER_ACTIONS.FETCH_USER_OK: {
      const { payload } = action;
      return {
        ...state,
        selectedUser: payload,
        selectedUserStatus: ProcessStatus.SUCCESS,
      };
    }

    case USER_ACTIONS.FETCH_USER_ERROR: {
      return {
        ...state,
        selectedUser: null,
        selectedUserStatus: ProcessStatus.ERROR,
      };
    }

    case USER_ACTIONS.RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
}

export default UsersReducer;
