/* eslint-disable react/no-array-index-key */
import { Card } from 'components';
import React from 'react';
import { ReactComponent as MaximeterCurveIcon } from 'assets/icons/MaximeterCurve.svg';
import { I18n } from 'core';
import { Maximeters } from 'types/models/widgetsCIM';
import wattsConverterService from 'services/local/WattsConverter';
import styles from './MaximetersWidget.module.scss';

interface MaximetersWidgetPropsI {
  maximeters: Maximeters;
}

const MaximetersWidget = (props: MaximetersWidgetPropsI): JSX.Element => {
  const { maximeters } = props;

  const ICON_SIZE = 25;
  return (
    <Card
      title={[I18n.t('MaximetersWidget.title')]}
      body={[
        <div className={styles.root}>
          {maximeters.datos.map((maximeter, index) => (
            <div key={`${index}`} className={styles.maximeter_wrapper}>
              <div className={styles.icon_wrapper}>
                <MaximeterCurveIcon fill="#FF8282" height={ICON_SIZE} width={ICON_SIZE} />
              </div>
              <div className={styles.period_wrapper}>
                <span>{`P${maximeter.periodo}`}</span>
              </div>
              <div className={styles.maximeter_info}>
                <p className={styles.value}>{`${
                  wattsConverterService.convertWattsToKwattsWithUnits(maximeter.maximetro).num
                } ${wattsConverterService.convertWattsToKwattsWithUnits(maximeter.maximetro).unit}`}</p>
                <p className={styles.date}>{maximeter.fecha}</p>
              </div>
            </div>
          ))}
        </div>,
      ]}
    />
  );
};

export default MaximetersWidget;
