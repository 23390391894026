import Button from 'components/Button/Button';
import React, { useRef, useState } from 'react';
import cx from 'classnames';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/ArrowLeft_Thin.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight_Thin.svg';
import { Colors } from 'styles';
import { useOutsideClick } from 'hooks';
import { UtilsService } from 'services';
import styles from './YearPicker.module.scss';

interface MonthPickerPropsI {
  value: Date;
  onChange: CallableFunction;
  customInput?: React.ReactNode;
  anchorSelector: 'left' | 'right';
}

const YearPicker = (props: MonthPickerPropsI) => {
  const OFFSETMONTHS = 9;
  const { onChange, value, customInput, anchorSelector } = props;

  const dropdownRef = useRef(null);

  const [show, setShow] = useState<boolean>(false);
  const [selectedYear, setSelectedYear] = useState<number>(value.getFullYear());

  useOutsideClick(dropdownRef, () => {
    setShow(false);
  });

  const handleYearClicked = (year: number) => {
    const dateSelected = new Date(year, 0, 0);
    onChange(dateSelected);
    setShow(false);
  };

  const generateGrid = () => {
    const rows = [];
    for (let i = 0; i < 3; i += 1) {
      const row = [];
      for (let j = 0; j < 3; j += 1) {
        const offset = i * 3 + j;
        const yearNumber = selectedYear - offset;
        row.push(
          <button onClick={() => handleYearClicked(yearNumber + 1)} type="button" className={styles.month}>
            {yearNumber}
          </button>,
        );
      }
      rows.push(<div className={styles.monthRow}>{row}</div>);
    }
    return rows;
  };

  return (
    <div ref={dropdownRef} className={styles.root}>
      <div className={styles.header}>
        {customInput || (
          <Button
            className={styles.picker_button}
            type="button"
            variant="secondary"
            text={`${UtilsService.dateToLocaleString(value, { year: 'numeric' }, true)}`}
            onClick={(): void => {
              setShow(!show);
            }}
          />
        )}
      </div>
      <div
        style={{
          left: anchorSelector === 'left' ? 0 : undefined,
          right: anchorSelector === 'right' ? 0 : undefined,
        }}
        className={cx(styles.months_selector, { [styles.show]: show })}
      >
        <div className={styles.months_header}>
          <button
            type="button"
            className={styles.arrow_button}
            onClick={() => setSelectedYear(selectedYear - OFFSETMONTHS)}
          >
            <ArrowLeftIcon height={20} width={20} fill={Colors.COLOR_ELECSUMGREEN} />
          </button>
          <span className={styles.year}>{`${selectedYear - OFFSETMONTHS + 1} - ${selectedYear}`}</span>
          <button
            type="button"
            className={styles.arrow_button}
            onClick={() => setSelectedYear(selectedYear + OFFSETMONTHS)}
          >
            <ArrowRightIcon height={20} width={20} fill={Colors.COLOR_ELECSUMGREEN} />
          </button>
        </div>
        <div className={cx(styles.months_grid)}>{generateGrid()}</div>
      </div>
    </div>
  );
};

export default React.memo(YearPicker);
