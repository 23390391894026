import { Card } from 'components';
import React, { useState, useEffect } from 'react';
import { Colors } from 'styles';
import { I18n } from 'core';
import ComercialitzationSummary from 'types/models/widgetsData/comercializationSummaryObj';
import styles from './ComercializationSummaryWidget.module.scss';

interface ComercializationSummaryProps {
  data: ComercialitzationSummary;
}

const Concepto = ({ etiqueta, valor }: { etiqueta: string; valor: string }): JSX.Element => (
  <div className={styles.concepto}>
    <span className={styles.conceptoInfo}>{etiqueta}</span>
    <span className={styles.conceptoValor}>{valor}</span>
  </div>
);

type TerminosParsed = { periodo: number; terminoEnergia: number; terminoPotencia: number };

const ComercializationSummaryWidget = (props: ComercializationSummaryProps): JSX.Element => {
  const { data } = props;
  const [terminosParsed, setTerminosParsed] = useState<Array<TerminosParsed>>([]);

  useEffect(() => {
    const aux: Array<TerminosParsed> = [];
    data.terminosDePotencia.forEach((item) => {
      aux.push({ periodo: item.periodo, terminoEnergia: 0, terminoPotencia: item.termino });
    });

    data.terminosDeEnergia.forEach((item) => {
      const findIndex = aux.findIndex((x) => x.periodo === item.periodo);
      if (findIndex !== -1) {
        aux[findIndex].terminoEnergia = item.termino;
      } else {
        aux.push({ periodo: item.periodo, terminoEnergia: item.termino, terminoPotencia: 0 });
      }
    });

    setTerminosParsed(aux);
  }, [data]);

  const PowersArray = [
    Colors.COLOR_NOTIFICATIONS,
    Colors.COLOR_GRAY_500,
    Colors.COLOR_BLUE_100,
    Colors.COLOR_ERROR,
    Colors.COLOR_BROWN,
    Colors.COLOR_PURPLE,
  ];

  return (
    <Card
      title={I18n.t('ComercializationSummaryWidget.title')}
      body={[
        <div>
          {data.tarifaComercializadora || data.tarifa ? (
            <Concepto
              etiqueta={`${I18n.t('ComercializationSummaryWidget.tarifa')}`}
              valor={`${data.tarifaComercializadora} ${data.tarifaComercializadora && data.tarifa ? '/' : null} ${
                data.tarifa
              }`}
            />
          ) : null}
          {terminosParsed.length > 0 ? (
            <table className={styles.terminosTable}>
              <thead>
                <tr>
                  <th aria-label="period" />
                  <th>{I18n.t('ComercializationSummaryWidget.tPotencia')}</th>
                </tr>
              </thead>
              <tbody>
                {terminosParsed
                  .sort((a, b) => {
                    if (b.periodo < a.periodo) {
                      return 0.1;
                    }
                    return -1;
                  })
                  .map((item, index) => (
                    <tr>
                      <td className={styles.period}>
                        <div className={styles.period}>
                          <div />
                          {item.terminoPotencia > 0 ? `${I18n.t('Potencia')} ${index}` : null}
                        </div>
                      </td>
                      <td className={styles.term_col}>
                        {item.terminoPotencia > 0
                          ? `${item.terminoPotencia.toFixed(6)} €/kW/${I18n.t(
                              'ComercializationSummaryWidget.year',
                            ).toLowerCase()}`
                          : ''}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : null}
          <div className={styles.divider} />

          {terminosParsed.length > 0 ? (
            <table className={styles.terminosTable}>
              <thead>
                <tr>
                  <th aria-label="period" />
                  <th>{I18n.t('ComercializationSummaryWidget.tEnergia')}</th>
                </tr>
              </thead>
              <tbody>
                {terminosParsed
                  .sort((a, b) => {
                    if (b.periodo < a.periodo) {
                      return 0.1;
                    }
                    return -1;
                  })
                  .map((item, index) => (
                    <tr>
                      <td className={styles.period}>
                        <div className={styles.period}>
                          {item.terminoEnergia > 0 ? (
                            <>
                              <div className={styles.dot} style={{ backgroundColor: `${PowersArray[index]}` }} />
                              {`P${item.periodo}`}
                            </>
                          ) : null}
                        </div>
                      </td>
                      <td className={styles.term_col}>
                        {item.terminoEnergia > 0 ? `${item.terminoEnergia.toFixed(6)} €/kWh` : ''}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : null}
        </div>,
      ]}
    />
  );
};

export default ComercializationSummaryWidget;
