import { Spinner } from 'components';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { ElecsumGreen } from 'types/models';
import React, { useEffect, useState } from 'react';
import { elecsumGreenServiceInstance } from 'services';

import usersService from 'services/remote/UsersService';
import { useDispatch } from 'react-redux';
import { fetchUser, fetchUserSuccess } from 'redux/actions/UsersActions';
import ElecsumGreenData from './ElecsumGreen/ElecsumGreenData';
import ElecsumGreenImages from './ElecsumGreenImages/ElecsumGreenImages';
import EnvironmentalNews from './EnvironmentalNews/EnvironmentalNews';
import styles from './UsuarioEgreenPage.module.scss';

const UsuarioEgreenPage = (props: any): JSX.Element => {
  // PROPS
  const [greenStamp, setGreenStamp] = useState<ElecsumGreen>();
  const [loadingData, setLoadingData] = useState(false);
  const {
    match: {
      params: { userID },
    },
  } = props;

  // Hooks
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser());
    usersService.getUserById(userID).then((response) => {
      dispatch(fetchUserSuccess(response));
    });
  }, [userID]);

  const fetchStamp = async () => {
    setLoadingData(true);
    elecsumGreenServiceInstance.getElecsumGreen(userID).then((response) => {
      setGreenStamp(response);
      setLoadingData(false);
    });
  };

  useEffect(() => {
    fetchStamp();
  }, [userID]);

  return (
    <SectionWrapper customClassname={styles.root}>
      {loadingData ? (
        <div className={styles.loading_wrapper}>
          <Spinner icon />
        </div>
      ) : (
        <div>
          {greenStamp ? <ElecsumGreenData nif={userID} greenStamp={greenStamp} afterSubmitAction={fetchStamp} /> : null}
          {greenStamp ? (
            <ElecsumGreenImages nif={userID} images={greenStamp.images} afterSubmitAction={fetchStamp} />
          ) : null}
          {greenStamp ? <EnvironmentalNews nif={userID} news={greenStamp.news} afterSubmitAction={fetchStamp} /> : null}
        </div>
      )}
    </SectionWrapper>
  );
};
export default UsuarioEgreenPage;
