import React, { ChangeEvent } from 'react';

import cx from 'classnames';
import styles from './TextInput.module.scss';

export interface InputInterface {
  path: string;
  onChange?: CallableFunction;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  type: string;
  id: string;
  placeholder?: string;
  required?: boolean;
  name: string;
  readOnly?: boolean;
  min?: number;
  max?: number;
  maxLenght?: number;
  step?: number;
  tabindex?: number;
  error?: string;
}

const EunoiaInput = (props: InputInterface): JSX.Element => {
  const {
    value,
    type,
    id,
    required,
    placeholder,
    name,
    readOnly,
    className = '',
    min,
    max,
    maxLenght,
    step,
    tabindex,
    error,
  } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { path, onChange } = props;

    if (onChange) {
      onChange(path, e.target.value, id);
    }
  };

  const formatPlaceholder = (): string => {
    if (typeof placeholder === 'string') {
      return placeholder.charAt(0).toUpperCase() + placeholder.slice(1).toLowerCase();
    }
    return String(placeholder);
  };

  return (
    <>
      <input
        id={id}
        type={type}
        className={cx(styles.root, className)}
        value={value}
        onChange={handleChange}
        autoComplete="off"
        placeholder={formatPlaceholder()}
        required={required}
        name={name}
        readOnly={readOnly}
        min={min}
        max={max}
        maxLength={maxLenght}
        step={step}
        tabIndex={tabindex}
      />
      {error && <p style={{ color: 'red', padding: '10px' }}>{error}</p>}
    </>
  );
};

export default EunoiaInput;
