import React, { useState } from 'react';
import { I18n } from 'core';

import { toast } from 'react-toastify';

import { ReactComponent as LandscapeIcon } from 'assets/icons/Landscape.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg';
import { LoggingService, elecsumGreenServiceInstance } from 'services';

import { SectionSubTitle, LoadingButton, EunoiaImageInput, DataTable, TableHeading } from 'components';
import customToast from 'components/CustomToast/CustomToast';
import { Colors } from 'styles';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import styles from './ElecsumGreenImages.module.scss';

interface EGIPropsInterface {
  nif: string;
  images: Array<EGImages>;
  afterSubmitAction?: CallableFunction;
}
interface EGImages {
  id: string;
  tipo: string;
  url: string;
}

const ElecsumGreenImages = ({ nif, images, afterSubmitAction }: EGIPropsInterface): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState<string>('');
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState({});

  const validateForm = (): boolean => {
    if (!file) {
      setSubmitDisabled(true);
      setErrors({});
      return false;
    }
    setSubmitDisabled(false);
    setErrors({});
    return true;
  };

  const submitForm = async (type: string): Promise<boolean> => {
    setLoading(true);
    setSubmitDisabled(true);

    try {
      if (file) {
        const response = await elecsumGreenServiceInstance.addGaleryImage(nif, file, type);
        if (response === 201) {
          customToast.success(I18n.t('Image saved!'));

          if (afterSubmitAction) {
            afterSubmitAction();
          }

          setSubmitDisabled(false);
        }
      }
    } catch (error) {
      toast.error(I18n.t('Error Uploading gallery image.'));
    }

    setLoading(false);
    setFile(undefined);
    setFileName('');

    return true;
  };

  const settingFile = (file1: File): void => {
    if (file1) {
      setFile(file1);
      setFileName(file1.name);
      setIsButtonDisabled(false);
    }
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (validateForm()) {
      LoggingService.log('handleSubmit :: ok', { file });
      submitForm('galeria');
    } else {
      LoggingService.log('handleSubmit :: errors', { file, errors });
    }
  };

  // TODO: Define an class/interface for image
  const deleteImage = async (image: any): Promise<void> => {
    // eslint-disable-next-line no-alert
    const accept = window.confirm(I18n.t('Are you sure you want to delete the image?'));

    if (!accept) {
      return;
    }

    try {
      await elecsumGreenServiceInstance.deleteElecsumGreenImage(nif, image.id);

      customToast.success(`${I18n.t('Image deleted successfully')}!`);

      if (afterSubmitAction) {
        afterSubmitAction();
      }
      setSubmitDisabled(false);
    } catch (error) {
      toast.error(`${I18n.t('Error deleting image')}.`);
    }
  };

  const headings: Array<TableHeading> = [
    { label: I18n.t('Image'), type: 'image', key: 'url' },
    { label: I18n.t('Weight'), key: 'weight' },
    {
      label: I18n.t('Actions'),
      type: 'actions',
      key: '',
      actions: [
        {
          label: I18n.t('Delete'),
          icon: <TrashIcon height={25} width={25} fill={Colors.COLOR_ELECSUMGREEN} />,
          onActionClick: deleteImage,
        },
      ],
    },
  ];

  return (
    <SectionWrapper customClassname={styles.root}>
      <div className={styles.header}>
        <SectionSubTitle text={I18n.t('Images section about the company')} />
      </div>

      <form className={styles.form} onSubmit={onSubmit}>
        <div className={styles.fields}>
          <EunoiaImageInput
            id="img-galeria"
            label={I18n.t('add galery image')}
            note={I18n.t('(Must be 950x527px *)')}
            fileName={fileName}
            icon={<LandscapeIcon height={20} width={20} fill={Colors.COLOR_GRAY_400} />}
            type="galeria"
            action={settingFile}
          />
        </div>
        <div className={styles.actions}>
          <LoadingButton
            type="submit"
            text={I18n.t('add image')}
            disabled={isButtonDisabled || submitDisabled}
            loading={loading}
          />
        </div>
      </form>

      {/* // TODO: IMAGE TABLE */}
      <div className="col-12">
        <div className="table-news">
          {images && images.length > 0 ? <DataTable headings={headings} rows={images} loading={loading} /> : null}
        </div>
      </div>
    </SectionWrapper>
  );
};

export default ElecsumGreenImages;
