import React from 'react';
// import { csvService } from 'services';
// import Button from 'components/Button/Button';
// import styles from './informe.module.scss';

interface MiembroRowProps {
  miembro: any;
}

const MiembroRowExcluido = ({ miembro }: MiembroRowProps): JSX.Element => (
  <tr key={miembro.cups} style={{ textAlign: 'center' }}>
    <td>{miembro.nombre}</td>
    <td>{miembro.apellidos}</td>
    {/* <td>{miembro.autoconsumoEuro ? miembro?.autoconsumoEuro.toFixed(2) : 0} €</td>
    <td>{miembro.excedenteEuro ? miembro?.excedenteEuro.toFixed(2) : 0} €</td>
    <td>{miembro.ahorrosEuro ? miembro?.ahorrosEuro.toFixed(2) : 0} €</td> */}

    {/* <td onClick={() => csvService.exportCSV(miembro)} role="presentation">
        <Button className={styles.viewmore_button} variant="tertiary" type="button" text="Export" />
      </td> */}
  </tr>
);

export default MiembroRowExcluido;
