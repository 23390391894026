import { Card } from 'components';
import React from 'react';
import { I18n } from 'core';
import Button from 'components/Button/Button';
import configuration from 'config';
import styles from './ElecsumGreenWidget.module.scss';

interface ElecsumGreenWidgetPropsI {
  link: string;
  elecsumStampId: string;
}

const ElecsumGreenWidget = (props: ElecsumGreenWidgetPropsI): JSX.Element => {
  const { link, elecsumStampId } = props;

  const { apiUrl } = configuration;

  return (
    <>
      <Card
        fullHeight
        title="Elecsum Green"
        subtitle={I18n.t('checkAndShareElecsumGreen')}
        body={[
          <div key={1} className={styles.root}>
            <div className={styles.imageStamp}>
              <img
                id="imagenSelloVerde"
                src={`${apiUrl}/fv/elecsum-green/imagen-sello/m/${elecsumStampId}/`}
                alt="imagenSelloVerde"
                width={100}
                height={250}
              />
            </div>
            <div className={styles.button_wrapper}>
              <Button
                className={styles.button}
                newtab
                key={1}
                variant="primary"
                href={link}
                type="link"
                text={I18n.t('query')}
              />
            </div>
          </div>,
        ]}
      />
    </>
  );
};

export default ElecsumGreenWidget;
