const CLIENTINFO_MOCK = [
  {
    nombre: 'FV Residential Trade (Titular)',
    nif: 'B25123229',
    domicilio: 'Gran Via Carles III, Nº 98, 5t 2a, Barcelona, 08028',
    provincia: 'Barcelona',
    cp: '08028',
    contacto: 'Miquel Perez Desel',
    telefon: '931351212',
    // informes: [{
    //   nombre: 'Fotovoltaic FV',
    //   tipo: {
    //     mensual: false,
    //     anual: false,
    //   },
    // },
    // {
    //   nombre: 'Complet + desviació',
    //   tipo: {
    //     mensual: false,
    //     anual: true,
    //   },
    // },
    // {
    //   nombre: 'Garantía de producció',
    //   tipo: {
    //     mensual: false,
    //     anual: false,
    //   },
    // },
    // ],
    informeFotovoltaicoMensual: 'no',
    informeFotovoltaicoAnual: 'no',
    informeCompletoMasDesviacionMensual: 'si',
    informeCompletoMasDesviacionAnual: 'no',
    informeGarantiaDeProduccionMensual: 'no',
    informeGarantiaDeProduccionAnual: 'si',
    porcentajeTitularidadInstalacion: 50.25,
  },
];
export default CLIENTINFO_MOCK;
