import { GuardInterface } from 'types/interfaces';

import { authServiceInstance, AuthService } from 'services';
import WidgetPermission from 'types/enums/WidgetPermission';

class IsProdGuard implements GuardInterface {
  private authService: AuthService;

  private redirectUrl = '/';

  constructor() {
    this.authService = authServiceInstance;
  }

  canActivate(): boolean {
    const isUserLogged = this.authService.isLoggedIn();
    const { user, permisos } = this.authService;

    const hasPermissionTecnicos = permisos && permisos.includes(WidgetPermission.ACCESO_WEB_TECNICOS);
    if (!isUserLogged) {
      return false;
    }

    if (hasPermissionTecnicos) {
      this.redirectUrl = '/tecnic/dashboard'; // Establece el URL de redirección a la pantalla principal
      return false;
    }

    return user !== null && user.isPromotor();
  }

  getRedirectUrl(): string | null {
    return this.redirectUrl;
  }
}

const isProdGuard = new IsProdGuard();

export default isProdGuard;
