import { AxiosResponse } from 'axios';
import configuration from 'config';
import { AdminCupsListItem } from 'types/models';
import apiService from './ApiService';

interface FilteredCupsParamsI {
  autoconsumo?: boolean;
  industrial?: boolean;
  garantia?: boolean;
  garantiaAños?: number;
  contratoMantenimiento?: boolean;
  textoBusqueda?: string;
  page?: number;
  pageSize?: number;
}

export class CupsAdminService {
  readonly http = apiService;

  private apiUrl = configuration.apiUrl;

  async getFilteredCups(params: FilteredCupsParamsI): Promise<{
    data: Array<AdminCupsListItem>;
    paginacion: {
      page: number;
      pageSize: number;
      totalPages: number;
      elements: number;
    };
  }> {
    const url = `${this.apiUrl}/fv/cliente/lista-completa/`;
    const response: AxiosResponse = await this.http.get(url, {
      params: {
        autoconsumo: params.autoconsumo ? 'si' : undefined,
        industrial: params.industrial ? 'si' : undefined,
        garantia: params.garantia ? 'si' : undefined,
        garantiaAños: params.garantiaAños,
        contratoMantenimiento: params.contratoMantenimiento ? 'si' : undefined,
        textoBusqueda: params.textoBusqueda || undefined,
        page: params.page || undefined,
        pageSize: params.pageSize || undefined,
      },
    });
    const { page, pageSize, totalPages, elements } = response.data.paginacion;
    return {
      data: new AdminCupsListItem().deserializeArray(response.data.datos),
      paginacion: {
        elements,
        page,
        pageSize,
        totalPages,
      },
    };
  }

  public async getFilteredCupsCount(): Promise<number> {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/cliente/count/`);
    return response.data.datos.numeroClientes;
  }
}

const cupsAdminServiceInstance = new CupsAdminService();

export default cupsAdminServiceInstance;
