/* eslint-disable react/no-danger */
import React from 'react';

import { NewsObjectI } from 'types/models/elecsumGreenWeb';
import styles from './CompanyNew.module.scss';

interface CompanyNewI {
  key: string;
  companyNew: NewsObjectI;
}

const CompanyNew = (props: CompanyNewI): JSX.Element => {
  const { companyNew } = props;
  return (
    <div className={styles.CompanyNew}>
      <div className={styles.img_wrapper}>
        <img src={companyNew.image} alt="news-img" />
      </div>
      <div className={styles.info_container}>
        <p className={styles.date}>
          {new Date(companyNew.date).toLocaleString('default', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </p>
        <p className={styles.headline}>{companyNew.headline}</p>
        <p className={styles.description} dangerouslySetInnerHTML={{ __html: companyNew.description }} />
      </div>
    </div>
  );
};

export default CompanyNew;
