import { SearchInput } from 'components/search-input';
import React from 'react';
import I18n from 'i18n-js';
import styles from './communities-list-filters.module.scss';

interface CommunitiesListFiltersProps {
  search: (text: string) => void;
}

export const CommunitiesListFilters = ({ search }: CommunitiesListFiltersProps): JSX.Element => {
  const { t } = I18n;
  return (
    <div className={styles.container}>
      <div className={styles.left_wrapper}>
        <p className={styles.title}>{t(`ComunidadesListPage.title`)}</p>
        <div className={styles.search_wrapper}>
          <SearchInput
            placeholder={t(`ComunidadesListPage.searchPlaceholder`)}
            onChange={(e) => search(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};
