import React, { useEffect, useState } from 'react';
import { Colors } from 'styles';
import { ContractsData } from 'types/models';
import { I18n } from 'core';
import { ReactComponent as DownloadIcon } from 'assets/icons/Download.svg';
import { toast } from 'react-toastify';
import { clientServiceInstance } from 'services';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { SectionTitle } from 'components';
import { useSelector } from 'react-redux';
import { GlobalStoreState } from 'types/state';
import { navigate } from 'navigation';
import styles from './DocumentsPage.module.scss';

const DocumentsPage = (props: any): JSX.Element => {
  // Props
  const {
    match: {
      params: { cupsID },
    },
  } = props;
  const [contracts, setContracts] = useState<Array<ContractsData | null>>();
  const cupsSelected = useSelector((state: GlobalStoreState) => state.clientCUPS.selectedCups);

  // const dispatch = useDispatch();

  // Effects
  // useEffect(() => {
  //   if (!authServiceInstance.user?.nif) return;
  //   cupsServiceInstance.getCupsFromUserId(authServiceInstance.user?.nif).then((response) => {
  //     if (response.length > 0) {
  //       const [firstCups] = response;
  //       if (!cupsID) {
  //         navigate(`/client/${firstCups.cups}/documents`);
  //       }
  //       const cupsById = response.find((item) => item.cups === cupsID);
  //       dispatch(updateClientCUPS(cupsById || firstCups));
  //       dispatch(fetchClientCUPSListSuccess(response));
  //     }
  //   });
  // }, [cupsID]);

  // Detectar cambio de CUPS seleccionado para redirigir a URL correcta
  useEffect(() => {
    if (cupsSelected) {
      if (cupsSelected.cups !== cupsID) {
        navigate(`/client/${cupsSelected.cups}/documents`);
      }
    }
  }, [cupsSelected]);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const contractsData = await clientServiceInstance.getContractsData(cupsID);
        setContracts(contractsData);
      } catch (error) {
        toast.error(I18n.t('getContractsError'));
      }
    };
    fetchData();
  }, [cupsID]);

  return (
    <SectionWrapper customClassname={styles.contracts_container}>
      <SectionTitle text={I18n.t('documents')} />
      {contracts?.map((contract) => {
        if (!contract) {
          return null;
        }
        return (
          <div key={contract.name} className={styles.contract} onClick={(): Window | null => window.open(contract.url)}>
            <p className={styles.name}>{contract.name}</p>
            <div>
              <DownloadIcon height={25} width={25} fill={Colors.COLOR_ELECSUMGREEN} />
            </div>
          </div>
        );
      })}
      {contracts?.length === 0 && <p>{I18n.t('documents_no_documents')}</p>}
    </SectionWrapper>
  );
};

export default DocumentsPage;
