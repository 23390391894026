import React from 'react';
// import { VictoryStack, VictoryBar, VictoryLine } from 'victory';
import { I18n } from 'core';
import { ReactComponent as CO2Icon } from 'assets/icons/CO2.svg';
import { ReactComponent as TreeIcon } from 'assets/icons/Tree.svg';
import { navigate } from 'navigation';
import { Colors } from 'styles';
import { KilogramsConverterService, UtilsService } from 'services';
import classNames from 'classnames';
import Card from '../../Card/Card';
import styles from './SustainabilityWidget.module.scss';
import Button from '../../Button/Button';

interface SustainabilityDataPropsI {
  cups: string;
  title: string;
  subtitle?: string;
  image?: JSX.Element;
  noEmmitedData: number;
  noEmmitedUnit: string;
  treesData: number;
  viewMore?: boolean;
}

interface SectionInfoPropsI {
  icon: JSX.Element;
  data: number | string;
  unit: string;
  text: string;
}

const SectionInfo = (props: SectionInfoPropsI): JSX.Element => {
  const { icon, data, unit, text } = props;
  return (
    <div className={classNames(styles.sectionInfo)}>
      {icon}
      <div className={styles.value}>
        <p className={styles.data}>{data}</p>
        <p className={styles.text}>{unit}</p>
      </div>
      <p className={styles.bold}>{text}</p>
    </div>
  );
};

const SustainabilityWidget = (props: SustainabilityDataPropsI): JSX.Element => {
  const { title, subtitle, image, noEmmitedData, treesData, viewMore, cups } = props;

  return (
    <Card
      fullHeight
      title={I18n.t(title)}
      subtitle={subtitle?.toUpperCase()}
      image={image}
      body={[
        <div key={1} className={styles.container}>
          <div className={styles.sections_container}>
            <SectionInfo
              icon={<CO2Icon width={45} height={45} fill={Colors.COLOR_ELECSUMGREEN} />}
              data={KilogramsConverterService.convertKilos(noEmmitedData).num}
              unit={KilogramsConverterService.convertKilos(noEmmitedData).unit}
              text={I18n.t('noEmmited')}
            />
            <SectionInfo
              icon={<TreeIcon width={45} height={45} fill={Colors.COLOR_ELECSUMGREEN} />}
              data={UtilsService.formatTwoDecimal(treesData).slice(0, -3)}
              unit={I18n.t('trees')}
              text={I18n.t('planted')}
            />
          </div>
          {viewMore ? (
            <div className={styles.view_more_container}>
              <Button
                className={styles.viewmore_button}
                variant="secondary"
                type="button"
                onClick={(): Promise<void> => navigate(`/client/${cups}/sustainability`)}
                text={I18n.t('viewMore')}
              />
            </div>
          ) : null}
        </div>,
      ]}
    />
  );
};

export default React.memo(SustainabilityWidget);
