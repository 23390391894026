import { SectionTitle, Spinner } from 'components';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { I18n } from 'core';
import { Installation, ProdEnergyData, ProdGuaranteeEvolutionData } from 'types/models';
import React, { useEffect, useState } from 'react';
import { ClientInfo, clientServiceInstance, installationServiceInstance } from 'services';

import { useDispatch } from 'react-redux';
import { fetchInstallation, fetchInstallationSuccess } from 'redux/actions/InstallationsActions';
import EnergyCard from './EnergyCard';
import ClientDropdown from './ClientDropdown';

import styles from './InstallationProductionPage.module.scss';
import ProdGuaranteeEvolutionCard from './ProdGuaranteeEvolutionCard/ProdGuaranteeEvolutionCard';
import ProductionChartCard from './ProductionChartCard/ProductionChartCard';

const InstallationProductionPage = (props: any): JSX.Element => {
  // PROPS
  const {
    match: {
      params: { installationID },
    },
  } = props;

  // Hooks
  const dispatch = useDispatch();

  // STATE
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [installation, setInstallation] = useState<Installation>();
  const [installationClients, setIntallationClients] = useState<ClientInfo[] | null>(null);
  const [clientSelected, setClientSelected] = useState<ClientInfo | null>(null);

  const [energyData, setEnergyData] = useState<ProdEnergyData>(new ProdEnergyData());
  const [loadingEnergyData, setLoadingEnergyData] = useState<boolean>(false);

  const [prodGuaranteeEvolutionData, setProdGuaranteeEvolutionData] = useState<ProdGuaranteeEvolutionData>(
    new ProdGuaranteeEvolutionData(),
  );
  const [loadingProdGuaranteeEvolutionData, setLoadingProdGuaranteeEvolutionData] = useState<boolean>(false);

  // EFFECTS
  useEffect(() => {
    clientServiceInstance.getClientsFromInstallation(installationID).then((clients) => {
      setIntallationClients(clients);
    });
  }, [installationID]);

  useEffect(() => {
    dispatch(fetchInstallation());
    installationServiceInstance.getInstallation(installationID).then((inst) => {
      dispatch(fetchInstallationSuccess(inst));
      setInstallation(inst);
    });
  }, [installationID]);

  useEffect(() => {
    setLoadingEnergyData(true);
    installationServiceInstance.getProductionEnergyData(installationID, clientSelected?.cups).then((response) => {
      setEnergyData(response);
      setLoadingEnergyData(false);
    });
  }, [installationID, clientSelected]);

  useEffect(() => {
    setLoadingProdGuaranteeEvolutionData(true);
    installationServiceInstance.getProdGuaranteeEvolutionData(installationID, clientSelected?.cups).then((response) => {
      setProdGuaranteeEvolutionData(response);
      setLoadingProdGuaranteeEvolutionData(false);
    });
  }, []);

  // FUNCTIONS
  const handleDropdownChange = (value: ClientInfo | null) => {
    setClientSelected(value);
  };
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <ClientDropdown
          selected={clientSelected}
          items={installationClients || []}
          onOptionChange={handleDropdownChange}
        />
      </div>
      <div className={styles.content}>
        <div>
          <SectionWrapper>
            {loadingProdGuaranteeEvolutionData ? (
              <div className={styles.loading_wrapper}>
                <Spinner icon />
              </div>
            ) : (
              <ProductionChartCard cupsSelected={clientSelected?.cups || null} installationID={installationID} />
            )}
          </SectionWrapper>
        </div>
        <div>
          <SectionWrapper>
            <SectionTitle text={I18n.t('Production guarantee evolution')} />
            {loadingProdGuaranteeEvolutionData ? (
              <div className={styles.loading_wrapper}>
                <Spinner icon />
              </div>
            ) : (
              <ProdGuaranteeEvolutionCard years={prodGuaranteeEvolutionData.years || []} />
            )}
          </SectionWrapper>
          <SectionWrapper>
            <SectionTitle text={I18n.t('energy')} />
            {loadingEnergyData ? (
              <div className={styles.loading_wrapper}>
                <Spinner icon />
              </div>
            ) : (
              <EnergyCard
                date={energyData.date}
                generationData={energyData.generation}
                shrinkData={energyData.shrink}
                networkData={energyData.network}
                selfConsumptionData={energyData.selfconsumption}
                consumptionData={energyData.consumption}
              />
            )}
          </SectionWrapper>
        </div>
      </div>
    </div>
  );
};

export default React.memo(InstallationProductionPage);
