import { Serializable } from '../interfaces';

export interface ConfiguracionMiembroAPI {
  id: number;
  idConfiguracion: number;
  idInscrito: string;
  nombre: string;
  apellidos: string;
  nif: string;
  email: string;
  cups: string;
  direccionCompleta: string;
  tipoConsumo: string;
  telefono: string;
  precioCompensacion: number;
  terminoEnergiaP1: number;
  terminoEnergiaP2: number;
  terminoEnergiaP3: number;
  terminoEnergiaP4: number;
  terminoEnergiaP5: number;
  terminoEnergiaP6: number;
  infoCurvaDeConsumo: {
    año: string;
    consumo: number;
  };
  idPerfil: number;
  consumoAnual: number;
  miembroVip: string;
  miembroPreferente: string;
  seleccionado: string;
  aceptado: string;
  betaMax: number;
  betaMin: number;
  fuente: string;
  curvaConsumo: string;
  porcentajeDatosAproximados: number;
}

export default class ConfiguracionMiembro implements Serializable<ConfiguracionMiembro> {
  id: number;

  idConfiguracion: number;

  idInscrito: string;

  nombre: string;

  apellidos: string;

  nif: string;

  email: string;

  cups: string;

  direccionCompleta: string;

  tipoConsumo: string;

  telefono: string;

  precioCompensacion: number;

  terminoEnergiaP1: number;

  terminoEnergiaP2: number;

  terminoEnergiaP3: number;

  terminoEnergiaP4: number;

  terminoEnergiaP5: number;

  terminoEnergiaP6: number;

  betaMax: number;

  betaMin: number;

  infoCurvaDeConsumo: {
    año: string;
    consumo: number;
  };

  idPerfil: number;

  consumoAnual: number;

  miembroVip: string;

  miembroPreferente: string;

  seleccionado: string;

  aceptado: string;

  fuente: string;

  curvaConsumo: boolean;

  porcentajeDatosAproximados: string;

  deserialize(input: ConfiguracionMiembroAPI): ConfiguracionMiembro {
    if (!input) {
      return this;
    }

    this.id = input.id;
    this.idConfiguracion = input.idConfiguracion;
    this.idInscrito = input.idInscrito;
    this.nombre = input.nombre;
    this.apellidos = input.apellidos;
    this.nif = input.nif;
    this.email = input.email;
    this.cups = input.cups;
    this.direccionCompleta = input.direccionCompleta;
    this.telefono = input.telefono;
    this.precioCompensacion = input.precioCompensacion;
    this.terminoEnergiaP1 = input.terminoEnergiaP1;
    this.terminoEnergiaP2 = input.terminoEnergiaP2;
    this.terminoEnergiaP3 = input.terminoEnergiaP3;
    this.terminoEnergiaP4 = input.terminoEnergiaP4;
    this.terminoEnergiaP5 = input.terminoEnergiaP5;
    this.terminoEnergiaP6 = input.terminoEnergiaP6;
    this.infoCurvaDeConsumo = {
      año: input.infoCurvaDeConsumo.año || '',
      consumo: input.infoCurvaDeConsumo.consumo || 0,
    };
    this.idPerfil = input.idPerfil;
    this.consumoAnual = input.consumoAnual;
    this.miembroVip = input.miembroVip;
    this.miembroPreferente = input.miembroPreferente;
    this.seleccionado = input.seleccionado;
    this.aceptado = input.aceptado;
    this.tipoConsumo = input.tipoConsumo;
    this.betaMax = input.betaMax || 0;
    this.betaMin = input.betaMin || 0;
    this.fuente = input.fuente || 'No information';
    this.curvaConsumo = input.curvaConsumo === 'si';
    this.porcentajeDatosAproximados =
      input.porcentajeDatosAproximados !== undefined && input.porcentajeDatosAproximados !== null
        ? `${(input.porcentajeDatosAproximados * 100).toFixed(2)}%`
        : '-';
    return this;
  }

  deserializeArray(inputArray: Array<ConfiguracionMiembroAPI>): Array<ConfiguracionMiembro> {
    return inputArray.map((input) => new ConfiguracionMiembro().deserialize(input));
  }

  setAttr<T>(key: string, value: T): void {
    Object.assign(this, { [key]: value });
  }
}
