import React, { useEffect, useState } from 'react';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import { useParams } from 'react-router-dom';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import ConfiguracionMatcher from 'types/models/ConfiguracionMatcher';
import MatcherConfiguracionInstance from 'services/remote/MatcherConfiguracionService';
import styles from './MatcherListPage.module.scss';
import { MatcherListTable } from './matcher-list-table/matcher-list-table';

interface ParameterDetalles {
  solicitudID: string;
}

export const MatcherListPage = (): JSX.Element => {
  // const [AllSolicitudes, setAllSolicitudes] = useState<ActualizarTarifaAll[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const { solicitudID } = useParams<ParameterDetalles>();
  const [configuracionMatcher, setConfiguracionMatcher] = useState<Array<ConfiguracionMatcher>>([]);
  const [modifcado, setModificado] = useState('Default');

  const getConfiguracionMatcher = async (): Promise<void> => {
    setLoadingData(true);
    try {
      const apiData = await MatcherConfiguracionInstance.getAllConfiguracionesMatcher();
      setConfiguracionMatcher(apiData);
      setModificado('Default');
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        setConfiguracionMatcher([]);
      }
    }

    setLoadingData(false);
  };

  useEffect(() => {
    getConfiguracionMatcher();
  }, [modifcado]);

  return (
    <div className={solicitudID !== undefined ? styles.container : styles.container2}>
      <SectionWrapper>
        {loadingData ? (
          <FullPageSyncLoader icon />
        ) : (
          <MatcherListTable onChange={setModificado} data={configuracionMatcher} solicitudID={solicitudID} />
        )}
      </SectionWrapper>
    </div>
  );
};
