import React from 'react';

import { ReactComponent as TreeIcon } from 'assets/icons/Tree.svg';
import { ReactComponent as CO2Icon } from 'assets/icons/CO2.svg';
import kilogramsConverterService from 'services/local/kilogramsConverterService';
import { I18n } from 'core';
import { Colors } from 'styles';
import styles from './CO2andPlantedTrees.module.scss';

interface CO2andPlantedTreesI {
  co2: number;
  trees: number;
}

const CO2andPlantedTrees = (props: CO2andPlantedTreesI): JSX.Element => {
  const { co2, trees } = props;
  return (
    <div className={styles.root}>
      <div className={styles.value}>
        <div className={styles.title_image}>
          <CO2Icon width={50} height={50} fill={Colors.COLOR_ELECSUMGREEN} />
        </div>
        <div className={styles.data}>
          <div className={styles.top}>
            {kilogramsConverterService.convertKilos(co2).num}
            <span className={styles.units}>{`${kilogramsConverterService.convertKilos(co2).unit} CO2`}</span>
          </div>
          <span className={styles.units_down}> {I18n.t('noEmmited')}</span>
        </div>
      </div>
      <div className={styles.value}>
        <div>
          <div className={styles.title_image}>
            <TreeIcon width={50} height={50} fill={Colors.COLOR_ELECSUMGREEN} />
          </div>
          <div className={styles.data}>
            <div className={styles.top}>
              {trees
                .toFixed(0)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}
              <span className={styles.units}>{I18n.t('trees')}</span>
            </div>
            <span className={styles.units_down}>{I18n.t('planted')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CO2andPlantedTrees;
