/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import cx from 'classnames';
import moment from 'moment';
import { I18n } from 'core';
import { DateIntervalPicker } from 'components';
import { DateInterval } from 'components/DateIntervalPicker/DateIntervalPicker';
import { useDispatch, useSelector } from 'react-redux';
import { clientServiceInstance } from 'services';
import { fetchCUPS, fetchCUPSSuccess } from 'redux/actions/CupsActions';
import { Formik, Form, Field } from 'formik';
import ControlledInput from 'components/Input/NewInputs';
import CIMServiceInstance from 'services/remote/CIMService';
import InvoiceSimulation from 'types/models/invoiceSimulation';

import FullPageSyncLoader from 'components/FullPageSyncLoader';
import { useRole } from 'hooks';
import { Roles } from 'types/enums';
import { navigate } from 'navigation';
import { GlobalStoreState } from 'types/state';
import { PowersList } from 'types/models';
import styles from './CIMInvoiceSimulationPage.module.scss';

interface ContractDataLinePropsI {
  name: string;
  value: string;
}

const ContractDataLine = (props: ContractDataLinePropsI) => {
  const { name, value } = props;
  return (
    <div className={styles.ContractDataLine}>
      <span className={styles.name}>{name}</span>
      <span className={styles.value}>{value}</span>
    </div>
  );
};

const Title = (props: { text: string }) => {
  const { text } = props;
  return <p className={styles.title}>{text}</p>;
};
const Subtitle = (props: { text: string }) => {
  const { text } = props;
  return <p className={styles.subtitle}>{text}</p>;
};

const CIMInvoiceSimulationPage = (props: any): JSX.Element => {
  moment.locale(I18n.currentLocale().substring(0, 2));
  // Props
  const {
    match: {
      params: { cupsID },
    },
  } = props;

  // Hooks
  const dispatch = useDispatch();
  const role = useRole();
  const cupsSelected = useSelector((state: GlobalStoreState) => state.clientCUPS.selectedCups);

  // State
  const [simulationData, setSimulationData] = useState<InvoiceSimulation | null>(null);
  const [powersHired, setPowersHired] = useState<PowersList | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [texto, setTexto] = useState('');
  // Effects
  useEffect(() => {
    dispatch(fetchCUPS());
    clientServiceInstance.getClient(cupsID).then((response) => {
      response.setAttr('cups', cupsID);
      dispatch(fetchCUPSSuccess(response));
    });
  }, [cupsID]);

  // useEffect(() => {
  //   if (role !== Roles.CLIENT) return;
  //   if (!authServiceInstance.user?.nif) return;
  //   cupsServiceInstance.getCupsFromUserId(authServiceInstance.user?.nif).then((response) => {
  //     if (response.length > 0) {
  //       const [firstCups] = response;
  //       if (!cupsID) {
  //         navigate(`/client/${firstCups.cups}/invoice-simulation`);
  //       }
  //       const cupsById = response.find((item) => item.cups === cupsID);
  //       dispatch(updateClientCUPS(cupsById || firstCups));
  //       dispatch(fetchClientCUPSListSuccess(response));
  //     }
  //   });
  // }, [cupsID, role]);

  useEffect(() => {
    if (role !== Roles.CLIENT) return;
    if (cupsSelected) {
      if (cupsSelected.cups !== cupsID) {
        navigate(`/client/cim/${cupsSelected.cups}/invoice-simulation`);
      }
    }
  }, [cupsSelected, role]);

  useEffect(() => {
    clientServiceInstance.getPowersHired(cupsID).then((response) => {
      setPowersHired(response);
    });
  }, [cupsID]);

  const handleSubmit = (values: any) => {
    setLoading(true);
    CIMServiceInstance.getInvoiceSimulation({
      cups: cupsID,
      fechaInicio: values.dateInterval.start.format('YYYY/MM/DD'),
      fechaFin: values.dateInterval.end.format('YYYY/MM/DD'),
      potencia1: values.potencia1 * 1000,
      potencia2: values.potencia2 * 1000,
      potencia3: values.potencia3 * 1000,
      potencia4: values.potencia4 * 1000,
      potencia5: values.potencia5 * 1000,
      potencia6: values.potencia6 * 1000,
    })
      .then((response) => {
        setSimulationData(response);
        setLoading(false);
      })
      .catch(() => {
        setTexto(I18n.t(`CIM.InvoiceSimulationPage.Errores`));
        setLoading(false);
      });
  };

  const POWERS_ARRAY = ['p1', 'p2', 'p3', 'p4', 'p5', 'p6'];
  return (
    <div className={styles.root}>
      <Formik
        enableReinitialize
        initialValues={{
          dateInterval: {
            start: moment().startOf('month'),
            end: moment().endOf('month'),
          },
          potencia1: powersHired ? powersHired.datos.potencia1 / 1000 : 0,
          potencia2: powersHired ? powersHired.datos.potencia2 / 1000 : 0,
          potencia3: powersHired ? powersHired.datos.potencia3 / 1000 : 0,
          potencia4: powersHired ? powersHired.datos.potencia4 / 1000 : 0,
          potencia5: powersHired ? powersHired.datos.potencia5 / 1000 : 0,
          potencia6: powersHired ? powersHired.datos.potencia6 / 1000 : 0,
        }}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, resetForm }) => (
          <Form className={styles.menu}>
            <div className={styles.options}>
              <Field name="dateInterval">
                {({ field }: any) => (
                  <DateIntervalPicker
                    onChangeDates={(dates: DateInterval) => {
                      setFieldValue('dateInterval', dates);
                    }}
                    datesInterval={field.value}
                    label={I18n.t(`CIM.InvoiceSimulationPage.Commons.simulationInterval`)}
                  />
                )}
              </Field>
            </div>
            <div className={styles.powers_wrapper}>
              {POWERS_ARRAY.map((item, index) => (
                <Field name={`potencia${index + 1}`}>
                  {({ field }: any) => (
                    <ControlledInput
                      type="number"
                      value={field.value}
                      placeholder="0"
                      label={`${I18n.t(`CIM.InvoiceSimulationPage.Powers.${item}`)} (kW)`}
                      onChange={field.onChange}
                      id={`potencia${index + 1}`}
                      name={field.name}
                    />
                  )}
                </Field>
              ))}
            </div>
            <div className={styles.actions}>
              <Button
                className={styles.btn}
                type="submit"
                text={I18n.t(`CIM.InvoiceSimulationPage.Commons.simulate`)}
                variant="primary"
              />
              <Button
                className={styles.btn}
                type="button"
                text={I18n.t(`CIM.InvoiceSimulationPage.Commons.reset`)}
                variant="secondary"
                onClick={() => {
                  resetForm();
                  setSimulationData(null);
                }}
              />
            </div>
          </Form>
        )}
      </Formik>

      {(() => {
        if (loading) {
          return (
            <div className={styles.loading}>
              <FullPageSyncLoader icon />
            </div>
          );
        }
        if (simulationData !== null) {
          return (
            <div className={styles.simulation_wrapper}>
              <>
                <div className={styles.left}>
                  <div className={styles.resumen_simulacion}>
                    <p className={styles.heading}>{I18n.t(`CIM.InvoiceSimulationPage.ResumenSection.title`)}</p>
                    <div className={styles.data}>
                      <div className={styles.concept}>
                        <p className={styles.label}>{I18n.t(`CIM.InvoiceSimulationPage.ResumenSection.base`)}</p>
                        <p className={styles.value}>{`${simulationData.datos.totalFactura.base.toLocaleString(
                          'es-ES',
                        )} €`}</p>
                      </div>
                      <div className={styles.concept}>
                        <p className={styles.label}>{I18n.t(`CIM.InvoiceSimulationPage.ResumenSection.iva`)}</p>
                        <p className={styles.value}>{`${simulationData.datos.totalFactura.iva.toLocaleString(
                          'es-ES',
                        )} €`}</p>
                      </div>
                      <div className={cx(styles.concept, styles.total)}>
                        <p className={styles.stronglabel}>{I18n.t(`CIM.InvoiceSimulationPage.ResumenSection.total`)}</p>
                        <p
                          className={styles.strongValue}
                        >{`${simulationData.datos.totalFactura.importeTotal.toLocaleString()} €`}</p>
                      </div>
                    </div>
                  </div>
                  {/* DATOS DE CONTRATACION */}
                  <div className={cx(styles.section, styles.datosContratacion)}>
                    <Title text={I18n.t(`CIM.InvoiceSimulationPage.DatosContratacionSection.title`)} />
                    <div className={styles.concepts}>
                      <ContractDataLine
                        name={I18n.t(`CIM.InvoiceSimulationPage.DatosContratacionSection.tarifa`)}
                        value={simulationData.datos.datosContratacion.tarifaAcceso}
                      />
                      <ContractDataLine
                        name={I18n.t(`CIM.InvoiceSimulationPage.DatosContratacionSection.potencia`)}
                        value={simulationData.datos.datosContratacion.potenciasContratadas
                          .map((p) => `${p / 1000}kW`)
                          .join(' - ')}
                      />
                      <ContractDataLine
                        name={I18n.t(`CIM.InvoiceSimulationPage.DatosContratacionSection.titular`)}
                        value={simulationData.datos.datosContratacion.titular}
                      />
                      <ContractDataLine name="NIF" value={simulationData.datos.datosContratacion.nif} />
                      <ContractDataLine name="CUPS" value={simulationData.datos.datosContratacion.cups} />
                    </div>
                  </div>
                  {/* LECTURAS */}
                  <div className={cx(styles.section, styles.lecturas)}>
                    <Title text={I18n.t(`CIM.InvoiceSimulationPage.Lecturas.title`)} />
                    <div className={styles.lecturaWrapper}>
                      <Subtitle text={I18n.t(`CIM.InvoiceSimulationPage.Lecturas.active`)} />
                      <table>
                        <thead>
                          <tr>
                            <th>{I18n.t(`CIM.InvoiceSimulationPage.Commons.period`)}</th>
                            <th>{I18n.t(`CIM.InvoiceSimulationPage.Commons.dateInterval`)}</th>
                            <th className={styles.consume}>{I18n.t(`CIM.InvoiceSimulationPage.Lecturas.consume`)}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {simulationData.datos.lecturas.activa.map((lectura) => (
                            <>
                              <tr key={lectura.periodo}>
                                <td className={styles.power}>{`P${lectura.periodo}`}</td>
                                <td
                                  className={styles.period}
                                >{`${simulationData.datos.fechaInicio} - ${simulationData.datos.fechaFin}`}</td>
                                <td className={styles.consume}>{`${lectura.energia / 1000} kWh`}</td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                      <div className={styles.divider} />
                      <div className={styles.totalWrapper}>
                        <span className={styles.label}>{I18n.t(`CIM.InvoiceSimulationPage.Lecturas.activeTotal`)}</span>
                        <span className={styles.value}>
                          {`${simulationData.datos.lecturas.activa.reduce((a, b) => a + b.energia, 0) / 1000} kWh`}
                        </span>
                      </div>
                    </div>
                    <div className={styles.lecturaWrapper}>
                      <Subtitle text={I18n.t(`CIM.InvoiceSimulationPage.Lecturas.reactive`)} />
                      <table>
                        <thead>
                          <tr>
                            <th>{I18n.t(`CIM.InvoiceSimulationPage.Commons.period`)}</th>
                            <th>{I18n.t(`CIM.InvoiceSimulationPage.Commons.dateInterval`)}</th>
                            <th className={styles.maximeter}>
                              {I18n.t(`CIM.InvoiceSimulationPage.Lecturas.maximeter`)}
                            </th>
                            <th className={styles.consume}>{I18n.t(`CIM.InvoiceSimulationPage.Lecturas.consume`)}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {simulationData.datos.lecturas.reactiva.map((lectura) => (
                            <>
                              <tr key={lectura.periodo}>
                                <td className={styles.power}>{`P${lectura.periodo}`}</td>
                                <td
                                  className={styles.period}
                                >{`${simulationData.datos.fechaInicio} - ${simulationData.datos.fechaFin}`}</td>
                                <td className={styles.maximeter}>{`${lectura.maximetro / 1000} kW`}</td>
                                <td className={styles.consume}>{`${lectura.energia / 1000} kVArh`}</td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                      <div className={styles.divider} />
                      <div className={styles.totalWrapper}>
                        <span className={styles.label}>
                          {I18n.t(`CIM.InvoiceSimulationPage.Lecturas.reactiveTotal`)}
                        </span>
                        <span className={styles.value}>
                          {`${simulationData.datos.lecturas.reactiva.reduce((a, b) => a + b.energia, 0) / 1000} kVArh`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.right}>
                  {/* CONSUMOS */}
                  <div className={cx(styles.section, styles.consumos)}>
                    <Title text={I18n.t(`CIM.InvoiceSimulationPage.Consumos.title`)} />
                    {simulationData.datos.consumos.terminoDePotencia.length > 0 && (
                      <div className={styles.consumoWrapper}>
                        <Subtitle text={I18n.t(`CIM.InvoiceSimulationPage.Consumos.powerTerm`)} />
                        <table>
                          <thead>
                            <tr>
                              <th className={styles.power}>{I18n.t(`CIM.InvoiceSimulationPage.Commons.period`)}</th>
                              <th>{I18n.t(`CIM.InvoiceSimulationPage.Commons.description`)}</th>
                              <th className={styles.total}>{I18n.t(`CIM.InvoiceSimulationPage.Commons.total`)}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {simulationData.datos.consumos.terminoDePotencia.map((consumo) => (
                              <>
                                <tr key={consumo.periodo}>
                                  <td className={styles.power}>{`P${consumo.periodo}`}</td>
                                  <td>
                                    <strong>{`${consumo.potencia / 1000} kW`}</strong>
                                    {` x ${consumo.termino}€ x ${consumo.dias} ${
                                      consumo.dias > 1
                                        ? I18n.t(`CIM.InvoiceSimulationPage.Commons.days`)
                                        : I18n.t(`CIM.InvoiceSimulationPage.Commons.day`)
                                    }`}
                                  </td>
                                  <td className={styles.total}>{`${consumo.total} €`}</td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                        <div className={styles.divider} />
                        <div className={styles.totalWrapper}>
                          <span className={styles.label}>
                            {I18n.t(`CIM.InvoiceSimulationPage.Consumos.powerTotal`)}
                          </span>
                          <span className={styles.value}>
                            {`${simulationData.datos.consumos.terminoDePotencia
                              .reduce((a, b) => a + b.total, 0)
                              .toFixed(2)} €`}
                          </span>
                        </div>
                      </div>
                    )}
                    {simulationData.datos.consumos.terminoDeEnergia.length > 0 && (
                      <div className={styles.consumoWrapper}>
                        <Subtitle text={I18n.t(`CIM.InvoiceSimulationPage.Consumos.energyTerm`)} />
                        <table>
                          <thead>
                            <tr>
                              <th className={styles.power}>{I18n.t(`CIM.InvoiceSimulationPage.Commons.period`)}</th>
                              <th>{I18n.t(`CIM.InvoiceSimulationPage.Commons.description`)}</th>
                              <th className={styles.total}>{I18n.t(`CIM.InvoiceSimulationPage.Commons.total`)}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {simulationData.datos.consumos.terminoDeEnergia.map((consumo) => (
                              <>
                                <tr key={consumo.periodo}>
                                  <td className={styles.power}>{`P${consumo.periodo}`}</td>
                                  <td>
                                    <strong>{`${consumo.energia / 1000} kWh`}</strong> {` x ${consumo.termino}€`}
                                  </td>
                                  <td className={styles.total}>{`${consumo.total} €`}</td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                        <div className={styles.divider} />
                        <div className={styles.totalWrapper}>
                          <span className={styles.label}>
                            {I18n.t(`CIM.InvoiceSimulationPage.Consumos.energyTotal`)}
                          </span>
                          <span className={styles.value}>
                            {`${simulationData.datos.consumos.terminoDeEnergia
                              .reduce((a, b) => a + b.total, 0)
                              .toFixed(2)} €`}
                          </span>
                        </div>
                      </div>
                    )}
                    {simulationData.datos.consumos.terminoDeEnergiaReactiva.length > 0 && (
                      <div className={styles.consumoWrapper}>
                        <Subtitle text={I18n.t(`CIM.InvoiceSimulationPage.Consumos.reactiveTerm`)} />
                        <table>
                          <thead>
                            <tr>
                              <th className={styles.power}>{I18n.t(`CIM.InvoiceSimulationPage.Commons.period`)}</th>
                              <th>{I18n.t(`CIM.InvoiceSimulationPage.Commons.description`)}</th>
                              <th className={styles.total}>{I18n.t(`CIM.InvoiceSimulationPage.Commons.total`)}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {simulationData.datos.consumos.terminoDeEnergiaReactiva.map((consumo) => (
                              <>
                                <tr key={consumo.periodo}>
                                  <td className={styles.power}>{`P${consumo.periodo}`}</td>
                                  <td>{`${consumo.reactiva / 1000} kVArh x  ${consumo.termino}€`}</td>
                                  <td className={styles.total}>{`${consumo.total} €`}</td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                        <div className={styles.divider} />
                        <div className={styles.totalWrapper}>
                          <span className={styles.label}>
                            {I18n.t(`CIM.InvoiceSimulationPage.Consumos.reactiveTotal`)}
                          </span>
                          <span className={styles.value}>
                            {`${simulationData.datos.consumos.terminoDeEnergiaReactiva
                              .reduce((a, b) => a + b.total, 0)
                              .toFixed(2)} €`}
                          </span>
                        </div>
                      </div>
                    )}
                    {simulationData.datos.consumos.excesosDePotencia.length > 0 && (
                      <div className={styles.consumoWrapper}>
                        <Subtitle text={I18n.t(`CIM.InvoiceSimulationPage.Consumos.powerExcessTerm`)} />
                        <table>
                          <thead>
                            <tr>
                              <th className={styles.power}>{I18n.t(`CIM.InvoiceSimulationPage.Commons.period`)}</th>
                              <th>{I18n.t(`CIM.InvoiceSimulationPage.Commons.description`)}</th>
                              <th className={styles.total}>{I18n.t(`CIM.InvoiceSimulationPage.Commons.total`)}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {simulationData.datos.consumos.excesosDePotencia.map((consumo) => (
                              <>
                                <tr>
                                  <td className={styles.power}>{`P${consumo.periodo}`}</td>
                                  <td>
                                    <strong>{`${consumo.ae / 1000}`}</strong>
                                    {`  x ${consumo.termino} x ${consumo.constanteK}`}
                                  </td>
                                  <td className={styles.total}>{`${consumo.total} €`}</td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                        <div className={styles.divider} />
                        <div className={styles.totalWrapper}>
                          <span className={styles.label}>
                            {I18n.t(`CIM.InvoiceSimulationPage.Consumos.powerExcessTotal`)}
                          </span>
                          <span className={styles.value}>
                            {`${simulationData.datos.consumos.excesosDePotencia
                              .reduce((a, b) => a + b.total, 0)
                              .toFixed(2)} €`}
                          </span>
                        </div>
                      </div>
                    )}
                    {simulationData.datos.consumos.excedentes.length > 0 && (
                      <div className={styles.consumoWrapper}>
                        <Subtitle text={I18n.t(`CIM.InvoiceSimulationPage.Consumos.excess`)} />
                        <table>
                          <thead>
                            <tr>
                              <th className={styles.power}>{I18n.t(`CIM.InvoiceSimulationPage.Commons.period`)}</th>
                              <th>{I18n.t(`CIM.InvoiceSimulationPage.Commons.description`)}</th>
                              <th className={styles.total}>{I18n.t(`CIM.InvoiceSimulationPage.Commons.total`)}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {simulationData.datos.consumos.excedentes.map((consumo) => (
                              <>
                                <tr>
                                  <td className={styles.power}>{`P${consumo.periodo}`}</td>
                                  <td>
                                    <strong>{`${consumo.excedente / 1000} kWh`}</strong>
                                    {` x ${consumo.termino}€`}
                                  </td>
                                  <td className={styles.total}>{`${consumo.total} €`}</td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                        <div className={styles.divider} />
                        <div className={styles.totalWrapper}>
                          <span className={styles.label}>
                            {I18n.t(`CIM.InvoiceSimulationPage.Consumos.excessTotal`)}
                          </span>
                          <span className={styles.value}>
                            {`${simulationData.datos.consumos.excedentes
                              .reduce((a, b) => a + b.total, 0)
                              .toFixed(2)} €`}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* IMPUESTO ELECTRICIDAD */}
                  <div className={cx(styles.section)}>
                    <Title text={I18n.t(`CIM.InvoiceSimulationPage.Consumos.electricityTax`)} />
                    <table>
                      <thead>
                        <tr>
                          <th>{I18n.t(`CIM.InvoiceSimulationPage.Commons.description`)}</th>
                          <th className={styles.total}>{I18n.t(`CIM.InvoiceSimulationPage.Commons.total`)}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{`${simulationData.datos.impuestoSobreLaElectricidad.porcentajeImpuesto}% x ${simulationData.datos.impuestoSobreLaElectricidad.porcentajeExentoImpuesto}% x ${simulationData.datos.impuestoSobreLaElectricidad.baseImpuesto}€`}</td>
                          <td
                            className={styles.total}
                          >{`${simulationData.datos.impuestoSobreLaElectricidad.totalImpuesto}€`}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className={styles.divider} />
                    <div className={styles.totalWrapper}>
                      <span className={styles.label}>{I18n.t(`CIM.InvoiceSimulationPage.Commons.total`)}</span>
                      <span className={styles.value}>
                        {`${simulationData.datos.impuestoSobreLaElectricidad.totalImpuesto.toFixed(2)} €`}
                      </span>
                    </div>
                  </div>
                  {/* IMPUESTO EQUIPO */}
                  <div className={cx(styles.section)}>
                    <Title text={I18n.t(`CIM.InvoiceSimulationPage.Consumos.hardwareItems`)} />
                    <table>
                      <thead>
                        <tr>
                          <th>{I18n.t(`CIM.InvoiceSimulationPage.Commons.description`)}</th>
                          <th className={styles.total}>{I18n.t(`CIM.InvoiceSimulationPage.Commons.total`)}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{`${simulationData.datos.equiposDeMedida.diasAlquiler} ${
                            simulationData.datos.equiposDeMedida.diasAlquiler > 1 ? 'dias' : 'dia'
                          } x ${simulationData.datos.equiposDeMedida.terminoAlquiler}€`}</td>
                          <td className={styles.total}>{`${simulationData.datos.equiposDeMedida.totalAlquiler} €`}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className={styles.divider} />
                    <div className={styles.totalWrapper}>
                      <span className={styles.label}>{I18n.t(`CIM.InvoiceSimulationPage.Commons.total`)}</span>
                      <span className={styles.value}>
                        {`${simulationData.datos.equiposDeMedida.totalAlquiler.toFixed(2)} €`}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            </div>
          );
        }

        return texto === '' ? null : <div className={styles.margenes}>{texto}</div>;
      })()}
    </div>
  );
};

export default CIMInvoiceSimulationPage;
