import React from 'react';
import { navigate } from 'navigation';
import { Card, Spinner } from 'components';
import { I18n } from 'core';
import { ReactComponent as UserIcon } from 'assets/icons/User.svg';

import { Colors } from 'styles';
import styles from './RegisteredUsersWidget.module.scss';

interface RegisteredUsersWidgetPropsI {
  users: number;
}

const RegisteredUsersWidget = (props: RegisteredUsersWidgetPropsI): JSX.Element => {
  const { users } = props;

  const handleClickNavigate = () => {
    navigate('/tecnic/users');
  };

  return (
    <Card
      title={I18n.t('RegisteredUsersWidget.title')}
      body={[
        <div className={styles.root} key={1}>
          <UserIcon fill={Colors.COLOR_BLUE_200} height={50} width={50} />
          <span className={styles.value}>{users >= 0 ? users : <Spinner size={30} />}</span>
          <p className={styles.label}>{I18n.t('RegisteredUsersWidget.label')}</p>
          <button className={styles.viewmore_button} type="button" onClick={() => handleClickNavigate()}>
            {I18n.t('RegisteredUsersWidget.link')}
          </button>
        </div>,
      ]}
    />
  );
};

export default React.memo(RegisteredUsersWidget);
