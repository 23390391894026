import React, { useState, useEffect } from 'react';

import { I18n } from 'core';
import { SustainabilityWidget, TabsSwitch } from 'components';
import { toast } from 'react-toastify';
import { SustainabilityObject } from 'types/models';
import { authServiceInstance, clientServiceInstance, CupsInfoValues, elecsumGreenServiceInstance } from 'services';

// import { ReactComponent as ElecsumGreenIcon } from 'assets/icons/ElecsumGreen.svg';
import configuration from 'config';

import FullPageSyncLoader from 'components/FullPageSyncLoader';
import { useSelector } from 'react-redux';
import { GlobalStoreState } from 'types/state';
import { navigate } from 'navigation';
import styles from './SustainabilityPage.module.scss';
import ElecsumGreenWidget from './ElecsumGreenWidget';
import EmisionsWidget from './EmisionsWidget';
import EuropeWidget from './EuropeWidget';

const TABS_AVAILABLE = [
  { key: CupsInfoValues.MINE, label: I18n.t('La meva') },
  { key: CupsInfoValues.COMMUNITY, label: I18n.t('Comunitat') },
];
const SustainabilityPage = (props: any): JSX.Element => {
  // Props
  const {
    match: {
      params: { cupsID },
    },
  } = props;

  // const dispatch = useDispatch();

  const cupsSelected = useSelector((state: GlobalStoreState) => state.clientCUPS.selectedCups);

  const [sustainabilityData, setSustainabilityData] = useState<SustainabilityObject>();
  const [tabSelected, setTabSelected] = useState(CupsInfoValues.MINE);
  const [elecsumGreenLink, setElecsumGreenLink] = useState<string>('');
  const [elecsumGreenId, setElecsumGreenId] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const getQueryLink = async (): Promise<void> => {
    if (!authServiceInstance.user?.nif) {
      return;
    }
    try {
      const clientServiceInstanceRespone = await clientServiceInstance.getClient(cupsID);

      const getelecsumGreenId = await elecsumGreenServiceInstance.getElecsumGreenId(clientServiceInstanceRespone.nif);

      setElecsumGreenId(getelecsumGreenId);
      setElecsumGreenLink(configuration.elecsumGreenUrl + getelecsumGreenId);
    } catch (error) {
      toast.error(I18n.t('getElecsumGreenError'));
    }
  };

  // Effects
  // useEffect(() => {
  //   if (!authServiceInstance.user?.nif) return;
  //   cupsServiceInstance.getCupsFromUserId(authServiceInstance.user?.nif).then((response) => {
  //     if (response.length > 0) {
  //       const [firstCups] = response;
  //       if (!cupsID) {
  //         navigate(`/client/${firstCups.cups}/sustainability`);
  //       }
  //       const cupsById = response.find((item) => item.cups === cupsID);
  //       dispatch(updateClientCUPS(cupsById || firstCups));
  //       dispatch(fetchClientCUPSListSuccess(response));
  //     }
  //   });
  // }, [cupsID]);

  // Detectar cambio de CUPS seleccionado para redirigir a URL correcta
  useEffect(() => {
    if (cupsSelected) {
      if (cupsSelected.cups !== cupsID) {
        navigate(`/client/${cupsSelected.cups}/sustainability`);
      }
    }
  }, [cupsSelected]);

  useEffect(() => {
    if (!cupsID) return;
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      try {
        const data: SustainabilityObject = await clientServiceInstance.getSustainabilityData(cupsID);
        await getQueryLink();
        setSustainabilityData(data);
        setLoading(false);
      } catch (error) {
        toast.error(I18n.t);
      }
    };
    fetchData();
  }, [cupsID]);

  const handleTabClick = (option: any) => {
    setTabSelected(option.key);
  };

  if (!sustainabilityData) {
    return <FullPageSyncLoader />;
  }

  if (loading) {
    return <FullPageSyncLoader />;
  }

  return (
    <div className={styles.root}>
      {cupsSelected?.esComunidad === 'si' && (
        <TabsSwitch options={TABS_AVAILABLE} selected={tabSelected} onTabClick={handleTabClick} />
      )}
      <div className={styles.content}>
        {/*
          Componente que utiliza elecsumContributions
        <div className={styles.contribution_widget}>
          <SustainabilityWidget
            cups={cupsID}
            title="contribution"
            subtitle={`${sustainabilityData.elecsumContributions.instalationsNumber} ${I18n.t('instalations')}`}
            image={<ElecsumGreenIcon width={100} height={50} />}
            noEmmitedData={sustainabilityData.elecsumContributions.co2}
            noEmmitedUnit="kg CO2"
            treesData={sustainabilityData.elecsumContributions.trees}
          />
        </div> */}
        <div className={styles.sustainability_widget}>
          {cupsSelected?.esComunidad === 'si' && tabSelected === CupsInfoValues.COMMUNITY && (
            <SustainabilityWidget
              cups={cupsID}
              title="sustainability"
              noEmmitedData={sustainabilityData.comunity.co2}
              noEmmitedUnit="kg CO2"
              treesData={sustainabilityData.comunity.trees}
            />
          )}
          {tabSelected === CupsInfoValues.MINE && (
            <SustainabilityWidget
              cups={cupsID}
              title="sustainability"
              noEmmitedData={sustainabilityData.individual.co2}
              noEmmitedUnit="kg CO2"
              treesData={sustainabilityData.individual.trees}
            />
          )}
        </div>

        {elecsumGreenLink !== '' && (
          <div className={styles.egreen_widget}>
            <ElecsumGreenWidget elecsumStampId={elecsumGreenId} link={elecsumGreenLink} />
          </div>
        )}

        <div className={styles.emisions_widget}>
          <EmisionsWidget sustainabilityData={sustainabilityData} />
        </div>

        <div className={styles.eu_widget}>
          <EuropeWidget sustainabilityData={sustainabilityData} />
        </div>
      </div>
    </div>
  );
};

export default SustainabilityPage;
