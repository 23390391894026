export interface AhorroPorMesData {
  fecha: string;
  ahorro: number;
  importeSinElecsum: number;
  excedente: number;
  autoconsumida: number;
  importeElecsum: number;
  ahorroAcumulado: number;
}

interface SavingDataAPII {
  ahorrosPorMes: AhorroPorMesData[];
  ahorroAcumuladoInicio: string;
  ahorroAcumuladoFin: string;
}

export default class SavingData {
  ahorrosPorMes: AhorroPorMesData[];

  ahorroAcumuladoInicio: string;

  ahorroAcumuladoFin: string;

  deserialize(input: SavingDataAPII): SavingData {
    if (!input) {
      return this;
    }

    this.ahorrosPorMes = input.ahorrosPorMes;
    this.ahorroAcumuladoInicio = input.ahorroAcumuladoInicio;
    this.ahorroAcumuladoFin = input.ahorroAcumuladoFin;

    return this;
  }
}
