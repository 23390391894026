import I18n from 'i18n-js';
import React from 'react';
import { CommunityModel } from 'types/models/community';
import { CommunityListRow } from '../community-list-row/community-list-row';
import styles from './communities-list-table.module.scss';

interface CommunitiesListTableProps {
  data: CommunityModel[];
}

export const CommunitiesListTable = (props: CommunitiesListTableProps): JSX.Element => {
  const { data } = props;
  const { t } = I18n;
  return (
    <table className={styles.table_wrapper}>
      <thead>
        <tr>
          <th>{t(`ComunidadesListPage.headers.name`)}</th>
          <th>{t(`ComunidadesListPage.headers.location`)}</th>
          <th>{t(`ComunidadesListPage.headers.inscription`)}</th>
          <th>{t(`ComunidadesListPage.headers.status`)}</th>

          <th aria-label="Detail" />
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <CommunityListRow key={row.id} row={row} />
        ))}
      </tbody>
    </table>
  );
};
