import { Serializable } from 'types/interfaces';

export interface AdminCupsListItemDTO {
  cups: string;
  cau: string;
  nif: string;
  nombre: string;
  apellidos: string;
  domicilio: string;
  poblacion: string;
  provincia: string;
  codigoPostal: string;
  telefono: string;
  instalacion: {
    id: number;
    garantia: string;
    garantiaAños: number;
    contratoMantenimiento: string;
  };
}

export default class AdminCupsListItem implements Serializable<AdminCupsListItem> {
  cups: string;

  cau: string;

  nif: string;

  nombre: string;

  apellidos: string;

  domicilio: string;

  poblacion: string;

  provincia: string;

  codigoPostal: string;

  telefono: string;

  instalacion: {
    id: number;
    garantia: boolean;
    garantiaAños: number;
    contratoMantenimiento: boolean;
  } | null;

  deserialize(input: AdminCupsListItemDTO): AdminCupsListItem {
    if (!input) {
      return this;
    }

    this.cups = input.cups || '';
    this.cau = input.cau || '';
    this.nif = input.nif || '';
    this.nombre = input.nombre || '';
    this.apellidos = input.apellidos || '';
    this.domicilio = input.domicilio || '';
    this.poblacion = input.poblacion || '';
    this.provincia = input.provincia || '';
    this.codigoPostal = input.codigoPostal || '';
    this.telefono = input.telefono || '';

    this.instalacion =
      Object.keys(input.instalacion).length > 0
        ? {
            id: input.instalacion.id,
            contratoMantenimiento: input.instalacion.contratoMantenimiento === 'si',
            garantia: input.instalacion.garantia === 'si',
            garantiaAños: input.instalacion.garantiaAños || 0,
          }
        : null;

    return this;
  }

  deserializeArray(inputArray: Array<AdminCupsListItemDTO>): Array<AdminCupsListItem> {
    const result: Array<AdminCupsListItem> = inputArray.map((input) => new AdminCupsListItem().deserialize(input));
    return result;
  }
}
