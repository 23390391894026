import AmortizationData from './amortizationData';

interface AmortizationDataPropertiesI {
  importeAmortizado: string;
  importeTotal: number;
  porcentajeAmortizado: number;
}

interface AmortizationDataAPII {
  datos: {
    duracion: number;
    individual: AmortizationDataPropertiesI;
    comunidad: AmortizationDataPropertiesI;
  };
  habilitado: 'si' | 'no';
}

export default class AmortizationObject {
  duration: number;

  individual: AmortizationData;

  comunity: AmortizationData;

  showWidget: boolean;

  deserialize(input: AmortizationDataAPII): AmortizationObject {
    if (!input) {
      this.showWidget = false;
      return this;
    }

    this.duration = input.datos.duracion;
    this.individual = new AmortizationData().deserialize(input.datos.individual);
    this.comunity = new AmortizationData().deserialize(input.datos.comunidad);
    this.showWidget = input.habilitado === 'si';

    return this;
  }
}
