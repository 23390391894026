import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { authServiceInstance, cupsServiceInstance } from 'services';
import { toast } from 'react-toastify';
import { navigate } from 'navigation';
import { LoadingButton } from 'components';
import { I18n } from 'core';
import { CupsInfo } from 'types/models';
import * as Yup from 'yup';
import logo from 'assets/images/logo.png';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import ControlledInput from 'components/Input/NewInputs';
import Button from 'components/Button/Button';
import WidgetPermission from 'types/enums/WidgetPermission';
import usersService from 'services/remote/UsersService';
import styles from './LoginPage.module.scss';

const LoginPage = (): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const ValidationLoginSchema = Yup.object({
    id: Yup.string().required(I18n.t('LoginPage.errors.idRequired')),
    password: Yup.string().required(I18n.t('LoginPage.errors.passwordRequired')),
  });

  const handleNewSubmit = async (values: any) => {
    setLoading(true);
    try {
      const { user, permisos } = await authServiceInstance.logIn(values.id, values.password);

      const hasPermission = permisos && permisos.includes(WidgetPermission.ACCESO_WEB_TECNICOS);
      if ((user && user.isAdmin()) || hasPermission) {
        return await navigate('/tecnic/dashboard');
      }
      if (user && user.isPromotor()) {
        if (user.nif) {
          return await navigate(`/promotor/${user.nif}/home`);
        }
      }
      if (user && !user.isAdmin()) {
        const clientCups: CupsInfo[] = await cupsServiceInstance.getCupsFromUserId(user.nif);
        if (clientCups && clientCups.length) {
          cupsServiceInstance.cupsList = clientCups;
          const [firstCups] = clientCups;
          cupsServiceInstance.cups = firstCups;
        }
        const hasPermissionCupsAutorizados = permisos && permisos.includes(WidgetPermission.ACCESO_CUPS_AUTORIZADOS);
        if (hasPermissionCupsAutorizados) {
          // Realiza la segunda solicitud de CUPS autorizados
          const secondResponse = await usersService.getCupsAutorizados(user.nif);
          const megeArray = [...clientCups, ...secondResponse];

          cupsServiceInstance.cupsList = megeArray;

          // eslint-disable-next-line prefer-destructuring
          cupsServiceInstance.cups = megeArray[0];
        }

        return await navigate(`/client/${cupsServiceInstance.cups?.cups}/home`);
      }
    } catch (err) {
      if (err.response) {
        const { status } = err.response;
        if (status === 400) {
          toast.error(I18n.t('LoginPage.errors.badCredentials'));
        }
        setLoading(false);
      }
    }

    setLoading(false);
    return navigate('/');
  };

  return (
    <div className={styles.root}>
      <div className={styles.formcontainer}>
        <img src={logo} className={styles.logo} alt="" />
        <Formik
          validationSchema={ValidationLoginSchema}
          initialValues={{ id: '', password: '' }}
          onSubmit={handleNewSubmit}
        >
          {({ isValid, dirty }) => (
            <Form>
              <div className={styles.field}>
                <Field name="id">
                  {({ field }: any) => (
                    <ControlledInput
                      type="text"
                      value={field.value}
                      placeholder={I18n.t('LoginPage.form.idPlaceholder')}
                      label={I18n.t('LoginPage.form.idLabel')}
                      onChange={field.onChange}
                      id="id"
                      name={field.name}
                    />
                  )}
                </Field>
                <ErrorMessage component="div" className={styles.error} name="id" />
              </div>
              <div className={styles.field}>
                <Field name="password">
                  {({ field }: any) => (
                    <ControlledInput
                      type="password"
                      value={field.value}
                      placeholder={I18n.t('LoginPage.form.passwordPlaceholder')}
                      label={I18n.t('LoginPage.form.passwordLabel')}
                      onChange={field.onChange}
                      id="password"
                      name={field.name}
                    />
                  )}
                </Field>
                <ErrorMessage component="div" className={styles.error} name="password" />
                <Link className={styles.forgot} to="/auth/forgot-password">
                  {I18n.t('LoginPage.form.forgotPassword')}
                </Link>
              </div>
              <div className={styles.submit_button}>
                <LoadingButton
                  type="submit"
                  text={I18n.t('LoginPage.form.login')}
                  disabled={!(isValid && dirty)}
                  loading={loading}
                  className={styles.btn}
                />
              </div>
            </Form>
          )}
        </Formik>
        <div className={styles.divider}>
          <hr />
          <span>{I18n.t('LoginPage.form.notRegistered')}</span>
          <hr />
        </div>
        <div className={styles.registerButton}>
          <Button
            variant="secondary"
            className={styles.btn}
            type="button"
            onClick={() => navigate('/auth/signup')}
            text={I18n.t('LoginPage.form.register')}
            disabled={false}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
