import { Card, Spinner } from 'components';
import React from 'react';
import { I18n } from 'core';
import { t } from 'i18n-js';
import { ReactComponent as InvoicesIcon } from 'assets/icons/Invoices.svg';

import { Colors } from 'styles';
import { useHistory } from 'react-router-dom';
import { FormatNumberService } from 'services';
import styles from './tarifas-dashboard-widget.module.scss';

interface TarifasDashboardWidgetProps {
  solicitudes: number;
}

export const TarifasDashboardWidget = (props: TarifasDashboardWidgetProps): JSX.Element => {
  const { solicitudes } = props;
  const i18nPrefix = `DashboardPage.TarifasDashboardWidget`;
  const history = useHistory();

  return (
    <Card
      title={I18n.t(`${i18nPrefix}.title`)}
      body={[
        <div className={styles.root}>
          <div className={styles.section}>
            <InvoicesIcon stroke={Colors.COLOR_ELECSUMGREEN} height={50} width={50} />
            <span className={styles.value}>
              {solicitudes >= 0 ? FormatNumberService.formatInteger(`${solicitudes}`) : <Spinner size={30} />}
            </span>{' '}
            <p className={styles.label}>{t(`${i18nPrefix}.pending`)}</p>
            <button
              className={styles.viewmore_button}
              type="button"
              onClick={() => {
                history.push('/tecnic/tarifas');
              }}
            >
              {I18n.t(`${i18nPrefix}.viewmore`)}
            </button>
          </div>
        </div>,
      ]}
    />
  );
};
