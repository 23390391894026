import React from 'react';
import { I18n } from 'core';

import styles from './InstallationListFilters.module.scss';
import SearchInstallationInput from '../SearchInstallationInput';

interface InstallationListFiltersPropsI {
  search: (text: string) => void;
}

const InstallationListFilters = (props: InstallationListFiltersPropsI): JSX.Element => {
  const { search } = props;
  return (
    <div className={styles.container}>
      <div className={styles.left_wrapper}>
        <p className={styles.title}>{I18n.t('InstallationListPage.title')}</p>
        <div className={styles.search_wrapper}>
          <SearchInstallationInput
            placeholder={`${I18n.t('InstallationListPage.filters.searchPlaceholder')}...`}
            onChange={(event) => {
              search(event.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default InstallationListFilters;
