import { Serializable } from 'types/interfaces';

interface LineaAPII {
  numeroDeLinea: number;
  importe: number;
  concepto: {
    ca_ES: string;
    es_ES: string;
  };
}

interface InvoiceDataAPII {
  contrato: string;
  fecha: string;
  estado: 'pendiente' | 'pagada' | 'impagada';
  finPeriodoFacturado: string;
  inicioPeriodoFacturado: string;
  importeTotal: number;
  numero: number;
  url: string;
  lineas: Array<LineaAPII>;
}

export interface LineI {
  lineNumber: number;
  cost: number;
  concept: {
    ca_ES: string;
    es_ES: string;
  };
}

export default class InvoiceData implements Serializable<InvoiceData> {
  contract: string;

  date: string;

  periodInvoicedStart: string;

  periodInvoicedEnd: string;

  totalCost: number;

  invoiceNumber: number;

  estado: 'pendiente' | 'pagada' | 'impagada';

  url: string;

  lines: Array<LineI>;

  deserialize(input: InvoiceDataAPII): InvoiceData {
    if (!input) {
      return this;
    }

    this.contract = input.contrato;
    this.date = input.fecha;
    this.estado = input.estado;
    this.periodInvoicedStart = input.inicioPeriodoFacturado;
    this.periodInvoicedEnd = input.finPeriodoFacturado;
    this.totalCost = input.importeTotal;
    this.invoiceNumber = input.numero;
    this.url = input.url;
    this.lines = input.lineas.map(
      (line: LineaAPII): LineI => ({
        lineNumber: line.numeroDeLinea,
        cost: line.importe,
        concept: line.concepto,
      }),
    );

    return this;
  }
}
