// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { NavItemI, I18n } from 'core';

import { useParams } from 'react-router-dom';

const createNavigationLinks = (): Array<NavItemI> => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { installationID } = useParams<{ installationID: string }>();
  const links: Array<NavItemI> = [
    {
      name: I18n.t('Sidenav.Tecnic.Installation_Clients'),
      url: `/tecnic/installation/${installationID}/cups`,
    },
    {
      name: I18n.t('Sidenav.Tecnic.Installation_Installation'),
      url: `/tecnic/installation/${installationID}/installation`,
    },
    {
      name: I18n.t('Sidenav.Tecnic.Installation_Study'),
      url: `/tecnic/installation/${installationID}/study`,
    },
    {
      name: I18n.t('Sidenav.Tecnic.Installation_Production'),
      url: `/tecnic/installation/${installationID}/production`,
    },
  ];
  return links;
};

export default createNavigationLinks;
