import React from 'react';

import { Header } from 'components';
import { RoutesInterface } from 'types/interfaces';
import styles from './NoSidenavNoGradient.module.scss';

const NoSidenavNoGradientLayout = (props: RoutesInterface): JSX.Element | null => {
  const { component: Component } = props;

  return (
    <>
      <Header />

      <main role="main" className={styles.root}>
        {Component ? <Component {...props} /> : null}
      </main>
    </>
  );
};
export default NoSidenavNoGradientLayout;
