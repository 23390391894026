/* eslint-disable no-return-assign */
import React, { useState } from 'react';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { I18n } from 'core';
import SectionTitle from 'components/SectionTitle/SectionTitle';
// import { useToggle } from 'hooks/useToogle';
// import { RoundedButton } from 'components/NewInputs';
// import Button from 'components/Button/Button';
// import ModalMatcher from 'components/ModalMatcher/ModalMatcher';
import { toast } from 'react-toastify';
// import rolesPermisosService from 'services/remote/RolesPermisosService';
import RolInfoPermisos from 'types/models/rolinfopermiso';
import roleService from 'services/remote/RolesService';
import { LoadingButton } from 'components';
import { RolInfo } from 'types/models';
import Button from 'components/Button/Button';
import { navigate } from 'navigation';
import styles from '../RolesUserPageDetalle.module.scss';
// import stylesDropdown from './roles-dropdown.module.scss';

interface RolesPermisoDataSectionProps {
  rolData: RolInfoPermisos[]; // Define the rolData prop
  submitDisabled: boolean; // Define submitDisabled prop
  fetchData: Function;
  data: RolInfo;
  toggleSwitch: Function;
}

function RolesPermisoDataSection({ data, rolData, toggleSwitch }: RolesPermisoDataSectionProps) {
  const [loading, setLoading] = useState(false);

  const [selectedRoles, setSelectedRoles] = useState<any[]>(data.permisos);
  const handleInputChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedRoles((prevSelectedRoles) => [...prevSelectedRoles, rolData.find((role) => role.id === id)]);
    } else {
      setSelectedRoles((prevSelectedRoles) => prevSelectedRoles.filter((role) => role.id !== id));
    }
  };

  const UpdatePermisos = async (id: number, arrayPermisos: any) => {
    setLoading(true);

    try {
      await roleService.updateRolPermisos(id.toString(), arrayPermisos);
      toast.success(I18n.t('updateSuccess'));
    } catch (error) {
      toast.error(I18n.t('DashboardPage.comunity_fetching_error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <SectionWrapper customClassname={styles.table_wrapper}>
      <SectionTitle text={I18n.t('RolPermisionDetailPage.RolData.titlePermisos')} />
      <div>
        <table>
          <thead>
            <tr>
              <th>{I18n.t('RolPermisionDetailPage.RolData.labels.name')}</th>
              <th>{I18n.t('RolPermisionDetailPage.RolData.labels.selected')}</th>
              <th aria-label="Detail" />
            </tr>
          </thead>
          <tbody>
            {rolData && rolData.length > 0 ? (
              rolData.map((disp: RolInfoPermisos) => (
                <tr key={disp.id}>
                  <td>{disp.nombre}</td>

                  <td className={styles.checkboxTable}>
                    <input
                      id="seleccionado"
                      type="checkbox"
                      name="seleccionado"
                      checked={selectedRoles.map((role) => role.id).includes(disp.id)}
                      onChange={(e) => handleInputChangeCheckbox(e, disp.id)}
                    />
                  </td>
                  <td />
                </tr>
              ))
            ) : (
              <tr className={styles.Sinboder}>
                <td className={styles.nodata}>{I18n.t('UsuarioDetailPage.DevicesData.noData')}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className={styles.actions}>
        <Button
          type="button"
          onClick={(): Promise<void> => navigate('/tecnic/roles')}
          variant="secondary"
          text={I18n.t('Cancel')}
        />
        <LoadingButton
          type="submit"
          text={I18n.t('Save')}
          onClick={() => {
            UpdatePermisos(data.id, selectedRoles);
          }}
          loading={loading}
        />
      </div>

      <Button
        className={styles.viewmore_button}
        variant="reddelete"
        type="button"
        onClick={() => {
          toggleSwitch();
        }}
        text="eliminar rol"
      />
    </SectionWrapper>
  );
}

export default RolesPermisoDataSection;
