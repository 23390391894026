import { ChartDataI } from '../index';

export interface ProdEnergyProductionDataDailyAPII {
  generacion: number;
  excedente: number;
  autoconsumo: number;
  red: number;
  diferenciaGeneracion: number;
  horas: Array<ProdEnergyProductionDataHourlyAPII>;
}

export interface ProdEnergyProductionDataHourlyAPII {
  hora: number;
  a: number;
  e: number;
  g: number;
  r: number;
}

export interface ProdEnergyProductionDataHourly {
  hour: number;
  selfConsumption: number;
  surplus: number;
  generation: number;
  network: number;
}

export default class ProdEnergyProductionDataDaily {
  generation: number;

  surplus: number;

  selfConsumption: number;

  network: number;

  differenceGeneration: number;

  hours: Array<ProdEnergyProductionDataHourly>;

  generationData: Array<ChartDataI>;

  surplusData: Array<ChartDataI>;

  selfConsumptionData: Array<ChartDataI>;

  networkData: Array<ChartDataI>;

  totals: {
    generation: number;
    surplus: number;
    selfConsumption: number;
    network: number;
  };

  deserialize(input: ProdEnergyProductionDataDailyAPII): ProdEnergyProductionDataDaily {
    if (!input) {
      return this;
    }

    this.generation = input.generacion;
    this.surplus = input.excedente;
    this.selfConsumption = input.autoconsumo;
    this.network = input.red;
    this.differenceGeneration = input.diferenciaGeneracion;
    this.hours = input.horas.map(
      (data: ProdEnergyProductionDataHourlyAPII): ProdEnergyProductionDataHourly => ({
        hour: data.hora,
        selfConsumption: data.a,
        surplus: data.e,
        generation: data.g,
        network: data.r,
      }),
    );
    this.generationData = input.horas.map(
      (hour): ChartDataI => ({
        x: hour.hora,
        y: hour.g,
      }),
    );
    this.surplusData = input.horas.map(
      (hour): ChartDataI => ({
        x: hour.hora,
        y: -hour.e,
      }),
    );
    this.selfConsumptionData = input.horas.map(
      (hour): ChartDataI => ({
        x: hour.hora,
        y: hour.a,
      }),
    );
    this.networkData = input.horas.map(
      (hour): ChartDataI => ({
        x: hour.hora,
        y: hour.r,
      }),
    );
    this.totals = {
      generation: input.generacion,
      surplus: input.excedente,
      selfConsumption: input.autoconsumo,
      network: input.red,
    };

    return this;
  }
}
