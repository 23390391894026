import React, { useState, useEffect } from 'react';
import { I18n } from 'core';

import { VictoryAxis, VictoryLine, VictoryChart, VictoryLabel, VictoryContainer } from 'victory';
import { Colors } from 'styles';
import wattsConverterService from 'services/local/WattsConverter';
import { Card } from 'components';
import { YearlyGuaranteeEvolution } from 'types/models';
import { UtilsService } from 'services';
import cx from 'classnames';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/ArrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import { SizeMe } from 'react-sizeme';
import Constants from 'config/constants';
import styles from './ProdGuaranteeEvolutionCard.module.scss';

interface ListItemsPropsI {
  color: string;
  name: string;
  data: number;
}

interface ProdGuaranteeEvolutionCardI {
  years: Array<YearlyGuaranteeEvolution>;
}

const ListItem = (props: ListItemsPropsI): JSX.Element => {
  const { color, name, data } = props;

  return (
    <div className={styles.listitem}>
      <div className={styles.leftContainer}>
        <div className={cx(styles.dot, color)} />
        <p className={styles.text}>{name}</p>
      </div>
      <div className={styles.rightContainer}>
        <p className={styles.data}>{wattsConverterService.convertWatts(data).num}</p>
        <p className={styles.unit}>{wattsConverterService.convertWatts(data).unitHour}</p>
      </div>
    </div>
  );
};

// interface GuaranteePercentajeI {
//   text: string;
//   data: number;
// }

// const GuaranteePercentaje = (props: GuaranteePercentajeI): JSX.Element => {
//   const { text, data } = props;
//   const unit = '%';
//   return (
//     <div className="GuaranteePercentaje mt-2 d-flex align-items-center justify-content-between">
//       <p className="text">
//         {text}
//       </p>
//       <p className="data">
//         {data}
//         <span className="units">{unit}</span>
//       </p>
//     </div>
//   );
// };
interface ChartI {
  x: Date;
  y: number;
}

const ProdGuaranteeEvolutionCard = (props: ProdGuaranteeEvolutionCardI): JSX.Element => {
  const { years } = props;
  const [year, setYear] = useState<number>(years.length > 0 ? years[years.length - 1].year : 0);
  const [guaranteeData, setGuaranteeData] = useState<Array<ChartI>>(year > 0 ? years[year - 1].guaranteeData : []);
  const [generationData, setGenerationData] = useState<Array<ChartI>>(year > 0 ? years[year - 1].generationData : []);
  const [guarantee, setGuarantee] = useState(year > 0 ? years[year - 1].guarantee : 0);
  const [generation, setGeneration] = useState(year > 0 ? years[year - 1].generation : 0);

  const downYear = (): void => {
    // if ((currentYear > 1) && (currentYear <= pages)) {
    //   setCurrentYear(currentYear - 1);
    // }
    setYear(year - 1);
  };

  const upYear = (): void => {
    // if (currentYear < pages) {
    //   setCurrentYear(currentYear + 1);
    // }
    setYear(year + 1);
  };

  useEffect((): void => {
    if (years.length === 0 || undefined) {
      return;
    }
    setGuarantee(years[year - 1].guarantee);
    setGeneration(years[year - 1].generation);
    setGuaranteeData(years[year - 1].guaranteeData);
    setGenerationData(years[year - 1].generationData);
  }, [years, year]);

  const listItemsArray = [
    { color: 'bck-green', name: 'production guarantee', data: guarantee },
    { color: 'bck-orange', name: 'generation', data: generation },
  ];

  if (year === 0) {
    return (
      <div className={styles.year_zero}>
        <p className={styles.message}>{I18n.t('noDataObtained')}.</p>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.paginator_container}>
        <button type="button" disabled={year === 1} onClick={downYear} className={styles.btn_wrapper}>
          <ArrowLeftIcon height={18} width={18} fill={Colors.COLOR_ELECSUMGREEN} />
        </button>
        <div className={styles.current}>
          <span>
            {I18n.t('year')} {year}
          </span>
        </div>
        <button type="button" disabled={year === years.length} onClick={upYear} className={styles.btn_wrapper}>
          <ArrowRightIcon height={18} width={18} fill={Colors.COLOR_ELECSUMGREEN} />
        </button>
      </div>
      <Card
        noshadow
        body={[
          <div key={1}>
            <SizeMe>
              {({ size }) => (
                <div>
                  <svg viewBox={`0 0 ${size.width} 400`} height="100%" width="100%">
                    <VictoryChart
                      width={size.width || 500}
                      height={400}
                      standalone={false}
                      containerComponent={<VictoryContainer responsive={false} />}
                    >
                      <VictoryLabel
                        style={{
                          fontSize: Constants.charts.AXIS_LABELS_FONTSIZE,
                        }}
                        text="kWh"
                        x={30}
                        y={15}
                        textAnchor="middle"
                      />
                      <VictoryAxis
                        dependentAxis
                        tickFormat={(t): string => `${Math.round(t / 1000)}`}
                        style={{
                          axis: { stroke: 'transparent' },
                          tickLabels: {
                            fontSize: Constants.charts.AXIS_LABELS_FONTSIZE,
                            fill: Colors.COLOR_CONSUM_NOT_SELECTED,
                          },
                        }}
                      />
                      <VictoryAxis
                        style={{
                          axis: { stroke: 'transparent' },
                          tickLabels: {
                            fontSize: Constants.charts.AXIS_LABELS_FONTSIZE,
                            fill: Colors.COLOR_CONSUM_NOT_SELECTED,
                          },
                        }}
                        tickFormat={(t: Date): string =>
                          UtilsService.dateToLocaleString(new Date(t), { month: 'short' }, true)
                        }
                      />
                      <VictoryLine
                        interpolation="natural"
                        data={guaranteeData}
                        style={{
                          data: {
                            stroke: Colors.COLOR_ELECSUMGREEN,
                            strokeWidth: 2,
                          },
                        }}
                      />
                      <VictoryLine
                        interpolation="natural"
                        data={generationData}
                        style={{
                          data: {
                            stroke: Colors.COLOR_GENERACIO,
                            strokeWidth: 2,
                          },
                        }}
                      />
                    </VictoryChart>
                  </svg>
                </div>
              )}
            </SizeMe>

            <div className="mt-4 mx-5">
              {listItemsArray.map(
                (item): JSX.Element => (
                  <ListItem key={item.name} color={item.color} name={`${I18n.t(item.name)}`} data={item.data} />
                ),
              )}
              {/* <GuaranteePercentaje
              text={I18n.t('guarantee percentage achieved')}
              data={percentaje}
            /> */}
            </div>
          </div>,
        ]}
      />
    </div>
  );
};

export default ProdGuaranteeEvolutionCard;
