export interface ChartDataExtI {
  x: number;
  y: number;
  index: number;
}
export interface ProdEnergyProductionDataHistoricalAPII {
  años: Array<ProdEnergyProductionDataTotalYearlyAPII>;
}

export interface ProdEnergyProductionDataTotalYearlyAPII {
  año: number;
  generacion: number;
  garantia: number;
  diferenciaGarantia: number;
  estimacion: number;
  diferenciaEstimacion: number;
  diferenciaGeneracion: number;
}

export interface ProdEnergyProductionDataTotalYearly {
  year: number;
  generation: number;
  guarantee: number;
  diffGuarantee: number;
  estimate: number;
  diffEstimate: number;
  diffGeneration: number;
}

export default class ProdEnergyProductionDataHistorical {
  years: Array<ProdEnergyProductionDataTotalYearly>;

  generationData: Array<ChartDataExtI>;

  guaranteeData: Array<ChartDataExtI>;

  estimateData: Array<ChartDataExtI>;

  deserialize(input: ProdEnergyProductionDataHistoricalAPII): ProdEnergyProductionDataHistorical {
    if (!input) {
      return this;
    }

    this.years = input.años.map(
      (data: ProdEnergyProductionDataTotalYearlyAPII): ProdEnergyProductionDataTotalYearly => ({
        year: data.año,
        generation: data.generacion,
        guarantee: data.garantia,
        diffGuarantee: data.diferenciaGarantia,
        estimate: data.estimacion,
        diffEstimate: data.diferenciaEstimacion,
        diffGeneration: data.diferenciaGeneracion,
      }),
    );
    this.generationData = input.años.map(
      (año, i): ChartDataExtI => ({
        x: año.año,
        y: año.generacion,
        index: i,
      }),
    );
    this.guaranteeData = input.años.map(
      (año, i): ChartDataExtI => ({
        x: año.año,
        y: año.garantia,
        index: i,
      }),
    );
    this.estimateData = input.años.map(
      (año, i): ChartDataExtI => ({
        x: año.año,
        y: año.estimacion,
        index: i,
      }),
    );

    return this;
  }
}
