import React, { useEffect, useState } from 'react';

import { AdminCupsListItem } from 'types/models';
import cupsAdminServiceInstance from 'services/remote/TecnicoCupsService';
import { I18n } from 'core';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import { useDebounce } from 'hooks';
import { SelectItemI } from 'components/NewInputs/Select/ElecsumSelect';
import Button from 'components/Button/Button';
import { navigate } from 'navigation';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';

import styles from './CupsListPage.module.scss';

import { CupsListFilters, CupsListTable, CupsListTablePaginator } from './components';
import { FiltersStatusI } from './interfaces/FiltersStatus';

/* page
page_size
filters
	- garantia: boolean
	- garantiaAños: number
	- contratoMantenimiento: boolean
	- busqueda:string
  - autoconsumo: boolean
 */
const manteinanceYears: Array<SelectItemI> = Array(20)
  .fill(0)
  .map((x, index) => ({ id: `${index}`, label: `${index}`, value: `${index}` }));
manteinanceYears.splice(0, 0, {
  id: `${-1}`,
  label: `${I18n.t('CUPSListPage.filters.allYears')}`,
  value: null,
});
const CupsListPage = (): JSX.Element => {
  const PAGE_SIZE = 20;
  const [cupsData, setCupsData] = useState<Array<AdminCupsListItem>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [maxPages, setMaxPages] = useState<number>(0);
  const [filters, setFilters] = useState<FiltersStatusI>({
    searchText: undefined,
    autoconsumo: false,
    mantenimiento: false,
    garantia: false,
    industrial: false,
    añosGarantia: manteinanceYears[0],
  });

  const debouncedSearchText = useDebounce(filters.searchText, 500);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const handleNextPage = () => {
    if (currentPage < maxPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const refetchCups = (page: number) => {
    cupsAdminServiceInstance
      .getFilteredCups({
        pageSize: PAGE_SIZE,
        page,
        textoBusqueda: filters.searchText,
        contratoMantenimiento: filters.mantenimiento,
        autoconsumo: filters.autoconsumo,
        garantia: filters.garantia,
        ...(filters.añosGarantia?.value !== null &&
          filters.garantia && { garantiaAños: Number(filters.añosGarantia?.value) }),
        industrial: filters.industrial,
      })
      .then((response) => {
        setCurrentPage(response.paginacion.page);
        setMaxPages(response.paginacion.totalPages);
        setCupsData(response.data);
        setLoadingData(false);
      });
  };

  useEffect(() => {
    setLoadingData(true);
    refetchCups(1);
  }, [filters.mantenimiento, filters.autoconsumo, filters.garantia, filters.industrial, filters.añosGarantia]);

  useEffect(() => {
    setLoadingData(true);
    refetchCups(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setLoadingData(true);
    refetchCups(1);
  }, [debouncedSearchText]);

  const handleFilterChange = (name: string, value: any) => {
    setFilters((prev) => ({ ...prev, [`${name}`]: value }));
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Button
          className={styles.viewmore_button}
          variant="tertiary"
          type="button"
          onClick={(): Promise<void> => navigate('/tecnic/dashboard')}
          text={I18n.t('UsersPage.goDashboard')}
        />
      </div>
      <SectionWrapper>
        <CupsListFilters
          filtersValues={filters}
          handleChange={handleFilterChange}
          manteinanceOptions={manteinanceYears}
        />
        <div className={styles.table_wrapper}>
          {loadingData ? <FullPageSyncLoader icon /> : <CupsListTable data={cupsData} />}
        </div>
        {loadingData ? null : (
          <CupsListTablePaginator
            handleNext={handleNextPage}
            handlePrev={handlePrevPage}
            current={currentPage}
            max={maxPages}
          />
        )}
      </SectionWrapper>
    </div>
  );
};

export default CupsListPage;
