enum Roles {
  USER = 'ROLE_USER',
  CUPS = 'ROLE_CUPS',
  INSTALLATION = 'ROLE_INSTALLATION',
  CLIENT = 'ROLE_CLIENT',
  COMMUNITY = 'ROLE_COMMUNITY',
  ROLE_COMMUNITYENROLLED = 'ROLE_COMMUNITYENROLLED',
  DEFAULT = 'ROLE_DEFAULT',
  PROMOTOR = 'ROLE_PROMOTOR',
  MATCHER = 'ROLE_MATCHER',
  ROLE_PERMISIONS = 'ROLE_PERMISIONS',
}

export default Roles;
