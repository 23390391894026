/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/ArrowDown.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import cx from 'classnames';
import { ReactComponent as TickIcon } from 'assets/icons/Tick_Thin.svg';
import { useOutsideClick } from 'hooks';
import { CommunityModel } from 'types/models/community';
import { Colors } from 'styles';
import { I18n } from 'core';
import styles from './ElecsumSelect.module.scss';

export interface SelectItemI {
  id: string;
  value: string | null;
  label: string;
}

interface ElecsumSelectPropsI {
  selected?: CommunityModel;
  defaultSelect: number;
  values: Array<CommunityModel>;
  label: string;
  minWidth?: '7.5rem' | '10rem' | '12.5rem' | '15rem' | '17.5rem' | '20rem';
  onChange(element: CommunityModel): void;
  search: CallableFunction;
  disabled?: boolean; // Add a disabled prop
}

const ElecsumComunidadSelect = (props: ElecsumSelectPropsI) => {
  const dropdownRef = useRef(null);
  const { values, label, onChange, minWidth, search, defaultSelect, disabled } = props;
  const [defaultSelected, setDefaultSelected] = useState(defaultSelect);

  const selected = values.find((item) => item.id === defaultSelected);

  const [open, setOpen] = useState(false);

  useOutsideClick(dropdownRef, () => {
    setOpen(false);
  });

  const getHeadLabel = (): string => {
    if (!selected) return `${label}`;
    if (selected.id === null) return `${label}`;
    return `${selected.nombre}`;
  };
  const handleInputChange = (evt: any) => {
    search(evt.target.value);
  };

  return (
    <div style={{ minWidth }} ref={dropdownRef} className={cx(styles.container, { [styles.disabled]: disabled })}>
      <button
        onClick={() => setOpen(!open)}
        className={cx(styles.head, { [styles.open]: open })}
        type="button"
        disabled={disabled} // Set the disabled prop on the button
      >
        <span className={styles.label}>{getHeadLabel()}</span>
        <ArrowDownIcon />
      </button>

      <ul className={cx(styles.itemsComunidad, { [styles.open]: open })}>
        <div className={styles.header}>
          <SearchIcon height={18} width={18} fill={Colors.COLOR_GRAY_500} />
          <input
            placeholder={I18n.t('HeaderSearchInput.inputPlaceholderMatcher')}
            onChange={handleInputChange}
            className={styles.input}
            type="text"
          />
        </div>
        {values.map((item) => (
          <li
            onClick={() => {
              setOpen(false);
              setDefaultSelected(item.id);
              onChange(item);
            }}
            className={cx(styles.item, {
              [styles['item--selected']]:
                item.id === selected?.id || (selected === null && item.id === defaultSelected),
            })}
            key={item.id}
          >
            <span>{item.nombre}</span>
            {item.id === selected?.id ? <TickIcon /> : null}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ElecsumComunidadSelect;
