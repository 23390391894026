import { ProdGuaranteeDataAPII } from '../types/models/widgetsData/prodGuaranteeEvolutionData';

const PROD_GUARANTEE_EVOLUTION_DATA_MOCK: ProdGuaranteeDataAPII = {
  años: [
    {
      numero: 1,
      garantia: 3430,
      generacion: 4350,
      meses: [
        {
          mes: 12,
          año: 2019,
          garantia: 15,
          generacion: 12,
        },
        {
          mes: 1,
          año: 2020,
          garantia: 15,
          generacion: 12,
        },
        {
          mes: 2,
          año: 2020,
          garantia: 15,
          generacion: 12,
        },
        {
          mes: 3,
          año: 2020,
          garantia: 15,
          generacion: 12,
        },
        {
          mes: 4,
          año: 2020,
          garantia: 15,
          generacion: 12,
        },
        {
          mes: 5,
          año: 2020,
          garantia: 15,
          generacion: 12,
        },
        {
          mes: 6,
          año: 2020,
          garantia: 15,
          generacion: 12,
        },
        {
          mes: 7,
          año: 2020,
          garantia: 15,
          generacion: 12,
        },
        {
          mes: 8,
          año: 2020,
          garantia: 15,
          generacion: 12,
        },
        {
          mes: 9,
          año: 2020,
          garantia: 15,
          generacion: 12,
        },
        {
          mes: 10,
          año: 2020,
          garantia: 15,
          generacion: 12,
        },
        {
          mes: 11,
          año: 2020,
          garantia: 15,
          generacion: 12,
        },
      ],
    },
    {
      numero: 2,
      garantia: 2130,
      generacion: 3550,
      meses: [
        {
          mes: 12,
          año: 2019,
          garantia: 30,
          generacion: 25,
        },
        {
          mes: 1,
          año: 2020,
          garantia: 32,
          generacion: 22,
        },
        {
          mes: 2,
          año: 2020,
          garantia: 42,
          generacion: 32,
        },
        {
          mes: 3,
          año: 2020,
          garantia: 35,
          generacion: 22,
        },
        {
          mes: 4,
          año: 2020,
          garantia: 50,
          generacion: 42,
        },
        {
          mes: 5,
          año: 2020,
          garantia: 46,
          generacion: 31,
        },
        {
          mes: 6,
          año: 2020,
          garantia: 25,
          generacion: 18,
        },
        {
          mes: 7,
          año: 2020,
          garantia: 33,
          generacion: 26,
        },
        {
          mes: 8,
          año: 2020,
          garantia: 38,
          generacion: 32,
        },
        {
          mes: 9,
          año: 2020,
          garantia: 42,
          generacion: 36,
        },
        {
          mes: 10,
          año: 2020,
          garantia: 36,
          generacion: 29,
        },
        {
          mes: 11,
          año: 2020,
          garantia: 30,
          generacion: 21,
        },
      ],
    },
  ],
};

export default PROD_GUARANTEE_EVOLUTION_DATA_MOCK;
