import React, { useState, useEffect, useCallback, useMemo } from 'react';
import cx from 'classnames';
import { DayPicker, MonthPicker, YearPicker } from 'components/Input';
import { ReactComponent as GenerationIcon } from 'assets/icons/Generation.svg';
import { toast } from 'react-toastify';
import wattsConverterService from 'services/local/WattsConverter';
import curvaConsumoTeledetectionService from 'services/remote/CurvaConsumoTeledetectionService';
import CurvaTeledetectionChart from 'components/CurvaTeledetectionChart/CurvaTeledetectionChart';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import GraficaCurvaConsumoTeledectionData from 'types/models/graficaCurvateledetectionData';
import { Colors } from 'styles';
import I18n from 'i18n-js';
import styles from './grafic-curva-consumo.module.scss';

interface DataItemPropsI {
  icon: JSX.Element;
  name: string;
  data: number;
}

const DataItem = ({ icon, name, data }: DataItemPropsI): JSX.Element => (
  <div className={cx(styles.DataItem)}>
    <div className={styles.type}>
      {icon}
      <p className={styles.name}>{I18n.t(name)}</p>
    </div>
    <div className={styles.value}>
      <p className={styles.data}>{wattsConverterService.convertWatts(data).num}</p>
      <p className={styles.unit}>{wattsConverterService.convertWatts(data).unitHour}</p>
    </div>
  </div>
);

interface GraficoCurvaConsumoProops {
  id: number;
  miembroId: number;
}
const ICON_SIZE = '25px';
const GraficoCurvaConsumo = ({ id, miembroId }: GraficoCurvaConsumoProops) => {
  const [loadingDetailData, setLoadingDetailData] = useState(false);
  const [teledetectionData, setTeledetectionData] = useState(new GraficaCurvaConsumoTeledectionData());
  const [currentTab, setCurrentTab] = useState('day');

  const getInitalDate = useMemo(() => {
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday;
  }, []);

  const [date, setDate] = useState({
    day: getInitalDate.getDate(),
    month: getInitalDate.getMonth() + 1,
    year: getInitalDate.getFullYear(),
  });

  const updateDate2 = useCallback((newDate: Date): void => {
    if (!newDate) return;
    setDate({
      day: newDate.getDate(),
      month: newDate.getMonth() + 1,
      year: newDate.getFullYear(),
    });
  }, []);

  const getDateComponent = useMemo((): JSX.Element => {
    if (currentTab === 'day') {
      return <DayPicker date={new Date(`${date.month}/${date.day}/${date.year}`)} onChangeDate={updateDate2} />;
    }
    if (currentTab === 'month') {
      return (
        <MonthPicker
          anchorSelector="right"
          value={new Date(`${date.month}/${date.day}/${date.year}`)}
          onChange={updateDate2}
        />
      );
    }
    if (currentTab === 'year') {
      return (
        <YearPicker
          anchorSelector="right"
          value={new Date(`${date.month}/${date.day}/${date.year}`)}
          onChange={updateDate2}
        />
      );
    }
    return <></>;
  }, [currentTab, date, updateDate2]);

  const fetchData = useCallback(async (): Promise<void> => {
    setLoadingDetailData(true);
    try {
      const teledetectionObj = await curvaConsumoTeledetectionService.getGraficaCurvaConsumoTeledectionData(
        currentTab,
        id,
        miembroId,
        date.month,
        date.day,
      );
      setTeledetectionData(teledetectionObj);
    } catch (error) {
      toast.error('Error getting teledetection data');
    } finally {
      setLoadingDetailData(false);
    }
  }, [currentTab, id, miembroId, date]);
  const handleTabChange = useCallback(
    (tab: string) => {
      if (tab !== currentTab) {
        setCurrentTab(tab);
        setLoadingDetailData(true);
      }
    },
    [currentTab],
  );
  useEffect(() => {
    fetchData();
  }, [currentTab, id, miembroId, date]);

  if (loadingDetailData) {
    return <FullPageSyncLoader icon />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.chartWrapper}>
        <CurvaTeledetectionChart
          isLoading={loadingDetailData}
          fotovoltaica="no"
          teledetectionData={teledetectionData}
          dateComponent={getDateComponent}
          currentTab={currentTab}
          setCurrentTabFunction={handleTabChange}
        />

        {!loadingDetailData ? (
          <div className={styles.grid_container}>
            {teledetectionData && (
              <>
                <div className={styles.item}>
                  {teledetectionData.totals !== undefined && teledetectionData.totals.consumption !== undefined ? (
                    <DataItem
                      icon={<GenerationIcon fill={Colors.COLOR_GENERACIO} width={ICON_SIZE} height={ICON_SIZE} />}
                      name="consumo"
                      data={teledetectionData.totals.consumption}
                    />
                  ) : null}
                </div>
              </>
            )}
          </div>
        ) : (
          <div style={{ height: 140 }} />
        )}
      </div>
    </div>
  );
};

export default GraficoCurvaConsumo;
