import { Serializable } from '../interfaces';

interface Combinacion {
  duracionDeCalculos: number;
  numeroMiembros: number;
  generacion: number;
  consumo: number;
  autoconsumo: number;
  excedente: number;
  autoconsumoEuro: number;
  excedenteEuro: number;
  ahorrosEuro: number;
  autoconsumoPorcentaje: number;
  excedentePorcentaje: number;
  esLaOptima: boolean;
}

interface Miembro {
  idMiembro: number;
  idInscrito: string;
  nombre: string;
  apellidos: string;
  aceptado: boolean;
  betaFija: number;
  betaFijaDiasLaborales: number;
  betaFijaDiasFestivos: number;
  betaFijaAgosto: number;
  autoconsumoEuro: number;
  excedenteEuro: number;
  ahorrosEuro: number;
}

interface Error {
  codigo: string;
  mensaje: string;
}

interface MatcherInformeAPI {
  fechaDeInforme: string;
  duracionCalculos: number;
  nombreComunidad: string;
  nombreConfiguracion: string;
  numeroDeMiembrosSelecionados: number;
  numeroDeMiembrosAceptados: number;
  generacion: number;
  consumo: number;
  autoConsumo: number;
  excedente: number;
  autoConsumoEuro: number;
  excedentelEuro: number;
  ahorrosEuro: number;
  excedentePorcentaje: number;
  autoconsumoPorcentaje: number;
  listaCombinaciones: Combinacion[];
  listaMiembros: Miembro[];
  errores: Error[];
  metodoDeCalculos: string;
}

export default class MatcherInforme implements Serializable<MatcherInforme> {
  fechaDeInforme: string;

  duracionCalculos: number;

  nombreComunidad: string;

  nombreConfiguracion: string;

  numeroDeMiembrosSelecionados: number;

  numeroDeMiembrosAceptados: number;

  generacion: number;

  consumo: number;

  autoConsumo: number;

  excedente: number;

  autoConsumoEuro: number;

  excedentelEuro: number;

  ahorrosEuro: number;

  excedentePorcentaje: number;

  autoconsumoPorcentaje: number;

  listaCombinaciones: Combinacion[];

  listaMiembros: Miembro[];

  errores: Error[];

  metodoDeCalculos: string;

  deserialize(input: MatcherInformeAPI): MatcherInforme {
    if (!input) {
      return this;
    }

    this.fechaDeInforme = input.fechaDeInforme;

    this.duracionCalculos = input.duracionCalculos;

    this.nombreComunidad = input.nombreComunidad;

    this.nombreConfiguracion = input.nombreConfiguracion;

    this.numeroDeMiembrosSelecionados = input.numeroDeMiembrosSelecionados;

    this.numeroDeMiembrosAceptados = input.numeroDeMiembrosAceptados;

    this.generacion = input.generacion;

    this.consumo = input.consumo;

    this.autoConsumo = input.autoConsumo;

    this.excedente = input.excedente;

    this.autoConsumoEuro = input.autoConsumoEuro;

    this.excedentelEuro = input.excedentelEuro;

    this.ahorrosEuro = input.ahorrosEuro;

    this.excedentePorcentaje = input.excedentePorcentaje;

    this.autoconsumoPorcentaje = input.autoconsumoPorcentaje;

    this.listaCombinaciones = input.listaCombinaciones
      ? input.listaCombinaciones.map((combinaciones: Combinacion) => ({ ...combinaciones }))
      : [];

    this.listaMiembros = input.listaMiembros ? input.listaMiembros.map((miembros: Miembro) => ({ ...miembros })) : [];

    this.errores = input.errores;
    this.metodoDeCalculos = input.metodoDeCalculos || '';
    return this;
  }

  deserializeArray(inputArray: Array<MatcherInformeAPI>): Array<MatcherInforme> {
    return inputArray.map((input) => new MatcherInforme().deserialize(input));
  }

  setAttr<T>(key: string, value: T): void {
    Object.assign(this, { [key]: value });
  }
}
