/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { I18n } from 'core';
import {
  TeledetectionData,
  ProdEnergyProductionDataDaily,
  ProdEnergyProductionDataMonthly,
  ProdEnergyProductionDataYearly,
  ProdEnergyProductionDataHistorical,
} from 'types/models';
import {
  SectionTitle,
  ProductionDailyChart,
  FreqDataChartPicker,
  ProductionEnergyMonthlyChart,
  ProductionEnergyChart,
  ProductionEnergyHistoricChart,
  Spinner,
} from 'components';
import { toast } from 'react-toastify';

import { clientServiceInstance, LoggingService, installationServiceInstance } from 'services';

import teledetectionService from 'services/remote/TeledetectionService';

import { DayPicker, MonthPicker, YearPicker } from 'components/Input';
import { useSelector } from 'react-redux';
import { GlobalStoreState } from 'types/state';
import styles from './ProductionChartCard.module.scss';

interface ProductionChartCardPropsI {
  installationID: string;
  cupsSelected: string | null;
}

const ProductionChartCard = (props: ProductionChartCardPropsI): JSX.Element => {
  const { installationID, cupsSelected } = props;
  const NAV_HISTORIC = 'historic';
  const NAV_YEAR = 'year';
  const NAV_MONTH = 'month';
  const NAV_DAY = 'day';

  const [title, setTitle] = useState('Daily');

  const [teledetectionData, setTeledetectionData] = useState(new TeledetectionData());
  const [energyProductionDataDaily, setenergyProductionDataDaily] = useState(new ProdEnergyProductionDataDaily());
  const [energyProductionDataMonthly, setEnergyProductionDataMonthly] = useState(new ProdEnergyProductionDataMonthly());
  const [energyProductionDataYearly, setEnergyProductionDataYearly] = useState(new ProdEnergyProductionDataYearly());
  const [energyProductionDataHistorical, setEnergyProductionDataHistorical] = useState(
    new ProdEnergyProductionDataHistorical(),
  );
  const [fechaValida, setFechaValida] = useState<boolean>(true);

  const installationSelected = useSelector((state: GlobalStoreState) => state.installations.selectedInstallation);

  const getInitalDate = (): Date => {
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(yesterday.getDate() - 1);

    return yesterday;
  };

  const [date, setDate] = useState({
    day: getInitalDate().getDate(),
    month: getInitalDate().getMonth() + 1,
    year: getInitalDate().getFullYear(),
  });
  const [dateUpdate, setDateUpdate] = useState({
    day: getInitalDate().getDate(),
    month: getInitalDate().getMonth() + 1,
    year: getInitalDate().getFullYear(),
  });
  const [currentTab, setCurrentTab] = useState(NAV_DAY);
  const [dataIsLoad, setDataIsLoad] = useState(false);

  const [dailyDataLoaded, setDailyDataLoaded] = useState(false);
  const [monthlyDataLoaded, setMonthlyDataLoaded] = useState(false);
  const [yearlyDataLoaded, setYearlyDataLoaded] = useState(false);
  const [historicDataLoaded, setHistoricDataLoaded] = useState(false);

  const fetchLastDate = async () => {
    if (!installationID) {
      return;
    }
    const clientsInstallation = await clientServiceInstance.getClientsFromInstallation(installationID);
    const lastDatesClients = clientsInstallation.map(async (c: any) => {
      const ldate: any = await teledetectionService.getFechaUltimosDatos(c.cups);

      const { data } = ldate;
      const { datos } = data;
      const { fecha } = datos;
      const ultimaFecha = new Date(fecha);

      if (Number.isNaN(ultimaFecha.getTime())) {
        setFechaValida(false);
      }
      return ultimaFecha;
    });

    Promise.all(lastDatesClients).then((values) => {
      const latestDate = values.reduce((prev, current) => (prev > current ? current : prev));
      setDate({
        day: latestDate.getDate(),
        month: latestDate.getMonth() + 1,
        year: latestDate.getFullYear(),
      });
    });
    setDataIsLoad(true);
  };

  useEffect(() => {
    fetchLastDate();
  }, [cupsSelected]);

  const updateDate = (newDate: Date): void => {
    if (!newDate) {
      return;
    }
    setDate({
      day: newDate.getDate(),
      month: newDate.getMonth() + 1,
      year: newDate.getFullYear(),
    });

    setDateUpdate({
      day: newDate.getDate(),
      month: newDate.getMonth() + 1,
      year: newDate.getFullYear(),
    });
  };
  const getDateComponent = (): JSX.Element => {
    if (currentTab === NAV_DAY) {
      return (
        <>
          <DayPicker date={new Date(`${date.month}/${date.day}/${date.year}`)} onChangeDate={updateDate} />
        </>
      );
    }
    if (currentTab === NAV_MONTH) {
      return (
        <>
          <MonthPicker
            anchorSelector="right"
            value={new Date(`${date.month}/${date.day}/${date.year}`)}
            onChange={updateDate}
          />
        </>
      );
    }
    if (currentTab === NAV_YEAR) {
      return (
        <>
          <YearPicker
            anchorSelector="right"
            value={new Date(`${date.month}/${date.day}/${date.year}`)}
            onChange={updateDate}
          />
        </>
      );
    }
    return <></>;
  };

  useEffect((): void => {
    const fetchData = async (): Promise<void> => {
      if (!fechaValida) {
        return; // Salir temprano si fechaValida es false
      }
      try {
        if (installationID) {
          setDailyDataLoaded(false);
          if (currentTab === 'day') {
            const energyProductionDaily: ProdEnergyProductionDataDaily =
              await installationServiceInstance.getProdEnergyProductionDataDaily(
                installationID,
                date.year.toString(),
                date.month.toString(),
                date.day.toString(),
                cupsSelected || undefined,
              );

            setenergyProductionDataDaily(energyProductionDaily);
            const detectionData = new TeledetectionData();
            detectionData.generationData = energyProductionDaily.generationData;
            detectionData.selfConsumptionData = energyProductionDaily.selfConsumptionData;
            detectionData.shirnkData = energyProductionDaily.surplusData;
            detectionData.networkonData = energyProductionDaily.networkData;
            setDailyDataLoaded(true);
            setTeledetectionData(detectionData);
          }
          if (currentTab === 'month') {
            setMonthlyDataLoaded(false);
            const energyProductionMonthly: ProdEnergyProductionDataMonthly =
              await installationServiceInstance.getProdEnergyProductionDataMonthly(
                installationID,
                date.year.toString(),
                date.month.toString(),
              );
            setEnergyProductionDataMonthly(energyProductionMonthly);
            setMonthlyDataLoaded(true);
          }
          if (currentTab === 'year') {
            setYearlyDataLoaded(false);
            const energyProductionYearly: ProdEnergyProductionDataYearly =
              await installationServiceInstance.getProdEnergyProductionDataYearly(installationID, date.year.toString());
            setEnergyProductionDataYearly(energyProductionYearly);
            setYearlyDataLoaded(true);
          }
          if (currentTab === 'historic') {
            setHistoricDataLoaded(false);
            const energyProductionHistorical: ProdEnergyProductionDataHistorical =
              await installationServiceInstance.getProdEnergyProductionDataHistorical(installationID);
            setEnergyProductionDataHistorical(energyProductionHistorical);
            setHistoricDataLoaded(true);
          }
        }
      } catch (error) {
        LoggingService.error(error);
        toast.error('errorGettingTeledetectionData');
      }
    };
    if (!fechaValida) return;
    fetchData();
  }, [currentTab, dateUpdate, installationID, cupsSelected]);

  useEffect((): void => {
    const changeTitle = (): void => {
      if (currentTab === NAV_DAY) {
        setTitle('daily');
      } else if (currentTab === NAV_MONTH) {
        setTitle('monthly');
      } else if (currentTab === NAV_YEAR) {
        setTitle('annual');
      } else if (currentTab === NAV_HISTORIC) {
        setTitle('historical');
      }
    };

    changeTitle();
  }, [currentTab]);

  if (!fechaValida) return <div className={styles.nodata}>{I18n.t('noDataObtained')}.</div>;
  if (!dataIsLoad) {
    return (
      <div className={styles.loading_wrapper}>
        <Spinner icon />
      </div>
    );
  }

  return (
    <>
      <div className={styles.root}>
        <SectionTitle text={I18n.t(title)} />
        <div className={styles.picker_container}>
          <FreqDataChartPicker
            dateComponent={getDateComponent()}
            currentTab={currentTab}
            setCurrentTabFunction={setCurrentTab}
          />
        </div>
        <div className={styles.graph_container}>
          {currentTab === 'day' ? (
            dailyDataLoaded ? (
              <>
                <ProductionDailyChart
                  teledetectionData={teledetectionData}
                  date={date}
                  currentTab={currentTab}
                  generation={energyProductionDataDaily.totals.generation}
                  surplus={energyProductionDataDaily.totals.surplus}
                  selfConsumption={energyProductionDataDaily.totals.selfConsumption}
                  network={energyProductionDataDaily.totals.network}
                  diffGeneration={energyProductionDataDaily.differenceGeneration}
                  radius={installationSelected?.seguimientoRadioInstalaciones || 0}
                />
              </>
            ) : (
              <div className={styles.loading_wrapper}>
                <Spinner icon />
              </div>
            )
          ) : null}
          {currentTab === 'month' ? (
            monthlyDataLoaded ? (
              <>
                <ProductionEnergyMonthlyChart
                  generationData={energyProductionDataMonthly.generationData}
                  guaranteeData={energyProductionDataMonthly.guaranteeData}
                  estimateData={energyProductionDataMonthly.estimateData}
                  generation={energyProductionDataMonthly.generation}
                  guarantee={energyProductionDataMonthly.guarantee}
                  estimate={energyProductionDataMonthly.estimate}
                  differenceGeneration={energyProductionDataMonthly.differenceGeneration}
                  date={date}
                  currentTab={currentTab}
                  radius={installationSelected?.seguimientoRadioInstalaciones || 0}
                />
              </>
            ) : (
              <div className={styles.loading_wrapper}>
                <Spinner icon />
              </div>
            )
          ) : null}
          {currentTab === 'year' ? (
            yearlyDataLoaded ? (
              <ProductionEnergyChart
                generationData={energyProductionDataYearly.generationData}
                guaranteeData={energyProductionDataYearly.guaranteeData}
                estimateData={energyProductionDataYearly.estimateData}
                monthlyData={energyProductionDataYearly.months}
                date={date}
                currentTab={currentTab}
                radius={installationSelected?.seguimientoRadioInstalaciones || 0}
              />
            ) : (
              <div className={styles.loading_wrapper}>
                <Spinner icon />
              </div>
            )
          ) : null}
          {currentTab === 'historic' ? (
            historicDataLoaded ? (
              <ProductionEnergyHistoricChart
                generationData={energyProductionDataHistorical.generationData}
                guaranteeData={energyProductionDataHistorical.guaranteeData}
                estimateData={energyProductionDataHistorical.estimateData}
                yearlyData={energyProductionDataHistorical.years}
                date={date}
                currentTab={currentTab}
                radius={installationSelected?.seguimientoRadioInstalaciones || 0}
              />
            ) : (
              <div className={styles.loading_wrapper}>
                <Spinner icon />
              </div>
            )
          ) : null}
        </div>
      </div>
    </>
  );
};
export default ProductionChartCard;
