interface TecnologiaI {
  fotovoltaica: string;
  eolica: string;
}

interface PotenciasI {
  potencia1: number;
  potencia2: number;
  potencia3: number;
  potencia4: number;
  potencia5: number;
  potencia6: number;
}

interface InstallationLine {
  tecnologia: string;
  potencia: number;
}

export interface ContractSummaryAPII {
  codigoCliente: string;
  tarifa: string;
  tarifaComercializadora: string;
  potencias: PotenciasI;
  tecnologia: TecnologiaI;
  instalaciones?: Array<InstallationLine>;
  porcentajeExencionImpuesto: number;
  porcentajeElectricidadBonificacion: number;
  comercializado: string;
}

export default class ContractSummary {
  codigoCliente: string;

  tarifa: string;

  tarifaComercializadora: string;

  potencias: PotenciasI;

  tecnologia: TecnologiaI;

  instalaciones?: Array<InstallationLine>;

  porcentajeExencionImpuesto: number;

  porcentajeElectricidadBonificacion: number;

  comercializado: string;

  deserialize(input: ContractSummaryAPII): ContractSummary {
    if (!input) {
      return this;
    }

    this.codigoCliente = input.codigoCliente || '';
    this.tarifa = input.tarifa || '';
    this.tarifaComercializadora = input.tarifaComercializadora || '';
    this.porcentajeElectricidadBonificacion = input.porcentajeElectricidadBonificacion || 0;
    this.porcentajeExencionImpuesto = input.porcentajeExencionImpuesto || 0;

    this.potencias = input.potencias
      ? {
          potencia1: input.potencias.potencia1 || 0,
          potencia2: input.potencias.potencia2 || 0,
          potencia3: input.potencias.potencia3 || 0,
          potencia4: input.potencias.potencia4 || 0,
          potencia5: input.potencias.potencia5 || 0,
          potencia6: input.potencias.potencia6 || 0,
        }
      : {
          potencia1: 0,
          potencia2: 0,
          potencia3: 0,
          potencia4: 0,
          potencia5: 0,
          potencia6: 0,
        };

    this.tecnologia = {
      fotovoltaica: input.tecnologia?.fotovoltaica || 'no',
      eolica: input.tecnologia?.eolica || 'no',
    };

    this.instalaciones = input.instalaciones
      ? input.instalaciones.map(
          (inst: InstallationLine): InstallationLine => ({
            tecnologia: inst.tecnologia || 'empty',
            potencia: inst.potencia || 0,
          }),
        )
      : [];
    this.comercializado = input.comercializado || 'no';
    return this;
  }
}
