import React, { useState, useEffect } from 'react';

import './TestTable.scss';

import Table, { TableHeading } from '../Table/Table';

interface EstudioI {
  id: string;
  mes: string;
  estimacion: number;
  garantia: number;
}

const headings: Array<TableHeading> = [
  { label: 'ID', key: 'id' },
  { label: 'MES', key: 'mes' },
  { label: 'ESTIMACION', key: 'estimacion' },
  { label: 'GARANTIA', key: 'garantia' },
];

const ESTUDIO_DATA = [
  {
    id: '0',
    mes: 'enero',
    estimacion: 100,
    garantia: 90,
  },
  {
    id: '1',
    mes: 'febrero',
    estimacion: 200,
    garantia: 190,
  },
  {
    id: '2',
    mes: 'marzo',
    estimacion: 300,
    garantia: 290,
  },
  {
    id: '3',
    mes: 'abril',
    estimacion: 400,
    garantia: 390,
  },
];

const TestTable = (): JSX.Element => {
  const [tableData, setTableData] = useState<Array<EstudioI>>([]);

  useEffect(() => {
    setTableData(ESTUDIO_DATA);
  }, []);

  return (
    <div>
      <h1>TestTable</h1>
      <Table headings={headings} rows={tableData} />
      <pre>{JSON.stringify(tableData, null, 2)}</pre>
    </div>
  );
};

export default TestTable;
