import configuration from 'config';
import { AxiosResponse } from 'axios';
import {
  Maximeters,
  MonthlyConsumption,
  MeasurementRegisters,
  MeasurementRegistersMaximeter,
  ClosuresRangeObject,
  MeasuringEquipmentObject,
} from 'types/models/widgetsCIM';
import { MeasuremenRegistersAPII } from 'types/models/widgetsCIM/measurementsRegistersObj';
import { MeasuremenRegistersMaximeterAPII } from 'types/models/widgetsCIM/measurementsRegistersMaximeterObj';
import InvoiceSimulation from 'types/models/invoiceSimulation';
import PowerOptimization from 'types/models/powerOptimization';
import apiService from './ApiService';

export class CIMService {
  readonly http = apiService;

  private apiUrl = configuration.apiUrl;

  async getMaximeters(cups: string): Promise<Maximeters> {
    const url = `${this.apiUrl}/fv/widget/ultimos-maximetros/${cups}`;
    const response: AxiosResponse = await this.http.get(url);
    const data = response.data as Maximeters;
    if (response.data === {}) return new Maximeters().deserialize({ datos: [], habilitado: 'si' });
    return new Maximeters().deserialize(data);
  }

  async getMonthlyConsumption(cups: string, year: string): Promise<MonthlyConsumption> {
    const url = `${this.apiUrl}/fv/widget/consumo-anual-periodos/${cups}/${year}/`;
    const response: AxiosResponse = await this.http.get(url);
    const data = response.data as MonthlyConsumption;

    return new MonthlyConsumption().deserialize(data);
  }

  async getMonthlyConsumptionTarifa(cups: string, year: string): Promise<MonthlyConsumption> {
    const url = `${this.apiUrl}/fv/widget/consumo-anual-periodos-tarifa/${cups}/${year}/`;
    const response: AxiosResponse = await this.http.get(url);
    const data = response.data as MonthlyConsumption;

    return new MonthlyConsumption().deserialize(data);
  }

  async getMeasuringEquipment(cups: string): Promise<MeasuringEquipmentObject> {
    const url = `${this.apiUrl}/fv/widget/equipos-de-medicion/${cups}/`;
    const response: AxiosResponse = await this.http.get(url);

    return new MeasuringEquipmentObject().deserialize(response.data);
  }

  async getMeasurementRegisters(
    cups: string,
    startDate: string,
    endDate: string,
    grouping: string,
  ): Promise<MeasurementRegisters> {
    const params = {
      fechaInicio: startDate,
      fechaFin: endDate,
      agrupacion: grouping === 'todo' ? undefined : grouping,
    };
    const url = `${this.apiUrl}/fv/widget/registros-de-medicion/${cups}`;
    const response: AxiosResponse = await this.http.get(url, { params });
    const data = response.data as MeasuremenRegistersAPII;

    return new MeasurementRegisters().deserialize(data);
  }

  async getMeasurementRegistersNew(
    cups: string,
    startDate: string,
    endDate: string,
    grouping: string,
  ): Promise<MeasurementRegisters> {
    const params = {
      fechaInicio: startDate,
      fechaFin: endDate,
      agrupacion: grouping === 'todo' ? undefined : grouping,
    };
    const url = `${this.apiUrl}/fv/widget/registros-de-medicion/${cups}`;
    const response: AxiosResponse = await this.http.get(url, { params });
    const data = response.data as MeasuremenRegistersAPII;

    return new MeasurementRegisters().deserialize(data);
  }

  async getMeasurementRegistersMaximeter(
    cups: string,
    startDate: string,
    endDate: string,
  ): Promise<MeasurementRegistersMaximeter> {
    const params = {
      fechaInicio: startDate,
      fechaFin: endDate,
    };
    const url = `${this.apiUrl}/fv/widget/registros-de-medicion-maximetros/${cups}`;
    const response: AxiosResponse = await this.http.get(url, { params });
    const data = response.data as MeasuremenRegistersMaximeterAPII;

    return new MeasurementRegistersMaximeter().deserialize(data);
  }

  public async getPowerOptimization(opts: {
    cups: string;
    fechaInicio: string;
    fechaFin: string;
  }): Promise<PowerOptimization | null> {
    const { cups, fechaInicio, fechaFin } = opts;
    const params = { fechaInicio, fechaFin };
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/widget/optimizacion-de-potencia/${cups}`, {
      params,
    });

    if (Object.keys(response.data.datos).length === 0) {
      return null;
    }
    return new PowerOptimization().deserialize(response.data);
  }

  async getInvoiceSimulation(opts: {
    cups: string;
    fechaInicio: string;
    fechaFin: string;
    potencia1: number;
    potencia2: number;
    potencia3: number;
    potencia4: number;
    potencia5: number;
    potencia6: number;
  }): Promise<InvoiceSimulation> {
    const { cups, fechaInicio, fechaFin, potencia1, potencia2, potencia3, potencia4, potencia5, potencia6 } = opts;
    const params = { fechaInicio, fechaFin, potencia1, potencia2, potencia3, potencia4, potencia5, potencia6 };
    const url = `${this.apiUrl}/fv/widget/simulacion-de-factura/${cups}`;
    const response: AxiosResponse = await this.http.get(url, { params });
    return new InvoiceSimulation().deserialize(response.data);
  }

  async getClosuresRanges(cups: string): Promise<ClosuresRangeObject> {
    const url: string = `${this.apiUrl}/fv/widget/cierres-fechas/${cups}/`;
    const response: AxiosResponse = await this.http.get(url);

    return new ClosuresRangeObject().deserialize(response.data);
  }

  public async exportConsumosData(opts: { cups: string; fechaInicio: string; fechaFin: string }) {
    const { cups, fechaInicio, fechaFin } = opts;
    const params = { fechaInicio, fechaFin };
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/exportacion-datos/consumos/${cups}`, {
      params,
    });
    return response;
  }

  public async exportCierresData(opts: { cups: string; fechaInicio: string; fechaFin: string }) {
    const { cups, fechaInicio, fechaFin } = opts;
    const params = { fechaInicio, fechaFin };
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/exportacion-datos/cierres/${cups}`, {
      params,
    });
    return response;
  }
}

const CIMServiceInstance = new CIMService();

export default CIMServiceInstance;
