import React, { useEffect, useState } from 'react';
import { FormGroup, FormGroupProps } from 'core';

import { DataTable, EunoiaImageInput, LoadingButton, SectionTitle, TableHeading } from 'components';

import { Colors } from 'styles';
import I18n from 'i18n-js';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import Modal from 'components/Modal/Modal';
// import CircularProgressBar from 'components/ProgressBar/ProgressBar';
import { ReactComponent as LandscapeIcon } from 'assets/icons/Landscape.svg';
import { LoggingService } from 'services';
import customToast from 'components/CustomToast/CustomToast';
import { toast } from 'react-toastify';
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg';

import './fix-tarifa-detalles-inscritos.module.scss';
import Button from 'components/Button/Button';
import { navigate } from 'navigation';
import { useHistory, useParams } from 'react-router-dom';

import UpdateTarifaServiceInstance from 'services/remote/UpdateTarifaService';
import ActualizarTarifa, { ActualizarTarifaAPII } from 'types/models/updateTarifa';
import { SolicitudActualizarDTO } from 'types/models/SolicitudActualizar';
import { FORMGROUP_TYPES } from 'core/form/FormGroup/FormGroup';
import styles from './tarifa-detalles-inscritos.module.scss';

interface ParameterDetalles {
  communityID: string;
}

export const TarifaInscritoDetalles = (props: any): JSX.Element => {
  // Props

  const {
    match: {
      params: { tarifaID },
    },
  } = props;
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const history = useHistory();

  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(false);

  const [installation, setInstallation] = useState<ActualizarTarifaAPII>();

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [imagenDettale, setImagen] = useState<string>();
  const [actualizar, setActualizar] = useState<boolean>(false);
  // const [setpdf] = useState<boolean>(false);
  const [nameImagen, setnameImagen] = useState<string>('');
  const [indicearray, setindicearray] = useState<number>(0);
  const { communityID } = useParams<ParameterDetalles>();

  function isImage(url: any) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  }
  useEffect(() => {
    UpdateTarifaServiceInstance.getSolicitudActualizacion(tarifaID)
      .then(async (response) => {
        setInstallation(response);
      })
      .then()
      .finally(() => {
        setLoadingData(false);
        setActualizar(false);
      });
  }, [actualizar]);

  const validateForm = (): boolean => {
    if (!file) {
      setSubmitDisabled(true);
      setErrors({});
      return false;
    }
    setSubmitDisabled(false);
    setErrors({});
    return true;
  };
  const submitForm = async (): Promise<boolean> => {
    setLoading(true);
    setSubmitDisabled(true);

    try {
      if (file) {
        const response = await UpdateTarifaServiceInstance.addGaleryImage(tarifaID, file, nameImagen);
        if (response === 201) {
          customToast.success(I18n.t('Inscrito saved!'));

          setSubmitDisabled(false);
          setFile(undefined);
          setnameImagen('');
          setActualizar(true);
        }
      }
    } catch (error) {
      toast.error(I18n.t('ErrorInscritos'));
      setSubmitDisabled(false);

      setFile(undefined);
      setActualizar(true);
    }

    setLoading(false);
    setFile(undefined);
    setnameImagen('');
    return true;
  };
  function handleChange<T>(path: string, value: T): void {
    setInstallation({
      ...installation,
      [path]: value,
    });

    // installation?.setAttr(path, value);
  }

  function changeImg(e: React.MouseEvent<HTMLImageElement, MouseEvent>, index: number) {
    e.stopPropagation();

    const img = e.currentTarget;

    setImagen(img.src);
    setindicearray(index);
  }
  function changedatapDF(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
    e.stopPropagation();

    const img = e.currentTarget;
    setImagen(`${img.dataset.r}#zoom=80`);
    setindicearray(index);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleCheckBox<T>(path: string, value: string, id: string, checked: boolean): Promise<unknown> {
    let NewValue: string;
    if (checked) {
      NewValue = 'si';
    } else {
      NewValue = 'no';
    }
    await setInstallation(
      new ActualizarTarifa().deserialize({
        ...installation,
        [path]: NewValue,
      }),
    );

    return true;
  }

  // Effects
  const validateFormInscritos = (): boolean => {
    setSubmitDisabled(false);
    setErrors({});
    return true;
  };
  const submitReportChanges = async (InscritosUpdate: SolicitudActualizarDTO): Promise<boolean> => {
    try {
      await UpdateTarifaServiceInstance.patchSolicitudActualizacion(tarifaID, InscritosUpdate);
      customToast.success(I18n.t('UpdateTarifaPage.toastCorrectoGuardada'));
    } catch (error) {
      toast.error(I18n.t('Error updating report!'));
    }
    return true;
  };
  const submitReportChangesValidar = async (): Promise<boolean> => {
    try {
      await UpdateTarifaServiceInstance.validarSolicitud(tarifaID);
      // enviar al dashboard
      history.push('/tecnic/tarifas');
      customToast.success(I18n.t('Inscrito validado'));
      setShow(false);
    } catch (error: any) {
      switch (error.response.status) {
        case 400:
          toast.error(I18n.t('YaValidado'));
          break;
        case 401:
          toast.error(I18n.t('Not authorized'));
          break;
        case 403:
          toast.error(I18n.t('Without access privileges'));
          break;
        case 404:
          toast.error(I18n.t('Inscrit not found on system'));
          break;

        default:
          break;
      }
      setShow(false);
    }
    return true;
  };

  const submitFormForInscritos = async (): Promise<boolean> => {
    setLoading(true);
    setSubmitDisabled(true);
    submitReportChanges(installation as ActualizarTarifa);
    setLoading(false);
    setSubmitDisabled(false);
    return true;
  };
  const submitFormForInscritosValidar = async (): Promise<boolean> => {
    setLoading(true);
    setSubmitDisabled(true);
    submitReportChangesValidar();
    setLoading(false);
    setSubmitDisabled(false);
    return true;
  };

  const handleClick = () => {
    if (validateFormInscritos()) {
      LoggingService.log('handleSubmit :: ok', { installation });
      submitFormForInscritos();
    } else {
      LoggingService.log('handleSubmit :: errors', { installation, errors });
    }
  };
  const handleClickValidar = () => {
    if (validateFormInscritos()) {
      LoggingService.log('handleSubmit :: ok', { installation });
      submitFormForInscritosValidar();
    } else {
      LoggingService.log('handleSubmit :: errors', { installation, errors });
    }
  };

  const handleShow = () => setShow(true);

  const deleteImage = async (imagedel: any): Promise<void> => {
    // eslint-disable-next-line no-alert
    const accept = window.confirm(I18n.t('Are you sure you want to delete the document?'));
    if (!accept) {
      return;
    }

    try {
      await UpdateTarifaServiceInstance.deleteImage(tarifaID, imagedel.id);

      customToast.success(`${I18n.t('Inscrito deleted successfully')}!`);

      setSubmitDisabled(false);
      setActualizar(true);
      setindicearray(0);
      setImagen(undefined);
    } catch (error) {
      toast.error(`${I18n.t('Error deleting image')}.`);
      setSubmitDisabled(false);

      setActualizar(true);
      setindicearray(0);
      setImagen(undefined);
    }
  };
  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (validateForm()) {
      LoggingService.log('handleSubmit :: ok', { file });
      submitForm();
    } else {
      LoggingService.log('handleSubmit :: errors', { file, errors });
    }
  };

  const settingFile = (file1: File): void => {
    if (file1) {
      setFile(file1);

      setnameImagen(file1.name);
      setIsButtonDisabled(false);
    }
  };

  const formFieldsLeft: Array<FormGroupProps> = [
    {
      label: I18n.t('InscritosDetalle.nombre'),

      type: 'string',
      id: 'nombre',
      path: 'nombre',
      name: 'nombre',
      value: installation?.nombre,
      placeholder: '',
      readOnly: true,

      //   // readOnly: (installation?.validado === 'si') === true,
    },
    {
      label: I18n.t('InscritosDetalle.Dni'),
      type: 'string',
      id: 'nif',
      path: 'nif',
      name: 'nif',
      placeholder: '',
      value: installation?.nif,
      readOnly: true,

      //   // readOnly: (installation?.validado === 'si') === true,
    },
    {
      label: I18n.t('UpdateTarifaPage.cups'),
      type: 'string',
      id: 'cups',
      path: 'cups',
      name: 'cups',
      placeholder: '',
      value: installation?.cups,

      //   // readOnly: (installation?.validado === 'si') === true,
    },
  ];

  const formFieldsRight: Array<FormGroupProps> = [
    {
      label: I18n.t('InscritosDetalle.apellidos'),

      type: 'string',
      id: 'apellidos',
      path: 'apellidos',
      name: 'apellidos',
      value: installation?.apellidos,
      placeholder: '',
      readOnly: true,

      // readOnly: (installation?.validado === 'si') === true,
    },
    {
      label: I18n.t('TarifasListPage.headers.fecha'),

      type: 'string',
      id: 'fecha',
      path: 'fecha',
      name: 'fecha',
      value: installation?.fechaAltaSolicitud,
      readOnly: true,
      // readOnly: (installation?.validado === 'si') === true,
    },
    {
      label: I18n.t('TarifasListPage.headers.comunidad'),

      type: 'string',
      id: 'nombreComunidad',
      path: 'nombreComunidad',
      name: 'nombreComunidad',
      value:
        installation?.nombreComunidad !== ''
          ? installation?.nombreComunidad
          : I18n.t('TarifasListPage.headers.errorComunidad'),
      readOnly: true,
      // readOnly: (installation?.validado === 'si') === true,
    },
  ];

  const arrayForcheckox: Array<FormGroupProps> = [
    {
      label: I18n.t('UpdateTarifaPage.tipoSolicitante'),
      type: FORMGROUP_TYPES.select,
      id: 'tipoSolicitante',
      path: 'tipoSolicitante',
      name: 'tipoSolicitante',
      placeholder: 'wwww',
      value: installation?.tipoSolicitante,
      options: [
        { label: 'UpdateTarifaPage.domestico', value: 'domestico' },
        { label: 'UpdateTarifaPage.industrial', value: 'industrial' },
      ],
    },
  ];

  const formFieldsRightMargeMensual: Array<FormGroupProps> = [
    {
      label: I18n.t('UpdateTarifaPage.margeMensual'),
      type: 'number',
      placeholder: '0',
      id: 'margenMensual',
      path: 'margenMensual',
      name: 'margenMensual',
      value: installation?.margenMensual,
    },
  ];

  const formFieldsRightMargeKw: Array<FormGroupProps> = [
    {
      label: I18n.t('UpdateTarifaPage.margeKw'),
      type: 'number',
      placeholder: '0',
      id: 'margenKWH',
      path: 'margenKWH',
      name: 'margenKWH',
      value: installation?.margenKWH,
    },
  ];

  const tarifaDefault: Array<FormGroupProps> = [
    {
      label: 'P 1',
      type: 'betas',
      placeholder: '0',
      id: 'Tarifa1',
      path: 'terminoDeEnergiaP1',
      name: 'Tarifa1',
      value: installation?.terminoDeEnergiaP1,
      // readOnly: (installation?.validado === 'si') === true,
    },

    {
      label: 'P 2',
      type: 'betas',
      placeholder: '0',
      id: 'Tarifa2',
      path: 'terminoDeEnergiaP2',
      name: 'Tarifa2',
      value: installation?.terminoDeEnergiaP2,
      // readOnly: (installation?.validado === 'si') === true,
    },
    {
      label: 'P 3',
      type: 'betas',
      placeholder: '0',
      id: 'Tarifa3',
      path: 'terminoDeEnergiaP3',
      name: 'Tarifa3',
      value: installation?.terminoDeEnergiaP3,
      // readOnly: (installation?.validado === 'si') === true,
    },
    {
      label: 'P 4',
      type: 'betas',
      placeholder: '0',
      id: 'Tarifa4',
      path: 'terminoDeEnergiaP4',
      name: 'Tarifa4',
      value: installation?.terminoDeEnergiaP4,
      // readOnly: (installation?.validado === 'si') === true,
    },
    {
      label: 'P 5',
      type: 'betas',
      placeholder: '0',
      id: 'Tarifa5',
      path: 'terminoDeEnergiaP5',
      name: 'Tarifa5',
      value: installation?.terminoDeEnergiaP5,
      // readOnly: (installation?.validado === 'si') === true,
    },
    {
      label: 'P 6',
      type: 'betas',
      placeholder: '0',
      id: 'Tarifa6',
      path: 'terminoDeEnergiaP6',
      name: 'Tarifa6',
      value: installation?.terminoDeEnergiaP6,
      // readOnly: (installation?.validado === 'si') === true,
    },
  ];

  const formprecioCompensacion: Array<FormGroupProps> = [
    {
      label: I18n.t('InscritosDetalle.precioCompensacion'),
      type: 'number',
      placeholder: '0',
      id: 'precioCompensacion',
      path: 'precioCompensacion',
      name: 'precioCompensacion',
      value: installation?.precioCompensacion,
      // readOnly: (installation?.validado === 'si') === true,
    },
  ];
  const formFieldsRightFacturaCheckbox: Array<FormGroupProps> = [
    {
      label: 'Indexada',
      type: 'checkbox',
      id: 'indexada',
      path: 'indexada',
      name: 'indexada',
      checked: installation?.indexada === 'si',
      value: installation?.indexada === 'si',
      // readOnly: (installation?.validado === 'si') === true,
    },
  ];
  const headings: Array<TableHeading> = [
    { label: I18n.t('Documentos'), type: 'image', key: 'url' },

    {
      label: I18n.t('Actions'),
      type: 'actions',
      key: '',
      actions: [
        {
          label: I18n.t('Delete'),
          icon: <TrashIcon height={25} width={25} fill={Colors.COLOR_ELECSUMGREEN} />,
          onActionClick: deleteImage,
        },
      ],
    },
  ];

  return (
    <div className={styles.root}>
      {loadingData ? (
        <FullPageSyncLoader icon />
      ) : (
        <>
          <form className={styles.form}>
            <div className={styles.posotionradio}>
              <div className={styles.postflex}>
                <SectionTitle text={I18n.t('TarifasListPage.title')} />
              </div>
              <div className={styles.positionValidar}>
                <div className={styles.postflexbutton}>
                  <LoadingButton type="button" text={I18n.t('Validar')} onClick={handleShow} />
                </div>
                {/* )} */}
              </div>
            </div>

            <div className={styles.flex}>
              <SectionTitle text={I18n.t('TarifasListPage.datosSolicitud')} />
            </div>

            <div className={styles.containerForms}>
              <div className={styles.containerForm}>
                {formFieldsLeft.map((formGroup: FormGroupProps) => (
                  <FormGroup
                    key={formGroup.id}
                    label={formGroup.label}
                    type={formGroup.type}
                    id={formGroup.id}
                    path={formGroup.path}
                    name={formGroup.name}
                    placeholder={formGroup.placeholder}
                    value={formGroup.value}
                    required={formGroup.required}
                    readOnly={formGroup.readOnly}
                    className={formGroup.className}
                    onChange={handleChange}
                    maxLenght={200}
                  />
                ))}
              </div>

              <div className={styles.containerForm}>
                {formFieldsRight.map((formGroup: FormGroupProps) => (
                  <FormGroup
                    key={formGroup.id}
                    label={formGroup.label}
                    type={formGroup.type}
                    id={formGroup.id}
                    path={formGroup.path}
                    name={formGroup.name}
                    placeholder={formGroup.placeholder}
                    value={formGroup.value}
                    required={formGroup.required}
                    readOnly={formGroup.readOnly}
                    className={formGroup.className}
                    onChange={handleChange}
                    maxLenght={200}
                  />
                ))}
              </div>
            </div>

            <div className={styles.linea} />

            <div className={styles.flex} style={{ padding: '15px 0px  0px  0px' }}>
              <SectionTitle text={I18n.t('TarifasListPage.datosTarifa')} className={styles.SectionPotencias} />
            </div>
            <div className={styles.containerFormsTest}>
              <div className={styles.containerFormsTest1}>
                <div className={styles.containerFormsTest3}>
                  {arrayForcheckox.map((formGroup: FormGroupProps) => (
                    <FormGroup
                      key={formGroup.id}
                      label={formGroup.label}
                      type={formGroup.type}
                      id={formGroup.id}
                      path={formGroup.path}
                      name={formGroup.name}
                      placeholder={formGroup.placeholder}
                      value={formGroup.value}
                      required={formGroup.required}
                      onChange={handleChange}
                      options={formGroup.options}
                    />
                  ))}
                  {formFieldsRightFacturaCheckbox.map((formGroup: FormGroupProps) => (
                    <FormGroup
                      key={formGroup.id}
                      label={formGroup.label}
                      type={formGroup.type}
                      id={formGroup.id}
                      path={formGroup.path}
                      name={formGroup.name}
                      placeholder={formGroup.placeholder}
                      value={formGroup.value}
                      required={formGroup.required}
                      readOnly={formGroup.readOnly}
                      className={formGroup.className}
                      checked={formGroup.checked}
                      onChange={handleCheckBox}
                      maxLenght={200}
                    />
                  ))}
                </div>

                {installation?.indexada === 'si' ? (
                  <div>
                    <SectionTitle text={I18n.t('UpdateTarifaPage.margenesTecnico')} className={styles.SectionTarfa} />
                    <div className={styles.containerMarges}>
                      {formFieldsRightMargeMensual.map((formGroup: FormGroupProps) => (
                        <FormGroup
                          key={formGroup.id}
                          label={formGroup.label}
                          type={formGroup.type}
                          id={formGroup.id}
                          path={formGroup.path}
                          name={formGroup.name}
                          placeholder={formGroup.placeholder}
                          value={formGroup.value}
                          required={formGroup.required}
                          readOnly={formGroup.readOnly}
                          className={formGroup.className}
                          onChange={handleChange}
                          maxLenght={200}
                        />
                      ))}
                      <p className={styles.containerO}>o</p>
                      {formFieldsRightMargeKw.map((formGroup: FormGroupProps) => (
                        <FormGroup
                          key={formGroup.id}
                          label={formGroup.label}
                          type={formGroup.type}
                          id={formGroup.id}
                          path={formGroup.path}
                          name={formGroup.name}
                          placeholder={formGroup.placeholder}
                          value={formGroup.value}
                          required={formGroup.required}
                          readOnly={formGroup.readOnly}
                          className={formGroup.className}
                          onChange={handleChange}
                          maxLenght={200}
                        />
                      ))}
                    </div>
                  </div>
                ) : null}
                <div>
                  {installation?.indexada === 'no' ? (
                    <SectionTitle text={I18n.t('Tarifa')} className={styles.SectionTarfa} />
                  ) : null}
                  <div className={styles.containerPotencia}>
                    {installation?.indexada === 'no'
                      ? tarifaDefault
                          .slice(0, installation?.tipoSolicitante === 'industrial' ? 6 : 3)
                          .map((formGroup: FormGroupProps) => (
                            <FormGroup
                              key={formGroup.id}
                              label={formGroup.label}
                              type={formGroup.type}
                              id={formGroup.id}
                              path={formGroup.path}
                              name={formGroup.name}
                              placeholder={formGroup.placeholder}
                              value={formGroup.value}
                              required={formGroup.required}
                              readOnly={formGroup.readOnly}
                              className={formGroup.className}
                              onChange={handleChange}
                              maxLenght={200}
                            />
                          ))
                      : null}
                  </div>
                </div>
                {installation?.indexada === 'no' ? (
                  <div className={styles.containerPotenciapRECIO}>
                    {formprecioCompensacion.map((formGroup: FormGroupProps) => (
                      <FormGroup
                        key={formGroup.id}
                        label={formGroup.label}
                        type={formGroup.type}
                        id={formGroup.id}
                        path={formGroup.path}
                        name={formGroup.name}
                        placeholder={formGroup.placeholder}
                        value={formGroup.value}
                        required={formGroup.required}
                        readOnly={formGroup.readOnly}
                        className={formGroup.className}
                        onChange={handleChange}
                        maxLenght={200}
                      />
                    ))}
                  </div>
                ) : null}
              </div>
              <div className={styles.containerFormsTest2}>
                {installation?.documentos?.length ? (
                  <div>
                    <div className={styles.styleDivgeneral}>
                      {isImage(imagenDettale) === true ||
                      isImage(installation?.documentos[indicearray].url) === true ? (
                        <img
                          className={styles.imagenMosttrar}
                          role="presentation"
                          src={imagenDettale === undefined ? installation?.documentos[indicearray].url : imagenDettale}
                          alt={imagenDettale}
                        />
                      ) : (
                        <object
                          className={styles.imagenMosttrar}
                          data={
                            imagenDettale === undefined
                              ? `${installation?.documentos[indicearray]?.url}#zoom=80`
                              : `${imagenDettale}#zoom=80`
                          }
                          type="application/pdf"
                          aria-labelledby="pdf"
                          width="100%"
                          height="500"
                        />
                      )}
                    </div>
                    <div className={styles.test}>
                      {installation?.documentos.map((docu, index) =>
                        isImage(docu.url) === true ? (
                          <img
                            role="presentation"
                            src={docu.url}
                            alt="Imagen3"
                            height={100}
                            className={styles.imagenThumnail}
                            onClick={(e) => changeImg(e, index)}
                          />
                        ) : (
                          <button
                            className={styles.imagenThumnail}
                            type="button"
                            data-r={docu.url}
                            onClick={(e) => changedatapDF(e, index)}
                          >
                            {' '}
                            {docu.nombre}
                          </button>
                        ),
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={styles.ResultadosDocumentos}>
                    <p> {I18n.t('Noinscritos')}</p>
                  </div>
                )}
              </div>
            </div>
          </form>
          <div className={styles.linea} />

          <div className={styles.containerFormsTest}>
            <div className={styles.direccioncolum}>
              <SectionTitle text={I18n.t('Archivo adjunto')} />
              <form className={styles.form} onSubmit={onSubmit}>
                <div className={styles.containerFormsTestHeight}>
                  <div className={styles.containerForm}>
                    <div className="col-12">
                      <div className={styles.imagenDatatable}>
                        {installation?.documentos && installation?.documentos.length > 0 ? (
                          <DataTable headings={headings} rows={installation?.documentos} loading={loading} />
                        ) : null}
                      </div>
                    </div>
                    <div>
                      <div className={styles.imagenButton}>
                        {actualizar || (
                          <EunoiaImageInput
                            id="img-galeria"
                            label={I18n.t('add all')}
                            note={I18n.t('Peso Documento')}
                            fileName=""
                            icon={<LandscapeIcon height={20} width={20} fill={Colors.COLOR_GRAY_400} />}
                            type="all"
                            action={settingFile}
                          />
                        )}

                        <div className={styles.actions}>
                          <LoadingButton
                            type="submit"
                            text={I18n.t('add docu')}
                            disabled={isButtonDisabled || submitDisabled}
                            loading={loading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            {show ? (
              <Modal
                title={I18n.t('mensajeModalTarifa') + installation?.nombre + I18n.t('spacio') + installation?.apellidos}
                body={I18n.t('mensajeModalBodyTarifa')}
                onClick={handleClickValidar}
                testbutton={I18n.t('Confirmar')}
                onClick2={() => setShow(false)}
              />
            ) : null}
          </div>
          <div className={styles.actions}>
            {communityID === undefined ? (
              <Button
                type="button"
                onClick={(): Promise<void> => navigate('/tecnic/tarifas')}
                variant="secondary"
                text={I18n.t('Cancel')}
              />
            ) : (
              <Button
                type="button"
                onClick={(): Promise<void> => navigate(`/tecnic/tarifas/${communityID}/tarifas`)}
                variant="secondary"
                text={I18n.t('Cancel')}
              />
            )}

            <LoadingButton
              type="submit"
              text={I18n.t('Save')}
              disabled={submitDisabled}
              loading={loading}
              onClick={() => handleClick()}
            />
          </div>
        </>
      )}
    </div>
  );
};
