import React, { ChangeEvent } from 'react';

import cx from 'classnames';
import styles from './SliderInput.module.scss';

export interface SliderInputInterface {
  path: string;
  value: number;
  type: string;
  id: string;
  name: string;
  max: number;
  onChange?: CallableFunction;
  className?: string;
  required?: boolean;
  min?: number;
  step?: number;
}

const ACTIVE = '#98c832'; // HOW TO USE SCSS VARIABLES HERE?
const INACTIVE = '#d8d8d8';

export default class SliderInput extends React.PureComponent<SliderInputInterface> {
  constructor(props: SliderInputInterface) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e: ChangeEvent<HTMLInputElement>): void {
    const { path, onChange } = this.props;

    if (onChange) {
      onChange(path, e.target.value);
    }
  }

  render(): JSX.Element {
    const { value, type, id, min, max, step, required, name, className = '' } = this.props;
    const progress: number = (value / max) * 100;

    const styleInput = {
      background: `linear-gradient(90deg, ${ACTIVE} 0% ${progress}%, ${INACTIVE} ${progress}% 100%)`,
    };

    return (
      <input
        id={id}
        type={type}
        min={min}
        max={max}
        step={step}
        className={cx(styles.root, className)}
        value={value}
        onChange={this.handleChange}
        required={required}
        name={name}
        style={styleInput}
      />
    );
  }
}
