import React, { useRef } from 'react';
import { ReactComponent as ArrowLeft } from 'assets/icons/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/ArrowRight.svg';
import { I18n } from 'core';
import Card from 'components/Card/Card';

import useSlider from './hooks/useSlider';

import styles from './SliderHorizontal.module.scss';

const Concepto = ({ etiqueta, valor }: { etiqueta: string; valor: string }): JSX.Element => (
  <div className={styles.concepto}>
    <span className={styles.conceptoInfo}>{etiqueta}</span>
    <span className={styles.conceptoValor}>{valor}</span>
  </div>
);

const Slider = ({ images, Titulo }: any) => {
  const slideImage = useRef(null);

  const { goToPreviousSlide, goToNextSlide } = useSlider(slideImage, images);

  return (
    <Card
      className={styles.left_widget}
      title={I18n.t('Tarifes')}
      body={[
        <div>
          <Concepto etiqueta={I18n.t('ContractResumCard.tarifa')} valor={`${Titulo} `} />
          <div className={styles.slider} ref={slideImage}>
            <div className={styles.sliderContent}>
              <button type="button" onClick={goToPreviousSlide} className={styles.estyloButtonLEFT}>
                <ArrowLeft />
              </button>
              <div className={styles.sliderFeature} />
              <button type="button" onClick={goToNextSlide} className={styles.estyloButtonLEFT}>
                <i className="fas fa-angle-right" />
                <ArrowRight />
              </button>
            </div>
          </div>
        </div>,
      ]}
    />
  );
};

export default Slider;
