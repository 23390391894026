import { SectionTitle } from 'components';
import React, { useEffect, useState } from 'react';
import { FormGroup, FormGroupProps, I18n } from 'core';

import { CommunityAPII, CommunityModel } from 'types/models/community';
import { Colors } from 'styles';
import { ReactComponent as DocumentsIcon } from 'assets/icons/Documents.svg';
import { SelectItemI } from 'components/NewInputs/Select/ElecsumSelect';
import { LoggingService } from 'services';
import CommunitiesServiceIntance from 'services/remote/communities-service';
import customToast from 'components/CustomToast/CustomToast';
import { toast } from 'react-toastify';
import { DayPicker } from 'components/Input';
import './comunidadPicker.scss';
// import Button from 'components/Button/Button';
// import { navigate } from 'navigation';
import { FORMGROUP_TYPES } from 'core/form/FormGroup/FormGroup';

import styles from './comunidades-detalle-details-form.module.scss';

interface ComunidadesDetalleDetailsFormPageProps {
  selectedCommunity: CommunityModel;
  currentLang: SelectItemI;
  afterSubmitAction?: CallableFunction;
  clicked: boolean;
}

const ComunidadesDetalleDetailsFormPage = (props: ComunidadesDetalleDetailsFormPageProps): JSX.Element => {
  const { selectedCommunity, currentLang, afterSubmitAction, clicked } = props;
  const [client, setClient] = useState<CommunityModel>();
  const comunidadPost = JSON.parse(JSON.stringify(selectedCommunity));
  // const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  // const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const [ModificacionPromocion, setModificacionPromocion] = useState<boolean>(false);
  const [ModificacionTipologia, setModificacionTipologia] = useState<boolean>(false);
  const [ModificacionDescripcion, setModificacionDescripcion] = useState<boolean>(false);
  const [Descripcion, setDescripcion] = useState<string>(selectedCommunity?.descripcion.translations?.es as string);
  const [DescripcionCA, setDescripcionCA] = useState<string>(selectedCommunity?.descripcion.translations?.ca as string);
  const [Promocion, setPromocion] = useState<string>(selectedCommunity?.promocion.translations?.es as string);
  const [PromocionCa, setPromocionCa] = useState<string>(selectedCommunity?.promocion.translations?.ca as string);
  const [Tipologia, setTipologia] = useState<string>(selectedCommunity?.tipologia.translations?.es as string);
  const [TipologiaCa, setTipologiaCa] = useState<string>(selectedCommunity?.tipologia.translations?.ca as string);

  const [impactoClimatico, setimpactoClimatico] = useState<string>(
    selectedCommunity?.impactoClimatico.translations?.es as string,
  );
  const [impactoClimaticoCa, setimpactoClimaticoCa] = useState<string>(
    selectedCommunity?.impactoClimatico.translations?.ca as string,
  );
  const [estadoInicial] = useState<string>(selectedCommunity?.estado);
  // Define max steps as a state to update dynamically
  const [maxSteps, setMaxSteps] = useState(selectedCommunity.pasosOnboarding);
  useEffect(() => {
    // Actualiza el valor máximo de pasos en función del tipo de comunidad
    const maxStepsValue = selectedCommunity.tipoDeComunidad === 'otc' ? 5 : 4;
    setMaxSteps(maxStepsValue);
  }, [selectedCommunity.tipoDeComunidad]);
  function handleChange<T>(path: string, value: T): void {
    let x = {
      es: '',
      ca: '',
    };
    if (path === 'descripcion' || path === 'promocion' || path === 'tipologia' || path === 'impactoClimatico') {
      if (path === 'descripcion') {
        if (currentLang.value === 'Es') {
          x = {
            es: value as unknown as string,
            ca: DescripcionCA,
          };
        } else {
          x = {
            es: Descripcion,
            ca: value as unknown as string,
          };
        }
      }
      if (path === 'promocion') {
        if (currentLang.value === 'Es') {
          x = {
            es: value as unknown as string,
            ca: PromocionCa,
          };
        } else {
          x = {
            es: Promocion,
            ca: value as unknown as string,
          };
        }
      }
      if (path === 'tipologia') {
        if (currentLang.value === 'Es') {
          x = {
            es: value as unknown as string,
            ca: TipologiaCa,
          };
        } else {
          x = {
            es: Tipologia,
            ca: value as unknown as string,
          };
        }
      }

      if (path === 'impactoClimatico') {
        if (currentLang.value === 'Es') {
          x = {
            es: value as unknown as string,
            ca: impactoClimaticoCa,
          };
        } else {
          x = {
            es: impactoClimatico,
            ca: value as unknown as string,
          };
        }
      }

      selectedCommunity.setAttr(path, x);
      setClient(
        new CommunityModel().deserialize({
          ...comunidadPost,
          [path]: x,
        }),
      );
    } else {
      selectedCommunity.setAttr(path, value);
      setClient(
        new CommunityModel().deserialize({
          ...comunidadPost,
          [path]: value,
        }),
      );
    }
    if (path === 'promocion') {
      setModificacionPromocion(true);

      if (currentLang.value === 'Es') {
        setPromocion(x.es);
      } else {
        setPromocionCa(x.ca);
      }
    }
    if (path === 'tipologia') {
      setModificacionTipologia(true);
      if (currentLang.value === 'Es') {
        setTipologia(x.es);
      } else {
        setTipologiaCa(x.ca);
      }
    }
    if (path === 'descripcion') {
      setModificacionDescripcion(true);
      if (currentLang.value === 'Es') {
        setDescripcion(x.es);
      } else {
        setDescripcionCA(x.ca);
      }
    }
    if (path === 'impactoClimatico') {
      setModificacionDescripcion(true);
      if (currentLang.value === 'Es') {
        setimpactoClimatico(x.es);
      } else {
        setimpactoClimaticoCa(x.ca);
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleCheckBox<T>(path: string, value: string, id: string, checked: boolean): Promise<unknown> {
    let NewValue: string;
    if (checked) {
      NewValue = 'completa';
    } else {
      NewValue = estadoInicial;
    }
    selectedCommunity.setAttr(path, NewValue);

    await setClient(
      new CommunityModel().deserialize({
        ...comunidadPost,
        [path]: NewValue,
      }),
    );
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleCheckBoxCheck<T>(path: string, value: string, id: string, checked: boolean): Promise<unknown> {
    let NewValue: string;
    if (checked) {
      NewValue = 'si';
    } else {
      NewValue = 'no';
    }

    selectedCommunity.setAttr(path, NewValue);

    await setClient(
      new CommunityModel().deserialize({
        ...comunidadPost,
        [path]: NewValue,
      }),
    );

    return true;
  }

  const getInitalDate = (): Date => {
    const currentDate = new Date(selectedCommunity?.fechaFinInscripciones || new Date());
    const yesterday = new Date(currentDate);
    yesterday.setDate(yesterday.getDate());

    return yesterday;
  };
  const [date, setDate] = useState({
    day: getInitalDate().getDate(),
    month: getInitalDate().getMonth() + 1,
    year: getInitalDate().getFullYear(),
  });
  const updateDate = (newDate: Date): void => {
    if (!newDate) {
      return;
    }
    setDate({
      day: newDate.getDate(),
      month: newDate.getMonth() + 1,
      year: newDate.getFullYear(),
    });
  };
  // Effects
  const validateForm = (): boolean => {
    // setSubmitDisabled(false);
    setErrors({});
    return true;
  };
  const submitReportChanges = async (CommunityModelUpdate: CommunityAPII): Promise<boolean> => {
    const object = CommunityModelUpdate;

    if (ModificacionPromocion === false) {
      object.promocion = comunidadPost.promocion.translations.translations;
    }
    if (ModificacionTipologia === false) {
      object.tipologia = comunidadPost.tipologia.translations.translations;
    }
    if (ModificacionDescripcion === false) {
      object.descripcion = comunidadPost.descripcion.translations.translations;
    }
    if (ModificacionDescripcion === false) {
      object.impactoClimatico = comunidadPost.impactoClimatico.translations.translations;
    }
    if (object.generacionInstalable === '') {
      object.generacionInstalable = '0';
    }
    if (object.potenciaInstalable === '') {
      object.potenciaInstalable = '0';
    }
    object.fechaFinInscripciones = `${date.year}-${date.month}-${date.day}`;
    try {
      // eslint-disable-next-line no-console
      await CommunitiesServiceIntance.putCommunities(JSON.parse(JSON.stringify(object)));
      customToast.success(I18n.t('Comunidad successfully updated'));
    } catch (error) {
      toast.error(I18n.t('Error updating report!'));
    }
    return true;
  };
  const submitForm = async (): Promise<boolean> => {
    // setLoading(true);
    // setSubmitDisabled(true);
    submitReportChanges(comunidadPost);
    // setLoading(false);
    // setSubmitDisabled(false);
    return true;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (validateForm()) {
      LoggingService.log('handleSubmit :: ok', { client });
      submitForm();
    } else {
      LoggingService.log('handleSubmit :: errors', { client, errors });
    }
  };
  useEffect(() => {
    if (clicked === true) {
      if (validateForm()) {
        LoggingService.log('handleSubmit :: ok', { client });
        submitForm();
        if (afterSubmitAction) {
          afterSubmitAction();
        }
      } else {
        LoggingService.log('handleSubmit :: errors', { client, errors });
      }
    }
  }, [clicked]);
  const formFieldsLeft: Array<FormGroupProps> = [
    {
      label: I18n.t('ComunidadesDetallePage.detailData.labels.name'),
      type: 'string',
      id: 'nombre',
      path: 'nombre',
      name: 'nombre',
      placeholder: '',
      value: selectedCommunity?.nombre,
    },
    {
      label: I18n.t('ComunidadesDetallePage.detailData.labels.domicilio'),
      type: 'string',
      id: 'domicilio',
      path: 'domicilio',
      name: 'domicilio',
      placeholder: '',
      value: selectedCommunity.domicilio,
    },
    {
      label: I18n.t('ComunidadesDetallePage.detailData.labels.poblacion'),
      type: 'string',
      id: 'poblacion',
      path: 'poblacion',
      name: 'poblacion',
      placeholder: '',
      value: selectedCommunity.poblacion,
    },
    {
      label: I18n.t('ComunidadesDetallePage.detailData.labels.codigoPostal'),
      type: 'string',
      id: 'codigoPostal',
      path: 'codigoPostal',
      name: 'codigoPostal',
      placeholder: '',
      value: selectedCommunity.codigoPostal,
    },
    {
      label: I18n.t('ComunidadesDetallePage.detailData.labels.provincia'),
      type: 'string',
      id: 'provincia',
      path: 'provincia',
      name: 'provincia',
      placeholder: '',
      value: selectedCommunity.provincia,
    },
  ];
  const formFieldsLeft2: Array<FormGroupProps> = [
    {
      label: I18n.t('ComunidadesDetallePage.detailData.labels.radioAlcance'),
      type: 'string',
      id: 'radioAlcance',
      path: 'radioAlcance',
      name: 'radioAlcance',
      min: 0,
      placeholder: '',
      value: selectedCommunity.radioAlcance,
    },
    {
      label: I18n.t('ComunidadesDetallePage.detailData.labels.descripcion'),
      type: 'textarea',
      id: 'descripcion',
      path: 'descripcion',
      name: 'descripcion',
      placeholder: '',
      // eslint-disable-next-line no-nested-ternary
      value: currentLang.value === 'Ca' ? (DescripcionCA === undefined ? '' : DescripcionCA) : Descripcion,
    },
    // {
    //   label: I18n.t('ComunidadesDetallePage.detailData.labels.tipologia'),
    //   type: 'string',
    //   id: 'tipologia',
    //   path: 'tipologia',
    //   name: 'tipologia',
    //   placeholder: '',
    //   value: currentLang.value === 'ca' ? TipologiaCa : Tipologia,
    // },
    // {
    //   label: I18n.t('ComunidadesDetallePage.detailData.labels.promocion'),
    //   type: 'string',
    //   id: 'promocion',
    //   path: 'promocion',
    //   name: 'promocion',
    //   placeholder: '',
    //   value: currentLang.value === 'ca' ? PromocionCa : Promocion,
    // },
  ];

  const formFieldsRight: Array<FormGroupProps> = [
    {
      label: I18n.t('ComunidadesDetallePage.detailData.labels.tipologia'),
      type: 'string',
      id: 'tipologia',
      path: 'tipologia',
      name: 'tipologia',
      placeholder: '',
      value: currentLang.value === 'Ca' ? TipologiaCa : Tipologia,
    },
    {
      label: I18n.t('ComunidadesDetallePage.detailData.labels.promocion'),
      type: 'string',
      id: 'promocion',
      path: 'promocion',
      name: 'promocion',
      placeholder: '',
      // eslint-disable-next-line no-nested-ternary
      value: currentLang.value === 'Ca' ? (PromocionCa === undefined ? '' : PromocionCa) : Promocion,
    },
    {
      label: I18n.t('ComunidadesDetallePage.detailData.labels.generacion'),
      type: 'string',
      id: 'generacionInstalable',
      path: 'generacionInstalable',
      name: 'generacionInstalable',
      value: selectedCommunity?.generacionInstalable === '0' ? '' : selectedCommunity?.generacionInstalable,
      placeholder: '',
      readOnly: false,
    },
    {
      label: I18n.t('ComunidadesDetallePage.detailData.labels.potenciainstalable'),
      type: 'string',
      id: 'potenciaInstalable',
      path: 'potenciaInstalable',
      name: 'potenciaInstalable',
      value: selectedCommunity?.potenciaInstalable === '0' ? '' : selectedCommunity?.potenciaInstalable,
      placeholder: '',
      readOnly: false,
    },
    {
      label: I18n.t('ComunidadesDetallePage.detailData.labels.co2'),
      type: 'string',
      id: 'impactoClimatico',
      path: 'impactoClimatico',
      name: 'impactoClimatico',
      placeholder: '',
      readOnly: false,
      value:
        // eslint-disable-next-line no-nested-ternary
        currentLang.value === 'Ca' ? (impactoClimaticoCa === undefined ? '' : impactoClimaticoCa) : impactoClimatico,
    },
    // {
    //   label: I18n.t('ComunidadesDetallePage.detailData.labels.arbres'),
    //   type: 'string',
    //   id: 'generacion',
    //   path: 'generacion',
    //   name: 'generacion',
    //   value: '',
    //   placeholder: '',
    //   readOnly: true,
    //   className: styles.field,
    // },
    // {
    //   label: I18n.t('ComunidadesDetallePage.detailData.labels.members'),
    //   type: 'string',
    //   id: 'members',
    //   path: 'members',
    //   name: 'members',
    //   value: '',
    //   placeholder: '',
    //   readOnly: true,
    //   className: styles.field,
    // },,
    {
      label: I18n.t('ComunidadesDetallePage.detailData.labels.titularPoliticaPrivacidad'),
      type: 'string',
      id: 'titularPoliticaPrivacidad',
      path: 'titularPoliticaPrivacidad',
      name: 'titularPoliticaPrivacidad',
      placeholder: '',
      value: selectedCommunity.titularPoliticaPrivacidad,
    },
    {
      label: I18n.t('ComunidadesDetallePage.detailData.labels.limitacionNumeroInscripciones'),
      type: FORMGROUP_TYPES.number,
      id: 'limitacionNumeroInscripciones',
      path: 'limitacionNumeroInscripciones',
      name: 'limitacionNumeroInscripciones',
      placeholder: '',
      value: selectedCommunity.limitacionNumeroInscripciones,
    },
  ];
  const formcheckbosleft: Array<FormGroupProps> = [
    {
      label: I18n.t('ComunidadesListPage.estado.titulo'),

      type: FORMGROUP_TYPES.select,
      id: 'estado',
      path: 'estado',
      name: 'estado',
      placeholder: 'wwww',
      value: selectedCommunity?.estado,

      options: [
        { label: 'abierta', value: 'abierta' },
        { label: 'completa', value: 'completa' },
        { label: 'oculta', value: 'oculta' },
        { label: 'cerrada', value: 'cerrada' },
      ],
    },
  ];
  const formSelectTipoComunidad: Array<FormGroupProps> = [
    {
      label: I18n.t('ComunidadesListPage.tipoDeComunidad.titulo'),

      type: FORMGROUP_TYPES.select,
      id: 'tipoDeComunidad',
      path: 'tipoDeComunidad',
      name: 'tipoDeComunidad',
      placeholder: 'wwww',
      value: selectedCommunity?.tipoDeComunidad,

      options: [
        { label: 'ComunidadesListPage.tipoDeComunidad.estandard', value: 'estandart' },
        { label: 'ComunidadesListPage.tipoDeComunidad.otc', value: 'otc' },
      ],
    },
  ];

  const formGroupsEnviarAutorizacionAPromotor: Array<FormGroupProps> = [
    {
      label: I18n.t('ComunidadesListPage.tipoDeComunidad.checkSignaturitPromotor'),
      type: 'checkbox',
      id: 'enviarAutorizacionAPromotor',
      path: 'enviarAutorizacionAPromotor',
      name: 'enviarAutorizacionAPromotor',
      checked: selectedCommunity.enviarAutorizacionAPromotor === 'si',
      value: selectedCommunity.enviarAutorizacionAPromotor === 'si',
    },
  ];

  const formFieldsLeftMaxSteps: Array<FormGroupProps> = [
    {
      label: I18n.t('ComunidadesDetallePage.detailData.labels.MaxStepsOnboarding'),
      type: 'number',
      id: 'pasosOnboarding',
      path: 'pasosOnboarding',
      name: 'pasosOnboarding',
      placeholder: '',
      value: selectedCommunity.pasosOnboarding,
      step: maxSteps,
    },
  ];
  return (
    <div className={styles.root}>
      <div className={styles.flex}>
        <DocumentsIcon height={20} width={20} fill={Colors.COLOR_BLACK} className={styles.DocumentsIcon} />
        <SectionTitle text={I18n.t('ComunidadesDetallePage.detailData.title')} />
      </div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.containerForms}>
          <div className={styles.containerForm}>
            {formFieldsLeft.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                placeholder={formGroup.placeholder}
                value={formGroup.value}
                required={formGroup.required}
                onChange={handleChange}
                readOnly={formGroup.readOnly}
                className={formGroup.className}
                maxLenght={200}
              />
            ))}
            <div>
              <label className={styles.labelDatepicker} htmlFor="fechaFinInscripciones" id="fechaFinInscripciones">
                {I18n.t('ComunidadesDetallePage.detailData.labels.date')}
              </label>
              <div className={styles.inputDatepicker}>
                <DayPicker date={new Date(`${date.month}/${date.day}/${date.year}`)} onChangeDate={updateDate} />
              </div>
            </div>
            {formFieldsLeft2.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                placeholder={formGroup.placeholder}
                value={formGroup.value}
                required={formGroup.required}
                onChange={handleChange}
                readOnly={formGroup.readOnly}
                className={formGroup.className}
              />
            ))}
          </div>
          <div className={styles.margenesLefst}>
            {formFieldsRight.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                placeholder={formGroup.placeholder}
                value={formGroup.value}
                required={formGroup.required}
                readOnly={formGroup.readOnly}
                className={formGroup.className}
                onChange={handleChange}
                maxLenght={200}
              />
            ))}

            {/* <p>{t(`ComunidadesListPage.Inscrits`)}</p>
            <p>{t(`ComunidadesListPage.Places`)}</p> */}

            {formcheckbosleft.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                placeholder={formGroup.placeholder}
                value={formGroup.value}
                required={formGroup.required}
                onChange={handleChange}
                options={formGroup.options}
                className={styles.removeMargin}
              />
            ))}

            {formSelectTipoComunidad.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                placeholder={formGroup.placeholder}
                value={formGroup.value}
                required={formGroup.required}
                onChange={handleChange}
                options={formGroup.options}
              />
            ))}

            {formFieldsLeftMaxSteps.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                placeholder={formGroup.placeholder}
                value={formGroup.value}
                required={formGroup.required}
                onChange={handleChange}
                options={formGroup.options}
                max={formGroup.step}
                min={1}
              />
            ))}

            {formGroupsEnviarAutorizacionAPromotor.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                value={formGroup.value}
                checked={formGroup.checked}
                onChange={handleCheckBoxCheck}
              />
            ))}
          </div>
        </div>

        {/* <div className={styles.positionBotonoes}>
          <div className={styles.divBoton}>
            <Button
              type="button"
              onClick={(): Promise<void> => navigate('/tecnic/communities')}
              variant="secondary"
              text={I18n.t('Cancel')}
            />
            <LoadingButton type="submit" text={I18n.t('Save')} disabled={submitDisabled} loading={loading} />
          </div>
        </div> */}
      </form>
    </div>
  );
};

export default ComunidadesDetalleDetailsFormPage;
