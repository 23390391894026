class FormatNumber {
  private numberToConvert: number | string;

  private format(number: number): string {
    return number
      .toFixed(2)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }

  private formatWithoutThousandSeparator(number: number | string): string {
    const num = typeof number === 'string' ? parseFloat(number) : number;

    return num.toString().replace('.', ',');
  }

  formatTwoDecimal(numberToConvert: number): string {
    this.numberToConvert = numberToConvert;

    return this.format(this.numberToConvert);
  }

  formatInteger(numberToConvert: string): string {
    return numberToConvert.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }

  formatTwoDecimalWithThousandSeparator(numberToConvert: string | number): string {
    this.numberToConvert = numberToConvert;
    return this.formatWithoutThousandSeparator(this.numberToConvert);
  }
}
const FormatNumberService: FormatNumber = new FormatNumber();

export default FormatNumberService;
