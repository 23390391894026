import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { I18n } from 'core';
import { SectionTitle, Spinner } from 'components';

import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { SolicitudesServiceRegistroPendiente } from 'services';
import SolicitudesPendientesRegistro from 'types/models/solicitudesPendiente';
import { CommunitiesEnrolledListTable } from './components/communities-enrolled-list-table/communities-enrolled-list-table';

// import { CommunitiesEnrolledListPaginator } from './components/communities-enrolled-list-paginator/communities-enrolled-list-paginator';

import styles from './comunities-enrolled-list-page.module.scss';

interface ParameterDetalles {
  communityID: string;
  userID: string;
}

function sortByFechaAltaDesc(array: SolicitudesPendientesRegistro[]): SolicitudesPendientesRegistro[] {
  return array.sort((a, b) => {
    const dateA = a.fechaAlta ? new Date(a.fechaAlta).getTime() : 0;
    const dateB = b.fechaAlta ? new Date(b.fechaAlta).getTime() : 0;
    return dateB - dateA;
  });
}
// Luego puedes usar esta función en tu código

export const ComunitiesSolicitudesPendienteListPage = (): JSX.Element => {
  const [communitiesEnrolled, setCommunitiesEnrolled] = useState<SolicitudesPendientesRegistro[]>([]);
  const { communityID } = useParams<ParameterDetalles>();
  const { userID } = useParams<ParameterDetalles>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    if (communityID !== undefined) {
      SolicitudesServiceRegistroPendiente.getSolicitudesByComunidad(communityID)
        .then((response) => {
          const data = sortByFechaAltaDesc(response);

          setCommunitiesEnrolled(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (userID !== undefined) {
      SolicitudesServiceRegistroPendiente.getSolicitudesByPromotor(userID)
        .then((response) => {
          const data = sortByFechaAltaDesc(response);

          setCommunitiesEnrolled(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <div className={communityID !== undefined ? styles.container : styles.container2}>
      {loading ? (
        <div className={styles.loading}>
          <Spinner size={100} icon />
        </div>
      ) : (
        <SectionWrapper>
          <SectionTitle text={I18n.t('ComunitiesSolicitudesPendienteListPage.title')} />

          <div className={styles.table_wrapper}>
            <CommunitiesEnrolledListTable data={communitiesEnrolled} communityID={communityID} />
          </div>
          <div>
            {/* <CommunitiesEnrolledListPaginator current={0} max={1} handleNext={() => {}} handlePrev={() => {}} /> */}
          </div>
        </SectionWrapper>
      )}
    </div>
  );
};
