/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';

import { I18n } from 'core';
import { Colors } from 'styles';

import { SectionTitle, Spinner } from 'components';
import { toast } from 'react-toastify';
import { invoiceService } from 'services';
import { InvoicesObj, InvoiceObj } from 'types/models';
import { ReactComponent as LeftIcon } from 'assets/icons/left-arrow.svg';
import { ReactComponent as RightIcon } from 'assets/icons/right-arrow.svg';

import Button from 'components/Button/Button';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { SizeMe } from 'react-sizeme';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryContainer } from 'victory';
import Constants from 'config/constants';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import { useSelector } from 'react-redux';
import { GlobalStoreState } from 'types/state';
import { navigate } from 'navigation';
import InvoiceCard from './InvoiceCard';
import styles from './InvoicesPage.module.scss';

interface NewInvoiceDataI {
  name: string;
  monthLabel: string;
  x: number;
  totalMonth: number;
  invoice: Array<any>;
}
const monthArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const AccountingPage = (props: any): JSX.Element => {
  // Props
  const {
    match: {
      params: { cupsID },
    },
  } = props;

  const cupsSelected = useSelector((state: GlobalStoreState) => state.clientCUPS.selectedCups);
  // Hooks

  // State
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [noData, setNoData] = useState(false);
  const [hasGlobalData, setHasGlobalData] = useState(false);
  const [hasDetailData, setHasDetailData] = useState(false);

  const [globalFetchedData, setGlobalFetchedData] = useState<Record<string, Array<NewInvoiceDataI>>>({});
  const [selectedMonth, setSelectedMonth] = useState<NewInvoiceDataI | null>(null);
  const [invoicesOfSelectedMonth, setInvoicesOfSelectedMonth] = useState<Array<InvoiceObj | null> | null>(null);
  const [testAll, settestAll] = useState<any>();

  // Effects

  // Detectar cambio de CUPS seleccionado para redirigir a URL correcta
  useEffect(() => {
    if (cupsSelected) {
      if (cupsSelected.cups !== cupsID) {
        navigate(`/client/${cupsSelected.cups}/invoices`);
      }
    }
  }, [cupsSelected]);

  useEffect(() => {
    setHasGlobalData(false);
    const fetchData = async (): Promise<void> => {
      try {
        if (cupsSelected) {
          const invoicesObj: InvoicesObj = await invoiceService.getInvoicesData(cupsSelected.cups);
          const invoicesObj2: InvoicesObj = await invoiceService.getInvoicesDataContabilidad(cupsSelected.cups);
          settestAll(invoicesObj2);
          if (!invoicesObj.data.length) {
            setNoData(true);
          }

          const newMonthArray: Array<NewInvoiceDataI> = [
            {
              name: 'January',
              monthLabel: I18n.t('January'),
              x: 1,
              totalMonth: 0,
              invoice: [],
            },
            {
              name: 'February',
              monthLabel: I18n.t('February'),
              x: 2,
              totalMonth: 0,
              invoice: [],
            },
            {
              name: 'March',
              monthLabel: I18n.t('March'),
              x: 3,
              totalMonth: 0,
              invoice: [],
            },
            {
              name: 'April',
              monthLabel: I18n.t('April'),
              x: 4,
              totalMonth: 0,
              invoice: [],
            },
            {
              name: 'May',
              monthLabel: I18n.t('May'),
              x: 5,
              totalMonth: 0,
              invoice: [],
            },
            {
              name: 'June',
              monthLabel: I18n.t('June'),
              x: 6,
              totalMonth: 0,
              invoice: [],
            },
            {
              name: 'July',
              monthLabel: I18n.t('July'),
              x: 7,
              totalMonth: 0,
              invoice: [],
            },
            {
              name: 'August',
              monthLabel: I18n.t('August'),
              x: 8,
              totalMonth: 0,
              invoice: [],
            },
            {
              name: 'September',
              monthLabel: I18n.t('September'),
              x: 9,
              totalMonth: 0,
              invoice: [],
            },
            {
              name: 'October',
              monthLabel: I18n.t('October'),
              x: 10,
              totalMonth: 0,
              invoice: [],
            },
            {
              name: 'November',
              monthLabel: I18n.t('November'),
              x: 11,
              totalMonth: 0,
              invoice: [],
            },
            {
              name: 'December',
              monthLabel: I18n.t('December'),
              x: 12,
              totalMonth: 0,
              invoice: [],
            },
          ];

          // const newDataset: Record<number, Array<any>> = {};
          const newDataset2: Record<number, Array<any>> = {};

          // invoicesObj.data.forEach((invoice) => {
          //   const currDate: Date = new Date(invoice.date);
          //   const invoiceYear: number = currDate.getFullYear();
          //   const invoiceMonth: number = currDate.getMonth();
          //   if (!(invoiceYear in newDataset)) {
          //     newDataset[invoiceYear] = JSON.parse(JSON.stringify(newMonthArray));
          //   }
          //   newDataset[invoiceYear][invoiceMonth].invoice.push(invoice.number);
          //   newDataset[invoiceYear][invoiceMonth].totalMonth += invoice.totalCost;
          // });
          // setGlobalFetchedData(newDataset);
          // setHasGlobalData(true);

          // invoicesObj2.data.forEach((invoice) => {
          //   const currDate: Date = new Date(invoice.date);
          //   const invoiceYear: number = currDate.getFullYear();
          //   const invoiceMonth: number = currDate.getMonth();
          //   if (!(invoiceYear in newDataset2)) {
          //     newDataset2[invoiceYear] = JSON.parse(JSON.stringify(newMonthArray));
          //   }
          //   newDataset2[invoiceYear][invoiceMonth].invoice.push(invoice.number);
          //   newDataset2[invoiceYear][invoiceMonth].totalMonth += invoice.totalCost;
          // });
          // setGlobalFetchedData(newDataset2);
          // setHasGlobalData(true);

          const array3 = [...invoicesObj.data, ...invoicesObj2.data];

          const ittotal = array3.filter((v, i, a) => a.findIndex((t) => t.number === v.number) === i);

          ittotal.forEach((invoice) => {
            const currDate: Date = new Date(invoice.date);
            const invoiceYear: number = currDate.getFullYear();
            const invoiceMonth: number = currDate.getMonth();
            if (!(invoiceYear in newDataset2)) {
              newDataset2[invoiceYear] = JSON.parse(JSON.stringify(newMonthArray));
            }
            newDataset2[invoiceYear][invoiceMonth].invoice.push(invoice.number);
            newDataset2[invoiceYear][invoiceMonth].totalMonth += invoice.totalCost;
          });
          setGlobalFetchedData(newDataset2);
          setHasGlobalData(true);
        }
      } catch (error) {
        toast.error(I18n.t('getInvoicesError'));
      }
    };

    fetchData();
  }, [cupsSelected]);

  // Hook para seleccionar el primer año con datos disponibles.
  useEffect(() => {
    const firstNonEmptyYear = Object.keys(globalFetchedData)
      .reverse()
      .find((year) => globalFetchedData[year] !== undefined);
    setCurrentYear(Number(firstNonEmptyYear));
  }, [globalFetchedData]);

  // Hook para seleccionar el primer mes disponible de X año.
  useEffect(() => {
    if (!globalFetchedData) return;
    if (!currentYear) return;
    const currentYearData = globalFetchedData[currentYear];
    if (currentYearData) {
      const firstIndex = currentYearData.reverse().findIndex((m) => m.invoice.length > 0);
      setSelectedMonth(globalFetchedData[currentYear][firstIndex]);
    }
  }, [globalFetchedData, currentYear]);

  /*  useEffect(() => {
    if (!currentYear) return;
    if (!globalFetchedData) return;
    if (!globalFetchedData[currentYear]) return;
    globalFetchedData[currentYear].forEach((month) => {
      month.invoice.forEach((inv) => {
        try {
          invoiceService.getInvoiceById(inv);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      });
    });
  }, [currentYear]); */

  // Hook para solicitar los datos en detalle de un mes selecionado
  useEffect((): void => {
    setHasDetailData(false);
    const fetchData = async (): Promise<void> => {
      if (!selectedMonth) {
        return;
      }
      const invoiceInfoArray: Array<InvoiceObj | null> = await Promise.all(
        selectedMonth.invoice.map(async (invoiceNumber) => {
          let invoiceInfo: InvoiceObj | null = new InvoiceObj();

          const ittotal = testAll.data.filter((a: any) => a.number === invoiceNumber);

          if (ittotal.length > 0) {
            try {
              invoiceInfo = await invoiceService.getInvoiceByIdContabilidad(invoiceNumber);
              return invoiceInfo;
            } catch (error) {
              toast.error(`${I18n.t('errorGettingInvoiceInfo')} ${invoiceNumber}`);
            }
            return null;
          }
          try {
            invoiceInfo = await invoiceService.getInvoiceById(invoiceNumber);
            return invoiceInfo;
          } catch (error) {
            toast.error(`${I18n.t('errorGettingInvoiceInfo')} ${invoiceNumber}`);
          }
          return null;
        }),
      );

      setHasDetailData(true);

      setInvoicesOfSelectedMonth(invoiceInfoArray);
    };

    if (selectedMonth) {
      fetchData();
    }
  }, [selectedMonth]);

  const showPrice = (): string => {
    if (!selectedMonth) return '-';
    return selectedMonth?.totalMonth.toFixed(2).toString();
  };

  const showMonth = (): string => {
    if (!selectedMonth) return '-';
    return selectedMonth?.monthLabel.toString();
  };
  if (!hasGlobalData) {
    return <FullPageSyncLoader icon />;
  }

  return (
    <SectionWrapper customClassname={styles.root}>
      <SectionTitle text={I18n.t('invoices')} />
      {noData ? (
        <div className={styles.nodata}>{I18n.t('noDataObtained')}.</div>
      ) : (
        <div className={styles.invoices_container}>
          <div className={styles.invoices_chart}>
            <div className={styles.picker}>
              <Button
                className={styles.picker_button}
                type="button"
                variant="secondary"
                onClick={(): void => {
                  if (globalFetchedData[currentYear - 1]) {
                    setCurrentYear(currentYear - 1);
                  }
                }}
              >
                <LeftIcon
                  height={16}
                  width={16}
                  fill={
                    globalFetchedData[currentYear - 1]
                      ? Colors.COLOR_ELECSUMGREEN
                      : Colors.COLOR_ELECSUMGREEN_NOT_SELECTED
                  }
                />
              </Button>
              <p className={styles.picker_value}>{currentYear}</p>
              <Button
                type="button"
                variant="secondary"
                className={styles.picker_button}
                disabled={currentYear === +Object.keys(globalFetchedData)[Object.keys(globalFetchedData).length - 1]}
                onClick={(): void => {
                  if (globalFetchedData[currentYear + 1]) {
                    setCurrentYear(currentYear + 1);
                  }
                }}
              >
                <RightIcon
                  height={16}
                  width={16}
                  fill={
                    globalFetchedData[currentYear + 1]
                      ? Colors.COLOR_ELECSUMGREEN
                      : Colors.COLOR_ELECSUMGREEN_NOT_SELECTED
                  }
                />
              </Button>
            </div>
            <div className={styles.infopicked}>
              <p className={styles.month}>{showMonth()}</p>
              <p className={styles.cost}>{`${showPrice()} €`}</p>
            </div>

            <div>
              <SizeMe>
                {({ size }) => (
                  <div>
                    <svg viewBox={`0 0 ${size.width} 350`} height="100%" width="100%" className={styles.chart_svg}>
                      <VictoryChart
                        width={size.width || 500}
                        height={350}
                        standalone={false}
                        containerComponent={<VictoryContainer responsive={false} />}
                      >
                        <VictoryBar
                          barRatio={0.8}
                          data={globalFetchedData[currentYear]}
                          y={(d) => d.totalMonth}
                          style={{
                            data: {
                              fill: ({ datum }) => {
                                if (datum._x === selectedMonth?.x) {
                                  return Colors.COLOR_ELECSUMSTRONG;
                                }
                                return Colors.COLOR_ELECSUMLOW;
                              },
                            },
                          }}
                          events={[
                            {
                              target: 'data',
                              eventHandlers: {
                                onClick: (): any => [
                                  {
                                    // TODO tipar la funció
                                    target: 'data',
                                    mutation: (value: { datum: any }): void => {
                                      setSelectedMonth(value.datum);
                                    },
                                  },
                                ],
                              },
                            },
                          ]}
                        />
                        <VictoryAxis
                          tickValues={monthArray.map((item) => I18n.t(item))}
                          tickFormat={(t): string => t.toUpperCase().toString().substr(0, 3)}
                          style={{
                            axis: { stroke: 'transparent' },
                            tickLabels: {
                              fill: Colors.COLOR_CONSUM_NOT_SELECTED,
                              fontSize: Constants.charts.AXIS_VALUES_FONTSIZE,
                            },
                          }}
                        />
                      </VictoryChart>
                    </svg>
                  </div>
                )}
              </SizeMe>
            </div>
          </div>
          <div className={styles.invoices}>
            {invoicesOfSelectedMonth ? (
              hasDetailData ? (
                invoicesOfSelectedMonth.map((invoice) => (invoice !== null ? <InvoiceCard invoice={invoice} /> : null))
              ) : (
                <div style={{ width: '100%' }}>
                  <FullPageSyncLoader icon />
                </div>
              )
            ) : (
              <Spinner icon />
            )}
          </div>
        </div>
      )}
    </SectionWrapper>
  );
};
export default AccountingPage;
