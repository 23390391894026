/* eslint-disable import/prefer-default-export */
import { Serializable } from 'types/interfaces';

interface ClosuresRangeAPII {
  datos: Array<Array<string>>;
  habilitado: string;
}

export class ClosuresRangeObject implements Serializable<ClosuresRangeObject> {
  datos: Array<{
    inicio: string;
    fin: string;
  }>;

  habilitado: string;

  deserialize(input: ClosuresRangeAPII): ClosuresRangeObject {
    if (!input) {
      return this;
    }
    this.datos = input.datos.map((rango) => ({
      inicio: rango[0] || '',
      fin: rango[1] || '',
    }));
    this.habilitado = input.habilitado || 'no';
    return this;
  }
}
