import { Serializable } from '../interfaces';

export interface InvoiceSimulationAPII {
  datos: {
    fechaInicio: string;
    fechaFin: string;
    totalFactura: {
      base: number;
      iva: number;
      importeTotal: number;
    };
    datosContratacion: {
      tarifaAcceso: string;
      potenciasContratadas: Array<number>;
      titular: string;
      nif: string;
      cups: string;
    };
    lecturas: {
      activa: Array<{
        periodo: number;
        energia: number;
      }>;
      reactiva: Array<{
        periodo: number;
        maximetro: number;
        energia: number;
      }>;
    };
    consumos: {
      terminoDePotencia: Array<{
        periodo: number;
        potencia: number;
        termino: number;
        dias: number;
        total: number;
      }>;
      terminoDeEnergia: Array<{
        periodo: number;
        energia: number;
        termino: number;
        total: number;
      }>;
      terminoDeEnergiaReactiva: Array<{
        periodo: number;
        reactiva: number;
        termino: number;
        total: number;
      }>;
      excesosDePotencia: Array<{
        periodo: number;
        ae: number;
        termino: number;
        constanteK: number;
        total: number;
      }>;
      excedentes: Array<{
        periodo: number;
        excedente: number;
        termino: number;
        total: number;
      }>;
    };
    impuestoSobreLaElectricidad: {
      porcentajeImpuesto: number;
      porcentajeExentoImpuesto: number;
      baseImpuesto: number;
      totalImpuesto: number;
    };
    equiposDeMedida: {
      diasAlquiler: number;
      terminoAlquiler: number;
      totalAlquiler: number;
    };
  };
  habilitado: string;
}

export default class InvoiceSimulation implements Serializable<InvoiceSimulation> {
  datos: {
    fechaInicio: string;
    fechaFin: string;
    totalFactura: {
      base: number;
      iva: number;
      importeTotal: number;
    };
    datosContratacion: {
      tarifaAcceso: string;
      potenciasContratadas: Array<number>;
      titular: string;
      nif: string;
      cups: string;
    };
    lecturas: {
      activa: Array<{
        periodo: number;
        energia: number;
      }>;
      reactiva: Array<{
        periodo: number;
        maximetro: number;
        energia: number;
      }>;
    };
    consumos: {
      terminoDePotencia: Array<{
        periodo: number;
        potencia: number;
        termino: number;
        dias: number;
        total: number;
      }>;
      terminoDeEnergia: Array<{
        periodo: number;
        energia: number;
        termino: number;
        total: number;
      }>;
      terminoDeEnergiaReactiva: Array<{
        periodo: number;
        reactiva: number;
        termino: number;
        total: number;
      }>;
      excesosDePotencia: Array<{
        periodo: number;
        ae: number;
        termino: number;
        constanteK: number;
        total: number;
      }>;
      excedentes: Array<{
        periodo: number;
        excedente: number;
        termino: number;
        total: number;
      }>;
    };
    impuestoSobreLaElectricidad: {
      porcentajeImpuesto: number;
      baseImpuesto: number;
      porcentajeExentoImpuesto: number;
      totalImpuesto: number;
    };
    equiposDeMedida: {
      diasAlquiler: number;
      terminoAlquiler: number;
      totalAlquiler: number;
    };
  };

  habilitado: string;

  deserialize(input: InvoiceSimulationAPII): InvoiceSimulation {
    if (!input) {
      return this;
    }
    this.datos = {
      fechaInicio: input.datos.fechaInicio,
      fechaFin: input.datos.fechaFin,
      totalFactura: {
        base: input.datos.totalFactura.base || 0,
        iva: input.datos.totalFactura.iva || 0,
        importeTotal: input.datos.totalFactura.importeTotal || 0,
      },
      datosContratacion: {
        tarifaAcceso: input.datos.datosContratacion.tarifaAcceso || '',
        potenciasContratadas: input.datos.datosContratacion.potenciasContratadas || [],
        titular: input.datos.datosContratacion.titular || '',
        nif: input.datos.datosContratacion.nif || '',
        cups: input.datos.datosContratacion.cups || '',
      },
      lecturas: {
        activa: input.datos.lecturas.activa || [],
        reactiva: input.datos.lecturas.reactiva || [],
      },
      consumos: {
        terminoDePotencia: input.datos.consumos.terminoDePotencia || [],
        terminoDeEnergia: input.datos.consumos.terminoDeEnergia || [],
        terminoDeEnergiaReactiva: input.datos.consumos.terminoDeEnergiaReactiva || [],
        excesosDePotencia: input.datos.consumos.excesosDePotencia || [],
        excedentes: input.datos.consumos.excedentes || [],
      },
      impuestoSobreLaElectricidad: {
        porcentajeImpuesto: input.datos.impuestoSobreLaElectricidad.porcentajeImpuesto,
        porcentajeExentoImpuesto: input.datos.impuestoSobreLaElectricidad.porcentajeExentoImpuesto,
        baseImpuesto: input.datos.impuestoSobreLaElectricidad.baseImpuesto,
        totalImpuesto: input.datos.impuestoSobreLaElectricidad.totalImpuesto,
      },
      equiposDeMedida: {
        diasAlquiler: input.datos.equiposDeMedida.diasAlquiler,
        terminoAlquiler: input.datos.equiposDeMedida.terminoAlquiler,
        totalAlquiler: input.datos.equiposDeMedida.totalAlquiler,
      },
    };
    return this;
  }
}
