import { GuardInterface } from 'types/interfaces';

import { authServiceInstance, AuthService } from 'services';

class LoggedInGuard implements GuardInterface {
  private authService: AuthService;

  private redirectUrl = '/auth/login';

  constructor() {
    this.authService = authServiceInstance;
  }

  canActivate(): boolean {
    const isUserLogged = this.authService.isLoggedIn();
    return isUserLogged;
  }

  getRedirectUrl(): string | null {
    return this.redirectUrl;
  }
}

const loggedInGuard = new LoggedInGuard();

export default loggedInGuard;
