import { Serializable } from 'types/interfaces';

interface InformeInterface {
  nombre: string;
  tipo: ReportOptions;
}
interface ReportOptions {
  mensual: boolean;
  anual: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ClientAPI {
  nif: string;
  nombre: string;
  apellidos: string;
  domicilio: string;
  poblacion: string;
  provincia: string;
  telefono: string;
  informeFotovoltaicoMensual: string;
  informeFotovoltaicoAnual: string;
  informeCompletoMasDesviacionMensual: string;
  informeCompletoMasDesviacionAnual: string;
  informeGarantiaDeProduccionMensual: string;
  informeGarantiaDeProduccionAnual: string;
  porcentajeTitularidadInstalacion: number;
  instalacionID: number;
  tarifaComercializadora: string;
  costeAcceso: number;
  tarifaIndexada: string;
}
export default class Client implements Serializable<Client> {
  nif: string;

  cups: string;

  nombre: string;

  apellidos?: string;

  domicilio: string;

  poblacion: string;

  provincia: string;

  cp?: number;

  contacto?: string;

  telefono?: number;

  production: number;

  distribution: number;

  distributionP: number;

  informes: Array<InformeInterface>;

  informeFotovoltaicoMensual?: string;

  informeFotovoltaicoAnual?: string;

  informeCompletoMasDesviacionMensual?: string;

  informeCompletoMasDesviacionAnual?: string;

  informeGarantiaDeProduccionMensual?: string;

  informeGarantiaDeProduccionAnual?: string;

  porcentajeTitularidadInstalacion?: number;

  costeAcceso?: number;

  instalacionID: number;

  modem: boolean;

  tarifaComercializadora: string;

  tarifaIndexada: string;

  comercializado: boolean;

  constructor() {
    this.deserialize({});
  }

  deserialize(input: any): Client {
    if (!input) {
      return this;
    }

    this.nif = input.nif || '';
    this.cups = input.cups || '';
    this.nombre = input.nombre || '';
    this.apellidos = input.apellidos || '';
    this.domicilio = input.domicilio || '';
    this.poblacion = input.poblacion || '';
    this.provincia = input.provincia || '';
    this.cp = input.cp || '';
    this.contacto = input.contacto || '';
    this.telefono = input.telefono || '';
    this.production = input.production || 0;
    this.distribution = input.distribution || 0;
    this.distributionP = input.distributionP || 0;
    this.informes = input.informes ? input.informes.map((informe: InformeInterface) => ({ ...informe })) : '';
    this.informeFotovoltaicoMensual = input.informeFotovoltaicoMensual || '';
    this.informeFotovoltaicoAnual = input.informeFotovoltaicoAnual || '';
    this.informeCompletoMasDesviacionMensual = input.informeCompletoMasDesviacionMensual || '';
    this.informeCompletoMasDesviacionAnual = input.informeCompletoMasDesviacionAnual || '';
    this.informeGarantiaDeProduccionMensual = input.informeGarantiaDeProduccionMensual || '';
    this.informeGarantiaDeProduccionAnual = input.informeGarantiaDeProduccionAnual || '';
    this.porcentajeTitularidadInstalacion = input.porcentajeTitularidadInstalacion || null;
    this.costeAcceso = input.costeAcceso || 0;
    this.instalacionID = input.instalacionID || -1;
    this.modem = input.modem === 'si';
    this.tarifaComercializadora = input.tarifaComercializadora;
    this.tarifaIndexada = input.tarifaIndexada;
    this.comercializado = input.comercializado === 'si';
    return this;
  }

  setAttr<T>(key: string, value: T): void {
    Object.assign(this, { [key]: value });
  }

  // eslint-disable-next-line class-methods-use-this
  deserializeArray(inputArray: Array<any>): Array<Client> {
    const clients: Array<Client> = inputArray.map((input) => new Client().deserialize(input));

    return clients;
  }
}
