import { Serializable } from 'types/interfaces';

export interface PromotorUpdatedAPI {
  identificador: string;
  smtpHost: string;
  smtpPort: number;
  username: string;
  password: string;
  useTls: 'si' | 'no';
  useSsl: 'si' | 'no';
  telefonoDeContacto: string;
}

export interface PromotorAPI {
  identificador: string;
  smtpHost: string;
  smtpPort: number;
  username: string;
  useTls: 'si' | 'no';
  useSsl: 'si' | 'no';
  telefonoDeContacto: string;
}

export default class Promotor implements Serializable<Promotor> {
  identificador: string;

  smtpHost: string;

  smtpPort: number;

  username: string;

  useTls: 'si' | 'no';

  useSsl: 'si' | 'no';

  telefonoDeContacto: string;

  deserialize(input: PromotorAPI): Promotor {
    if (!input) {
      return this;
    }
    this.identificador = input.identificador || '';
    this.smtpHost = input.smtpHost || '';
    this.smtpPort = input.smtpPort || 0;
    this.username = input.username || '';
    this.useTls = input.useTls || 'no';
    this.useSsl = input.useSsl || 'no';
    this.telefonoDeContacto = input.telefonoDeContacto || '';

    return this;
  }

  deserializeArray(inputArray: Array<PromotorAPI>): Array<Promotor> {
    const Promotores: Array<Promotor> = inputArray.map((input) => new Promotor().deserialize(input));

    return Promotores;
  }

  setAttr<T>(key: string, value: T): void {
    Object.assign(this, { [key]: value });
  }
}
