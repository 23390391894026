import React from 'react';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import { toast } from 'react-toastify';
import I18n from 'i18n-js';
import { CommunityModel } from 'types/models/community';
import { ElecsumSelect, RoundedButton } from 'components/NewInputs';
import { SelectItemI } from 'components/NewInputs/Select/ElecsumSelect';
import configuration from 'config';

import styles from './tools-bar.module.scss';

interface ToolsBarProps {
  langs: SelectItemI[];
  selectedLang: SelectItemI;
  onChangeLanguage(lang: SelectItemI): void;
  communityID: string;
  selectedCommunity: CommunityModel;
}

export const ToolsBar = (props: ToolsBarProps): JSX.Element => {
  const { langs, onChangeLanguage, selectedLang, communityID, selectedCommunity } = props;

  const buildShareUrl = (): string => `${configuration.elecsumShareUrl}comunitats/${communityID}/onboarding`;
  const buildShareUrlPromotor = (): string =>
    `${configuration.elecsumShareUrl}comunitats/${communityID}/onboarding-promotor`;

  // Extrae el botón de copia a una función para evitar duplicación de código
  const handleCopyToClipboard = (url: string, successMessage: string) => {
    if (!navigator.clipboard) return;
    navigator.clipboard.writeText(url).then(() => {
      toast.success(I18n.t(successMessage));
    });
  };
  const hasPromotores = selectedCommunity.promotores.length > 0;

  return (
    <div className={styles.container}>
      <div className={styles.container}>
        {hasPromotores && (
          <RoundedButton
            onClick={() => handleCopyToClipboard(buildShareUrlPromotor(), 'ComunidadesDetallePage.linkCopiedPromotor')}
            className={styles.shareButton}
            variant="ghost"
          >
            <LinkIcon />
            {I18n.t('ComunidadesDetallePage.copyUrlPromotor')}
          </RoundedButton>
        )}

        <RoundedButton
          onClick={() =>
            handleCopyToClipboard(
              buildShareUrl(),
              hasPromotores ? 'ComunidadesDetallePage.linkCopied' : 'ComunidadesDetallePage.linkCopiedComunidad',
            )
          }
          className={styles.shareButton}
          variant="ghost"
        >
          <LinkIcon />
          {hasPromotores ? I18n.t('ComunidadesDetallePage.copyUrl') : I18n.t('ComunidadesDetallePage.copyUrlComunidad')}
        </RoundedButton>
      </div>

      <ElecsumSelect
        minWidth="15rem"
        selected={selectedLang}
        onChange={onChangeLanguage}
        label="Idioma"
        values={langs}
      />
    </div>
  );
};
