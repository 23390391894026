import React from 'react';
import cx from 'classnames';
import { ReactComponent as ElecsumIcon } from 'assets/icons/ElecsumSpinnerGradient.svg';
import { Colors } from 'styles';
import styles from './Spinner.module.scss';

interface SpinnerPropsI {
  size?: number;
  icon?: boolean;
}

const Spinner = (props: SpinnerPropsI) => {
  const { size, icon } = props;
  const getSize = () => ({ height: `${size}px`, width: `${size}px` });
  return (
    <div style={getSize()} className={cx(styles.root)}>
      <div className={styles.spinner} />
      {icon && (
        <ElecsumIcon fill={Colors.COLOR_ELECSUMGREEN} height={size ? size * 0.4 : 10} width={size ? size * 0.4 : 20} />
      )}
    </div>
  );
};

Spinner.defaultProps = {
  size: 75,
  icon: false,
};

export default Spinner;
