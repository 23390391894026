import { DataTable, SectionTitle, TableHeading } from 'components';

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { FormGroup, FormGroupProps, I18n } from 'core';
import { ReactComponent as DocumentsIcon } from 'assets/icons/Documents.svg';

import { CommunityCustomOnboarding } from 'types/models';
import { Colors } from 'styles';
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg';

import { CommunityCustomOnboardingServiceInstance, LoggingService } from 'services';
import { FORMGROUP_TYPES } from 'core/form/FormGroup/FormGroup';
import { toast } from 'react-toastify';
import customToast from 'components/CustomToast/CustomToast';
import styles from './comunidades-custom-onboarding-horario.module.scss';
import { SaveDataHandle } from '../../comunidades-custom-onboarding-process-page';
// import { ColorPicker } from '../comunidades-color-picker/comunidades-color-picker';

interface ComunidadesCustomOnboardingHorarioProps {
  communityId: string;
  communityCustomOnboarding: CommunityCustomOnboarding;
  saveDetails: (customOnboarding: any) => void;
}
const ComunidadesCustomOnboardingHorario = forwardRef<SaveDataHandle, ComunidadesCustomOnboardingHorarioProps>(
  (props: ComunidadesCustomOnboardingHorarioProps, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { communityId, communityCustomOnboarding, saveDetails } = props;
    const [errors, setErrors] = useState({});
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [environmentNews, setEnvironmentNews] = useState<Array<any>>(
      communityCustomOnboarding.horarioContacto.horarios,
    );
    const [horaInicio, SeThoraInicio] = useState('');

    const [horaFin, SeThoraFin] = useState('');
    const [environmentNew, setenvironmentNew] = useState({
      franja: '',
      horas: `${horaInicio}a${horaFin}`,
      idioma: 'es',
    });
    if ((communityCustomOnboarding.horarioContacto as any) === '{}') {
      communityCustomOnboarding.horarioContacto = {
        horarios: [],
      };

      setEnvironmentNews(communityCustomOnboarding.horarioContacto.horarios);
    }
    const validateForm = (): boolean => {
      setErrors({});
      return true;
    };

    const handleChange = async (path: string, value: string) => {
      if (path === 'horaInicio') {
        SeThoraInicio(value);
      } else if (path === 'horaFin') {
        SeThoraFin(value);
      }
      setenvironmentNew((prev) => ({
        ...prev,
        [path]: value,
      }));
    };

    const handleSubmit = (): void => {
      environmentNew.horas = `${horaInicio} a ${horaFin}`;
      if (validateForm()) {
        LoggingService.log('handleSubmit :: ok', { client: environmentNew });
        saveDetails(communityCustomOnboarding.horarioContacto.horarios.push(environmentNew));
      } else {
        LoggingService.log('handleSubmit :: errors', { client: environmentNew, errors });
      }
    };

    const deleteHorario = async (horario: any): Promise<void> => {
      // eslint-disable-next-line no-alert
      const accept = window.confirm(
        `${I18n.t('Are you sure you want to delete the horari?')} : ${horario.franja} - ${horario.horas} ? `,
      );

      if (!accept) {
        return;
      }

      communityCustomOnboarding.horarioContacto.horarios = communityCustomOnboarding.horarioContacto.horarios.filter(
        (x) => x !== horario,
      );
      setEnvironmentNews(communityCustomOnboarding.horarioContacto.horarios);
      try {
        await CommunityCustomOnboardingServiceInstance.updateCommunityCustomOnboardingHorarios(
          communityId,
          communityCustomOnboarding,
        );
        customToast.success(I18n.t('ComunidadesCustomOnboardingProcess.updateSuccess'));
      } catch (error) {
        toast.error(`${I18n.t('Error deleting image')}.`);
      }
    };

    const formFields: Array<FormGroupProps> = [
      {
        label: I18n.t('FranjaHead'),
        type: 'string',
        id: 'franja',
        path: 'franja',
        name: 'franja',
        placeholder: 'franja',
        value: environmentNew?.franja,
      },
      {
        label: I18n.t('horaInicio'),
        type: 'time',
        id: 'horaInicio',
        path: 'horaInicio',
        name: 'horaInicio',
        placeholder: 'horas',
        value: horaInicio,
      },
      {
        label: I18n.t('horaFin'),
        type: 'time',
        id: 'horaFin',
        path: 'horaFin',
        name: 'horaFin',
        placeholder: 'horas',
        value: horaFin,
      },
      {
        label: I18n.t('IdiomaHead'),
        type: FORMGROUP_TYPES.select,
        id: 'idioma',
        path: 'idioma',
        name: 'idioma',
        value: environmentNew?.idioma,
        options: [
          { label: 'es', value: 'es' },
          { label: 'ca', value: 'ca' },
        ],
      },
    ];

    useImperativeHandle(ref, () => ({
      getData: () => {
        handleSubmit();
      },
    }));

    const headings: Array<TableHeading> = [
      { label: I18n.t('FranjaHead'), key: 'franja' },
      { label: I18n.t('HorasHead'), key: 'horas' },
      { label: I18n.t('IdiomaHead'), key: 'idioma' },
      {
        label: I18n.t('Actions'),
        type: 'actions',
        key: '',
        actions: [
          {
            label: I18n.t('Delete'),
            // eslint-disable-next-line react/jsx-no-undef
            icon: <TrashIcon height={25} width={25} fill={Colors.COLOR_ELECSUMGREEN} />,
            onActionClick: deleteHorario,
          },
        ],
      },
    ];

    return (
      <div className={styles.root}>
        <div className={styles.flex}>
          <DocumentsIcon height={20} width={20} fill={Colors.COLOR_BLACK} className={styles.DocumentsIcon} />
          <SectionTitle text={I18n.t('ComunidadesCustomOnboardingProcess.form.horarioTitle')} />
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.containerForms}>
            <div className={styles.containerForm}>
              {formFields.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  placeholder={formGroup.placeholder}
                  value={formGroup.value}
                  required={formGroup.required}
                  onChange={handleChange}
                  readOnly={formGroup.readOnly}
                  className={formGroup.className}
                  options={formGroup.options}
                  maxLenght={20000}
                />
              ))}
            </div>
          </div>
        </form>
        <div className={styles.tablenews}>
          {communityCustomOnboarding.horarioContacto.horarios &&
          communityCustomOnboarding.horarioContacto.horarios.length !== 0 &&
          environmentNews.length !== 0 ? (
            <DataTable headings={headings} rows={environmentNews} />
          ) : null}
        </div>
      </div>
    );
  },
);

export default ComunidadesCustomOnboardingHorario;
