enum WidgetPermission {
  ACCESO_WIDGET_ALERTAS = 'acceso_widget_alertas',
  ACCESO_WIDGET_USUARIOS = 'acceso_widget_usuarios',
  ACCESO_WIDGET_CUPS = 'acceso_widget_cups',
  ACCESO_WIDGET_COMUNIDADES = 'acceso_widget_comunidades',
  ACCESO_WIDGET_INSTALACIONES = 'acceso_widget_instalaciones',
  ACCESO_WIDGET_ACTUALIZACIONES_DE_TARIFA = 'acceso_widget_actualizaciones_de_tarifa',
  ACCESO_WEB_TECNICOS = 'acceso_web_tecnicos',
  ACCESO_WIDGET_MATCHER = 'acceso_widget_matcher',
  ACCESO_WEB = 'acceso_web',
  ACCESO_CUPS_AUTORIZADOS = 'acceso_cups_autorizados',
  ACCESO_CIM_AUTORIZADOS = 'acceso_cim',
  ACCESO_APP_MOBILE = 'acceso_app_mobile',
  ACCESO_APP_MOBILE_ADMIN = 'acceso_app_mobile_admin',
  ACCESO_ACTUALIZAR_TARIFA = 'acceso_actualizar_tarifa',
}

export default WidgetPermission;
