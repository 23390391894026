import { GuardInterface } from 'types/interfaces';

import { authServiceInstance, AuthService } from 'services';
import WidgetPermission from 'types/enums/WidgetPermission';

class IsAdminGuard implements GuardInterface {
  private authService: AuthService;

  private redirectUrl = '/';

  constructor() {
    this.authService = authServiceInstance;
  }

  canActivate(): boolean {
    const isUserLogged = this.authService.isLoggedIn();
    const { user, permisos } = this.authService;
    const hasPermission = permisos && permisos.includes(WidgetPermission.ACCESO_WEB_TECNICOS);
    const hasPermissionAccesoWeb = permisos && permisos.includes(WidgetPermission.ACCESO_WEB);
    if (!isUserLogged) {
      return false;
    }
    if (hasPermission) {
      return true;
    }

    return (user !== null && user.isAdmin()) || (hasPermission && hasPermissionAccesoWeb);
  }

  getRedirectUrl(): string | null {
    return this.redirectUrl;
  }
}

const isAdminGuard = new IsAdminGuard();

export default isAdminGuard;
