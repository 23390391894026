import { ElecsumGreenWebAPI } from '../types/models';

const ELECSUMGREENWEBPAGE_MOCK: ElecsumGreenWebAPI = {
  imagenCabecera: 'https://elecsum.region.s3storage.com/imagen/5E64C87AB4757AF5.png',
  // imagenCabecera: '',
  imagenLogo: 'https://elecsum.region.s3storage.com/imagen/5E64C87AB4757AF5.png',
  nombreComercial: 'Hotel Balneari Termes Victoria',
  telefono: '931 234 567',
  email: 'hello@elecsum.com',
  paginaWeb: 'https://www.elecsum.com',
  fechaActualizacion: '2020-01-21',
  produccion: {
    añoEnCurso: {
      produccion: 300000000,
      co2: 4.63,
      arboles: 25,
    },
    totalAcumulado: {
      produccion: 925000000,
      co2: 2.184,
      arboles: 760,
    },
  },
  consumo: {
    añoEnCurso: {
      consumo: 245000000,
      co2: 4.63,
      arboles: 25,
    },
    totalAcumulado: {
      consumo: 925000000,
      co2: 2.184,
      arboles: 760,
    },
  },
  comercializado: 'no',
  empresa: {
    imagenes: [
      'https://picsum.photos/300/200?random=1',
      'https://picsum.photos/300/200?random=2',
      'https://picsum.photos/300/200?random=3',
      'https://picsum.photos/300/200?random=4',
      'https://picsum.photos/300/200?random=5',
    ],
    descripcion:
      'Els orígens de l’Hotel Balneari Termes Victòria es remunten al segle XVII. Al llarg dels anys s’han fet diverses reformes arquitectòniques, però sempre conservant elements originals com la volta de la galeria del centre termal del segle XVIII, les rajoles dels terres dels salons i els vitralls del menjador. Actualment es manté la direcció familiar de l’hotel que des de l’any 1917 regenta la família Anglí. L’hotel està situat al casc antic de la vila termal i romana de Caldes de Montbui, a només 25 km de Barcelona. Una ubicació privilegiada amb vistes a la muntanya que combina el relax i la tranquil.litat amb la comoditat de passejar pels carrers del nucli antic i gaudir de comerços,  bars i terrasses.',
    noticias: [
      {
        titular: 'NOTICIA 1 La empresa elecsum ha participado en la campaña de reforestación de ...',
        fecha: '2020-05-25',
        descripcion: 'La campaña ha consistido en ...',
        imagenUrl: 'https://picsum.photos/300/200?random=4',
      },
      {
        titular: 'NOTICIA 2 La empresa elecsum ha participado en la campaña de reforestación de ...',
        fecha: '2020-05-25',
        descripcion: 'La campaña ha consistido en ...',
        imagenUrl: 'https://picsum.photos/300/200?random=5',
      },
      {
        titular: 'NOTICIA 3 La empresa elecsum ha participado en la campaña de reforestación de ...',
        fecha: '2020-05-25',
        descripcion: 'La campaña ha consistido en ...',
        imagenUrl: 'https://picsum.photos/300/200?random=6',
      },
    ],
  },
};

export default ELECSUMGREENWEBPAGE_MOCK;
