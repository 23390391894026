import { ElecsumSelect } from 'components/NewInputs';
import { SelectItemI } from 'components/NewInputs/Select/ElecsumSelect';
import React from 'react';

import styles from './tools-bar.module.scss';

interface ToolsBarProps {
  langs: SelectItemI[];
  selectedLang: SelectItemI;
  onChangeLanguage(lang: SelectItemI): void;
}

export const ToolsBarWithoutUrl = (props: ToolsBarProps): JSX.Element => {
  const { langs, onChangeLanguage, selectedLang } = props;
  return (
    <div className={styles.container}>
      <ElecsumSelect
        minWidth="15rem"
        selected={selectedLang}
        onChange={onChangeLanguage}
        label="Idioma"
        values={langs}
      />
    </div>
  );
};
