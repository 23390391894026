import I18n from 'i18n-js';
import React, { useState } from 'react';
import { LoadingButton, SectionTitle } from 'components';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { navigate } from 'navigation';
import MatcherConfiguracionInstance from 'services/remote/MatcherConfiguracionService';
import ConfiguracionMatcher from 'types/models/ConfiguracionMatcher';
import styles from './matcher-list-table.module.scss';
import { MatcherListRow } from '../matcher-list-row/matcher-list-row';

interface MatcherListTableProps {
  data: ConfiguracionMatcher[];
  solicitudID: string;
  onChange: CallableFunction;
}

export const MatcherListTable = (props: MatcherListTableProps): JSX.Element => {
  const { data, onChange } = props;
  const { t } = I18n;
  const [loading, setLoading] = useState(false);

  const submitForm = async (): Promise<void> => {
    setLoading(true);

    try {
      // MODIFICAR

      const apiData = await MatcherConfiguracionInstance.postConfiguracionMatcher();

      // const currentDate = new Date(); // Get current date
      // const nextYear = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate()); // Add one year

      const fecha = {
        // fechaInicio: `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`,
        // fechaFin: `${nextYear.getFullYear()}-${nextYear.getMonth() + 1}-${nextYear.getDate()}`,
        fechaInicio: `2024-01-01`,
        fechaFin: `2024-12-31`,
      };
      await MatcherConfiguracionInstance.patchConfiguracionMatcher(apiData.datos.id, fecha);

      navigate(`/tecnic/matcher/${apiData.datos.id}/edit`);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <div className={styles.root}>
      <SectionWrapper>
        <div className={styles.table_wrapper2}>
          <div className={styles.titleWrapper}>
            <SectionTitle text={I18n.t('MatcherListPage.title')} />
            <div className={styles.loadingButtonFlex}>
              <LoadingButton
                className={styles.loadingButtonAddUser}
                loading={loading}
                type="submit"
                text={I18n.t('MatcherListPage.add')}
                onClick={submitForm}
              />
            </div>
          </div>

          <table className={styles.table_wrapper}>
            {data.length > 0 ? (
              <>
                <thead>
                  <tr>
                    <th>{t(`MatcherListPage.headers.name`)}</th>
                    <th>{t(`MatcherListPage.headers.comunidad`)}</th>
                    <th>{t(`MatcherListPage.headers.fecha`)}</th>
                    <th>{t(`MatcherListPage.headers.numberMember`)}</th>
                    <th>{t(`MatcherListPage.headers.report`)}</th>
                    <th aria-label="Detail" />
                  </tr>
                </thead>
                <tbody>
                  {data
                    .sort((a, b) => {
                      const dateA = new Date(a.fechaCreacion);
                      const dateB = new Date(b.fechaCreacion);

                      return dateB.getTime() - dateA.getTime();
                    })
                    .map((configuracion) => (
                      <MatcherListRow key={configuracion.id} configuracion={configuracion} deleteOnChange={onChange} />
                    ))}
                </tbody>
              </>
            ) : (
              <p>{t(`TarifasListPage.headers.notResult`)}</p>
            )}
          </table>
        </div>
      </SectionWrapper>
    </div>
  );
};
