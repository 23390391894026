import React, { useState, useEffect, ChangeEvent } from 'react';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { toast } from 'react-toastify';
import { ReactComponent as LandscapeIcon } from 'assets/icons/Landscape.svg';
import { FormGroup, FormGroupProps, I18n } from 'core';

import { LoggingService, elecsumGreenServiceInstance } from 'services';

import { SectionTitle, SelectUserType, EunoiaImageInput, LoadingButton, Spinner } from 'components';

import { ElecsumGreen } from 'types/models';
import customToast from 'components/CustomToast/CustomToast';
import Button from 'components/Button/Button';
import { Colors } from 'styles';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import configuration from 'config';
import ImageSize from 'types/enums/ImageSizes';
import styles from './ElecsumGreenData.module.scss';

interface ElecsumGreenDataI {
  greenStamp: ElecsumGreen;
  nif: string;
  afterSubmitAction?: CallableFunction;
}

const ElecsumGreenData = (props: ElecsumGreenDataI): JSX.Element => {
  const { greenStamp, nif, afterSubmitAction } = props;

  const [loading, setLoading] = useState(false);
  const [loadingHeaderImage, setLoadingHeaderImage] = useState(false);
  const [loadingLogoImage, setLoadingLogoImage] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState<string>('');
  const [fileLogo, setFileLogo] = useState<File>();
  const [fileNameLogo, setFileNameLogo] = useState<string>('');
  const [headerImage, setHeaderImage] = useState<string>();
  const [logoImage, setLogoImage] = useState<string>();
  const [elecsumGreenQR, setElecsumGreenQR] = useState<string>('');
  const [selectedSize, setSelectedSize] = useState(ImageSize.small);
  const formsGroupGreenStampData: Array<FormGroupProps> = [
    {
      label: 'ID',
      type: 'text',
      id: 'id',
      path: 'id',
      name: 'id',
      placeholder: 'ID instal·lació',
      value: greenStamp.identificador,
      readOnly: true,
      className: 'input-grey',
    },
    {
      label: I18n.t('Comercial name'),
      type: 'string',
      id: 'comercialName',
      path: 'comercialName',
      name: 'comercialName',
      placeholder: '',
      value: greenStamp.comercialName,
    },
    {
      label: I18n.t('Telephone'),
      type: 'string',
      id: 'telephone',
      path: 'telephone',
      name: 'telephone',
      placeholder: '',
      value: greenStamp.telephone,
    },
    {
      label: I18n.t('Email'),
      type: 'string',
      id: 'email',
      path: 'email',
      name: 'email',
      placeholder: '',
      value: greenStamp.email,
    },
    {
      label: I18n.t('Web Page'),
      type: 'string',
      id: 'web',
      path: 'web',
      name: 'web',
      placeholder: '',
      value: greenStamp.web,
    },
  ];

  const formGroupsGreenStampDescription: Array<FormGroupProps> = [
    {
      label: I18n.t('Description'),
      type: 'editor',
      id: 'eGreenDescription',
      path: 'description',
      name: 'description',
      rows: 13,
      cols: 50,
      placeholder: I18n.t('Enter a description'),
      value: greenStamp.description,
      maxLenght: 2000,
    },
  ];

  const settingFileBackground = (file1: File, type?: string): void => {
    if (file1) {
      if (type === 'logo') {
        setFileLogo(file1);
        setFileNameLogo(file1.name);
      } else {
        setFile(file1);
        setFileName(file1.name);
      }
    }
  };

  const uploadImage = async (type: string): Promise<boolean> => {
    // setLoading(true);
    // setSubmitDisabled(true);
    // await of([]).pipe(delay(1000)).toPromise();
    // setLoading(false);
    if (type === 'logo') {
      setLoadingLogoImage(true);
    } else {
      setLoadingHeaderImage(true);
    }
    try {
      if (file) {
        await elecsumGreenServiceInstance.addGaleryImage(nif, file, type);
        customToast.success(I18n.t('Successfully added image'));
        if (afterSubmitAction) {
          afterSubmitAction();
        }
      } else if (fileLogo) {
        await elecsumGreenServiceInstance.addGaleryImage(nif, fileLogo, type);
        customToast.success(I18n.t('Successfully added image'));
        if (afterSubmitAction) {
          afterSubmitAction();
        }
      }
    } catch (error: any) {
      setLoading(false);
      setSubmitDisabled(false);
      switch (error.response.status) {
        case 400:
          toast.error(I18n.t('Too big image'));
          break;
        case 401:
          toast.error(I18n.t('Not authorized'));
          break;
        case 403:
          toast.error(I18n.t('Without access privileges'));
          break;
        case 404:
          toast.error(I18n.t('Nif not found on system'));
          break;

        default:
          break;
      }
    }
    if (type === 'logo') {
      setLoadingLogoImage(false);
      setFileLogo(undefined);
      setFileNameLogo('');
    } else {
      setLoadingHeaderImage(false);
      setFile(undefined);
      setFileName('');
    }

    return true;
  };

  const updateElecsumGreen = async (): Promise<boolean> => {
    try {
      await elecsumGreenServiceInstance.putElecsumGreen(nif, greenStamp);
    } catch (error) {
      toast.error(I18n.t('Error updating report!'));
    }
    return true;
  };

  const validateForm = (): boolean => {
    setSubmitDisabled(false);
    setErrors({});
    return true;
  };

  const submitForm = async (): Promise<boolean> => {
    setLoading(true);
    setSubmitDisabled(true);
    await of([]).pipe(delay(1000)).toPromise();
    updateElecsumGreen();
    setLoading(false);
    setSubmitDisabled(false);
    customToast.success('GreenStamp saved!');
    return true;
  };

  function handleChange<T>(path: string, value: T): void {
    // setGreenStamp(new ElecsumGreen().deserialize({
    //   ...greenStamp,
    //   [path]: value,
    // }));
    greenStamp.setAttr(path, value);

    validateForm();
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (validateForm()) {
      LoggingService.log('handleSubmit :: ok', { greenStamp });
      submitForm();
    } else {
      LoggingService.log('handleSubmit :: errors', { greenStamp, errors });
    }
  };

  // eslint-disable-next-line consistent-return
  const getElecsumGreenQR = async () => {
    try {
      const imageElecsumGreenQR = await elecsumGreenServiceInstance.getStampImage(
        selectedSize,
        greenStamp.identificador,
      );

      setElecsumGreenQR(imageElecsumGreenQR.config.url as string);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedSize(event.target.value as ImageSize);
  };

  const downloadImage = (elecsumGreenQR2: string) => {
    if (elecsumGreenQR2) {
      fetch(elecsumGreenQR2)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName || `elecsumGreenQRImage-${selectedSize}.png`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => console.error('Error downloading image:', error));
    } else {
      console.log('No image to download');
    }
  };

  useEffect(() => {
    const setImages = (): void => {
      // TODO: Set Header Image and Logo Image
      const imageLogo = greenStamp.images.filter((image) => image.tipo === 'logo');
      const imageHeader = greenStamp.images.filter((image) => image.tipo === 'cabecera');
      if (imageLogo.length > 0) {
        setLogoImage(imageLogo[0].url);
      } else {
        setLogoImage(undefined);
      }
      if (imageHeader.length > 0) {
        setHeaderImage(imageHeader[0].url);
      } else {
        setHeaderImage(undefined);
      }
    };
    setImages();

    setSubmitDisabled(!validateForm());
  }, [greenStamp]);

  useEffect(() => {
    getElecsumGreenQR();
  }, [selectedSize, greenStamp.identificador]);

  if (greenStamp.identificador === undefined) {
    return (
      <div className={styles.sync_loader}>
        <Spinner icon />
      </div>
    );
  }

  return (
    <SectionWrapper customClassname={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>
          <SectionTitle text={I18n.t('Elecsum green configuration')} />
        </div>
        <div className={styles.consulta}>
          <Button
            type="link"
            newtab
            href={`${configuration.elecsumGreenUrl}${greenStamp.identificador}`}
            variant="primary"
            text={I18n.t('query')}
          />
        </div>
      </div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.fields_container}>
          <div className={styles.fields_group}>
            <SelectUserType value={greenStamp.type} path="type" onChange={handleChange} />

            {formsGroupGreenStampData.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                placeholder={formGroup.placeholder}
                value={formGroup.value}
                required={formGroup.required}
                onChange={handleChange}
                readOnly={formGroup.readOnly}
                className={formGroup.className}
              />
            ))}
          </div>
          <div className={styles.fields_group}>
            <div className={styles['image-type']}>{I18n.t('Header background image')}</div>
            <div className={styles['image-uploader-wrapper']}>
              {headerImage ? (
                <div className={styles['image-fetched-wrapper']}>
                  <img src={headerImage} alt="" />
                </div>
              ) : null}
              <EunoiaImageInput
                id="img-cabecera"
                label={I18n.t('add company background image')}
                note={I18n.t('Must be 1440x120px *')}
                fileName={fileName}
                icon={<LandscapeIcon height={20} width={20} fill={Colors.COLOR_GRAY_400} />}
                type="cabecera"
                action={settingFileBackground}
                className="background-img-type"
              />
              {file && (
                <LoadingButton
                  className={styles.uploadImageBtn}
                  type="button"
                  text={I18n.t('Save')}
                  disabled={submitDisabled}
                  loading={loadingHeaderImage}
                  onClick={(): Promise<boolean> => uploadImage('cabecera')}
                />
              )}
            </div>
            <div className={styles['image-type']}>{I18n.t('Company Logo')}</div>
            <div className={styles['image-uploader-wrapper']}>
              {logoImage ? (
                <div className={styles['image-fetched-wrapper']}>
                  <img src={logoImage} alt="" />
                </div>
              ) : null}
              <EunoiaImageInput
                id="img-logo"
                label={I18n.t('add company logo')}
                note={I18n.t('130x74 px *')}
                fileName={fileNameLogo}
                icon={<LandscapeIcon height={20} width={20} fill={Colors.COLOR_GRAY_400} />}
                type="logo"
                action={settingFileBackground}
              />

              <div className={styles['image-elecsumGreenQr']}>
                <label>
                  {I18n.t('Elecsum green selecciona tamaño')}
                  <select value={selectedSize} onChange={handleSizeChange}>
                    <option value={ImageSize.small}>Small</option>
                    <option value={ImageSize.medium}>Medium</option>
                    <option value={ImageSize.large}>Large</option>
                  </select>

                  <LoadingButton type="button" text="Descargar" onClick={() => downloadImage(elecsumGreenQR)} />
                </label>

                {elecsumGreenQR ? (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <img src={elecsumGreenQR} alt="elecsumGreenQR" onClick={() => downloadImage(elecsumGreenQR)} />
                ) : (
                  <p>No image</p>
                )}
              </div>

              {fileLogo && (
                <LoadingButton
                  type="button"
                  text={I18n.t('Save')}
                  disabled={submitDisabled}
                  loading={loadingLogoImage}
                  onClick={(): Promise<boolean> => uploadImage('logo')}
                />
              )}
            </div>
          </div>
        </div>
        {/* <div className={styles.description}> */}
        {formGroupsGreenStampDescription.map((formGroup: FormGroupProps) => (
          <FormGroup
            key={formGroup.id}
            label={formGroup.label}
            type={formGroup.type}
            id={formGroup.id}
            path={formGroup.path}
            name={formGroup.name}
            value={formGroup.value}
            required={formGroup.required}
            placeholder={formGroup.placeholder}
            onChange={handleChange}
            onChangeEditor={handleChange}
            maxLenght={formGroup.maxLenght}
          />
        ))}
        {/* </div> */}

        <div className={styles.actions}>
          <LoadingButton type="submit" text={I18n.t('Save')} disabled={submitDisabled} loading={loading} />
        </div>
      </form>
      {/* <pre>{ JSON.stringify(greenStamp, null, 2) }</pre> */}
    </SectionWrapper>
  );
};

export default ElecsumGreenData;
