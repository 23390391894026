// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { NavItemI, I18n } from 'core';
import { ReactComponent as HomeIcon } from 'assets/icons/Home.svg';
import { ReactComponent as DocumentsIcon } from 'assets/icons/Documents.svg';

import { Colors } from 'styles';
import User from 'types/models/user';

const ICON_SIZE = 20;
const iconColor = Colors.COLOR_GRAY_100;
const activeIconColor = Colors.COLOR_ELECSUMGREEN;

const createRolPermisionNavigationLinks = (user: User | null): Array<NavItemI> => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  if (user && user.tipoDeUsuario !== 'admin') return [];
  const links: Array<NavItemI> = [
    {
      name: I18n.t('Sidenav.Tecnic.CUPS_Dashboard'),
      url: `/tecnic/`,

      icon: <HomeIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
      activeIcon: <HomeIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
    },
    {
      name: I18n.t('Sidenav.Tecnic.RolesPermisions'),
      url: `/tecnic/roles`,

      icon: <DocumentsIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
      activeIcon: <DocumentsIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
    },
  ];
  return links;
};

export default createRolPermisionNavigationLinks;
