import React, { useState } from 'react';
import { AdminCupsListItem } from 'types/models';
import cx from 'classnames';
import { navigate } from 'navigation';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/ArrowDown.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/ArrowUp.svg';
import { RoundedButton } from 'components/NewInputs';
import { I18n } from 'core';
import styles from './CupsListTableRow.module.scss';

interface CupsListTableRowPropsI {
  row: AdminCupsListItem;
  index: number;
}

interface LabeledValueItemPropsI {
  label: string;
  value: string;
}

const prettiefyDirection = (item: AdminCupsListItem): string =>
  `${item.domicilio} - ${item.poblacion} (${item.provincia})`;

const LabeledValueItem = (props: LabeledValueItemPropsI): JSX.Element => {
  const { label, value } = props;
  return (
    <div className={styles.labeled_value_item}>
      <p className={styles.label}>{label}</p>
      <p className={styles.value}>{value}</p>
    </div>
  );
};

const CupsListTableRow = (props: CupsListTableRowPropsI) => {
  const [expanded, setExpanded] = useState(false);
  const { row, index } = props;
  const { cups, nombre, apellidos, instalacion, cau } = row;

  return (
    <>
      <tr
        key={index}
        className={cx(styles.mainrow, { [styles.even]: index % 2 === 0 }, { [styles.odd]: index % 2 !== 0 })}
      >
        <td className={styles.collapse}>
          {instalacion?.id ? (
            <button
              type="button"
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              {expanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </button>
          ) : null}
        </td>
        <td className={styles.cups}>{cups}</td>
        <td>{`${nombre} ${apellidos}`}</td>
        <td>{prettiefyDirection(row)}</td>
        <td>
          <RoundedButton
            onClick={() => {
              navigate(`/tecnic/cups/${cups}/detail`);
            }}
          >
            {I18n.t('CUPSListPage.row.verDetalle')}
          </RoundedButton>
        </td>
      </tr>
      {expanded ? (
        <tr className={cx(styles.collapsedrow, { [styles.even]: index % 2 === 0 }, { [styles.odd]: index % 2 !== 0 })}>
          <td colSpan={1} />
          <td colSpan={3}>
            <div className={styles.content}>
              <div className={styles.info}>
                <LabeledValueItem label={I18n.t('CUPSListPage.row.autoconsumo')} value={instalacion ? 'Si' : 'No'} />
                <LabeledValueItem
                  label={I18n.t('CUPSListPage.row.mantenimiento')}
                  value={instalacion?.contratoMantenimiento ? 'Si' : 'No'}
                />
                <LabeledValueItem
                  label={I18n.t('CUPSListPage.row.garantia')}
                  value={instalacion?.garantia ? 'Si' : 'No'}
                />
                {instalacion?.garantia ? (
                  <LabeledValueItem
                    label={I18n.t('CUPSListPage.row.yearsGarantia')}
                    value={`${instalacion?.garantiaAños}`}
                  />
                ) : null}
                <RoundedButton
                  fit="stretch"
                  onClick={() => {
                    navigate(`/tecnic/installation/${instalacion?.id}/installation`);
                  }}
                  variant="ghost"
                >
                  {I18n.t('CUPSListPage.row.verInstalacion')}
                </RoundedButton>
              </div>
              <div className={styles.info}>
                <LabeledValueItem label="CAU" value={cau} />
              </div>
              <div className={styles.actions} />
            </div>
          </td>
          <td />
        </tr>
      ) : null}
    </>
  );
};

export default CupsListTableRow;
