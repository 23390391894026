import React from 'react';
import { NavItemI, I18n } from 'core';
import { Colors } from 'styles';
import { ReactComponent as DocumentsIcon } from 'assets/icons/Documents.svg';
import { ReactComponent as ChartsIcon } from 'assets/icons/Charts.svg';
import { useParams } from 'react-router-dom';

const ICON_SIZE = 20;
const iconColor = Colors.COLOR_GRAY_100;
const activeIconColor = Colors.COLOR_ELECSUMGREEN;

const createPromotorNavigationLinks = (): Array<NavItemI> => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { promotorID } = useParams<{ promotorID: string }>();
  const links: Array<NavItemI> = [
    {
      name: I18n.t('Sidenav.Client.Home'),
      url: promotorID ? `/promotor/${promotorID}/home` : '/promotor/home',
      icon: <DocumentsIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
      activeIcon: <DocumentsIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
      client: true,
    },
    {
      name: I18n.t('Sidenav.Client.Charts'),
      url: promotorID ? `/promotor/${promotorID}/charts` : '/promotor/charts',
      icon: <ChartsIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
      activeIcon: <ChartsIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
      client: true,
    },
  ];
  return links;
};

export default createPromotorNavigationLinks;
