import React, { useEffect, FormEvent, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { FormGroup, FormGroupProps, I18n } from 'core';

import { SectionTitle, LoadingButton } from 'components';

import { authServiceInstance } from 'services';

import { toast } from 'react-toastify';
import customToast from 'components/CustomToast/CustomToast';
import logoWhite from 'assets/images/logo-white.png';

import { navigate } from 'navigation';

import styles from './RecoverPasswordPage.module.scss';

const RecoverPasswordPage = (props: any): JSX.Element => {
  const {
    match: {
      params: { token },
    },
  } = props;
  const [newPassword, setNewPassword] = useState<string>('');
  const [newRePassword, setNewRePassword] = useState<string>('');
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);

  const formGroupRecoverPassword: Array<FormGroupProps> = [
    {
      label: I18n.t('New Password'),
      type: 'password',
      id: 'newPassword',
      path: 'newPassword',
      name: 'newPassword',
      value: newPassword,
      placeholder: I18n.t('min 8 characters'),
    },
    {
      label: I18n.t('Repeat New Password'),
      type: 'password',
      id: 'newRePassword',
      path: 'newRePassword',
      name: 'newRePassword',
      value: newRePassword,
      placeholder: I18n.t('min 8 characters'),
    },
  ];

  const handleChange = (path: string, value: string): void => {
    if (path === 'newPassword') {
      setNewPassword(value);
    } else if (path === 'newRePassword') {
      setNewRePassword(value);
    }
  };

  const handleRecoverPassword = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await authServiceInstance.recoverPassword(newPassword, token);
      if (response) {
        customToast.success(I18n.t('Password Changed'));
        setTimeout(() => {
          navigate('/auth/login/');
        }, 2000);
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(I18n.t('invalidToken'));
      } else if (error.response.status === 400) {
        toast.error(I18n.t('newPasswordAtLeast8'));
      } else {
        toast.error(I18n.t('errorRecoverPassword'));
      }
    }

    setNewPassword('');
    setNewRePassword('');
    setLoading(false);
  };

  useEffect(() => {
    const validateNewPasswords = (): boolean => {
      let validated = false;
      if (newPassword.trim().length >= 8 && newRePassword.trim().length >= 8) {
        if (newPassword.trim() === newRePassword.trim()) {
          validated = true;
        } else {
          validated = false;
        }
      } else {
        validated = false;
      }
      return validated;
    };
    const canSubmitForm = validateNewPasswords();
    setSubmitDisabled(!canSubmitForm);
  }, [newPassword, newRePassword]);

  return (
    <div className={styles.root}>
      <div className={styles.head}>
        <NavLink to="/auth/login">
          <img src={logoWhite} alt="" />
        </NavLink>
      </div>
      <div className={styles.form_container}>
        <div className={styles.form}>
          <SectionTitle text={I18n.t('Set New Password')} />
          <form onSubmit={handleRecoverPassword}>
            <div className={styles.passwords}>
              {formGroupRecoverPassword.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  placeholder={formGroup.placeholder}
                  value={formGroup.value}
                  required={formGroup.required}
                  readOnly={formGroup.readOnly}
                  onChange={handleChange}
                />
              ))}
            </div>
            <div className={styles.actions}>
              <LoadingButton
                type="submit"
                text={I18n.t('Change Password')}
                disabled={submitDisabled}
                loading={loading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RecoverPasswordPage;
