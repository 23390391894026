import { SectionTitle } from 'components';

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { FormGroup, FormGroupProps, I18n } from 'core';
import { ReactComponent as DocumentsIcon } from 'assets/icons/Documents.svg';

import { CommunityCustomOnboarding, Customizable } from 'types/models';
import { Colors } from 'styles';
import { LoggingService } from 'services';

import { SaveDataHandle } from '../../comunidades-custom-onboarding-process-page';
import styles from './comunidades-custom-onboarding-otros.module.scss';
// import { ColorPicker } from '../comunidades-color-picker/comunidades-color-picker';

interface ComunidadesCustomOnboardingOtrosProps {
  communityId: string;
  communityCustomOnboarding: CommunityCustomOnboarding;
  saveDetails: (customOnboarding: CommunityCustomOnboarding) => void;
}
const ComunidadesCustomOnboardingOtros = forwardRef<SaveDataHandle, ComunidadesCustomOnboardingOtrosProps>(
  (props: ComunidadesCustomOnboardingOtrosProps, ref) => {
    const { communityCustomOnboarding, saveDetails } = props;
    const [customOnboarding, setCustomOnboarding] = useState<CommunityCustomOnboarding>();
    const [errors, setErrors] = useState({});

    const customOnboardingData = JSON.parse(JSON.stringify(communityCustomOnboarding.serialize()));
    const validateForm = (): boolean => {
      setErrors({});
      return true;
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function handleCheckBox<T>(path: string, value: string, id: string, checked: boolean): void {
      communityCustomOnboarding.setAttr(path, checked);

      setCustomOnboarding(
        new CommunityCustomOnboarding().deserialize({
          ...customOnboardingData,
          [path]: checked ? Customizable.SI : Customizable.NO,
        }),
      );
    }

    const handleSubmit = (): void => {
      if (validateForm() && customOnboarding) {
        LoggingService.log('handleSubmit :: ok', { client: customOnboarding });
        saveDetails(customOnboarding);
      } else {
        LoggingService.log('handleSubmit :: errors', { client: customOnboarding, errors });
      }
    };

    const formFieldsCheckBoxes: Array<FormGroupProps> = [
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.forzarRevisionActualizarTarifa'),
        type: 'checkbox',
        id: 'forzarRevisionActualizarTarifa',
        path: 'forzarRevisionActualizarTarifa',
        name: 'forzarRevisionActualizarTarifa',
        checked: communityCustomOnboarding?.forzarRevisionActualizarTarifa,
        value: communityCustomOnboarding?.forzarRevisionActualizarTarifa,
      },
    ];

    useImperativeHandle(ref, () => ({
      getData: () => {
        handleSubmit();
      },
    }));

    return (
      <div className={styles.root}>
        <div className={styles.flex}>
          <div className={styles.titulo}>
            <DocumentsIcon height={20} width={20} fill={Colors.COLOR_BLACK} className={styles.DocumentsIcon} />
            <SectionTitle text={I18n.t('ComunidadesCustomOnboardingProcess.form.otrosTab')} />
          </div>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.containerForms}>
            <div className={styles.containerForm}>
              {formFieldsCheckBoxes.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  value={formGroup.value}
                  checked={formGroup.checked}
                  onChange={handleCheckBox}
                />
              ))}
            </div>
          </div>
        </form>
      </div>
    );
  },
);

export default ComunidadesCustomOnboardingOtros;
