import { Serializable } from 'types/interfaces';

interface LiniaOptimizacion {
  periodo: number;
  potenciaContratada: number;
  potenciaOptimizada: number;
}

export interface PowerOptimizationAPII {
  datos: {
    ahorroPorcentaje: number;
    ahorroImporte: number;
    potencias: Array<LiniaOptimizacion>;
  };
  habilitado: string;
}

export default class PowerOptimization implements Serializable<PowerOptimization> {
  datos: {
    ahorroPorcentaje: number;
    ahorroImporte: number;
    potencias: Array<LiniaOptimizacion>;
  };

  habilitado: string;

  deserialize(input: PowerOptimizationAPII): PowerOptimization {
    if (!input) {
      return this;
    }

    this.datos = {
      ahorroPorcentaje: input.datos.ahorroPorcentaje || 0,
      ahorroImporte: input.datos.ahorroImporte || 0,
      potencias:
        input.datos.potencias.map((line) => ({
          periodo: line.periodo,
          potenciaContratada: line.potenciaContratada || 0,
          potenciaOptimizada: line.potenciaOptimizada || 0,
        })) || [],
    };
    this.habilitado = input.habilitado || 'no';

    return this;
  }
}
