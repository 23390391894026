import UserInfo from 'types/models/userinfo';

import { USER_ACTIONS } from './types';

const fetchUser = () => ({ type: USER_ACTIONS.FETCH_USER });

const fetchUserSuccess = (payload: UserInfo) => ({
  type: USER_ACTIONS.FETCH_USER_OK,
  payload,
});

const fetchUserError = (payload: string) => ({
  type: USER_ACTIONS.FETCH_USER_ERROR,
  payload,
});

const resetUsers = () => ({
  type: USER_ACTIONS.RESET_STATE,
});

const fetchUserPermisos = () => ({ type: USER_ACTIONS.FETCH_PERMISOS });

export { fetchUser, fetchUserError, fetchUserSuccess, resetUsers, fetchUserPermisos };
