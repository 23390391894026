/* eslint-disable react/button-has-type */
import React, { useEffect, useRef, useState } from 'react';
import { RoundedButton } from 'components/NewInputs';
import I18n from 'i18n-js';
import Button from 'components/Button/Button';
import { navigate } from 'navigation';
import { useToggle } from 'hooks/useToogle';
import ModalMatcher from 'components/ModalMatcher/ModalMatcher';
import MatcherPerfilesConsumoInstance from 'services/remote/MatcherPerfilesConsumoService';
import customToast from 'components/CustomToast/CustomToast';
import { toast } from 'react-toastify';
import { PerfilConsumoAPI } from 'types/models/PerfilConsumo';
import stylesDropdown from './perfil-dropdown.module.scss';
import styles from './perfil-list-row.module.scss';

interface PerfilListRowProps {
  perfilConsumo: PerfilConsumoAPI;
  deleteOnChange: CallableFunction;
}

export const PerfilListRow = (props: PerfilListRowProps): JSX.Element => {
  const { perfilConsumo, deleteOnChange } = props;
  const { t } = I18n;
  const [isSwitchOn, toggleSwitch] = useToggle();
  const [loading, setLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: any) => {
    if (dropdownRef) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
  };
  const deletePerfilComunidad = async (idPerfilConsumo: any): Promise<void> => {
    // eslint-disable-next-line no-alert
    setLoading(true);
    try {
      await MatcherPerfilesConsumoInstance.deletePerfilDeConsumo(idPerfilConsumo);

      customToast.success(`${I18n.t('PerfilListPage.EliminarPerfilesConsumo_fetching')}!`);
    } catch (error) {
      toast.error(`${I18n.t('PerfilListPage.EliminarPerfilesConsumo_fetching_error')}.`);
      toggleSwitch();
    }
    setLoading(false);

    deleteOnChange('DELETE');
    toggleSwitch();
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <tr className={styles.mainrow} key={perfilConsumo.id}>
        <td>{perfilConsumo.nombre}</td>
        <td>{perfilConsumo.infoDeConsumo.año}</td>
        <td>
          <div className={stylesDropdown.dropdown} ref={dropdownRef}>
            <RoundedButton
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              {t(`Actions`)}
            </RoundedButton>

            {isOpen && (
              <div className={stylesDropdown.dropdownMenu}>
                <Button
                  className={styles.viewmore_button}
                  variant="tertiary"
                  type="button"
                  onClick={() => {
                    navigate(`/tecnic/matcher/preferencias/perfiles-consumo/${perfilConsumo.id}`);
                  }}
                  text={t(`PerfilListPage.actions.ver`)}
                />
                <Button
                  className={styles.viewmore_button}
                  variant="tertiary"
                  type="button"
                  onClick={() => {
                    setIsOpen(false);
                    toggleSwitch();
                  }}
                  text={t(`PerfilListPage.actions.delete`)}
                />
              </div>
            )}
          </div>
        </td>
      </tr>
      {isSwitchOn && (
        <ModalMatcher
          title={`${t('MatcherListPage.mensajeModalDelete')} ${perfilConsumo.nombre}`}
          onClick={() => deletePerfilComunidad(perfilConsumo.id)}
          testbutton={t('Confirmar')}
          loading={loading}
          onClick2={toggleSwitch}
        />
      )}
    </>
  );
};
