import Button from 'components/Button/Button';
import { I18n } from 'core';
import { navigate } from 'navigation';
import React from 'react';
import styles from './Error404Page.module.scss';

const P404Component = (): JSX.Element => (
  <div className={styles.root}>
    <h1>{I18n.t('NotFound404Page.title')}</h1>
    <h4>
      {I18n.t('NotFound404Page.match')} <code>{window.location.pathname}</code>
    </h4>
    <Button onClick={() => navigate('/')} type="button" text={I18n.t('NotFound404Page.home')} variant="primary" />
  </div>
);

export default P404Component;
