import { CommunityModel } from 'types/models/community';
import { COMMUNITIES_ACTIONS } from './types';

const fetchCommunity = () => ({
  type: COMMUNITIES_ACTIONS.FETCH_COMMUNITY,
});

const fetchCommunitySuccess = (payload: CommunityModel) => ({
  type: COMMUNITIES_ACTIONS.FETCH_COMMUNITY_OK,
  payload,
});

const resetCommunities = () => ({
  type: COMMUNITIES_ACTIONS.RESET_STATE,
});

export { fetchCommunity, fetchCommunitySuccess, resetCommunities };
