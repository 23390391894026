import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { FormGroup, FormGroupProps, I18n } from 'core';
import { navigate } from 'navigation';
import { Client } from 'types/models';
import { clientServiceInstance, LoggingService } from 'services';
import { ReactComponent as UserIcon } from 'assets/icons/User.svg';
import { ReactComponent as InstallationIcon } from 'assets/icons/Installation.svg';
import { SectionTitle, LoadingButton, Spinner } from 'components';
import { useLocation } from 'react-router-dom';

import customToast from 'components/CustomToast/CustomToast';
import Button from 'components/Button/Button';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';

import { Colors } from 'styles';
import { useDispatch } from 'react-redux';
import { fetchCUPS, fetchCUPSSuccess } from 'redux/actions/CupsActions';

import usersService from 'services/remote/UsersService';
import styles from './CUPSDetailPage.module.scss';

export interface ReportOptions {
  mensual: boolean;
  anual: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ClientDetail = (props: any): JSX.Element => {
  // Props
  const {
    match: {
      params: { cupsID },
    },
  } = props;
  // Hooks
  const dispatch = useDispatch();

  // State
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState({});
  const [client, setClient] = useState<Client>();
  const [hasUserRegistered, setHasUserRegistered] = useState<boolean>(false);
  // Effects
  const validateForm = (): boolean => {
    setSubmitDisabled(false);
    setErrors({});
    return true;
  };

  useEffect(() => {
    dispatch(fetchCUPS());
    const getClient = async (cups: string): Promise<void> => {
      clientServiceInstance.getClient(cups).then((response) => {
        response.setAttr('cups', cups);
        dispatch(fetchCUPSSuccess(response));
        setClient(response);
      });
    };

    getClient(cupsID);

    setSubmitDisabled(!validateForm());
  }, [cupsID]);

  useEffect(() => {
    if (!client) return;
    usersService
      .getUserById(client.nif)
      .then((response) => {
        if (response) {
          setHasUserRegistered(true);
        }
      })
      .catch(() => {
        setHasUserRegistered(false);
      });
  }, [client]);

  if (!client || client.nombre === undefined) {
    return (
      <div className={styles.nodata}>
        <Spinner icon />
      </div>
    );
  }
  const location = useLocation();

  const formGroupsClientData: Array<FormGroupProps> = [
    {
      label: I18n.t('Name'),
      type: 'text',
      id: 'name',
      path: 'name',
      name: 'name',
      value: client.nombre,
      readOnly: true,
      placeholder: '',
    },
    {
      label: I18n.t('Surname'),
      type: 'text',
      id: 'surname',
      path: 'surname',
      name: 'surname',
      value: client.apellidos,
      readOnly: true,
      placeholder: '',
    },
    {
      label: 'NIF',
      type: 'text',
      id: 'nif',
      path: 'nif',
      name: 'nif',
      value: client.nif,
      readOnly: true,
      placeholder: '',
    },
    {
      label: I18n.t('Address'),
      type: 'text',
      id: 'address',
      path: 'address',
      name: 'address',
      value: client.domicilio,
      readOnly: true,
      placeholder: '',
    },
    {
      label: I18n.t('Town'),
      type: 'text',
      id: 'town',
      path: 'town',
      name: 'town',
      value: client.poblacion,
      readOnly: true,
      placeholder: '',
    },
    {
      label: I18n.t('Province'),
      type: 'text',
      id: 'provincia',
      path: 'provincia',
      name: 'provincia',
      value: client.provincia,
      readOnly: true,
      placeholder: '',
    },
    {
      label: I18n.t('Telephone'),
      type: 'number',
      id: 'telefono',
      path: 'telefono',
      name: 'telefono',
      value: client.telefono,
      readOnly: true,
      placeholder: '',
    },
  ];

  const formGroupsInformesFotoVoltaicClient: Array<FormGroupProps> = [
    {
      label: I18n.t('CUPSDetailPage.labels.monthly'),
      type: 'checkbox',
      id: 'informeFotovoltaicoMensual',
      path: 'informeFotovoltaicoMensual',
      name: 'informeFotovoltaicoMensual',
      checked: client.informeFotovoltaicoMensual === 'si',
      value: client.informeFotovoltaicoMensual === 'si',
    },
    {
      label: I18n.t('CUPSDetailPage.labels.yearly'),
      type: 'checkbox',
      id: 'informeFotovoltaicoAnual',
      path: 'informeFotovoltaicoAnual',
      name: 'informeFotovoltaicoAnual',
      checked: client.informeFotovoltaicoAnual === 'si',
      value: client.informeFotovoltaicoAnual === 'si',
    },
  ];
  const formGroupsInformesCompletClient: Array<FormGroupProps> = [
    {
      label: I18n.t('CUPSDetailPage.labels.monthly'),
      type: 'checkbox',
      id: 'informeCompletoMasDesviacionMensual',
      path: 'informeCompletoMasDesviacionMensual',
      name: 'informeCompletoMasDesviacionMensual',
      checked: client.informeCompletoMasDesviacionMensual === 'si',
      value: client.informeCompletoMasDesviacionMensual === 'si',
    },
    {
      label: I18n.t('CUPSDetailPage.labels.yearly'),
      type: 'checkbox',
      id: 'informeCompletoMasDesviacionAnual',
      path: 'informeCompletoMasDesviacionAnual',
      name: 'informeCompletoMasDesviacionAnual',
      checked: client.informeCompletoMasDesviacionAnual === 'si',
      value: client.informeCompletoMasDesviacionAnual === 'si',
    },
  ];
  const formGroupsInformesGarantiaClient: Array<FormGroupProps> = [
    {
      label: I18n.t('CUPSDetailPage.labels.monthly'),
      type: 'checkbox',
      id: 'informeGarantiaDeProduccionMensual',
      path: 'informeGarantiaDeProduccionMensual',
      name: 'informeGarantiaDeProduccionMensual',
      checked: client.informeGarantiaDeProduccionMensual === 'si',
      value: client.informeGarantiaDeProduccionMensual === 'si',
    },
    {
      label: I18n.t('CUPSDetailPage.labels.yearly'),
      type: 'checkbox',
      id: 'informeGarantiaDeProduccionAnual',
      path: 'informeGarantiaDeProduccionAnual',
      name: 'informeGarantiaDeProduccionAnual',
      checked: client.informeGarantiaDeProduccionAnual === 'si',
      value: client.informeGarantiaDeProduccionAnual === 'si',
    },
  ];

  const formGroupsOthersSection: Array<FormGroupProps> = [
    {
      label: I18n.t('Instalation titularity percentage'),
      type: 'number',
      id: 'porcentajeTitularidadInstalacion',
      path: 'porcentajeTitularidadInstalacion',
      name: 'porcentajeTitularidadInstalacion',
      value: client.porcentajeTitularidadInstalacion,
      placeholder: '- %',
      readOnly: false,
    },
    {
      label: I18n.t('Coste acceso'),
      type: 'number',
      id: 'costeAcceso',
      path: 'costeAcceso',
      name: 'costeAcceso',
      step: 0.1,
      value: client.costeAcceso,
      placeholder: '',
      readOnly: false,
      required: false,
    },
  ];
  const submitReportChanges = async (clientToUpdate: Client): Promise<boolean> => {
    try {
      await clientServiceInstance.putClientReport(clientToUpdate);
      customToast.success(I18n.t('Client successfully updated'));
    } catch (error) {
      toast.error(I18n.t('Error updating report!'));
    }
    return true;
  };

  const submitForm = async (): Promise<boolean> => {
    setLoading(true);
    setSubmitDisabled(true);
    submitReportChanges(client);
    setLoading(false);
    setSubmitDisabled(false);
    return true;
  };

  function handleChange<T>(path: string, value: T): void {
    setClient(
      new Client().deserialize({
        ...client,
        [path]: value,
      }),
    );
    validateForm();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleCheckBox<T>(path: string, value: string, id: string, checked: boolean): Promise<unknown> {
    let NewValue: string;
    if (checked) {
      NewValue = 'si';
    } else {
      NewValue = 'no';
    }
    await setClient(
      new Client().deserialize({
        ...client,
        [path]: NewValue,
      }),
    );

    return true;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (validateForm()) {
      LoggingService.log('handleSubmit :: ok', { client });
      submitForm();
    } else {
      LoggingService.log('handleSubmit :: errors', { client, errors });
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {client.instalacionID > 0 ? (
          <Button
            variant="tertiary"
            type="button"
            onClick={(): Promise<void> => navigate(`/tecnic/installation/${client.instalacionID}/installation`)}
            icon={<InstallationIcon height={20} width={20} fill={Colors.COLOR_GENERACIO} />}
            text={I18n.t('CUPSDetailPage.viewInstallation')}
          />
        ) : null}
        {client.nif && hasUserRegistered && (
          <Button
            variant="tertiary"
            type="button"
            icon={<UserIcon height={20} width={20} fill={Colors.COLOR_EXCEDENTS} />}
            onClick={(): Promise<void> => navigate(`/tecnic/user/${client.nif}/data`)}
            text={I18n.t('CUPSDetailPage.viewUser')}
          />
        )}
      </div>
      <form className={styles.clientform} onSubmit={handleSubmit}>
        <div className={styles.fields_container}>
          <SectionWrapper>
            <div className={styles.fields_group}>
              <SectionTitle text={I18n.t('Data client')} />
              {formGroupsClientData.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  placeholder={formGroup.placeholder}
                  value={formGroup.value}
                  required={formGroup.required}
                  readOnly={formGroup.readOnly}
                  onChange={handleChange}
                />
              ))}
            </div>
          </SectionWrapper>
          <SectionWrapper>
            <div className={styles.fields_group}>
              <div className={styles.reports}>
                <SectionTitle text={I18n.t('Report client')} />
                <div className={styles.report_type}>
                  <div className={styles.type}>{I18n.t('Fotovoltaic FV')}</div>
                  <div className={styles.checkBoxes}>
                    {formGroupsInformesFotoVoltaicClient.map((formGroup: FormGroupProps) => (
                      <FormGroup
                        key={formGroup.id}
                        label={formGroup.label}
                        type={formGroup.type}
                        id={formGroup.id}
                        path={formGroup.path}
                        name={formGroup.name}
                        value={formGroup.value}
                        checked={formGroup.checked}
                        onChange={handleCheckBox}
                      />
                    ))}
                  </div>
                </div>
                <div className={styles.report_type}>
                  <div className={styles.type}>{I18n.t('Complete + deviation')}</div>
                  <div className={styles.checkBoxes}>
                    {formGroupsInformesCompletClient.map((formGroup: FormGroupProps) => (
                      <FormGroup
                        key={formGroup.id}
                        label={formGroup.label}
                        type={formGroup.type}
                        id={formGroup.id}
                        path={formGroup.path}
                        name={formGroup.name}
                        value={formGroup.value}
                        checked={formGroup.checked}
                        onChange={handleCheckBox}
                      />
                    ))}
                  </div>
                </div>
                <div className={styles.report_type}>
                  <div className={styles.type}>{I18n.t('Production guarantee')}</div>
                  <div className={styles.checkBoxes}>
                    {formGroupsInformesGarantiaClient.map((formGroup: FormGroupProps) => (
                      <FormGroup
                        key={formGroup.id}
                        label={formGroup.label}
                        type={formGroup.type}
                        id={formGroup.id}
                        path={formGroup.path}
                        name={formGroup.name}
                        value={formGroup.value}
                        checked={formGroup.checked}
                        onChange={handleCheckBox}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {formGroupsOthersSection.length > 0 ? (
              <div className={styles.fields_group}>
                <SectionTitle text={I18n.t('Others')} />
                {formGroupsOthersSection.map((formGroup: FormGroupProps) => (
                  <FormGroup
                    key={formGroup.id}
                    label={formGroup.label}
                    type={formGroup.type}
                    id={formGroup.id}
                    path={formGroup.path}
                    name={formGroup.name}
                    placeholder={formGroup.placeholder}
                    value={formGroup.value}
                    min={formGroup.min}
                    required={formGroup.required}
                    readOnly={formGroup.readOnly}
                    onChange={handleChange}
                  />
                ))}
              </div>
            ) : null}
          </SectionWrapper>
        </div>
        <div className={styles.actions}>
          <Button
            type="button"
            onClick={
              location.pathname.includes('/tecnic/cups/')
                ? (): Promise<void> => navigate('/tecnic/cups')
                : (): Promise<void> => navigate('/data/clients')
            }
            variant="secondary"
            text={I18n.t('Cancel')}
          />

          <LoadingButton type="submit" text={I18n.t('Save')} disabled={submitDisabled} loading={loading} />
        </div>
      </form>
    </div>
  );
};

export default ClientDetail;
