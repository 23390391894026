import React, { useState } from 'react';
import { I18n } from 'core';

import { VictoryChart, VictoryAxis, VictoryGroup, VictoryBar, VictoryLabel, VictoryContainer } from 'victory';
import { Colors } from 'styles';
import { ChartDataI, ProdEnergyProductionDataTotalMonthly } from 'types/models';

import { ViewDate, ChartDataList } from 'components';

import wattsConverterService from 'services/local/WattsConverter';
import { SizeMe } from 'react-sizeme';
import Constants from 'config/constants';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import styles from './ProductionChartsCommon.module.scss';

interface DateType {
  day: number;
  month: number;
  year: number;
}

interface ProductionEnergyChartI {
  generationData: Array<ChartDataI>;
  guaranteeData: Array<ChartDataI>;
  estimateData: Array<ChartDataI>;
  date: DateType;
  currentTab: string;
  monthlyData: Array<ProdEnergyProductionDataTotalMonthly>;
  radius: number;
  isLoading?: boolean;
}

const guaranteeBarStyle = {
  NotSelected: Colors.COLOR_ELECSUMGREEN_NOT_SELECTED,
  Selected: Colors.COLOR_ELECSUMGREEN,
};

const generationBarStyle = {
  NotSelected: Colors.COLOR_GENERACIO_NOT_SELECTED,
  Selected: Colors.COLOR_GENERACIO,
};

const estimateBarStyle = {
  NotSelected: Colors.COLOR_CONSUM_NOT_SELECTED,
  Selected: Colors.COLOR_GRAY_500,
};

const MONTHS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

const ProductionEnergyChart = (props: ProductionEnergyChartI): JSX.Element => {
  const { generationData, guaranteeData, estimateData, date, currentTab, monthlyData, radius, isLoading } = props;
  const TOTAL_TICKS = 5;

  const [selectedBar, setSelectedBar] = useState<{
    productionGuarantee: ChartDataI;
    generation: ChartDataI;
    estimate: ChartDataI;
  }>({
    productionGuarantee: guaranteeData[0],
    generation: generationData[0],
    estimate: estimateData[0],
  });

  const [selectArrayElement, setSelectArrayElement] = useState(0);

  const listItemsArray = [
    {
      color: 'bck-orange',
      name: I18n.t('generation'),
      data: monthlyData[selectArrayElement].generation,
    },
    {
      color: 'bck-green',
      name: I18n.t('productionGuarantee'),
      data: monthlyData[selectArrayElement].guarantee,
    },
    {
      color: '',
      name: I18n.t('diffGuarantee'),
      data: monthlyData[selectArrayElement].diffGuarantee,
    },
    {
      color: 'bck-dark-grey',
      name: I18n.t('estimateProject'),
      data: monthlyData[selectArrayElement].estimate,
    },
    {
      color: '',
      textColor: 'text-red',
      name: `${I18n.t('differenceInst')} ${radius}km`,
      data: monthlyData[selectArrayElement].diffGeneration,
    },
  ];

  if (isLoading) {
    return <FullPageSyncLoader />;
  }

  return (
    <div className={styles.root}>
      <SizeMe>
        {({ size }) => (
          <div>
            <svg viewBox={`0 0 ${size.width} 400`} height="100%" width="100%">
              <VictoryChart
                width={size.width || 500}
                height={400}
                standalone={false}
                domainPadding={{ x: 0, y: 10 }}
                containerComponent={<VictoryContainer responsive={false} />}
              >
                <VictoryLabel
                  style={{ fontSize: Constants.charts.AXIS_LABELS_FONTSIZE }}
                  text="kWh"
                  x={30}
                  y={30}
                  textAnchor="middle"
                />
                <VictoryAxis
                  style={{
                    axis: { stroke: Colors.COLOR_WHITE },
                    tickLabels: {
                      fontSize: Constants.charts.AXIS_VALUES_FONTSIZE,
                      fill: Colors.COLOR_CONSUM_NOT_SELECTED,
                    },
                  }}
                  domain={[1, 12]}
                  tickFormat={(tick): string => `${I18n.t(MONTHS[parseInt(tick, 10) - 1])}`}
                  offsetY={45}
                />
                <VictoryAxis
                  dependentAxis
                  style={{
                    axis: { stroke: Colors.COLOR_WHITE },
                    tickLabels: {
                      fontSize: Constants.charts.AXIS_VALUES_FONTSIZE,
                      fill: Colors.COLOR_CONSUM_NOT_SELECTED,
                    },
                  }}
                  tickCount={TOTAL_TICKS}
                  // tickValues={tickValuesY}
                  tickFormat={(tick): number => wattsConverterService.convertWattsToKwatts(tick)}
                  offsetX={45}
                />
                <VictoryGroup
                  colorScale={[Colors.COLOR_GENERACIO, Colors.COLOR_ELECSUMGREEN, Colors.COLOR_GRAY_500]}
                  offset={5}
                >
                  <VictoryBar
                    barRatio={Constants.charts.BAR_RATIO_FILL}
                    cornerRadius={{ top: Constants.charts.BAR_CORNER_RADIUS }}
                    data={generationData}
                    style={{
                      data: {
                        fill: ({ datum }) => {
                          if (datum.x === selectedBar.productionGuarantee.x) {
                            return generationBarStyle.Selected;
                          }
                          return generationBarStyle.NotSelected;
                        },
                      },
                    }}
                    events={[
                      {
                        target: 'data',
                        eventHandlers: {
                          onClick: () => [
                            {
                              // TODO tipar la funció
                              target: 'data',
                              mutation: (value: { datum: ChartDataI }): void => {
                                setSelectedBar({
                                  productionGuarantee: guaranteeData[value.datum.x - 1],
                                  generation: generationData[value.datum.x - 1],
                                  estimate: estimateData[value.datum.x - 1],
                                });
                                setSelectArrayElement(value.datum.x - 1);
                              },
                            },
                          ],
                        },
                      },
                    ]}
                  />
                  <VictoryBar
                    barRatio={Constants.charts.BAR_RATIO_FILL}
                    cornerRadius={{ top: Constants.charts.BAR_CORNER_RADIUS }}
                    data={guaranteeData}
                    // x="x"
                    style={{
                      data: {
                        fill: ({ datum }) => {
                          if (datum.x === selectedBar.productionGuarantee.x) {
                            return guaranteeBarStyle.Selected;
                          }

                          return guaranteeBarStyle.NotSelected;
                        },
                      },
                    }}
                    events={[
                      {
                        target: 'data',
                        eventHandlers: {
                          onClick: () => [
                            {
                              // TODO tipar la funció
                              target: 'data',
                              mutation: (value: { datum: ChartDataI }): void => {
                                setSelectedBar({
                                  productionGuarantee: guaranteeData[value.datum.x - 1],
                                  generation: generationData[value.datum.x - 1],
                                  estimate: estimateData[value.datum.x - 1],
                                });
                                setSelectArrayElement(value.datum.x - 1);
                              },
                            },
                          ],
                        },
                      },
                    ]}
                  />
                  <VictoryBar
                    barRatio={Constants.charts.BAR_RATIO_FILL}
                    cornerRadius={{ top: Constants.charts.BAR_CORNER_RADIUS }}
                    data={estimateData}
                    // x="x"
                    style={{
                      data: {
                        fill: ({ datum }) => {
                          if (datum.x === selectedBar.productionGuarantee.x) {
                            return estimateBarStyle.Selected;
                          }

                          return estimateBarStyle.NotSelected;
                        },
                      },
                    }}
                    events={[
                      {
                        target: 'data',
                        eventHandlers: {
                          onClick: () => [
                            {
                              // TODO tipar la funció
                              target: 'data',
                              mutation: (value: { datum: ChartDataI }): void => {
                                setSelectedBar({
                                  productionGuarantee: guaranteeData[value.datum.x - 1],
                                  generation: generationData[value.datum.x - 1],
                                  estimate: estimateData[value.datum.x - 1],
                                });
                                setSelectArrayElement(value.datum.x - 1);
                              },
                            },
                          ],
                        },
                      },
                    ]}
                  />
                </VictoryGroup>
              </VictoryChart>
            </svg>
          </div>
        )}
      </SizeMe>
      {listItemsArray.length > 0 ? (
        <div className={styles.data_footer}>
          <ViewDate date={date} currentTab={currentTab} currentMonthSelected={selectArrayElement} />
          <div className={styles.data_footer_list}>
            {listItemsArray.map(
              (item): JSX.Element => (
                <ChartDataList
                  key={item.name}
                  color={item.color}
                  textColor={item.textColor}
                  name={item.name}
                  data={item.data}
                />
              ),
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProductionEnergyChart;
