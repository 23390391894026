import configuration from 'config';
import { TeledetectionData } from 'types/models';
import { AxiosResponse } from 'axios';
import apiService from './ApiService';
import TeledectionData from '../../types/models/teledetectionData';

export const dataFrequencies = {
  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year',
  HISTORIC: 'historic',
};

class TeledetectionService {
  private readonly http = apiService;

  private apiUrl = configuration.apiUrl;

  public async getFechaUltimosDatos(cups: string) {
    return this.http.get(`${this.apiUrl}/fv/teledeteccion/${cups}/fecha-ultimos-datos/`);
  }

  public async getTeledetectionData(
    freq: string,
    cups: string,
    year?: number,
    month?: number,
    day?: number,
  ): Promise<TeledetectionData> {
    let teledetectionData = new TeledectionData();
    if (dataFrequencies.MONTH === freq && year && month) {
      teledetectionData = await this.getTeledetectionMonthData(cups, year, month);
    }

    if (dataFrequencies.YEAR === freq && year) {
      teledetectionData = await this.getTeledetectionYearData(cups, year);
    }

    if (dataFrequencies.HISTORIC === freq) {
      teledetectionData = await this.getTeledetectionHistoricData(cups);
    }
    if (dataFrequencies.DAY === freq && year && month && day) {
      teledetectionData = await this.getTeledetectionDayData(cups, year, month, day);
    }
    return teledetectionData;
  }

  private async getTeledetectionDayData(
    cups: string,
    year: number,
    month: number,
    day: number,
  ): Promise<TeledetectionData> {
    const response: AxiosResponse = await this.http.get(
      `${this.apiUrl}/fv/teledeteccion/diaria/${cups}/${year}/${month}/${day}/`,
    );

    return new TeledetectionData().deserialize(response.data.datos);
  }

  private async getTeledetectionMonthData(cups: string, year: number, month: number): Promise<TeledetectionData> {
    const response: AxiosResponse = await this.http.get(
      `${this.apiUrl}/fv/teledeteccion/mensual/${cups}/${year}/${month}/`,
    );
    return new TeledetectionData().deserialize(response.data.datos);
  }

  private async getTeledetectionYearData(cups: string, year: number): Promise<TeledetectionData> {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/teledeteccion/anual/${cups}/${year}/`);

    return new TeledetectionData().deserialize(response.data.datos);
  }

  private async getTeledetectionHistoricData(cups: string): Promise<TeledetectionData> {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/teledeteccion/historica/${cups}/`);

    return new TeledetectionData().deserialize(response.data.datos);
  }
}

const teledetectionService: TeledetectionService = new TeledetectionService();

export default teledetectionService;
