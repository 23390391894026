import ImageType from 'types/enums/ImageTypes';
import { Serializable } from 'types/interfaces';
import { TranslatableJSON } from 'types/interfaces/polyglot';

export enum Customizable {
  SI = 'si',
  NO = 'no',
}
export const VALORDEFECTOES = 'VALOR EN ESP';
export const VALORDEFECTOCA = 'VALOR EN CA';

export const CUSTOMIZABLE_VALUES = Object.values(Customizable);

export type CustomizableType = typeof CUSTOMIZABLE_VALUES[number];

export enum CustomizableImageType {
  LOGO = 'logo',
  IMAGE_STEP_01 = 'imagen-paso-01',
  IMAGE_STEP_02 = 'imagen-paso-02',
  IMAGE_STEP_03 = 'imagen-paso-03',
  IMAGE_STEP_04 = 'imagen-paso-04',
  IMAGE_STEP_05 = 'imagen-paso-05',
}

export const CUSTOMIZABLE_IMAGE_TYPE_VALUES = Object.values(CustomizableImageType);

export type CustomizableImageTypeType = typeof CUSTOMIZABLE_IMAGE_TYPE_VALUES[number];

export interface CustomizableImage {
  id: string;
  tipo: CustomizableImageType;
  url: string;
  mostrar: ImageType;
}
export interface FaqsApi {
  id: number;
  pregunta: string;
  respuesta: string;
  idioma: string;
}
export interface PromotorCustomOnboardingAPII {
  nombreDominio: string;

  colorPrimario: string;

  colorSecundario: string;

  logos: CustomizableImage[];

  personalizacionPromotor: {
    mensajeFinalOnboarding: {
      es: string;
      ca: string;
    };
    politicaDePrivacidad: {
      es: string;
      ca: string;
    };
    avisoLegalWeb: {
      es: string;
      ca: string;
    };
  };
}

export const translatableMockString: TranslatableJSON<string> = {
  es: '',
  ca: '',
};
export default class PromotorCustomOnboarding implements Serializable<PromotorCustomOnboarding> {
  nombreDominio: string;

  colorPrimario: string;

  colorSecundario: string;

  logos: CustomizableImage[];

  personalizacionPromotor: {
    mensajeFinalOnboarding: {
      es: string;
      ca: string;
    };
    politicaDePrivacidad: {
      es: string;
      ca: string;
    };
    avisoLegalWeb: {
      es: string;
      ca: string;
    };
  };

  deserialize(input: PromotorCustomOnboardingAPII): PromotorCustomOnboarding {
    if (!input) {
      return this;
    }
    this.nombreDominio = input.nombreDominio;
    this.colorPrimario = input.colorPrimario;
    this.colorSecundario = input.colorSecundario;

    this.logos = input.logos || [];
    // this.faqs = input.faqs || [];

    // Deserializar la nueva propiedad "personalizacionOnboarding"
    this.personalizacionPromotor = {
      mensajeFinalOnboarding: {
        es: input.personalizacionPromotor?.mensajeFinalOnboarding?.es || '',
        ca: input.personalizacionPromotor?.mensajeFinalOnboarding?.ca || '',
      },
      politicaDePrivacidad: {
        es: input.personalizacionPromotor?.politicaDePrivacidad?.es || '',
        ca: input.personalizacionPromotor?.politicaDePrivacidad?.ca || '',
      },
      avisoLegalWeb: {
        es: input.personalizacionPromotor?.avisoLegalWeb?.es || '',
        ca: input.personalizacionPromotor?.avisoLegalWeb?.ca || '',
      },
    };

    return this;
  }

  serialize(): PromotorCustomOnboardingAPII {
    return {
      nombreDominio: this.nombreDominio,
      colorPrimario: this.colorPrimario,
      colorSecundario: this.colorSecundario,
      logos: this.logos,

      // Deserializar la nueva propiedad "personalizacionOnboarding"
      personalizacionPromotor: {
        mensajeFinalOnboarding: {
          es: this.personalizacionPromotor.mensajeFinalOnboarding.es || '',
          ca: this.personalizacionPromotor.mensajeFinalOnboarding.ca || '',
        },
        politicaDePrivacidad: {
          es: this.personalizacionPromotor?.politicaDePrivacidad.es || '',
          ca: this.personalizacionPromotor?.politicaDePrivacidad.ca || '',
        },

        avisoLegalWeb: {
          es: this.personalizacionPromotor?.avisoLegalWeb.es || '',
          ca: this.personalizacionPromotor?.avisoLegalWeb.ca || '',
        },
      },
    } as PromotorCustomOnboardingAPII;
  }

  setAttr<T>(key: string, value: T): void {
    Object.assign(this, { [key]: value });
  }
}
