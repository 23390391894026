/* eslint-disable no-return-assign */
import React, { useRef } from 'react';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { I18n } from 'core';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import { RolInfo } from 'types/models';
import { RoundedButton } from 'components/NewInputs';
import { navigate } from 'navigation';
import stylesDropdown from './roles-dropdown.module.scss';
import styles from '../RolesUserPage.module.scss';

interface RolesDataSectionProps {
  rolData: RolInfo[]; // Define the rolData prop
  onDeleteRole: (id: number) => void; // Define onDeleteRole prop
  submitDisabled: boolean; // Define submitDisabled prop
  loading: boolean; // Define loading prop
  fetchData: Function;
}

function RolesDataSection({
  rolData,
}: // onDeleteRole, loading, fetchData
RolesDataSectionProps) {
  const dropdownRefs = useRef<(HTMLElement | null)[]>([]);

  return (
    <SectionWrapper customClassname={styles.table_wrapper}>
      <SectionTitle text={I18n.t('RolDetailPage.RolData.perfiles')} />
      <div>
        <table>
          <thead>
            <tr>
              <th>{I18n.t('RolDetailPage.RolData.labels.name')}</th>
            </tr>
          </thead>
          <tbody>
            {rolData && rolData.length > 0 ? (
              rolData.map((disp: RolInfo) => (
                <tr key={disp.id}>
                  <td>{disp.nombre}</td>
                  <td>
                    <div className={stylesDropdown.dropdown} ref={(ref) => (dropdownRefs.current[disp.id] = ref)}>
                      <RoundedButton
                        onClick={() => {
                          navigate(`/tecnic/roles/${disp.id}/`);
                        }}
                      >
                        {I18n.t(`RolDetailPage.RolData.ver`)}
                      </RoundedButton>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className={styles.Sinboder}>
                <td className={styles.nodata}>{I18n.t('UsuarioDetailPage.DevicesData.noData')}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </SectionWrapper>
  );
}

export default RolesDataSection;
