import { Installation } from 'types/models';
import React from 'react';
import { I18n } from 'core';
import styles from './InstallationListTable.module.scss';
import { InstallationListTableRow } from '..';

interface InstallationListTablePropsI {
  data: Array<Installation>;
}

const InstallationListTable = (props: InstallationListTablePropsI): JSX.Element => {
  const { data } = props;

  return (
    <table className={styles.table_wrapper}>
      <thead>
        <tr>
          <th> {I18n.t('InstallationListPage.headers.CAU')} </th>
          <th> {I18n.t('InstallationListPage.headers.Nombre')} </th>
          <th> {I18n.t('InstallationListPage.headers.Domicilio')} </th>
          <th aria-label="actions" />
        </tr>
      </thead>

      <tbody>
        {data.length > 0 ? (
          data.map((row, index) => <InstallationListTableRow row={row} index={index} />)
        ) : (
          <tr>
            <td className={styles.nodata} colSpan={4}>
              {I18n.t('CUPSListPage.error.noData')}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default InstallationListTable;
