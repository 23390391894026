import { INSTALLATIONS_ACTIONS } from 'redux/actions/types';
import { ProcessStatus } from 'types/enums';
import { InstallationsState } from 'types/state';

const initialState: InstallationsState = {
  selectedInstallation: null,
  selectedInstallationStatus: ProcessStatus.STANDBY,
};

function InstallationsReducer(state = initialState, action: any): InstallationsState {
  switch (action.type) {
    case INSTALLATIONS_ACTIONS.FETCH_INSTALLATION:
      return {
        ...state,
        selectedInstallation: null,
        selectedInstallationStatus: ProcessStatus.LOADING,
      };

    case INSTALLATIONS_ACTIONS.FETCH_INSTALLATION_OK: {
      const { payload } = action;
      return {
        ...state,
        selectedInstallationStatus: ProcessStatus.SUCCESS,
        selectedInstallation: payload,
      };
    }
    case INSTALLATIONS_ACTIONS.FETCH_INSTALLATION_ERROR: {
      return {
        ...state,
        selectedInstallation: null,
        selectedInstallationStatus: ProcessStatus.ERROR,
      };
    }

    case INSTALLATIONS_ACTIONS.RESET_STATE: {
      return initialState;
    }

    default:
      return { ...state };
  }
}

export default InstallationsReducer;
