import React from 'react';

import { Footer } from 'components';
import { RoutesInterface } from 'types/interfaces';
import styles from './ErrorLayout.module.scss';

const ErrorLayout = (props: RoutesInterface): JSX.Element => {
  const { component: Component } = props;

  return (
    <div className={styles.root}>
      <div className={styles.errorContainer}>{Component ? <Component {...props} /> : null}</div>
      <Footer />
    </div>
  );
};
export default ErrorLayout;
