import React, { useEffect, useState } from 'react';
import { SectionTitle, SectionSubTitle, LoadingButton, Spinner } from 'components';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { I18n } from 'core';
import MatcherInformeInstance from 'services/remote/MatcherInformeService';
import MatcherInforme from 'types/models/MatcherInforme';
import { WattsConverterService } from 'services';
import PercentageFormatter from 'services/local/PercentageFormatter';
import configuration from 'config';
import TableMatcher from '../Table';
import MiembroRow from './MiembroRow';
import GlobalSectionInforme from './GlobalSectionInforme';
import styles from './informe.module.scss';
import ListaCombinacionesRow from './ListaCombinacionesRow';
import MiembroRowExcluido from './MiembroRowExcluido';

interface InformeMatcherProps {
  configuracionId: number;
}

const InformeMatcher = ({ configuracionId }: InformeMatcherProps): JSX.Element => {
  const [estadoInforme, setEstadoInforme] = useState('');
  const [informe, setInforme] = useState<MatcherInforme>();
  const [loadingExportInforme, setLoadingExportInforme] = useState<boolean>(false);
  const [loadingExportListaDistribucion, setLoadingExportListaDistribucion] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingGeneralTest, setLoadingGeneralTest] = useState<boolean>(false);

  const [mensajeErrorInforme, setMensajeErrorInforme] = useState({
    codigo: '',
    mensaje: '',
  });

  const obtenerInforme = async () => {
    setLoadingGeneralTest(true);
    try {
      const getInforme = await MatcherInformeInstance.getInforme(configuracionId);
      setInforme(getInforme);
    } catch {
      setEstadoInforme('ENEJECUCION');
      setMensajeErrorInforme({ codigo: '', mensaje: '' });
    }
    setLoadingGeneralTest(false);
  };

  const obtenerEstadoInforme = async () => {
    setLoadingGeneralTest(true);

    const estado = await MatcherInformeInstance.estadoInforme(configuracionId);
    setEstadoInforme(estado);

    if (estadoInforme === 'COMPLETADO') {
      obtenerInforme();
      setMensajeErrorInforme({ codigo: '', mensaje: '' });
    }
    if (estadoInforme === 'COMPLETADOCONERRORES') {
      obtenerInforme();
    }
    setMensajeErrorInforme({ codigo: '', mensaje: '' });

    setLoadingGeneralTest(false);
  };

  useEffect(() => {
    obtenerEstadoInforme();
  }, [estadoInforme]);

  useEffect(() => {
    let interval: any;

    if (estadoInforme !== 'COMPLETADO') {
      interval = setInterval(() => {
        obtenerEstadoInforme();
      }, 6000);

      return () => clearInterval(interval);
    }
    return interval;
  }, [estadoInforme]);

  const submitForm = async (): Promise<void> => {
    setLoading(true);
    setMensajeErrorInforme({ codigo: '', mensaje: '' });

    try {
      const ejecutarInforme = await MatcherInformeInstance.ejecutarInforme(configuracionId);
      if (ejecutarInforme === 204) {
        obtenerEstadoInforme();
        setMensajeErrorInforme({ codigo: '', mensaje: '' });
      } else {
        setMensajeErrorInforme(ejecutarInforme);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const exportInforme = async (): Promise<void> => {
    setLoadingExportInforme(true);

    try {
      const ejecutarInforme = await MatcherInformeInstance.descargarInforme(configuracionId);
      const a = document.createElement('a');
      a.href = `data:text/csv;charset=utf-8,${ejecutarInforme}`;
      a.textContent = 'download';
      a.download = `${new Date().toLocaleDateString()}_ExportData.csv`;
      a.click();
      a.remove();
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingExportInforme(false);
    }
  };

  const exportListaDistribucion = async (): Promise<void> => {
    setLoadingExportListaDistribucion(true);

    try {
      const ejecutarInforme = await MatcherInformeInstance.descargarListaDistribucion(configuracionId);
      const a = document.createElement('a');
      a.href = `data:text/csv;charset=utf-8,${ejecutarInforme}`;
      a.textContent = 'download';
      a.download = `${new Date().toLocaleDateString()}_lista_de_distribucion_configuracion_id_${configuracionId}.txt`;
      a.click();
      a.remove();
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingExportListaDistribucion(false);
    }
  };
  const getBetaColumns = (metodoDeCalculosProp: string) => {
    if (metodoDeCalculosProp === 'Betas fijas') {
      return [I18n.t('MatcherListPage.listaCombinacion.BetaFija')];
    }
    if (metodoDeCalculosProp === 'Betas fijas 3 tipos') {
      return ['BetaL', 'BetaF ', 'BetaA'];
    }
    return [];
  };
  // MOCK HEADERS
  // Actualizar el headerMatcherHeader con las columnas Beta según el método de cálculo
  const headerMatcherHeader = [
    I18n.t('MatcherListPage.addMatcher.name'),
    I18n.t('MatcherListPage.addMatcher.apellidos'),
    ...(configuration.matcher2 && informe?.metodoDeCalculos ? getBetaColumns(informe.metodoDeCalculos) : []), // Condición para añadir columnas
    I18n.t('MatcherListPage.listaCombinacion.autoconsumoEuro'),
    I18n.t('MatcherListPage.listaCombinacion.excedenteEuro'),
    I18n.t('MatcherListPage.listaCombinacion.ahorrosEuro'),
  ];

  const headerMatcherHeaderExcluido = [
    I18n.t('MatcherListPage.addMatcher.name'),
    I18n.t('MatcherListPage.addMatcher.apellidos'),
  ];

  const ListadeMiembroHeadier = [
    I18n.t('MatcherListPage.listaCombinacion.numeroMiembros'),
    I18n.t('MatcherListPage.listaCombinacion.autoconsumoEuro'),
    I18n.t('MatcherListPage.listaCombinacion.excedenteEuro'),
    I18n.t('MatcherListPage.listaCombinacion.ahorrosEuro'),
    I18n.t('MatcherListPage.listaCombinacion.autoconsumoPorcentaje'),
    I18n.t('MatcherListPage.listaCombinacion.excedentePorcentaje'),
    I18n.t('MatcherListPage.listaCombinacion.esLaOptima'),
  ];
  /// MOCK INFORME GLOBAL
  const resultInformeGlobal = [
    { nombre: 'numeroDeMiembrosSelecionados', value: informe?.numeroDeMiembrosSelecionados || 0 },
    { nombre: 'numeroDeMiembrosAceptados', value: informe?.numeroDeMiembrosAceptados || 0 },
    {
      nombre: 'generacion',
      value:
        `${informe?.generacion ? WattsConverterService.convertWatts(informe?.generacion).num : 0} ${
          informe?.generacion ? WattsConverterService.convertWatts(informe?.generacion).unitHour : ''
        }` || 0,
    },
    {
      nombre: 'energia',
      value:
        `${informe?.consumo ? WattsConverterService.convertWatts(informe?.consumo).num : 0} ${
          informe?.consumo ? WattsConverterService.convertWatts(informe?.consumo).unitHour : ''
        }` || 0,
    },
    {
      nombre: 'energiaLimpia',
      value:
        `${
          informe?.consumo && informe?.autoConsumo
            ? WattsConverterService.convertWatts(informe.consumo - informe.autoConsumo).num
            : 0
        } ${
          informe?.consumo && informe?.autoConsumo
            ? WattsConverterService.convertWatts(informe.consumo - informe.autoConsumo).unitHour
            : ''
        }` || 0,
    },

    {
      nombre: 'autoConsumo',
      value: `${informe?.autoConsumo ? WattsConverterService.convertWatts(informe?.autoConsumo).num : 0} mWh`,
    },
    {
      nombre: 'excedente',
      value:
        `${informe?.excedente ? WattsConverterService.convertWatts(informe?.excedente).num : 0} ${
          informe?.excedente ? WattsConverterService.convertWatts(informe?.excedente).unitHour : ''
        }` || 0,
    },
    {
      nombre: 'autoConsumoEuro',
      value: `${informe?.autoConsumoEuro ? informe?.autoConsumoEuro.toFixed(2) : 0} €`,
    },
    {
      nombre: 'excedenteEuro',
      value: `${informe?.excedentelEuro ? informe?.excedentelEuro.toFixed(2) : 0} €`,
    },
    {
      nombre: 'ahorrosEuro',
      value: `${informe?.ahorrosEuro ? informe?.ahorrosEuro.toFixed(2) : 0} €`,
    },
    {
      nombre: 'autoconsumoPorcentaje',
      value: `${
        informe?.autoconsumoPorcentaje ? PercentageFormatter.formatValue(informe?.autoconsumoPorcentaje.toString()) : 0
      } %`,
    },
    {
      nombre: 'excedentePorcentaje',
      value: `${
        informe?.excedentePorcentaje ? PercentageFormatter.formatValue(informe?.excedentePorcentaje.toString()) : 0
      } %`,
    },
  ];

  const SpinnerInforme = () => (
    <div className={styles.loading}>
      <Spinner size={100} icon />
    </div>
  );

  /// TABLA MIEMBROS ACEPTADOS
  const MiembroAceptadosSection = () => (
    <>
      {informe ? (
        <>
          <SectionTitle className={styles.tituloinforme} text={I18n.t('MatcherListPage.informe.miembroAceptado')} />
          <div className={styles.table_wrapper}>
            <TableMatcher
              headerMatcherHeader={headerMatcherHeader}
              texAling="center"
              miembrosMatcher={informe?.listaMiembros.filter(
                (miembroAceptado: any) => miembroAceptado.aceptado === true,
              )}
              renderRow={(miembro) => (
                <MiembroRow key={miembro.id} miembro={miembro} metodoDeCalculos={informe?.metodoDeCalculos} />
              )} // Add the unique key prop
            />
          </div>
          <SectionWrapper customClassname={styles.table_wrapper}>
            <div className={styles.divider} />
          </SectionWrapper>
        </>
      ) : (
        <SpinnerInforme />
      )}
    </>
  );
  /// TABLA MIEMBROS EXCLUIDOS

  const MiembroExcluidosSection = () => (
    <>
      {informe ? (
        <>
          <SectionTitle className={styles.tituloinforme} text={I18n.t('MatcherListPage.informe.miembroExcluido')} />
          <div className={styles.table_wrapper}>
            <TableMatcher
              headerMatcherHeader={headerMatcherHeaderExcluido}
              miembrosMatcher={informe?.listaMiembros.filter(
                (miembroAceptado: any) => miembroAceptado.aceptado !== true,
              )}
              renderRow={(miembro) => <MiembroRowExcluido key={miembro.id} miembro={miembro} />} // Add the unique key prop
              texAling="center"
            />
          </div>
        </>
      ) : (
        <SpinnerInforme />
      )}
    </>
  );

  // TABLA LISTA DE COMBINACIOENS

  const ListaCombinacionesSection = () => (
    <>
      {informe ? (
        <>
          <div className={styles.sizeDiv}>
            {configuration.matcher2 && (
              <SectionTitle
                className={styles.tituloinforme}
                text={`${I18n.t('MatcherListPage.informe.metodoDeCalculos')}: ${informe?.metodoDeCalculos}`}
              />
            )}
            <SectionTitle className={styles.tituloinforme} text={I18n.t('MatcherListPage.informe.listaCombinacion')} />
          </div>
          <div className={styles.table_wrapper}>
            <TableMatcher
              headerMatcherHeader={ListadeMiembroHeadier}
              miembrosMatcher={informe?.listaCombinaciones}
              renderRow={(lista) => <ListaCombinacionesRow key={lista.id} lista={lista} />} // Add the unique key prop
              texAling="center"
            />
          </div>
          <SectionWrapper customClassname={styles.table_wrapper}>
            <div className={styles.divider} />
          </SectionWrapper>
        </>
      ) : (
        <SpinnerInforme />
      )}
    </>
  );

  if (loadingGeneralTest) {
    <SpinnerInforme />;
  }

  return (
    <>
      {estadoInforme ? (
        <>
          <SectionTitle text={I18n.t('MatcherListPage.headers.report')} />{' '}
          <>
            {estadoInforme !== 'COMPLETADO' ? (
              <>
                {estadoInforme && estadoInforme !== 'ENEJECUCION' ? (
                  <LoadingButton type="submit" text={I18n.t('Lanzar informe')} onClick={submitForm} />
                ) : null}
                <div className={styles.informeMessage}>
                  {I18n.t('MatcherListPage.informe.estadoInforme')}{' '}
                  <p className={styles.tiempoEjecutar}> {I18n.t(estadoInforme)}</p>
                  {I18n.t('MatcherListPage.informe.tarda')}
                </div>
                {mensajeErrorInforme.codigo === '' ? null : (
                  <div className={styles.errorMessage}>
                    <p>{I18n.t(mensajeErrorInforme.codigo)}</p>
                    <p>{mensajeErrorInforme.mensaje}</p>
                  </div>
                )}
                {informe && informe.errores && informe.errores.length > 0 ? (
                  <div className={styles.errorMessage}>
                    {informe.errores.flat().map((error) => (
                      <div key={error.codigo + error.mensaje}>
                        <p>
                          {error.codigo} {error.mensaje}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : null}
              </>
            ) : (
              <>
                <div className={styles.flexContainerInforme}>
                  <div className={styles.flexInformeFecha}>
                    <p className={styles.pInformeFechaLabel}>{I18n.t('MatcherListPage.informe.fechaInforme')}:</p>
                    <SectionSubTitle text={new Date().toLocaleDateString()} />
                  </div>

                  <div className={styles.botonGuardar}>
                    <LoadingButton
                      type="submit"
                      text={I18n.t('MatcherListPage.informe.lanzarInforme')}
                      loading={loading}
                      onClick={submitForm}
                      className={styles.loadingButtonSameSize}
                    />

                    <LoadingButton
                      type="submit"
                      text={I18n.t('MatcherListPage.informe.export')}
                      onClick={exportInforme}
                      loading={loadingExportInforme}
                      className={styles.loadingButtonSameSize}
                    />
                    <LoadingButton
                      type="submit"
                      text={I18n.t('MatcherListPage.informe.ExportListaDis')}
                      onClick={exportListaDistribucion}
                      loading={loadingExportListaDistribucion}
                      className={styles.loadingButtonSameSize}
                    />
                  </div>
                </div>
                {loading ? (
                  <div className={styles.loading}>
                    <Spinner size={100} icon />
                  </div>
                ) : (
                  <>
                    {mensajeErrorInforme.codigo === '' ? null : (
                      <div className={styles.errorMessage}>
                        <p>{I18n.t(mensajeErrorInforme.codigo)}</p>
                        <p>{mensajeErrorInforme.mensaje}</p>
                      </div>
                    )}
                    <div className={styles.flexContainerInforme}>
                      <div className={styles.containerForms}>
                        <ListaCombinacionesSection />

                        <MiembroAceptadosSection />
                        <MiembroExcluidosSection />
                      </div>

                      <div className={styles.containerForms}>
                        <div className={styles.resultadoInforme}>
                          {informe ? (
                            <GlobalSectionInforme text={I18n.t('Globales')} resultInformeGlobal={resultInformeGlobal} />
                          ) : (
                            <SpinnerInforme />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        </>
      ) : (
        <SpinnerInforme />
      )}
    </>
  );
};

export default InformeMatcher;
