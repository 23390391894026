import React, { useState } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import ControlledInput from 'components/Input/NewInputs';
import Button from 'components/Button/Button';
import { I18n } from 'core';
import logo from 'assets/images/logo.png';
import { ReactComponent as LikeIcon } from 'assets/icons/LikeIcon.svg';
import { Colors } from 'styles';
import { authServiceInstance } from 'services';
import { Link } from 'react-router-dom';
import { navigate } from 'navigation';
import styles from './ForgotPasswordPage.module.scss';

const ForgotPasswordPage = (): JSX.Element => {
  const [formSubmited, setFormSubmited] = useState(false);

  const handleSubmit = (values: any) => {
    authServiceInstance.solicitarRecoverPassword(values.nif).then((response) => {
      if (response.status === 200) {
        setFormSubmited(true);
      }
    });
  };

  const validationSchema = Yup.object({
    nif: Yup.string().min(5, 'Too Short!').required('Required'),
  });

  return (
    <div className={styles.root}>
      {formSubmited ? (
        <div className={styles.success_container}>
          <LikeIcon fill={Colors.COLOR_ELECSUMGREEN} height={64} width={64} />
          <h4>{I18n.t('ForgotPasswordPage.successTitle')}</h4>
          <p>{I18n.t('ForgotPasswordPage.successMsg')}</p>
          <Button
            type="button"
            onClick={() => navigate('/')}
            variant="primary"
            text={I18n.t('ForgotPasswordPage.goBack')}
          />
        </div>
      ) : (
        <div className={styles.form_container}>
          <Link to="/auth/login">
            <img src={logo} className={styles.logo} alt="" />
          </Link>
          <Formik validationSchema={validationSchema} onSubmit={handleSubmit} initialValues={{ nif: '' }}>
            {({ isValid, dirty }) => (
              <Form className={styles.form}>
                <p className={styles.helper}>{I18n.t('ForgotPasswordPage.helperNif')}</p>
                <div className={styles.input}>
                  <Field name="nif">
                    {({ field }: any) => (
                      <ControlledInput
                        type="text"
                        value={field.value}
                        placeholder="NIF"
                        label="NIF"
                        onChange={field.onChange}
                        id="nif"
                        name={field.name}
                      />
                    )}
                  </Field>
                </div>
                <div className={styles.next_step_wrapper}>
                  <Button
                    className={styles.button}
                    disabled={!(isValid && dirty)}
                    type="submit"
                    variant="primary"
                    text={I18n.t('ForgotPasswordPage.recover')}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default React.memo(ForgotPasswordPage);
