import { UtilsService } from '..';

interface ConvertedKilo {
  num: string;
  unit: string;
}

class KilogramsConverterService {
  private numberToConvert: number;

  convertKilos(numberToConvert: number): ConvertedKilo {
    this.numberToConvert = numberToConvert;
    const format = (number: number): string => UtilsService.formatTwoDecimal(number);

    if (this.numberToConvert >= 0 && this.numberToConvert < 999) {
      return {
        num: format(this.numberToConvert),
        unit: 'kg',
      };
    }
    if (this.numberToConvert >= 1000) {
      return {
        num: format(this.numberToConvert / 1000),
        unit: 'tn',
      };
    }
    return {
      num: '0',
      unit: '',
    };
  }
}
const kilogramsConverterService: KilogramsConverterService = new KilogramsConverterService();

export default kilogramsConverterService;
