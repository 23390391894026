import { Installation } from 'types/models';
import { INSTALLATIONS_ACTIONS } from './types';

const fetchInstallation = () => ({
  type: INSTALLATIONS_ACTIONS.FETCH_INSTALLATION,
});

const fetchInstallationSuccess = (payload: Installation) => ({
  type: INSTALLATIONS_ACTIONS.FETCH_INSTALLATION_OK,
  payload,
});

const fetchInstallationError = (payload: string) => ({
  type: INSTALLATIONS_ACTIONS.FETCH_INSTALLATION_ERROR,
  payload,
});

const resetInstallations = () => ({
  type: INSTALLATIONS_ACTIONS.RESET_STATE,
});

export { fetchInstallation, fetchInstallationSuccess, fetchInstallationError, resetInstallations };
