import { Tag } from 'components';
import I18n from 'i18n-js';

import React from 'react';
import { Colors } from 'styles';
// import { InscritosModel } from 'types/models/inscritos';
import styles from './community-enrolled-list-row.module.scss';

interface CommunityListRowProps {
  row: any;
  communityID: string;
}

const ColorTags: any = {
  pendiente: Colors.COLOR_ELECSUMGREEN,
  Colors: Colors.COLOR_ORANGE_STRONG,
};

export const CommunityEnrolledListRow = (props: CommunityListRowProps): JSX.Element => {
  const { row } = props;
  const { t } = I18n;

  return (
    <tr className={styles.mainrow}>
      <td>
        {row.nombre} {row.apellidos}
      </td>
      <td>{`${row.direccionCompleta.slice(0, 20)}...`}</td>
      <td>{row.telefono}</td>
      <td>{row.nombreComunidad}</td>
      <td style={{ padding: '2rem' }}>
        <label className={styles.checkboxTable}>
          <input type="checkbox" defaultChecked={row.OTC} disabled />
        </label>
      </td>

      <td>{row.cups}</td>
      <td>
        <Tag color={ColorTags.pendiente}>{t(`ComunitiesEnrolledListPage.PendientaValidar`)}</Tag>
      </td>

      <td style={{ textAlign: 'center' }}>{row.pasoCompletado}</td>
    </tr>
  );
};
