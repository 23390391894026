import { SectionTitle } from 'components';

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { FormGroup, FormGroupProps, I18n } from 'core';

import { LoggingService } from 'services';
import CommunityCustomOnboarding from 'types/models/communityCustomOnboarding';
import { ToolsBarWithoutUrl } from 'pages/ModeTecnic/rol-comunidades/comunidades-detalle-page/tools-bar-without-url/tools-bar-without-url';
import { translatableLangs } from 'config/translatable-langs';
import { SelectItemI } from 'components/NewInputs/Select/ElecsumSelect';
import styles from './comunidades-custom-onboarding-confirmacion.module.scss';
import { SaveDataHandle } from '../../comunidades-custom-onboarding-process-page';

interface ComunidadesCustomOnboardingOfertaProps {
  communityId: string;
  communityCustomOnboarding: CommunityCustomOnboarding;
  saveDetails: (customOnboarding: CommunityCustomOnboarding) => void;
}
const ComunidadesCustomOnboardingConfirmacion = forwardRef<SaveDataHandle, ComunidadesCustomOnboardingOfertaProps>(
  (props: ComunidadesCustomOnboardingOfertaProps, ref) => {
    const { communityCustomOnboarding, saveDetails } = props;
    const [customOnboarding, setCustomOnboarding] = useState<CommunityCustomOnboarding>();
    const [errors, setErrors] = useState({});
    const customOnboardingData = JSON.parse(JSON.stringify(communityCustomOnboarding.serialize()));
    const [currentLang, setCurrentLang] = useState<SelectItemI>(translatableLangs[0]);

    const [textoTituloCa, setTextoTituloCa] = useState<string>(
      customOnboardingData.tituloFinalizacionOnboarding.ca || '',
    );
    const [textoTituloEs, setTextoTituloEs] = useState<string>(
      customOnboardingData?.tituloFinalizacionOnboarding.es || '',
    );
    const [textoMensajeCa, setTextoMensajeCa] = useState<string>(
      customOnboardingData.mensajeFinalizacionOnboarding.ca || '',
    );
    const [textoMensajeEs, setTextoMensajeEs] = useState<string>(
      customOnboardingData?.mensajeFinalizacionOnboarding.es || '',
    );

    const validateForm = (): boolean => {
      setErrors({});
      return true;
    };

    function handleChange<T>(path: string, value: T): void {
      let tituloFinalizacion = {
        es: textoTituloEs,
        ca: textoTituloCa,
      };
      let mensajeFinalizacion = {
        es: textoMensajeEs,
        ca: textoMensajeCa,
      };
      if (path === 'tituloFinalizacionOnboarding') {
        if (currentLang.id === 'es') {
          tituloFinalizacion = {
            es: value as unknown as string,
            ca: textoTituloCa,
          };
          setTextoTituloEs(tituloFinalizacion.es);
          communityCustomOnboarding.setAttr('tituloFinalizacionOnboarding', tituloFinalizacion);

          setCustomOnboarding(
            new CommunityCustomOnboarding().deserialize({
              ...customOnboardingData,
              tituloFinalizacionOnboarding: tituloFinalizacion,
            }),
          );
        } else {
          tituloFinalizacion = {
            es: textoTituloEs,
            ca: value as unknown as string,
          };
          communityCustomOnboarding.setAttr('tituloFinalizacionOnboarding', tituloFinalizacion);

          setTextoTituloCa(tituloFinalizacion.ca);
          setCustomOnboarding(
            new CommunityCustomOnboarding().deserialize({
              ...customOnboardingData,
              tituloFinalizacionOnboarding: tituloFinalizacion,
            }),
          );
        }
      } else if (path === 'mensajeFinalizacionOnboarding') {
        if (currentLang.id === 'es') {
          mensajeFinalizacion = {
            es: value as unknown as string,
            ca: textoMensajeCa,
          };
          setTextoMensajeEs(mensajeFinalizacion.es);
          communityCustomOnboarding.setAttr('mensajeFinalizacionOnboarding', mensajeFinalizacion);

          setCustomOnboarding(
            new CommunityCustomOnboarding().deserialize({
              ...customOnboardingData,
              mensajeFinalizacionOnboarding: mensajeFinalizacion,
            }),
          );
        } else {
          mensajeFinalizacion = {
            es: textoMensajeEs,
            ca: value as unknown as string,
          };
          communityCustomOnboarding.setAttr('mensajeFinalizacionOnboarding', mensajeFinalizacion);

          setTextoMensajeCa(mensajeFinalizacion.ca);
          setCustomOnboarding(
            new CommunityCustomOnboarding().deserialize({
              ...customOnboardingData,
              mensajeFinalizacionOnboarding: mensajeFinalizacion,
            }),
          );
        }
      }
    }

    const handleSubmit = (): void => {
      if (validateForm() && customOnboarding) {
        LoggingService.log('handleSubmit :: ok', { client: customOnboarding });
        saveDetails(customOnboarding);
      } else {
        LoggingService.log('handleSubmit :: errors', { client: customOnboarding, errors });
      }
    };
    const formFields: Array<FormGroupProps> = [
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.tituloFinalizacionOnboarding'),
        type: 'string',
        id: 'tituloFinalizacionOnboarding',
        path: 'tituloFinalizacionOnboarding',
        name: 'tituloFinalizacionOnboarding',
        placeholder: '',
        value: currentLang.id === 'es' ? textoTituloEs : textoTituloCa,
      },
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.mensajeFinalizacionOnboarding'),
        type: 'textarea',
        id: 'mensajeFinalizacionOnboarding',
        path: 'mensajeFinalizacionOnboarding',
        name: 'mensajeFinalizacionOnboarding',
        placeholder: '',
        value: currentLang.id === 'es' ? textoMensajeEs : textoMensajeCa,
      },
    ];

    useImperativeHandle(ref, () => ({
      getData: () => {
        handleSubmit();
      },
    }));

    return (
      <div className={styles.root}>
        <div className={styles.flex}>
          <div className={styles.titulo}>
            <SectionTitle text={I18n.t('ComunidadesCustomOnboardingProcess.form.confirmacionMensaje')} />
          </div>
          <div>
            <ToolsBarWithoutUrl
              langs={translatableLangs}
              selectedLang={currentLang}
              onChangeLanguage={(x) => setCurrentLang(x)}
            />
          </div>
        </div>
        <div style={{ marginBottom: '15px' }}>
          <p className={styles.label}>
            {I18n.t('ComunidadesCustomOnboardingProcess.form.confirmacionMensajeExplicacion')}
          </p>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.containerForms}>
            <div className={styles.containerForm}>
              {formFields.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  placeholder={formGroup.placeholder}
                  value={formGroup.value}
                  required={formGroup.required}
                  onChange={handleChange}
                  readOnly={formGroup.readOnly}
                  className={formGroup.className}
                  maxLenght={20000}
                />
              ))}
            </div>
          </div>
        </form>
      </div>
    );
  },
);
export default ComunidadesCustomOnboardingConfirmacion;
