import { Serializable } from '../../interfaces/serializable';

interface MonthlyConsumptionDataAPII {
  datos: {
    tarifa: string;
    tarifaComercializadora: string;
    meses: Array<{
      mes: number;
      periodos: Array<{
        periodo: number;
        energia: number;
      }>;
    }>;
  };
  habilitado: string;
}

export default class MonthlyConsumptionObject implements Serializable<MonthlyConsumptionObject> {
  datos: {
    tarifa: string;
    tarifaComercializadora: string;
    meses: Array<{
      mes: number;
      periodos: Array<{
        periodo: number;
        energia: number;
      }>;
    }>;
  };

  habilitado: string;

  deserialize(input: MonthlyConsumptionDataAPII): MonthlyConsumptionObject {
    if (!input) {
      return this;
    }

    this.datos = {
      tarifa: input.datos.tarifa,
      tarifaComercializadora: input.datos.tarifaComercializadora,
      meses:
        input.datos.meses.map((mesItem) => ({
          mes: mesItem.mes,
          periodos:
            mesItem.periodos.map((mesPeriod) => ({ periodo: mesPeriod.periodo, energia: mesPeriod.energia })) || [],
        })) || [],
    };

    this.habilitado = input.habilitado || 'no';

    return this;
  }
}
