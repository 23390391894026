const ENVIRONMENTALNEWS_MOCK = [
  {
    id: 'FD110591E552AF1A',
    titular: 'Primera notícia MOCK 1',
    fecha: 'data MOCK 1',
    descripcion: 'Descripció de la primera noticia MOCK 1',
    imagenUrl: 'http.....',
  },
  {
    id: 'FD110591E552AF1B',
    titular: 'Segona notícia MOCK 2',
    fecha: 'data MOCK 2',
    descripcion: 'Descripció de la segona noticia MOCK 2',
    imagenUrl: 'http.....',
  },
  {
    id: 'FD110591E552AF1C',
    titular: 'Tercera notícia MOCK 3',
    fecha: 'data MOCK 3',
    descripcion: 'Descripció de la tercera noticia MOCK 3',
    imagenUrl: 'http.....',
  },
];

export default ENVIRONMENTALNEWS_MOCK;
