import { BaseLayout, ErrorLayout } from 'layouts';
import { RouteInterface } from 'types/interfaces';

import { TestPage } from 'pages/Testing';

import { P404Component } from '../../pages';

import CommonRoutes from './Common.routing';
import ClientRoutes from './ModeClient.routing';
import TecnicRoutes from './ModeTecnic.routing';
import PromotorRoutes from './ModePromotor.routing';

// import OldRoutes from './Old.routing';

const routes: Array<RouteInterface> = [...CommonRoutes, ...ClientRoutes, ...TecnicRoutes, ...PromotorRoutes];

if (process.env.REACT_APP_ENV !== 'prod') {
  routes.push({
    path: '/test',
    name: 'Test',
    layout: BaseLayout,
    component: TestPage,
  });
}

routes.push({
  path: '*',
  name: 'Error 404',
  layout: ErrorLayout,
  component: P404Component,
});

export default routes;
