import { DateIntervalPicker, SectionTitle } from 'components';

import React, { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars

import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { I18n } from 'core';
import wattsConverterService from 'services/local/WattsConverter';
import Button from 'components/Button/Button';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { clientServiceInstance } from 'services';
import { fetchCUPS, fetchCUPSSuccess } from 'redux/actions/CupsActions';
import CIMServiceInstance from 'services/remote/CIMService';
import PowerOptimization from 'types/models/powerOptimization';
import { toast } from 'react-toastify';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import { useRole } from 'hooks';
import { GlobalStoreState } from 'types/state';
import { Roles } from 'types/enums';
import { navigate } from 'navigation';
import styles from './CIMPowerOptimizationPage.module.scss';

interface DateInterval {
  start: moment.Moment;
  end: moment.Moment;
}

const CIMPowerOptimizationPage = (props: any): JSX.Element => {
  // Props
  const {
    match: {
      params: { cupsID },
    },
  } = props;

  // Hooks
  const dispatch = useDispatch();
  const role = useRole();
  const cupsSelected = useSelector((state: GlobalStoreState) => state.clientCUPS.selectedCups);
  // State
  const [dateInterval, setDateInterval] = useState<DateInterval>({
    start: moment().startOf('month').subtract(1, 'year'),
    end: moment().startOf('month'),
  });
  const [optimizationData, setOptimizationData] = useState<PowerOptimization | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [noData, setNodata] = useState<boolean>(false);
  // Effects
  useEffect(() => {
    dispatch(fetchCUPS());
    clientServiceInstance.getClient(cupsID).then((response) => {
      response.setAttr('cups', cupsID);
      dispatch(fetchCUPSSuccess(response));
    });
  }, [cupsID]);

  // useEffect(() => {
  //   if (role !== Roles.CLIENT) return;
  //   if (!authServiceInstance.user?.nif) return;
  //   cupsServiceInstance.getCupsFromUserId(authServiceInstance.user?.nif).then((response) => {
  //     if (response.length > 0) {
  //       const [firstCups] = response;
  //       if (!cupsID) {
  //         navigate(`/client/${firstCups.cups}/power-optimization`);
  //       }
  //       const cupsById = response.find((item) => item.cups === cupsID);
  //       dispatch(updateClientCUPS(cupsById || firstCups));
  //       dispatch(fetchClientCUPSListSuccess(response));
  //     }
  //   });
  // }, [cupsID, role]);

  useEffect(() => {
    if (role !== Roles.CLIENT) return;
    if (cupsSelected) {
      if (cupsSelected.cups !== cupsID) {
        navigate(`/client/cim/${cupsSelected.cups}/power-optimization`);
      }
    }
  }, [cupsSelected, role]);

  const handleChangeDate = (dates: DateInterval) => {
    setDateInterval(dates);
  };

  // eslint-disable-next-line no-console

  const handleSubmit = () => {
    // Must perform request data;
    if (!cupsID) return;
    setLoading(true);
    CIMServiceInstance.getPowerOptimization({
      cups: cupsID,
      fechaInicio: dateInterval.start.format('YYYY/MM/DD'),
      fechaFin: dateInterval.end.format('YYYY/MM/DD'),
    })
      .then((response) => {
        if (response === null) {
          setNodata(true);
        }
        setOptimizationData(response);
        setLoading(false);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e.message);
        toast.error(I18n.t('CIM.PowerOptimizationPage.errorFetching'));
      });
  };

  return (
    <div className={styles.root}>
      <div className={styles.menu}>
        <DateIntervalPicker
          onChangeDates={handleChangeDate}
          datesInterval={dateInterval}
          label={I18n.t('CIM.PowerOptimizationPage.interval')}
        />
        <div className={styles.actions}>
          <Button onClick={() => handleSubmit()} type="button" text="CALCULAR" variant="primary" />
        </div>
      </div>
      {(() => {
        if (loading) {
          return (
            <div className={styles.loading_wrapper}>
              <div className={styles.messages}>
                <p className={styles.title}>{I18n.t('CIM.PowerOptimizationPage.loadingTitle')}</p>
                <p className={styles.subtitle}>{I18n.t('CIM.PowerOptimizationPage.loadingSubtitle')}</p>
              </div>
              <FullPageSyncLoader icon />
            </div>
          );
        }
        if (noData) {
          return (
            <div className={styles.nodata_wrapper}>
              <div className={styles.messages}>
                <p className={styles.title}>{I18n.t('CIM.PowerOptimizationPage.noData')}</p>
              </div>
            </div>
          );
        }
        if (optimizationData) {
          return (
            <div className={styles.sim_wrapper}>
              <SectionWrapper customClassname={styles.optimization}>
                <SectionTitle text={I18n.t('CIM.PowerOptimizationPage.OptimizationSection.title')} />
                <div className={styles.wrapper}>
                  {optimizationData.datos.potencias.length > 0 ? (
                    <table>
                      <thead>
                        <tr>
                          <th>{I18n.t('CIM.PowerOptimizationPage.OptimizationSection.period')}</th>
                          <th>{I18n.t('CIM.PowerOptimizationPage.OptimizationSection.optimal')}</th>
                          <th>{I18n.t('CIM.PowerOptimizationPage.OptimizationSection.actual')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {optimizationData.datos.potencias.map((line) => (
                          <tr>
                            <td className={styles.p}>{`P${line.periodo}`}</td>
                            <td className={styles.optimal}>{`${
                              wattsConverterService.convertWatts(line.potenciaOptimizada).num
                            } ${wattsConverterService.convertWatts(line.potenciaOptimizada).unit}`}</td>
                            <td className={styles.actual}>{`${
                              wattsConverterService.convertWatts(line.potenciaContratada).num
                            } ${wattsConverterService.convertWatts(line.potenciaContratada).unit}`}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>{I18n.t('CIM.PowerOptimizationPage.OptimizationSection.no_data')}</p>
                  )}
                </div>
              </SectionWrapper>
              <SectionWrapper customClassname={styles.saving}>
                <SectionTitle className={styles.title} text={I18n.t('CIM.PowerOptimizationPage.SavingSection.title')} />
                <div className={styles.concepts}>
                  <div className={styles.wrapper}>
                    <p className={styles.label}>
                      {I18n.t('CIM.PowerOptimizationPage.SavingSection.savingLabelPercent')}
                    </p>
                    <p className={styles.value}>{`${optimizationData.datos.ahorroPorcentaje} %`}</p>
                  </div>
                  <div className={styles.wrapper}>
                    <p className={styles.label}>
                      {I18n.t('CIM.PowerOptimizationPage.SavingSection.savingLabelImport')}
                    </p>
                    <p className={styles.value}>{`${optimizationData.datos.ahorroImporte.toLocaleString(
                      'ca-ES',
                    )} €`}</p>
                    <p className={styles.quote}>{I18n.t('CIM.PowerOptimizationPage.SavingSection.iva')}</p>
                  </div>
                </div>
              </SectionWrapper>
            </div>
          );
        }
        return null;
      })()}
    </div>
  );
};

export default CIMPowerOptimizationPage;
