import React from 'react';
import { I18n } from 'core';
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryStack,
  VictoryBar,
  VictoryLabel,
  VictoryContainer,
} from 'victory';
import { Colors } from 'styles';
import { ChartDataI } from 'types/models';

import wattsConverterService from 'services/local/WattsConverter';
import { ViewDate, ChartDataList } from 'components';
import { SizeMe } from 'react-sizeme';
import Constants from 'config/constants';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import styles from './ProductionChartsCommon.module.scss';

interface DateType {
  day: number;
  month: number;
  year: number;
}

interface ProductionEnergyMonthlyPropsI {
  // energyProductionMonthly: {
  generationData: Array<ChartDataI>;
  guaranteeData: Array<ChartDataI>;
  estimateData: Array<ChartDataI>;
  generation: number;
  guarantee: number;
  estimate: number;
  differenceGeneration: number;
  date: DateType;
  currentTab: string;
  isLoading?: boolean;
  radius: number;
  // };
}

const ProductionEnergyMonthlyChart = (props: ProductionEnergyMonthlyPropsI): JSX.Element => {
  const {
    generationData,
    guaranteeData,
    estimateData,
    date,
    currentTab,
    generation,
    guarantee,
    estimate,
    differenceGeneration,
    isLoading,
    radius,
  } = props;

  // const TOTAL_TICKS = 5;
  // const MAX = Math.max(...generationData.map((data) => data.y));
  // const tickValuesX = Array.from(Array(TOTAL_TICKS+1).keys()).map((e) => e*(MAX / TOTAL_TICKS));

  const listItemsArray = [
    { color: 'bck-orange', name: I18n.t('generation'), data: generation },
    {
      color: 'bck-green',
      name: I18n.t('productionGuarantee'),
      data: guarantee,
    },
    { color: 'bck-dark-grey', name: I18n.t('estimateProject'), data: estimate },
    {
      color: '',
      textColor: 'text-red',
      name: `${I18n.t('differenceInst')} ${radius}km`,
      data: differenceGeneration,
    },
  ];

  if (isLoading) {
    return <FullPageSyncLoader />;
  }

  return (
    <div>
      <SizeMe>
        {({ size }) => (
          <div>
            <svg viewBox={`0 0 ${size.width} 400`} height="100%" width="100%">
              <VictoryChart
                width={size.width || 500}
                height={400}
                standalone={false}
                domainPadding={{ x: 0, y: 10 }}
                containerComponent={<VictoryContainer responsive={false} />}
              >
                <VictoryLabel
                  style={{ fontSize: Constants.charts.AXIS_LABELS_FONTSIZE }}
                  text="kWh"
                  x={30}
                  y={30}
                  textAnchor="middle"
                />
                <VictoryAxis
                  style={{
                    axis: { stroke: 'transparent' },
                    tickLabels: {
                      fontSize: Constants.charts.AXIS_VALUES_FONTSIZE,
                      fill: Colors.COLOR_CONSUM_NOT_SELECTED,
                    },
                  }}
                  tickCount={10}
                  tickFormat={(t): string => Math.round(t).toString()}
                  offsetY={45}
                />
                <VictoryAxis
                  dependentAxis
                  style={{
                    axis: { stroke: 'transparent' },
                    tickLabels: {
                      fontSize: Constants.charts.AXIS_VALUES_FONTSIZE,
                      fill: Colors.COLOR_CONSUM_NOT_SELECTED,
                    },
                  }}
                  tickCount={5}
                  tickFormat={(tick): number => wattsConverterService.convertWattsToKwatts(tick)}
                  offsetX={45}
                />
                <VictoryStack colorScale={[Colors.COLOR_GENERACIO]}>
                  <VictoryBar barRatio={Constants.charts.BAR_RATIO_FILL} data={generationData} />
                </VictoryStack>
                <VictoryLine
                  interpolation="natural"
                  style={{
                    data: { stroke: Colors.COLOR_ELECSUMGREEN, strokeWidth: 2 },
                  }}
                  data={guaranteeData}
                />
                <VictoryLine
                  interpolation="natural"
                  style={{
                    data: { stroke: Colors.COLOR_GRAY_500, strokeWidth: 2 },
                  }}
                  data={estimateData}
                />
              </VictoryChart>
            </svg>
          </div>
        )}
      </SizeMe>
      <div className={styles.data_footer}>
        <ViewDate date={date} currentTab={currentTab} />
        <div className={styles.data_footer_list}>
          {listItemsArray.map(
            (item): JSX.Element => (
              <ChartDataList
                key={item.name}
                color={item.color}
                textColor={item.textColor}
                name={item.name}
                data={item.data}
              />
            ),
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductionEnergyMonthlyChart;
