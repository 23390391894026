import { GuardInterface } from 'types/interfaces';

import { authServiceInstance, AuthService, InstallationService, installationServiceInstance } from 'services';

class HomeRouteGuard implements GuardInterface {
  private authService: AuthService;

  private installationService: InstallationService;

  private loginUrl = '/auth/login';

  private clientHomeURL = '/client/home';

  private redirectAdminUrl = '/tecnic/dashboard';

  private redirectAdminNoInstallationUrl = '/tecnic/dashboard';

  private redirectPromotor="/promotor/home"

  constructor() {
    this.authService = authServiceInstance;
    this.installationService = installationServiceInstance;
  }

  canActivate(): boolean {
    return false;
  }

  getRedirectUrl(): string | null {
    const isAdmin = this.authService.user;

    if (!this.authService.isLoggedIn()) {
      return this.loginUrl;
    }

    if (isAdmin?.tipoDeUsuario==="user") {
      return this.clientHomeURL;
    }
    if (isAdmin?.tipoDeUsuario==="promotor") {
      return this.redirectPromotor;
    }
    const { installation } = this.installationService;
    if (installation) {
      return this.redirectAdminUrl;
    }

    return this.redirectAdminNoInstallationUrl;
  }
}

const homeRouteGuard = new HomeRouteGuard();

export default homeRouteGuard;
