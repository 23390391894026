import { Serializable } from '../interfaces';

export interface PerfilConsumoAPI {
  id: number;
  nombre: string;
  descripcion: string;
  infoDeConsumo: {
    año: number;
    consumoAnual: number;
  };
}

export interface PerfilConsumoCaracteristica {
  property: string;
  value: boolean;
}

export default class PerfilConsumo implements Serializable<PerfilConsumo> {
  id: number;

  nombre: string;

  descripcion: string;

  infoDeConsumo: {
    año: number;
    consumoAnual: number;
  };

  deserialize(input: PerfilConsumoAPI): PerfilConsumo {
    if (!input) {
      return this;
    }

    this.id = input.id;
    this.nombre = input.nombre;
    this.descripcion = input.descripcion;
    this.infoDeConsumo = {
      año: input.infoDeConsumo.año,
      consumoAnual: input.infoDeConsumo.consumoAnual || 0,
    };

    return this;
  }

  deserializeArray(inputArray: Array<PerfilConsumoAPI>): Array<PerfilConsumo> {
    return inputArray.map((input) => new PerfilConsumo().deserialize(input));
  }

  setAttr<T>(key: string, value: T): void {
    Object.assign(this, { [key]: value });
  }
}
