import { Card, Spinner } from 'components';
import React from 'react';
import { I18n } from 'core';
import { ReactComponent as BellIcon } from 'assets/icons/Bell_Filled.svg';
import { Colors } from 'styles';
import { navigate } from 'navigation';
import styles from './AlertsWidget.module.scss';

interface AlertsWidgetPropsI {
  alertsNumber: number;
}

const AlertsWidget = (props: AlertsWidgetPropsI): JSX.Element => {
  const { alertsNumber } = props;

  const handleClickNavigate = () => {
    navigate('/tecnic/alerts');
  };

  return (
    <Card
      title={I18n.t('alerts')}
      body={[
        <div className={styles.root} key={1}>
          <BellIcon fill={Colors.COLOR_NOTIFICATIONS} height={50} width={50} />
          <span className={styles.value}>{alertsNumber >= 0 ? alertsNumber : <Spinner size={30} />}</span>
          <p className={styles.label}>{I18n.t('alerts_pending')}</p>
          <button className={styles.viewmore_button} type="button" onClick={() => handleClickNavigate()}>
            {I18n.t('viewMore')}
          </button>
        </div>,
      ]}
    />
  );
};

export default React.memo(AlertsWidget);
