import React, { ChangeEvent, useEffect, useState } from 'react';

import cx from 'classnames';
import styles from './NumberInput.module.scss';

export interface InputInterface {
  path: string;
  onChange?: CallableFunction;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  type: string;
  id: string;
  placeholder?: string;
  required?: boolean;
  name: string;
  readOnly?: boolean;
  min?: number;
  max?: number;
  step?: number;
}

const NumberInputMargenes = (props: InputInterface): JSX.Element => {
  const { value, id, required, placeholder, name, readOnly, className = '', min, max, step } = props;
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const valueDecimal = 0.001;
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { path, onChange } = props;

    let newValue = e.target.value;
    // Ignora cualquier carácter que no sea un número, un punto o una coma
    newValue = newValue
      .replace(/[^0-9.,]/g, '')
      .replace(/,/g, '.')
      .replace(/(\d*\.\d*).*/g, '$1');

    // Si newValue es mayor que max, establece newValue a max
    const numericValue = parseFloat(newValue);
    if (max) {
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(numericValue) && numericValue > max) {
        newValue = max.toString();
      }
    }

    if (onChange) {
      onChange(path, newValue, id);
    }
  };
  const handleIncrement = (): void => {
    const { path, onChange } = props;
    const numericValue = parseFloat(inputValue) || 0;

    const incrementedValue = (numericValue + valueDecimal).toFixed(3);

    const newValue = Math.min(max || 1, parseFloat(incrementedValue));

    if (onChange) {
      onChange(path, newValue, id);
    }
  };

  const handleDecrement = (): void => {
    const { path, onChange } = props;
    const numericValue = parseFloat(inputValue) || 0;
    const decrementedValue = (numericValue - valueDecimal).toFixed(3);
    const newValue = Math.max(min || 0, parseFloat(decrementedValue));

    if (onChange) {
      onChange(path, newValue, id);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'ArrowUp') {
      e.preventDefault(); // Evita el comportamiento predeterminado de la flecha hacia arriba
      handleIncrement();
    } else if (e.key === 'ArrowDown') {
      e.preventDefault(); // Evita el comportamiento predeterminado de la flecha hacia abajo
      handleDecrement();
    }
  };

  const formatPlaceholder = (): string => {
    if (typeof placeholder === 'string') {
      return placeholder.charAt(0).toUpperCase() + placeholder.slice(1).toLowerCase();
    }
    return String(placeholder);
  };

  return (
    <input
      id={id}
      type="text"
      className={cx(styles.root, className)}
      value={inputValue}
      onChange={handleChange}
      autoComplete="off"
      onKeyDown={handleKeyDown}
      placeholder={formatPlaceholder()}
      required={required}
      name={name}
      readOnly={readOnly}
      min={min}
      max={max}
      step={step}
    />
  );
};

export default NumberInputMargenes;
