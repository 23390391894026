import { Card, Spinner } from 'components';
import React from 'react';
import { I18n } from 'core';
import { t } from 'i18n-js';
import { ReactComponent as PendingIcon } from 'assets/icons/community_pending.svg';
import { ReactComponent as MembersIcon } from 'assets/icons/community_share.svg';

import { Colors } from 'styles';
import { useHistory } from 'react-router-dom';
import { FormatNumberService } from 'services';

import styles from './comunidades-dashboard-widget.module.scss';

interface ComunidadesDashboardWidgetProps {
  communities: number;
  pending: number;
}

export const ComunidadesDashboardWidget = (props: ComunidadesDashboardWidgetProps): JSX.Element => {
  const { communities, pending } = props;
  const i18nPrefix = `DashboardPage.ComunidadesDashboardWidget`;
  const history = useHistory();
  return (
    <Card
      fullHeight
      title={I18n.t(`${i18nPrefix}.title`)}
      body={[
        <div className={styles.root}>
          <div className={styles.section}>
            <MembersIcon height={50} width={50} fill={Colors.COLOR_NOTIFICATIONS} />
            <span className={styles.value}>
              {communities >= 0 ? FormatNumberService.formatInteger(`${communities}`) : <Spinner size={30} />}
            </span>
            <p className={styles.label}>{t(`${i18nPrefix}.comunities`)}</p>

            <button
              onClick={() => {
                history.push('/tecnic/communities');
              }}
              className={styles.viewmore_button}
              type="button"
            >
              {I18n.t(`${i18nPrefix}.viewmore`)}
            </button>
          </div>
          <div className={styles.divider} />
          <div className={styles.section}>
            <PendingIcon stroke={Colors.COLOR_EXCEDENTS} height={50} width={50} />
            <span className={styles.value}>
              {pending >= 0 ? FormatNumberService.formatInteger(`${pending}`) : <Spinner size={30} />}
            </span>
            <p className={styles.label}>{t(`${i18nPrefix}.pending`)}</p>
            <button
              className={styles.viewmore_button}
              type="button"
              onClick={() => {
                history.push('/tecnic/inscritos');
              }}
            >
              {I18n.t(`${i18nPrefix}.viewmore`)}
            </button>
          </div>
        </div>,
      ]}
    />
  );
};
