import { SectionTitle } from 'components';

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { FormGroup, FormGroupProps, I18n } from 'core';
import { ReactComponent as DocumentsIcon } from 'assets/icons/Documents.svg';

import { Colors } from 'styles';
import { LoggingService } from 'services';

import { FORMGROUP_TYPES } from 'core/form/FormGroup/FormGroup';
import { SelectItemI } from 'components/NewInputs/Select/ElecsumSelect';
import { translatableLangs } from 'config/translatable-langs';
import { ToolsBarWithoutUrl } from 'pages/ModeTecnic/rol-comunidades/comunidades-detalle-page/tools-bar-without-url/tools-bar-without-url';
import { PromotorCustomOnboarding } from 'types/models';
import styles from './promotor-custom-onboarding-form.module.scss';
import { SaveDataHandle } from '../PersonalizacionPromotor';

interface PromotorCustomOnboardingFormProps {
  promotorId: string;
  promotorCustomOnboarding: PromotorCustomOnboarding;
  saveDetails: (customOnboarding: PromotorCustomOnboarding) => void;
}

const PromotorCustomOnboardingForm = forwardRef<SaveDataHandle, PromotorCustomOnboardingFormProps>(
  (props: PromotorCustomOnboardingFormProps, ref) => {
    const { promotorCustomOnboarding, saveDetails } = props;
    const [customOnboarding, setCustomOnboarding] = useState<PromotorCustomOnboarding>();
    const [errors, setErrors] = useState({});
    const [currentLang, setCurrentLang] = useState<SelectItemI>(translatableLangs[0]);

    const customOnboardingData = JSON.parse(JSON.stringify(promotorCustomOnboarding.serialize()));
    const validateForm = (): boolean => {
      setErrors({});
      return true;
    };

    function updateLanguageFields<T>(
      path: string,
      value: T,
      currentLangUp: { id: string },
      customOnboardingDataUp: any,
      promotorCustomOnboardingUp: any,
    ) {
      let updatedValue: any = {};
      const { id: currentLangId } = currentLangUp;
      if (currentLangId === 'es') {
        updatedValue = {
          es: value as unknown as string,
          ca: customOnboardingDataUp[path]?.ca || '',
        };
      } else if (currentLangId === 'ca') {
        updatedValue = {
          es: customOnboardingDataUp[path]?.es || '',
          ca: value as unknown as string,
        };
      }

      const updatedCustomOnboardingData = {
        ...customOnboardingDataUp,
        [path]: updatedValue,
      };

      setCustomOnboarding(new PromotorCustomOnboarding().deserialize(updatedCustomOnboardingData));
      promotorCustomOnboardingUp.setAttr(path, updatedValue);
    }

    function updateLanguageFieldsOnboarding<T>(
      path: string,
      value: T,
      currentLangUp: { id: string },
      customOnboardingDataUp: any,
      promotorCustomOnboardingUp: any,
    ) {
      const currentLangId = currentLangUp.id;
      // Divide la cadena `path` para obtener el campo específico
      const pathSegments = path.split('.');
      const fieldName = pathSegments[1];

      // Mantén el valor existente para el idioma no seleccionado
      const updatedValue = {
        ...(promotorCustomOnboardingUp?.personalizacionPromotor?.[fieldName] || {}),
        [currentLangId]: String(value), // Convertir a cadena de manera segura
      };

      // Actualiza solo el campo correspondiente en `mensajeFinalOnboarding`
      const updatedPersonalizacionOnboarding = {
        ...promotorCustomOnboardingUp?.personalizacionPromotor,
        [fieldName]: updatedValue,
      };

      // Actualiza `customOnboarding` con los datos actualizados
      const updatedCustomOnboardingData = {
        ...customOnboardingDataUp,
        personalizacionPromotor: updatedPersonalizacionOnboarding,
      };
      setCustomOnboarding(new PromotorCustomOnboarding().deserialize(updatedCustomOnboardingData));
      promotorCustomOnboardingUp.setAttr('personalizacionPromotor', updatedPersonalizacionOnboarding);
    }

    function handleChange<T>(path: string, value: T): void {
      if (path === 'politicaDePrivacidad') {
        updateLanguageFields(path, value, currentLang, customOnboardingData, promotorCustomOnboarding);
      } else if (path.includes('Promotor')) {
        updateLanguageFieldsOnboarding(path, value, currentLang, customOnboardingData, promotorCustomOnboarding);
      } else {
        const updatedCustomOnboardingData = {
          ...customOnboardingData,
          [path]: value,
        };
        setCustomOnboarding(new PromotorCustomOnboarding().deserialize(updatedCustomOnboardingData));
        promotorCustomOnboarding.setAttr(path, value);
      }
    }

    const handleSubmit = (): void => {
      if (validateForm() && customOnboarding) {
        LoggingService.log('handleSubmit :: ok', { client: customOnboarding });
        saveDetails(customOnboarding);
      } else {
        LoggingService.log('handleSubmit :: errors', { client: customOnboarding, errors });
      }
    };

    const formFieldsNombreDominio: Array<FormGroupProps> = [
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.nombreDominio'),
        type: 'string',
        id: 'nombreDominio',
        path: 'nombreDominio',
        name: 'nombreDominio',
        placeholder: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.nombreDominio'),
        value: promotorCustomOnboarding?.nombreDominio,
      },
    ];

    const formFields: Array<FormGroupProps> = [
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.primaryColor'),
        type: FORMGROUP_TYPES.colorPicker,
        id: 'colorPrimario',
        path: 'colorPrimario',
        name: 'colorPrimario',
        placeholder: I18n.t('ComunidadesCustomOnboardingProcess.form.placeholder.primaryColor'),
        value: promotorCustomOnboarding?.colorPrimario.trim(),
      },
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.secondaryColor'),
        type: FORMGROUP_TYPES.colorPicker,
        id: 'colorSecundario',
        path: 'colorSecundario',
        name: 'colorSecundario',
        placeholder: I18n.t('ComunidadesCustomOnboardingProcess.form.placeholder.secondaryColor'),
        value: promotorCustomOnboarding?.colorSecundario.trim(),
      },

      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.finalOnboarding'),
        type: 'textarea',
        id: 'personalizacionPromotor.mensajeFinalOnboarding',
        path: 'personalizacionPromotor.mensajeFinalOnboarding',
        name: 'personalizacionPromotor.mensajeFinalOnboarding',
        value:
          currentLang.id === 'es'
            ? promotorCustomOnboarding?.personalizacionPromotor.mensajeFinalOnboarding.es
            : promotorCustomOnboarding?.personalizacionPromotor.mensajeFinalOnboarding.ca,
        maxLenght: 500,
      },
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.politicaDePrivacidad'),
        type: 'textarea',
        id: 'personalizacionPromotor.politicaDePrivacidad',
        path: 'personalizacionPromotor.politicaDePrivacidad',
        name: 'personalizacionPromotor.politicaDePrivacidad',
        value:
          currentLang.id === 'es'
            ? promotorCustomOnboarding?.personalizacionPromotor.politicaDePrivacidad.es
            : promotorCustomOnboarding?.personalizacionPromotor.politicaDePrivacidad.ca,
      },
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.legal'),
        type: 'textarea',
        id: 'personalizacionPromotor.avisoLegalWeb',
        path: 'personalizacionPromotor.avisoLegalWeb',
        name: 'personalizacionPromotor.avisoLegalWeb',
        value:
          currentLang.id === 'es'
            ? promotorCustomOnboarding?.personalizacionPromotor.avisoLegalWeb.es
            : promotorCustomOnboarding?.personalizacionPromotor.avisoLegalWeb.ca,
      },
    ];

    useImperativeHandle(ref, () => ({
      getData: () => {
        handleSubmit();
      },
    }));
    // Nueva función para manejar el cambio de idioma
    const handleLanguageChange = (newLang: SelectItemI) => {
      // Guarda el valor actual del mensaje final en el idioma seleccionado
      const updatedValue = {
        es:
          currentLang.id === 'es'
            ? customOnboarding?.personalizacionPromotor?.mensajeFinalOnboarding.es
            : customOnboarding?.personalizacionPromotor?.mensajeFinalOnboarding.ca,
        ca:
          currentLang.id === 'ca'
            ? customOnboarding?.personalizacionPromotor?.mensajeFinalOnboarding.ca
            : customOnboarding?.personalizacionPromotor?.mensajeFinalOnboarding.es,
      };

      // Actualiza el estado con el valor del mensaje antes de cambiar de idioma
      setCustomOnboarding((prev: any) => {
        const updatedOnboarding = { ...prev };
        if (updatedOnboarding.personalizacionPromotor) {
          updatedOnboarding.personalizacionPromotor.mensajeFinalOnboarding = {
            es: updatedValue.es || '',
            ca: updatedValue.ca || '',
          };
        }
        return updatedOnboarding;
      });

      // Cambia el idioma
      setCurrentLang(newLang);
    };

    return (
      <div className={styles.root}>
        <div className={styles.flex}>
          <div className={styles.titulo}>
            <DocumentsIcon height={20} width={20} fill={Colors.COLOR_BLACK} className={styles.DocumentsIcon} />
            <SectionTitle text={I18n.t('ComunidadesCustomOnboardingProcess.form.section2')} />
          </div>
          <div>
            <ToolsBarWithoutUrl
              langs={translatableLangs}
              selectedLang={currentLang}
              onChangeLanguage={handleLanguageChange}
            />
          </div>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.containerForms}>
            <div className={styles.containerForm}>
              {formFieldsNombreDominio.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  placeholder={formGroup.placeholder}
                  value={formGroup.value}
                  required={formGroup.required}
                  onChange={handleChange}
                  readOnly={formGroup.readOnly}
                  className={formGroup.className}
                  maxLenght={20000}
                />
              ))}
            </div>{' '}
          </div>
          <div className={styles.containerForms}>
            <div className={styles.containerForm}>
              {formFields.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  placeholder={formGroup.placeholder}
                  value={formGroup.value}
                  required={formGroup.required}
                  onChange={handleChange}
                  onChangeEditor={handleChange}
                  readOnly={formGroup.readOnly}
                  className={formGroup.className}
                  maxLenght={20000}
                />
              ))}
            </div>
          </div>
        </form>
      </div>
    );
  },
);

export default PromotorCustomOnboardingForm;
