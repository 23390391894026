/* eslint-disable no-param-reassign */
import { LoadingButton, SectionTitle, Spinner } from 'components';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { FormGroup, FormGroupProps, I18n } from 'core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUser } from 'redux/actions/UsersActions';
import { toast } from 'react-toastify';

import roleService from 'services/remote/RolesService';
import RolInfoPermisos from 'types/models/rolinfopermiso';
import rolesPermisosService from 'services/remote/RolesPermisosService';
import { navigate } from 'navigation';
import ModalMatcher from 'components/ModalMatcher/ModalMatcher';
import { useToggle } from 'hooks/useToogle';
import { RolInfo } from 'types/models';
import styles from './RolesUserPageDetalle.module.scss';
import RolesPermisoDataSection from './components/RolesPermisoDataSection';

const RolesUserPageDetalle = (props: any): JSX.Element => {
  // Props
  const {
    match: {
      params: { rolesId },
    },
  } = props;

  // Hooks
  const dispatch = useDispatch();

  // State
  const [rolInfoPermisosData, setRolInfoPermisosData] = useState<RolInfoPermisos[]>();
  const [isSwitchOn, toggleSwitch] = useToggle();

  const [rolData, setRolData] = useState<RolInfo>();

  const [loadingData, setLoadingData] = useState(false);
  const [rolInfoForm, setRolInfoForm] = useState({ nombre: '' });
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  // const [submitDisabledDelete, setSubmitDisabledDelete] = useState<boolean>(false);
  const fetchData = () => {
    roleService.getRolesById(rolesId).then((response) => {
      setRolInfoForm({ nombre: response.nombre });
      setRolData(response);
    });
    rolesPermisosService.getPermisos().then((response) => {
      setRolInfoPermisosData(response);
    });
  };

  // Effects
  useEffect(() => {
    setLoadingData(true);
    dispatch(fetchUser());
    fetchData();
    setLoadingData(false);
  }, []);

  if (loadingData) {
    return (
      <div className={styles.loading_container}>
        <Spinner icon />
      </div>
    );
  }

  const formFields: Array<FormGroupProps> = [
    {
      label: I18n.t('RolPermisionDetailPage.RolData.labels.name'),
      type: 'text',
      id: 'nombre',
      path: 'nombre',
      name: 'nombre',
      value: rolInfoForm?.nombre,
      className: styles.field,
      placeholder: '',
    },
  ];

  const UpdateRol = async () => {
    setLoading(true);
    setSubmitDisabled(true);

    try {
      await roleService.updateRolInPermisio(rolesId, rolInfoForm.nombre);
    } catch (error) {
      toast.error(I18n.t('RolDetailPage.RolData.error'));
    } finally {
      setLoading(false);
      setSubmitDisabled(false);
    }
  };
  function handleChange(path: string, value: string): void {
    setRolInfoForm((prev) => ({
      ...prev,
      [path]: value,
    }));
  }
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    UpdateRol();
  };
  const handleDeleteRole = async (id: number) => {
    setLoadingDelete(true);

    try {
      // Call the delete role function from your service
      await roleService.deleteRol(id);
    } catch (error) {
      toast.error(I18n.t('RolDetailPage.RolData.error'));
      setLoadingDelete(false);
    } finally {
      navigate(`/tecnic/roles`);
    }
  };
  return (
    <div className={styles.root}>
      <SectionWrapper>
        <div className={styles.titleHeader}>
          <SectionTitle text={I18n.t('RolPermisionDetailPage.RolData.titleRol')} />
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.user_info}>
            {formFields.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                placeholder={formGroup.placeholder}
                value={formGroup.value}
                required={formGroup.required}
                readOnly={formGroup.readOnly}
                className={formGroup.className}
                onChange={handleChange}
              />
            ))}
          </div>
          <div className={styles.saveContainer}>
            <LoadingButton
              type="submit"
              text={I18n.t('RolPermisionDetailPage.RolData.update')}
              disabled={submitDisabled}
              loading={loading}
            />
          </div>
        </form>
        <div>
          {rolData && rolInfoPermisosData && (
            <RolesPermisoDataSection
              data={rolData}
              rolData={rolInfoPermisosData}
              submitDisabled
              fetchData={fetchData}
              toggleSwitch={toggleSwitch}
            />
          )}
        </div>
        {isSwitchOn && (
          <ModalMatcher
            title={`${I18n.t('RolPermisionDetailPage.RolData.mensajeModalDelete')} ${rolInfoForm?.nombre} `}
            body={`${I18n.t('RolPermisionDetailPage.RolData.mensajeModalDelete')} ${rolInfoForm?.nombre}  `}
            onClick={() => handleDeleteRole(rolesId)}
            testbutton={I18n.t('Confirmar')}
            loading={loadingDelete}
            onClick2={toggleSwitch}
          />
        )}
      </SectionWrapper>
    </div>
  );
};

export default React.memo(RolesUserPageDetalle);
