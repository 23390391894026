const Colors = {
  COLOR_ELECSUMGREEN: '#98c832',
  COLOR_ELECSUMGREEN_NOT_SELECTED: 'rgba(152, 200, 50, 0.4)',
  COLOR_GRADIENT_GREEN: '#9cc217',
  COLOR_ELECSUMSTRONG: '#72a800',
  COLOR_ELECSUMLOW: '#d5e4b4',
  COLOR_GRADIENT_YELLOW: '#f5e61e',
  COLOR_SUCCESS: '#00e096',
  COLOR_ERROR: '#d8000c',
  COLOR_ERROR_SHADOW: '#7a0006',
  COLOR_NOTIFICATIONS: '#ff5983',

  COLOR_ANDROID: '#3ddc84',
  COLOR_BROWN: '#774700',
  COLOR_PURPLE: '#9B51E0',
  COLOR_EXCEDENTS: '#0091ff',
  COLOR_GENERACIO: '#ffbe2c',
  COLOR_GENERACIO_NOT_SELECTED: 'rgba(255, 190, 44, 0.4)',
  COLOR_ORANGE_STRONG: '#fd9800',
  COLOR_YELLOW_LOW: '#ffcd6b',

  COLOR_XARXA: '#d8d8d8',
  COLOR_CONSUM: '#666666',
  COLOR_CONSUM_NOT_SELECTED: 'rgba(102, 102, 102, 0.6)',
  COLOR_BLUE_100: '#56ccf2',
  COLOR_BLUE_150: '#0091FF',
  COLOR_BLUE_200: '#2f80ed',

  COLOR_WHITE: '#ffffff',
  COLOR_GRAY_000: '#f7f7f7',
  COLOR_GRAY_050: '#eeeeee',
  COLOR_GRAY_100: '#dddddd',
  COLOR_GRAY_200: '#bbbbbb',
  COLOR_GRAY_300: '#999999',
  COLOR_GRAY_350: '#858585',
  COLOR_GRAY_400: '#777777',
  COLOR_GRAY_500: '#666666',
  COLOR_GRAY_600: '#555555',
  COLOR_GRAY_700: '#444444',
  COLOR_GRAY_800: '#333333',
  COLOR_GRAY_900: '#222222',
  COLOR_BLACK: '#000000',
  COLOR_TRANSPARENT: 'transparent',
};

export default Colors;
