/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FormGroup, FormGroupProps, I18n } from 'core';

import { SectionTitle, LoadingButton, Spinner } from 'components';

import { Installation } from 'types/models';
import { installationServiceInstance, LoggingService } from 'services';
import customToast from 'components/CustomToast/CustomToast';
import { FORMGROUP_TYPES } from 'core/form/FormGroup/FormGroup';
import Button from 'components/Button/Button';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { useDispatch } from 'react-redux';
import { fetchInstallation, fetchInstallationSuccess } from 'redux/actions/InstallationsActions';
import styles from './InstallationInfoPage.module.scss';

const DataInstallation = (props: any): JSX.Element => {
  // Props
  const {
    match: {
      params: { installationID },
    },
  } = props;

  // Hooks
  const dispatch = useDispatch();

  // States
  const [installation, setInstallation] = useState<Installation>(new Installation());
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState({});

  // Effects
  useEffect(() => {
    setLoadingData(true);
    dispatch(fetchInstallation());
    installationServiceInstance.getInstallation(installationID).then((response) => {
      dispatch(fetchInstallationSuccess(response));
      setInstallation(response);
      setLoadingData(false);
    });
  }, []);

  const formGroupsInstallationData: Array<FormGroupProps> = [
    {
      label: I18n.t('Name'),
      type: 'text',
      id: 'nombre',
      path: 'nombre',
      name: 'nombre',
      placeholder: I18n.t('Installation name'),
      value: installation.nombre,
    },
    {
      label: I18n.t('Installation cost'),
      type: 'number',
      id: 'coste',
      path: 'coste',
      name: 'coste',
      placeholder: I18n.t('Cost'),
      value: installation.coste,
    },
    {
      label: I18n.t('Number of panels'),
      type: 'number',
      id: 'numeroDePaneles',
      path: 'numeroDePaneles',
      name: 'numeroDePaneles',
      placeholder: I18n.t('Number of panels'),
      value: installation.numeroDePaneles,
    },
    {
      label: `${I18n.t('Power')} [kWp]`,
      type: 'number',
      id: 'potencia',
      path: 'potencia',
      name: 'potencia',
      placeholder: I18n.t('Power'),
      value: installation.potencia,
    },
    {
      label: I18n.t('Number of inversors'),
      type: 'number',
      id: 'numeroDeInversores',
      path: 'numeroDeInversores',
      name: 'numeroDeInversores',
      placeholder: I18n.t('Quantity of inversors'),
      value: installation.numeroDeInversores,
    },
    {
      label: I18n.t('Inversors Power [kWh]'),
      type: 'number',
      id: 'potenciaInversor',
      path: 'potenciaInversor',
      name: 'potenciaInversor',
      placeholder: I18n.t('Inversors Power'),
      value: installation.potenciaInversor,
    },
    {
      label: I18n.t('Total Power [kWh]'),
      type: 'number',
      id: 'potenciaTotal',
      path: 'potenciaTotal',
      name: 'potenciaTotal',
      placeholder: I18n.t('Inversors Power'),
      value: installation.potenciaTotal,
    },
    {
      label: I18n.t('Orientation (º)'),
      type: 'text',
      id: 'orientacion',
      path: 'orientacion',
      name: 'orientacion',
      placeholder: I18n.t('Orientation (º)'),
      value: installation.orientacion,
    },
    {
      label: I18n.t('Tilt (º)'),
      type: 'text',
      id: 'inclinacio',
      path: 'inclinacio',
      name: 'inclinacio',
      placeholder: I18n.t('Tilt (º)'),
      value: installation.inclinacion,
    },
    {
      label: I18n.t('Energy ratio'),
      type: 'number',
      id: 'ratioEnergetico',
      path: 'ratioEnergetico',
      name: 'ratioEnergetico',
      placeholder: I18n.t('Energy ratio'),
      value: installation.ratioEnergetico,
    },
    {
      label: I18n.t('Warranty'),
      type: FORMGROUP_TYPES.select,
      id: 'garantia',
      path: 'garantia',
      name: 'garantia',
      placeholder: I18n.t('Has warranty? Yes/No'),
      value: installation.garantia,
      options: [
        { label: 'yes', value: 'si' },
        { label: 'no', value: 'no' },
      ],
    },
    {
      label: I18n.t('If so, how many years?'),
      type: 'number',
      id: 'garantiaAños',
      path: 'garantiaAños',
      name: 'garantiaAños',
      placeholder: '',
      value: installation.garantiaAños,
    },
    {
      label: I18n.t('Service agreement?'),
      type: FORMGROUP_TYPES.select,
      id: 'contratoMantenimiento',
      path: 'contratoMantenimiento',
      name: 'contratoMantenimiento',
      placeholder: I18n.t('Service agreement? Yes/No'),
      value: installation.contratoMantenimiento,
      options: [
        { label: 'yes', value: 'si' },
        { label: 'no', value: 'no' },
      ],
    },
    {
      label: I18n.t('Installation type (legalization)'),
      type: 'text',
      id: 'tipoDeInstalacionLegalizacion',
      path: 'tipoDeInstalacionLegalizacion',
      name: 'tipoDeInstalacionLegalizacion',
      placeholder: '(RD XXX/YY)',
      value: installation.tipoDeInstalacionLegalizacion,
    },
    {
      label: I18n.t('Date of reception of work'),
      type: 'text',
      id: 'fechaRecepcionObra',
      path: 'fechaRecepcionObra',
      name: 'fechaRecepcionObra',
      placeholder: I18n.t('yyyy-mm-dd (year-month-day)'),
      value: installation.fechaRecepcionObra,
    },
    {
      label: I18n.t('Start date production monitoring'),
      type: 'text',
      id: 'fechaInicioSeguimientoProduccion',
      path: 'fechaInicioSeguimientoProduccion',
      name: 'fechaInicioSeguimientoProduccion',
      placeholder: I18n.t('yyyy-mm-dd (year-month-day)'),
      value: installation.fechaInicioSeguimientoProduccion,
    },
    {
      label: I18n.t('Serial number consumption meter'),
      type: 'text',
      id: 'numeroDeSerieContadorDeConsumo',
      path: 'numeroDeSerieContadorDeConsumo',
      name: 'numeroDeSerieContadorDeConsumo',
      placeholder: I18n.t('Serial number meter'),
      value: installation.numeroDeSerieContadorDeConsumo,
    },
  ];

  const formGroupsPanelModel: Array<FormGroupProps> = [
    {
      label: I18n.t('Manufacturer'),
      type: 'text',
      id: 'panelFabricante',
      path: 'panelFabricante',
      name: 'panelFabricante',
      placeholder: I18n.t("Panel manufacturer's name"),
      value: installation.panelFabricante,
    },
    {
      label: I18n.t('Model'),
      type: 'text',
      id: 'panelModelo',
      path: 'panelModelo',
      name: 'panelModelo',
      placeholder: I18n.t('Panel model name'),
      value: installation.panelModelo,
    },
    {
      label: I18n.t('Inverter (Wn)'),
      type: 'number',
      id: 'inversorPanelInversor',
      path: 'inversorInversor',
      name: 'inversorInversor',
      placeholder: I18n.t('Inverter power'),
      value: installation.inversorInversor,
    },
    {
      label: I18n.t('Degradation year 0 (%)'),
      type: 'number',
      id: 'panelDegradacion0Años',
      path: 'panelDegradacion0Años',
      name: 'panelDegradacion0Años',
      placeholder: I18n.t('(%) degradation'),
      value: installation.panelDegradacion0Años,
    },
    {
      label: I18n.t('Degradation year 1 (%)'),
      type: 'number',
      id: 'panelDegradacion1Años',
      path: 'panelDegradacion1Años',
      name: 'panelDegradacion1Años',
      placeholder: I18n.t('(%) degradation'),
      value: installation.panelDegradacion1Años,
    },
    {
      label: I18n.t('Degradation year 2 (%)'),
      type: 'number',
      id: 'panelDegradacion2Años',
      path: 'panelDegradacion2Años',
      name: 'panelDegradacion2Años',
      placeholder: I18n.t('(%) degradation'),
      value: installation.panelDegradacion2Años,
    },
    {
      label: I18n.t('Degradation year 10 (%)'),
      type: 'number',
      id: 'panelDegradacion10Años',
      path: 'panelDegradacion10Años',
      name: 'panelDegradacion10Años',
      placeholder: I18n.t('(%) degradation'),
      value: installation.panelDegradacion10Años,
    },
    {
      label: I18n.t('Degradation year 25 (%)'),
      type: 'number',
      id: 'panelDegradacion25Años',
      path: 'panelDegradacion25Años',
      name: 'panelDegradacion25Años',
      placeholder: I18n.t('(%) degradation'),
      value: installation.panelDegradacion25Años,
    },
  ];

  const formGroupsInversorModel: Array<FormGroupProps> = [
    {
      label: I18n.t('Manufacturer'),
      type: 'text',
      id: 'inversorFabricante',
      path: 'inversorFabricante',
      name: 'inversorFabricante',
      placeholder: I18n.t("Inverter manufacturer's name"),
      value: installation.inversorFabricante,
    },
    {
      label: I18n.t('Model'),
      type: 'text',
      id: 'inversorModelo',
      path: 'inversorModelo',
      name: 'inversorModelo',
      placeholder: I18n.t("Inverter model's name"),
      value: installation.inversorModelo,
    },
    {
      label: I18n.t('Inverter (Wn)'),
      type: 'number',
      id: 'inversorInversor',
      path: 'inversorInversor',
      name: 'inversorInversor',
      placeholder: I18n.t('Inverter power'),
      value: installation.inversorInversor,
    },
    {
      label: I18n.t('Degradation year 0 (%)'),
      type: 'number',
      id: 'inversorDegradacion0Años',
      path: 'panelDegradacion0Años',
      name: 'panelDegradacion0Años',
      placeholder: I18n.t('(%) degradation'),
      value: installation.panelDegradacion0Años,
    },
  ];

  const formGroupsInstallationLocation: Array<FormGroupProps> = [
    {
      label: I18n.t('Latitude'),
      type: 'number',
      id: 'latitud',
      path: 'latitud',
      name: 'latitud',
      placeholder: I18n.t('Latitude'),
      value: installation.latitud,
    },
    {
      label: I18n.t('Longitude'),
      type: 'number',
      id: 'longitud',
      path: 'longitud',
      name: 'longitud',
      placeholder: I18n.t('Longitude'),
      value: installation.longitud,
    },
  ];

  const formGroupsInstallationRadius: Array<FormGroupProps> = [
    {
      label: I18n.t('Radio for comparative monitoring with other facilities (km)'),
      type: 'range',
      id: 'seguimientoRadioInstalaciones',
      path: 'seguimientoRadioInstalaciones',
      name: 'seguimientoRadioInstalaciones',
      units: 'km',
      min: 1,
      step: 1,
      max: 100,
      value: installation.seguimientoRadioInstalaciones,
    },
  ];

  const formGroupsInstallationDeviation: Array<FormGroupProps> = [
    {
      label: I18n.t('Maximum allowable deviation between facilities (%)'),
      type: 'range',
      id: 'seguimientoDesviacion',
      path: 'seguimientoDesviacion',
      name: 'seguimientoDesviacion',
      units: '%',
      min: 0,
      step: 0.1,
      max: 50,
      value: installation.seguimientoDesviacion,
    },
  ];

  const formGroupsInstallationNotes: Array<FormGroupProps> = [
    {
      type: 'textarea',
      id: 'notas',
      path: 'notas',
      name: 'notas',
      rows: 10,
      cols: 50,
      placeholder: I18n.t('Enter a note or reminder'),
      value: installation.notas,
    },
  ];

  const validateForm = (): boolean => {
    setSubmitDisabled(false);
    setErrors({});
    return true;
  };

  const submitForm = async (): Promise<boolean> => {
    setLoading(true);
    setSubmitDisabled(true);

    try {
      await installationServiceInstance.putInstallation(installation.id, installation);
      const updatedInstallation = await installationServiceInstance.getInstallation(installation.id);
      installationServiceInstance.installation = updatedInstallation;
      setInstallation(updatedInstallation);
      setLoading(false);
      setSubmitDisabled(false);
      customToast.success(I18n.t('Installation saved!'));
    } catch (error) {
      setLoading(false);
      setSubmitDisabled(false);
      toast.error(I18n.t('Error Updating Installation'));
    }

    return true;
  };

  function handleChange<T>(path: string, value: T): void {
    installation.setAttr<T>(path, value);

    setInstallation(installation); // veig que aixo no cal

    validateForm();
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (validateForm()) {
      LoggingService.log('handleSubmit :: ok', { installation });
      submitForm();
    } else {
      LoggingService.log('handleSubmit :: errors', { installation, errors });
    }
  };

  if (!installation) {
    return <div className={styles.notselected}>{I18n.t('installationNotSelected')}.</div>;
  }

  return (
    <div className={styles.root}>
      {loadingData ? (
        <div className={styles.loading_wrapper}>
          <Spinner icon />
        </div>
      ) : (
        <form className={styles.formcontainer} onSubmit={handleSubmit}>
          <SectionWrapper>
            <SectionTitle text={I18n.t('Installation Data')} />
            <div className={styles.fields}>
              {formGroupsInstallationData.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  placeholder={formGroup.placeholder}
                  value={formGroup.value}
                  required={formGroup.required}
                  onChange={handleChange}
                  options={formGroup.options}
                />
              ))}
            </div>
          </SectionWrapper>
          <div>
            <SectionWrapper>
              <SectionTitle text={I18n.t('Panel Model')} />
              <div className={styles.fields}>
                {formGroupsPanelModel.map((formGroup: FormGroupProps) => (
                  <FormGroup
                    key={formGroup.id}
                    label={formGroup.label}
                    type={formGroup.type}
                    id={formGroup.id}
                    path={formGroup.path}
                    name={formGroup.name}
                    placeholder={formGroup.placeholder}
                    value={formGroup.value}
                    required={formGroup.required}
                    onChange={handleChange}
                  />
                ))}
              </div>
            </SectionWrapper>
            <SectionWrapper>
              <SectionTitle text={I18n.t('Inverter Model')} />
              <div className={styles.fields}>
                {formGroupsInversorModel.map((formGroup: FormGroupProps) => (
                  <FormGroup
                    key={formGroup.id}
                    label={formGroup.label}
                    type={formGroup.type}
                    id={formGroup.id}
                    path={formGroup.path}
                    name={formGroup.name}
                    placeholder={formGroup.placeholder}
                    value={formGroup.value}
                    required={formGroup.required}
                    onChange={handleChange}
                  />
                ))}
              </div>
            </SectionWrapper>
          </div>
          <div>
            <SectionWrapper>
              <SectionTitle text={I18n.t('Location facilitie')} />
              <div className={styles.fields}>
                {formGroupsInstallationLocation.map((formGroup: FormGroupProps) => (
                  <FormGroup
                    key={formGroup.id}
                    label={formGroup.label}
                    type={formGroup.type}
                    id={formGroup.id}
                    path={formGroup.path}
                    name={formGroup.name}
                    placeholder={formGroup.placeholder}
                    value={formGroup.value}
                    required={formGroup.required}
                    onChange={handleChange}
                  />
                ))}
              </div>
            </SectionWrapper>
            <SectionWrapper>
              <SectionTitle text={I18n.t('Facilitie radius')} />
              {formGroupsInstallationRadius.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  value={formGroup.value}
                  required={formGroup.required}
                  units={formGroup.units}
                  min={formGroup.min}
                  max={formGroup.max}
                  step={formGroup.step}
                  onChange={handleChange}
                />
              ))}
            </SectionWrapper>
            <SectionWrapper>
              <SectionTitle text={I18n.t('Deviation')} />
              {formGroupsInstallationDeviation.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  value={formGroup.value}
                  required={formGroup.required}
                  units={formGroup.units}
                  min={formGroup.min}
                  max={formGroup.max}
                  step={formGroup.step}
                  onChange={handleChange}
                />
              ))}
              <div />
            </SectionWrapper>
            <SectionWrapper>
              <SectionTitle text={I18n.t('Notes')} />
              {formGroupsInstallationNotes.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  value={formGroup.value}
                  required={formGroup.required}
                  placeholder={formGroup.placeholder}
                  onChange={handleChange}
                />
              ))}
            </SectionWrapper>
          </div>
          <span />
          <span />
          <div className={styles.actions_container}>
            <div className={styles.action}>
              <Button block type="button" variant="secondary" text={I18n.t('Cancel')} />
            </div>
            <div className={styles.action}>
              <LoadingButton block type="submit" text={I18n.t('Save')} disabled={submitDisabled} loading={loading} />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default DataInstallation;
