import React from 'react';
import { I18n } from 'core';

import { VictoryAxis, VictoryPie, VictoryChart } from 'victory';
import { Colors } from 'styles';
import wattsConverterService from 'services/local/WattsConverter';
import classNames from 'classnames';
import styles from './EnergyCard.module.scss';

interface ListItemsPropsI {
  color: string;
  name: string;
  data: string;
  unit: string;
}

interface EnergyCardPropsI {
  date: string;
  generationData: number;
  shrinkData: number;
  networkData: number;
  selfConsumptionData: number;
  consumptionData: number;
}

const ListItem = (props: ListItemsPropsI): JSX.Element => {
  const { color, name, data, unit } = props;

  return (
    <div className={styles.listitem}>
      <div className={styles.leftContainer}>
        <div className={classNames(color, styles.dot)} />
        <p className={styles.text}>{name}</p>
      </div>
      <div className={styles.rightContainer}>
        <p className={styles.data}>{data}</p>
        <p className={styles.unit}>{unit}</p>
      </div>
    </div>
  );
};

const EnergyCard = (props: EnergyCardPropsI): JSX.Element => {
  const { date, generationData, shrinkData, networkData, selfConsumptionData, consumptionData } = props;

  const bigestRadius = 150;

  const listItemsArray = [
    { color: 'bck-orange', name: 'generation', data: generationData },
    { color: 'bck-blue', name: 'shrinking', data: shrinkData },
    { color: 'bck-grey', name: 'network', data: networkData },
    { color: 'bck-green', name: 'selfConsumption', data: selfConsumptionData },
    { color: 'bck-dark-grey', name: 'consumption', data: consumptionData },
  ];

  const getMonth = (): string => {
    const monthArray = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const month = new Date(date).getMonth();
    const monthTranslated = I18n.t(monthArray[month]);
    return monthTranslated;
  };

  const getYear = (): number => {
    const today = new Date();
    const year = today.getFullYear();
    return year;
  };

  const subTitleText = (): string => {
    const textTranslated = I18n.t('current month');
    return textTranslated;
  };

  return (
    <>
      {networkData === 0 &&
      generationData === 0 &&
      selfConsumptionData === 0 &&
      shrinkData === 0 &&
      consumptionData === 0 ? (
        <div className={styles.nodata}>{I18n.t('noDataObtained')}.</div>
      ) : (
        <div key={1}>
          <p className={styles.chart_title}>{`${subTitleText()} (${getMonth()} ${getYear()})`}</p>
          <VictoryChart height={190}>
            <VictoryAxis
              style={{
                axis: { stroke: 'transparent' },
                ticks: { stroke: 'transparent' },
                tickLabels: { fill: 'transparent' },
              }}
            />
            <VictoryAxis
              style={{
                axis: { stroke: 'transparent' },
                ticks: { stroke: 'transparent' },
                tickLabels: { fill: 'transparent' },
              }}
            />
            <VictoryPie
              innerRadius={bigestRadius}
              radius={bigestRadius + 15}
              startAngle={90}
              endAngle={-90}
              labels={(): string => ''}
              colorScale={[Colors.COLOR_WHITE, Colors.COLOR_GENERACIO]}
              data={[
                { x: 1, y: networkData },
                { x: 2, y: generationData },
              ]}
              groupComponent={<g transform="translate(0 85)" />}
            />
            <VictoryPie
              innerRadius={bigestRadius - 30}
              radius={bigestRadius - 15}
              startAngle={90}
              endAngle={-90}
              labels={(): string => ''}
              colorScale={[Colors.COLOR_GRAY_100, Colors.COLOR_ELECSUMGREEN, Colors.COLOR_EXCEDENTS]}
              data={[
                { x: 1, y: networkData },
                { x: 2, y: selfConsumptionData },
                { x: 3, y: shrinkData },
              ]}
              groupComponent={<g transform="translate(0 85)" />}
            />
            <VictoryPie
              innerRadius={bigestRadius - 60}
              radius={bigestRadius - 45}
              startAngle={90}
              endAngle={-90}
              labels={(): string => ''}
              colorScale={[Colors.COLOR_GRAY_500, Colors.COLOR_WHITE]}
              data={[
                { x: 2, y: consumptionData },
                { x: 1, y: shrinkData },
              ]}
              groupComponent={<g transform="translate(0 85)" />}
            />
          </VictoryChart>
          <div>
            {listItemsArray.map(
              (item): JSX.Element => (
                <ListItem
                  key={item.name}
                  color={item.color}
                  name={I18n.t(item.name)}
                  data={wattsConverterService.toSelectedMagnitudeString(item.data, 'kWh')}
                  unit="kWh"
                />
              ),
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default EnergyCard;
