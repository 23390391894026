interface AmortizationDataAPII {
  importeAmortizado: string;
  importeTotal: number;
  porcentajeAmortizado: number;
}

export default class AmortizationData {
  amortized: string;

  total: number;

  amortizedPercentage: number;

  deserialize(input: AmortizationDataAPII): AmortizationData {
    if (!input) {
      return this;
    }

    this.amortized = input.importeAmortizado;
    this.total = input.importeTotal;
    this.amortizedPercentage = input.porcentajeAmortizado;

    return this;
  }
}
