/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useEffect } from 'react';
import MeasuremenRegistersObject from 'types/models/widgetsCIM/measurementsRegistersObj';
import { Line } from 'react-chartjs-2';
import { Colors } from 'styles';
import 'chartjs-adapter-date-fns';
import wattsConverterService from 'services/local/WattsConverter';
import { Chart } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { I18n } from 'core';
import { MeasurementRegistersMaximeter } from 'types/models/widgetsCIM';
import { ca, es } from 'date-fns/locale';

const locales: any = {
  es,
  ca,
};

Chart.register(zoomPlugin);

interface MeasurementChartPropsI {
  data: MeasurementRegistersMaximeter;
}
const colorset = {
  activa: '#ff5983',
  capacitiva: '#56ccf2',
  inductiva: '#555555',
};
const MeasurementChart = (props: MeasurementChartPropsI): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data } = props;

  const chartRef = useRef<any>(null);
  const TENSION = 0.25;
  const BORDER_WIDTH = 1.25;
  const POINT_RADIUS = 0;
  useEffect(() => {
    if (chartRef) {
      if (chartRef.current) {
        chartRef.current.chartInstance?.resetZoom();
      }
    }
  }, [data]);

  const parsedData = data.datos.map((point) => ({
    x: point.fecha,
    activa: point.activa,
    contratada: point.contratada,
  }));

  return (
    <Line
      type="line"
      ref={chartRef}
      data={{
        datasets: [
          {
            label: I18n.t('CIM.MeasurementRegistersPage.active'),
            data: parsedData,
            borderColor: colorset.activa,
            backgroundColor: colorset.activa,
            borderDashOffset: 0.0,
            borderWidth: BORDER_WIDTH,
            pointRadius: POINT_RADIUS,
            tension: TENSION,
            parsing: {
              yAxisKey: 'activa',
            },
          },
          {
            label: I18n.t('CIM.MeasurementRegistersPage.hired'),
            data: parsedData,
            borderColor: colorset.capacitiva,
            backgroundColor: colorset.capacitiva,
            borderDashOffset: 0.0,
            borderWidth: BORDER_WIDTH,
            pointRadius: POINT_RADIUS,
            tension: TENSION,
            parsing: {
              yAxisKey: 'contratada',
            },
          },
        ],
      }}
      options={{
        responsive: true,
        animation: false,
        maintainAspectRatio: false,
        layout: {
          padding: 10,
        },
        scales: {
          x: {
            color: Colors.COLOR_GRAY_200,
            type: 'time',
            display: true,
            adapters: {
              date: {
                locale: locales[I18n.currentLocale().substring(0, 2)] || locales.es,
              },
            },
            ticks: { maxRotation: 0, minRotation: 0 },
            time: {
              tooltipFormat: 'yyyy/mm/dd HH:mm',
              unit: 'day',
              displayFormats: {
                second: 'HH:MM:SS',
                minute: 'HH:mm',
                hour: 'ddd HH:mm',
                day: 'MMMM dd',
                week: 'MMMM dd',
                month: 'MMMM dd',
                year: 'MMMM dd',
              },
            },
          },
          y: {
            title: {
              display: true,
              text: 'kW',
            },
            ticks: {
              callback: (value: any) => `${wattsConverterService.toSelectedMagnitudeString(value, 'kW')} kW`,
            },
          },
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          tooltip: {
            enabled: true,
            cornerRadius: 8,
            callbacks: {
              label: (tooltipItem: any, dt: any) =>
                `${wattsConverterService.toSelectedMagnitudeString(tooltipItem.parsed.y, 'kW')} kWh`,
            },
          },
          legend: {
            position: 'top',
            align: 'center',
            labels: {
              pointStyle: 'circle',
              usePointStyle: true,
            },
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'x',
              speed: 100,
              threshold: 100,
            },
            zoom: {
              enabled: true,
              drag: false,
              mode: 'x',
              wheel: {
                enabled: true,
              },
              limits: {
                max: 10,
                min: 0.5,
              },
            },
          },
        },
      }}
    />
  );
};

export default React.memo(MeasurementChart);
