import React from 'react';
import cx from 'classnames';
import styles from './TabSwitchIcons.module.scss';

export interface TabSwitchOption {
  id: number | string;
  value: string | number;
  icon?: JSX.Element | null;
  label: string;
}

export interface TabSwitchPropsI {
  value: TabSwitchOption;
  values: TabSwitchOption[];
  onChange(item: TabSwitchOption): void;
}

export const TabSwitchIcons = (props: TabSwitchPropsI): JSX.Element => {
  const { value, values, onChange } = props;
  return (
    <div
      className={styles.wrapper}
      style={{
        gridTemplateColumns: `repeat( ${values.length}, 1fr)`,
      }}
    >
      {values.map((option) => (
        <button
          key={option.id}
          type="button"
          onClick={() => onChange(option)}
          className={cx(styles.opt, {
            [styles.active]: option.id === value.id,
          })}
        >
          {option.icon ? <div style={{ width: '20px', height: '20px', color: '#666666' }}>{option.icon}</div> : null}
          {option.label}
        </button>
      ))}
    </div>
  );
};
