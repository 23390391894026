import React from 'react';
import { ReactComponent as UserIcon } from 'assets/icons/User.svg';
import { Colors } from 'styles';
import { navigate } from 'navigation';
import styles from './SearchItems.module.scss';

interface UserSearchItemPropsI {
  nombre: string;
  identificador: string;
  email: string;
}

const UserSearchItem = (props: UserSearchItemPropsI): JSX.Element => {
  const { nombre, identificador, email } = props;

  const handleClick = (): void => {
    navigate(`/tecnic/user/${identificador}/data`);
  };
  return (
    <button onClick={() => handleClick()} type="button" className={styles.root}>
      <div className={styles.icon_wrapper}>
        <UserIcon height={25} width={25} fill={Colors.COLOR_BLUE_150} />
      </div>
      <div className={styles.info_wrapper}>
        <p className={styles.title}>{nombre}</p>
        <p className={styles.subtitle}>{email}</p>
        <p className={styles.information}>{identificador}</p>
      </div>
    </button>
  );
};

export default React.memo(UserSearchItem);
