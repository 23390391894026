import React, { useState, useEffect } from 'react';
import { I18n } from 'core';
import { TableHeading, SectionTitle, Spinner } from 'components';
import { clientServiceInstance } from 'services';
import { Alert } from 'types/models';
import { toast } from 'react-toastify';
import { navigate } from 'navigation';
import Button from 'components/Button/Button';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import TableAlerts from 'components/TableAlerts/TableAlerts';
import styles from './AlertsPage.module.scss';

const AlertsPage = (): JSX.Element => {
  const [alerts, setAlerts] = useState<Array<Alert> | null>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [dataError, setDataError] = useState<boolean>(false);

  const fetchData = async (): Promise<void> => {
    try {
      const apiData: Array<Alert> = await clientServiceInstance.getAlerts();
      setAlerts(apiData);
      setDataError(false);
    } catch (error) {
      setDataError(true);
      toast.error(I18n.t('errorGetAlertsData'));
    }
    setDataLoading(false);
  };

  const handleChangeSelect = async (path: string, value: number, id: string): Promise<void> => {
    try {
      await clientServiceInstance.modifyAlert(id, value);
      fetchData();
    } catch (error) {
      toast.error(I18n.t('errorModifyingAlert'));
    }
  };

  // const selectInstallation = async (tableItem: TableItem): Promise<void> => {
  //   navigate(`/tecnic/installation/${tableItem.idInstalation}/installation`);
  // };

  const headings: Array<TableHeading> = [
    // {
    //   label: I18n.t('Installation'),
    //   key: 'client',
    //   type: 'link',
    //   handleLink: selectInstallation,
    // },
    { label: I18n.t('description'), key: 'description' },
    { label: I18n.t('date'), key: 'date' },
    { label: I18n.t('hour'), key: 'hour' },
    {
      label: I18n.t('status'),
      key: 'status',
      type: 'input',
      inputType: 'select',
      showDots: true,
      handleInput: handleChangeSelect,
      itemsArray: [
        { value: 0, label: 'pending' },
        { value: 1, label: 'doing' },
        { value: 2, label: 'done' },
      ],
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Button
          className={styles.viewmore_button}
          variant="tertiary"
          type="button"
          onClick={(): Promise<void> => navigate('/tecnic/dashboard')}
          text={I18n.t('AlertsPage.goDashboard')}
        />
      </div>
      <SectionWrapper customClassname={styles.content}>
        <SectionTitle text={I18n.t('alertsList')} />
        {(() => {
          if (!alerts) {
            return (
              <div className={styles.loading_wrapper}>
                <Spinner icon />
              </div>
            );
          }
          if (dataError) {
            return <p className={styles.error_message}>{I18n.t('errorGetAlertsData')}</p>;
          }
          return alerts.length > 0 ? (
            <TableAlerts headings={headings} rows={alerts} loading={dataLoading} />
          ) : (
            <p className={styles.error_message}>{I18n.t('noAlertsMessage')}</p>
          );
        })()}
      </SectionWrapper>
    </div>
  );
};

export default AlertsPage;
