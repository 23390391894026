interface EvolutionArrayAPII {
  fecha: string;
  generacion: number;
  garantia: number;
}

interface GuaranteeDataAPII {
  periodoInicio: string;
  periodoFin: string;
  porcentajeGarantiaLogrado: number;
  generacion: number;
  garantia: number;
  evolucionGarantia: Array<EvolutionArrayAPII>;
}

interface EvolutionArrayI {
  x: string;
  y: number;
  index: number;
}

export default class GuaranteeObject {
  startDate: string;

  finalDate: string;

  guaranteePercentage: number;

  generation: number;

  guarantee: number;

  productionGuaranteeArray: Array<EvolutionArrayI>;

  generationGuaranteeArray: Array<EvolutionArrayI>;

  deserialize(input: GuaranteeDataAPII): GuaranteeObject {
    if (!input) {
      return this;
    }

    this.startDate = input.periodoInicio || '';
    this.finalDate = input.periodoFin || '';
    this.guaranteePercentage = input.porcentajeGarantiaLogrado || 0;
    this.generation = input.generacion || 1;
    this.guarantee = input.garantia || 0;
    this.productionGuaranteeArray =
      input.evolucionGarantia.map(
        (evolution: EvolutionArrayAPII, index: number): EvolutionArrayI => ({
          x: evolution.fecha || '',
          y: evolution.garantia || 0,
          index,
        }),
      ) || [];
    this.generationGuaranteeArray =
      input.evolucionGarantia.map(
        (evolution: EvolutionArrayAPII, index: number): EvolutionArrayI => ({
          x: evolution.fecha || '',
          y: evolution.generacion || 0,
          index,
        }),
      ) || [];

    return this;
  }
}
