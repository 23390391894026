import configuration from 'config';
import { AxiosResponse } from 'axios';
import { Solicitudes } from 'types/models';
import apiService from './ApiService';

class SolicitudesService {
  private readonly http = apiService;

  private apiUrl = configuration.apiUrl;

  public async getSolicitudesByNIF(nif: string) {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/usuario/cups-autorizados/${nif}/solicitudes/`);
    const { datos } = response.data;

    const solicitudes = new Solicitudes().deserializeArray(datos.solicitudes);

    return solicitudes;
  }

  public async createSolicitud(nif: string, cups: string) {
    const solicitud = { cups };
    const response: AxiosResponse = await this.http.post(
      `${this.apiUrl}/usuario/cups-autorizados/${nif}/solicitudes/`,
      solicitud,
    );
    return response.data;
  }

  public async updateSolicitud(nif: string, solicitudId: string, solicitudData: 'si' | 'no') {
    const decision = {
      acceptada: solicitudData,
    };
    const response: AxiosResponse = await this.http.patch(
      `${this.apiUrl}/usuario/cups-autorizados/${nif}/solicitudes/${solicitudId}/`,
      decision,
    );
    return response.data;
  }

  public async getSolicitudById(cups: string, solicitudId: string) {
    const response: AxiosResponse = await this.http.get(
      `${this.apiUrl}/usuario/cups-autorizados/${cups}/solicitudes/${solicitudId}/acceptar/`,
    );
    return new Solicitudes().deserialize(response.data);
  }

  public async acceptOrRejectSolicitud(cups: string, solicitudId: string, decision: string) {
    const decisionUser = {
      acceptada: decision,
    };
    const response: AxiosResponse = await this.http.post(
      `${this.apiUrl}/usuario/cups-autorizados/${cups}/solicitudes/${solicitudId}/acceptar/`,
      decisionUser,
    );
    return response.data;
  }
}

const solicitudesService: SolicitudesService = new SolicitudesService();

export default solicitudesService;
