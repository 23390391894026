const Constants = {
  charts: {
    AXIS_VALUES_FONTSIZE: '12px',
    AXIS_LABELS_FONTSIZE: '14px',
    BAR_CORNER_RADIUS: 2,
    BAR_RATIO_FILL: 0.9,
  },
  roles: {
    default: 'ROLE_DEFAULT',
    user: {
      key: 'ROLE_USER',
      rule: '/tecnic/user/',
    },
    cups: {
      key: 'ROLE_CUPS',
      rule: '/tecnic/cups/',
    },
    installation: {
      key: 'ROLE_INSTALLATION',
      rule: '/tecnic/installation/',
    },
    client: {
      key: 'ROLE_CLIENT',
      rule: '/client/',
    },
    community: {
      key: 'ROLE_COMMUNITY',
      rule: '/tecnic/communities',
    },
    communityEnrolled: {
      key: 'ROLE_COMMUNITYENROLLED',
      rule: '/tecnic/inscritos',
    },
    detallesCommunity: {
      key: 'ROLE_COMMUNITY_Detalles',
      rule: '/tecnic/communities/:communityID',
    },
    promotor: {
      key: 'ROLE_CLIENT',
      rule: '/promotor',
    },
    matcher: {
      key: 'ROLE_USER',
      rule: '/tecnic/matcher',
    },
    rolePermision: {
      key: 'ROLE_USER',
      rule: '/tecnic/roles',
    },
  },
};

export default Constants;
