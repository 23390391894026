import React, { useState, useRef } from 'react';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/ArrowDown.svg';
import cx from 'classnames';
import { useOutsideClick } from 'hooks';
import { InscritosModel } from 'types/models/inscritos';
import styles from './ElecsumSelect.module.scss';

export interface SelectItemI {
  id: string;
  value: string | null;
  label: string;
}

interface ElecsumInscritoSelectPropsI {
  selected?: InscritosModel;
  values: Array<InscritosModel>;
  label: string;
  minWidth?: '7.5rem' | '10rem' | '12.5rem' | '15rem' | '17.5rem' | '20rem';
  onChange(element: InscritosModel): void;
}

const ElecsumInscritoSelect = (props: ElecsumInscritoSelectPropsI) => {
  const dropdownRef = useRef(null);
  const { selected, values, label, onChange, minWidth } = props;

  const [open, setOpen] = useState(false);

  useOutsideClick(dropdownRef, () => {
    setOpen(false);
  });

  const getHeadLabel = (): string => {
    if (!selected) return `${label}`;
    if (selected.id === null) return `${label}`;
    return `${selected.nombre ? selected.nombre : 'no hay valor nombre'} ${
      selected.apellidos ? selected.apellidos : 'no hay valor apellidos'
    } - ${selected.nif ? selected.nif : 'no hay valor nif'} - ${selected.cups ? selected.cups : 'no hay valor cups'}`;
  };

  return (
    <div style={{ minWidth }} ref={dropdownRef} className={styles.container}>
      <button onClick={() => setOpen(!open)} className={cx(styles.head, { [styles.open]: open })} type="button">
        <span className={styles.label}>{getHeadLabel()}</span>
        <ArrowDownIcon />
      </button>

      {open && (
        <div className={cx(styles.tableContainer)}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Apellidos</th>
                <th>NIF</th>
                <th>CUPS</th>
              </tr>
            </thead>
            <tbody>
              {values.map((item) => (
                <tr
                  key={item.id}
                  onClick={() => {
                    setOpen(false);
                    onChange(item);
                  }}
                  className={cx(styles.itemRow, { [styles['item--selected']]: item.id === selected?.id })}
                >
                  <td>{item.nombre}</td>
                  <td>{item.apellidos}</td>
                  <td>{item.nif ? item.nif : 'no hay valor nif'}</td>
                  <td>{item.cups ? item.cups : 'no hay valor cups'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ElecsumInscritoSelect;
