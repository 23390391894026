import React from 'react';
import cx from 'classnames';
import styles from './CommonStyle.module.scss';

interface HelperPropsI {
  type: 'default' | 'warning' | 'error';
  value: string;
}

interface NewInputPropsI {
  type: 'text' | 'password' | 'email' | 'number';
  label?: string;
  placeholder?: string;
  value: any;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  id: string;
  name: string;
  className?: string;
  helper?: HelperPropsI;
  disabled?: boolean;
}

const ControlledInput = (props: NewInputPropsI) => {
  const { type, label, placeholder, onChange, id, name, className, value, disabled } = props;
  return (
    <div className={styles.container}>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        placeholder={placeholder}
        id={id}
        name={name}
        className={cx(className)}
        value={value}
        onChange={onChange}
        type={type}
        disabled={disabled}
      />
      {/*
      {touched[field.name] && errors[field.name] && <div className="error">{errors[field.name]}</div>} */}
    </div>
  );
};
export default React.memo(ControlledInput);
