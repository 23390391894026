import React from 'react';
import { I18n } from 'core';
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryStack,
  VictoryBar,
  VictoryLabel,
  VictoryContainer,
} from 'victory';
import { Colors } from 'styles';
import { TeledetectionData } from 'types/models';

import { ViewDate, ChartDataList } from 'components';
import { WattsConverterService } from 'services';
import { SizeMe } from 'react-sizeme';
import Constants from 'config/constants';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import styles from './ProductionChartsCommon.module.scss';

interface DateType {
  day: number;
  month: number;
  year: number;
}
interface TeledetectionChartPropsI {
  teledetectionData: TeledetectionData;
  date: DateType;
  currentTab: string;
  generation: number;
  surplus: number;
  selfConsumption: number;
  network: number;
  diffGeneration: number;
  isLoading?: boolean;
  radius: number;
}

const ProductionDailyChart = (props: TeledetectionChartPropsI): JSX.Element => {
  const {
    teledetectionData,
    date,
    currentTab,
    generation,
    surplus,
    selfConsumption,
    network,
    diffGeneration,
    radius,
    isLoading,
  } = props;

  const TOTAL_TICKS = 5;

  const listItemsArray = [
    { color: 'bck-orange', name: I18n.t('generation'), data: generation },
    { color: 'bck-blue', name: I18n.t('surplus'), data: surplus },
    {
      color: 'bck-green',
      name: I18n.t('selfConsumption'),
      data: selfConsumption,
    },
    { color: 'bck-grey', name: I18n.t('network'), data: network },
    {
      color: '',
      textColor: 'text-red',
      name: `${I18n.t('differenceInst')} ${radius}km`,
      data: diffGeneration,
    },
  ];

  if (isLoading) {
    return <FullPageSyncLoader />;
  }

  return (
    <div className={styles.root}>
      <SizeMe>
        {({ size }) => (
          <div>
            <svg viewBox={`0 0 ${size.width} 400`} height="100%" width="100%">
              <VictoryChart
                width={size.width || 500}
                height={400}
                standalone={false}
                domainPadding={{ x: 0, y: 10 }}
                containerComponent={<VictoryContainer responsive={false} />}
              >
                <VictoryLabel
                  style={{ fontSize: Constants.charts.AXIS_LABELS_FONTSIZE }}
                  text="kWh"
                  x={30}
                  y={30}
                  textAnchor="middle"
                />
                <VictoryAxis
                  style={{
                    axis: { stroke: 'transparent' },
                    tickLabels: {
                      fontSize: Constants.charts.AXIS_VALUES_FONTSIZE,
                      fill: Colors.COLOR_CONSUM_NOT_SELECTED,
                    },
                  }}
                  domain={[0, 24]}
                  tickValues={[0, 6, 12, 18, 23]}
                  tickFormat={(tick): string => `${Math.round(tick)} H`}
                  offsetY={45}
                />
                <VictoryAxis
                  dependentAxis
                  style={{
                    axis: { stroke: 'transparent' },
                    tickLabels: {
                      fontSize: Constants.charts.AXIS_VALUES_FONTSIZE,
                      fill: Colors.COLOR_CONSUM_NOT_SELECTED,
                    },
                  }}
                  tickCount={TOTAL_TICKS}
                  tickFormat={(tick): number => WattsConverterService.convertWattsToKwatts(tick)}
                  offsetX={45}
                />
                <VictoryStack colorScale={[Colors.COLOR_BLUE_200, Colors.COLOR_ELECSUMGREEN, Colors.COLOR_GRAY_100]}>
                  <VictoryBar barRatio={Constants.charts.BAR_RATIO_FILL} data={teledetectionData.shirnkData} />
                  <VictoryBar barRatio={Constants.charts.BAR_RATIO_FILL} data={teledetectionData.selfConsumptionData} />
                  <VictoryBar barRatio={Constants.charts.BAR_RATIO_FILL} data={teledetectionData.networkonData} />
                </VictoryStack>
                <VictoryLine
                  interpolation="natural"
                  style={{
                    data: { stroke: Colors.COLOR_GENERACIO, strokeWidth: 2 },
                  }}
                  data={teledetectionData.generationData}
                />
              </VictoryChart>
            </svg>
          </div>
        )}
      </SizeMe>

      <div className={styles.data_footer}>
        <ViewDate date={date} currentTab={currentTab} />
        <div className={styles.data_footer_list}>
          {listItemsArray.map(
            (item): JSX.Element => (
              <ChartDataList
                key={item.name}
                color={item.color}
                textColor={item.textColor}
                name={item.name}
                data={item.data}
              />
            ),
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductionDailyChart;
