/* eslint-disable no-param-reassign */
import { LoadingButton, SectionTitle, Spinner } from 'components';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { FormGroup, FormGroupProps, I18n } from 'core';
import UserInfo from 'types/models/userinfo';
import React, { FormEvent, useEffect, useState } from 'react';
import usersService from 'services/remote/UsersService';
import { useDispatch } from 'react-redux';
import { fetchUser, fetchUserSuccess } from 'redux/actions/UsersActions';
import { LoggingService, PromotorService } from 'services';
import customToast from 'components/CustomToast/CustomToast';
import { toast } from 'react-toastify';

import PromotorInfo from 'types/models/promotorInfo';
import CommunitiesServiceIntance from 'services/remote/communities-service';
import { CommunityModel } from 'types/models/community';
import configuration from 'config';
import Promotor from 'types/models/promotor';

import styles from './UsuarioDetailPage.module.scss';
import DevicesDataSection from './components/DevicesDataSection';
import PromotorTable from './components/PromotorTable';
import ComunidadSelectAndButton from './components/ComunidadSelectAndButton';

const UsuarioDetailPage = (props: any): JSX.Element => {
  // Props
  const {
    match: {
      params: { userID },
    },
  } = props;

  // Hooks
  const dispatch = useDispatch();

  // State
  const [userData, setUserData] = useState<UserInfo | null>();
  const [promotorData, setPromotorData] = useState<Promotor | null>();
  const [loadingData, setLoadingData] = useState(false);
  const [loadingEmail, setLoadingemail] = useState(false);
  const [loadingAsociarComunidad, setloadingAsociarComunidad] = useState(false);

  const [submitDisabledEmail, setSubmitDisabledEmail] = useState<boolean>(true);
  const [submitDisabledUpdatePromotor, setSubmitDisabledUpdatePromotor] = useState<boolean>(true);
  const [CommunitiesPromotor, setCommunitiesPromotor] = useState<Array<PromotorInfo> | null>(null);
  const [AllCommunities, setCommunities] = useState<Array<CommunityModel> | null>(null);
  const [selectComunidad, setSelectComunidad] = useState<number>(0);
  const [disabledComunidad, setdisabledComunidad] = useState<boolean>(true);
  const [urlMapaPromotor, setUrlMapaPromotor] = useState<string>('');
  const [errorEmail, setError] = useState<string | null>(null);
  const [errorEmailPromotor, setErrorPromotor] = useState<string>('');
  const [isPromotor, setIsPromotor] = useState<boolean>(false);
  const fetchAllcomunity = async () => {
    try {
      const response = await CommunitiesServiceIntance.getCommunities();
      const filteredCommunities = response.filter((community) => community.nombre !== '');
      setCommunities(filteredCommunities);
    } catch (error) {
      toast.error(I18n.t('DashboardPage.comunity_fetching_error'));
    }
  };
  const fetchComunityAsociados = async (nif: string) => {
    try {
      const apiData = await PromotorService.promotorListaComunidad(nif);

      setCommunitiesPromotor(apiData);
    } catch (error: any) {
      if (error.response.status === 403) {
        setCommunitiesPromotor(null);
        setIsPromotor(false);
      } else {
        toast.error(I18n.t('DashboardPage.comunity_fetching_error'));
      }
    }
  };
  const fetchUrlPromotor = async (nif: string) => {
    try {
      const promotorDataResponse = await PromotorService.getPromotor(nif);
      setPromotorData(promotorDataResponse);

      setUrlMapaPromotor(`${configuration.elecsumShareUrl}comunitats/p/${promotorDataResponse.identificador}`);
    } catch (error: any) {
      if (error.response.status === 403) {
        setIsPromotor(false);
      } else {
        toast.error(I18n.t('DashboardPage.comunity_fetching_error'));
      }
    }
  };
  // Effects
  useEffect(() => {
    setLoadingData(true);
    dispatch(fetchUser());
    usersService.getUserById(userID).then((response) => {
      dispatch(fetchUserSuccess(response));
      setUserData(response);
      if (response.tipoDeUsuario === 'promotor') {
        fetchComunityAsociados(response.nif);
        fetchUrlPromotor(response.nif);
        setIsPromotor(true);
      }
    });
    fetchAllcomunity();
    setLoadingData(false);
  }, [userID]);

  useEffect(() => {
    if (selectComunidad !== 0) {
      setdisabledComunidad(false);
    } else {
      setdisabledComunidad(true);
    }
  }, [selectComunidad]);

  if (loadingData) {
    return (
      <div className={styles.loading_container}>
        <Spinner icon />
      </div>
    );
  }
  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const formFields: Array<FormGroupProps> = [
    {
      label: I18n.t('UsuarioDetailPage.UserData.labels.name'),
      type: 'text',
      id: 'name',
      path: 'name',
      name: 'name',
      value: userData?.nombre,
      readOnly: true,
      className: styles.field,
      placeholder: '',
    },
    {
      label: I18n.t('UsuarioDetailPage.UserData.labels.surname'),
      type: 'text',
      id: 'cognoms',
      path: 'cognoms',
      name: 'cognoms',
      value: userData?.apellidos,
      readOnly: true,
      className: styles.field,
      placeholder: '',
    },
    {
      label: I18n.t('UsuarioDetailPage.UserData.labels.nif'),
      type: 'text',
      id: 'nif',
      path: 'nif',
      name: 'nif',
      value: userData?.nif,
      readOnly: true,
      className: styles.field,
      placeholder: '',
    },
    {
      label: I18n.t('UsuarioDetailPage.UserData.labels.email'),
      type: 'text',
      id: 'email',
      path: 'email',
      name: 'email',
      value: userData?.email,
      className: styles.field,
      placeholder: '',
    },
  ];

  const handleChange = (path: string, value: string): void => {
    if (path === 'email') {
      if (userData) {
        setUserData(new UserInfo().deserialize({ ...userData, [path]: value }));
        if (!isValidEmail(userData.email)) {
          setSubmitDisabledEmail(true);

          setError('Email is invalid');
        } else {
          setSubmitDisabledEmail(false);
          setError(null);
        }
      }
    }
  };
  const handleChangeEmail = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setLoadingemail(true);
    try {
      if (userData) {
        const response = await usersService.changeEmailUserInfo(userData);
        LoggingService.log('response', response);
        if (response) {
          dispatch(fetchUser());
          usersService.getUserById(userID).then((responseDispatch) => {
            dispatch(fetchUserSuccess(responseDispatch));
            setUserData(responseDispatch);
            setLoadingData(false);
          });
          customToast.success(I18n.t('Cliente actualizado correctamente'));
        }
      }
    } catch (error) {
      toast.error(I18n.t('Error updating report!'));
    }
    setLoadingemail(false);
  };
  // Lanzar eliminar comunidad asociada
  const deleteComunidad = async (disp: any) => {
    // eslint-disable-next-line no-alert
    const accept = window.confirm(
      `${I18n.t('Are you sure you want to delete the comunidad asociada ?')} ${disp.nombre} ?`,
    );
    if (!accept) {
      return;
    }
    setloadingAsociarComunidad(true);
    try {
      if (userData) {
        const response = await PromotorService.promotorAsociarComunidad(userData.nif, 'del', disp.id_comunidad);
        if (response) {
          fetchComunityAsociados(userData.nif);
          customToast.success(I18n.t('Promotor actualizado correctamente'));
        }
      }
    } catch (error) {
      toast.error(I18n.t('DashboardPage.comunity_fetching_error'));
    }
    setloadingAsociarComunidad(false);
  };

  const onOptionChangeHandler = (event: any) => {
    setSelectComunidad(event.target.value);
  };
  // Lanzar Add comunidad
  const addComunidad = async () => {
    setloadingAsociarComunidad(true);
    try {
      if (userData) {
        const response = await PromotorService.promotorAsociarComunidad(userData.nif, 'add', selectComunidad);
        if (response) {
          if (response) {
            fetchComunityAsociados(userData.nif);
            customToast.success(I18n.t('Promotor actualizado correctamente'));
          }
        }
      }
    } catch (error) {
      toast.error(I18n.t('DashboardPage.comunity_fetching_error'));
    } finally {
      setSelectComunidad(0);
      setloadingAsociarComunidad(false);
    }
  };

  const handleCheckboxChange = async (id: PromotorInfo) => {
    setloadingAsociarComunidad(true);
    try {
      if (userData) {
        await PromotorService.promotorAsociarComunidadPrincipal(userData.nif, id.id_comunidad);
      }
    } catch (error) {
      toast.error(I18n.t('DashboardPage.comunity_fetching_error'));
    } finally {
      if (userData) {
        fetchComunityAsociados(userData.nif);
      }
      setloadingAsociarComunidad(false);
    }
  };

  /* PROMOTOR */

  const [promotorDataUpdatedPassword, setPromotorDataUpdatedPassword] = useState<string>();

  const formSMTPFields: Array<FormGroupProps> = [
    {
      label: I18n.t('UsuarioDetailPage.UserData.promotorLabels.smtpHost'),
      type: 'text',
      id: 'smtpHost',
      path: 'smtpHost',
      name: 'smtpHost',
      value: promotorData?.smtpHost,
      className: styles.field,
      placeholder: '',
    },
    {
      label: I18n.t('UsuarioDetailPage.UserData.promotorLabels.smtpPort'),
      type: 'number',
      id: 'smtpPort',
      path: 'smtpPort',
      name: 'smtpPort',
      value: promotorData?.smtpPort,
      className: styles.field,
      placeholder: '',
    },
    {
      label: I18n.t('UsuarioDetailPage.UserData.promotorLabels.username'),
      type: 'text',
      id: 'username',
      path: 'username',
      name: 'username',
      value: promotorData?.username,
      className: styles.field,
      placeholder: '',
      error: errorEmailPromotor,
    },
    {
      label: I18n.t('UsuarioDetailPage.UserData.promotorLabels.password'),
      type: 'password',
      id: 'password',
      path: 'password',
      name: 'password',
      value: promotorDataUpdatedPassword,
      className: styles.field,
      placeholder: '',
    },

    {
      label: I18n.t('UsuarioDetailPage.UserData.promotorLabels.telefonoDeContacto'),
      type: 'text',
      id: 'telefonoDeContacto',
      path: 'telefonoDeContacto',
      name: 'telefonoDeContacto',
      value: promotorData?.telefonoDeContacto,

      className: styles.field,
      placeholder: '',
    },
  ];

  const formSMTPFieldCheckboxes: Array<FormGroupProps> = [
    {
      label: I18n.t('UsuarioDetailPage.UserData.promotorLabels.useTls'),
      type: 'checkbox',
      id: 'useTls',
      path: 'useTls',
      name: 'useTls',
      value: promotorData?.useTls === 'si',
      checked: promotorData?.useTls === 'si',
      className: styles.field,
      placeholder: '',
    },
    {
      label: I18n.t('UsuarioDetailPage.UserData.promotorLabels.useSsl'),
      type: 'checkbox',
      id: 'useSsl',
      path: 'useSsl',
      name: 'useSsl',
      value: promotorData?.useSsl === 'si',
      checked: promotorData?.useSsl === 'si',
      className: styles.field,
      placeholder: '',
    },
  ];

  const handleChangeSMTPFields = (path: string, value: string | number): void => {
    if (path === 'password' && typeof value === 'string') {
      setPromotorDataUpdatedPassword(value);
    }

    if (promotorData) {
      const isEmailValid = isValidEmail(promotorData.username);
      setSubmitDisabledUpdatePromotor(!isEmailValid);

      setErrorPromotor(isEmailValid ? '' : 'Email is invalid');
      setPromotorData(new Promotor().deserialize({ ...promotorData, [path]: value }));

      if (isEmailValid) {
        const todosLlenos = Object.values(promotorData).every((dentro) => dentro !== '');
        if (todosLlenos === true) {
          setSubmitDisabledUpdatePromotor(false);
        }
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleCheckBox<T>(path: string, value: string, id: string, checked: boolean): Promise<unknown> {
    // Desmarca los otros checkboxes y actualiza el estado
    const updatedCheckboxes = formSMTPFieldCheckboxes.map((checkbox) => ({
      ...checkbox,
      value: checkbox.path === path ? checked : false,
      checked: checkbox.path === path ? checked : false,
    }));

    if (promotorData) {
      updatedCheckboxes.forEach((checkbox) => {
        promotorData.setAttr(checkbox.path, checkbox.checked ? 'si' : 'no');
      });

      setPromotorData(new Promotor().deserialize({ ...promotorData }));
    }

    return true;
  }
  const handleUpdatePromotor = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    if (userData) {
      const newPromotorSmtp = {
        identificador: userData?.nif,
        smtpHost: promotorData?.smtpHost ? promotorData?.smtpHost : '',
        smtpPort: promotorData?.smtpPort ? promotorData?.smtpPort : 0,
        username: promotorData?.username ? promotorData?.username : '',
        password: promotorDataUpdatedPassword || '',
        useTls: promotorData?.useTls ? promotorData?.useTls : 'no',
        useSsl: promotorData?.useSsl ? promotorData?.useSsl : 'no',
        telefonoDeContacto: promotorData?.telefonoDeContacto ? promotorData?.telefonoDeContacto : '',
      };
      try {
        const promotorDataResponse = await PromotorService.patchPromotor(userData?.nif, newPromotorSmtp);
        if (promotorDataResponse) {
          customToast.success(I18n.t('Cliente actualizado correctamente'));
        }
      } catch (error) {
        toast.error(I18n.t('Error updating report!'));
      }
    }
  };

  const handleValidarPromotor = async () => {
    if (promotorData && promotorDataUpdatedPassword) {
      try {
        const promotorValidarResponse = await PromotorService.validarPromotorSMTPData(
          promotorData?.smtpHost,
          promotorData?.smtpPort,
          promotorData?.username,
          promotorDataUpdatedPassword,
          promotorData?.useTls,
          promotorData?.useSsl,
        );
        if (promotorValidarResponse) {
          customToast.success(I18n.t('El email de prueba ha sido enviado con éxito'));
        }
      } catch (error) {
        toast.error(I18n.t('Error updating report!'));
      }
    }
  };
  return (
    <div className={styles.root}>
      <SectionWrapper>
        <SectionTitle text={I18n.t('UsuarioDetailPage.UserData.title')} />
        <form onSubmit={handleChangeEmail}>
          <div className={styles.user_info}>
            {formFields.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                placeholder={formGroup.placeholder}
                value={formGroup.value}
                required={formGroup.required}
                readOnly={formGroup.readOnly}
                className={formGroup.className}
                onChange={handleChange}
              />
            ))}
            {errorEmail && <p style={{ color: 'red' }}>{errorEmail}</p>}
          </div>
          <div className={styles.saveContainer}>
            <LoadingButton
              type="submit"
              text={I18n.t('Email Cuenta')}
              disabled={submitDisabledEmail}
              loading={loadingEmail}
            />
          </div>
        </form>
      </SectionWrapper>
      {isPromotor ? (
        <SectionWrapper>
          <SectionTitle text={I18n.t('UsuarioDetailPage.UserData.titleSmtp')} />
          <form onSubmit={handleUpdatePromotor}>
            <div className={styles.user_info}>
              {formSMTPFields.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  error={formGroup.error}
                  placeholder={formGroup.placeholder}
                  value={formGroup.value}
                  required={formGroup.required}
                  readOnly={formGroup.readOnly}
                  className={formGroup.className}
                  onChange={handleChangeSMTPFields}
                />
              ))}
            </div>
            <div className={styles.user_info}>
              {formSMTPFieldCheckboxes.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  placeholder={formGroup.placeholder}
                  value={formGroup.value}
                  checked={formGroup.checked}
                  required={formGroup.required}
                  readOnly={formGroup.readOnly}
                  className={formGroup.className}
                  onChange={handleCheckBox}
                />
              ))}
            </div>
            <section className={styles.buttonsContainer}>
              <div className={styles.saveContainer}>
                <LoadingButton type="button" text="test email" onClick={handleValidarPromotor} />
              </div>
              <div className={styles.saveContainer}>
                <LoadingButton
                  type="submit"
                  text={I18n.t('Email Cuenta')}
                  disabled={submitDisabledUpdatePromotor}
                  loading={loadingEmail}
                />
              </div>
            </section>
          </form>
        </SectionWrapper>
      ) : null}

      {(() => {
        if (userData && userData.tipoDeUsuario !== 'promotor') {
          return <DevicesDataSection userData={userData} />;
        }
        if (isPromotor) {
          return (
            <SectionWrapper customClassname={styles.table_wrapper}>
              <SectionTitle text={I18n.t('UsuarioDetailPage.Promotor.title')} />
              <div className={styles.urlMapa}>
                <a href={urlMapaPromotor} target="_blank" rel="noreferrer" className={styles.shareButton}>
                  {urlMapaPromotor}
                </a>
              </div>
              <div>
                {CommunitiesPromotor && (
                  <PromotorTable
                    CommunitiesPromotor={CommunitiesPromotor}
                    handleCheckboxChange={handleCheckboxChange}
                    deleteComunidad={deleteComunidad}
                  />
                )}

                <ComunidadSelectAndButton
                  AllCommunities={AllCommunities}
                  CommunitiesPromotor={CommunitiesPromotor}
                  onOptionChangeHandler={onOptionChangeHandler}
                  addComunidad={addComunidad}
                  disabledComunidad={disabledComunidad}
                  loadingAsociarComunidad={loadingAsociarComunidad}
                />
              </div>
            </SectionWrapper>
          );
        }
        return <p>{I18n.t('NoPermisoUsuarioPromotor')}</p>;
      })()}
    </div>
  );
};

export default React.memo(UsuarioDetailPage);
