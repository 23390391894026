const INSTALLATIONS_ACTIONS = {
  FETCH_INSTALLATION: 'FETCH_INSTALLATION',
  FETCH_INSTALLATION_OK: 'FETCH_INSTALLATION_SUCCESS',
  FETCH_INSTALLATION_ERROR: 'FETCH_INSTALLATION_FAILED',
  RESET_STATE: 'RESET_STATE',
};

const CUPS_ACTIONS = {
  FETCH_CUPS: 'FETCH_CUPS',
  FETCH_CUPS_OK: 'FETCH_CUPS_SUCCESS',
  FETCH_CUPS_ERROR: 'FETCH_CUPS_FAILED',
  RESET_STATE: 'RESET_STATE',
  FETCH_CUPS_LIST_OK: 'FECTH_ADMIN_CUPS_LIST_OK',
  UPDATE_CUPS: 'UPDATE_CUPS',
};

const USER_ACTIONS = {
  FETCH_USER: 'FETCH_USER',
  FETCH_USER_OK: 'FETCH_USER_SUCCESS',
  FETCH_USER_ERROR: 'FETCH_USER_FAILED',
  RESET_STATE: 'RESET_STATE',
  FETCH_PERMISOS: 'FETCH_PERMISOS',
};

const CLIENT_CUPS_ACTIONS = {
  FETCH_CUPS: 'FETCH_CLIENT_CUPS',
  FETCH_CUPS_OK: 'FETCH_CLIENT_CUPS_OK',
  FETCH_CUPS_ERROR: 'FETCH_CLIENT_CUPS_FAILED',
  UPDATE_CUPS: 'UPDATE_CUPS',
  FETCH_CUPS_LIST: 'FETCH_CLIENT_CUPS_LIST',
  FETCH_CUPS_LIST_OK: 'FECTH_CLIENT_CUPS_LIST_OK',
  FETCH_CUPS_LIST_ERROR: 'FETCH_CLIENT_CUPS_LIST_FAILED',
  RESET_STATE: 'RESET_STATE',
};

const COMMUNITIES_ACTIONS = {
  FETCH_COMMUNITY: 'FETCH_COMMUNITY',
  FETCH_COMMUNITY_OK: 'FETCH_COMMUNITY_OK',
  FETCH_COMMUNITY_ERROR: 'FETCH_COMMUNITY_ERROR',
  RESET_STATE: 'RESET_STATE',
};

export { CUPS_ACTIONS, INSTALLATIONS_ACTIONS, USER_ACTIONS, CLIENT_CUPS_ACTIONS, COMMUNITIES_ACTIONS };
