import { Serializable } from '../interfaces';

export interface ConfiguracionMatcherAPI {
  id: number;

  nombre: string;

  fechaInicio: string;

  fechaFin: string;

  idComunidad: number;

  betaMax: number;

  betaMin: number;

  minimizarMiembros: string;

  maximizarMiembros: string;

  fechaCreacion: string;

  bloqueada: boolean;

  infoDeGeneracion: {
    año: string;
    generacionAnual: number;
  };
  perfilDeConsumo?: {
    id: number;
    nombre: string;
    descripcion: string;
    infoDeConsumo: {
      año: number;
      consumoAnual: number;
    };
  };
  numeroMiembros: number;

  nombreComunidad: string;
  metodoDeCalculos: string;
  step: number;
}

export default class ConfiguracionMatcher implements Serializable<ConfiguracionMatcher> {
  id: number;

  nombre: string;

  fechaInicio: string;

  fechaFin: string;

  idComunidad: number;

  betaMax: number;

  betaMin: number;

  minimizarMiembros: string;

  maximizarMiembros: string;

  fechaCreacion: string;

  bloqueada: boolean;

  infoDeGeneracion: {
    año: string;
    generacionAnual: number;
  };

  perfilDeConsumo?: {
    id: number;
    nombre: string;
    descripcion: string;
    infoDeConsumo: {
      año: number;
      consumoAnual: number;
    };
  };

  numeroMiembros: number;

  nombreComunidad: string;

  metodoDeCalculos: string;

  step: number;

  deserialize(input: ConfiguracionMatcherAPI): ConfiguracionMatcher {
    if (!input) {
      return this;
    }

    this.id = input.id;

    this.nombre = input.nombre;

    this.fechaInicio = input.fechaInicio;

    this.fechaFin = input.fechaFin;

    this.idComunidad = input.idComunidad;

    this.betaMax = input.betaMax;

    this.betaMin = input.betaMin;

    this.minimizarMiembros = input.minimizarMiembros;

    this.maximizarMiembros = input.maximizarMiembros;

    this.fechaCreacion = input.fechaCreacion;

    this.bloqueada = input.bloqueada;

    this.infoDeGeneracion = {
      año: input.infoDeGeneracion.año,
      generacionAnual: input.infoDeGeneracion.generacionAnual,
    };

    this.numeroMiembros = input.numeroMiembros;

    this.nombreComunidad = input.nombreComunidad;

    this.metodoDeCalculos = input.metodoDeCalculos;
    this.step = input.step;
    this.perfilDeConsumo = input.perfilDeConsumo;
    return this;
  }

  deserializeArray(inputArray: Array<ConfiguracionMatcherAPI>): Array<ConfiguracionMatcher> {
    return inputArray.map((input) => new ConfiguracionMatcher().deserialize(input));
  }

  setAttr<T>(key: string, value: T): void {
    Object.assign(this, { [key]: value });
  }
}
