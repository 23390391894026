import React from 'react';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { I18n } from 'core';
import TooltipOferta from 'components/tooltipOferta';
import styles from './CupsTooltip.module.scss';

const TooltipContent = (): JSX.Element => {
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
  return (
    <div className={styles.contentOferta}>
      <p>
        {I18n.t('TooltipOferta.title')} {previousYear} o {currentYear}.{I18n.t('TooltipOferta.subtitle')}
      </p>
    </div>
  );
};

const CupsTooltipOferta = (props: { title: string }): JSX.Element => {
  const { title } = props;

  return (
    <TooltipOferta cursor="help" direction="bottom" content={<TooltipContent />}>
      <div className={styles.root}>
        <InfoIcon />
        <p>{title}</p>
      </div>
    </TooltipOferta>
  );
};

export default CupsTooltipOferta;
