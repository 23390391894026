import { Card } from 'components';
import React from 'react';
import { I18n } from 'core';
import { ReactComponent as SpainCO2Icon } from 'assets/icons/SpainCO2.svg';
import { ReactComponent as CO2Icon } from 'assets/icons/CO2.svg';
import { Colors } from 'styles';
import { UtilsService } from 'services';
import { SustainabilityObject } from 'types/models';
import styles from './EmisionsWidget.module.scss';

interface EmisionsWidgetPropsI {
  sustainabilityData: SustainabilityObject;
}

const EmisionsWidget = (props: EmisionsWidgetPropsI): JSX.Element => {
  const { sustainabilityData } = props;
  return (
    <>
      <Card
        fullHeight
        title={I18n.t('emisions')}
        body={[
          <div key={0} className={styles.emissions_card}>
            <div className={styles.section}>
              <CO2Icon width={50} height={50} fill={Colors.COLOR_ELECSUMGREEN} />
              <div className={styles.value_container}>
                <p className={styles.data}>
                  {`${UtilsService.formatTwoDecimal(sustainabilityData.emisions.myContribution)}`}
                </p>
                <p className={styles.text}>tn CO2</p>
              </div>
              <p className={styles.text}>{I18n.t('noEmmitedYear')}</p>
              <p className={styles.text_bold}>{I18n.t('myContribution')}</p>
            </div>
            <div className={styles.section}>
              <SpainCO2Icon height={50} width={50} fill={Colors.COLOR_ELECSUMGREEN} />
              <div className={styles.value_container}>
                <p className={styles.data}>
                  {`${UtilsService.formatTwoDecimal(sustainabilityData.emisions.perInhabitant)}`}
                </p>
                <p className={styles.text}>tn CO2</p>
              </div>
              <p className={styles.text}>{I18n.t('emmitedHabYear')}</p>
              <p className={styles.text_bold}>{I18n.t('spain')}</p>
            </div>
          </div>,
        ]}
      />
    </>
  );
};

export default EmisionsWidget;
