import { EnergyDataAPII } from './index';

export default class ProdEnergyData {
  date: string;

  generation: number;

  shrink: number;

  network: number;

  selfconsumption: number;

  consumption: number;

  deserialize(input: EnergyDataAPII): ProdEnergyData {
    if (!input) {
      return this;
    }

    this.date = input.fecha;
    this.generation = input.generacion;
    this.shrink = input.excedente;
    this.network = input.red;
    this.selfconsumption = input.autoconsumo;
    this.consumption = input.consumo;
    return this;
  }
}
