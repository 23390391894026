import { RoundedButton } from 'components/NewInputs';
import I18n from 'i18n-js';
import { navigate } from 'navigation';
import React from 'react';
import ActualizarTarifaAll from 'types/models/updateTarifaAll';
import styles from './tarifas-list-row.module.scss';

interface TarifasListRowProps {
  solicitud: ActualizarTarifaAll;
  solicitudID: string;
}

export const TarifasListRow = (props: TarifasListRowProps): JSX.Element => {
  let url: string;
  const { solicitud, solicitudID } = props;
  const { t } = I18n;

  if (solicitudID === undefined) {
    url = `/tecnic/tarifas/${solicitud.id}/tarifas`;
  } else {
    url = `/tecnic/tarifas/${solicitudID}/${solicitud.id}/tarifas`;
  }
  return (
    <tr className={styles.mainrow}>
      <td>
        {solicitud.nombre} {solicitud.apellidos}
      </td>
      <td>{solicitud.nif}</td>
      <td>{solicitud.cups}</td>
      <td>{solicitud.nombreComunidad}</td>
      <td>{solicitud.fechaAltaSolicitud.split(' ')[0]}</td>
      <td>
        <RoundedButton
          onClick={() => {
            navigate(url);
          }}
        >
          {t(`ComunitiesEnrolledListPage.detalles`)}
        </RoundedButton>
      </td>
    </tr>
  );
};
