import configuration from 'config';

import { AxiosResponse } from 'axios';
import GraficaCurvaConsumoTeledectionData from 'types/models/graficaCurvateledetectionData';
import apiService from './ApiService';

export const dataFrequencies = {
  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year',
  HISTORIC: 'historic',
  NAV_YEAR_HOUR: 'yearxhour',
};

class CurvaConsumoTeledetectionService {
  private readonly http = apiService;

  private apiUrl = configuration.apiUrl;

  public async getGraficaCurvaConsumoTeledectionData(
    freq: string,
    id: number,
    id_miembro: number,
    month?: number,
    day?: number,
  ): Promise<GraficaCurvaConsumoTeledectionData> {
    let teledetectionData = new GraficaCurvaConsumoTeledectionData();
    if (dataFrequencies.DAY === freq && month) {
      teledetectionData = await this.getTeledetectionHourlyData(id, id_miembro);
    }
    if (dataFrequencies.MONTH === freq && month) {
      teledetectionData = await this.getTeledetectionMonthData(month, id, id_miembro);
    }

    if (dataFrequencies.YEAR === freq) {
      teledetectionData = await this.getTeledetectionYearData(id, id_miembro);
    }

    if (dataFrequencies.DAY === freq && month && day) {
      teledetectionData = await this.getTeledetectionDayData(month, day, id, id_miembro);
    }
    if (dataFrequencies.NAV_YEAR_HOUR === freq && month && day) {
      teledetectionData = await this.getTeledetectionHourlyData(id, id_miembro);
    }

    return teledetectionData;
  }

  private async getTeledetectionDayData(
    month: number,
    day: number,
    id: number,
    id_miembro: number,
  ): Promise<GraficaCurvaConsumoTeledectionData> {
    const response: AxiosResponse = await this.http.get(
      `${this.apiUrl}/matcher/configuracion-matcher/${id}/miembro/${id_miembro}/curva-consumo/diaria/${month}/${day}/`,
    );
    return new GraficaCurvaConsumoTeledectionData().deserialize(response.data.datos);
  }

  private async getTeledetectionMonthData(
    month: number,
    id: number,
    id_miembro: number,
  ): Promise<GraficaCurvaConsumoTeledectionData> {
    const response: AxiosResponse = await this.http.get(
      `${this.apiUrl}/matcher/configuracion-matcher/${id}/miembro/${id_miembro}/curva-consumo/mensual/${month}/`,
    );
    return new GraficaCurvaConsumoTeledectionData().deserialize(response.data.datos);
  }

  private async getTeledetectionYearData(id: number, id_miembro: number): Promise<GraficaCurvaConsumoTeledectionData> {
    const response: AxiosResponse = await this.http.get(
      `${this.apiUrl}/matcher/configuracion-matcher/${id}/miembro/${id_miembro}/curva-consumo/anual/`,
    );
    return new GraficaCurvaConsumoTeledectionData().deserialize(response.data.datos);
  }

  private async getTeledetectionHourlyData(
    id: number,
    id_miembro: number,
  ): Promise<GraficaCurvaConsumoTeledectionData> {
    const response: AxiosResponse = await this.http.get(
      `${this.apiUrl}/matcher/configuracion-matcher/${id}/miembro/${id_miembro}/curva-consumo/horas/`,
    );
    return new GraficaCurvaConsumoTeledectionData().deserialize(response.data.datos);
  }
}

const curvaConsumoTeledetectionService: CurvaConsumoTeledetectionService = new CurvaConsumoTeledetectionService();

export default curvaConsumoTeledetectionService;
