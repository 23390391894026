import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import React, { createRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { I18n } from 'core';
import { navigate } from 'navigation';
import { toast } from 'react-toastify';
import { CommunityCustomOnboardingServiceInstance } from 'services';
import { LoadingButton } from 'components';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import Button from 'components/Button/Button';
import customToast from 'components/CustomToast/CustomToast';
import { FaqsApi } from 'types/models/communityCustomOnboarding';
import FaqsCustomOnboarding from 'types/models/faqsCustomOnboarding';
import { useFetchCommunity } from 'hooks';
import styles from './comunidades-custom-onboarding-process-page.module.scss';
import ComunidadesCustomOnboardingFaqs from './components/comunidades-custom-onboarding-faqs/comunidades-custom-onboarding-faqs';

export type SaveDataHandle = {
  getData: () => void;
};

export const ComunidadesCustomOnboardingProcessPageFaqs = (): JSX.Element => {
  const { communityID } = useParams<{ communityID: string }>();

  const [loading, setLoading] = useState<boolean>(false);

  const [communityFaqsCustomOnboarding, setCommunityFaqsCustomOnboarding] = useState<
    FaqsCustomOnboarding[] | undefined
  >(undefined);

  const customOnboardingRef = createRef<SaveDataHandle>();
  // const customOnboardingRefFaqs = createRef<SaveDataHandle>();
  // const customOnboardingRefipoDeContacto = createRef<SaveDataHandle>();

  const getFaqsCustomOnboardingData = async (id: string): Promise<void> => {
    setLoading(true);
    try {
      const customOnboarding = await CommunityCustomOnboardingServiceInstance.getFAQSCommunityCustomOnboarding(id);
      if (customOnboarding) {
        setCommunityFaqsCustomOnboarding(customOnboarding);
      }
    } catch (error) {
      toast.error(I18n.t('ComunidadesCustomOnboardingProcess.errors.errorGettingCustomOnboardingData'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!communityID) return;
    getFaqsCustomOnboardingData(communityID);
  }, []);
  const fetchCommunityData = useFetchCommunity(communityID);
  useEffect(() => {
    fetchCommunityData();
  }, [fetchCommunityData]);
  const addFaqs = async (communityCustomOnboardingUpdate: FaqsApi): Promise<void> => {
    if (communityCustomOnboardingUpdate.pregunta !== '') {
      try {
        await CommunityCustomOnboardingServiceInstance.addFaqsComunity(communityID, communityCustomOnboardingUpdate);

        customToast.success(I18n.t('ComunidadesCustomOnboardingProcess.updateSuccess'));
        getFaqsCustomOnboardingData(communityID);
      } catch (error) {
        toast.error(I18n.t('ComunidadesCustomOnboardingProcess.errors.updating'));
      }
    }
  };
  const deleteFaq = async (idFaq: any): Promise<void> => {
    // eslint-disable-next-line no-alert
    const accept = window.confirm(`${I18n.t('Are you sure you want to delete the faq?')}${idFaq.pregunta} ? `);

    if (!accept) {
      return;
    }

    try {
      await CommunityCustomOnboardingServiceInstance.deleteFaqsComunity(communityID, idFaq.id);
      customToast.success(I18n.t('ComunidadesCustomOnboardingProcess.updateSuccess'));

      getFaqsCustomOnboardingData(communityID);
    } catch (error) {
      toast.error(`${I18n.t('Error deleting image')}.`);
    }
  };

  if (loading) return <FullPageSyncLoader icon />;
  return (
    <div className={styles.container}>
      <SectionWrapper>
        <>
          {communityFaqsCustomOnboarding && (
            <ComunidadesCustomOnboardingFaqs
              ref={customOnboardingRef}
              communityId={communityID}
              communityCustomOnboarding={communityFaqsCustomOnboarding}
              saveDetails={(customOnboarding) => addFaqs(customOnboarding)}
              deleteFaqs={(customOnboarding) => deleteFaq(customOnboarding)}
            />
          )}
        </>

        <div className={styles.positionBotonoes}>
          <div className={styles.divBoton}>
            <Button
              type="button"
              onClick={(): Promise<void> => navigate('/tecnic/communities')}
              variant="secondary"
              text={I18n.t('Cancel')}
            />
            <LoadingButton type="submit" text={I18n.t('Save')} onClick={() => customOnboardingRef.current?.getData()} />
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
};
