import React, { useState, useEffect } from 'react';

import SyncLoader from 'react-spinners/ClipLoader';

import { I18n } from 'core';

import { Card } from 'components';
import { ElecsumGreenWeb } from 'types/models';
import { WattsConverterService, elecsumGreenServiceInstance } from 'services';
import { ReactComponent as ElecsumGreenIcon } from 'assets/icons/ElecsumGreen.svg';
import { ReactComponent as ElectricPlugIcon } from 'assets/icons/ElectricPlug.svg';
import { ReactComponent as GenerationIcon } from 'assets/icons/Generation.svg';
import { ReactComponent as TickIcon } from 'assets/icons/Tick.svg';
import cx from 'classnames';
import { Colors } from 'styles';
import greenEnergyLogoTest from './GreenEnergyLogoTest.png';
import greenStamp from './stamp.png';
import electraCaldenseEnergiaLogo from './logo-electracaldense.png';

import CO2andPlantedTrees from './ElecsumGreenWebPageComponents/CO2andPlantedTrees/CO2andPlantedTrees';

import styles from './ElecsumGreenWebPage.module.scss';
import ElecsumGreenCompany from './ElecsumGreenWebPageComponents/ElecsumGreenCompany/ElecsumGreenCompany';

interface DisplayEnergyDataI {
  data: string;
  unit: string;
}
const PREMIUM = 'Premium';

const DisplayEnergyData = (props: DisplayEnergyDataI): JSX.Element => {
  const { data, unit } = props;
  return (
    <div className={styles.DisplayEnergyData}>
      <span className={styles.data}>{data}</span>
      <span className={styles.data}>{unit}</span>
    </div>
  );
};

const ElecsumGreenWebPage = (props: any): JSX.Element => {
  const {
    match: {
      params: { identificator },
    },
  } = props;
  const [loading, setLoading] = useState(true);
  const [webData, setWebData] = useState<ElecsumGreenWeb>();

  useEffect(() => {
    const getWebData = async (): Promise<void> => {
      const webDataInfo = await elecsumGreenServiceInstance.getElecsumGreenWebPage(identificator);
      setWebData(webDataInfo);
      setLoading(false);
    };
    getWebData();
  }, [identificator]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <SyncLoader color="#98c832" />
      </div>
    );
  }

  return (
    <>
      {webData ? (
        <div className={styles.root}>
          <header className={styles.header}>
            <ElecsumGreenIcon height={80} width={120} />
          </header>

          {/* --- */}

          <div className={styles.background_company_image}>
            <img src={webData.headerImage} alt="" />
          </div>

          <div className={styles.content_wrapper}>
            <div className={styles.company_logo_wrapper}>
              <img src={webData.logoImage} className={styles.company_logo} alt="" />
            </div>
            <div className={styles.drow}>
              <div className={styles.company_contact}>
                <p className={styles.company_name}>{webData.comercialName}</p>
                <p className={styles.company_tel}>
                  {webData.telephone ? (
                    <>
                      t.
                      <span>{webData.telephone}</span>
                    </>
                  ) : (
                    ''
                  )}
                </p>
                <p className={styles.company_email}>{webData.email}</p>
                <p className={styles.company_web}>
                  {webData.webPage ? (
                    <a target="_blank" rel="noopener noreferrer" href={webData.webPage}>
                      {I18n.t('see web')}
                    </a>
                  ) : (
                    ''
                  )}
                </p>
              </div>

              <div className={styles.greenEnergy_wrapper}>
                <div className={styles.elecsum_title}>
                  <div className={styles.title_image}>
                    <img src={greenStamp} alt="" />
                  </div>
                  <p>Elecsum Green</p>
                </div>
                <div className={styles.data}>
                  <div className={styles.logo_wrapper}>
                    <img src={greenEnergyLogoTest} alt="" />
                  </div>
                  <div className={styles.greenEnergy_claim}>
                    <p className={styles.claim}>
                      {I18n.t(
                        '"Elecsum Green certifies the generation and / or 100% renewable energy consumption, See the details below"',
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p className={styles.updatedAt}>
              {I18n.t('Updated information a')}
              {webData.actualizationDate === ''
                ? '--'
                : new Date(webData.actualizationDate).toLocaleString('default', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
            </p>
            <div className={styles.widgets_container}>
              <div className={cx(styles.widget_section, styles.production)}>
                <div className={styles.title}>
                  <GenerationIcon
                    height={40}
                    width={40}
                    fill={Colors.COLOR_GENERACIO}
                    stroke={Colors.COLOR_GENERACIO}
                  />
                  <p>{I18n.t('Production')}</p>
                </div>

                <div className={styles.slider}>
                  <div className={styles.widget}>
                    <Card
                      title={I18n.t('Renewable energy production')}
                      subtitle={I18n.t('current year')}
                      className={styles.card_style}
                      body={[
                        <div key="1">
                          <DisplayEnergyData
                            data={WattsConverterService.convertWatts(webData.production.actualYear.production).num}
                            unit={WattsConverterService.convertWatts(webData.production.actualYear.production).unitHour}
                          />
                        </div>,
                      ]}
                      footer={
                        <CO2andPlantedTrees
                          co2={webData.production.actualYear.co2}
                          trees={webData.production.actualYear.trees}
                        />
                      }
                    />
                  </div>
                  <div className={styles.widget}>
                    <Card
                      title={I18n.t('Renewable energy production')}
                      subtitle={I18n.t('accumulated total')}
                      className={styles.card_style}
                      body={[
                        <div key="1">
                          <DisplayEnergyData
                            data={WattsConverterService.convertWatts(webData.production.totalProduction.production).num}
                            unit={
                              WattsConverterService.convertWatts(webData.production.totalProduction.production).unitHour
                            }
                          />
                        </div>,
                      ]}
                      footer={
                        <CO2andPlantedTrees
                          co2={webData.production.totalProduction.co2}
                          trees={webData.production.totalProduction.trees}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
              <div className={cx(styles.widget_section, styles.consumption)}>
                <div className={styles.title}>
                  <ElectricPlugIcon height={40} width={40} fill={Colors.COLOR_ELECSUMGREEN} />
                  <p>{I18n.t('Consumption')}</p>
                </div>

                <div className={styles.slider}>
                  <div className={styles.widget}>
                    <Card
                      title={I18n.t('Renewable energy consumption')}
                      subtitle={I18n.t('current year')}
                      className={styles.card_style}
                      body={[
                        <div key="1">
                          <DisplayEnergyData
                            data={WattsConverterService.convertWatts(webData.consumption.actualYear.consumption).num}
                            unit={
                              WattsConverterService.convertWatts(webData.consumption.actualYear.consumption).unitHour
                            }
                          />
                        </div>,
                      ]}
                      footer={
                        <CO2andPlantedTrees
                          co2={webData.consumption.actualYear.co2}
                          trees={webData.consumption.actualYear.trees}
                        />
                      }
                    />
                  </div>
                  <div className={styles.widget}>
                    <Card
                      title={I18n.t('Renewable energy consumption')}
                      subtitle={I18n.t('accumulated total')}
                      className={styles.card_style}
                      body={[
                        <div key="1">
                          <DisplayEnergyData
                            data={
                              WattsConverterService.convertWatts(webData.consumption.totaConsumption.consumption).num
                            }
                            unit={
                              WattsConverterService.convertWatts(webData.consumption.totaConsumption.consumption)
                                .unitHour
                            }
                          />
                        </div>,
                      ]}
                      footer={
                        <CO2andPlantedTrees
                          co2={webData.consumption.totaConsumption.co2}
                          trees={webData.consumption.totaConsumption.trees}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <>
              {webData.comercializado === 'si' ? (
                <div className={styles.certifications}>
                  <div className={styles.certificate}>
                    <div className={styles.logos_wrapper}>
                      <div className={styles.icon_wrapper}>
                        <TickIcon fill={Colors.COLOR_ELECSUMGREEN} height={16} width={16} />
                      </div>
                      <div className={styles.logo_wrapper}>
                        <img src={electraCaldenseEnergiaLogo} className={styles['caldense-logo']} alt="" />
                      </div>
                    </div>
                    <p className={styles.certified}>
                      ELECTRA CALDENSE ENERGIA S.A.
                      {I18n.t('certifies that')}
                      {webData.comercialName}
                      {I18n.t('is a customer of')}
                      ELECTRA CALDENSE ENERGIA, S.A.
                      {I18n.t('as of today')}
                      {new Date().toLocaleDateString()}.
                    </p>
                  </div>
                  <div className={styles.certificate}>
                    <div className={styles.logos_wrapper}>
                      <div className={styles.icon_wrapper}>
                        <TickIcon fill={Colors.COLOR_ELECSUMGREEN} height={16} width={16} />
                      </div>
                      <div className={styles.logo_wrapper}>
                        <img src={electraCaldenseEnergiaLogo} className={styles['caldense-logo']} alt="" />
                      </div>
                    </div>
                    <p className={styles.certified}>
                      {I18n.t('ELECTRA CALDENSE ENERGIA S L is provided exclusively with 100% renovable energy')}
                    </p>
                    <p className={styles.company_certificate}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://gdo.cnmc.es/CNE/resumenGdo.do?informe=garantias_etiquetado_electricidad"
                      >
                        {I18n.t('See CNMC certificate')}
                      </a>
                    </p>
                  </div>
                </div>
              ) : null}
            </>

            <div className={styles.contactus}>
              <div className={styles.more_info}>
                <p className={styles.text}>{I18n.t('If you want more information about')}</p>
                <p className={styles.text}>{I18n.t('photovoltaic installations')}</p>
              </div>

              <div className={styles.button_wrapper}>
                <a
                  className={styles.contact_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://elecsum.com/contacta-amb-nosaltres/"
                >
                  {I18n.t('contact us')}
                </a>
              </div>
            </div>
          </div>

          {webData.clientType === PREMIUM ? (
            <ElecsumGreenCompany name={webData.comercialName} company={webData.company} />
          ) : null}
        </div>
      ) : (
        <div className={styles.loading}>
          <SyncLoader color="#98c832" />
        </div>
      )}
    </>
  );
};

export default ElecsumGreenWebPage;
