/* eslint-disable @typescript-eslint/no-unused-vars */
import { SectionTitle } from 'components';

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { FormGroup, FormGroupProps, I18n } from 'core';
import { ReactComponent as DocumentsIcon } from 'assets/icons/Documents.svg';

import { CommunityCustomOnboarding } from 'types/models';
import { Colors } from 'styles';
import { LoggingService } from 'services';

import { CommunityModel } from 'types/models/community';
import { SaveDataHandle } from '../../comunidades-custom-onboarding-process-page';
import styles from './comunidades-custom-onboarding-informe-automatico.module.scss';

interface ComunidadesCustomOnboardingInformeAutomaticoProps {
  communityId: string;
  communityCustomOnboarding: CommunityModel;
  saveDetails: (customOnboarding: CommunityCustomOnboarding) => void;
  saveLimiteExcedente: (Excedente: string) => void;
}
const ComunidadesCustomOnboardingInformeAutomatico = forwardRef<
  SaveDataHandle,
  ComunidadesCustomOnboardingInformeAutomaticoProps
>((props: ComunidadesCustomOnboardingInformeAutomaticoProps, ref) => {
  const { communityCustomOnboarding, saveDetails, saveLimiteExcedente } = props;
  const [customOnboarding, setCustomOnboarding] = useState<any>();
  const [errors, setErrors] = useState({});
  const validateForm = (): boolean => {
    setErrors({});
    return true;
  };
  const comunidadPost = JSON.parse(JSON.stringify(communityCustomOnboarding));
  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleCheckBox<T>(path: string, value: string, id: string, checked: boolean): Promise<unknown> {
    let NewValue: string;
    if (checked) {
      NewValue = 'si';
    } else {
      NewValue = 'no';
    }
    communityCustomOnboarding.setAttr(path, NewValue);
    setCustomOnboarding(
      new CommunityModel().deserialize({
        ...comunidadPost,
        [path]: NewValue,
      }),
    );
    return true;
  }

  function handleChange<T>(path: string, value: T): void {
    communityCustomOnboarding.setAttr(path, value);
    setCustomOnboarding(
      new CommunityModel().deserialize({
        ...comunidadPost,
        [path]: value,
      }),
    );
    // validateForm();
  }

  const handleSubmit = (): void => {
    if (validateForm() && customOnboarding) {
      LoggingService.log('handleSubmit :: ok', { client: customOnboarding });
      saveDetails(customOnboarding);
      saveLimiteExcedente(customOnboarding.limiteExcedente);
    } else {
      LoggingService.log('handleSubmit :: errors', { client: customOnboarding, errors });
    }
  };

  const formFieldsCheckBoxes: Array<FormGroupProps> = [
    {
      label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.informes'),
      type: 'checkbox',
      id: 'informeAutomatico',
      path: 'informeAutomatico',
      name: 'informeAutomatico',
      checked: communityCustomOnboarding?.informeAutomatico === 'si',
      value: communityCustomOnboarding?.informeAutomatico === 'si',
    },
  ];

  const FormFieldsInformeInmediato: Array<FormGroupProps> = [
    {
      label: `${I18n.t('ComunidadesCustomOnboardingProcess.form.labels.informeInmediato')}`,
      type: 'checkbox',
      id: 'informeInmediato',
      path: 'informeInmediato',
      name: 'informeInmediato',
      checked: communityCustomOnboarding?.informeInmediato === 'si',
      value: communityCustomOnboarding?.informeInmediato === 'si',
    },
  ];

  const FormFieldsInformeExcedente: Array<FormGroupProps> = [
    {
      label: `${I18n.t('ComunidadesCustomOnboardingProcess.form.labels.limiteExcedente')}`,
      type: 'number',
      id: 'limiteExcedente',
      path: 'limiteExcedente',
      name: 'limiteExcedente',
      placeholder: '0',
      value: comunidadPost?.limiteExcedente,
    },
  ];

  useImperativeHandle(ref, () => ({
    getData: () => {
      handleSubmit();
    },
  }));

  return (
    <div className={styles.root}>
      <div className={styles.flex}>
        <div className={styles.titulo}>
          <DocumentsIcon height={20} width={20} fill={Colors.COLOR_BLACK} className={styles.DocumentsIcon} />
          <SectionTitle text={I18n.t('ComunidadesCustomOnboardingProcess.form.informe')} />
        </div>
      </div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.containerForms}>
          <div className={styles.containerForm}>
            {formFieldsCheckBoxes.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                value={formGroup.value}
                checked={formGroup.checked}
                onChange={handleCheckBox}
              />
            ))}

            {FormFieldsInformeInmediato.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                value={formGroup.value}
                checked={formGroup.checked}
                onChange={handleCheckBox}
              />
            ))}
          </div>
        </div>
        <div className={styles.containerFormsDetails}>
          {FormFieldsInformeExcedente.map((formGroup: FormGroupProps) => (
            <FormGroup
              key={formGroup.id}
              label={formGroup.label}
              type={formGroup.type}
              id={formGroup.id}
              path={formGroup.path}
              placeholder={formGroup.placeholder}
              name={formGroup.name}
              value={formGroup.value}
              checked={formGroup.checked}
              onChange={handleChange}
              step={0.01}
              min={0}
              max={1}
            />
          ))}
        </div>
      </form>
    </div>
  );
});

export default ComunidadesCustomOnboardingInformeAutomatico;
