import React from 'react';
import { I18n } from 'core';
import { UtilsService } from 'services';
import { getMonthFromStringDate } from 'services/local/getMonthFromStringDate';
import Card from '../../Card/Card';

interface SavingDataPropsI {
  acumulatedSaving: number;
  startDate: string;
}

const SavingAgregateCardCustom = (props: SavingDataPropsI): JSX.Element => {
  const { acumulatedSaving, startDate } = props;

  // if (!isClient) {
  //   return <></>;
  // }

  return (
    <Card
      title={[I18n.t('accumulatedSavings')]}
      subtitle={[I18n.t('totalAccumulated').toUpperCase()]}
      rightCorner={[`${UtilsService.formatTwoDecimal(acumulatedSaving)} €`]}
      body={[
        <div key={1}>
          <div>
            <p>
              {[I18n.t('desde')]} {getMonthFromStringDate(startDate, true)}
            </p>
          </div>
        </div>,
      ]}
    />
  );
};

export default SavingAgregateCardCustom;
