interface ComercialitzationSummaryDTO {
  tarifa: string;
  tarifaComercializadora: string;
  terminosDePotencia: Array<{ periodo: number; termino: number }>;
  terminosDeEnergia: Array<{ periodo: number; termino: number }>;
}

export default class ComercialitzationSummary {
  tarifa: string;

  tarifaComercializadora: string;

  terminosDePotencia: Array<{ periodo: number; termino: number }>;

  terminosDeEnergia: Array<{ periodo: number; termino: number }>;

  deserialize(input: ComercialitzationSummaryDTO): ComercialitzationSummary {
    if (!input) {
      return this;
    }
    this.tarifa = input.tarifa || '';
    this.tarifaComercializadora = input.tarifaComercializadora || '';

    this.terminosDePotencia = input.terminosDePotencia
      ? input.terminosDePotencia.map((item) => ({ periodo: item.periodo, termino: item.termino }))
      : [];
    this.terminosDeEnergia = input.terminosDeEnergia
      ? input.terminosDeEnergia.map((item) => ({ periodo: item.periodo, termino: item.termino }))
      : [];

    return this;
  }
}
