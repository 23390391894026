import React from 'react';
import { Spinner } from 'components';
import styles from './FullPageSyncLoader.module.scss';

interface FullPageSyncLoaderPropsI {
  icon?: boolean;
  size?: number;
}

const FullPageSyncLoader = (props: FullPageSyncLoaderPropsI): JSX.Element => {
  const { icon, size } = props;
  return (
    <div className={styles.loading_wrapper}>
      <Spinner icon={icon} size={size} />
    </div>
  );
};

FullPageSyncLoader.defaultProps = {
  icon: false,
  size: 100,
};
export default React.memo(FullPageSyncLoader);
