import React, { useState, useEffect } from 'react';
import { I18n } from 'core';
import { TeledetectionData } from 'types/models';

import { SectionTitle, TeledetectionChart } from 'components';
import { toast } from 'react-toastify';
import wattsConverterService from 'services/local/WattsConverter';
import { ReactComponent as NetworkIcon } from 'assets/icons/Network.svg';
import { ReactComponent as GenerationIcon } from 'assets/icons/Generation.svg';
import { ReactComponent as ShrinkIcon } from 'assets/icons/Shrink.svg';
import { ReactComponent as SelfConsumptionIcon } from 'assets/icons/SelfConsumption.svg';
import cx from 'classnames';

import { authServiceInstance, PromotorService } from 'services';
import { Colors } from 'styles';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import { DayPicker, MonthPicker, YearPicker } from 'components/Input';

import PromotorInfo from 'types/models/promotorInfo';
import PromotorDropDown from 'pages/ModeTecnic/RolInstalacion/InstallationProductionPage/PromotorDropDown';
import styles from './ChartsPagePromotor.module.scss';

interface DataItemPropsI {
  icon: JSX.Element;
  name: string;
  data: number;
}
// TODO
const DataItem = (props: DataItemPropsI): JSX.Element => {
  const { icon, name, data } = props;
  return (
    <div className={cx(styles.DataItem)}>
      <div className={styles.type}>
        {icon}
        <p className={styles.name}>{I18n.t(name)}</p>
      </div>
      <div className={styles.value}>
        <p className={styles.data}>{wattsConverterService.convertWatts(data).num}</p>
        <p className={styles.unit}>{wattsConverterService.convertWatts(data).unitHour}</p>
      </div>
    </div>
  );
};

const ChartsPagePromotor = (props: any): JSX.Element => {
  // Props

  const {
    match: {
      params: { promotorID },
    },
  } = props;

  const NAV_YEAR = 'year';
  const NAV_MONTH = 'month';
  const NAV_DAY = 'day';

  const ICON_SIZE = '25px';
  const getInitalDate = (): Date => {
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(yesterday.getDate() - 1);

    return yesterday;
  };

  const [installationPromotor, setIntallationPromotor] = useState<PromotorInfo[] | null>(null);
  const [clientSelected, setClientSelected] = useState<PromotorInfo | null>(null);

  const [teledetectionData, setTeledetectionData] = useState(new TeledetectionData());
  const [date, setDate] = useState({
    day: getInitalDate().getDate(),
    month: getInitalDate().getMonth() + 1,
    year: getInitalDate().getFullYear(),
  });

  const [currentTab, setCurrentTab] = useState(NAV_DAY);
  const [dataIsLoad, setDataIsLoad] = useState(false);
  const [loadingDetailData, setLoadingDetailData] = useState(false);
  const updateDate = (newDate: Date): void => {
    if (!newDate) {
      return;
    }
    setDate({
      day: newDate.getDate(),
      month: newDate.getMonth() + 1,
      year: newDate.getFullYear(),
    });
  };

  const getDateComponent = (): JSX.Element => {
    if (currentTab === NAV_DAY) {
      return (
        <>
          <DayPicker
            date={
              Number.isNaN(date.year)
                ? new Date(
                    `${getInitalDate().getMonth() + 1}/${getInitalDate().getDate()}/${getInitalDate().getFullYear()}`,
                  )
                : new Date(`${date.month}/${date.day}/${date.year}`)
            }
            onChangeDate={updateDate}
          />
        </>
      );
    }
    if (currentTab === NAV_MONTH) {
      return (
        <>
          <MonthPicker
            anchorSelector="right"
            value={
              Number.isNaN(date.year)
                ? new Date(
                    `${getInitalDate().getMonth() + 1}/${getInitalDate().getDate()}/${getInitalDate().getFullYear()}`,
                  )
                : new Date(`${date.month}/${date.day}/${date.year}`)
            }
            onChange={updateDate}
          />
        </>
      );
    }
    if (currentTab === NAV_YEAR) {
      return (
        <>
          <YearPicker
            anchorSelector="right"
            value={
              Number.isNaN(date.year)
                ? new Date(
                    `${getInitalDate().getMonth() + 1}/${getInitalDate().getDate()}/${getInitalDate().getFullYear()}`,
                  )
                : new Date(`${date.month}/${date.day}/${date.year}`)
            }
            onChange={updateDate}
          />
        </>
      );
    }
    return <></>;
  };
  const handleDropdownChange = (value: PromotorInfo | null) => {
    setClientSelected(value);
  };

  useEffect(() => {
    if (!authServiceInstance.user?.nif) return;

    PromotorService.promotorListaComunidad(authServiceInstance.user?.nif).then((response) => {
      setIntallationPromotor(response);
      if (response.length !== 0 && response) {
        PromotorService.getFechaUltimosDatos(promotorID, response).then((dataResponse: any) => {
          const { data } = dataResponse;

          const { datos } = data;
          const { fecha } = datos;

          const newDate = new Date(fecha);
          setDate({
            day: newDate.getDate(),
            month: newDate.getMonth() + 1,
            year: newDate.getFullYear(),
          });
        });
      } else {
        setDataIsLoad(true);
      }
    });
  }, [promotorID]);

  useEffect((): void => {
    const fetchData = async (): Promise<void> => {
      setLoadingDetailData(true);
      try {
        if (installationPromotor) {
          const teledetectionObj: TeledetectionData = await PromotorService.getTeledetectionData(
            currentTab,
            promotorID,
            clientSelected === null ? installationPromotor : [clientSelected],
            date.year,
            date.month,
            date.day,
          );
          setTeledetectionData(teledetectionObj);
          setDataIsLoad(true);
          setLoadingDetailData(false);
        }
      } catch (error) {
        toast.error('errorGettingTeledetectionData');
      }
    };

    fetchData();
  }, [currentTab, date, clientSelected]);

  if (!dataIsLoad) {
    return <FullPageSyncLoader icon />;
  }

  return (
    <div>
      {installationPromotor && installationPromotor.length !== 0 ? (
        <div className={styles.root}>
          <div>
            {installationPromotor && installationPromotor?.length > 0 ? (
              <div>
                {installationPromotor?.length > 1 ? (
                  <div>
                    <PromotorDropDown
                      selected={clientSelected}
                      items={installationPromotor || []}
                      onOptionChange={handleDropdownChange}
                    />
                  </div>
                ) : (
                  <div>
                    <SectionTitle text={installationPromotor[0].nombre} />
                  </div>
                )}
              </div>
            ) : (
              <div>{I18n.t('UsuarioDetailPage.Promotor.noData')}</div>
            )}
          </div>
          <div className={styles.chartWrapper}>
            <TeledetectionChart
              isLoading={loadingDetailData}
              fotovoltaica="si"
              teledetectionData={teledetectionData}
              dateComponent={getDateComponent()}
              currentTab={currentTab}
              setCurrentTabFunction={setCurrentTab}
            />
            {!loadingDetailData ? (
              <div className={styles.grid_container}>
                <>
                  <div className={styles.item}>
                    <DataItem
                      icon={<GenerationIcon fill={Colors.COLOR_GENERACIO} width={ICON_SIZE} height={ICON_SIZE} />}
                      name="generation"
                      data={teledetectionData.totals.generation}
                    />
                  </div>
                  <div className={styles.item}>
                    <DataItem
                      icon={<ShrinkIcon fill={Colors.COLOR_BLUE_200} width={ICON_SIZE} height={ICON_SIZE} />}
                      name="shrinking"
                      data={teledetectionData.totals.shrink}
                    />
                  </div>
                  <div className={styles.item}>
                    <DataItem
                      icon={
                        <SelfConsumptionIcon fill={Colors.COLOR_ELECSUMGREEN} width={ICON_SIZE} height={ICON_SIZE} />
                      }
                      name="selfConsumption"
                      data={teledetectionData.totals.selfconsumption}
                    />
                  </div>
                  <div className={styles.item}>
                    <DataItem
                      icon={<NetworkIcon fill={Colors.COLOR_GRAY_500} width={ICON_SIZE} height={ICON_SIZE} />}
                      name="network"
                      data={teledetectionData.totals.network}
                    />
                  </div>
                </>
              </div>
            ) : (
              <div style={{ height: 140 }} />
            )}
          </div>
        </div>
      ) : (
        <div className={styles.margenTexto}>{I18n.t('UsuarioDetailPage.Promotor.noData')} </div>
      )}
    </div>
  );
};
export default ChartsPagePromotor;
