import React, { useEffect, useState } from 'react';
import { FormGroup, FormGroupProps, I18n } from 'core';

import { SectionTitle, Toggle } from 'components';
import ButtonCsv from 'components/ButtonCsv/ButtonCsv';
import { ElecsumSelect } from 'components/NewInputs';
import { SelectItemI } from 'components/NewInputs/Select/ElecsumSelect';
import { TabSwitchIcons, TabSwitchOption } from 'components/TabSwitchIcons/TabSwitchIcons';
import MatcherPerfilesConsumoInstance from 'services/remote/MatcherPerfilesConsumoService';
import ConfiguracionMiembro from 'types/models/configuracionMiembrosMatcher';
import CupsTooltipOferta from 'pages/SignUpPage/components/CupsTooltip/CupsTooltipOferta';
import CupsTooltipInputMatcher from 'pages/SignUpPage/components/CupsTooltip/CupsTooltipInputMatcher';
import MatcherConfiguracionMiembrosInstance from 'services/remote/MatcherConfiguracionMiembrosService';
import styles from '../../AddMatcherPage.module.scss';

interface AddMemberPopUpProps {
  mensajeError: { codigo: string; mensaje: string };
  miembroConfiguracion: ConfiguracionMiembro;
  changeValue: CallableFunction;
  setFile: CallableFunction;
  añoSeleccionado: number;
}

const EditMemberPopUp = ({
  miembroConfiguracion,
  mensajeError,
  changeValue,
  setFile,
  añoSeleccionado,
}: AddMemberPopUpProps): JSX.Element => {
  const [perfilesDeConsumo, setPerfilesDeConsumo] = useState<Array<SelectItemI>>([]);
  const [selectedPerfilConsumo, setSelectedPerfilConsumo] = useState<SelectItemI>();
  const [isActive, setIsActive] = useState(false);

  const TextoBetaMax = [
    I18n.t('TooltipOferta.AddBetaMaxTitulo'),
    I18n.t('TooltipOferta.AddBetaMaxLista1'),
    I18n.t('TooltipOferta.AddBetaMaxLista2'),
  ];

  const TextoBetaMin = [
    I18n.t('TooltipOferta.AddBetaMinTitulo'),
    I18n.t('TooltipOferta.AddBetaMinLista1'),
    I18n.t('TooltipOferta.AddBetaMinLista2'),
  ];
  const TerminoEnergia = [
    I18n.t('TooltipOferta.TerminoEnergiaTitulo'),
    I18n.t('TooltipOferta.TerminoEnergiaLista1'),
    I18n.t('TooltipOferta.TerminoEnergiaLista2'),
  ];
  const TextoExcedente = [
    I18n.t('TooltipOferta.ExcedenteTitulo'),
    I18n.t('TooltipOferta.ExcedenteLista1'),
    I18n.t('TooltipOferta.ExcedenteLista2'),
  ];

  const POWERS_ARRAY_DOMESTICO = [
    {
      label: `${I18n.t('UpdateTarifaPage.consumPunta')}`,
      type: 'number',
      id: 'terminoEnergiaP1',
      path: 'terminoEnergiaP1',
      name: 'terminoEnergiaP1',
      placeholder: '0',
      value: miembroConfiguracion.terminoEnergiaP1,
      step: 0.01,
    },
    {
      label: `${I18n.t('UpdateTarifaPage.consumPla')}`,
      type: 'number',
      id: 'terminoEnergiaP2',
      path: 'terminoEnergiaP2',
      name: 'terminoEnergiaP2',
      placeholder: '0',
      value: miembroConfiguracion.terminoEnergiaP2,
      step: 0.01,
    },
    {
      label: `${I18n.t('UpdateTarifaPage.consumVall')}`,
      type: 'number',
      id: 'terminoEnergiaP3',
      path: 'terminoEnergiaP3',
      name: 'terminoEnergiaP3',
      placeholder: '0',
      value: miembroConfiguracion.terminoEnergiaP3,
      step: 0.01,
    },
    {
      label: `${I18n.t('UpdateTarifaPage.compensacion')}`,
      type: 'number',
      id: 'precioCompensacion',
      path: 'precioCompensacion',
      name: 'precioCompensacion',
      placeholder: '0',
      value: miembroConfiguracion.precioCompensacion,
      tooltip: (
        <CupsTooltipInputMatcher
          title={I18n.t('UpdateTarifaPage.compensacion')}
          descripcion={TextoExcedente}
          direction="right"
        />
      ),
    },
  ];
  const POWERS_ARRAY_INDUSTRIAL = [
    {
      label: `${I18n.t('UpdateTarifaPage.p1')}`,
      type: 'number',
      id: 'terminoDeEnergiaP1',
      path: 'terminoDeEnergiaP1',
      name: 'terminoDeEnergiaP1',
      placeholder: '0',
      value: miembroConfiguracion.terminoEnergiaP1,
      min: 0.01,
      step: 0.01,
    },
    {
      label: `${I18n.t('UpdateTarifaPage.p2')}`,
      type: 'number',
      id: 'terminoDeEnergiaP2',
      path: 'terminoDeEnergiaP2',
      name: 'terminoDeEnergiaP2',
      placeholder: '0',
      value: miembroConfiguracion.terminoEnergiaP2,
      step: 0.01,
    },
    {
      label: `${I18n.t('UpdateTarifaPage.p3')}`,
      type: 'number',
      id: 'terminoDeEnergiaP3',
      path: 'terminoDeEnergiaP3',
      name: 'terminoDeEnergiaP3',
      placeholder: '0',
      value: miembroConfiguracion.terminoEnergiaP3,
      step: 0.01,
    },
    {
      label: `${I18n.t('UpdateTarifaPage.p4')}`,
      type: 'number',
      id: 'terminoDeEnergiaP4',
      path: 'terminoDeEnergiaP4',
      name: 'terminoDeEnergiaP4',
      placeholder: '0',
      value: miembroConfiguracion.terminoEnergiaP4,
      step: 0.01,
    },
    {
      label: `${I18n.t('UpdateTarifaPage.p5')}`,
      type: 'number',
      id: 'terminoDeEnergiaP5',
      path: 'terminoDeEnergiaP5',
      name: 'terminoDeEnergiaP5',
      placeholder: '0',
      value: miembroConfiguracion.terminoEnergiaP5,
      step: 0.01,
    },
    {
      label: `${I18n.t('UpdateTarifaPage.p6')}`,
      type: 'number',
      id: 'terminoDeEnergiaP6',
      path: 'terminoDeEnergiaP6',
      name: 'terminoDeEnergiaP6',
      placeholder: '0',
      value: miembroConfiguracion.terminoEnergiaP6,
      step: 0.01,
    },
    {
      label: `${I18n.t('UpdateTarifaPage.compensacion')}`,
      type: 'number',
      id: 'compensacionImporte',
      path: 'compensacionImporte',
      name: 'compensacionImporte',
      placeholder: '0',
      value: miembroConfiguracion.precioCompensacion,
      tooltip: (
        <CupsTooltipInputMatcher
          title={I18n.t('UpdateTarifaPage.compensacion')}
          descripcion={TextoExcedente}
          direction="right"
        />
      ),
    },
  ];
  const formGroupModalData: Array<FormGroupProps> = [
    {
      label: I18n.t('PerfilListPage.addMatcher.preferent'),
      type: 'checkbox',
      id: 'miembroPreferente',
      path: 'miembroPreferente',
      name: 'miembroPreferente',
      value: miembroConfiguracion.miembroPreferente === 'si',
      checked: miembroConfiguracion.miembroPreferente === 'si',
    },
    {
      label: 'vip',
      type: 'checkbox',
      id: 'miembroVip',
      path: 'miembroVip',
      name: 'miembroVip',
      value: miembroConfiguracion.miembroVip === 'si',
      checked: miembroConfiguracion.miembroVip === 'si',
    },
    {
      label: 'βMax',
      type: 'betas',
      id: 'betaMax',
      path: 'betaMax',
      name: 'betaMax',
      placeholder: '0',
      value: miembroConfiguracion.betaMax,
      min: 0,
      step: 0.001,
      max: 1,
      tooltip: <CupsTooltipInputMatcher title="βMax" descripcion={TextoBetaMax} direction="left" />,
    },
    {
      label: 'βMin',
      type: 'betas',
      id: 'betaMin',
      path: 'betaMin',
      name: 'betaMin',
      placeholder: '0',
      value: miembroConfiguracion.betaMin,
      min: 0,
      step: 0.001,
      max: 1,
      tooltip: <CupsTooltipInputMatcher title="βMin" descripcion={TextoBetaMin} direction="left" />,
    },
  ];
  const formGroupDomestico: Array<FormGroupProps> = [
    {
      label: I18n.t('InscritosDetalle.tipoConsumo'),
      type: 'select',
      id: 'tipoConsumo',
      path: 'tipoConsumo',
      name: 'tipoConsumo',
      placeholder: 'wwww',
      value: miembroConfiguracion.tipoConsumo,
      options: [
        { label: 'Domestic', value: 'domestico' },
        { label: 'Industrial', value: 'industrial' },
      ],
    },
  ];

  const formFieldsPersonalMemberData: Array<FormGroupProps> = [
    {
      label: I18n.t('InscritosDetalle.nombre'),

      type: 'string',
      id: 'nombre',
      path: 'nombre',
      name: 'nombre',
      value: miembroConfiguracion.nombre,
      placeholder: '',
    },
    {
      label: I18n.t('InscritosDetalle.apellidos'),

      type: 'string',
      id: 'apellidos',
      path: 'apellidos',
      name: 'apellidos',
      value: miembroConfiguracion.apellidos,
      placeholder: '',
    },
    {
      label: I18n.t('InscritosDetalle.Email'),
      type: 'string',
      id: 'email',
      path: 'email',
      name: 'email',
      value: miembroConfiguracion.email,
      placeholder: '',
    },
    {
      label: I18n.t('InscritosDetalle.cups'),
      type: 'string',
      id: 'cups',
      path: 'cups',
      name: 'cups',
      value: miembroConfiguracion.cups,
      placeholder: '',
    },

    {
      label: I18n.t('InscritosDetalle.Dni'),
      type: 'string',
      id: 'nif',
      path: 'nif',
      name: 'nif',
      placeholder: '',
      value: miembroConfiguracion.nif,
    },
    {
      label: I18n.t('InscritosDetalle.telefon'),

      type: 'string',
      id: 'telefono',
      path: 'telefono',
      name: 'telefon',
      value: miembroConfiguracion.telefono,
    },

    {
      label: I18n.t('InscritosDetalle.Direccion'),

      type: 'string',
      id: 'Direccion',
      path: 'direccionCompleta',
      name: 'Direccion',
      value: miembroConfiguracion.direccionCompleta,
      placeholder: '',
    },
  ];
  const formGroupConsumoAnual: Array<FormGroupProps> = [
    {
      label: I18n.t('PerfilListPage.ConsumoAnual'),
      type: 'number',
      id: 'consumoAnual',
      path: 'consumoAnual',
      name: 'consumoAnual',
      placeholder: '0',
      value: miembroConfiguracion.consumoAnual,
    },
  ];

  // Actualizamos los datos del nuevo miembro
  async function handleCheckBox(path: string, value: string, id: string, checked: boolean): Promise<unknown> {
    const NewValue: string = checked ? 'si' : 'no';

    changeValue({
      ...miembroConfiguracion,
      [path]: checked === undefined ? value : NewValue,
    });

    return true;
  }
  function handelChangeDomestico(path: string, value: string): void {
    changeValue({
      ...miembroConfiguracion,
      [path]: value,
    });
    // onUpdateIsDomestico({ ...isDomestico, [path]: value });
  }
  // Actualizamos los datos de las potencias del nuevo miembro
  function handleChangePotencias(path: string, value: string): void {
    changeValue({
      ...miembroConfiguracion,
      [path]: value,
    });
  }
  // Actualizamos los datos personales del nuevo miembro
  function handlePersonalMemberData(path: string, value: string): void {
    changeValue({
      ...miembroConfiguracion,
      [path]: value,
    });
  }
  // Actualizamos el consumo anual del miembro
  function handleConsumoAnual(path: string, value: string): void {
    changeValue({
      ...miembroConfiguracion,
      [path]: value,
    });
  }

  const handleFileSelect = (file: File) => {
    //  onUpdatePerfilCSV(file);
    setFile(file);
  };
  const [tabSelected, setTabSelected] = useState('Selecionar');

  const handleTabClick = (option: TabSwitchOption) => {
    setTabSelected(option.value as string);
  };
  // Funcion para renderizar el form con su onchange
  const renderFormGroup = (
    formGroup: FormGroupProps,
    onChange: (path: string, value: string, id: string, checked: boolean) => void,
  ) => (
    <FormGroup
      key={formGroup.id}
      label={formGroup.label}
      type={formGroup.type}
      id={formGroup.id}
      path={formGroup.path}
      name={formGroup.name}
      placeholder={formGroup.placeholder}
      value={formGroup.value}
      onChange={onChange}
      options={formGroup.options}
      disabled={formGroup.disabled}
      tabindex={formGroup.tabindex}
      readOnly={formGroup.readOnly}
      error={formGroup.error}
      checked={formGroup.checked}
      min={formGroup.min}
      max={formGroup.max}
      step={formGroup.step}
      tooltip={formGroup.tooltip}
    />
  );

  const exportInforme = async (): Promise<void> => {
    try {
      const ejecutarInforme = await MatcherConfiguracionMiembrosInstance.descargarInforme(
        miembroConfiguracion.idConfiguracion,
        miembroConfiguracion.id,
      );

      const a = document.createElement('a');
      a.href = `data:text/csv;charset=utf-8,${ejecutarInforme}`;
      a.textContent = 'download';
      a.download = `${new Date().toLocaleDateString()}_ExportData.csv`;
      a.click();
      a.remove();
    } catch (error) {
      console.log(error);
    }
  };
  // Renderizar el form en caso Domestico
  const renderDomesticoSelect = () =>
    formGroupDomestico.map((formGroup: FormGroupProps) => (
      <div>
        <div className={styles.modalFlex}>{renderFormGroup(formGroup, handelChangeDomestico)}</div>
      </div>
    ));

  // Renderizar el form en caso Domestico
  const renderDomesticoPowers = () =>
    POWERS_ARRAY_DOMESTICO.map((formGroup: FormGroupProps) => (
      <div>
        <div className={styles.modalFlex}>{renderFormGroup(formGroup, handleChangePotencias)}</div>
      </div>
    ));

  const renderIndustrialPowers = () =>
    POWERS_ARRAY_INDUSTRIAL.map((formGroup: FormGroupProps) => (
      <div>
        <div className={styles.modalFlex}>{renderFormGroup(formGroup, handleChangePotencias)}</div>
      </div>
    ));

  // Renderizar el form en caso Domestico
  const renderPersonalMemberData = () =>
    formFieldsPersonalMemberData.map((formGroup: FormGroupProps) => (
      <div>
        <div className={styles.modalFlex}>{renderFormGroup(formGroup, handlePersonalMemberData)}</div>
      </div>
    ));

  // Renderizar el form en caso Domestico
  const renderModalData = () =>
    formGroupModalData.map((formGroup: FormGroupProps) => (
      <div>
        <div className={styles.modalFlex}>{renderFormGroup(formGroup, handleCheckBox)}</div>
      </div>
    ));

  // Renderizar el form Modal Data
  const renderConsumoAnual = () =>
    formGroupConsumoAnual.map((formGroup: FormGroupProps) => (
      <div>
        <div className={styles.modalFlex}>{renderFormGroup(formGroup, handleConsumoAnual)}</div>
      </div>
    ));

  const TABS_AVAILABLES: TabSwitchOption[] = [
    {
      id: '1',
      value: 'Selecionar',
      label: I18n.t('MatcherListPage.addMatcher.selectPerfil'),
    },
    {
      id: '2',
      value: 'Upload',
      label: `${I18n.t('MatcherListPage.addPerfilConsumo')}`,
    },
  ];

  const getAllPerfiles = () => {
    MatcherPerfilesConsumoInstance.getAllPerfilesDeConsumo().then((response) => {
      const perfilesSelect = response
        .filter((perfil) => perfil.infoDeConsumo.año === añoSeleccionado)
        .map((perfil) => ({
          id: perfil.id.toString(),
          value: perfil.nombre,
          label: perfil.nombre,
        }));
      setPerfilesDeConsumo(perfilesSelect);
      setSelectedPerfilConsumo(perfilesSelect.find((e) => e.id === miembroConfiguracion.idPerfil.toString()));
    });
  };
  useEffect(() => {
    getAllPerfiles();
  }, []);

  // Renderizamos el componente
  return (
    <div className={styles.modalFlex}>
      <SectionTitle className={styles.modalTitle} text={I18n.t('Dades personals')} />
      <div className={styles.modalGrid}>{renderPersonalMemberData()}</div>
      <div className={styles.tabModal}>
        <SectionTitle className={styles.modalTitle} text={I18n.t('MatcherListPage.addMatcher.perfilConsumo')} />
        <TabSwitchIcons
          value={TABS_AVAILABLES.find((option) => option.value === tabSelected) as TabSwitchOption}
          values={TABS_AVAILABLES}
          onChange={(item) => {
            handleTabClick(item);
          }}
        />
      </div>
      <div>
        {tabSelected === 'Selecionar' ? (
          <div className={styles.modalFlexRow}>
            <ElecsumSelect
              minWidth="17.5rem"
              values={perfilesDeConsumo}
              selected={selectedPerfilConsumo}
              label={I18n.t('MatcherListPage.addMatcher.selectPerfil')}
              onChange={(el) => {
                // onUpdatePerfilSelect(el);
                setSelectedPerfilConsumo(el);
                changeValue({
                  ...miembroConfiguracion,
                  idPerfil: parseInt(el.id, 10),
                });
              }}
            />
            <div>{renderConsumoAnual()}</div>
          </div>
        ) : (
          <div className={styles.modalCsv}>
            {miembroConfiguracion.infoCurvaDeConsumo.año === '' ? (
              <>
                <p>{I18n.t('PerfilListPage.showPerfilConsumoCsv')}</p>
                <ButtonCsv className={styles.csv_button} onFileSelect={handleFileSelect} />
                {mensajeError.codigo === '' ? null : (
                  <div className={styles.errorMessage}>
                    <p>{I18n.t(mensajeError.codigo)}</p>
                    <p>{mensajeError.mensaje}</p>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className={styles.datosPerfilConsumo}>
                  <p>{`${I18n.t('Year')} : ${miembroConfiguracion.infoCurvaDeConsumo.año}`}</p>
                  <p>
                    {`${I18n.t('PerfilListPage.ConsumoAnual')} : ${
                      miembroConfiguracion.infoCurvaDeConsumo.consumo
                    } Kwh`}
                  </p>
                </div>
                <div className={styles.toogle_wrapper}>
                  <p>{I18n.t('PerfilListPage.tituloCurva')}</p>
                  <Toggle value={isActive} onChange={setIsActive} />
                </div>
                <div className={styles.toogle_wrapper} onClick={exportInforme}>
                  <p className={styles.link_paragraph}>
                    {I18n.t('PerfilListPage.addMatcher.exportGeneracioDescExist')}
                  </p>
                </div>
                {isActive ? (
                  <>
                    <CupsTooltipOferta title={I18n.t('PerfilListPage.showPerfilConsumoCsv')} />

                    <ButtonCsv className={styles.csv_button} onFileSelect={handleFileSelect} />
                    {mensajeError.codigo === '' ? null : (
                      <div className={styles.errorMessage}>
                        <p>{I18n.t(mensajeError.codigo)}</p>
                        <p>{mensajeError.mensaje}</p>
                      </div>
                    )}
                  </>
                ) : null}
              </>
            )}
          </div>
        )}
      </div>

      <SectionTitle className={styles.modalTitle} text={I18n.t('Caracteristiques')} />
      <div className={styles.modalGrid4Columns}>{renderModalData()}</div>
      <div className={styles.modalGrid}>{renderDomesticoSelect()}</div>
      <div />
      <div>
        <div className={styles.modalTitle}>
          <CupsTooltipInputMatcher
            title={I18n.t('UpdateTarifaPage.preuTerme')}
            descripcion={TerminoEnergia}
            direction="right"
          />
        </div>{' '}
        {miembroConfiguracion.tipoConsumo === 'domestico' ? (
          <div className={styles.modalGrid}>{renderDomesticoPowers()}</div>
        ) : (
          <div className={styles.modalGrid}>{renderIndustrialPowers()}</div>
        )}
      </div>
    </div>
  );
};

export default EditMemberPopUp;
