import { Serializable } from 'types/interfaces';

export interface SolicitudesAPI {
  solicitudId: number;
  cups: string;
  nombre: string;
  acceptada: 'si' | 'no';
  propietario: 'si' | 'no';

  solicitante: string;
}

export default class Solicitudes implements Serializable<Solicitudes> {
  solicitudId: number;

  cups: string;

  nombre: string;

  solicitante: string;

  acceptada: 'si' | 'no';

  propietario: 'si' | 'no';

  deserialize(input: SolicitudesAPI): Solicitudes {
    if (!input) {
      return this;
    }

    this.solicitudId = input.solicitudId || 0;
    this.cups = input.cups || '';
    this.nombre = input.nombre || '';
    this.solicitante = input.solicitante || '';

    this.acceptada = input.acceptada || 'no';
    this.propietario = input.propietario || 'no';
    return this;
  }

  deserializeArray(inputArray: Array<SolicitudesAPI>): Array<Solicitudes> {
    const SolicitudesArray: Array<Solicitudes> = inputArray.map((input) => new Solicitudes().deserialize(input));

    return SolicitudesArray;
  }
}
