import { Tag } from 'components';
import { RoundedButton } from 'components/NewInputs';
import I18n from 'i18n-js';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Colors } from 'styles';
import { CommunityModel } from 'types/models/community';
import styles from './community-list-row.module.scss';

interface CommunityListRowProps {
  row: CommunityModel;
}

const ColorTags: any = {
  abierta: Colors.COLOR_ELECSUMGREEN,
  completa: Colors.COLOR_ORANGE_STRONG,
  cerrada: Colors.COLOR_ORANGE_STRONG,
  oculta: Colors.COLOR_PURPLE,
};

export const CommunityListRow = (props: CommunityListRowProps): JSX.Element => {
  const { row } = props;
  const { t } = I18n;
  const history = useHistory();

  return (
    <tr className={styles.mainrow}>
      <td>{row.nombre}</td>
      <td className={styles.maxWidth}>{row.domicilio}</td>
      <td>{row.fechaFinInscripciones}</td>
      <td>
        <Tag color={ColorTags[row.estado]}>{t(`ComunidadesListPage.estado.${row.estado}`)} </Tag>
      </td>

      <td>
        <RoundedButton
          onClick={() => {
            history.push(`/tecnic/communities/${row.id}/detail`);
          }}
        >
          {t(`ComunidadesListPage.detail`)}
        </RoundedButton>
      </td>
    </tr>
  );
};
