export interface EnergyDataAPII {
  fecha: string;
  generacion: number;
  excedente: number;
  red: number;
  autoconsumo: number;
  consumo: number;
}

export default class EnergyData {
  date: string;

  generation: number;

  shrink: number;

  network: number;

  selfconsumption: number;

  consumption: number;

  deserialize(input: EnergyDataAPII): EnergyData {
    if (!input) {
      return this;
    }

    this.date = input.fecha;
    this.generation = input.generacion;
    this.shrink = input.excedente;
    this.network = input.red;
    this.selfconsumption = input.autoconsumo;
    this.consumption = input.consumo;
    return this;
  }
}
