const CLIENTS_MOCK = [
  {
    nif: '12345678A',
    cups: 'ES01770000000000000001',
    nombre: 'FV Residential Trade (Titular)',
    production: 26495.4,
    distribution: 5007.0,
    distributionP: 0,
  },
  {
    nif: '12345678B',
    cups: 'ES01770000000000000002',
    nombre: 'Eunoia Digital S.L. ',
    production: 2519.2,
    distribution: 1251.75,
    distributionP: 25,
  },
  {
    nif: '12345678C',
    cups: 'ES01770000000000000003',
    nombre: 'BCN Associates',
    production: 2519.2,
    distribution: 1251.75,
    distributionP: 25,
  },
  {
    nif: '12345678D',
    cups: 'ES01770000000000000004',
    nombre: 'CaixaBank Payments',
    production: 2519.2,
    distribution: 1251.75,
    distributionP: 25,
  },
  {
    nif: '12345678E',
    cups: 'ES01770000000000000005',
    nombre: 'Figma S.L.',
    production: 2519.2,
    distribution: 1251.75,
    distributionP: 25,
  },
];

export default CLIENTS_MOCK;
