import { Serializable } from '../interfaces';

interface PowersListAPII {
  datos: {
    potencia1: number;
    potencia2: number;
    potencia3: number;
    potencia4: number;
    potencia5: number;
    potencia6: number;
  };
}

export default class PowersList implements Serializable<PowersList> {
  datos: {
    potencia1: number;
    potencia2: number;
    potencia3: number;
    potencia4: number;
    potencia5: number;
    potencia6: number;
  };

  deserialize(input: PowersListAPII): PowersList {
    if (!input) {
      return this;
    }
    this.datos = {
      potencia1: input.datos.potencia1 || 0,
      potencia2: input.datos.potencia2 || 0,
      potencia3: input.datos.potencia3 || 0,
      potencia4: input.datos.potencia4 || 0,
      potencia5: input.datos.potencia5 || 0,
      potencia6: input.datos.potencia6 || 0,
    };
    return this;
  }
}
