import { BehaviorSubject } from 'rxjs';

export interface HeaderPropsI {
  hideInstallation?: boolean;
  hideClient?: boolean;
  hideCommunity?: boolean;
}

export class HeaderService {
  private headerPropsSubject = new BehaviorSubject<HeaderPropsI>({});

  readonly headerProps$ = this.headerPropsSubject.asObservable();

  constructor(config?: HeaderPropsI) {
    if (config) {
      this.headerPropsSubject.next(config);
    } else {
      this.headerPropsSubject.next({
        hideClient: true,
        hideCommunity: false,
        hideInstallation: false,
      });
    }
  }

  set props(props: HeaderPropsI) {
    const currentProps: HeaderPropsI = this.props;
    this.headerPropsSubject.next({
      ...currentProps,
      ...props,
    });
  }

  get props(): HeaderPropsI {
    return this.headerPropsSubject.getValue();
  }
}

const headerServiceInstance = new HeaderService();

export default headerServiceInstance;
