import React from 'react';
import { EunoiaInput, EunoiaSliderInput, EunoiaTextAreaInput, EunoiaCheckbox, EunoiaRadioButton } from 'components';

import { EunoiaInputNumber, EunoiaSelect, NumberInputBetas, OptionsPropsI } from 'components/Input';
import cx from 'classnames';
import { ColorPicker } from 'pages/ModeTecnic/rol-comunidades/comunidades-custom-onboarding-process-page/components/comunidades-color-picker/comunidades-color-picker';
import EunoiaInputTimerPicker from 'components/Input/TimePicker/TimerPicker';
import NumberInputMargenes from 'components/Input/NumberInput/NumberInputMargenes';
import EunoiaEditorInput from 'components/Input/EunoiaEditorInput/EunoiaEditorInput';
import styles from './FormGroup.module.scss';

export interface FormGroupProps {
  path: string;
  name: string;
  value: any;
  type: string;
  id: string;
  onChange?: CallableFunction;
  onChangeEditor?: (path: string, value: any) => void;
  label?: string;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  required?: boolean;
  readOnly?: boolean;
  units?: string;
  max?: number;
  min?: number;
  step?: number;
  rows?: number;
  cols?: number;
  checked?: boolean;
  maxLenght?: number;
  options?: Array<OptionsPropsI>;
  showDots?: boolean;
  disabled?: boolean;
  tabindex?: number;
  error?: string;
  tooltip?: React.ReactElement;
}

export const FORMGROUP_TYPES = {
  range: 'range',
  textarea: 'textarea',
  checkbox: 'checkbox',
  select: 'select',
  radio: 'radio',
  number: 'number',
  betas: 'betas',
  time: 'time',
  colorPicker: 'colorPicker',
  margenes: 'margenes',
  editor: 'editor', // Added editor type
};

const FormGroup = (props: FormGroupProps): JSX.Element => {
  const {
    value,
    type,
    id,
    label,
    required,
    placeholder,
    name,
    path,
    readOnly,
    className = '',
    inputClassName = '',
    onChange,
    units,
    max,
    min,
    step,
    rows,
    cols,
    checked,
    maxLenght,
    options,
    showDots,
    disabled,
    tabindex,
    error,
    tooltip,
    onChangeEditor = () => {},
  } = props;

  if (type === FORMGROUP_TYPES.colorPicker) {
    return (
      <div className={cx(styles.root, styles.range, className)}>
        <div className={styles.top}>{label ? <label htmlFor={name}>{label}</label> : null}</div>
        <ColorPicker path={path} placeholder={placeholder} value={value} onChange={onChange} />
      </div>
    );
  }

  if (type === FORMGROUP_TYPES.range) {
    return (
      <div className={cx(styles.root, styles.range, className)}>
        <div className={styles.top}>
          {label ? (
            <label htmlFor={name} className={styles.info}>
              {label}
            </label>
          ) : null}
          <div className={styles.value}>
            {value} {units}
          </div>
        </div>
        <EunoiaSliderInput
          type={type}
          id={id}
          path={path}
          name={name}
          value={value}
          min={min === undefined ? 0 : min}
          max={max || 100}
          step={step || 1}
          onChange={onChange}
        />
      </div>
    );
  }

  if (type === FORMGROUP_TYPES.checkbox) {
    return (
      <div className={cx(styles.root, className)}>
        <EunoiaCheckbox
          label={label || ''}
          type={type}
          id={id}
          path={path}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        />
      </div>
    );
  }
  if (type === FORMGROUP_TYPES.radio) {
    return (
      <div className={cx(styles.root, className)}>
        <EunoiaRadioButton
          label={label || ''}
          type={type}
          id={id}
          path={path}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        />
      </div>
    );
  }
  if (type === FORMGROUP_TYPES.select) {
    return (
      <div className={cx(styles.root, className)}>
        {label ? <label htmlFor={name}>{label}</label> : null}
        <EunoiaSelect
          label={label || ''}
          type={type}
          id={id}
          path={path}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          options={options}
          showDots={showDots}
          disabled={disabled || false}
          tabindex={tabindex}
        />
      </div>
    );
  }
  if (type === FORMGROUP_TYPES.number) {
    return (
      <div className={cx(styles.root, className)}>
        {tooltip || (label ? <label htmlFor={name}>{label}</label> : null)}

        <EunoiaInputNumber
          type={type}
          id={id}
          path={path}
          name={name}
          placeholder={placeholder}
          value={value}
          readOnly={readOnly}
          className={inputClassName}
          required={required}
          onChange={onChange}
          min={min}
          max={max}
          step={step}
        />
      </div>
    );
  }
  if (type === FORMGROUP_TYPES.betas) {
    return (
      <div className={cx(styles.root, className)}>
        {tooltip || (label ? <label htmlFor={name}>{label}</label> : null)}

        <NumberInputBetas
          type={type}
          id={id}
          path={path}
          name={name}
          placeholder={placeholder}
          value={value}
          readOnly={readOnly}
          className={inputClassName}
          required={required}
          onChange={onChange}
          min={min}
          max={max}
          step={step}
        />
      </div>
    );
  }
  if (type === FORMGROUP_TYPES.margenes) {
    return (
      <div className={cx(styles.root, className)}>
        {tooltip || (label ? <label htmlFor={name}>{label}</label> : null)}

        <NumberInputMargenes
          type={type}
          id={id}
          path={path}
          name={name}
          placeholder={placeholder}
          value={value}
          readOnly={readOnly}
          className={inputClassName}
          required={required}
          onChange={onChange}
          min={min}
          max={max}
          step={step}
        />
      </div>
    );
  }
  if (type === FORMGROUP_TYPES.time) {
    return (
      <div className={cx(styles.root, className)}>
        {label ? <label htmlFor={name}>{label}</label> : null}
        <EunoiaInputTimerPicker
          type={type}
          id={id}
          path={path}
          name={name}
          placeholder={placeholder}
          value={value}
          readOnly={readOnly}
          className={inputClassName}
          required={required}
          onChange={onChange}
          min={min}
          max={max}
          step={step}
        />
      </div>
    );
  }

  if (type === FORMGROUP_TYPES.editor) {
    return (
      <div className={cx(styles.root, className)}>
        {label ? <label htmlFor={name}>{label}</label> : null}
        <EunoiaEditorInput
          id={id}
          path={path}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChangeEditor}
        />
      </div>
    );
  }

  return (
    <div className={cx(styles.root, className)}>
      {label ? <label htmlFor={name}>{label}</label> : null}
      {type === FORMGROUP_TYPES.textarea ? (
        <EunoiaTextAreaInput
          id={id}
          path={path}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          rows={rows || 10}
          cols={cols || 50}
          maxLenght={maxLenght}
        />
      ) : (
        <EunoiaInput
          type={type}
          id={id}
          path={path}
          name={name}
          placeholder={placeholder}
          value={value}
          readOnly={readOnly}
          className={inputClassName}
          required={required}
          onChange={onChange}
          min={min}
          max={max}
          step={step}
          tabindex={tabindex}
          error={error}
        />
      )}
    </div>
  );
};

export default FormGroup;
