import I18n from 'i18n-js';

import ca from 'assets/i18n/ca';
import es from 'assets/i18n/es';

// Set the locale once at the beginning of your app.
I18n.locale = navigator.language;
I18n.translations = {
  ca,
  es,
};
// When a value is missing from a language it'll fallback to another language with the key present.
I18n.fallbacks = true;
I18n.defaultLocale = 'es';

export default I18n;
