import configuration from 'config';
import { AxiosResponse } from 'axios';

import RolInfoPermisos, { RolInfoPermisosAPI, RolInfoPermisosUpdateAPI } from 'types/models/rolinfopermiso';
import apiService from './ApiService';

class RolesPermisosService {
  private readonly http = apiService;

  private apiUrl = configuration.apiUrl;

  public async getPermisos() {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/auth/permisos/`);
    return new RolInfoPermisos().deserializeArray(response.data.datos);
  }

  public async updateRolInfoPermisos(userInfoObject: RolInfoPermisosAPI) {
    const url = `${this.apiUrl}/auth/permisos/${userInfoObject.id}/`;
    const userUpdate: RolInfoPermisosUpdateAPI = {
      nombre: userInfoObject.nombre,
    };

    const response: AxiosResponse<string> = await this.http.patch(url, userUpdate);

    return response.data;
  }
}

const rolesPermisosService: RolesPermisosService = new RolesPermisosService();

export default rolesPermisosService;
