import React from 'react';
import { ReactComponent as UserIcon } from 'assets/icons/User.svg';
import { Colors } from 'styles';
import styles from './Display.module.scss';

interface InstallationDisplayPropsI {
  name: string;
  surname: string;
  nif: string;
  email: string;
}

const UserDisplay = (props: InstallationDisplayPropsI): JSX.Element => {
  const { name, nif, email, surname } = props;
  return (
    <div className={styles.root}>
      <div className={styles.icon}>
        <div className={styles.logo}>
          <UserIcon height={25} width={25} fill={Colors.COLOR_BLUE_200} />
        </div>
      </div>
      <div className={styles.data}>
        <p className={styles.title}>{`${name} ${surname}`}</p>
        <p className={styles.subtitle}>{email}</p>
        <p className={styles.quote}>{nif}</p>
      </div>
    </div>
  );
};

export default React.memo(UserDisplay);
