import { DataTable, EunoiaImageInput, LoadingButton, SectionTitle, TableHeading } from 'components';
import React, { useRef, useState } from 'react';
import { I18n } from 'core';
import { ReactComponent as LandscapeIcon } from 'assets/icons/Landscape.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg';
import { Colors } from 'styles';

import customToast from 'components/CustomToast/CustomToast';
import { toast } from 'react-toastify';
import { CommunityCustomOnboardingServiceInstance, LoggingService } from 'services';
import { CustomizableImage, CustomizableImageType } from 'types/models';
import ImageInputWithOptions from 'components/Input/ImageInput/ImageInputWithOptions';
import ImageType from 'types/enums/ImageTypes';
import styles from './comunidades-custom-onboarding-image-form.module.scss';

interface ComunidadesCustomOnboardingImageFormProps {
  images: Array<CustomizableImage>;
  afterSubmitAction?: CallableFunction;
  communitiyId: string;
  imageType: CustomizableImageType;
  imageFormTitle: string;
  addImageText: string;
  imageNote: string;
  buttonText: string;
}
export const ComunidadesCustomOnboardingImageForm = ({
  communitiyId,
  images,
  afterSubmitAction,
  imageType,
  imageFormTitle,
  addImageText,
  imageNote,
  buttonText,
}: ComunidadesCustomOnboardingImageFormProps): JSX.Element => {
  // const [image] = images;
  // const [fileName, setFileName] = useState<string>(image?.url);
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectedType, setSelectedType] = useState<string>(
    imageType === ImageType.logo.toString() ? ImageType.logo : ImageType.imagen,
  ); // Nuevo
  const imageInputRef = useRef<HTMLInputElement | null>(null);

  const validateForm = (): boolean => {
    if (!file) {
      setSubmitDisabled(true);
      setErrors({});
      return false;
    }
    setSubmitDisabled(false);
    setErrors({});
    return true;
  };
  const submitForm = async (type: CustomizableImageType, selectedTypeImage: string): Promise<boolean> => {
    setLoading(true);
    setSubmitDisabled(true);

    try {
      if (file) {
        const response = await CommunityCustomOnboardingServiceInstance.addImage(
          communitiyId,
          file,
          type,
          selectedTypeImage,
        );
        if (response === 201) {
          customToast.success(I18n.t('Image saved!'));

          if (afterSubmitAction) {
            afterSubmitAction(communitiyId);
          }

          //   setSubmitDisabled(false);
        }
      }
    } catch (error) {
      toast.error(I18n.t('Error Uploading gallery image.'));
      setSubmitDisabled(false);
      setLoading(false);
      setFile(undefined);
    }

    // setLoading(false);
    // setFile(undefined);

    return true;
  };
  const settingFile = (file1: File): void => {
    if (file1) {
      setFile(file1);
      setIsButtonDisabled(false);
    }
  };
  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (validateForm()) {
      LoggingService.log('handleSubmit :: ok', { file });
      submitForm(imageType, selectedType);
    } else {
      LoggingService.log('handleSubmit :: errors', { file, errors });
    }
  };

  const deleteImage = async (imagedel: any): Promise<void> => {
    // eslint-disable-next-line no-alert
    const accept = window.confirm(I18n.t('Are you sure you want to delete the image?'));

    if (!accept) {
      return;
    }

    try {
      await CommunityCustomOnboardingServiceInstance.deleteImage(communitiyId, imagedel.id);

      customToast.success(`${I18n.t('Image deleted successfully')}!`);

      if (afterSubmitAction) {
        afterSubmitAction(communitiyId);
      }
      setSubmitDisabled(false);
    } catch (error) {
      toast.error(`${I18n.t('Error deleting image')}.`);
    }
  };

  const headings: Array<TableHeading> = [
    { label: I18n.t('Image'), type: 'image', key: 'url' },
    { label: I18n.t('Tipus'), type: 'value', key: 'mostrar' },

    {
      label: I18n.t('Actions'),
      type: 'actions',
      key: '',
      actions: [
        {
          label: I18n.t('Delete'),
          icon: <TrashIcon height={25} width={25} fill={Colors.COLOR_ELECSUMGREEN} />,
          onActionClick: deleteImage,
        },
      ],
    },
  ];

  return (
    <>
      <div className={styles.flex}>
        <LandscapeIcon height={20} width={20} fill={Colors.COLOR_BLACK} className={styles.LandscapeIcon} />
        <SectionTitle text={imageFormTitle} />
      </div>
      {images.length <= 0 ? (
        <form className={styles.form} onSubmit={onSubmit}>
          <div className={styles.fields}>
            {imageType === 'logo' ? (
              <EunoiaImageInput
                id={imageType}
                label={addImageText}
                note={imageNote}
                fileName=""
                icon={<LandscapeIcon height={20} width={20} fill={Colors.COLOR_GRAY_400} />}
                type={imageType}
                action={settingFile}
              />
            ) : (
              <ImageInputWithOptions
                ref={imageInputRef}
                id={imageType}
                label={addImageText}
                note={imageNote}
                tituloSeleccion={I18n.t('SelecionaImagen')}
                opcion1={I18n.t('Imatge')}
                opcion2={I18n.t('Logo')}
                fileName=""
                icon={<LandscapeIcon height={20} width={20} fill={Colors.COLOR_GRAY_400} />}
                type={imageType}
                action={settingFile}
                mostrar={setSelectedType}
                defaultSelect={selectedType}
              />
            )}
          </div>
          <div className={styles.actions}>
            <LoadingButton
              type="submit"
              text={buttonText}
              disabled={isButtonDisabled || submitDisabled}
              loading={loading}
            />
          </div>
        </form>
      ) : null}

      <div className="col-12">
        <div className="table-news">
          {images && images.length > 0 ? <DataTable headings={headings} rows={images} loading={loading} /> : null}
        </div>
      </div>
    </>
  );
};
