import React, { useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import 'moment/min/locales';
import moment from 'moment';
import './DayPicker.scss';
import { I18n } from 'core';

interface DayPickerPropsI {
  label?: string;
  date: Date;
  onChangeDate(date: Date | null): void;
}

const DayPicker = (props: DayPickerPropsI): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { label, date, onChangeDate } = props;
  const [focusInput, setFocusInput] = useState<any>(null);
  const [currentDate, setCurrentDate] = useState<moment.Moment>(moment(date));
  moment.locale(I18n.currentLocale().substring(0, 2));
  useEffect(() => {
    setCurrentDate(moment(date));
  }, [date]);

  return (
    <div className="DayPicker">
      {label && <p className="label">{label}</p>}
      <SingleDatePicker
        date={currentDate}
        numberOfMonths={1}
        anchorDirection="right"
        hideKeyboardShortcutsPanel
        onDateChange={(dt) => {
          onChangeDate(dt ? dt.toDate() : null);
        }}
        enableOutsideDays
        isOutsideRange={() => false}
        focused={focusInput}
        onFocusChange={({ focused }) => setFocusInput(focused)}
        id="date"
      />
    </div>
  );
};

export default React.memo(DayPicker);
