import LoadingButton from 'components/LoadingButton/LoadingButton';
import React from 'react';
import styles from './Modal.module.scss';

const Modal = (props: any): JSX.Element => {
  const { title, body, onClick, testbutton, onClick2 } = props;

  return (
    <div
      className={styles.popUp}
      id="exampleModal"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{
        display: 'block',
        position: 'fixed',
        top: '50%',
        left: '50%',
        margin: '0 -50% 0 0',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFFFFF50',
        width: '100%',
        height: '100%',
        zIndex: 123321312,
      }}
    >
      <div className={styles.modaldialog} role="document">
        <div className={styles.modalcontent}>
          <div className={styles.modalheader}>
            <h5 className={styles.modaltitle} id="exampleModalLabel">
              {title}
            </h5>
            <button type="button" className={styles.close} data-dismiss="modal" aria-label="Close" onClick={onClick2}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className={styles.modalbody}>{body}</div>
          {testbutton && (
            <div style={{ display: 'flex', justifyContent: 'end', padding: '15px' }} className={styles.footer}>
              <div className={styles.botonModal}>
                <LoadingButton type="submit" text={testbutton} onClick={onClick} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Modal;
