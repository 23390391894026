import React from 'react';
import cx from 'classnames';
import styles from './EnvironmentReporter.module.scss';

const EnvironmentReporter = (): JSX.Element | null => {
  if (process.env.REACT_APP_ENV !== 'prod') {
    return (
      <>
        <div className={cx(styles.root, styles.dev)}>
          <span className={styles.value}>{process.env.REACT_APP_ENV}</span>
          <div className={cx(styles.responsive_reporter)} />
        </div>
      </>
    );
  }
  return null;
};

export default EnvironmentReporter;
