import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as DocumentsIcon } from 'assets/icons/Documents.svg';

import { FormGroupProps, FormGroup, I18n } from 'core';
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg';

import { SectionSubTitle, LoadingButton, DataTable, TableHeading } from 'components';
import { LoggingService } from 'services';

import ComunityCaracteristicasNew from 'types/models/comunityCaracteristicas';

import customToast from 'components/CustomToast/CustomToast';
//  import { Colors } from 'styles';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import CommunitiesServiceIntance from 'services/remote/communities-service';
import { SelectItemI } from 'components/NewInputs/Select/ElecsumSelect';
import { Colors } from 'styles';
import styles from './comunidades-detalle-Caracteristicas.module.scss';

interface ENPropsI {
  nif: string;
  news: Array<any>;
  currentLang: SelectItemI;

  afterSubmitAction?: CallableFunction;
}
const ComunidadesDetalleCaracteristicas = ({ news, nif, afterSubmitAction, currentLang }: ENPropsI): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [environmentNew] = useState<ComunityCaracteristicasNew>(new ComunityCaracteristicasNew());
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [environmentNews, setEnvironmentNews] = useState<Array<any>>(new Array<any>());
  const [errors, setErrors] = useState({});
  const [test] = useState(true);

  const [impactoClimaticoEs, setimpactoClimaticoEs] = useState<string>('');
  const [impactoClimaticoCa, setimpactoClimaticoCa] = useState<string>('');
  const [textoEs, setextoEs] = useState<string>('');
  const [textoCa, setextoCa] = useState<string>('');
  const [valorEs, setvalorEs] = useState<string>('');
  const [valorCA, setvalorCA] = useState<string>('');

  const formGroupsEnvironmentNewData: Array<FormGroupProps> = [
    {
      label: I18n.t('Categoria'),
      type: 'text',
      id: 'categoria',
      path: 'categoria',
      name: 'categoria',
      placeholder: '',
      value: currentLang.value === 'Ca' ? environmentNew.categoria.ca : environmentNew.categoria.es,
    },
  ];
  const formGroupsEnvironmentNewDataText: Array<FormGroupProps> = [
    {
      label: I18n.t('Text'),
      type: 'text',
      id: 'texto',
      path: 'texto',
      name: 'texto',
      placeholder: '',
      value: currentLang.value === 'Ca' ? environmentNew.texto.ca : environmentNew.texto.es,
    },
  ];
  const formGroupsEnvironmentNewDescription: Array<FormGroupProps> = [
    {
      label: I18n.t('Valor'),
      type: 'text',
      id: 'valor',
      path: 'valor',
      name: 'valor',
      placeholder: '',

      value: currentLang.value === 'Ca' ? environmentNew.valor.ca : environmentNew.valor.es,
    },
  ];

  const validateForm = useCallback((): boolean => {
    if (
      (environmentNew.categoria.ca !== '' && environmentNew.texto.ca !== '') ||
      (environmentNew.categoria.es !== '' && environmentNew.texto.es !== '')
    ) {
      setSubmitDisabled(false);
      setErrors({});

      return true;
    }
    setErrors({ error: 'Some empty field' });
    setSubmitDisabled(true);

    return false;
  }, [environmentNew]);
  const submitForm = async (): Promise<boolean> => {
    setLoading(true);
    setSubmitDisabled(true);

    // await of([]).pipe(delay(1000)).toPromise();
    try {
      const response = await CommunitiesServiceIntance.addCaracteristicas(nif, environmentNew);
      if (response === 201) {
        customToast.success(I18n.t('caracteristica saved!'));

        if (afterSubmitAction) {
          afterSubmitAction();
        }
      }

      setLoading(false);
      setSubmitDisabled(false);
    } catch (error) {
      setLoading(false);
      setSubmitDisabled(false);
      toast.error(I18n.t('Error saving new!'));
    }

    return true;
  };

  function handleChange<T>(path: string, value: T): void {
    // setEnvironmentNew(new EnvironmentNew().deserialize({
    //   ...environmentNew,
    //   [path]: value,
    // }));
    let x = {
      es: '',
      ca: '',
    };
    if (path === 'categoria' || path === 'texto' || path === 'valor') {
      if (path === 'categoria') {
        if (currentLang.value === 'Es') {
          x = {
            es: value as unknown as string,
            ca: impactoClimaticoCa,
          };
        } else {
          x = {
            es: impactoClimaticoEs,
            ca: value as unknown as string,
          };
        }
      }
      if (path === 'texto') {
        if (currentLang.value === 'Es') {
          x = {
            es: value as unknown as string,
            ca: textoCa,
          };
        } else {
          x = {
            es: textoEs,
            ca: value as unknown as string,
          };
        }
      }
      if (path === 'valor') {
        if (currentLang.value === 'Es') {
          x = {
            es: value as unknown as string,
            ca: valorCA,
          };
        } else {
          x = {
            es: valorEs,
            ca: value as unknown as string,
          };
        }
      }
      if (path === 'categoria') {
        if (currentLang.id === 'ca') {
          setimpactoClimaticoCa(x.ca);
          environmentNew.categoria.ca = x.ca;
        } else {
          setimpactoClimaticoEs(x.es);
          environmentNew.categoria.es = x.es;
        }
      }
      if (path === 'texto') {
        if (currentLang.id === 'ca') {
          setextoCa(x.ca);
          environmentNew.texto.ca = x.ca;
        } else {
          setextoEs(x.es);
          environmentNew.texto.es = x.es;
        }
      }
      if (path === 'valor') {
        if (currentLang.id === 'ca') {
          setvalorCA(x.ca);
          environmentNew.valor.ca = x.ca;
        } else {
          setvalorEs(x.es);
          environmentNew.valor.es = x.es;
        }
      }

      validateForm();
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (validateForm()) {
      LoggingService.log('handleSubmit :: ok', { environmentNew });
      submitForm();
    } else {
      LoggingService.log('handleSubmit :: errors', { environmentNew, errors });
      toast.error(I18n.t('Error: Some empty field'));
    }
  };

  useEffect(() => {
    if (news.length > 0) {
      setEnvironmentNews(news);
    } else {
      setEnvironmentNews([]);
    }
    setSubmitDisabled(!validateForm());
  }, [news, validateForm]);

  const deleteEnvironmentNew = async (eGreenNew: any): Promise<void> => {
    // eslint-disable-next-line no-alert
    const accept = window.confirm(I18n.t('Are you sure you want to delete the característica?'));

    if (!accept) {
      return;
    }

    try {
      await CommunitiesServiceIntance.deleteCaracteristicas(nif, eGreenNew.id);

      customToast.success(`${I18n.t('caracteristicas deleted successfully')}!`);

      if (afterSubmitAction) {
        afterSubmitAction();
      }

      setSubmitDisabled(false);
    } catch (error) {
      toast.error(`${I18n.t('Error deleting new')}.`);
    }
  };

  const headings: Array<TableHeading> = [
    { label: I18n.t('CategoriaHead'), key: 'categoria' },
    { label: I18n.t('TextHead'), key: 'texto' },
    { label: I18n.t('Valor'), key: 'valor' },
    {
      label: I18n.t('Actions'),
      type: 'actions',
      key: '',
      actions: [
        {
          label: I18n.t('Delete'),
          // eslint-disable-next-line react/jsx-no-undef
          icon: <TrashIcon height={25} width={25} fill={Colors.COLOR_ELECSUMGREEN} />,
          onActionClick: deleteEnvironmentNew,
        },
      ],
    },
  ];

  return (
    <SectionWrapper customClassname={styles.root}>
      <div className={styles.flex}>
        <DocumentsIcon height={20} width={20} fill={Colors.COLOR_BLACK} className={styles.DocumentsIcon} />

        <SectionSubTitle text={I18n.t('caracateristicas')} />
      </div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.fields_container}>
          <div className={styles.fields_group}>
            {formGroupsEnvironmentNewData.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                placeholder={formGroup.placeholder}
                value={formGroup.value}
                required={formGroup.required}
                onChange={handleChange}
              />
            ))}
          </div>
          <div className={styles.fields_group}>
            {formGroupsEnvironmentNewDataText.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                value={formGroup.value}
                required={formGroup.required}
                placeholder={formGroup.placeholder}
                onChange={handleChange}
              />
            ))}
          </div>
          <div className={styles.fields_group}>
            {formGroupsEnvironmentNewDescription.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                value={formGroup.value}
                required={formGroup.required}
                placeholder={formGroup.placeholder}
                onChange={handleChange}
              />
            ))}
          </div>
        </div>
        <div className={styles.actions}>
          <LoadingButton
            type="submit"
            text={I18n.t('Insert caracteristicas')}
            disabled={submitDisabled}
            loading={loading}
          />
        </div>
      </form>

      <div className={styles.tablenews}>
        {environmentNews.length > 0 ? (
          <DataTable
            headings={headings}
            rows={environmentNews}
            loading={loading}
            test={test}
            currentLang={currentLang.id}
          />
        ) : // <>
        //   {currentLang.id === 'es'
        //     ? environmentNews.map((x) => x.categoria.es)
        //     : environmentNews.map((x) => x.categoria.ca)}
        // </>
        null}
      </div>

      {/* <pre>{ JSON.stringify(environmentNew, null, 2) }</pre>
      <pre>{ JSON.stringify(environmentNews, null, 2) }</pre> */}
    </SectionWrapper>
  );
};

export default ComunidadesDetalleCaracteristicas;
