import React from 'react';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/ArrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import { I18n } from 'core';
import styles from './CupsListTablePaginator.module.scss';

interface CupsListTablePaginatorPropsI {
  current: number;
  max: number;
  handlePrev(): void;
  handleNext(): void;
}

const CupsListTablePaginator = (props: CupsListTablePaginatorPropsI): JSX.Element => {
  const { current, max, handleNext, handlePrev } = props;
  return (
    <div className={styles.container}>
      {current > 1 ? (
        <button className={styles.btn} type="button" onClick={() => handlePrev()}>
          <ArrowLeftIcon />
        </button>
      ) : null}
      <span className={styles.text}>{`${I18n.t('CUPSListPage.paginacion.page')} ${current} de ${
        max === 0 ? 1 : max
      }`}</span>
      {current < max ? (
        <button className={styles.btn} type="button" onClick={() => handleNext()}>
          <ArrowRightIcon />
        </button>
      ) : null}
    </div>
  );
};

export default CupsListTablePaginator;
