/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import cx from 'classnames';
import { VictoryStack, VictoryBar } from 'victory';
import { Colors } from 'styles';
import { I18n } from 'core';
import noClientImg from 'assets/images/saving_no_client.png';
import { UtilsService } from 'services';
import configuration from 'config';
import DateSwitcher from 'components/DateSwitcher/DateSwitcher';
import { getMonthFromStringDate } from 'services/local/getMonthFromStringDate';
import styles from './SavingWidget.module.scss';
import Card from '../../Card/Card';

interface ListItemProps {
  color?: string;
  name?: string;
  data: number;
  unit?: string;
  unitGrey?: boolean;
}

const ListItem = (props: ListItemProps): JSX.Element => {
  const { color, name, data, unit, unitGrey } = props;
  return (
    <div className={styles.list_item}>
      <div className={styles.type_container}>
        <div className={cx(styles.dot, color)} />
        <p className={styles.text}>{name}</p>
      </div>
      <div className={cx(styles.value_container, { [styles.grey]: unitGrey })}>
        <p className={styles.data}>{UtilsService.formatTwoDecimal(data)}</p>
        <p className={styles.unit}>{unit}</p>
      </div>
    </div>
  );
};

interface SavingDataPropsI {
  isClient: boolean;
  comercializado: boolean;
  date: string;
  savingData: number;
  costWithoutElecsumData: number;
  shrinkingData: number;
  selfConsumptionData: number;
  costWithElecsumData: number;
  acumulatedSaving: number;
  startDate: string;
  finalDate: string;
  onDateChange: (newDate: Date, fechaMesAñoArrayPosition: number) => void;
  maxArrayPosition: number;
  fechaMesAñoArrayPositionDad: number;
  promotor?: boolean;
}

const SavingWidget = (props: SavingDataPropsI): JSX.Element => {
  const {
    date,
    savingData,
    costWithoutElecsumData,
    shrinkingData,
    selfConsumptionData,
    costWithElecsumData,
    onDateChange,
    maxArrayPosition,
    fechaMesAñoArrayPositionDad,
    promotor,
  } = props;

  if (
    savingData === 0 &&
    costWithoutElecsumData === 0 &&
    shrinkingData === 0 &&
    selfConsumptionData === 0 &&
    costWithElecsumData === 0
  ) {
    return (
      <>
        <Card
          fullHeight
          title={I18n.t('saving')}
          subtitle={getMonthFromStringDate(date, false)}
          switcher={
            !promotor && (
              <DateSwitcher
                initialDate={new Date(date)}
                maxArrayPosition={maxArrayPosition}
                onDateChange={onDateChange}
                fechaMesAñoArrayPositionDad={fechaMesAñoArrayPositionDad}
              />
            )
          }
          // TODO: Migrate
          body={[
            <div key={0} className={styles.noclient_container}>
              <img src={noClientImg} alt="" />
              {/* <p className={styles.quote}>{I18n.t('thisMonthSavingWithElecsum')}</p>
            <p className={styles.value}>{`${UtilsService.formatTwoDecimal(savingData)} €`}</p> */}
              <p className={styles.quote}>{I18n.t('getPotencialSaving').toUpperCase()}</p>
              <a className={styles.link} href={configuration.contactUs}>
                {I18n.t('contactUs')}
              </a>
            </div>,
          ]}
        />
      </>
    );
  }

  return (
    <>
      <Card
        title={[I18n.t('saving')]}
        subtitle={getMonthFromStringDate(date, true).toUpperCase()}
        rightCorner={[`${UtilsService.formatTwoDecimal(savingData)} €`]}
        switcher={
          !promotor && (
            <DateSwitcher
              initialDate={new Date(date)}
              maxArrayPosition={maxArrayPosition}
              onDateChange={onDateChange}
              fechaMesAñoArrayPositionDad={fechaMesAñoArrayPositionDad}
            />
          )
        }
        body={[
          <div key={1} className={styles.container}>
            <VictoryStack
              colorScale={[Colors.COLOR_GRAY_100, Colors.COLOR_ELECSUMGREEN, Colors.COLOR_BLUE_200]}
              height={70}
              range={{ x: [0, 450], y: [30, 0] }}
              horizontal
            >
              <VictoryBar
                data={[{ y: costWithElecsumData }]}
                barWidth={50}
                cornerRadius={{
                  bottom: () => {
                    if (costWithElecsumData === 0 && shrinkingData === 0) {
                      return 5;
                    }
                    return 0;
                  },
                  top: () => {
                    if (selfConsumptionData === 0 && shrinkingData) {
                      return 5;
                    }
                    return 0;
                  },
                }}
              />
              <VictoryBar
                horizontal
                data={[{ y: selfConsumptionData * -1 }]}
                barWidth={50}
                cornerRadius={{
                  top: shrinkingData === 0 ? 5 : 0,
                  bottom: costWithElecsumData === 0 ? 5 : 0,
                }}
              />
              <VictoryBar
                horizontal
                data={[{ y: shrinkingData * -1 }]}
                barWidth={50}
                cornerRadius={{
                  bottom: () => {
                    if (selfConsumptionData === 0 && costWithElecsumData === 0) {
                      return 5;
                    }
                    return 0;
                  },
                  top: shrinkingData === 0 ? 0 : 5,
                }}
              />
            </VictoryStack>
            <>
              <ListItem name={I18n.t('costWithoutElecsum')} data={costWithoutElecsumData} unit="€" unitGrey />
              <ListItem name={I18n.t('shrinking')} color="bck-blue" data={shrinkingData} unit="€" />
              <ListItem name={I18n.t('selfConsumptionFen')} color="bck-green" data={selfConsumptionData} unit="€" />
              <div className={styles.divider} />
              <ListItem
                name={I18n.t('costWithElecsum')}
                color="bck-grey"
                data={costWithElecsumData}
                unit="€"
                unitGrey
              />
            </>
          </div>,
        ]}
      />
    </>
  );
};

export default React.memo(SavingWidget);
