import { toast } from 'react-toastify';
import { ReactText } from 'react';

import styles from './CustomToast.module.scss';

const customToast = {
  success(msg: string, options = {}): ReactText {
    return toast.success(msg, {
      ...options,
      className: styles['toast-success-container'],
      progressClassName: styles['toast-succes-progress'],
    });
  },
};

export default customToast;
