import React from 'react';
import { I18n } from 'core';
import { VictoryStack, VictoryBar } from 'victory';
import { Colors } from 'styles';
import { UtilsService } from 'services';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import Card from '../../Card/Card';
import styles from './AmortizationWidget.module.scss';

interface AmortizationDataI {
  totalData: number;
  amortizedData: number;
  percentAmortized: number;
  duration: number;
}

const AmortizaTionWidget = (props: AmortizationDataI): JSX.Element => {
  const { totalData, amortizedData, percentAmortized, duration } = props;

  return (
    <Card
      title={I18n.t('amortization')}
      rightCorner={`${percentAmortized} %`}
      body={[
        <div key="1" className={styles.root}>
          <VictoryStack
            colorScale={[Colors.COLOR_ELECSUMGREEN, Colors.COLOR_GRAY_100]}
            height={70}
            range={{ x: [0, 450], y: [30, 0] }}
          >
            <VictoryBar
              horizontal
              data={[{ y: amortizedData }]}
              barWidth={50}
              cornerRadius={{ bottom: 5, top: totalData > 0 ? 0 : 5 }}
            />
            <VictoryBar
              horizontal
              data={[
                {
                  y: totalData - amortizedData > 0 ? totalData - amortizedData : 1,
                },
              ]}
              barWidth={50}
              cornerRadius={{ top: 5 }}
            />
          </VictoryStack>
          <div className={styles.data_text_container}>
            <p className={styles.value}>{`${UtilsService.formatTwoDecimal(amortizedData)} €`}</p>
            <p className={styles.total}>{` / ${UtilsService.formatTwoDecimal(totalData)} €`}</p>
          </div>
        </div>,
      ]}
      footer={
        percentAmortized === 25 || percentAmortized === 50 || percentAmortized === 75 ? (
          <div className={styles.flag}>
            <FontAwesomeIcon icon={faThumbsUp} className={cx('green', styles.icon)} />
            <div className={styles.text_container}>
              <p className={styles.text}>
                {`${I18n.t('in')} ${duration} ${I18n.t('monthYouAmortized')} ${percentAmortized}% ${I18n.t(
                  'ofTheInstalation',
                )}`}
              </p>
            </div>
          </div>
        ) : undefined
      }
    />
  );
};

export default React.memo(AmortizaTionWidget);
