import { Serializable } from 'types/interfaces';

export interface UserInfoRoleAPI {
  rolId: number;
  rolNombre: string;
}

export default class UserInfoRole implements Serializable<UserInfoRole> {
  rolId: number;

  rolNombre: string;

  deserialize(input: UserInfoRoleAPI): UserInfoRole {
    if (!input) {
      return this;
    }
    this.rolId = input.rolId;
    this.rolNombre = input.rolNombre;

    return this;
  }

  deserializeArray(inputArray: Array<UserInfoRoleAPI>): Array<UserInfoRole> {
    const Users: Array<UserInfoRole> = inputArray.map((input) => new UserInfoRole().deserialize(input));

    return Users;
  }
}
