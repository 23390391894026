/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { NavItemI, I18n } from 'core';
import { ReactComponent as CIMIcon } from 'assets/icons/CIM.svg';
import { useParams } from 'react-router-dom';
import { Colors } from 'styles';
import { useSelector } from 'react-redux';
import { GlobalStoreState } from 'types/state';
import { Client, User } from 'types/models';
import { ReactComponent as ChartsIcon } from 'assets/icons/Charts.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/Home.svg';
import { ReactComponent as InvoicesIcon } from 'assets/icons/Invoices.svg';
import WidgetPermission from 'types/enums/WidgetPermission';

const ICON_SIZE = 20;
const iconColor = Colors.COLOR_GRAY_100;
const activeIconColor = Colors.COLOR_ELECSUMGREEN;
const createNavigationLinks = (cupsSelected: Client | null, user: User | null, permisos: string[]): Array<NavItemI> => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { cupsID } = useParams<{ cupsID: string }>();

  // Verificar si el usuario es administrador
  const isAdmin = user?.tipoDeUsuario === 'admin';

  // Verificar si el usuario tiene permisos para ver CIM
  const hasPermissionForCIM = permisos.includes(WidgetPermission.ACCESO_CIM_AUTORIZADOS);

  // Verificar si el CUPS está comercializado
  const isComercializado = cupsSelected?.comercializado;

  // Lógica para mostrar el enlace del CIM
  const shouldShowCIMLink = isAdmin || (isComercializado && hasPermissionForCIM);

  const links: Array<NavItemI> = [
    { name: I18n.t('Sidenav.Tecnic.CUPS_Detail'), url: `/tecnic/cups/${cupsID}/detail` },
    {
      name: I18n.t('Sidenav.Tecnic.CUPS_Dashboard'),

      url: `/tecnic/cups/${cupsID}/dashboard`,
      icon: <HomeIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
      activeIcon: <HomeIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
    },
    {
      name: I18n.t('Sidenav.Client.Charts'),
      url: `/tecnic/cups/${cupsID}/graficos`,
      icon: <ChartsIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
      activeIcon: <ChartsIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
    },
    ...(shouldShowCIMLink // Aplicando la condición de la tabla
      ? [
          {
            name: I18n.t('Sidenav.CIM.cim'),
            url: `/tecnic/cups/${cupsID}/cim`,

            icon: <CIMIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
            activeIcon: <CIMIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
            children: [
              {
                name: I18n.t('Sidenav.CIM.dashboard'),
                url: `/tecnic/cups/${cupsID}/cim/dashboard`,
              },
              {
                name: I18n.t('Sidenav.CIM.registers'),
                url: `/tecnic/cups/${cupsID}/cim/measurement-registers`,
              },
              ...(cupsSelected?.tarifaIndexada === 'no'
                ? [
                    {
                      name: I18n.t('Sidenav.CIM.simulation'),
                      url: `/tecnic/cups/${cupsID}/cim/invoice-simulation`,
                    },
                  ]
                : []),
              ...(cupsSelected?.comercializado === true
                ? [
                    {
                      name: I18n.t('Sidenav.CIM.devices'),
                      url: `/tecnic/cups/${cupsID}/cim/measurement-devices`,
                    },
                  ]
                : []),
              ...(cupsSelected?.modem
                ? [
                    {
                      name: I18n.t('Sidenav.CIM.optimization'),
                      url: `/tecnic/cups/${cupsID}/cim/power-optimization`,
                    },
                  ]
                : []),
              {
                name: I18n.t('Sidenav.CIM.closures'),
                url: `/tecnic/cups/${cupsID}/cim/closures`,
              },
              {
                name: I18n.t('Sidenav.CIM.export'),
                url: `/tecnic/cups/${cupsID}/cim/data-export`,
              },
            ],
          },
        ]
      : []),
  ];
  return links;
};

export default createNavigationLinks;
