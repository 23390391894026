import { Field, Form, Formik } from 'formik';
import React from 'react';
import { LoadingButton } from 'components';
import styles from './SolicitudCupsTerceroPage.module.scss';

interface SolicitudFormProps {
  solicitudEnviada: boolean;
  handleSubmit: (values: any, formikHelpers: any) => void;
}

const SolicitudForm = ({ solicitudEnviada, handleSubmit }: SolicitudFormProps) => (
  <Formik initialValues={{ cups: '' }} onSubmit={handleSubmit}>
    {({ values: { cups } }) => (
      <Form className={styles.menuForm}>
        {' '}
        <div className={styles.inputWrapper}>
          <label htmlFor="cups" className={styles.label}>
            CUPS
          </label>
          <Field type="text" name="cups" className={styles.inputs} />
        </div>
        <div className={styles.btnFinal}>
          <LoadingButton type="submit" text="Enviar Solicitud" disabled={cups === ''} loading={solicitudEnviada} />
        </div>
      </Form>
    )}
  </Formik>
);

export default SolicitudForm;
