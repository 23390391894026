import React, { useEffect } from 'react';
import { I18n } from 'core';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { SectionTitle, Spinner } from 'components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCommunity, fetchCommunitySuccess } from 'redux/actions/communities-actions';
import CommunitiesServiceIntance, { CommunitiesService } from 'services/remote/communities-service';
import { GlobalStoreState } from 'types/state';
import { toast } from 'react-toastify';
import { ProcessStatus } from 'types/enums';
import styles from './comunidades-detalle-promotor-page.module.scss';

export const ComunidadesDetallePromotorPage = (): JSX.Element => {
  // Props
  const { communityID } = useParams<{ communityID: string }>();
  const dispatch = useDispatch();
  const communitiesState = useSelector((state: GlobalStoreState) => state.communities);

  const fetchAndUpdateCommunity = () => {
    dispatch(fetchCommunity());
    new CommunitiesService().getCommunity(communityID).then((response) => {
      dispatch(fetchCommunitySuccess(response));
    });
  };
  const handleRowClick = async (index: string) => {
    try {
      // eslint-disable-next-line no-console
      await CommunitiesServiceIntance.putMarcarPromotorPrincipal(communityID, index);
      fetchAndUpdateCommunity();
    } catch (error) {
      toast.error(I18n.t('Error updating report!'));
    }
  };

  useEffect(() => {
    if (!communityID) return;
    fetchAndUpdateCommunity();
  }, []);
  if (communitiesState.selectedCommunityStatus === ProcessStatus.LOADING) return <Spinner icon />;
  return (
    <SectionWrapper customClassname={styles.table_wrapper}>
      <SectionTitle text={I18n.t('UsuarioDetailPage.Promotor.titleComunidadPromotor')} />
      <table>
        <thead>
          <tr>
            <th>{I18n.t('UsuarioDetailPage.Promotor.headers.Promotors')}</th>
            <th>{I18n.t('UsuarioDetailPage.Promotor.headers.principal')}</th>
          </tr>
        </thead>
        <tbody>
          {communitiesState && communitiesState.selectedCommunity ? (
            communitiesState.selectedCommunity.promotores.map((promotor) => (
              <tr key={promotor.id}>
                <td>{promotor.nombre}</td>
                <td className={styles.checkboxTable}>
                  <input
                    id={`seleccionado-${promotor.id}`}
                    type="checkbox"
                    checked={promotor.principal === 'si'}
                    onChange={() => handleRowClick(promotor.id)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr className={styles.Sinboder}>
              <td className={styles.nodata}>{I18n.t('UsuarioDetailPage.Promotor.noDataComunidadPromotor')}</td>
            </tr>
          )}
        </tbody>
      </table>
    </SectionWrapper>
  );
};
