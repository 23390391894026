import { COMMUNITIES_ACTIONS } from 'redux/actions/types';
import { ProcessStatus } from 'types/enums';
import { CommunitiesState } from 'types/state';

const initialState: CommunitiesState = {
  selectedCommunity: null,
  selectedCommunityStatus: ProcessStatus.STANDBY,
};

export const CommunitiesReducer = (state = initialState, action: any): CommunitiesState => {
  switch (action.type) {
    case COMMUNITIES_ACTIONS.FETCH_COMMUNITY:
      return {
        ...state,
        selectedCommunityStatus: ProcessStatus.LOADING,
        selectedCommunity: null,
      };
    case COMMUNITIES_ACTIONS.FETCH_COMMUNITY_OK: {
      const { payload } = action;
      return {
        ...state,
        selectedCommunityStatus: ProcessStatus.SUCCESS,
        selectedCommunity: payload,
      };
    }

    case COMMUNITIES_ACTIONS.FETCH_COMMUNITY_ERROR: {
      return {
        ...state,
        selectedCommunity: null,
        selectedCommunityStatus: ProcessStatus.ERROR,
      };
    }

    case COMMUNITIES_ACTIONS.RESET_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
