import { CUPS_ACTIONS } from 'redux/actions/types';
import { ProcessStatus } from 'types/enums';
import { CupsState } from 'types/state';

const initialState: CupsState = {
  selectedCups: null,
  selectedCupsStatus: ProcessStatus.STANDBY,
  selectedCupsInfo: null,
};

function CUPSReducer(state = initialState, action: any): CupsState {
  switch (action.type) {
    case CUPS_ACTIONS.FETCH_CUPS:
      return {
        ...state,
        selectedCupsStatus: ProcessStatus.LOADING,
        selectedCups: null,
        selectedCupsInfo: null,
      };
    case CUPS_ACTIONS.FETCH_CUPS_OK: {
      const { payload } = action;
      return {
        ...state,
        selectedCupsStatus: ProcessStatus.SUCCESS,
        selectedCups: payload,
        selectedCupsInfo: payload,
      };
    }
    case CUPS_ACTIONS.UPDATE_CUPS: {
      const { payload } = action;
      return {
        ...state,
        selectedCups: payload,
        selectedCupsInfo: payload,
      };
    }
    case CUPS_ACTIONS.FETCH_CUPS_LIST_OK: {
      const { payload } = action;
      return {
        ...state,
        selectedCupsInfo: payload,
        selectedCupsStatus: ProcessStatus.SUCCESS,
      };
    }
    case CUPS_ACTIONS.FETCH_CUPS_ERROR: {
      return {
        ...state,
        selectedCups: null,
        selectedCupsInfo: null,
        selectedCupsStatus: ProcessStatus.ERROR,
      };
    }

    case CUPS_ACTIONS.RESET_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export default CUPSReducer;
