import { InstallationAPI } from '../types/models/installation';

const INSTALLATION_MOCK: InstallationAPI = {
  id: '20',
  nombre: 'ELECTRA CALDENSE ENERGIA, S.A.',
  coste: 5232.4,
  domicilio: 'Es la dirección MOCK',
  numeroDePaneles: 101,
  potencia: 28.785,
  numeroDeInversores: 1,
  potenciaInversor: 25,
  potenciaTotal: 25,
  orientacion: '2 SE',
  inclinacion: '15',
  ratioEnergetico: 1410,
  garantia: 'si',
  garantiaAños: 10,
  contratoMantenimiento: 'si',
  tipoDeInstalacionLegalizacion: 'Sense exc.(RD 244/19)',
  fechaRecepcionObra: '2019-04-17',
  fechaInicioSeguimientoProduccion: '2019-05-01',
  numeroDeSerieContadorDeConsumo: '3456789765435678987654',
  panelFabricante: 'Hanwha',
  panelModelo: 'QCELLS - Q.Plus-G4',
  panelInversor: 285,
  panelDegradacion0Años: 0,
  panelDegradacion1Años: 1,
  panelDegradacion2Años: 2,
  panelDegradacion10Años: 10,
  panelDegradacion25Años: 25,
  inversorFabricante: 'Hanwha',
  inversorModelo: 'QCELLS - Q.Plus-G4',
  inversorInversor: 285,
  inversorDegradacion0Años: 0,
  latitud: 41.377903,
  longitud: 2.18842,
  seguimientoRadioInstalaciones: 4,
  seguimientoDesviacion: 0.4,
  notas: 'notas varias',
  poblacion: 'CALDES DE MONTBUI',
  provincia: 'BARCELONA',
  idElecsum: 38,
  cau: 'cau',
  cil: 'cil',
  nombreInstalacion: 'prueba nombreInstalacion',
  nombreTitular: 'prueba nombreTitular',
  apellidosTitular: 'prueba apellidosTitular',
  domicilioTitular: 'prueba domicilioTitular',
  poblacionTitular: 'prueba poblacionTitular',
  provinciaTitular: 'prueba provinciaTitular',
  codigoPostarTitular: 'prueba codigoPostarTitular',
};

export default INSTALLATION_MOCK;
