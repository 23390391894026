import { Serializable } from 'types/interfaces';

export interface CommunityEnrolledAPII {
  id: number;
  nombre?: string;
  community?: string; // TranslatableJSON<string>
}

export class CommunityEnrolledModel implements Serializable<CommunityEnrolledModel> {
  id: number;

  nombre: string;

  community: string; // TranslatableElement<string>

  deserialize(input: CommunityEnrolledAPII): CommunityEnrolledModel {
    this.id = input.id;
    this.nombre = input.nombre || '';
    this.community = input.community || '';
    return this;
  }

  deserializeArray(inputArray: Array<CommunityEnrolledAPII>): Array<CommunityEnrolledModel> {
    return inputArray.map((input) => new CommunityEnrolledModel().deserialize(input));
  }
}
