import { AxiosError, AxiosResponse } from 'axios';
import configuration from 'config';
import { COMMUNITIES_ENROLLED_MOCK } from 'mocks/communities-enrolled-mock';
import { TranslatableJSON } from 'types/interfaces/polyglot';
import { CommunityAPII, CommunityModel } from 'types/models/community';
import { CommunityEnrolledModel } from 'types/models/communityEnrolled';
import ComunityCaracteristicasNew from 'types/models/comunityCaracteristicas';
import apiService from './ApiService';

export interface ComunityUpdate {
  nombre: string;
  descripcion: TranslatableJSON<string>;
  tipologia: TranslatableJSON<string>;
  promocion: TranslatableJSON<string>;
  impactoClimatico: TranslatableJSON<string>;
  domicilio?: string;
  codigoPostal?: string;
  poblacion?: string;
  provincia?: string;
  generacionInstalable: string;
  potenciaInstalable: string;
  fechaFinInscripciones: string;
  estado: string;
  tipoDeComunidad: string;
  caracteristicas?: Array<{
    id: string;
    categoria: TranslatableJSON<string>;
    texto: TranslatableJSON<string>;
    valor: TranslatableJSON<string>;
  }>;
  radioAlcance: number;
  informeAutomatico?: string;
  informeInmediato?: string;
  curvaGeneracion?: string;
  titularPoliticaPrivacidad?: string;
  consumoAnualPromedio?: number;
  limitacionNumeroInscripciones?: number;
  enviarAutorizacionAPromotor?: string;
  pasosOnboarding?: number;
}
export class CommunitiesService {
  readonly http = apiService;

  private apiUrl = configuration.apiUrl;

  async getCommunities(textSearch: string = ''): Promise<CommunityModel[]> {
    const url = `${this.apiUrl}/ce/comunidades-energeticas/`;
    const params = new URLSearchParams();

    if (textSearch) {
      params.append('buscar', textSearch);
    }

    const response: AxiosResponse<{ datos: CommunityAPII[] }> = await this.http.get(url, { params });
    const result = new CommunityModel().deserializeArray(response.data.datos);
    return result;
  }

  async getCommunity(id: number | string): Promise<CommunityModel> {
    const url = `${this.apiUrl}/ce/comunidades-energeticas/${id}/`;
    const response: AxiosResponse<{ datos: CommunityAPII }> = await this.http.get(url);
    const result = new CommunityModel().deserialize(response.data.datos);
    return result;
  }

  async getCommunitiesEnrolled(): Promise<CommunityEnrolledModel[]> {
    // const url = `${this.apiUrl}/ce/comunidades-energeticas/`;
    // const response: AxiosResponse<{ datos: CommunityAPII[] }> = await this.http.get(url);
    const result = new CommunityEnrolledModel().deserializeArray(COMMUNITIES_ENROLLED_MOCK);
    return result;
  }

  async addGaleryImage(id: string, file: File, type: string, select: string): Promise<number> {
    const url = `${this.apiUrl}/ce/comunidades-energeticas/${id}/imagen/`;

    const imageData = new window.FormData();

    imageData.append('tipo', type);
    imageData.append('imagen', file);
    imageData.append('mostrar', select);

    const response: AxiosResponse<{ status: number }> = await this.http.post(url, imageData);

    return response.status;
  }

  async deleteImage(idus: string, idimg: string): Promise<unknown> {
    const url = `${this.apiUrl}/ce/comunidades-energeticas/${idus}/imagen/${idimg}/`;

    const response: AxiosResponse<number> = await this.http.delete(url);

    return response;
  }

  async addGaleryImageLogo(id: string, file: File, type: string): Promise<number> {
    const url = `${this.apiUrl}/ce/comunidades-energeticas/${id}/logo/`;
    const imageData = new window.FormData();

    imageData.append('tipo', type);
    imageData.append('logo', file);

    const response: AxiosResponse<{ status: number }> = await this.http.post(url, imageData);

    return response.status;
  }

  async deleteImageLogo(idus: string, idimg: string): Promise<unknown> {
    const url = `${this.apiUrl}/ce/comunidades-energeticas/${idus}/logo/${idimg}/`;
    const response: AxiosResponse<number> = await this.http.delete(url);

    return response;
  }

  async putCommunities(Comunity: CommunityAPII): Promise<string> {
    const url = `${this.apiUrl}/ce/comunidades-energeticas/${Comunity.id}/`;

    const comunityUpdate: ComunityUpdate = {
      nombre: Comunity.nombre || '',
      domicilio: Comunity.domicilio || '',
      codigoPostal: Comunity.codigoPostal || '',
      poblacion: Comunity.poblacion || '',
      provincia: Comunity.provincia || '',
      descripcion: (Comunity.descripcion as TranslatableJSON<string>) || '',
      tipologia: (Comunity.tipologia as TranslatableJSON<string>) || '',
      promocion: (Comunity.promocion as TranslatableJSON<string>) || '',
      impactoClimatico: (Comunity.impactoClimatico as TranslatableJSON<string>) || '',
      generacionInstalable: Comunity.generacionInstalable || '',
      potenciaInstalable: Comunity.potenciaInstalable || '',
      fechaFinInscripciones: Comunity.fechaFinInscripciones || '',
      estado: Comunity.estado || '',
      tipoDeComunidad: Comunity.tipoDeComunidad || '',
      radioAlcance: Comunity.radioAlcance || 0,
      informeAutomatico: Comunity.informeAutomatico || 'no',
      informeInmediato: Comunity.informeInmediato || 'no',
      curvaGeneracion: Comunity.curvaGeneracion || 'no',
      titularPoliticaPrivacidad: Comunity.titularPoliticaPrivacidad || '',
      consumoAnualPromedio: Comunity.consumoAnualPromedio || 0,
      limitacionNumeroInscripciones: Comunity.limitacionNumeroInscripciones || 0,

      enviarAutorizacionAPromotor: Comunity.enviarAutorizacionAPromotor || 'no',
      pasosOnboarding: Comunity.pasosOnboarding || 0,
    };

    const response: AxiosResponse<string> = await this.http.patch(url, comunityUpdate);

    return response.data;
  }

  async putCommunitiesExcedent(id: string, limiteExcedente: string): Promise<string> {
    const url = `${this.apiUrl}/ce/comunidades-energeticas/${id}/`;

    const comunityUpdate = {
      limiteExcedente,
    };

    const response: AxiosResponse<string> = await this.http.patch(url, comunityUpdate);

    return response.data;
  }

  async putMarcarPromotorPrincipal(idComunidad: string, idPromotor: string): Promise<string> {
    const url = `${this.apiUrl}/ce/comunidades-energeticas/${idComunidad}/marcar-promotor-principal/`;

    const paramasUpdate = {
      idPromotor,
    };

    const response: AxiosResponse<string> = await this.http.patch(url, paramasUpdate);

    return response.data;
  }

  async addCaracteristicas(nif: string, newToAdd: ComunityCaracteristicasNew): Promise<number> {
    const url = `${this.apiUrl}/ce/comunidades-energeticas/${nif}/caracteristica/`;
    const newToAPI = newToAdd.serialize();

    const response: AxiosResponse<{ status: number }> = await this.http.post(url, newToAPI);

    return response.status;
  }

  async deleteCaracteristicas(nif: string, id: string): Promise<unknown> {
    const url = `${this.apiUrl}/ce/comunidades-energeticas/${nif}/caracteristica/${id}/`;
    const response: AxiosResponse<number> = await this.http.delete(url);

    return response;
  }

  async patchCsvConfiguracionComunidades(id: string, file: File): Promise<any> {
    const url = `${this.apiUrl}/ce/comunidades-energeticas/${id}/curva-generacion`;
    const csvData = new window.FormData();
    csvData.append('curvaDeGeneracion', file);

    let responsePromise;
    try {
      const response: AxiosResponse = await this.http.patch(url, csvData);
      responsePromise = response.status;
    } catch (error: unknown) {
      if ((error as AxiosError)?.response?.data && (error as AxiosError).response?.status === 400) {
        responsePromise = {
          codigo: (error as AxiosError).response?.data[0].codigo,
          mensaje: (error as AxiosError).response?.data[0].mensaje,
        };
      }
    }
    return responsePromise;
  }

  async patchCsvCurvaDeConsumo(id: string, file: File): Promise<any> {
    const url = `${this.apiUrl}/ce/comunidades-energeticas/${id}/curva-consumo`;

    const csvData = new window.FormData();
    csvData.append('curvaDeConsumo', file);

    let responsePromise;
    try {
      const response: AxiosResponse = await this.http.patch(url, csvData);
      responsePromise = response.status;
    } catch (error: unknown) {
      if ((error as AxiosError)?.response?.data && (error as AxiosError).response?.status === 400) {
        responsePromise = {
          codigo: (error as AxiosError).response?.data[0].codigo,
          mensaje: (error as AxiosError).response?.data[0].mensaje,
        };
      }
    }
    return responsePromise;
  }

  // Devuelve un CSV (representado por Blob en JavaScript)
  public async descargarInformeComunidades(id: string): Promise<Blob> {
    const url = `${this.apiUrl}/ce/comunidades-energeticas/${id}/curva-generacion/`;
    const response: AxiosResponse<Blob> = await this.http.get(url);

    return response.data;
  }

  public async descargarInformeConsumoComunidades(id: string): Promise<Blob> {
    const url = `${this.apiUrl}/ce/comunidades-energeticas/${id}/curva-consumo/`;
    const response: AxiosResponse<Blob> = await this.http.get(url);

    return response.data;
  }
}

const CommunitiesServiceIntance = new CommunitiesService();
export default CommunitiesServiceIntance;
