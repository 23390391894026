import React, { useState, ChangeEvent, useEffect } from 'react';

import cx from 'classnames';
import styles from './ImageInput.module.scss';

export interface ImageInputInterface {
  id: string;
  label: string;
  note: string;
  fileName: string;
  icon: JSX.Element;
  action: CallableFunction;
  type?: string;
  className?: string;
}

const ImageInput = (props: ImageInputInterface): JSX.Element => {
  const { id, label, note, fileName, icon, action, type, className = '' } = props;

  const [filePlace, setFilePlace] = useState<string>('');
  const [fileNamePdf, setfileNamePdf] = useState<string>(fileName);
  const [itsImage, setitsImage] = useState<boolean>(false);
  function isImage(url: any) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;

    const file1: File = (target.files as FileList)[0];
    if (e && file1) {
      action(file1, type);

      setFilePlace(URL.createObjectURL(file1));

      setfileNamePdf(file1.name);
      if (isImage(file1.name) === true) {
        setitsImage(true);
      } else {
        setitsImage(false);
      }
    }
  };

  useEffect(() => {
    const quitImagePreview = (): void => {
      if (!fileNamePdf) {
        setFilePlace('');
      }
    };

    quitImagePreview();
  }, [fileNamePdf]);

  return (
    <div className={styles['image-file']}>
      <input
        type="file"
        accept={type === 'all' ? 'image/*,application/pdf' : 'image/*'}
        className={styles['image-file-input']}
        id={id}
        onChange={handleChange}
      />
      <label className={styles['image-file-label']} htmlFor={id}>
        <div className={styles['image-placeholder']}>
          <div className={cx(styles['icon-wrapper'], className)}>
            <div className={styles['icon-wrapp']}>{icon}</div>
            {filePlace &&
              (itsImage === true ? (
                <div className={styles['img-preview']}>
                  <img id="imagenSelecionada" src={filePlace} alt="imageToUpload" />
                </div>
              ) : null)}
          </div>
          <div className={styles['text-placeholder']}>
            <div className={styles['placeholder-label']}>{label}</div>
            <div className={styles['placeholder-note']}>{note}</div>
            {fileNamePdf && <div className={styles['placeholder-picked']}>{fileNamePdf}</div>}
          </div>
        </div>
      </label>
    </div>
  );
};

export default ImageInput;
