import React, { ChangeEvent } from 'react';
import cx from 'classnames';
import styles from './TextAreaInput.module.scss';

export interface TextAreaInterface {
  path: string;
  value: string;
  id: string;
  placeholder?: string;
  name: string;
  rows: number;
  cols: number;
  onChange?: CallableFunction;
  maxLenght?: number;
  className?: string;
}
const TextAreaInput = (props: TextAreaInterface): JSX.Element => {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const { path, onChange } = props;

    if (onChange) {
      onChange(path, e.target.value);
    }
  };
  const { value, id, placeholder, name, rows, cols, maxLenght, className = '' } = props;

  return (
    <textarea
      id={id}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      className={cx(styles.root, className)}
      rows={rows}
      cols={cols}
      maxLength={maxLenght}
    />
  );
};
export default TextAreaInput;
