import { FormatNumberService } from 'services';

interface ConvertedWatt {
  num: string;
  unit: string;
  unitHour: string;
}

class WattsConverterService {
  private numberToConvert: number;

  private totalWatts: number;

  private magnitude: string;

  convertWatts(numberToConvert: number): ConvertedWatt {
    this.numberToConvert = numberToConvert;
    const format = (number: number): string => number.toFixed(2).replace('.', ',');
    if (this.numberToConvert > -999 && this.numberToConvert < 999) {
      return {
        num: format(this.numberToConvert),
        unit: 'W',
        unitHour: 'Wh',
      };
    }
    if (
      (this.numberToConvert <= -1000 && this.numberToConvert > -999999) ||
      (this.numberToConvert >= 1000 && this.numberToConvert < 999999)
    ) {
      return {
        num: format(this.numberToConvert / 1000),
        unit: 'kW',
        unitHour: 'kWh',
      };
    }
    if (this.numberToConvert <= -1000000 || this.numberToConvert >= 1000000) {
      return {
        num: format(this.numberToConvert / 1000000),
        unit: 'MW',
        unitHour: 'MWh',
      };
    }
    return {
      num: '0',
      unit: 'error',
      unitHour: 'error',
    };
  }

  convertWattsToKwatts(numberToConvert: number): number {
    this.numberToConvert = numberToConvert;

    const number = this.numberToConvert / 1000;

    return Math.round((number + Number.EPSILON) * 100) / 100;
  }

  convertKwattsToWatts(numberToConvert: number): number {
    this.numberToConvert = numberToConvert;

    const number = this.numberToConvert * 1000;

    return number;
  }

  convertWattsToKwattsWithUnits(numberToConvert: number): ConvertedWatt {
    let formatedNum = '';
    this.numberToConvert = numberToConvert;
    const convertedNumber = this.numberToConvert / 1000;

    if (convertedNumber < 1) {
      formatedNum = convertedNumber
        .toFixed(1)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    } else {
      formatedNum = convertedNumber
        .toFixed(0)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }

    return {
      num: formatedNum,
      unit: 'kW',
      unitHour: 'kWh',
    };
  }

  convertWattsToKwattsWithUnitsSmall(numberToConvert: number): ConvertedWatt {
    let formatedNum = '';
    this.numberToConvert = numberToConvert;
    const convertedNumber = this.numberToConvert;

    if (convertedNumber < 1) {
      formatedNum = convertedNumber
        .toFixed(1)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    } else {
      formatedNum = convertedNumber
        .toFixed(0)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }

    return {
      num: formatedNum,
      unit: 'kW',
      unitHour: 'kWh',
    };
  }

  getOptimalMagnitude(totalWatts: number): string {
    // to prevent Y numbers being bigger than 4 digits
    this.totalWatts = totalWatts;
    if (this.totalWatts >= 30000000) {
      return 'MWh';
    }
    if (this.totalWatts < 30000000 && this.totalWatts >= 30000) {
      return 'kWh';
    }
    return 'Wh';
  }

  toSelectedMagnitude(watts: number, magnitude: string): number {
    this.magnitude = magnitude;
    if (this.magnitude === 'Wh') {
      return watts;
    }
    if (this.magnitude === 'W') {
      return watts;
    }

    if (this.magnitude === 'kWh') {
      return watts / 1000;
    }

    if (this.magnitude === 'kW') {
      return watts / 1000;
    }

    if (this.magnitude === 'MWh') {
      return watts / 1000000;
    }

    if (this.magnitude === 'MW') {
      return watts / 1000000;
    }

    return 0;
  }

  toSelectedMagnitudeString(watts: number, magnitude: string): string {
    this.magnitude = magnitude;
    if (this.magnitude === 'Wh') {
      return FormatNumberService.formatTwoDecimal(watts);
    }
    if (this.magnitude === 'W') {
      return FormatNumberService.formatTwoDecimal(watts);
    }
    if (this.magnitude === 'kWh') {
      return FormatNumberService.formatTwoDecimal(watts / 1000);
    }
    if (this.magnitude === 'kW') {
      return FormatNumberService.formatTwoDecimal(watts / 1000);
    }
    if (this.magnitude === 'MWh') {
      return FormatNumberService.formatTwoDecimal(watts / 1000000);
    }
    if (this.magnitude === 'MW') {
      return FormatNumberService.formatTwoDecimal(watts / 1000000);
    }
    return FormatNumberService.formatTwoDecimal(watts);
  }

  normalize(watts: number): string {
    return watts.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
}
const wattsConverterService: WattsConverterService = new WattsConverterService();

export default wattsConverterService;
