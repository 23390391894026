/* eslint-disable @typescript-eslint/no-unused-vars */
import { Card } from 'components';
import React from 'react';
import { Colors } from 'styles';
import { I18n } from 'core';
import { ReactComponent as EnergiaBateriaIcon } from 'assets/icons/EnergiaBateria.svg';
import { ReactComponent as EnergiaEolicaIcon } from 'assets/icons/EnergiaEolica.svg';
import { ReactComponent as EnergiaMotorIcon } from 'assets/icons/EnergiaMotor.svg';
import { ReactComponent as EnergiaSolarIcon } from 'assets/icons/EnergiaSolar.svg';
import { FormatNumberService } from 'services';
import styles from './ContractSummaryWidget.module.scss';

interface ContractSummaryWidgetPropsI {
  codigoCliente?: string;
  tarifa?: string;
  tarifaComercializadora?: string;
  porcentajeElectricidadBonificacion?: number;
  porcentajeExencionImpuesto?: number;
  p1: number;
  p2: number;
  p3: number;
  p4: number;
  p5: number;
  p6: number;
  fotovoltaica?: boolean;
  eolica?: boolean;
  motor?: boolean;
  bateria?: boolean;
  instalaciones?: Array<{ tecnologia: string; potencia: number }>;
  comercializado: boolean;
}

interface LiniaPotenciaPropsI {
  label: string;
  value: number;
  color: string;
}

const LiniaPotencia = (props: LiniaPotenciaPropsI): JSX.Element => {
  const { label, value, color } = props;
  return (
    <div className={styles.linia_potencia_root}>
      <div className={styles.dot} style={{ backgroundColor: color }} />
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{`${value / 1000} kW`}</div>
    </div>
  );
};

const getInstalationRow = ({ tecnologia, potencia }: { tecnologia: string; potencia: number }) => {
  if (tecnologia === 'fotovoltaica') {
    return (
      <div key={tecnologia} className={styles.installation_wrapper}>
        <div className={styles.left}>
          <EnergiaSolarIcon height={40} width={40} fill={Colors.COLOR_GENERACIO} />
          <span>{I18n.t('ContractResumCard.solar')}</span>
        </div>
        <div className={styles.right}>{`${FormatNumberService.formatTwoDecimalWithThousandSeparator(
          potencia / 1000,
        )} kWn`}</div>
      </div>
    );
  }
  if (tecnologia === 'eolica') {
    return (
      <div key={tecnologia} className={styles.installation_wrapper}>
        <div className={styles.left}>
          <EnergiaEolicaIcon height={40} width={40} fill={Colors.COLOR_EXCEDENTS} />
          <span>{I18n.t('ContractResumCard.eolica')}</span>
        </div>
        <div className={styles.right}>{`${FormatNumberService.formatTwoDecimalWithThousandSeparator(
          potencia / 1000,
        )} kWn`}</div>
      </div>
    );
  }
  if (tecnologia === 'motor') {
    return (
      <div key={tecnologia} className={styles.installation_wrapper}>
        <div className={styles.left}>
          <EnergiaMotorIcon height={40} width={40} fill={Colors.COLOR_GRAY_500} />
          <span>{I18n.t('ContractResumCard.motor')}</span>
        </div>
        <div className={styles.right}>{`${FormatNumberService.formatTwoDecimalWithThousandSeparator(
          potencia / 1000,
        )} kWn`}</div>
      </div>
    );
  }
  if (tecnologia === 'bateria') {
    return (
      <div key={tecnologia} className={styles.installation_wrapper}>
        <div className={styles.left}>
          <EnergiaBateriaIcon height={40} width={40} fill={Colors.COLOR_GRAY_500} />
          <span>{I18n.t('ContractResumCard.bateria')}</span>
        </div>
        <div className={styles.right}>{`${FormatNumberService.formatTwoDecimalWithThousandSeparator(
          potencia / 1000,
        )} kWn`}</div>
      </div>
    );
  }
  return null;
};

const Concepto = ({ etiqueta, valor }: { etiqueta: string; valor: string }): JSX.Element => (
  <div className={styles.concepto}>
    <span className={styles.conceptoInfo}>{etiqueta}</span>
    <span className={styles.conceptoValor}>{valor}</span>
  </div>
);

const ContractSummaryWidget = (props: ContractSummaryWidgetPropsI): JSX.Element => {
  const {
    codigoCliente,
    tarifa,
    tarifaComercializadora,
    porcentajeElectricidadBonificacion,
    porcentajeExencionImpuesto,
    p1,
    p2,
    p3,
    p4,
    p5,
    p6,
    fotovoltaica,
    eolica,
    motor,
    bateria,
    instalaciones,
    comercializado,
  } = props;

  return (
    <Card
      title={I18n.t('ContractResumCard.title')}
      body={[
        <div className={styles.root}>
          <>
            <div className={styles.info_container}>
              {comercializado && comercializado === true ? (
                <Concepto etiqueta={I18n.t('ContractResumCard.cliente')} valor={codigoCliente || '-'} />
              ) : null}
              <Concepto
                etiqueta={I18n.t('ContractResumCard.tarifa')}
                valor={` ${(tarifa && FormatNumberService.formatTwoDecimalWithThousandSeparator(tarifa)) || ''}`}
              />
              {/*    {porcentajeElectricidadBonificacion && porcentajeElectricidadBonificacion > 0 ? (
              <Concepto
                etiqueta={I18n.t('ContractResumCard.porcentajeElectricidadBonificacion')}
                valor={`${porcentajeElectricidadBonificacion}%`}
              />
            ) : null} */}
              {porcentajeExencionImpuesto && porcentajeExencionImpuesto > 0 ? (
                <Concepto
                  etiqueta={I18n.t('ContractResumCard.porcentajeExcencionImpuesto')}
                  valor={`${FormatNumberService.formatTwoDecimalWithThousandSeparator(porcentajeExencionImpuesto)}%`}
                />
              ) : null}
            </div>

            <div className={styles.potencies_container}>
              {p1 > 0 && (
                <Concepto
                  etiqueta={`${I18n.t('Potencia')} 1`}
                  valor={`${FormatNumberService.formatTwoDecimalWithThousandSeparator(p1 / 1000)} kW`}
                />
              )}
              {p2 > 0 && (
                <Concepto
                  etiqueta={`${I18n.t('Potencia')} 2`}
                  valor={`${FormatNumberService.formatTwoDecimalWithThousandSeparator(p2 / 1000)} kW`}
                />
              )}
              {p3 > 0 && (
                <Concepto
                  etiqueta={`${I18n.t('Potencia')} 3`}
                  valor={`${FormatNumberService.formatTwoDecimalWithThousandSeparator(p3 / 1000)} kW`}
                />
              )}
              {p4 > 0 && (
                <Concepto
                  etiqueta={`${I18n.t('Potencia')} 4`}
                  valor={`${FormatNumberService.formatTwoDecimalWithThousandSeparator(p4 / 1000)} kW`}
                />
              )}
              {p5 > 0 && (
                <Concepto
                  etiqueta={`${I18n.t('Potencia')} 5`}
                  valor={`${FormatNumberService.formatTwoDecimalWithThousandSeparator(p5 / 1000)} kW`}
                />
              )}
              {p6 > 0 && (
                <Concepto
                  etiqueta={`${I18n.t('Potencia')} 6`}
                  valor={`${FormatNumberService.formatTwoDecimalWithThousandSeparator(p6 / 1000)} kW`}
                />
              )}

              {/* {p1 > 0 && <LiniaPotencia label="P1" value={p1} color={Colors.COLOR_NOTIFICATIONS} />}
                {p2 > 0 && <LiniaPotencia label="P2" value={p2} color={Colors.COLOR_GRAY_500} />}
                {p3 > 0 && <LiniaPotencia label="P3" value={p3} color={Colors.COLOR_BLUE_100} />}
                {p4 > 0 && <LiniaPotencia label="P4" value={p4} color={Colors.COLOR_ERROR} />}
                {p5 > 0 && <LiniaPotencia label="P5" value={p5} color={Colors.COLOR_BROWN} />}

                {p6 > 0 && <LiniaPotencia label="P6" value={p6} color={Colors.COLOR_PURPLE} />} */}
            </div>
          </>

          {fotovoltaica || eolica || motor || bateria ? (
            <>
              <div className={styles.divider} />

              <p className={styles.divider}>{I18n.t('ContractResumCard.instalaciones')}</p>
              <div className={styles.energies_container}>
                {fotovoltaica && (
                  <>
                    <div className={styles.divider} />

                    <div className={styles.energy}>
                      <EnergiaSolarIcon height={40} width={40} fill={Colors.COLOR_GENERACIO} />
                      <span>{I18n.t('ContractResumCard.solar')}</span>
                    </div>
                  </>
                )}
                {motor && (
                  <div className={styles.energy}>
                    <EnergiaMotorIcon height={40} width={40} fill={Colors.COLOR_GRAY_500} />
                    <span>{I18n.t('ContractResumCard.motor')}</span>
                  </div>
                )}
                {eolica && (
                  <div className={styles.energy}>
                    <EnergiaEolicaIcon height={40} width={40} fill={Colors.COLOR_EXCEDENTS} />
                    <span>{I18n.t('ContractResumCard.eolica')}</span>
                  </div>
                )}
                {bateria && (
                  <div className={styles.energy}>
                    <EnergiaBateriaIcon height={40} width={40} fill={Colors.COLOR_BLACK} />
                    <span>{I18n.t('ContractResumCard.bateria')}</span>
                  </div>
                )}
              </div>
              <div className={styles.divider} />
            </>
          ) : null}
          {instalaciones && instalaciones?.length > 0 ? (
            <>
              <div className={styles.divider} />

              <p className={styles.installations_title}>{I18n.t('ContractResumCard.instalaciones')}</p>
              <div className={styles.installations_container}>
                {instalaciones.map((inst) =>
                  getInstalationRow({
                    tecnologia: inst.tecnologia,
                    potencia: inst.potencia,
                  }),
                )}
              </div>
            </>
          ) : null}
        </div>,
      ]}
    />
  );
};

export default ContractSummaryWidget;
