import { Serializable } from 'types/interfaces/serializable';

export interface ActualizarTarifaAPII {
  id?: string;

  nif?: string;

  nombre?: string;

  apellidos?: string;

  fechaAltaSolicitud?: string;

  cups?: string;

  nombreComunidad?: string;

  tipoSolicitante?: string;

  indexada?: string;

  margenMensual?: number;

  margenKWH?: number;

  terminoDeEnergiaP1?: number;

  terminoDeEnergiaP2?: number;

  terminoDeEnergiaP3?: number;

  terminoDeEnergiaP4?: number;

  terminoDeEnergiaP5?: number;

  terminoDeEnergiaP6?: number;

  precioCompensacion?: number;

  documentos?: Array<{
    id: string;
    nombre: string;
    url: string;
  }>;
}

export default class ActualizarTarifa implements Serializable<ActualizarTarifa> {
  id: string;

  nif: string;

  nombre: string;

  apellidos: string;

  fechaAltaSolicitud: string;

  cups: string;

  nombreComunidad: string;

  tipoSolicitante: string;

  indexada: string;

  margenMensual: number;

  margenKWH: number;

  terminoDeEnergiaP1: number;

  terminoDeEnergiaP2: number;

  terminoDeEnergiaP3: number;

  terminoDeEnergiaP4: number;

  terminoDeEnergiaP5: number;

  terminoDeEnergiaP6: number;

  precioCompensacion: number;

  documentos: Array<{
    id: string;
    nombre: string;
    url: string;
  }>;

  deserialize(input: ActualizarTarifaAPII): ActualizarTarifa {
    if (!input) {
      return this;
    }
    this.id = input.id || '';
    this.nif = input.nif || '';
    this.nombre = input.nombre || '';
    this.apellidos = input.apellidos || '';
    this.fechaAltaSolicitud = input.fechaAltaSolicitud || '';
    this.cups = input.cups || '';
    this.nombreComunidad = input.nombreComunidad || '';
    this.tipoSolicitante = input.tipoSolicitante || '';
    this.indexada = input.indexada || 'no';
    this.margenMensual = input.margenMensual || 0;
    this.margenKWH = input.margenKWH || 0;
    this.terminoDeEnergiaP1 = input.terminoDeEnergiaP1 || 0;
    this.terminoDeEnergiaP2 = input.terminoDeEnergiaP2 || 0;
    this.terminoDeEnergiaP3 = input.terminoDeEnergiaP3 || 0;
    this.terminoDeEnergiaP4 = input.terminoDeEnergiaP4 || 0;
    this.terminoDeEnergiaP5 = input.terminoDeEnergiaP5 || 0;
    this.terminoDeEnergiaP6 = input.terminoDeEnergiaP6 || 0;

    this.precioCompensacion = input.precioCompensacion || 0;
    this.documentos = input.documentos || [];

    return this;
  }

  // eslint-disable-next-line class-methods-use-this
  deserializeArray(inputArray: Array<ActualizarTarifaAPII>): Array<ActualizarTarifa> {
    const result: Array<ActualizarTarifa> = inputArray.map((input) => new ActualizarTarifa().deserialize(input));
    return result;
  }

  setAttr<T>(key: string, value: T): void {
    Object.assign(this, { [key]: value });
  }
}
