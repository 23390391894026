import { Serializable } from '../interfaces';

export interface UserAPI {
  token: string;
  email: string;
  nombre: string;
  apellidos: string;
  tipoDeUsuario: string;
  nif: string;
}

export default class User implements Serializable<User> {
  token: string;

  email: string;

  nombre: string;

  apellidos: string;

  tipoDeUsuario: string;

  nif: string;

  deserialize(input: UserAPI): User | null {
    if (!input) {
      return null;
    }

    this.token = input.token || '';
    this.email = input.email || '';
    this.nombre = input.nombre || '';
    this.apellidos = input.apellidos || '';
    this.tipoDeUsuario = input.tipoDeUsuario || '';
    this.nif = input.nif || '';

    return this;
  }

  // eslint-disable-next-line class-methods-use-this
  deserializeArray(inputArray: Array<UserAPI>): Array<User | null> {
    const users: Array<User | null> = inputArray.map((input) => new User().deserialize(input));

    return users;
  }

  isAdmin(): boolean {
    return this.tipoDeUsuario === 'admin';
  }

  isPromotor(): boolean {
    return this.tipoDeUsuario === 'promotor';
  }
}
