import React, { useEffect, useState } from 'react';

import { Installation } from 'types/models';
import { I18n } from 'core';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import { useDebounce } from 'hooks';
import Button from 'components/Button/Button';
import { navigate } from 'navigation';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';

import { installationServiceInstance } from 'services';
import styles from './InstallationListPage.module.scss';

import { InstallationListFilters, InstallationListTable } from './components';

const InstallationListPage = (): JSX.Element => {
  const [search, setSearch] = useState<string>('');
  const debouncedSearchText = useDebounce(search, 500);
  const [AllInstallation, setInstallation] = useState<Array<Installation>>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const getInstallation = () => {
    installationServiceInstance
      .getAllInstallation(search)
      .then((response) => {
        setInstallation(response);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    getInstallation();
  }, [debouncedSearchText]);

  useEffect(() => {
    setLoadingData(true);

    getInstallation();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Button
          className={styles.viewmore_button}
          variant="tertiary"
          type="button"
          onClick={(): Promise<void> => navigate('/tecnic/dashboard')}
          text={I18n.t('UsersPage.goDashboard')}
        />
      </div>
      <SectionWrapper>
        <InstallationListFilters search={setSearch} />

        <div className={styles.table_wrapper}>
          {loadingData ? <FullPageSyncLoader icon /> : <InstallationListTable data={AllInstallation} />}
        </div>
      </SectionWrapper>
    </div>
  );
};

export default InstallationListPage;
