import { Serializable } from '../interfaces';

export interface ElecsumGreenNewAPI {
  id?: string;
  titular: string;
  fecha: string;
  descripcion: string;
  imagenUrl: string;
}

export default class ElecsumGreenNew implements Serializable<ElecsumGreenNew> {
  id: string;

  titular: string;

  date: string;

  description: string;

  image: string;

  deserialize(input: ElecsumGreenNewAPI): ElecsumGreenNew {
    if (!input) {
      return this;
    }
    this.id = input.id || '';
    this.titular = input.titular || '';
    this.date = input.fecha || '';
    this.description = input.descripcion || '';
    this.image = input.imagenUrl || '';

    return this;
  }

  serialize(): ElecsumGreenNewAPI {
    return {
      titular: this.titular,
      fecha: this.date,
      descripcion: this.description,
      // imagenUrl: this.image,
    } as ElecsumGreenNewAPI;
  }

  setAttr<T>(key: string, value: T): void {
    Object.assign(this, { [key]: value });
  }

  deserializeArray(inputArray: Array<ElecsumGreenNewAPI>): Array<ElecsumGreenNew> {
    const elecsumGreenNews: Array<ElecsumGreenNew> = inputArray.map((input) =>
      new ElecsumGreenNew().deserialize(input),
    );

    return elecsumGreenNews;
  }
}
