import { I18n } from 'core';
import React, { ChangeEvent } from 'react';

import styles from './SelectUserType.module.scss';

export interface SelectUSerInterface {
  value: string;
  path: string;
  onChange: CallableFunction;
}
class SelectUserType extends React.PureComponent<
  {
    value: string;
    path: string;
    onChange: CallableFunction;
  },
  SelectUSerInterface
> {
  constructor(props: SelectUSerInterface) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e: ChangeEvent<HTMLSelectElement>): void {
    const { path, onChange } = this.props;
    if (onChange) {
      onChange(path, e.target.value);
    }
  }

  render(): JSX.Element {
    const { value } = this.props;
    return (
      // <form onSubmit={this.handleSubmit}>
      <div className={styles.root}>
        <label htmlFor="type">{I18n.t('SelectUserType.label')}</label>
        <select id="type" name="type" value={value} onChange={this.handleChange}>
          <option value="Basic">{I18n.t('SelectUserType.basic')}</option>
          <option value="Premium">{I18n.t('SelectUserType.premium')}</option>
        </select>
        {/* <input type="submit" value="Submit" />
      </form> */}
      </div>
    );
  }
}

export default SelectUserType;
