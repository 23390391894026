import { useCallback, useState } from 'react';

export const useToggle = (initialState = false): [boolean, () => void] => {
  // inicializamos el estado con un valor inicial
  const [value, setState] = useState(initialState);

  // useCallback hace que se guarde la función
  // en memoria y no se vuelva a crear
  const toggle = useCallback(() => {
    setState((state) => !state);
  }, []);

  return [value, toggle];
};
