import React from 'react';
import { Installation } from 'types/models';
import cx from 'classnames';
import { navigate } from 'navigation';
import { RoundedButton } from 'components/NewInputs';
import { I18n } from 'core';
import styles from './InstallationListTableRow.module.scss';

interface InstallationListTableRowPropsI {
  row: Installation;
  index: number;
}

const prettiefyDirection = (item: Installation): string =>
  `${item.domicilioTitular} - ${item.poblacionTitular} (${item.provinciaTitular})`;

const InstallationListTableRow = (props: InstallationListTableRowPropsI) => {
  const { row, index } = props;
  const { idElecsum, cau, nombreTitular, apellidosTitular } = row;

  const handleClick = () => {
    navigate(`/tecnic/installation/${idElecsum}/installation`);
  };

  return (
    <>
      <tr
        key={index}
        className={cx(styles.mainrow, { [styles.even]: index % 2 === 0 }, { [styles.odd]: index % 2 !== 0 })}
        onClick={handleClick}
      >
        <td className={styles.cups}>{cau} </td>
        <td>{`${nombreTitular} ${apellidosTitular}`}</td>
        <td>{prettiefyDirection(row)} </td>
        <td className={styles.sizeButton}>
          <RoundedButton onClick={handleClick}>{I18n.t('CUPSListPage.row.verDetalle')}</RoundedButton>
        </td>
      </tr>
    </>
  );
};

export default InstallationListTableRow;
