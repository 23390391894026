import wattsConverterService from 'services/local/WattsConverter';
import React from 'react';
import classNames from 'classnames';
import { I18n } from 'core';
import { ReactComponent as ElecsumShareIcon } from 'assets/icons/ElecsumShare.svg';
import { FormatNumberService } from 'services';
import styles from './SharedEnergyWidget.module.scss';
import Card from '../../Card/Card';

interface MemberI {
  nombre: string;
  porcentaje: number;
  generacion: number;
}

interface SharedEnergyPropsI {
  totalGeneration: number;
  members: Array<MemberI>;
  powerInstallation: number;
  maxHeight?: string;
}

const SharedEnergyWidget = (props: SharedEnergyPropsI): JSX.Element => {
  const { totalGeneration, members, powerInstallation, maxHeight } = props;
  const totalConverted = wattsConverterService.convertWatts(totalGeneration);

  return (
    <Card
      fullHeight
      title={I18n.t('sharedEnergy')}
      rightCorner={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ElecsumShareIcon />
        </div>
      }
      body={[
        <div className={styles.root} key={1}>
          <div className={styles.info_container}>
            <div className={styles.row}>
              <p className={styles.label}>{I18n.t('installationPowerTotal')}</p>
              <p className={styles.value}>
                {wattsConverterService.toSelectedMagnitudeString(powerInstallation, 'kW')} kWn
              </p>
            </div>
            <div className={styles.row}>
              <p className={styles.label}>{I18n.t('totalAccumulated')}</p>
              <p className={styles.value}>{`${totalConverted.num} ${totalConverted.unitHour}`}</p>
            </div>
          </div>
          <div className={classNames(maxHeight, styles.members_wrapper)}>
            {members.map((member, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={classNames(
                  styles.member,
                  { [styles.even]: index % 2 === 0 },
                  { [styles.odd]: index % 2 === 1 },
                )}
              >
                <span className={styles.nombre}>{member.nombre}</span>
                <span className={styles.percent}>
                  {FormatNumberService.formatTwoDecimalWithThousandSeparator(member.porcentaje)}%
                </span>
                <span className={styles.generation}>
                  {`${wattsConverterService.convertWatts(member.generacion).num} ${
                    wattsConverterService.convertWatts(member.generacion).unitHour
                  }`}
                </span>
              </div>
            ))}
          </div>
        </div>,
      ]}
    />
  );
};

export default React.memo(SharedEnergyWidget);
