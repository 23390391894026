export interface EnergyDataProperties {
  number: number;
  date: Date;
  totalCost: number;
}

interface EnergyDataApi {
  numero: number;
  fecha: Date;
  importeTotal: number;
}

export default class InvoicesObj {
  data: Array<EnergyDataProperties>;

  deserialize(input: Array<EnergyDataApi>): InvoicesObj {
    if (!input) {
      return this;
    }

    this.data = input.map(
      (dataObj: EnergyDataApi): EnergyDataProperties => ({
        number: dataObj.numero,
        date: dataObj.fecha,
        totalCost: dataObj.importeTotal,
      }),
    );

    return this;
  }
}
