import { LoggedInGuard } from 'navigation/guards';
import { BaseLayout } from 'layouts';

import isProdGuard from 'navigation/guards/is-prod.guard';
import HomePagePromotor from 'pages/ModePromotor/HomePagePromotor';
import ChartsPagePromotor from 'pages/ModePromotor/ChartsPagePromotor';
import { RouteInterface } from '../../types/interfaces';

const routes: Array<RouteInterface> = [
  {
    path: '/promotor',
    name: 'Promotor',
    children: [
      {
        path: '/home',
        layout: BaseLayout,
        component: HomePagePromotor,
        name: 'Home',
        exact: true,
        guards: [LoggedInGuard, isProdGuard],
      },
      {
        path: '/:promotorID/home',
        layout: BaseLayout,
        component: HomePagePromotor,
        name: 'Home',
        exact: true,
        guards: [LoggedInGuard, isProdGuard],
      },
      {
        path: '/charts',
        layout: BaseLayout,
        component: ChartsPagePromotor,
        name: 'Charts',
        guards: [LoggedInGuard, isProdGuard],
      },
      {
        path: '/:promotorID/charts',
        layout: BaseLayout,
        component: ChartsPagePromotor,
        name: 'Charts',
        guards: [LoggedInGuard, isProdGuard],
      },
    ],
  },
];

export default routes;
