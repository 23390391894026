
import imagenesFix1cat from'../../../assets/images/SliderStatick/ECE-graf-PLA-FIX-2-cat--1.png';
import imagenesFix2cat from'../../../assets/images/SliderStatick/ECE-graf-PLA-FIX-2-cat--2.png';
import imagenesFix3cat from'../../../assets/images/SliderStatick/ECE-graf-PLA-FIX-cat--3.png';
import imagenesFix4cat from'../../../assets/images/SliderStatick/ECE-graf-PLA-FIX-cat--4.png';
import imagenesFix5cat from'../../../assets/images/SliderStatick/ECE-graf-PLA-FLEXA3-5.png';
import imagenesFix6cat from'../../../assets/images/SliderStatick/ECE-graf-PLA-FLEXA3-cat-6.png';

import imagenesFix1es from'../../../assets/images/SliderStatick/1.png';
import imagenesFix2es from'../../../assets/images/SliderStatick/2.png';
import imagenesFix3es from'../../../assets/images/SliderStatick/3.png';
import imagenesFix4es from'../../../assets/images/SliderStatick/4.png';
import imagenesFix5es from'../../../assets/images/SliderStatick/5.png';
import imagenesFix6es from'../../../assets/images/SliderStatick/6.png';

export default {


      plaFlix :{

        cat:[
        {
        src: imagenesFix3cat,
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse",
      }, {
        src: imagenesFix4cat,
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse",
      }
    ],

    es:[
        {
        src: imagenesFix1es,
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse",
      }, {
        src: imagenesFix2es,
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse",
      }
    ]
},
      plaFlexa2:{

        cat:[{
        src: imagenesFix1cat,
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse",
      },
      {
          src: imagenesFix2cat,
          text: "Duis aute irure dolor in reprehenderit in voluptate velit esse",
        }],
        es:[{
            src: imagenesFix3es,
            text: "Duis aute irure dolor in reprehenderit in voluptate velit esse",
          },
          {
              src: imagenesFix4es,
              text: "Duis aute irure dolor in reprehenderit in voluptate velit esse",
            }]

    },

        platFlexa3:{

            cat:[
            {
                src: imagenesFix5cat,
                text: "Duis aute irure dolor in reprehenderit in voluptate velit esse",
              }, {
                src: imagenesFix6cat,
                text: "Duis aute irure dolor in reprehenderit in voluptate velit esse",
              }

         ],
         es:[
            {
                src: imagenesFix5es,
                text: "Duis aute irure dolor in reprehenderit in voluptate velit esse",
              }, {
                src: imagenesFix6es,
                text: "Duis aute irure dolor in reprehenderit in voluptate velit esse",
              }

         ]},

    }
