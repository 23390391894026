import React from 'react';

import { RoutesInterface } from 'types/interfaces';
import styles from './AuthLayout.module.scss';

const AuthLayout = (props: RoutesInterface): JSX.Element => {
  const { component: Component } = props;

  return (
    <>
      <div className={styles.root}>{Component ? <Component {...props} /> : null}</div>
    </>
  );
};
export default AuthLayout;
