import { UtilsService } from 'services';
import React from 'react';
import styles from './ViewDate.module.scss';

interface DateObject {
  day: number;
  month: number;
  year: number;
}

interface ViewDatePropsI {
  date: DateObject;
  currentTab: string;
  currentMonthSelected?: number;
  yearSelected?: number;
}

const NAV_HISTORIC = 'historic';
const NAV_YEAR = 'year';
const NAV_MONTH = 'month';
const NAV_DAY = 'day';

const ViewDate = (props: ViewDatePropsI): JSX.Element => {
  const { date, currentTab, currentMonthSelected = 0, yearSelected = 0 } = props;

  let dateToShow = new Date(date.year, currentMonthSelected);

  if (currentTab === NAV_DAY) {
    return (
      <div className={styles.root}>
        <div className={styles.day}>
          {date.day}/{date.month}/{date.year}
        </div>
      </div>
    );
  }
  if (currentTab === NAV_MONTH) {
    dateToShow = new Date(date.year, date.month - 1);
    return (
      <div className={styles.root}>
        <span className={styles.month}>{UtilsService.dateToLocaleString(dateToShow, { month: 'long' })}</span>{' '}
        <span className={styles.year}>{UtilsService.dateToLocaleString(dateToShow, { year: 'numeric' })}</span>
      </div>
    );
  }
  if (currentTab === NAV_MONTH || currentTab === NAV_YEAR) {
    return (
      <div className={styles.root}>
        <span className={styles.month}>{UtilsService.dateToLocaleString(dateToShow, { month: 'long' })}</span>{' '}
        <span className={styles.year}>{UtilsService.dateToLocaleString(dateToShow, { year: 'numeric' })}</span>
      </div>
    );
  }
  if (currentTab === NAV_HISTORIC) {
    return (
      <div className={styles.root}>
        <span className={styles.year}>{yearSelected}</span>
      </div>
    );
  }
  return <></>;
};

export default ViewDate;
