import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';

import styles from './EunoiaEditorInput.module.scss';

interface EunoiaEditorInputProps {
  id: string;
  path: string;
  name: string;
  placeholder?: string;
  value: string;
  onChange: (path: string, value: string) => void;
}

function replaceEmptyPTagWithBrTa(htmlString: string) {
  return htmlString.replace(/<p><\/p>/gi, '<br/>');
}

const EunoiaEditorInput = (props: EunoiaEditorInputProps): JSX.Element => {
  const { path, value, onChange } = props;

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    const contentState = value ? convertFromHTML(value) : ContentState.createFromText('');
    setEditorState(EditorState.createWithContent(contentState));
  }, []);

  useEffect(() => {
    const html = convertToHTML(editorState.getCurrentContent());
    if (html) {
      const cleanhtml = replaceEmptyPTagWithBrTa(html);
      onChange(path, cleanhtml);
    }
  }, [editorState]);

  return (
    <div className={styles.editorWrapper}>
      <Editor
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
        }}
        editorState={editorState}
        onEditorStateChange={setEditorState}
        wrapperClassName={styles.wrapperClass}
        editorClassName={styles.editorClass}
        toolbarClassName={styles.toolbarClass}
      />
    </div>
  );
};

export default EunoiaEditorInput;
