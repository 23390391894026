import React from 'react';
import cx from 'classnames';

import { Colors } from 'styles';
import styles from './tag.module.scss';

export interface TagProps {
  children: any;
  color?: string;
  block?: boolean;
  strong?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

export const Tag = (
  props: TagProps &
    JSX.IntrinsicAttributes &
    React.ClassAttributes<HTMLParagraphElement> &
    React.HTMLAttributes<HTMLParagraphElement>,
): JSX.Element => {
  const { children, strong, block = false, color = Colors.COLOR_ELECSUMGREEN, size = 'sm', style } = props;
  return (
    <p
      style={{ color, borderColor: color, ...style }}
      className={cx(styles.tag, styles[`tag--size-${size}`], {
        [styles.block]: block,
        [styles.strong]: strong,
      })}
    >
      {children}
    </p>
  );
};
