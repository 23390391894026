export interface Environment {
  development: boolean;
  apiUrl: string;
  useMocks: boolean;
  elecsumGreenUrl: string;
  sustainabilityMoreInfo: string;
  contactUs: 'https://elecsum.com/contacta-amb-nosaltres/';
  elecsumShareUrl: string;
  matcher: boolean;
  matcher2: boolean;
  urlInformeAutomatico: string;
}

const environment = {
  dev: {
    development: true,
    useMocks: false,
    apiUrl: 'https://elecsum-api.eunoia.es',
    elecsumShareUrl: 'http://elecsum-web-share.eunoia.es/',
    // apiUrl: 'https://app-api.elecsum.com',
    elecsumGreenUrl: 'https://elecsum.eunoia.es/egreen/',
    sustainabilityMoreInfo: 'https://ec.europa.eu/clima/policies/international/negotiations/paris_es',
    contactUs: 'https://elecsum.com/contacta-amb-nosaltres/',
    // matcher: process.env.REACT_APP_ELECSUM_APP_MATCHER,
    urlInformeAutomatico: 'https://elecsum-web-share.eunoia.es/reports/auto/',

    matcher: true,
    matcher2: true,
  } as Environment,
  staging: {
    development: false,
    useMocks: false,
    apiUrl: 'https://app-api.elecsum.com',
    elecsumGreenUrl: 'https://app.elecsum.com/egreen/',
    sustainabilityMoreInfo: 'https://ec.europa.eu/clima/policies/international/negotiations/paris_es',
    contactUs: 'https://elecsum.com/contacta-amb-nosaltres/',
    urlInformeAutomatico: 'https://elecsum-web-share.eunoia.es/reports/auto/',
    // matcher: process.env.REACT_APP_ELECSUM_APP_MATCHER,
    matcher: false,
    matcher2: false,
  } as Environment,
  prod: {
    development: false,
    useMocks: false,
    apiUrl: 'https://app-api.elecsum.com',
    elecsumGreenUrl: 'https://app.elecsum.com/egreen/',
    elecsumShareUrl: 'https://share.elecsum.com/',
    sustainabilityMoreInfo: 'https://ec.europa.eu/clima/policies/international/negotiations/paris_es',
    contactUs: 'https://elecsum.com/contacta-amb-nosaltres/',
    // matcher: process.env.REACT_APP_ELECSUM_APP_MATCHER,
    urlInformeAutomatico: 'https://share.elecsum.com/reports/auto/',

    matcher: true,
    matcher2: true,
  } as Environment,
};

export default environment;
