import { Serializable } from 'types/interfaces';

interface DeviceInfo {
  identificador: string;
  sistemaOperativo: string;
  marca: string;
  modelo: string;
  idioma: string;
  activo: 'si' | 'no';
}
export interface UserInfoAPI {
  activo?: 'si' | 'no';
  administrador?: 'si' | 'no';
  nif: string;
  email: string;
  nombre: string;
  apellidos: string;
  dispositivos: Array<DeviceInfo>;
  fechaUltimaConexion?: string;
  origenUltimaConexion?: string;
  tipoDeUsuario: string;
}

export default class UserInfo implements Serializable<UserInfo> {
  activo: 'si' | 'no';

  administrador: 'si' | 'no';

  nif: string;

  email: string;

  nombre: string;

  apellidos: string;

  dispositivos: Array<DeviceInfo>;

  fechaUltimaConexion: string;

  origenUltimaConexion: string;

  tipoDeUsuario: string;

  deserialize(input: UserInfoAPI): UserInfo {
    if (!input) {
      return this;
    }
    this.activo = input.activo || 'no';
    this.administrador = input.administrador || 'no';
    this.nif = input.nif || '';
    this.email = input.email || '';
    this.nombre = input.nombre || '';
    this.apellidos = input.apellidos || '';
    this.dispositivos = input.dispositivos || [];
    this.fechaUltimaConexion = input.fechaUltimaConexion || '';
    this.tipoDeUsuario = input.tipoDeUsuario || '';
    return this;
  }

  deserializeArray(inputArray: Array<UserInfoAPI>): Array<UserInfo> {
    const Users: Array<UserInfo> = inputArray.map((input) => new UserInfo().deserialize(input));

    return Users;
  }
}
