import React, { ChangeEvent } from 'react';
import cx from 'classnames';
import styles from './ButtonCsv.module.scss';

interface ButtonCsvI {
  onFileSelect: (file: File) => void;
  className?: string;
}

const ButtonCsv = (props: ButtonCsvI): JSX.Element => {
  const { onFileSelect, className } = props;

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      onFileSelect(file);
    }
  };

  return (
    <>
      <input
        id="file-upload"
        className={cx(className, styles.root, {
          [styles.block]: true,
          [styles.primary]: false,
          [styles.secondary]: false,
          [styles.tertiary]: true,
          [styles.quaternary]: false,
          [styles.reddelete]: false,
        })}
        type="file"
        accept=".csv"
        onChange={handleFileUpload}
      />
    </>
  );
};

export default ButtonCsv;
