import { DataTable, SectionTitle, TableHeading } from 'components';

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { FormGroup, FormGroupProps, I18n } from 'core';
import { ReactComponent as DocumentsIcon } from 'assets/icons/Documents.svg';

import { Colors } from 'styles';
import { LoggingService } from 'services';

import Tipodecontacto from 'types/models/tipodecontacto';

import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg';

import { translatableLangs } from 'config/translatable-langs';
import { SelectItemI } from 'components/NewInputs/Select/ElecsumSelect';
import { ToolsBarWithoutUrl } from 'pages/ModeTecnic/rol-comunidades/comunidades-detalle-page/tools-bar-without-url/tools-bar-without-url';
import styles from './comunidades-custom-onboarding-tipodecontacto.module.scss';
import { SaveDataHandle } from '../../comunidades-custom-onboarding-process-page';

interface ComunidadesCustomOnboardingFormProps {
  communityId: string;
  communityCustomOnboarding: Tipodecontacto[];

  saveDetails: (customOnboarding: any) => void;
  deleteFaqs: (customOnboarding: any) => void;
}
const ComunidadesCustomOnboardingTipoDeContacto = forwardRef<SaveDataHandle, ComunidadesCustomOnboardingFormProps>(
  (props: ComunidadesCustomOnboardingFormProps, ref) => {
    const { communityCustomOnboarding, saveDetails, deleteFaqs } = props;
    const [currentLang, setCurrentLang] = useState<SelectItemI>(translatableLangs[0]);
    const [errors, setErrors] = useState({});
    const [environmentNew, setEnvironmentNew] = useState<Tipodecontacto>(new Tipodecontacto());

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [getIdioma, setIdioma] = useState<any>(currentLang.value);
    const [textoCa, setextoCa] = useState<string>('');
    const [textoEs, setextoEs] = useState<string>('');
    const validateForm = (): boolean => {
      setErrors({});
      return true;
    };
    function changeIdioma(x: any) {
      setCurrentLang(x);
      setIdioma(x.id);
    }

    function handleChange<T>(path: string, value: T): void {
      let x = {
        es: '',
        ca: '',
      };
      if (path === 'tipoContacto') {
        if (getIdioma === 'Es') {
          x = {
            es: value as unknown as string,
            ca: textoCa,
          };
          setextoEs(x.es);
          environmentNew.tipoContacto.es = x.es;
        } else {
          x = {
            es: textoEs,
            ca: value as unknown as string,
          };
          setextoCa(x.ca);
          environmentNew.tipoContacto.ca = x.ca;
        }
      } else {
        environmentNew.setAttr(path, value);
        setEnvironmentNew(
          new Tipodecontacto().deserialize({
            ...environmentNew,
            [path]: value,
          }),
        );
      }
    }
    const handleSubmit = (): void => {
      if (validateForm()) {
        LoggingService.log('handleSubmit :: ok', { client: environmentNew });
        saveDetails(environmentNew);
      } else {
        LoggingService.log('handleSubmit :: errors', { client: environmentNew, errors });
      }
    };
    useImperativeHandle(ref, () => ({
      getData: () => {
        handleSubmit();
      },
    }));
    const formFields: Array<FormGroupProps> = [
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.tipodeContacto'),
        type: 'string',
        id: 'tipoContacto',
        path: 'tipoContacto',
        name: 'tipoContacto',
        placeholder: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.tipodeContacto'),
        value: getIdioma === 'ca' ? environmentNew.tipoContacto.ca : environmentNew.tipoContacto.es,
      },
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.email'),
        type: 'email',
        id: 'emailContacto',
        path: 'emailContacto',
        name: 'emailContacto',
        placeholder: I18n.t('ComunidadesCustomOnboardingProcess.form.placeholder.email'),
        value: environmentNew.emailContacto,
      },
    ];

    const deleteFaq = async (faq: any): Promise<void> => {
      deleteFaqs(faq);
    };
    const headings: Array<TableHeading> = [
      { label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.tipodeContacto'), key: 'tipoContacto' },
      { label: 'Email', key: 'emailContacto' },
      {
        label: I18n.t('Actions'),
        type: 'actions',
        key: '',
        actions: [
          {
            label: I18n.t('Delete'),
            // eslint-disable-next-line react/jsx-no-undef
            icon: <TrashIcon height={25} width={25} fill={Colors.COLOR_ELECSUMGREEN} />,
            onActionClick: deleteFaq,
          },
        ],
      },
    ];
    return (
      <div className={styles.root}>
        <div className={styles.flex}>
          <div className={styles.titulo}>
            <DocumentsIcon height={20} width={20} fill={Colors.COLOR_BLACK} className={styles.DocumentsIcon} />
            <SectionTitle text={I18n.t('ComunidadesCustomOnboardingProcess.form.tipodeContactoTitle')} />
          </div>
          <div>
            <ToolsBarWithoutUrl
              langs={translatableLangs}
              selectedLang={currentLang}
              onChangeLanguage={(x) => changeIdioma(x)}
            />
          </div>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.containerForms}>
            <div className={styles.containerForm}>
              {formFields.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  placeholder={formGroup.placeholder}
                  value={formGroup.value}
                  required={formGroup.required}
                  onChange={handleChange}
                  readOnly={formGroup.readOnly}
                  className={formGroup.className}
                  options={formGroup.options}
                  maxLenght={20000}
                />
              ))}
            </div>
          </div>
        </form>
        <div className={styles.tablenews}>
          {communityCustomOnboarding && communityCustomOnboarding.length > 0 ? (
            <>
              {currentLang.id === 'es' ? (
                <DataTable
                  headings={headings}
                  rows={communityCustomOnboarding.filter((x) => x.tipoContacto.es !== '') as any}
                  currentLang={currentLang.id}
                  test
                />
              ) : (
                <DataTable
                  headings={headings}
                  rows={communityCustomOnboarding.filter((x) => x.tipoContacto.ca !== '') as any}
                  currentLang={currentLang.id}
                  test
                />
              )}
            </>
          ) : // <DataTable headings={headings} rows={communityCustomOnboarding as any} />
          null}
        </div>
      </div>
    );
  },
);

export default ComunidadesCustomOnboardingTipoDeContacto;
