import SavingDataPromotor from './savingDataPromotor';

interface AcummulatedSavingDataI {
  x: number;
  y: number;
}

interface SavingDataPropertiesI {
  fecha: string;
  ahorro: number;
  importeSinElecsum: number;
  excedente: number;
  autoconsumida: number;
  importeElecsum: number;
  ahorroAcumulado: number;
  ahorroAcumuladoGrafico: Array<AcummulatedSavingDataI>;
  ahorroAcumuladoInicio: string;
  ahorroAcumuladoFin: string;
}

interface SavingDataPromotorAPII {
  datos: SavingDataPropertiesI;
  habilitado: 'si' | 'no';
}

export default class SavingObjectPromotor {
  datos: SavingDataPromotor;

  showWidget: boolean;

  deserialize(input: SavingDataPromotorAPII): SavingObjectPromotor {
    if (!input) {
      this.showWidget = false;
      return this;
    }

    this.datos = new SavingDataPromotor().deserialize(input.datos);

    this.showWidget = input.habilitado === 'si';

    return this;
  }
}
