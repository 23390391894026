import { EunoiaInput } from 'components';
import { I18n } from 'core';
import React, { useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';

interface ColorPickerProps {
  path: string;
  placeholder?: string;
  value?: string;
  onChange?: CallableFunction;
}

export const ColorPicker = (props: ColorPickerProps): JSX.Element => {
  const { path, placeholder = I18n.t('ColorPicker.typeHexColor'), value = '', onChange } = props;
  const [color, setColor] = useState<string>(value);

  const changeColor = (pathA: string, valueA: string) => {
    if (onChange) {
      onChange(pathA, valueA);
      setColor(valueA);
    }
  };

  useEffect(() => {
    if (onChange && color !== value) {
      onChange(path, color.replace(' ', ''));
    }
  }, [color]);

  return (
    <div className="colorPicker">
      <HexColorPicker color={color} onChange={setColor} />
      <EunoiaInput
        id={path}
        name={path}
        type="text"
        onChange={changeColor}
        path={path}
        value={value.includes("#") ? value : `#${value}`} // agrega el símbolo "#" al inicio del valor solo si no está presente
        maxLenght={7}
        placeholder={placeholder}
      />
    </div>
  );
};
