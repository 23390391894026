import { Card, Spinner } from 'components';
import React from 'react';
import { I18n } from 'core';

import { ReactComponent as InstallationIcon } from 'assets/icons/Installation.svg';
import { navigate } from 'navigation';
import { Colors } from 'styles';
import { FormatNumberService } from 'services';
import styles from './RegisteredInstallationWidget.module.scss';

interface RegisteredInstallationWidgetPropsI {
  cupsNumber: number;
}

const RegisteredInstallationWidget = (props: RegisteredInstallationWidgetPropsI): JSX.Element => {
  const { cupsNumber } = props;

  const handleClickNavigate = () => {
    navigate('/tecnic/installation');
  };

  return (
    <Card
      title={I18n.t('DashboardPage.RegisteredInstallationWidget.title')}
      body={[
        <div className={styles.root} key={1}>
          <InstallationIcon fill={Colors.COLOR_ORANGE_STRONG} height={50} width={50} />
          <span className={styles.value}>
            {cupsNumber >= 0 ? FormatNumberService.formatInteger(`${cupsNumber}`) : <Spinner size={30} />}
          </span>
          <p className={styles.label}>{I18n.t('DashboardPage.RegisteredInstallationWidget.p1')}</p>
          <button className={styles.viewmore_button} type="button" onClick={() => handleClickNavigate()}>
            {I18n.t('viewMore')}
          </button>
        </div>,
      ]}
    />
  );
};

export default React.memo(RegisteredInstallationWidget);
