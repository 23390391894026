import { AdminCupsListItem } from 'types/models';
import React from 'react';
import { I18n } from 'core';
import styles from './CupsListTable.module.scss';
import { CupsListTableRow } from '..';

interface CupsListTablePropsI {
  data: Array<AdminCupsListItem>;
}

const CupsListTable = (props: CupsListTablePropsI): JSX.Element => {
  const { data } = props;

  return (
    <table className={styles.table_wrapper}>
      <thead>
        <tr>
          <th aria-label="toggler" />
          <th>{I18n.t('CUPSListPage.headers.cups')}</th>
          <th>{I18n.t('CUPSListPage.headers.name')}</th>
          <th>{I18n.t('CUPSListPage.headers.domicilio')}</th>
          <th aria-label="actions" />
        </tr>
      </thead>

      <tbody>
        {data.length > 0 ? (
          data.map((row, index) => <CupsListTableRow row={row} index={index} />)
        ) : (
          <tr>
            <td className={styles.nodata} colSpan={4}>
              {I18n.t('CUPSListPage.error.noData')}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default CupsListTable;
