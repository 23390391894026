import { ElecsumGreenNew } from 'types/models';

import { ENVIRONMENTALNEWS_MOCK } from '../../mocks';

export class EnvironmentalNewsService {
  async getAllEnvironmetalNews(): Promise<Array<ElecsumGreenNew>> {
    const news: Array<ElecsumGreenNew> = new ElecsumGreenNew().deserializeArray(ENVIRONMENTALNEWS_MOCK);

    return news;
  }
}

const environmentalNewsServiceInstance = new EnvironmentalNewsService();
export default environmentalNewsServiceInstance;
