import { Serializable } from 'types/interfaces';
import { TranslatableJSON } from 'types/interfaces/polyglot';

export enum Customizable {
  SI = 'si',
  NO = 'no',
}

export const CUSTOMIZABLE_VALUES = Object.values(Customizable);

export type CustomizableType = typeof CUSTOMIZABLE_VALUES[number];

export enum CustomizableImageType {
  LOGO = 'logo',
  IMAGE_STEP_01 = 'imagen-paso-01',
  IMAGE_STEP_02 = 'imagen-paso-02',
  IMAGE_STEP_03 = 'imagen-paso-03',
  IMAGE_STEP_04 = 'imagen-paso-04',
  IMAGE_STEP_05 = 'imagen-paso-05',
}

export const CUSTOMIZABLE_IMAGE_TYPE_VALUES = Object.values(CustomizableImageType);

export type CustomizableImageTypeType = typeof CUSTOMIZABLE_IMAGE_TYPE_VALUES[number];

interface TipoContactoLang {
  es: string;
  ca: string;
}

interface TipoContactoItem {
  id: number;
  tipoContacto: TipoContactoLang;
  emailContacto: string;
}

export interface CustomizableImage {
  id: string;
  tipo: CustomizableImageType;
  url: string;
}
export interface FaqsApi {
  id: number;
  pregunta: string;
  respuesta: string;
  idioma: string;
}
export interface CommunityCustomOnboardingCUPSAPII {
  personalizacionParaApp: Customizable;
  personalizacionParaWeb: Customizable;
  emailDeContacto: string;
  telefonoDeContacto: string;
  colorPrimario: string;
  colorSecundario: string;
  politicaDePrivacidad: {
    es: string;
    ca: string;
  };
  condicionesOferta: {
    es: string;
    ca: string;
  };
  imagenes?: CustomizableImage[];
  // faqs?: FaqsApi[];
  horarioContacto: {
    horarios: Array<{
      franja: string;
      horas: string;
      idioma: string;
    }>;
  };
  tituloFinalizacionOnboarding: {
    es: string;
    ca: string;
  };
  mensajeFinalizacionOnboarding: {
    es: string;
    ca: string;
  };
  faqs: FaqsApi[];
  tiposContacto: TipoContactoItem[];
}

export const translatableMockString: TranslatableJSON<string> = {
  es: '',
  ca: '',
};
export default class CommunityCustomOnboardingCUPS implements Serializable<CommunityCustomOnboardingCUPS> {
  personalizacionParaApp: boolean;

  personalizacionParaWeb: boolean;

  emailDeContacto: string;

  telefonoDeContacto: string;

  colorPrimario: string;

  colorSecundario: string;

  politicaDePrivacidad: {
    es: string;
    ca: string;
  };

  condicionesOferta: {
    es: string;
    ca: string;
  };

  imagenes: CustomizableImage[];

  // faqs: FaqsApi[];

  horarioContacto: {
    horarios: Array<{
      franja: string;
      horas: string;
      idioma: string;
    }>;
  };

  tituloFinalizacionOnboarding: {
    es: string;
    ca: string;
  };

  mensajeFinalizacionOnboarding: {
    es: string;
    ca: string;
  };

  faqs: FaqsApi[];

  tiposContacto: TipoContactoItem[];

  deserialize(input: CommunityCustomOnboardingCUPSAPII): CommunityCustomOnboardingCUPS {
    if (!input) {
      return this;
    }
    this.personalizacionParaApp = input.personalizacionParaApp === Customizable.SI;
    this.personalizacionParaWeb = input.personalizacionParaWeb === Customizable.SI;
    this.emailDeContacto = input.emailDeContacto;
    this.telefonoDeContacto = input.telefonoDeContacto;
    this.colorPrimario = input.colorPrimario;
    this.colorSecundario = input.colorSecundario;
    this.politicaDePrivacidad = {
      es: input.politicaDePrivacidad.es,
      ca: input.politicaDePrivacidad.ca,
    };
    this.condicionesOferta = {
      es: input.condicionesOferta.es,
      ca: input.condicionesOferta.ca,
    };
    this.imagenes = input.imagenes || [];
    // this.faqs = input.faqs || [];
    this.horarioContacto = input.horarioContacto || '';
    this.tituloFinalizacionOnboarding = {
      es: input.tituloFinalizacionOnboarding.es,
      ca: input.tituloFinalizacionOnboarding.ca,
    };
    this.mensajeFinalizacionOnboarding = {
      es: input.mensajeFinalizacionOnboarding.es,
      ca: input.mensajeFinalizacionOnboarding.ca,
    };
    this.faqs = input.faqs || [];
    this.tiposContacto = input.tiposContacto || [];

    return this;
  }

  serialize(): CommunityCustomOnboardingCUPSAPII {
    return {
      personalizacionParaApp: this.personalizacionParaApp ? Customizable.SI : Customizable.NO,
      personalizacionParaWeb: this.personalizacionParaWeb ? Customizable.SI : Customizable.NO,
      emailDeContacto: this.emailDeContacto,
      telefonoDeContacto: this.telefonoDeContacto,
      colorPrimario: this.colorPrimario,
      colorSecundario: this.colorSecundario,
      politicaDePrivacidad: this.politicaDePrivacidad,
      condicionesOferta: { ca: this.condicionesOferta.ca, es: this.condicionesOferta.es },
      imagenes: this.imagenes,
      horarioContacto: this.horarioContacto,
      tituloFinalizacionOnboarding: {
        ca: this.tituloFinalizacionOnboarding.ca,
        es: this.tituloFinalizacionOnboarding.es,
      },
      mensajeFinalizacionOnboarding: {
        ca: this.mensajeFinalizacionOnboarding.ca,
        es: this.mensajeFinalizacionOnboarding.es,
      },
      faqs: this.faqs,
      tiposContacto: this.tiposContacto,
    } as CommunityCustomOnboardingCUPSAPII;
  }

  setAttr<T>(key: string, value: T): void {
    Object.assign(this, { [key]: value });
  }
}
