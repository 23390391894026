/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { LoadingButton, SectionTitle, Spinner } from 'components';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import usersService from 'services/remote/UsersService';
import { fetchUser, fetchUserSuccess } from 'redux/actions/UsersActions';
import { I18n } from 'core';

import { RolInfo } from 'types/models';
import roleService from 'services/remote/RolesService';
import { useToggle } from 'hooks/useToogle';
import ModalRol from 'components/ModalMatcher/ModalRol';
import { toast } from 'react-toastify';
import styles from './UsuarioRolPage.module.scss';

const UsuarioRolPage = (props: any): JSX.Element => {
  // Props
  const {
    match: {
      params: { userID },
    },
  } = props;

  // Hooks
  const dispatch = useDispatch();
  const [rolData, setRolData] = useState<RolInfo[]>([]);

  // Props
  const [loadingData, setLoadingData] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isOpenModal, setIsOpenModal] = useState<boolean[]>([]);
  const [isEdit, toggleEdit] = useToggle();
  const [rolDetalleData, setDetalleRolData] = useState<RolInfo>();
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);

  useEffect(() => {
    setLoadingData(true);

    dispatch(fetchUser());
    usersService.getUserById(userID).then((response) => {
      dispatch(fetchUserSuccess(response));
    });
    roleService.getRoles().then((response) => {
      setRolData(response);
    });

    usersService.getRolUser(userID).then((response) => {
      const permisosID = response.map((x: any) => x.rolId);
      setSelectedRoles(permisosID);
      setLoadingData(false);
    });
    setLoadingData(false);
  }, []);
  const toggleDropdownModal = (index: number): void => {
    const newIsOpen = [...isOpenModal];
    newIsOpen[index] = !isEdit;
    setIsOpenModal(newIsOpen);
  };

  const handleInputChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedRoles((prevSelectedRoles) => [...prevSelectedRoles, id]); // Agrega solo el ID del rol
    } else {
      setSelectedRoles((prevSelectedRoles) => prevSelectedRoles.filter((roleId) => roleId !== id)); // Filtra el ID del rol
    }
  };

  const UpdateRolUser = async () => {
    setLoading(true);
    setSubmitDisabled(true);

    try {
      await usersService.updateRolPermisos(userID, selectedRoles);
      toast.success(I18n.t('updateSuccess'));
    } catch (error) {
      toast.error(I18n.t('DashboardPage.comunity_fetching_error'));
    } finally {
      setLoading(false);
      setSubmitDisabled(false);
    }
  };

  return (
    <div className={styles.root}>
      {loadingData ? (
        <div className={styles.loading_wrapper}>
          <Spinner icon />
        </div>
      ) : (
        <div>
          <SectionWrapper>
            <SectionTitle text={I18n.t('UsuarioRolPage.title')} />
            <div className={styles.table_wrapper}>
              <table>
                <thead>
                  <tr>
                    <th>{I18n.t('UsuarioRolPage.headers.rol')}</th>
                    <th>{I18n.t('UsuarioRolPage.headers.estado')}</th>
                  </tr>
                </thead>
                <tbody>
                  {(() => {
                    if (rolData && selectedRoles && rolData.length > 0) {
                      return rolData.map((rol) => (
                        <tr>
                          <td
                            onClick={() => {
                              roleService.getRolesById(rol.id.toString()).then((response) => {
                                setDetalleRolData(response);
                              });
                              toggleEdit();
                              toggleDropdownModal(rol.id);
                            }}
                          >
                            {rol.nombre}
                          </td>

                          <td className={styles.checkboxTable}>
                            <input
                              id="seleccionado"
                              type="checkbox"
                              name="seleccionado"
                              checked={selectedRoles.map((role) => role).includes(rol.id)}
                              onChange={(e) => handleInputChangeCheckbox(e, rol.id)}
                            />
                          </td>
                          {rolDetalleData && isOpenModal[rolDetalleData.id] && (
                            <ModalRol
                              title={`${rolDetalleData.nombre}`}
                              body={
                                rolDetalleData.permisos.length === 0
                                  ? I18n.t('UsuarioRolPage.headers.noRole')
                                  : rolDetalleData.permisos.map((roles: any) => <li key={roles.id}>{roles.nombre}</li>)
                              }
                              onClick2={() => {
                                toggleEdit();
                                toggleDropdownModal(rolDetalleData.id);
                              }}
                            />
                          )}
                        </tr>
                      ));
                    }
                    return <p className={styles.nodata}>{I18n.t('UsuarioRolPage.noRole')}</p>;
                  })()}
                </tbody>
              </table>
            </div>
            <div className={styles.saveContainer}>
              <LoadingButton
                type="submit"
                text="Update"
                disabled={submitDisabled}
                loading={loading}
                onClick={UpdateRolUser}
              />
            </div>
          </SectionWrapper>
        </div>
      )}
    </div>
  );
};

export default React.memo(UsuarioRolPage);
