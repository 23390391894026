import React, { useState } from 'react';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import { I18n } from 'core';
import { Colors } from 'styles';
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg';
import PromotorInfo, { PrincipalEnum } from 'types/models/promotorInfo';
import styles from '../UsuarioDetailPage.module.scss';

interface PromotorTableProps {
  CommunitiesPromotor: PromotorInfo[] | null;
  handleCheckboxChange: (disp: PromotorInfo) => void; // Replace YourCommunityType with the actual type
  deleteComunidad: (disp: any) => void; // Replace YourCommunityType with the actual type
}

function PromotorTable({ CommunitiesPromotor, handleCheckboxChange, deleteComunidad }: PromotorTableProps) {
  if (!CommunitiesPromotor) {
    return <FullPageSyncLoader icon />;
  }
  const [checkboxValue, setCheckboxValue] = useState(
    CommunitiesPromotor.map((disp) => disp.principal === PrincipalEnum.si),
  );

  if (CommunitiesPromotor.length === 0) {
    return (
      <table>
        <tbody>
          <tr className={styles.Sinboder}>
            <td className={styles.nodata}>{I18n.t('UsuarioDetailPage.Promotor.noData')}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  return (
    <table>
      <thead>
        <tr>
          <th>{I18n.t('UsuarioDetailPage.Promotor.headers.devices')}</th>
          <th>{I18n.t('UsuarioDetailPage.Promotor.headers.principal')}</th>
        </tr>
      </thead>
      <tbody>
        {CommunitiesPromotor.map((disp, index) => (
          <tr key={disp.cau}>
            <td>{disp.nombre}</td>
            <td className={styles.checkboxTable}>
              <input
                id={`seleccionado-${disp.cau}`}
                type="checkbox"
                checked={checkboxValue[index]}
                onChange={() => {
                  const updatedValues = [...checkboxValue];
                  updatedValues.fill(false); // Establecer todos los valores en false

                  updatedValues[index] = !updatedValues[index];
                  setCheckboxValue(updatedValues);
                  handleCheckboxChange(disp);
                }}
              />
            </td>
            <td onClick={() => deleteComunidad(disp)} role="presentation">
              <TrashIcon height={25} width={25} fill={Colors.COLOR_ELECSUMGREEN} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default React.memo(PromotorTable);
