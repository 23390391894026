import { AxiosResponse } from 'axios';
import configuration from 'config';
import apiService from './ApiService';

export interface SearchItemI {
  tipo: string;
  nombre: string;
  instalacionID: number;
  comunidadID: number;
  cups: string;
  cau: string;
  nif: string;
  email: string;
  domicilio: string;
  poblacion: string;
  provincia: string;
  codigoPostal: string;
  similtud: number;
}

export class SearchService {
  private apiUrl = configuration.apiUrl;

  readonly http = apiService;

  async searchItems(keywords: string): Promise<Array<SearchItemI>> {
    const url = `${this.apiUrl}/fv/utiles/buscador-tecnicos/${keywords}`;

    const response: AxiosResponse<{ datos: Array<SearchItemI> }> = await this.http.get(url);
    return response.data.datos;
  }
}

const searchServiceInstance = new SearchService();

export default searchServiceInstance;
