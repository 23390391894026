import React, { useEffect, useState } from 'react';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import { useParams } from 'react-router-dom';
import MatcherPerfilesConsumoInstance from 'services/remote/MatcherPerfilesConsumoService';
import { toast } from 'react-toastify';
import I18n from 'i18n-js';
import PerfilConsumo from 'types/models/PerfilConsumo';
import { PerfilListTable } from './perfil-list-table/perfil-list-table';
import styles from './PerfileListPage.module.scss';

interface ParameterDetalles {
  solicitudID: string;
}

export const PerfileListPage = (): JSX.Element => {
  const [modifcado, setModificado] = useState('Default');
  const [loadingData, setLoadingData] = useState(false);
  const { solicitudID } = useParams<ParameterDetalles>();
  const [perfilesDeConsumo, setPerfilesDeConsumo] = useState<Array<PerfilConsumo>>([]);

  const getPerfilesConsumo = async (): Promise<void> => {
    setLoadingData(true);
    try {
      const apiData = await MatcherPerfilesConsumoInstance.getAllPerfilesDeConsumo();
      setPerfilesDeConsumo(apiData);
      setModificado('Default');
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        setPerfilesDeConsumo([]);
      } else {
        toast.error(I18n.t('PerfilListPage.perfilesConsumo_fetching_error'));
      }
    }
    setLoadingData(false);
  };

  useEffect(() => {
    getPerfilesConsumo();
  }, [modifcado]);

  const renderTable = () => {
    if (loadingData) {
      return <FullPageSyncLoader icon />;
    }
    return <PerfilListTable data={perfilesDeConsumo} solicitudID={solicitudID} onChange={setModificado} />;
  };

  const containerClass = solicitudID !== undefined ? styles.container : styles.container2;

  return (
    <div className={containerClass}>
      <div className={styles.table_wrapper}>{renderTable()}</div>
    </div>
  );
};
