import React, { useState, useEffect } from 'react';
import { I18n } from 'core';
import {
  EnergyWidget,
  SavingWidget,
  SustainabilityWidget,
  AmortizationWidget,
  SharedEnergyWidget,
  TabsSwitch,
  ContractSummaryWidget,
  Spinner,
} from 'components';
import { clientServiceInstance, CupsInfoValues, cupsServiceInstance } from 'services';
import { EnergyObject, SavingObject, SustainabilityObject } from 'types/models';
import AmortizationObject from 'types/models/widgetsData/amortizationObj';
import SharedEnergyObject from 'types/models/widgetsData/sharedEnergyObj';
import EnergyFotovoltaicCard from 'components/widgets/EnergyFotovoltaicWidget/EnergyFotovoltaicWidget';

import ContractSummary from 'types/models/widgetsData/contractSummaryObj';
import { navigate } from 'navigation';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClientCUPSListSuccessAdmin, updateClientCUPSadmin } from 'redux/actions/ClientCupsActions';
import { GlobalStoreState } from 'types/state';
import { toast } from 'react-toastify';
import Slider from 'components/SliderHorizontal/Slider';

import cx from 'classnames';
import SavingAgregateCardCustom from 'components/widgets/SavingAgregateWidget/SavingAgregateWidgetCustom';
import Images from './images';

import styles from './HomePage.module.scss';

const TABS_AVAILABLE = [
  { key: CupsInfoValues.MINE, label: I18n.t('La meva') },
  { key: CupsInfoValues.COMMUNITY, label: I18n.t('Comunitat') },
];

const HomePageAdmin = (props: any): JSX.Element => {
  // Props
  const {
    match: {
      params: { cupsID },
    },
  } = props;

  // Hooks
  const dispatch = useDispatch();
  const cupsSelected = useSelector((state: GlobalStoreState) => state.clientCUPS.selectedCups);
  const adminCups = useSelector((state: GlobalStoreState) => state.cups.selectedCups);
  const estado = useSelector((state: GlobalStoreState) => state.cups.selectedCupsStatus);
  const [loadingEnergyData, setLoadingEnergyData] = useState<boolean>(false);
  const [energyData, setEnergyData] = useState<EnergyObject | null>(null);
  const [loadinggenerico, setloadinggenerico] = useState<boolean>(false);

  const [savingData, setSavingData] = useState<SavingObject | null>(null);
  const [loadingSavingData, setLoadingSavingData] = useState<boolean>(false);

  const [amortizationData, setAmortizationData] = useState<AmortizationObject | null>(null);
  const [loadingAmortizationData, setLoadingAmortizationData] = useState<boolean>(false);

  const [sustainabilityData, setSustainabilityData] = useState<SustainabilityObject | null>();
  const [loadingSustainabilityData, setLoadingSustainabilityData] = useState<boolean>(false);

  const [sharedEnergyData, setSharedEnergyData] = useState<SharedEnergyObject | null>();
  const [loadingSharedEnergyData, setLoadingSharedEnergyData] = useState<boolean>(false);

  const [contractSummaryData, setContractSummaryData] = useState<ContractSummary | null>();
  const [loadingContractSummaryData, setLoadingContractSummaryData] = useState<boolean>(false);

  const [tabSelected, setTabSelected] = useState(CupsInfoValues.MINE);

  const userLang = navigator.language;
  const lenguageDefault = userLang === 'es';
  const [Tarifa, setAlltarifa] = useState<any>();
  const [loadingData, setloadingData] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    clientServiceInstance
      .getClient(cupsID)
      .then((cliente) => {
        cupsServiceInstance.getCupsFromUserId(cliente.nif).then((response) => {
          if (response.length > 0) {
            const [firstCups] = response;
            if (!cupsID) {
              navigate(`/tecnic/cups/${cupsID}/dashboard`);
            }
            const cupsById = response.find((item) => item.cups === cupsID);
            dispatch(updateClientCUPSadmin(cupsById || firstCups));
            dispatch(fetchClientCUPSListSuccessAdmin(response));
          }
          setAlltarifa(response);
          setloadingData(false);
        });
      })
      .catch(() => {
        toast.error(I18n.t('getEnergyDataError'));
      });
  }, [cupsID]);

  // Detectar cambio de CUPS seleccionado para redirigir a URL correcta
  useEffect(() => {
    if (cupsSelected) {
      if (cupsSelected?.cups !== cupsID) {
        navigate(`/tecnic/cups/${cupsID}/dashboard`);
      }
    }
  }, [cupsSelected]);

  useEffect(() => {
    if (!cupsSelected) return;

    clientServiceInstance
      .getEnergyData(cupsSelected?.cups)
      .then((response) => {
        setEnergyData(response);
        setLoadingEnergyData(false);
      })
      .catch(() => {
        toast.error(I18n.t('getEnergyDataError'));
      });
    clientServiceInstance
      .getSavingData(cupsSelected?.cups)
      .then((response) => {
        response.individual.ahorrosPorMes = response.individual.ahorrosPorMes.filter(
          (item) =>
            item.fecha !== '' ||
            item.ahorro !== 0 ||
            item.importeSinElecsum !== 0 ||
            item.excedente !== 0 ||
            item.autoconsumida !== 0 ||
            item.importeElecsum !== 0 ||
            item.ahorroAcumulado !== 0,
        );
        response.comunity.ahorrosPorMes = response.comunity.ahorrosPorMes.filter(
          (item) =>
            item.fecha !== '' ||
            item.ahorro !== 0 ||
            item.importeSinElecsum !== 0 ||
            item.excedente !== 0 ||
            item.autoconsumida !== 0 ||
            item.importeElecsum !== 0 ||
            item.ahorroAcumulado !== 0,
        );

        setSavingData(response);
        setLoadingSavingData(false);
      })
      .catch(() => {
        toast.error(I18n.t('getSavingDataError'));
      });
    clientServiceInstance
      .getAmortizationData(cupsSelected?.cups)
      .then((response) => {
        setAmortizationData(response);
        setLoadingAmortizationData(false);
      })
      .catch(() => {
        toast.error(I18n.t('getAmortizationDataError'));
      });
    if (estado !== 'STATUS_OK') {
      clientServiceInstance
        .getSustainabilityData(cupsSelected?.cups)
        .then((response) => {
          setSustainabilityData(response);
          setLoadingSustainabilityData(false);
        })
        .catch(() => {
          toast.error(I18n.t('getSustainabilityDataError'));
        });
    }
    clientServiceInstance
      .getSharedEnergyData(cupsSelected?.cups)
      .then((response) => {
        setSharedEnergyData(response);
        setLoadingSharedEnergyData(false);
      })
      .catch(() => {
        toast.error(I18n.t('getSharedEnergyDataError'));
      });
    clientServiceInstance
      .getContractSummary(cupsSelected?.cups)
      .then((response) => {
        setContractSummaryData(response);
        setLoadingContractSummaryData(false);
      })
      .catch(() => {
        toast.error(I18n.t('getSummaryDataError'));
      });
    // clientServiceInstance
    //   .getClient(cupsSelected?.cups)
    //   .then((response) => {
    //     setAlltarifa(response);
    //     setloadingData(false);
    //   })
    //   .catch(() => {
    //     toast.error(I18n.t('getEnergyDataError'));
    //   });
    setLoadingEnergyData(true);
    setLoadingSavingData(true);
    setLoadingAmortizationData(true);
    setLoadingAmortizationData(true);
    setLoadingSharedEnergyData(true);
    setLoadingContractSummaryData(true);
    setloadinggenerico(true);
  }, [cupsSelected]);

  const handleTabClick = (option: any) => {
    setTabSelected(option.key);
  };

  const [fechaMesAñoArrayPositionDad, setFechaMesAñoArrayPositionDad] = useState<number>(0);

  const [fechaMesAñoArrayPositionDadIndividual, setFechaMesAñoArrayPositionDadIndividual] = useState<number>(0);
  // Con esto se inicializa la posición al último del array
  useEffect(() => {
    const ahorrosPorMesLength =
      savingData &&
      savingData.comunity &&
      savingData.comunity.ahorrosPorMes &&
      savingData.comunity.ahorrosPorMes.length;
    setFechaMesAñoArrayPositionDad(ahorrosPorMesLength ? ahorrosPorMesLength - 1 : 0);
    const ahorrosPorMesLengthIndividual =
      savingData &&
      savingData.individual &&
      savingData.individual.ahorrosPorMes &&
      savingData.individual.ahorrosPorMes.length;
    setFechaMesAñoArrayPositionDadIndividual(ahorrosPorMesLengthIndividual ? ahorrosPorMesLengthIndividual - 1 : 0);
  }, [savingData]);

  const handleDateChange = (newDate: Date, fechaMesAñoArrayPosition: number) => {
    setFechaMesAñoArrayPositionDad(fechaMesAñoArrayPosition);
  };

  const handleDateChangeIndividual = (newDate: Date, fechaMesAñoArrayPosition: number) => {
    setFechaMesAñoArrayPositionDadIndividual(fechaMesAñoArrayPosition);
  };

  const CommunityView = (): JSX.Element => (
    <div className={styles.container}>
      {/* WIDGET ENERGIA */}
      {(() => {
        if (loadingEnergyData) {
          return (
            <div className={styles.energy_widget}>
              <Spinner icon />
            </div>
          );
        }
        if (energyData && energyData.showWidget) {
          return (
            <div className={cx(styles.energy_widget, { [styles.expand]: cupsSelected?.fotovoltaica === 'si' })}>
              <EnergyWidget
                fotovoltaic={cupsSelected?.fotovoltaica || 'no'}
                date={energyData.comunity.date}
                generationData={energyData.comunity.generation}
                shrinkData={energyData.comunity.shrink}
                networkData={energyData.comunity.network}
                selfConsumptionData={energyData.comunity.selfconsumption}
                consumptionData={energyData.comunity.consumption}
              />
            </div>
          );
        }
        return null;
      })()}

      {/* WIDGET ENERGIA FV */}
      {(() => {
        if (loadingEnergyData) {
          return (
            <div className={styles.energy_fv_widget}>
              <Spinner icon />
            </div>
          );
        }

        if (energyData && energyData.showWidget && cupsSelected?.fotovoltaica === 'si') {
          return (
            <div className={styles.energy_fv_widget}>
              <EnergyFotovoltaicCard
                date={energyData.comunity.date}
                generationData={energyData.comunity.generation}
                shrinkData={energyData.comunity.shrink}
                networkData={energyData.comunity.network}
                selfConsumptionData={energyData.comunity.selfconsumption}
                consumptionData={energyData.comunity.consumption}
              />
            </div>
          );
        }
        return null;
      })()}

      {/* WIDGET AHORRO */}
      {(() => {
        if (loadingSavingData) {
          return (
            <div className={styles.saving_widget}>
              <Spinner icon />
            </div>
          );
        }
        if (savingData && savingData.showWidget && savingData.comunity.ahorrosPorMes.length > 0) {
          const savingDataComunidad = savingData.comunity.ahorrosPorMes[fechaMesAñoArrayPositionDad];
          return (
            <div className={styles.saving_widget}>
              <SavingWidget
                isClient={savingData.isClient}
                comercializado={savingData.comercializado}
                date={savingDataComunidad.fecha}
                savingData={savingDataComunidad.ahorro}
                costWithoutElecsumData={savingDataComunidad.importeSinElecsum}
                shrinkingData={savingDataComunidad.excedente}
                selfConsumptionData={savingDataComunidad.autoconsumida}
                acumulatedSaving={savingDataComunidad.ahorroAcumulado}
                costWithElecsumData={savingDataComunidad.importeElecsum}
                startDate={savingData.comunity.ahorroAcumuladoInicio}
                finalDate={savingData.comunity.ahorroAcumuladoFin}
                onDateChange={handleDateChange}
                maxArrayPosition={savingData.comunity.ahorrosPorMes.length - 1}
                fechaMesAñoArrayPositionDad={fechaMesAñoArrayPositionDad}
              />
            </div>
          );
        }
        return null;
      })()}

      {/* WIDGET AHORRO AGREGADO */}
      {/* {(() => {
        if (loadingSavingData) {
          return (
            <div className={styles.saving_agregate_widget}>
              <Spinner icon />
            </div>
          );
        }
        if (
          savingData &&
          savingData.comunity.ahorrosPorMes &&
          savingData.comunity.ahorrosPorMes.length > 0 &&
          savingData.comunity.ahorrosPorMes[fechaMesAñoArrayPositionDad].ahorro === 0 &&
          savingData.comunity.ahorrosPorMes[fechaMesAñoArrayPositionDad].importeSinElecsum === 0 &&
          savingData.comunity.ahorrosPorMes[fechaMesAñoArrayPositionDad].excedente === 0 &&
          savingData.comunity.ahorrosPorMes[fechaMesAñoArrayPositionDad].autoconsumida === 0 &&
          savingData.comunity.ahorrosPorMes[fechaMesAñoArrayPositionDad].importeElecsum === 0
        ) {
          const lastValue = savingData.comunity.ahorrosPorMes[savingData.comunity.ahorrosPorMes.length - 1];
          if (lastValue && lastValue.ahorroAcumulado !== undefined) {
            return (
              <div className={styles.saving_agregate_widget}>
                <SavingAgregateWidget
                  acumulatedSaving={lastValue.ahorroAcumulado}
                  savingArray={transformDataSavingArray(savingData.comunity.ahorrosPorMes)}
                  startDate={savingData.comunity.ahorroAcumuladoInicio}
                  finalDate={savingData.comunity.ahorroAcumuladoFin}
                />
              </div>
            );
          }
        }
        return null;
      })()} */}

      {/* WIDGET AHORRO AGREGADO */}

      {(() => {
        if (loadingSavingData) {
          return (
            <div className={styles.saving_agregate_widget}>
              <Spinner icon />
            </div>
          );
        }

        let acumulatedSaving = 0;
        let startDate = '';

        if (
          savingData &&
          savingData.comunity &&
          savingData.comunity.ahorrosPorMes &&
          savingData.comunity.ahorrosPorMes.length > 0
        ) {
          const lastValue = savingData.comunity.ahorrosPorMes[savingData.comunity.ahorrosPorMes.length - 1];
          acumulatedSaving = lastValue.ahorroAcumulado;
          startDate = savingData.comunity.ahorroAcumuladoInicio;
        } else {
          const date = new Date();
          startDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
        }

        return (
          <div className={styles.saving_agregate_widget}>
            <SavingAgregateCardCustom acumulatedSaving={acumulatedSaving} startDate={startDate} />
          </div>
        );
      })()}
      {/* WIDGET AMORTIZACION */}
      {/* {(() => {
        if (loadingAmortizationData) {
          return (
            <div className={styles.amortization_widget}>
              <Spinner icon />
            </div>
          );
        }
        if (amortizationData && amortizationData.showWidget) {
          return (
            <div className={styles.amortization_widget}>
              <AmortizationWidget
                totalData={amortizationData.comunity.total}
                amortizedData={parseInt(amortizationData.comunity.amortized, 10)}
                percentAmortized={amortizationData.comunity.amortizedPercentage}
                duration={amortizationData.duration}
              />
            </div>
          );
        }
        return null;
      })()} */}

      {/* WIDGET SOSTENIBILIDAD */}
      {(() => {
        if (loadingSustainabilityData) {
          return (
            <div className={styles.sustainability_widget}>
              <Spinner icon />
            </div>
          );
        }
        if (sustainabilityData) {
          return (
            <div className={styles.sustainability_widget}>
              <SustainabilityWidget
                cups={cupsID}
                title="sustainability"
                subtitle={I18n.t('totalAccumulated')}
                noEmmitedData={sustainabilityData.comunity.co2}
                noEmmitedUnit="kg CO2"
                treesData={sustainabilityData.comunity.trees}
                viewMore
              />
            </div>
          );
        }
        return null;
      })()}

      {/* WIDGET GENERACION REPARTIDA */}
      {(() => {
        if (loadingSharedEnergyData) {
          return (
            <div className={styles.shared_widget}>
              <Spinner icon />
            </div>
          );
        }
        if (sharedEnergyData && sharedEnergyData.showWidget) {
          return (
            <div className={styles.shared_widget}>
              <SharedEnergyWidget
                powerInstallation={sharedEnergyData.powerInstallation}
                totalGeneration={sharedEnergyData.totalGeneration}
                members={sharedEnergyData.members}
              />
            </div>
          );
        }
        return null;
      })()}
    </div>
  );

  const MineView = (): JSX.Element => (
    <div className={styles.container}>
      {/* WIDGET ENERGIA */}
      {(() => {
        if (loadingEnergyData) {
          return (
            <div className={styles.energy_widget}>
              <Spinner icon />
            </div>
          );
        }
        if (energyData && cupsSelected && energyData.showWidget) {
          return (
            <div className={cx(styles.energy_widget, { [styles.expand]: cupsSelected?.fotovoltaica === 'si' })}>
              <EnergyWidget
                fotovoltaic={cupsSelected.fotovoltaica || 'no'}
                date={energyData.individual.date}
                generationData={energyData.individual.generation}
                shrinkData={energyData.individual.shrink}
                networkData={energyData.individual.network}
                selfConsumptionData={energyData.individual.selfconsumption}
                consumptionData={energyData.individual.consumption}
              />
            </div>
          );
        }
        return null;
      })()}

      {(() => {
        if (loadingEnergyData) {
          return (
            <div className={styles.energy_fv_widget}>
              <Spinner icon />
            </div>
          );
        }
        if (cupsSelected && cupsSelected.cups && cupsSelected.fotovoltaica === 'si') {
          if (energyData && energyData.showWidget) {
            return (
              <div className={styles.energy_fv_widget}>
                <EnergyFotovoltaicCard
                  date={energyData.individual.date}
                  generationData={energyData.individual.generation}
                  shrinkData={energyData.individual.shrink}
                  networkData={energyData.individual.network}
                  selfConsumptionData={energyData.individual.selfconsumption}
                  consumptionData={energyData.individual.consumption}
                />
              </div>
            );
          }
        }
        return null;
      })()}

      {(() => {
        if (loadingSavingData) {
          return (
            <div className={styles.saving_widget}>
              <Spinner icon />
            </div>
          );
        }
        if (savingData && savingData.showWidget && savingData.individual.ahorrosPorMes.length > 0) {
          const savingDataIndividual = savingData.individual.ahorrosPorMes[fechaMesAñoArrayPositionDadIndividual];
          return (
            <div className={styles.saving_widget}>
              <SavingWidget
                comercializado={savingData.comercializado}
                isClient={savingData.isClient}
                date={savingDataIndividual.fecha}
                savingData={savingDataIndividual.ahorro}
                costWithoutElecsumData={savingDataIndividual.importeSinElecsum}
                shrinkingData={savingDataIndividual.excedente}
                selfConsumptionData={savingDataIndividual.autoconsumida}
                costWithElecsumData={savingDataIndividual.importeElecsum}
                acumulatedSaving={savingDataIndividual.ahorroAcumulado}
                startDate={savingData.individual.ahorroAcumuladoInicio}
                finalDate={savingData.individual.ahorroAcumuladoFin}
                onDateChange={handleDateChangeIndividual}
                maxArrayPosition={savingData.individual.ahorrosPorMes.length - 1}
                fechaMesAñoArrayPositionDad={fechaMesAñoArrayPositionDadIndividual}
              />
            </div>
          );
        }
        return null;
      })()}

      {(() => {
        if (loadingSavingData) {
          return (
            <div className={styles.saving_agregate_widget}>
              <Spinner icon />
            </div>
          );
        }

        let acumulatedSaving = 0;
        let startDate = '';

        if (
          savingData &&
          savingData.individual &&
          savingData.individual.ahorrosPorMes &&
          savingData.individual.ahorrosPorMes.length > 0
        ) {
          const lastValue = savingData.individual.ahorrosPorMes[savingData.individual.ahorrosPorMes.length - 1];
          acumulatedSaving = lastValue.ahorroAcumulado;
          startDate = savingData.individual.ahorroAcumuladoInicio;
        } else {
          const date = new Date();
          startDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
        }

        return (
          <div className={styles.saving_agregate_widget}>
            <SavingAgregateCardCustom acumulatedSaving={acumulatedSaving} startDate={startDate} />
          </div>
        );
      })()}

      {(() => {
        if (loadingAmortizationData) {
          return (
            <div className={styles.amortization_widget}>
              <Spinner icon />
            </div>
          );
        }
        if (amortizationData && amortizationData.showWidget) {
          return (
            <div className={styles.amortization_widget}>
              <AmortizationWidget
                totalData={amortizationData.individual.total}
                amortizedData={parseInt(amortizationData.individual.amortized, 10)}
                percentAmortized={amortizationData.individual.amortizedPercentage}
                duration={amortizationData.duration}
              />
            </div>
          );
        }
        return null;
      })()}

      {(() => {
        if (loadingSustainabilityData) {
          return (
            <div className={styles.sustainability_widget}>
              <Spinner icon />
            </div>
          );
        }
        if (!adminCups?.nif) {
          if (sustainabilityData) {
            return (
              <div className={styles.sustainability_widget}>
                <SustainabilityWidget
                  cups={cupsID}
                  title="sustainability"
                  subtitle={I18n.t('totalAccumulated')}
                  noEmmitedData={sustainabilityData.individual.co2}
                  noEmmitedUnit="kg CO2"
                  treesData={sustainabilityData.individual.trees}
                  viewMore
                />
              </div>
            );
          }
          return null;
        }
        return null;
      })()}
      {(() => {
        if (loadingData) {
          return (
            <div className={styles.sustainability_widget}>
              <Spinner icon />
            </div>
          );
        }
        if (Tarifa && Tarifa.tarifaComercializadora) {
          return (
            <div className={styles.energy_fv_widget}>
              {Tarifa.tarifaComercializadora === 'FIX' ? (
                <Slider
                  images={lenguageDefault === true ? Images.plaFlix.es : Images.plaFlix.cat}
                  Titulo={Tarifa.tarifaComercializadora}
                />
              ) : null}
              {Tarifa.tarifaComercializadora === 'FLEXA2' ? (
                <Slider
                  images={lenguageDefault === true ? Images.plaFlexa2.es : Images.plaFlexa2.cat}
                  Titulo={Tarifa.tarifaComercializadora}
                />
              ) : null}
              {Tarifa.tarifaComercializadora === 'FLEXA3' ? (
                <Slider
                  images={lenguageDefault === true ? Images.platFlexa3.es : Images.platFlexa3.cat}
                  Titulo={Tarifa.tarifaComercializadora}
                />
              ) : null}
            </div>
          );
        }
        return null;
      })()}
      {(() => {
        if (loadingContractSummaryData) {
          return (
            <div className={styles.summary_widget}>
              <Spinner icon />
            </div>
          );
        }
        if (contractSummaryData) {
          return (
            <div
              className={cx(
                styles.summary_widget,
                {
                  [styles.expand_mid]: contractSummaryData.instalaciones
                    ? contractSummaryData.instalaciones.length > 0
                    : false,
                },
                {
                  [styles.expand_big]:
                    [
                      contractSummaryData.potencias.potencia1 && 1,
                      contractSummaryData.potencias.potencia2 && 1,
                      contractSummaryData.potencias.potencia3 && 1,
                      contractSummaryData.potencias.potencia4 && 1,
                      contractSummaryData.potencias.potencia5 && 1,
                      contractSummaryData.potencias.potencia6 && 1,
                    ].reduce((a, b) => a + b, 0) > 2 && contractSummaryData.instalaciones?.length === 0,
                },
                {
                  [styles.expand_extra]:
                    [
                      contractSummaryData.potencias.potencia1 && 1,
                      contractSummaryData.potencias.potencia2 && 1,
                      contractSummaryData.potencias.potencia3 && 1,
                      contractSummaryData.potencias.potencia4 && 1,
                      contractSummaryData.potencias.potencia5 && 1,
                      contractSummaryData.potencias.potencia6 && 1,
                    ].reduce((a, b) => a + b, 0) > 3,
                },
              )}
            >
              <ContractSummaryWidget
                instalaciones={contractSummaryData.instalaciones || []}
                codigoCliente={contractSummaryData.codigoCliente}
                tarifa={contractSummaryData.tarifa}
                tarifaComercializadora={contractSummaryData.tarifaComercializadora}
                porcentajeExencionImpuesto={contractSummaryData.porcentajeExencionImpuesto}
                porcentajeElectricidadBonificacion={contractSummaryData.porcentajeElectricidadBonificacion}
                p1={contractSummaryData.potencias.potencia1}
                p2={contractSummaryData.potencias.potencia2}
                p3={contractSummaryData.potencias.potencia3}
                p4={contractSummaryData.potencias.potencia4}
                p5={contractSummaryData.potencias.potencia5}
                p6={contractSummaryData.potencias.potencia6}
                eolica={contractSummaryData.tecnologia.eolica === 'si'}
                fotovoltaica={contractSummaryData.tecnologia.fotovoltaica === 'si'}
                comercializado={contractSummaryData.comercializado === 'si'}
              />
            </div>
          );
        }
        return null;
      })()}
    </div>
  );

  return (
    <div>
      {loadinggenerico ? (
        <div>
          {cupsSelected?.esComunidad === 'si' && (
            <TabsSwitch options={TABS_AVAILABLE} selected={tabSelected} onTabClick={handleTabClick} />
          )}
          {tabSelected === CupsInfoValues.MINE && <MineView />}
          {tabSelected === CupsInfoValues.COMMUNITY && cupsSelected?.esComunidad === 'si' && <CommunityView />}
        </div>
      ) : (
        <div className={styles.spinnerGeneric}>
          <Spinner icon />
        </div>
      )}
    </div>
  );
};

export default HomePageAdmin;
