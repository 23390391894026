import React, { useEffect, useState } from 'react';
import { DateIntervalPicker, DropdownBase } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCUPS, fetchCUPSSuccess } from 'redux/actions/CupsActions';
import { clientServiceInstance } from 'services';
import CIMServiceInstance from 'services/remote/CIMService';
import MeasuremenRegistersObject from 'types/models/widgetsCIM/measurementsRegistersObj';
import moment from 'moment';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import { I18n } from 'core';
import { MeasurementRegistersMaximeter } from 'types/models/widgetsCIM';
import { useRole } from 'hooks';
import { Roles } from 'types/enums';
import { navigate } from 'navigation';
import { GlobalStoreState } from 'types/state';
import MeasurementMaximeterChart from './MeasurementMaximeterChart';
import MeasurementChart from './MeasurementChart';
import styles from './CIMMeasurementRegistersPage.module.scss';

interface DateInterval {
  start: moment.Moment;
  end: moment.Moment;
}

const typeOptions = [
  { id: 'agregado', label: I18n.t('CIM.MeasurementRegistersPage.agregated'), value: 'agregado' },
  { id: 'maximetro', label: I18n.t('CIM.MeasurementRegistersPage.maximeter'), value: 'maximetro' },
];

const groupingOptions = [
  { id: 'todo', label: I18n.t('CIM.MeasurementRegistersPage.all'), value: 'todo' },
  { id: 'hora', label: I18n.t('CIM.MeasurementRegistersPage.hour'), value: 'hora' },
  { id: 'dia', label: I18n.t('CIM.MeasurementRegistersPage.day'), value: 'dia' },
  { id: 'semana', label: I18n.t('CIM.MeasurementRegistersPage.week'), value: 'semana' },
  { id: 'mes', label: I18n.t('CIM.MeasurementRegistersPage.month'), value: 'mes' },
];

const CIMMeasurementRegistersPage = (props: any): JSX.Element => {
  // Props
  const {
    match: {
      params: { cupsID },
    },
  } = props;

  // State
  const [dateInterval, setDateInterval] = useState<DateInterval>({
    start: moment().subtract(1, 'months'),
    end: moment(),
  });
  const [grouping, setGrouping] = useState(groupingOptions[0]);
  const [chartType, setChartType] = useState(typeOptions[0]);
  const [chartData, setChartData] = useState<MeasuremenRegistersObject | null>(null);
  const [chartMaximeterData, setChartMaximeterData] = useState<MeasurementRegistersMaximeter | null>(null);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  // Hooks
  const dispatch = useDispatch();
  const role = useRole();
  const cupsSelected = useSelector((state: GlobalStoreState) => state.clientCUPS.selectedCups);

  useEffect(() => {
    dispatch(fetchCUPS());
    clientServiceInstance.getClient(cupsID).then((response) => {
      response.setAttr('cups', cupsID);
      dispatch(fetchCUPSSuccess(response));
    });
  }, [cupsID]);

  // useEffect(() => {
  //   if (role !== Roles.CLIENT) return;
  //   if (!authServiceInstance.user?.nif) return;
  //   cupsServiceInstance.getCupsFromUserId(authServiceInstance.user?.nif).then((response) => {
  //     if (response.length > 0) {
  //       const [firstCups] = response;
  //       if (!cupsID) {
  //         navigate(`/client/${firstCups.cups}/measurement-registers`);
  //       }
  //       const cupsById = response.find((item) => item.cups === cupsID);
  //       dispatch(updateClientCUPS(cupsById || firstCups));
  //       dispatch(fetchClientCUPSListSuccess(response));
  //     }
  //   });
  // }, [cupsID, role]);

  useEffect(() => {
    if (role !== Roles.CLIENT) return;
    if (cupsSelected) {
      if (cupsSelected.cups !== cupsID) {
        navigate(`/client/cim/${cupsSelected.cups}/measurement-registers`);
      }
    }
  }, [cupsSelected, role]);

  useEffect(() => {
    dispatch(fetchCUPS());
    clientServiceInstance.getClient(cupsID).then((response) => {
      response.setAttr('cups', cupsID);
      dispatch(fetchCUPSSuccess(response));
    });
  }, [cupsID]);

  useEffect(() => {
    if (chartType.id !== 'agregado') return;
    if (!cupsID) return;
    if (!dateInterval.end || !dateInterval.start) return;
    setLoadingData(true);
    CIMServiceInstance.getMeasurementRegisters(
      cupsID,
      dateInterval.start.format('YYYY/MM/DD'),
      dateInterval.end.format('YYYY/MM/DD'),
      grouping.value,
    ).then((response: MeasuremenRegistersObject) => {
      setLoadingData(false);
      setChartData(response);
    });
  }, [cupsID, dateInterval, grouping, chartType]);

  useEffect(() => {
    setLoadingData(true);
    if (chartType.id !== 'maximetro') return;
    if (!cupsID) return;
    if (!dateInterval.end || !dateInterval.start) return;
    CIMServiceInstance.getMeasurementRegistersMaximeter(
      cupsID,
      dateInterval.start.format('YYYY/MM/DD'),
      dateInterval.end.format('YYYY/MM/DD'),
    ).then((response: MeasurementRegistersMaximeter) => {
      setLoadingData(false);
      setChartMaximeterData(response);
    });
  }, [cupsID, dateInterval, grouping, chartType]);

  const handleChangeDate = (dates: DateInterval) => {
    setDateInterval(dates);
  };

  return (
    <div className={styles.root}>
      <div className={styles.menu}>
        <div className={styles.types}>
          <DropdownBase
            fullWidth
            label={I18n.t('CIM.MeasurementRegistersPage.typeLabel')}
            value={chartType}
            onChange={(element: any) => {
              setChartType(element);
            }}
            values={typeOptions}
          />
        </div>
        <div className={styles.intervalPicker}>
          <DateIntervalPicker
            onChangeDates={handleChangeDate}
            datesInterval={dateInterval}
            label={I18n.t('CIM.MeasurementRegistersPage.intervalLabel')}
          />
        </div>
        {chartType.id === 'agregado' ? (
          <div className={styles.grouping}>
            <DropdownBase
              fullWidth
              label={I18n.t('CIM.MeasurementRegistersPage.grouping')}
              value={grouping}
              onChange={(element: any) => {
                setGrouping(element);
              }}
              values={groupingOptions}
            />
          </div>
        ) : null}
      </div>

      {chartType.value === 'agregado' ? (
        <div className={styles.chartWrapper}>
          {(() => {
            if (loadingData) {
              return (
                <>
                  <FullPageSyncLoader icon />
                </>
              );
            }
            if (!chartData) return null;
            return <MeasurementChart data={chartData} />;
          })()}
        </div>
      ) : null}
      {chartType.value === 'maximetro' ? (
        <div className={styles.chartWrapper}>
          {(() => {
            if (loadingData) {
              return <FullPageSyncLoader icon />;
            }
            if (!chartMaximeterData) return null;
            return <MeasurementMaximeterChart data={chartMaximeterData} />;
          })()}
        </div>
      ) : null}
    </div>
  );
};

export default CIMMeasurementRegistersPage;
