import { Client } from 'types/models';
import { CUPS_ACTIONS } from './types';

const fetchCUPS = () => ({ type: CUPS_ACTIONS.FETCH_CUPS });

const fetchCUPSSuccess = (payload: Client) => ({
  type: CUPS_ACTIONS.FETCH_CUPS_OK,
  payload,
});

const fetchCUPSError = (payload: string) => ({
  type: CUPS_ACTIONS.FETCH_CUPS_ERROR,
  payload,
});

const resetCUPS = () => ({ type: CUPS_ACTIONS.RESET_STATE });

export { fetchCUPS, fetchCUPSSuccess, fetchCUPSError, resetCUPS };
