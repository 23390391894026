interface AcummulatedSavingDataI {
  x: number;
  y: number;
}

interface SavingDataAPII {
  fecha: string;
  ahorro: number;
  importeSinElecsum: number;
  excedente: number;
  autoconsumida: number;
  importeElecsum: number;
  ahorroAcumulado: number;
  ahorroAcumuladoGrafico: Array<AcummulatedSavingDataI>;
  ahorroAcumuladoInicio: string;
  ahorroAcumuladoFin: string;
}

export default class SavingDataPromotor {
  date: string;

  saving: number;

  costWithoutElecsum: number;

  shrink: number;

  selfConsumption: number;

  elecsumCost: number;

  acumulatedSaving: number;

  acummulatedSavingData: Array<AcummulatedSavingDataI>;

  acummulatedSavingStart: string;

  acummulatedSavingEnd: string;

  deserialize(input: SavingDataAPII): SavingDataPromotor {
    if (!input) {
      return this;
    }

    this.date = input.fecha;
    this.saving = input.ahorro;
    this.costWithoutElecsum = input.importeSinElecsum;
    this.shrink = input.excedente;
    this.selfConsumption = input.autoconsumida;
    this.elecsumCost = input.importeElecsum;
    this.acumulatedSaving = input.ahorroAcumulado;
    this.acummulatedSavingData = input.ahorroAcumuladoGrafico;
    this.acummulatedSavingStart = input.ahorroAcumuladoInicio;
    this.acummulatedSavingEnd = input.ahorroAcumuladoFin;

    return this;
  }
}
