import { DateIntervalPicker } from 'components';

import Button from 'components/Button/Button';
import { I18n } from 'core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clientServiceInstance } from 'services';
import { fetchCUPS, fetchCUPSSuccess } from 'redux/actions/CupsActions';
import CIMServiceInstance from 'services/remote/CIMService';
import moment from 'moment';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import { toast } from 'react-toastify';
import { useRole } from 'hooks';
import { Roles } from 'types/enums';
import { navigate } from 'navigation';

import { GlobalStoreState } from 'types/state';
import styles from './CIMDataExportPage.module.scss';

interface DateInterval {
  start: moment.Moment;
  end: moment.Moment;
}
const CIMDataExportPage = (props: any): JSX.Element => {
  // Props
  const {
    match: {
      params: { cupsID },
    },
  } = props;

  // Hooks
  const dispatch = useDispatch();
  const role = useRole();
  const cupsSelected = useSelector((state: GlobalStoreState) => state.clientCUPS.selectedCups);

  // State
  const [dateInterval, setDateInterval] = useState<DateInterval>({
    start: moment().subtract(1, 'months'),
    end: moment(),
  });
  const [loading, setLoading] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    dispatch(fetchCUPS());
    clientServiceInstance.getClient(cupsID).then((response) => {
      response.setAttr('cups', cupsID);
      dispatch(fetchCUPSSuccess(response));
    });
  }, [cupsID]);

  // useEffect(() => {
  //   if (role !== Roles.CLIENT) return;
  //   if (!authServiceInstance.user?.nif) return;
  //   cupsServiceInstance.getCupsFromUserId(authServiceInstance.user?.nif).then((response) => {
  //     if (response.length > 0) {
  //       const [firstCups] = response;
  //       if (!cupsID) {
  //         navigate(`/client/${firstCups.cups}/data-export`);
  //       }
  //       const cupsById = response.find((item) => item.cups === cupsID);
  //       dispatch(updateClientCUPS(cupsById || firstCups));
  //       dispatch(fetchClientCUPSListSuccess(response));
  //     }
  //   });
  // }, [cupsID, role]);

  useEffect(() => {
    if (role !== Roles.CLIENT) return;
    if (cupsSelected) {
      if (cupsSelected.cups !== cupsID) {
        navigate(`/client/cim/${cupsSelected.cups}/data-export`);
      }
    }
  }, [cupsSelected, role]);

  const handleSubmit = () => {
    if (!cupsID) return;
    setLoading(true);
    CIMServiceInstance.exportConsumosData({
      cups: cupsID,
      fechaInicio: dateInterval.start?.format('YYYY/MM/DD'),
      fechaFin: dateInterval.end?.format('YYYY/MM/DD'),
    })
      .then((response) => {
        const { data } = response;
        const a = document.createElement('a');
        a.href = `data:text/csv;charset=utf-8,${data}`;
        a.textContent = 'download';
        a.download = `${new Date().toLocaleDateString()}_ExportData.csv`;
        a.click();
        a.remove();
        setLoading(false);
      })
      .catch(() => {
        toast.error(I18n.t('CIM.DataExportPage.errorFetching'));
      });
  };

  const handleChangeDate = (dates: DateInterval) => {
    setDateInterval(dates);
  };

  return (
    <div className={styles.root}>
      <div className={styles.menu}>
        <DateIntervalPicker
          onChangeDates={handleChangeDate}
          datesInterval={dateInterval}
          label={I18n.t('CIM.DataExportPage.picker_label')}
        />
        <div className={styles.export_button}>
          <Button onClick={() => handleSubmit()} type="button" text="EXPORTAR EN .CSV" variant="primary" />
        </div>
      </div>
      <div>{loading ? <FullPageSyncLoader icon /> : null}</div>
    </div>
  );
};

export default CIMDataExportPage;
