import React from 'react';
import { ReactComponent as CupsIcon } from 'assets/icons/SmartMeter.svg';
import { Colors } from 'styles';
import { navigate } from 'navigation';
import styles from './SearchItems.module.scss';

interface CupsSearchItemPropsI {
  nombre: string;
  cups: string;
  direccion: string;
  ciudad?: string;
  provincia?: string;
  cp?: string;
  pais?: string;
}

const CupsSearchItem = (props: CupsSearchItemPropsI): JSX.Element => {
  const { nombre, cups, direccion, ciudad, provincia, cp } = props;

  const handleClick = (): void => {
    navigate(`/tecnic/cups/${cups}/detail`);
  };

  const getFormattedAddress = (): string =>
    `${direccion}, ${ciudad} ${provincia ? ',' : ''} ${provincia} ${cp ? ',' : ''} ${cp}`;
  return (
    <button onClick={() => handleClick()} type="button" className={styles.root}>
      <div className={styles.icon_wrapper}>
        <CupsIcon height={25} width={25} fill={Colors.COLOR_ELECSUMGREEN} />
      </div>
      <div className={styles.info_wrapper}>
        <p className={styles.title}>{nombre}</p>
        <p className={styles.subtitle}>{cups}</p>
        <p className={styles.information}>{getFormattedAddress()}</p>
      </div>
    </button>
  );
};

export default React.memo(CupsSearchItem);
