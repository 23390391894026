import { SearchInput } from 'components/search-input';
import React from 'react';
import I18n from 'i18n-js';
import styles from './communities-enrolled-list-filters.module.scss';

interface FilterText {
  handleChange(value: any): void;
}

export const CommunitiesEnrolledListFilters = (props: FilterText): JSX.Element => {
  const { t } = I18n;
  const { handleChange } = props;

  return (
    <div className={styles.container}>
      <div className={styles.left_wrapper}>
        <p className={styles.title}>{t(`ComunitiesEnrolledListPage.title`)}</p>
        <SearchInput
          placeholder={t(`ComunitiesEnrolledListPage.searchPlaceholder`)}
          onChange={(event) => {
            handleChange(event.target.value);
          }}
        />
      </div>
    </div>
  );
};
