// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DataTable, SectionTitle, TableHeading } from 'components';

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { FormGroup, FormGroupProps, I18n } from 'core';
import { ReactComponent as DocumentsIcon } from 'assets/icons/Documents.svg';

import { Colors } from 'styles';

import { LoggingService } from 'services';
import CommunityCustomOnboarding from 'types/models/communityCustomOnboarding';
import { ToolsBarWithoutUrl } from 'pages/ModeTecnic/rol-comunidades/comunidades-detalle-page/tools-bar-without-url/tools-bar-without-url';
import { translatableLangs } from 'config/translatable-langs';
import { SelectItemI } from 'components/NewInputs/Select/ElecsumSelect';
import styles from './comunidades-custom-onboarding-oferta.module.scss';
import { SaveDataHandle } from '../../comunidades-custom-onboarding-process-page';
// import { ColorPicker } from '../comunidades-color-picker/comunidades-color-picker';

interface ComunidadesCustomOnboardingOfertaProps {
  communityId: string;
  communityCustomOnboarding: CommunityCustomOnboarding;
  saveDetails: (customOnboarding: CommunityCustomOnboarding) => void;
}
const ComunidadesCustomOnboardingOferta = forwardRef<SaveDataHandle, ComunidadesCustomOnboardingOfertaProps>(
  (props: ComunidadesCustomOnboardingOfertaProps, ref) => {
    const { communityCustomOnboarding, saveDetails } = props;
    const [customOnboarding, setCustomOnboarding] = useState<CommunityCustomOnboarding>();
    const [errors, setErrors] = useState({});
    const customOnboardingData = JSON.parse(JSON.stringify(communityCustomOnboarding.serialize()));
    const [currentLang, setCurrentLang] = useState<SelectItemI>(translatableLangs[0]);
    const [textoCa, setextoCa] = useState<string>(customOnboardingData.condicionesOferta.ca || '');
    const [textoEs, setextoEs] = useState<string>(customOnboardingData?.condicionesOferta.es || '');
    const validateForm = (): boolean => {
      setErrors({});
      return true;
    };

    function handleChange<T>(path: string, value: T): void {
      let x = {
        es: textoEs,
        ca: textoCa,
      };

      if (currentLang.id === 'es') {
        x = {
          es: value as unknown as string,
          ca: textoCa,
        };
        setextoEs(x.es);
        communityCustomOnboarding.setAttr(path, x);

        setCustomOnboarding(
          new CommunityCustomOnboarding().deserialize({
            ...customOnboardingData,
            [path]: x,
          }),
        );
      } else {
        x = {
          es: textoEs,
          ca: value as unknown as string,
        };
        communityCustomOnboarding.setAttr(path, x);

        setextoCa(x.ca);
        setCustomOnboarding(
          new CommunityCustomOnboarding().deserialize({
            ...customOnboardingData,
            [path]: x,
          }),
        );
      }
    }

    const handleSubmit = (): void => {
      if (validateForm() && customOnboarding) {
        LoggingService.log('handleSubmit :: ok', { client: customOnboarding });
        saveDetails(customOnboarding);
      } else {
        LoggingService.log('handleSubmit :: errors', { client: customOnboarding, errors });
      }
    };
    const formFields: Array<FormGroupProps> = [
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.ofertaTitle'),
        type: 'textarea',
        id: 'condicionesOferta',
        path: 'condicionesOferta',
        name: 'condicionesOferta',
        placeholder: I18n.t('ComunidadesCustomOnboardingProcess.form.ofertaTitle'),
        value:
          currentLang.id === 'es'
            ? customOnboardingData.condicionesOferta.es
            : customOnboardingData.condicionesOferta.ca,
      },
    ];

    useImperativeHandle(ref, () => ({
      getData: () => {
        handleSubmit();
      },
    }));

    return (
      <div className={styles.root}>
        <div className={styles.flex}>
          <div className={styles.titulo}>
            <DocumentsIcon height={20} width={20} fill={Colors.COLOR_BLACK} className={styles.DocumentsIcon} />
            <SectionTitle text={I18n.t('ComunidadesCustomOnboardingProcess.form.ofertaPageTitle')} />
          </div>
          <div>
            <ToolsBarWithoutUrl
              langs={translatableLangs}
              selectedLang={currentLang}
              onChangeLanguage={(x) => setCurrentLang(x)}
            />
          </div>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.containerForms}>
            <div className={styles.containerForm}>
              {formFields.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  placeholder={formGroup.placeholder}
                  value={formGroup.value}
                  required={formGroup.required}
                  onChange={handleChange}
                  readOnly={formGroup.readOnly}
                  className={formGroup.className}
                  maxLenght={20000}
                />
              ))}
            </div>
          </div>
        </form>
      </div>
    );
  },
);
export default ComunidadesCustomOnboardingOferta;
