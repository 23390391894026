import React, { useEffect, useState } from 'react';
import { SectionTitle } from 'components';
import ControlledInput from 'components/Input/NewInputs';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { I18n } from 'core';
import CIMServiceInstance from 'services/remote/CIMService';
import { MeasuringEquipmentObject } from 'types/models/widgetsCIM';
import { toast } from 'react-toastify';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import styles from './CIMDeviceInfoPage.module.scss';

const CIMDeviceInfoPage = (props: any): JSX.Element => {
  const {
    match: {
      params: { cupsID },
    },
  } = props;
  const [data, setData] = useState<MeasuringEquipmentObject | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!cupsID) return;
    setLoading(true);
    CIMServiceInstance.getMeasuringEquipment(cupsID)
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch(() => {
        toast.error(I18n.t('CIM.CIMDeviceInfoPage.errorFetchingData'));
      });
  }, [cupsID]);

  if (loading) {
    return <FullPageSyncLoader icon />;
  }
  if (!data) return <></>;
  return (
    <div className={styles.root}>
      <SectionWrapper>
        <SectionTitle text={I18n.t('CIM.CIMDeviceInfoPage.title')} />
        <div className={styles.section_grid}>
          <ControlledInput
            type="text"
            value={data.datos.medida}
            placeholder="0"
            label={I18n.t('CIM.CIMDeviceInfoPage.medida')}
            onChange={() => {}}
            id="medida"
            name="medida"
            disabled
          />
          <ControlledInput
            type="number"
            value={data.datos.numeroDeContador}
            placeholder=""
            label={I18n.t('CIM.CIMDeviceInfoPage.numeroDeContador')}
            onChange={() => {}}
            id="numero_contador"
            name="numeroDeContador"
            disabled
          />
          <ControlledInput
            type="number"
            value={data.datos.numeroDeModem}
            placeholder=""
            label={I18n.t('CIM.CIMDeviceInfoPage.numeroDeModem')}
            onChange={() => {}}
            id="numero_modem"
            name="numeroDeModem"
            disabled
          />
          <ControlledInput
            type="text"
            value={data.datos.propiedad}
            placeholder=""
            label={I18n.t('CIM.CIMDeviceInfoPage.propiedad')}
            onChange={() => {}}
            id="propiedad"
            name="propiedad"
            disabled
          />
          <ControlledInput
            type="text"
            value={data.datos.telegestionado === 1 ? 'Si' : 'No'}
            placeholder=""
            label={`${I18n.t('CIM.CIMDeviceInfoPage.telegestionado')}`}
            onChange={() => {}}
            id="telegestionado"
            name="telegestionado"
            disabled
          />
          <ControlledInput
            type="text"
            value={data.datos.tipoDeMedicion}
            placeholder=""
            label={I18n.t('CIM.CIMDeviceInfoPage.tipoDeMedicion')}
            onChange={() => {}}
            id="tipoDeMedicion"
            name="tipoDeMedicion"
            disabled
          />
        </div>
      </SectionWrapper>
    </div>
  );
};

export default CIMDeviceInfoPage;
