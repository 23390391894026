import React from 'react';

import Button from 'components/Button/Button';
import I18n from 'i18n-js';
import { Solicitudes } from 'types/models';
import styles from './SolicitudCupsTerceroPage.module.scss';

interface SolicitudesRecividasProps {
  solicitudes: Solicitudes[];
  onAccept: (id: string) => void;
  onCancel: (id: string) => void;
}

const SolicitudesRecividas = ({ solicitudes, onAccept, onCancel }: SolicitudesRecividasProps) => (
  <div className={styles.table_wrapper}>
    <table>
      <thead>
        <tr>
          <th>CUPS</th>
          <th>{I18n.t('solicitante')}</th>
          <th aria-label="Detail" />
        </tr>
      </thead>
      <tbody>
        {solicitudes.map((solicitud) => (
          <tr key={solicitud.solicitudId}>
            <td>{solicitud.cups}</td>
            <td>{solicitud.solicitante}</td>
            <td style={{ width: '300px' }}>
              {solicitud.acceptada === 'si' ? (
                <div style={{ display: 'flex', gap: '2rem' }}>
                  <Button onClick={() => onCancel(solicitud.solicitudId.toString())} type="button" variant="reddelete">
                    {I18n.t('Rebutjar')}
                  </Button>
                </div>
              ) : (
                <div style={{ display: 'flex', gap: '2rem' }}>
                  <Button onClick={() => onAccept(solicitud.solicitudId.toString())} type="button" variant="primary">
                    {I18n.t('Aceptar')}
                  </Button>
                  <Button onClick={() => onCancel(solicitud.solicitudId.toString())} type="button" variant="reddelete">
                    {I18n.t('Rebutjar')}
                  </Button>
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default SolicitudesRecividas;
