import { Serializable } from 'types/interfaces';

interface MaximetersDataAPII {
  datos: Array<{
    periodo: number;
    maximetro: number;
    fecha: string;
  }>;

  habilitado: string;
}

export default class MaximetersObject implements Serializable<MaximetersObject> {
  datos: Array<{
    periodo: number;
    maximetro: number;
    fecha: string;
  }>;

  habilitado: string;

  deserialize(input: MaximetersDataAPII): MaximetersObject {
    if (!input) {
      return this;
    }
    this.datos = input.datos.map((item) => item) || [];
    this.habilitado = input.habilitado || 'no';
    return this;
  }
}
