/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { HeaderSearchInputCupsAsociados, SectionTitle, Spinner } from 'components';
import Button from 'components/Button/Button';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { navigate } from 'navigation';
import React, { useEffect, useState } from 'react';
import { clientServiceInstance } from 'services';
import { useDispatch } from 'react-redux';
import usersService from 'services/remote/UsersService';
import { fetchUser, fetchUserSuccess } from 'redux/actions/UsersActions';
import ClientResume from 'types/models/clientResume';
import { I18n } from 'core';
import { CupsInfo } from 'types/models';
import { toast } from 'react-toastify';
import WidgetPermission from 'types/enums/WidgetPermission';
import styles from './UsuarioCUPSPage.module.scss';

const UsuarioCUPSPage = (props: any): JSX.Element => {
  // Props
  const {
    match: {
      params: { userID },
    },
  } = props;

  // Hooks
  const dispatch = useDispatch();

  // Props
  const [clientsData, setClientsData] = useState<ClientResume[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loadingDataListaCups, setLoadingDataListaCups] = useState<boolean>(false);
  const [permisos, setPemisos] = useState<string[]>([]);

  useEffect(() => {
    dispatch(fetchUser());
    usersService.getUserById(userID).then((response) => {
      dispatch(fetchUserSuccess(response));
    });
  }, [userID]);

  useEffect(() => {
    setLoadingData(true);
    clientServiceInstance.getClientsResume(userID).then((response) => {
      setClientsData(response);
      setLoadingData(false);
    });
  }, [userID]);

  const [loadingDataDelete, setLoadingDataDelete] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cupsAsociados, setCupsAsociados] = useState<CupsInfo[]>([]);

  const fetchData = () => {
    setLoadingDataListaCups(true);
    usersService.getCupsAutorizados(userID).then((response) => {
      setCupsAsociados(response);
      setLoadingDataListaCups(false);
    });
  };
  const getPermisosUser = async () => {
    if (userID) {
      const response = await usersService.getPermisos(userID);
      // const permisos = ['acceso_web_tecnicos'];
      setPemisos(response);
    }
  };
  useEffect(() => {
    setLoadingData(true);
    getPermisosUser();
    dispatch(fetchUser());
    usersService.getUserById(userID).then((response) => {
      dispatch(fetchUserSuccess(response));
    });

    usersService.getCupsAutorizados(userID).then((response) => {
      setCupsAsociados(response);
    });
    fetchData();
    setLoadingData(false);
  }, []);

  const handleDelete = async (cupsValue: string): Promise<void> => {
    setLoadingDataDelete(true);
    const filteredCupsAsociados = cupsAsociados.filter((cups) => cups.cups !== cupsValue);
    const cupsfilteredCupsAsociados = filteredCupsAsociados.map((cups) => cups.cups);
    try {
      await usersService.updateCupsAutorizados(userID, cupsfilteredCupsAsociados);
    } catch (error) {
      toast.error(I18n.t('DashboardPage.comunity_fetching_error'));
    } finally {
      fetchData();
      setLoadingDataDelete(false);
    }
  };
  const hasPermission = permisos && permisos.includes(WidgetPermission.ACCESO_CUPS_AUTORIZADOS);

  return (
    <div className={styles.root}>
      {loadingData ? (
        <div className={styles.loading_wrapper}>
          <Spinner icon />
        </div>
      ) : (
        <div>
          <SectionWrapper>
            <SectionTitle text={I18n.t('UsuarioCUPSPage.title')} />
            <div className={styles.table_wrapper}>
              <table>
                <thead>
                  <tr>
                    <th>{I18n.t('UsuarioCUPSPage.headers.cups')}</th>
                    <th>{I18n.t('UsuarioCUPSPage.headers.address')}</th>
                    <th>{I18n.t('UsuarioCUPSPage.headers.city')}</th>
                    <th aria-label="has-installation" />
                  </tr>
                </thead>
                <tbody>
                  {(() => {
                    if (clientsData.length > 0) {
                      return clientsData.map((cups) => {
                        const clickHandle = () => {
                          navigate(`/tecnic/cups/${cups.cups}/detail`);
                        };
                        return (
                          <tr>
                            <td onClick={clickHandle}>{cups.cups}</td>
                            <td onClick={clickHandle}>{cups.domicilio}</td>
                            <td onClick={clickHandle}>{cups.poblacion}</td>
                            <td className={styles.hasInstallation}>
                              {cups.instalacionID !== -1 ? (
                                <Button
                                  variant="tertiary"
                                  type="button"
                                  onClick={() => {
                                    navigate(`/tecnic/installation/${cups.instalacionID}/installation`);
                                  }}
                                  text={I18n.t('UsuarioCUPSPage.viewInstallation')}
                                />
                              ) : null}
                            </td>
                          </tr>
                        );
                      });
                    }
                    return <p className={styles.nodata}>{I18n.t('UsuarioCUPSPage.noData')}</p>;
                  })()}
                </tbody>
              </table>
            </div>
          </SectionWrapper>
          {hasPermission && (
            <SectionWrapper>
              <SectionWrapper>
                <SectionTitle text={I18n.t('UsuarioCupsAssociarPage.subtitle')} />
                <HeaderSearchInputCupsAsociados cupsAsociados={cupsAsociados} userID={userID} fetchData={fetchData} />
              </SectionWrapper>
              <div className={styles.table_wrapper}>
                <table>
                  <thead>
                    <tr>
                      <th>{I18n.t('UsuarioCupsAssociarPage.headers.Cups')}</th>
                      <th>{I18n.t('UsuarioCupsAssociarPage.headers.name')}</th>
                      <th>{I18n.t('UsuarioCupsAssociarPage.headers.domicilio')}</th>
                      <th aria-label="Detail" />
                    </tr>
                  </thead>
                  <tbody>
                    {(() => {
                      if (loadingDataListaCups) {
                        return (
                          <div className={styles.loading_wrapper}>
                            <Spinner icon />
                          </div>
                        );
                      }
                      if (cupsAsociados && cupsAsociados.length > 0) {
                        return cupsAsociados.map((rol) => (
                          <tr>
                            <td>{rol.cups}</td>
                            <td>
                              {rol.nombre} {rol.apellidos}
                            </td>
                            <td>{rol.domicilio}</td>

                            <td>
                              <Button
                                loading={loadingDataDelete}
                                className={styles.viewmore_button}
                                variant="reddelete"
                                type="button"
                                onClick={() => handleDelete(rol.cups)}
                                text={I18n.t(`UsuarioCupsAssociarPage.quitar`)}
                              />
                            </td>
                          </tr>
                        ));
                      }
                      return (
                        <tr key="no-data">
                          <td className={styles.nodata}>{I18n.t('UsuarioCupsAssociarPage.noRole')}</td>
                        </tr>
                      );
                    })()}
                  </tbody>
                </table>
              </div>
            </SectionWrapper>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(UsuarioCUPSPage);
