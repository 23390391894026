/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';

import { FormGroup, FormGroupProps, I18n } from 'core';

import { SectionTitle, LoadingButton } from 'components';

import { navigate } from 'navigation';
import { ReactComponent as DocumentsIcon } from 'assets/icons/Documents.svg';
import { Colors } from 'styles';
import { toast } from 'react-toastify';
import customToast from 'components/CustomToast/CustomToast';
import promotorService from 'services/remote/PromotorService';
import Button from 'components/Button/Button';
import styles from './AddUserPage.module.scss';

const AddUserPage = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const [input, setInputs] = useState({
    email: '',
    nif: '',
    nombre: '',
    apellidos: '',
    tipoDeUsuario: 'promotor',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const [errorEmail, setError] = useState<string | undefined>(undefined);
  const [errorPassword, seterrorPassword] = useState<string | undefined>(undefined);

  const [errorPasswordNew, seterrorPasswordNew] = useState<string | undefined>(undefined);

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

  const [password, setPassword] = useState('');
  const formGroupUserDataLeft: Array<FormGroupProps> = [
    {
      label: I18n.t('Name'),
      type: 'text',
      id: 'nombre',
      path: 'nombre',
      name: 'nombre',
      value: input.nombre,
      placeholder: I18n.t('Name'),
      tabindex: 1,
    },
    {
      label: I18n.t('Email'),
      type: 'text',
      id: 'email',
      path: 'email',
      name: 'email',
      value: input.email,
      tabindex: 3,
      error: errorEmail,

      placeholder: I18n.t('Email'),
    },

    {
      label: I18n.t('New Password'),
      type: 'password',
      id: 'password',
      path: 'password',
      name: 'password',
      error: errorPasswordNew,

      value: input.password,
      tabindex: 5,

      placeholder: I18n.t('min 8 characters'),
    },
    {
      label: I18n.t('tipoDeUsuario'),
      type: 'select',
      id: 'tipoDeUsuario',
      path: 'tipoDeUsuario',
      name: 'tipoDeUsuario',
      value: input.tipoDeUsuario,
      options: [
        { label: 'Promotor', value: 'promotor' },
        { label: 'estandard', value: 'user' },
      ],
    },
  ];

  const formGroupUserDataRight: Array<FormGroupProps> = [
    {
      label: I18n.t('Surname'),
      type: 'text',
      id: 'apellidos',
      path: 'apellidos',
      name: 'apellidos',
      value: input.apellidos,
      tabindex: 2,

      placeholder: I18n.t('Surname'),
    },
    {
      label: I18n.t('NIF'),
      type: 'text',
      id: 'nif',
      path: 'nif',
      name: 'nif',
      value: input.nif,
      tabindex: 4,
      placeholder: I18n.t('NIF'),
    },
    {
      label: I18n.t('Repeat New Password'),
      type: 'password',
      id: 'newRePassword',
      path: 'newRePassword',
      name: 'newRePassword',
      value: password,
      tabindex: 6,
      error: errorPassword,
      placeholder: I18n.t('min 8 characters'),
    },
  ];
  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const submitForm = async (): Promise<void> => {
    setLoading(true);
    setSubmitDisabled(true);

    promotorService
      .registerPromotor(input.nif, input.email, input.nombre, input.apellidos, input.password, input.tipoDeUsuario)
      .then((response) => {
        if (response.status === 201) {
          customToast.success(I18n.t('NEW user!'));
          setLoading(false);
          setSubmitDisabled(false);
          navigate('/tecnic/users');
        }
      })
      .catch((error) => {
        const { response } = error;
        const { data } = response;
        if (data.includes('email')) {
          toast.error(I18n.t('SignUpPage.errors.submitEmail'));
          return;
        }
        if (data.includes('nif')) {
          setLoading(false);
          setSubmitDisabled(false);
          toast.error(I18n.t('SignUpPage.errors.submitNIF'));
          return;
        }
        setLoading(false);
        setSubmitDisabled(false);
        toast.error(data);
      });
  };

  function handleChange(path: string, value: string): void {
    setInputs((prev) => ({
      ...prev,
      [path]: value,
    }));
    if (path === 'email') {
      if (!isValidEmail(input.email)) {
        setError(I18n.t('UsuarioDetailPage.Promotor.errorEmail'));
      } else {
        setError(undefined);
      }
    }
    if (path === 'newRePassword') {
      setPassword(value);
    }
  }
  useEffect(() => {
    if (input.password.length < 8) {
      seterrorPasswordNew(I18n.t('UsuarioDetailPage.Promotor.errorContraMinLength'));
    } else {
      seterrorPasswordNew(undefined); // Limpia el error si la longitud es suficiente
      if (input.password !== password) {
        seterrorPassword(I18n.t('UsuarioDetailPage.Promotor.errorContra'));
      } else {
        seterrorPassword(undefined); // Limpia el error si las contraseñas coinciden
      }
    }
    if (
      input.nombre &&
      input.apellidos &&
      isValidEmail(input.email) &&
      input.nif &&
      input.password.length >= 8 &&
      password.length >= 8 &&
      input.password === password
    ) {
      setSubmitDisabled(false);
    }
  }, [password, input]);
  return (
    <div className={styles.root}>
      {/* <SectionWrapper> */}
      <div className={styles.flex} />
      <form className={styles.form}>
        <div className={styles.posotionradio}>
          <div className={styles.postflex}>
            <DocumentsIcon height={20} width={20} fill={Colors.COLOR_BLACK} className={styles.DocumentsIcon} />
            <SectionTitle text={I18n.t('UsersPage.userLabel')} />{' '}
          </div>
        </div>

        <div className={styles.flex}>
          <SectionTitle text={I18n.t('Dades personals')} />
        </div>

        <div className={styles.containerForms}>
          <div className={styles.containerForm}>
            {formGroupUserDataLeft.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                placeholder={formGroup.placeholder}
                value={formGroup.value}
                onChange={handleChange}
                options={formGroup.options}
                disabled={formGroup.disabled}
                tabindex={formGroup.tabindex}
                error={formGroup.error}
              />
            ))}
          </div>
          <div className={styles.containerForms}>
            <div className={styles.containerForms}>
              {formGroupUserDataRight.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  placeholder={formGroup.placeholder}
                  value={formGroup.value}
                  onChange={handleChange}
                  options={formGroup.options}
                  disabled={formGroup.disabled}
                  tabindex={formGroup.tabindex}
                  error={formGroup.error}
                />
              ))}
            </div>
          </div>
          {/* {errorEmail && <p style={{ color: 'red' }}>{errorEmail}</p>} */}
        </div>
        <div className={styles.actions_wrapper}>
          <div className={styles.action}>
            <Button block type="button" variant="secondary" text="Cancelar" onClick={() => navigate('/tecnic/users')} />
          </div>
          <div className={styles.action}>
            <LoadingButton
              type="submit"
              text="Guardar"
              disabled={submitDisabled}
              loading={loading}
              onClick={submitForm}
            />
          </div>
        </div>
      </form>
      {/* </SectionWrapper> */}
    </div>
  );
};

export default AddUserPage;
