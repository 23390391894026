import React from 'react';
import cx from 'classnames';
import styles from './TabsSwitch.module.scss';

interface TabItemI {
  key: string;
  label: string;
}

interface TabsSwitchPropsI {
  options: Array<TabItemI>;
  selected: string;
  onTabClick: CallableFunction;
}

const TabsSwitch = (props: TabsSwitchPropsI): JSX.Element => {
  const { selected, options, onTabClick } = props;
  return (
    <div className={styles.root}>
      {options.map((option) => (
        <button
          key={option.key}
          type="button"
          onClick={() => onTabClick(option)}
          className={cx(styles.option, {
            [styles.selected]: option.key === selected,
          })}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default React.memo(TabsSwitch);
