import React from 'react';

import styles from './SectionSubTitle.module.scss';

export interface SectionSubTitleInterface {
  text: string;
}

const SectionSubTitle = (props: SectionSubTitleInterface): JSX.Element => {
  const { text } = props;
  return <div className={styles.root}>{text}</div>;
};

export default SectionSubTitle;
