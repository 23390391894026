/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import { Card } from 'components';

import React, { useEffect, useState, useRef } from 'react';
import { SizeMe } from 'react-sizeme';
import { I18n } from 'core';
import Color from 'color';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/ArrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import wattsConverterService from 'services/local/WattsConverter';
import { MonthlyConsumption } from 'types/models/widgetsCIM';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import { Bar } from 'react-chartjs-2';

import 'chartjs-adapter-date-fns';

import { Chart } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

import { ca, es } from 'date-fns/locale';
import { Colors } from 'styles';
import { WattsConverterService } from 'services';
import CIMServiceInstance from 'services/remote/CIMService';
import styles from './MensualConsumptionWidget.module.scss';

// const monthsKeys = ['Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec'];
const monthsKeys = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const powerColorsArray = [
  Color(Colors.COLOR_NOTIFICATIONS).alpha(0.8).string(),
  Color(Colors.COLOR_GRAY_500).alpha(0.9).string(),
  Color(Colors.COLOR_BLUE_100).alpha(0.9).string(),
  Color(Colors.COLOR_ERROR).alpha(0.75).string(),
  Color(Colors.COLOR_BROWN).alpha(0.75).string(),
  Color(Colors.COLOR_PURPLE).alpha(0.75).string(),
];

const legendsData: Array<{
  name?: string;
  index: number;
  labels?: {
    fill?: string;
  };
  symbol?: {
    fill?: string;
    type?: string;
  };
}> = [
  {
    name: 'P1',
    index: 1,
    symbol: {
      fill: Colors.COLOR_NOTIFICATIONS,
    },
  },
  {
    name: 'P2',
    index: 2,
    symbol: {
      fill: Colors.COLOR_GRAY_500,
    },
  },
  {
    name: 'P3',
    index: 3,
    symbol: {
      fill: Colors.COLOR_BLUE_100,
    },
  },
  {
    name: 'P4',
    index: 4,
    symbol: {
      fill: Color(Colors.COLOR_ERROR).alpha(0.9).string(),
    },
  },
  {
    name: 'P5',
    index: 5,
    symbol: {
      fill: Color(Colors.COLOR_BROWN).alpha(0.9).string(),
    },
  },
  {
    name: 'P6',
    index: 6,
    symbol: {
      fill: Color(Colors.COLOR_PURPLE).alpha(0.9).string(),
    },
  },
];

const GraphTooltip = (props: any): JSX.Element => {
  const { datum, x, y } = props;
  const CONTAINER_SIZE = {
    x: 120,
    y: 50,
  };

  return (
    <g fill="white" z={1000}>
      <rect
        x={x - 40}
        y={y - 50}
        width={CONTAINER_SIZE.x}
        height={CONTAINER_SIZE.y}
        rx="4"
        fill="white"
        stroke={Colors.COLOR_GRAY_100}
        strokeWidth="1"
      />
      <circle cx={x - 27} cy={y - 15} r="5" stroke="transparent" fill={datum.color} />
      <text
        x={x}
        textAnchor="center"
        y={y - CONTAINER_SIZE.y * 0.6}
        fontSize="12"
        fontWeight="bold"
        fill={Colors.COLOR_BLACK}
      >
        {`${I18n.t(datum.monthLabel).toUpperCase()}`}
      </text>
      <text x={x - 18} y={y - 10} fontSize="12" fontWeight="bold" fill="black">
        {`${datum.p.toUpperCase()}`}
      </text>
      <text x={x + 25} y={y - 10} fontSize="12" fontWeight="400" fill={Colors.COLOR_GRAY_300}>
        {`${wattsConverterService.convertWatts(datum.y).num}${wattsConverterService.convertWatts(datum.y).unit}`}
      </text>
    </g>
  );
};

interface MensualConsumptionWidgetPropsI {
  title: string;
  cupsID: string;
}

const MensualConsumptionWidgetTest = (props: MensualConsumptionWidgetPropsI): JSX.Element => {
  const chartRef2 = useRef(null);
  const { title, cupsID } = props;
  const [newDataset, setNewDataset] = useState<any>(null);
  const MAX_YEAR = new Date().getFullYear();
  const [monthlyConsumptionYearTarifa, setMonthlyConsumptionYearTarifa] = useState<number>(new Date().getFullYear());
  const [loadingMonthlyConsumptionTarifa, setLoadingMonthlyConsumptionTarifa] = useState<boolean>(false);
  const [monthlyConsumptionTarifa, setMonthlyConsumptionTarifa] = useState<MonthlyConsumption['datos']>();

  useEffect(() => {
    setLoadingMonthlyConsumptionTarifa(true);

    CIMServiceInstance.getMonthlyConsumptionTarifa(cupsID, monthlyConsumptionYearTarifa.toString()).then((response) => {
      setMonthlyConsumptionTarifa(response.datos);
      if (response.datos) {
        const legendsWithExistingItems = legendsData.filter((legend) =>
          response.datos.meses.some((mes: { periodos: any[] }) =>
            mes.periodos.some((periodo) => legend.name === `P${periodo.periodo}`),
          ),
        );

        const newFinal: any = [];
        for (let i = 0; i < legendsWithExistingItems.length; i += 1) {
          const computedPeriod = `P${legendsWithExistingItems[i].index}`;
          newFinal.push({
            label: computedPeriod,
            color: powerColorsArray[legendsWithExistingItems[i].index - 1],
            data: response.datos.meses.map((mes: any, index: any) => {
              const period = mes.periodos.find((p: any) => p.periodo === legendsWithExistingItems[i].index);
              return {
                x: I18n.t(monthsKeys[index]).substring(0, 3),
                monthLabel: I18n.t(monthsKeys[index]),
                y: period ? period.energia : 0,
              };
            }),
          });
        }
        setNewDataset(newFinal);
        setLoadingMonthlyConsumptionTarifa(false);
      }
    });
  }, [cupsID, monthlyConsumptionYearTarifa]);

  const ICON_PICKER_SIZE = 16;
  return (
    <>
      {' '}
      {newDataset ? (
        <>
          {' '}
          <Card
            fullHeight
            title={title}
            rightCorner={
              <div className={styles.picker}>
                <button
                  onClick={() => setMonthlyConsumptionYearTarifa(monthlyConsumptionYearTarifa - 1)}
                  className={styles.button}
                  type="button"
                >
                  <ArrowLeftIcon fill={Colors.COLOR_ELECSUMGREEN} height={ICON_PICKER_SIZE} width={ICON_PICKER_SIZE} />
                </button>
                <span className={styles.value}>{monthlyConsumptionYearTarifa}</span>
                <button
                  disabled={monthlyConsumptionYearTarifa >= MAX_YEAR}
                  onClick={() => setMonthlyConsumptionYearTarifa(monthlyConsumptionYearTarifa + 1)}
                  className={styles.button}
                  type="button"
                >
                  <ArrowRightIcon
                    fill={
                      monthlyConsumptionYearTarifa >= MAX_YEAR ? Colors.COLOR_ELECSUMLOW : Colors.COLOR_ELECSUMGREEN
                    }
                    height={ICON_PICKER_SIZE}
                    width={ICON_PICKER_SIZE}
                  />
                </button>
              </div>
            }
            body={[
              <div key={2}>
                <SizeMe>
                  {({ size }) => (
                    <div className={styles.chart_wrapper}>
                      {loadingMonthlyConsumptionTarifa ? (
                        <FullPageSyncLoader icon />
                      ) : (
                        <Bar
                          ref={chartRef2}
                          type="bar"
                          data={{
                            datasets: newDataset?.map((period: any) => ({
                              label: period.label,
                              borderColor: period.color,
                              backgroundColor: period.color,
                              data: period.data,
                              parsing: {
                                yAxisKey: 'y',
                                xAxisKey: 'monthLabel',
                              },
                            })),
                          }}
                          options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            layout: {
                              padding: 20,
                            },
                            tooltips: {
                              enabled: true,
                              position: 'nearest',
                              mode: 'index',
                              titleFontStyle: 'bold',
                              titleFontFamily: 'Avenir',
                              titleFontColor: Colors.COLOR_BLACK,
                              backgroundColor: Colors.COLOR_GRAY_050,
                              bodyFontColor: Colors.COLOR_GRAY_800,
                              bodySpacing: 10,
                              bodyFontFamily: 'Avenir',
                              xPadding: 10,
                              yPadding: 10,
                              titleMarginBottom: 10,
                              cornerRadius: 8,
                            },
                            scales: {
                              x: {
                                stacked: true,
                                ticks: {
                                  callback: (value: any, index: any, values: any) =>
                                    I18n.t(monthsKeys[index]).substring(0, 3),
                                },
                              },
                              y: {
                                stacked: true,
                                ticks: {
                                  callback: (value: any, index: any, values: any) =>
                                    `${WattsConverterService.convertWattsToKwattsWithUnitsSmall(value).num} ${
                                      WattsConverterService.convertWattsToKwattsWithUnitsSmall(value).unitHour
                                    }`,
                                },
                              },
                            },
                            plugins: {
                              tooltip: {
                                callbacks: {
                                  label: (context: any) =>
                                    `${context.dataset.label}: ${
                                      WattsConverterService.convertWattsToKwattsWithUnitsSmall(context.parsed.y).num
                                    } ${
                                      WattsConverterService.convertWattsToKwattsWithUnitsSmall(context.parsed.y)
                                        .unitHour
                                    }`,
                                  footer: (context: any) => {
                                    const agregated = context.reduce((a: any, b: any) => a + b.parsed.y, 0);
                                    return [
                                      `Total: ${
                                        WattsConverterService.convertWattsToKwattsWithUnitsSmall(agregated).num
                                      } ${
                                        WattsConverterService.convertWattsToKwattsWithUnitsSmall(agregated).unitHour
                                      }`,
                                    ];
                                  },
                                },
                              },
                              legend: {
                                position: 'top',
                                align: 'center',
                                labels: {
                                  pointStyle: 'circle',
                                  usePointStyle: true,
                                },
                              },
                            },
                          }}
                        />
                      )}
                    </div>
                  )}
                </SizeMe>
              </div>,
            ]}
          />
        </>
      ) : (
        <FullPageSyncLoader icon />
      )}
    </>
  );
};

export default React.memo(MensualConsumptionWidgetTest);
