import { Serializable } from 'types/interfaces';

export enum PrincipalEnum {
  si = 'si',
  no = 'no',
}
export interface PromotorInfoAPI {
  id_comunidad: number;
  cau: string;
  nombre: string;
  principal: PrincipalEnum;
}
export interface PromotorIdentifiadprInfoAPI {
  identificador: string;
}

export default class PromotorInfo implements Serializable<PromotorInfo> {
  id_comunidad: number;

  cau: string;

  nombre: string;

  principal: PrincipalEnum;

  deserialize(input: PromotorInfoAPI): PromotorInfo {
    if (!input) {
      return this;
    }
    this.id_comunidad = input.id_comunidad;
    this.cau = input.cau;
    this.nombre = input.nombre;
    this.principal = input.principal;

    return this;
  }

  deserializeArray(inputArray: Array<PromotorInfoAPI>): Array<PromotorInfo> {
    const Users: Array<PromotorInfo> = inputArray.map((input) => new PromotorInfo().deserialize(input));

    return Users;
  }
}
