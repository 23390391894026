import React from 'react';
import { ReactComponent as SmartMeterIcon } from 'assets/icons/SmartMeter.svg';
import { Colors } from 'styles';
import styles from './Display.module.scss';

interface CupsDisplayPropsI {
  name: string;
  surname: string;
  cups: string;
  address: string;
  city: string;
  cp?: number;
}

const CupsDisplay = (props: CupsDisplayPropsI): JSX.Element => {
  const { name, surname, cups, address, city, cp } = props;
  return (
    <div className={styles.root}>
      <div className={styles.icon}>
        <div className={styles.logo}>
          <SmartMeterIcon height={25} width={25} fill={Colors.COLOR_ELECSUMGREEN} />
        </div>
      </div>
      <div className={styles.data}>
        <p className={styles.title}>{`${name} ${surname}`}</p>
        <p className={styles.subtitle}>{cups}</p>
        <p className={styles.quote}>{`${address} · ${city} ${cp ? '·' : ''} ${cp || ''}`}</p>
      </div>
    </div>
  );
};

export default CupsDisplay;
