import { Serializable } from 'types/interfaces';

export interface RolInfoAPI {
  id: number;
  nombre: string;
  permisos: Array<number>;
}

export interface RolInfoUpdateAPI {
  nombre: string;
}
export interface RolInfoPermisosUpdateAPI {
  permisos: Array<number>;
}
export default class RolInfo implements Serializable<RolInfo> {
  id: number;

  nombre: string;

  permisos: Array<number>;

  deserialize(input: RolInfoAPI): RolInfo {
    if (!input) {
      return this;
    }
    this.id = input.id;

    this.nombre = input.nombre || '';
    this.permisos = input.permisos || [];
    return this;
  }

  deserializeArray(inputArray: Array<RolInfoAPI>): Array<RolInfo> {
    const Rol: Array<RolInfo> = inputArray.map((input) => new RolInfo().deserialize(input));

    return Rol;
  }
}
