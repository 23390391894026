import React, { useState } from 'react';
import ArrowLeftSwitch from 'assets/icons/ArrowLeftSwitch.svg';
import ArrowRightSwitch from 'assets/icons/ArrowRightSwitch.svg';
import styles from './DateSwitcher.module.scss';

interface DateSwitcherProps {
  initialDate: Date;
  maxArrayPosition: number;
  onDateChange: (newDate: Date, fechaMesAñoArrayPosition: number) => void;
  fechaMesAñoArrayPositionDad: number;
}

const DateSwitcher = (props: DateSwitcherProps): JSX.Element => {
  const { initialDate, onDateChange, maxArrayPosition, fechaMesAñoArrayPositionDad } = props;

  const [currentDate, setCurrentDate] = useState(initialDate);
  const [fechaMesAñoArrayPosition, setFechaMesAñoArrayPosition] = useState<number>(fechaMesAñoArrayPositionDad);
  const [noHayNextFecha] = useState<boolean>(fechaMesAñoArrayPositionDad >= maxArrayPosition);
  const [noHayPreviousFecha] = useState<boolean>(fechaMesAñoArrayPosition - 1 < 0);

  const handlePrevious = () => {
    if (fechaMesAñoArrayPositionDad > 0) {
      const newDate = new Date(currentDate);
      const newFechaMesAñoArrayPosition = fechaMesAñoArrayPosition - 1;

      if (newDate.getMonth() === 0) {
        newDate.setFullYear(newDate.getFullYear() - 1);
        newDate.setMonth(11);
      } else {
        newDate.setMonth(newDate.getMonth() - 1);
      }

      setCurrentDate(newDate);
      setFechaMesAñoArrayPosition(newFechaMesAñoArrayPosition);
      onDateChange(newDate, newFechaMesAñoArrayPosition);
    }
  };

  const handleNext = () => {
    if (fechaMesAñoArrayPositionDad < maxArrayPosition) {
      const currentDateClone = new Date(currentDate);
      currentDateClone.setMonth(currentDateClone.getMonth() + 1);

      const newFechaMesAñoArrayPosition = fechaMesAñoArrayPosition + 1;

      if (currentDateClone.getMonth() === 12) {
        currentDateClone.setFullYear(currentDateClone.getFullYear() + 1);
        currentDateClone.setMonth(0); // Enero es el mes 0
      }

      setCurrentDate(currentDateClone);
      setFechaMesAñoArrayPosition(newFechaMesAñoArrayPosition);
      onDateChange(currentDateClone, newFechaMesAñoArrayPosition);
    }
  };

  return (
    <div className={styles.monthYearSwitcherContainer}>
      <button className={styles.switchButton} type="button" onClick={handlePrevious} disabled={noHayPreviousFecha}>
        <img src={ArrowLeftSwitch} alt="Arrow Left" />
      </button>
      <button className={styles.switchButton} type="button" onClick={handleNext} disabled={noHayNextFecha}>
        <img src={ArrowRightSwitch} alt="Arrow Right" />
      </button>
    </div>
  );
};

export default DateSwitcher;
