import { Client, CupsInfo } from 'types/models';
import { CLIENT_CUPS_ACTIONS, CUPS_ACTIONS } from './types';

// Acciones relacionadas con el cups seleccionado por el usuario
const fetchClientCUPS = () => ({ type: CLIENT_CUPS_ACTIONS.FETCH_CUPS });

const fetchClientCUPSSuccess = (payload: CupsInfo) => ({
  type: CLIENT_CUPS_ACTIONS.FETCH_CUPS_OK,
  payload,
});

const fetchClientCUPSError = (payload: string) => ({
  type: CLIENT_CUPS_ACTIONS.FETCH_CUPS_ERROR,
  payload,
});

const updateClientCUPS = (payload: CupsInfo) => ({
  type: CLIENT_CUPS_ACTIONS.UPDATE_CUPS,
  payload,
});

// Acciones relacionadas con el listado de cups vinculados al usuario validado.
const fetchClientCUPSList = () => ({
  type: CLIENT_CUPS_ACTIONS.FETCH_CUPS_LIST,
});

const fetchClientCUPSListSuccess = (payload: CupsInfo[]) => ({
  type: CLIENT_CUPS_ACTIONS.FETCH_CUPS_LIST_OK,
  payload,
});
const fetchClientCUPSListSuccessAdmin = (payload: CupsInfo[]) => ({
  type: CUPS_ACTIONS.FETCH_CUPS_LIST_OK,
  payload,
});

const updateClientCUPSadmin = (payload: CupsInfo) => ({
  type: CUPS_ACTIONS.UPDATE_CUPS,
  payload,
});
const fetchClientCUPSListError = (payload: string) => ({
  type: CLIENT_CUPS_ACTIONS.FETCH_CUPS_LIST_ERROR,
  payload,
});
const fetchCUPSTestSuccess = (payload: Client) => ({
  type: CUPS_ACTIONS.FETCH_CUPS_OK,
  payload,
});
const resetClientCUPS = () => ({ type: CLIENT_CUPS_ACTIONS.RESET_STATE });

export {
  fetchClientCUPS,
  fetchClientCUPSSuccess,
  fetchClientCUPSError,
  fetchClientCUPSList,
  fetchClientCUPSListError,
  fetchClientCUPSListSuccess,
  updateClientCUPS,
  resetClientCUPS,
  fetchClientCUPSListSuccessAdmin,
  updateClientCUPSadmin,
  fetchCUPSTestSuccess,
};
