interface PropertiesI {
  month?: 'short' | 'long';
  year?: 'numeric';
}

class UtilsService {
  dateToLocaleString(date: Date, properties: PropertiesI, uppercase?: boolean): string {
    const formatedDate = date
      .toLocaleDateString(navigator.language === 'es' ? 'es-ES' : 'ca-ES', properties)
      .replace('.', '');
    if (uppercase) {
      return formatedDate.toUpperCase();
    }
    return formatedDate;
  }

  formatTwoDecimal(numberToConvert: number): string {
    return numberToConvert
      .toFixed(2)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }
}

const utilsService: UtilsService = new UtilsService();

export default utilsService;
