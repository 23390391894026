export const mockMatcher = [
  { id: 1, nombre: 'test 1', comunitat: 'NOMBRE 1', fecha: '31/07/2019', numberMember: 4, report: true },
  { id: 2, nombre: 'test 2', comunitat: 'NOMBRE 2', fecha: '31/07/2023', numberMember: 5, report: true },
  { id: 3, nombre: 'test 3', comunitat: 'NOMBRE 3', fecha: '31/07/2021', numberMember: 6, report: false },
];

export enum MockMatcherTabs {
  CONFIG = 'config',
  INFORME = 'informe',
}

export const PerfilesConsumoMatcherList = [
  { id: 'perfil1', value: 'Perfil Consumo V1', label: 'Perfil Consumo V1' },
  { id: 'perfil2', value: 'Perfil Consumo V2', label: 'Perfil Consumo V2' },
  { id: 'perfil3', value: 'Perfil Consumo V3', label: 'Perfil Consumo V3' },
  { id: 'perfil4', value: 'Perfil Consumo V4', label: 'Perfil Consumo V4' },
];

export const mockMiembrosMatcher = [
  {
    seleccionado: true,
    nombre: 'Manolo',
    apellidos: 'García',
    nif: '123454665A',
    cups: 'ESO12431512413',
    kw: '12000 kw',

    preferent: true,
    vip: true,
  },
  {
    seleccionado: false,
    nombre: 'Francisco',
    apellidos: 'Fernandez',
    nif: '223454665B',
    cups: 'ESO12431512414',
    kw: '12000 kw',

    preferent: true,
    vip: true,
  },
  {
    seleccionado: false,
    nombre: 'Bonifacio',
    apellidos: 'Lopez',
    nif: '323454665C',
    cups: 'ESO12431512415',
    kw: '12000 kw',

    preferent: true,
    vip: false,
  },
  {
    seleccionado: false,
    nombre: 'Bonifacio',
    apellidos: 'Lopez',
    nif: '323454665C',
    cups: 'ESO12431512416',
    kw: '12000 kw',

    preferent: true,
    vip: false,
  },
  {
    seleccionado: false,
    nombre: 'Bonifacio',
    apellidos: 'Lopez',
    nif: '323454665C',
    cups: 'ESO12431512417',
    kw: '12000 kw',

    preferent: true,
    vip: false,
  },
  {
    seleccionado: false,
    nombre: 'Bonifacio',
    apellidos: 'Lopez',
    nif: '323454665C',
    cups: 'ESO12431512418',
    kw: '12000 kw',

    preferent: true,
    vip: false,
  },
];

export const MockPerfiles = [
  {
    id: 1,
    nombre: 'Grande industrial',
    descripcion: 'Industrial grande de año 2023 con consumo anual 1 MWh',
    curvaDeConsumo: {
      año: '2020',
      consumo: 1000000,
    },
  },
  {
    id: 1,
    nombre: 'Grande industrial',
    descripcion: 'Industrial grande de año 2023 con consumo anual 1 MWh',
    curvaDeConsumo: {
      año: '2020',
      consumo: 1000000,
    },
  },
];

export const MockConiguracion = {
  id: 1,
  nombre: 'Configuracion con 2 industriales y 10 domesticos clientos',
  fechaInicio: '2023-12-01',
  fechaFin: '2023-12-02',
  idComunidad: 1,
  betaMax: 0.15,
  betaMin: 0.05,
  minimizarMiembros: true,
  maximizarMiembros: true,
  fechaCreacion: '2023-05-01',
  bloqueada: true,
  curvaDeGeneracion: {
    año: '2020',
    generacion: 400000,
  },
};

export const MockInformeDetalle = {
  numeroDeMiembrosSelecionados: 10,
  numeroDeMiembrosAceptados: 5,
  generacion: 500000,
  consumo: 1500000,
  autoConsumo: 430000,
  excedente: 70000,
  autoConsumoEuro: 65,

  listaCombinaciones: [
    {
      duracionDeCalculos: 0.1,
      numeroMiembros: 10,
      autoconsumoEuro: 10,
      excedenteEuro: 10,
      ahorrosEuro: 20,
      autoconsumoPorcentaje: 0.9,
      excedentePorcentaje: 0.1,
      esLaOptima: true,
    },
  ],

  listaMiembros: [
    {
      idMiembro: 1,
      idInscrito: 'idInscrito',
      nombre: 'Juan',
      apellidos: 'Doe',
      aceptado: true,
    },
    {
      idMiembro: 1,
      idInscrito: 'idInscrito',
      nombre: 'Juan false',
      apellidos: 'Doe',
      aceptado: false,
    },
    {
      idMiembro: 1,
      idInscrito: 'idInscrito',
      nombre: 'Juan true',
      apellidos: 'Doe',
      aceptado: true,
    },
    {
      idMiembro: 1,
      idInscrito: 'idInscrito',
      nombre: 'Juan false 2',
      apellidos: 'Doe',
      aceptado: false,
    },
  ],
};
