import Button from 'components/Button/Button';
import React, { useEffect, useState } from 'react';
import { I18n } from 'core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCUPS, fetchCUPSSuccess } from 'redux/actions/CupsActions';
import { clientServiceInstance } from 'services';
import CIMServiceInstance from 'services/remote/CIMService';
import { Spinner, DropdownBase } from 'components';
import { toast } from 'react-toastify';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import { useRole } from 'hooks';
import { GlobalStoreState } from 'types/state';
import { Roles } from 'types/enums';
import { navigate } from 'navigation';
import styles from './CIMClosuresPage.module.scss';

const CIMClosuresPage = (props: any): JSX.Element => {
  // Props
  const {
    match: {
      params: { cupsID },
    },
  } = props;

  // Hooks
  const dispatch = useDispatch();
  const role = useRole();
  const cupsSelected = useSelector((state: GlobalStoreState) => state.clientCUPS.selectedCups);

  // State

  const [ranges, setRanges] = useState<any>(null);
  const [loadingRanges, setLoadingRanges] = useState(false);
  const [selectedRange, setSelectedRange] = useState<any>(null);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    dispatch(fetchCUPS());
    clientServiceInstance.getClient(cupsID).then((response) => {
      response.setAttr('cups', cupsID);
      dispatch(fetchCUPSSuccess(response));
    });
  }, [cupsID]);

  // useEffect(() => {
  //   if (role !== Roles.CLIENT) return;
  //   if (!authServiceInstance.user?.nif) return;
  //   cupsServiceInstance.getCupsFromUserId(authServiceInstance.user?.nif).then((response) => {
  //     if (response.length > 0) {
  //       const [firstCups] = response;
  //       if (!cupsID) {
  //         navigate(`/client/${firstCups.cups}/closures`);
  //       }
  //       const cupsById = response.find((item) => item.cups === cupsID);
  //       dispatch(updateClientCUPS(cupsById || firstCups));
  //       dispatch(fetchClientCUPSListSuccess(response));
  //     }
  //   });
  // }, [cupsID, role]);

  useEffect(() => {
    if (role !== Roles.CLIENT) return;
    if (cupsSelected) {
      if (cupsSelected.cups !== cupsID) {
        navigate(`/client/cim/${cupsSelected.cups}/closures`);
      }
    }
  }, [cupsSelected, role]);

  useEffect(() => {
    // Fetch ranges
    if (!cupsID) return;
    setLoadingRanges(true);
    CIMServiceInstance.getClosuresRanges(cupsID).then((response) => {
      const parsed = response.datos.map((item, index) => {
        const range = `${item.inicio} a ${item.fin}`;
        return { id: index, label: range, value: { inicio: item.inicio, fin: item.fin } };
      });
      setRanges(parsed);
      setLoadingRanges(false);
    });
  }, [cupsID]);

  useEffect(() => {
    if (!ranges) return;
    if (ranges.length > 0) {
      setSelectedRange(ranges[0]);
    }
  }, [ranges]);

  const handleSubmit = () => {
    if (!cupsID) return;
    setLoadingData(true);
    CIMServiceInstance.exportCierresData({
      cups: cupsID,
      fechaInicio: selectedRange.value.inicio,
      fechaFin: selectedRange.value.fin,
    })
      .then((response) => {
        const { data } = response;
        const a = document.createElement('a');
        a.href = `data:text/csv;charset=utf-8,${data}`;
        a.textContent = 'download';
        a.download = `${new Date().toLocaleDateString()}_${
          I18n.currentLocale() === 'ca' ? 'Tancaments' : 'Cierres'
        }.csv`;
        a.click();
        a.remove();
        setLoadingData(false);
      })
      .catch(() => {
        toast.error(I18n.t('CIM.ClosuresPage.errorFetching'));
      });
  };

  return (
    <div className={styles.root}>
      <div className={styles.menu}>
        {(() => {
          if (loadingRanges) {
            return <Spinner size={60} />;
          }
          if (ranges && selectedRange && ranges?.length > 0) {
            return (
              <DropdownBase
                label={I18n.t('CIM.ClosuresPage.pickerLabel')}
                values={ranges}
                value={selectedRange}
                onChange={(element: any) => {
                  setSelectedRange(element);
                }}
              />
            );
          }
          return <span>{I18n.t('CIM.ClosuresPage.noClosures')}</span>;
        })()}
        <div className={styles.export_button}>
          <Button
            onClick={() => handleSubmit()}
            disabled={selectedRange === null}
            type="button"
            text="EXPORTAR EN .CSV"
            variant="primary"
          />
        </div>
      </div>
      <div>{loadingData ? <FullPageSyncLoader icon /> : null}</div>
    </div>
  );
};

export default CIMClosuresPage;
