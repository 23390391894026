import { Serializable } from 'types/interfaces';

interface ChartDataApiI {
  t: number;
  a: number;
  e: number;
  g: number;
  r: number;
}

export interface ChartDataI {
  x: number;
  y: number;
}

interface TeledectionApiI {
  detecciones: Array<ChartDataApiI>;
  acumulados: {
    generacion: number;
    excedente: number;
    autoconsumo: number;
    red: number;
  };
}

export default class TeledectionData implements Serializable<TeledectionData> {
  generationData: Array<ChartDataI>;

  selfConsumptionData: Array<ChartDataI>;

  shirnkData: Array<ChartDataI>;

  networkonData: Array<ChartDataI>;

  totals: {
    selfconsumption: number;
    shrink: number;
    generation: number;
    network: number;
  };

  deserialize(input: TeledectionApiI): TeledectionData {
    if (!input) {
      return this;
    }

    this.generationData = input.detecciones.map(
      (detection): ChartDataI => ({
        x: detection.t,
        y: detection.g,
      }),
    );
    this.selfConsumptionData = input.detecciones.map(
      (detection): ChartDataI => ({
        x: detection.t,
        y: detection.a,
      }),
    );
    this.shirnkData = input.detecciones.map(
      (detection): ChartDataI => ({
        x: detection.t,
        y: -detection.e,
      }),
    );
    this.networkonData = input.detecciones.map(
      (detection): ChartDataI => ({
        x: detection.t,
        y: detection.r,
      }),
    );
    this.totals = {
      selfconsumption: input.acumulados.autoconsumo || 0,
      shrink: input.acumulados.excedente || 0,
      generation: input.acumulados.generacion || 0,
      network: input.acumulados.red || 0,
    };

    return this;
  }
}
