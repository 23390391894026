import { SavingData } from '.';

interface AhorroPorMesData {
  fecha: string;
  ahorro: number;
  importeSinElecsum: number;
  excedente: number;
  autoconsumida: number;
  importeElecsum: number;
  ahorroAcumulado: number;
}

interface SavingDataPropertiesI {
  ahorrosPorMes: AhorroPorMesData[];
  ahorroAcumuladoInicio: string;
  ahorroAcumuladoFin: string;
}

interface SavingDataAPII {
  datos: {
    esCliente: 'si' | 'no';
    comercializado: 'si' | 'no';
    individual: SavingDataPropertiesI;
    comunidad: SavingDataPropertiesI;
  };
  habilitado: 'si' | 'no';
}

export default class SavingObject {
  isClient: boolean;

  comercializado: boolean;

  individual: SavingData;

  comunity: SavingData;

  showWidget: boolean;

  deserialize(input: SavingDataAPII): SavingObject {
    if (!input) {
      this.showWidget = false;
      return this;
    }

    this.isClient = input.datos.esCliente === 'si';

    this.comercializado = input.datos.comercializado === 'si';

    this.individual = new SavingData().deserialize(input.datos.individual);
    this.comunity = new SavingData().deserialize(input.datos.comunidad);

    this.showWidget = input.habilitado === 'si';

    return this;
  }
}
