import LoadingButton from 'components/LoadingButton/LoadingButton';
import React from 'react';
import styles from './ModalMatcher.module.scss';

const ModalMatcher = (props: any): JSX.Element => {
  const { title, body, onClick, disabled, testbutton, onClick2, loading } = props;

  return (
    <div className={styles.modal} id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel">
      <div className={styles.modaldialog} role="document">
        <div className={styles.modalcontent}>
          <div className={styles.modalheader}>
            <h5 className={styles.modaltitle} id="exampleModalLabel">
              {title}
            </h5>
            <button type="button" className={styles.close} data-dismiss="modal" aria-label="Close" onClick={onClick2}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className={styles.modalbody}>{body}</div>
          <div style={{ display: 'flex', justifyContent: 'end', padding: '15px' }} className={styles.footer}>
            {testbutton && (
              <div className={styles.botonModal}>
                <LoadingButton
                  type="button"
                  loading={loading}
                  disabled={disabled}
                  text={testbutton}
                  onClick={onClick}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalMatcher;
