import RootReducer from 'redux/reducers';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import RootSaga from 'redux/sagas';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(RootReducer, {}, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(RootSaga);

export default store;
