export interface ProdGuaranteeDataAPII {
  años: Array<YearlyGuaranteeEvolutionAPII>;
}

interface YearlyGuaranteeEvolutionAPII {
  numero: number;
  garantia: number;
  generacion: number;
  meses: Array<GenerationGuaranteeDataAPII>;
}

interface GenerationGuaranteeDataAPII {
  mes: number;
  año: number;
  garantia: number;
  generacion: number;
}

export interface YearlyGuaranteeEvolution {
  year: number;
  guarantee: number;
  generation: number;
  guaranteeData: Array<ChartI>;
  generationData: Array<ChartI>;
}

export interface ChartI {
  x: Date;
  y: number;
}

export default class ProdGuaranteeEvolutionData {
  years: Array<YearlyGuaranteeEvolution>;

  deserialize(input: ProdGuaranteeDataAPII): ProdGuaranteeEvolutionData {
    if (!input) {
      return this;
    }

    this.years = input.años.map(
      (yearEvolution: YearlyGuaranteeEvolutionAPII): YearlyGuaranteeEvolution => ({
        year: yearEvolution.numero,
        guarantee: yearEvolution.garantia,
        generation: yearEvolution.generacion,
        guaranteeData: yearEvolution.meses.map((monthlyData: GenerationGuaranteeDataAPII) => ({
          x: new Date(monthlyData.año, monthlyData.mes),
          y: monthlyData.garantia,
        })),
        generationData: yearEvolution.meses.map((monthlyData: GenerationGuaranteeDataAPII) => ({
          x: new Date(monthlyData.año, monthlyData.mes),
          y: monthlyData.generacion,
        })),
      }),
    );

    return this;
  }
}
