import { GuardInterface } from 'types/interfaces';

import { authServiceInstance, AuthService } from 'services';
import WidgetPermission from 'types/enums/WidgetPermission';

class IsClientGuard implements GuardInterface {
  private authService: AuthService;

  private redirectUrl = '/home';

  constructor() {
    this.authService = authServiceInstance;
  }

  canActivate(): boolean {
    const isUserLogged = this.authService.isLoggedIn();
    const { user, permisos } = this.authService;

    const hasPermission = permisos && permisos.includes(WidgetPermission.ACCESO_WEB); // Cambia ACCESO_WEB por el permiso que deseas verificar

    const hasPermissionTecnicos = permisos && permisos.includes(WidgetPermission.ACCESO_WEB_TECNICOS);

    if (!isUserLogged) {
      return false;
    }

    if (!hasPermission) {
      this.authService.setUser(null);
      this.redirectUrl = '/'; // Establece el URL de redirección a la pantalla principal
      return false;
    }

    if (hasPermissionTecnicos) {
      this.redirectUrl = '/tecnic/dashboard'; // Establece el URL de redirección a la pantalla principal
      return false;
    }

    return user !== null && !user.isAdmin();
  }

  getRedirectUrl(): string | null {
    return this.redirectUrl;
  }
}

const isClientGuard = new IsClientGuard();

export default isClientGuard;
