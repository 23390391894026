import React from 'react';
import cx from 'classnames';
import styles from './SectionWrapper.module.scss';

interface SectionWrapperPropsI {
  children: any;
  customClassname?: string;
}

const SectionWrapper = (props: SectionWrapperPropsI): JSX.Element => {
  const { children, customClassname } = props;
  return <div className={cx(styles.container, customClassname)}>{children}</div>;
};

export default SectionWrapper;
