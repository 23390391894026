class CsvService {
  convertToCSV(data: any) {
    const headers = Object.keys(data);
    const values = Object.values(data);
    const csv = `${headers.join(',')}\n${values.join(',')}`;
    return csv;
  }

  downloadCSV(csv: any) {
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${new Date().toLocaleDateString()}_ExportData.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  exportCSV(data: any) {
    const csvData = this.convertToCSV(data);
    this.downloadCSV(csvData);
  }
}

const csvService: CsvService = new CsvService();

export default csvService;
