import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import React, { createRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CommunityCustomOnboarding } from 'types/models';
import { I18n } from 'core';
import { navigate } from 'navigation';
import { toast } from 'react-toastify';
import { CommunityCustomOnboardingServiceInstance } from 'services';
import { LoadingButton } from 'components';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import Button from 'components/Button/Button';
import customToast from 'components/CustomToast/CustomToast';

import { useFetchCommunity } from 'hooks';
import styles from './comunidades-custom-onboarding-process-page.module.scss';
import ComunidadesCustomOnboardingHorario from './components/comunidades-custom-onboarding-horario/comunidades-custom-onboarding-horario';

export type SaveDataHandle = {
  getData: () => void;
};

export const ComunidadesCustomOnboardingProcessPageHorario = (): JSX.Element => {
  const { communityID } = useParams<{ communityID: string }>();

  const [loading, setLoading] = useState<boolean>(false);
  const [communityCustomOnboarding, setCommunityCustomOnboarding] = useState<CommunityCustomOnboarding | undefined>(
    undefined,
  );

  const customOnboardingRef = createRef<SaveDataHandle>();

  const getCustomOnboardingData = async (id: string): Promise<void> => {
    setLoading(true);
    try {
      const customOnboarding = await CommunityCustomOnboardingServiceInstance.getCommunityCustomOnboarding(id);
      if (customOnboarding) {
        setCommunityCustomOnboarding(customOnboarding);
      }
    } catch (error) {
      toast.error(I18n.t('ComunidadesCustomOnboardingProcess.errors.errorGettingCustomOnboardingData'));
    } finally {
      setLoading(false);
    }
  };
  const fetchCommunityData = useFetchCommunity(communityID);

  useEffect(() => {
    if (!communityID) return;
    getCustomOnboardingData(communityID);
  }, []);
  useEffect(() => {
    fetchCommunityData();
  }, [fetchCommunityData]);
  const updateHorario = async (communityCustomOnboardingUpdate: any): Promise<void> => {
    if (communityCustomOnboardingUpdate.franja !== '') {
      try {
        await CommunityCustomOnboardingServiceInstance.updateCommunityCustomOnboardingHorarios(
          communityID,
          communityCustomOnboarding,
        );

        customToast.success(I18n.t('ComunidadesCustomOnboardingProcess.updateSuccess'));

        getCustomOnboardingData(communityID);
      } catch (error) {
        toast.error(I18n.t('ComunidadesCustomOnboardingProcess.errors.updating'));
      }
    }
  };

  if (loading) return <FullPageSyncLoader icon />;
  return (
    <div className={styles.container}>
      <SectionWrapper>
        <>
          {communityCustomOnboarding && (
            <ComunidadesCustomOnboardingHorario
              ref={customOnboardingRef}
              communityId={communityID}
              communityCustomOnboarding={communityCustomOnboarding}
              saveDetails={(customOnboarding) => updateHorario(customOnboarding)}
            />
          )}
        </>

        <div className={styles.positionBotonoes}>
          <div className={styles.divBoton}>
            <Button
              type="button"
              onClick={(): Promise<void> => navigate('/tecnic/communities')}
              variant="secondary"
              text={I18n.t('Cancel')}
            />
            <LoadingButton type="submit" text={I18n.t('Save')} onClick={() => customOnboardingRef.current?.getData()} />
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
};
