import { Serializable } from '../../interfaces/serializable';

export interface MeasuremenRegistersMaximeterAPII {
  datos: Array<Array<string | number | Date>>;
  habilitado: string;
}

export default class MeasuremenRegistersMaximeterObject implements Serializable<MeasuremenRegistersMaximeterObject> {
  datos: Array<{ fecha: Date; activa: number; contratada: number }>;

  habilitado: string;

  deserialize(input: MeasuremenRegistersMaximeterAPII): MeasuremenRegistersMaximeterObject {
    if (!input) {
      return this;
    }

    this.datos = input.datos
      ? input.datos.map((item) => {
          const inputFecha = item[0] as Date;
          const inputActiva = item[1] as number;
          const inputContratada = item[2] as number;
          return {
            fecha: inputFecha || new Date(),
            activa: inputActiva || 0,
            contratada: inputContratada || 0,
          };
        })
      : [];

    this.habilitado = input.habilitado || 'no';

    return this;
  }
}
