import configuration from 'config';
import { AxiosResponse } from 'axios';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  Installation,
  InstDataStudy,
  ProdEnergyData,
  ProdGuaranteeEvolutionData,
  ProdGuaranteeDataAPII,
  InstallationAPI,
  InstDataStudyAPI,
  ProdEnergyProductionDataDaily,
  ProdEnergyProductionDataDailyAPII,
  ProdEnergyProductionDataMonthlyAPII,
  ProdEnergyProductionDataMonthly,
  ProdEnergyProductionDataYearly,
  ProdEnergyProductionDataYearlyAPII,
  ProdEnergyProductionDataHistorical,
  ProdEnergyProductionDataHistoricalAPII,
} from 'types/models';

import { clientServiceInstance } from 'services';
import apiService from './ApiService';
import {
  INSTALLATION_MOCK,
  STUDIODATA_MOCK,
  PROD_GUARANTEE_EVOLUTION_DATA_MOCK,
  PROD_ENERGY_PRODUCTION_DATA_MOCK,
  PROD_ENERGY_PRODUCTION_M_DATA_MOCK,
  PROD_ENERGY_PRODUCTION_H_DATA_MOCK,
} from '../../mocks';
import PROD_ENERGY_PRODUCTION_Y_DATA_MOCK from '../../mocks/prodEnergyProductionY-mock';

export interface InstallationSearchI {
  tipo: 'cliente' | 'instalacion';
  cups?: string;
  idInstalacion: string;
  nombreInstalacion: string;
  nombre: string;
  domicilio: string;
  poblacion: string;
  provincia: string;
}

export interface Estimaciones {
  fecha: string;
  estimacion: number;
}
export class InstallationService {
  private readonly INSTALLATION_KEY = 'installationKey';

  private installationSubject = new BehaviorSubject<Installation | null>(null);

  readonly installation$: Observable<Installation | null> = this.installationSubject.asObservable();

  private apiUrl = configuration.apiUrl;

  readonly http = apiService;

  constructor() {
    this.init();
  }

  private init(): void {
    if (configuration.useMocks) {
      this.installation = new Installation().deserialize(INSTALLATION_MOCK);
    } else {
      this.installation = this.getStoredInstallation();
    }
  }

  private getStoredInstallation(): Installation | null {
    let installation = null;
    const data = localStorage.getItem(this.INSTALLATION_KEY);

    if (data) {
      try {
        installation = new Installation().deserialize(JSON.parse(data));
      } catch (error) {
        installation = null;
      }
    }

    return installation;
  }

  get installation(): Installation | null {
    return this.installationSubject.getValue();
  }

  set installation(installation: Installation | null) {
    if (installation) {
      localStorage.setItem(this.INSTALLATION_KEY, JSON.stringify(installation));
      this.installationSubject.next(installation);
    } else {
      localStorage.removeItem(this.INSTALLATION_KEY);
      this.installationSubject.next(null);
    }
  }

  async getAllInstallation(textSearch: string = ''): Promise<Installation[]> {
    const url = `${this.apiUrl}/fv/instalacion/`;
    const params = new URLSearchParams();

    if (textSearch) {
      params.append('buscar', textSearch);
    }

    const response: AxiosResponse<{ datos: InstallationAPI[] }> = await this.http.get(url, { params });
    const installation = new Installation().deserializeArray(response.data.datos);

    return installation;
  }

  public async getAllInstallationCount(): Promise<number> {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/instalacion/count/`);
    return response.data.datos.numeroInstalaciones;
  }

  async getInstallation(installationId: string): Promise<Installation> {
    const url = `${this.apiUrl}/fv/instalacion/${installationId}/`;

    const response: AxiosResponse<{
      datos: InstallationAPI;
    }> = await this.http.get(url);

    const installation = new Installation().deserialize({
      id: installationId,
      ...response.data.datos,
    });

    return installation;
  }

  async putInstallation(installationId: string, installation: Installation): Promise<unknown> {
    const url = `${this.apiUrl}/fv/instalacion/${installationId}/`;

    const installationAPI = installation.serialize();

    const response: AxiosResponse<number> = await this.http.put(url, installationAPI);

    return response;
  }

  async getInstallationDataStudy(installationId: string): Promise<InstDataStudy> {
    if (configuration.useMocks) {
      return new InstDataStudy().deserialize(STUDIODATA_MOCK);
    }

    const url = `${this.apiUrl}/fv/instalacion/${installationId}/estudio-garantia/`;

    const response: AxiosResponse<{
      datos: InstDataStudyAPI;
    }> = await this.http.get(url);

    const installationDataStudy = new InstDataStudy().deserialize(response.data.datos);

    return installationDataStudy;
  }

  async updateInstallationDataStudy(
    installationId: string,
    guaranteeCoeficient: number | null,
    estimations: Array<Estimaciones>,
  ): Promise<unknown> {
    const url = `${this.apiUrl}/fv/instalacion/${installationId}/estudio-garantia/`;

    const dataStudioToUpdate = {
      coeficienteCalculoDeGarantia: Number(guaranteeCoeficient) || 0,
      estimaciones: estimations,
    };

    const response: AxiosResponse<number> = await this.http.put(url, dataStudioToUpdate);

    return response;
  }

  public async getProductionEnergyData(id: string, cups?: string): Promise<ProdEnergyData> {
    const url = `${this.apiUrl}/fv/instalacion/${id}/energia/`;

    const response: AxiosResponse = await this.http.get(url, {
      params: {
        cups,
      },
    });

    return new ProdEnergyData().deserialize(response.data.datos);
  }

  public async getProdGuaranteeEvolutionData(id: string, cups?: string): Promise<ProdGuaranteeEvolutionData> {
    const url = `${this.apiUrl}/fv/instalacion/${id}/evolucion-garantia/`;

    if (configuration.useMocks) {
      const prodGuaranteeEvolutionData = new ProdGuaranteeEvolutionData().deserialize(
        PROD_GUARANTEE_EVOLUTION_DATA_MOCK,
      );
      return prodGuaranteeEvolutionData;
    }
    const response: AxiosResponse<{
      datos: ProdGuaranteeDataAPII;
    }> = await this.http.get(url, {
      params: {
        cups,
      },
    });

    const prodGuaranteeEvolutionData = new ProdGuaranteeEvolutionData().deserialize(response.data.datos);

    return prodGuaranteeEvolutionData;
  }

  public async getProdEnergyProductionDataDaily(
    id: string,
    year: string,
    month: string,
    day: string,
    cups?: string,
  ): Promise<ProdEnergyProductionDataDaily> {
    const url = `${this.apiUrl}/fv/instalacion/${id}/produccion/diaria/${year}/${month}/${day}/`;
    if (configuration.useMocks) {
      const prodEnergyProductionDataDaily = new ProdEnergyProductionDataDaily().deserialize(
        PROD_ENERGY_PRODUCTION_DATA_MOCK,
      );
      return prodEnergyProductionDataDaily;
    }
    const response: AxiosResponse<{
      datos: ProdEnergyProductionDataDailyAPII;
    }> = await this.http.get(url, { params: { cups } });

    const prodEnergyProductionDataDaily = new ProdEnergyProductionDataDaily().deserialize(response.data.datos);

    return prodEnergyProductionDataDaily;
  }

  public async getProdEnergyProductionDataMonthly(
    id: string,
    year: string,
    month: string,
  ): Promise<ProdEnergyProductionDataMonthly> {
    const url = `${this.apiUrl}/fv/instalacion/${id}/produccion/mensual/${year}/${month}/`;
    let cups;
    if (clientServiceInstance.client) {
      cups = clientServiceInstance.client.cups;
    }
    if (configuration.useMocks) {
      const prodEnergyProductionDataMonthly = new ProdEnergyProductionDataMonthly().deserialize(
        PROD_ENERGY_PRODUCTION_M_DATA_MOCK,
      );
      return prodEnergyProductionDataMonthly;
    }
    const response: AxiosResponse<{
      datos: ProdEnergyProductionDataMonthlyAPII;
    }> = await this.http.get(url, { params: { cups } });

    const prodEnergyProductionDataMonthly = new ProdEnergyProductionDataMonthly().deserialize(response.data.datos);

    return prodEnergyProductionDataMonthly;
  }

  public async getProdEnergyProductionDataYearly(id: string, year: string): Promise<ProdEnergyProductionDataYearly> {
    const url = `${this.apiUrl}/fv/instalacion/${id}/produccion/anual/${year}/`;
    let cups;
    if (clientServiceInstance.client) {
      cups = clientServiceInstance.client.cups;
    }
    if (configuration.useMocks) {
      const prodEnergyProductionDataYearly = new ProdEnergyProductionDataYearly().deserialize(
        PROD_ENERGY_PRODUCTION_Y_DATA_MOCK,
      );
      return prodEnergyProductionDataYearly;
    }
    const response: AxiosResponse<{
      datos: ProdEnergyProductionDataYearlyAPII;
    }> = await this.http.get(url, { params: { cups } });

    const prodEnergyProductionDataYearly = new ProdEnergyProductionDataYearly().deserialize(response.data.datos);

    return prodEnergyProductionDataYearly;
  }

  public async getProdEnergyProductionDataHistorical(id: string): Promise<ProdEnergyProductionDataHistorical> {
    const url = `${this.apiUrl}/fv/instalacion/${id}/produccion/historica/`;
    let cups;
    if (clientServiceInstance.client) {
      cups = clientServiceInstance.client.cups;
    }
    if (configuration.useMocks) {
      // eslint-disable-next-line max-len
      const prodEnergyProductionDataHistorical = new ProdEnergyProductionDataHistorical().deserialize(
        PROD_ENERGY_PRODUCTION_H_DATA_MOCK,
      );
      return prodEnergyProductionDataHistorical;
    }
    const response: AxiosResponse<{
      datos: ProdEnergyProductionDataHistoricalAPII;
    }> = await this.http.get(url, { params: { cups } });

    const prodEnergyProductionDataHistorical = new ProdEnergyProductionDataHistorical().deserialize(
      response.data.datos,
    );

    return prodEnergyProductionDataHistorical;
  }
}

const installationServiceInstance = new InstallationService();

export default installationServiceInstance;
