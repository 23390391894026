import React from 'react';
import { SectionTitle } from 'components';
import i18n from 'core/i18n';
import styles from './informe.module.scss';

interface GlobalSectionInformeProps {
  text: string;
  resultInformeGlobal: Array<{ nombre: string; value: string | number }>;
}

const GlobalSectionInforme = ({ text, resultInformeGlobal }: GlobalSectionInformeProps): JSX.Element => (
  <div className={styles.resultadoInforme}>
    <SectionTitle text={text} />
    {resultInformeGlobal.map((result, index) => {
      const { nombre, value } = result;
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className={styles.detailContainer}>
          <div className={styles.detail}>
            <p className={styles.detailTitle}>{i18n.t(`PerfilListPage.informe.${nombre}`)}:</p>
            <p className={styles.arrow}>→</p>
            <p className={styles.detailValue}>{value}</p>
          </div>
        </div>
      );
    })}
  </div>
);

export default GlobalSectionInforme;
