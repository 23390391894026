import { ProcessStatus } from 'types/enums';
import { ClientCupsState } from 'types/state';
import { CLIENT_CUPS_ACTIONS } from 'redux/actions/types';

const initialState: ClientCupsState = {
  selectedCups: null,
  selectedCupsStatus: ProcessStatus.STANDBY,
  cupsList: null,
  cupsListStatus: ProcessStatus.STANDBY,
};

function ClientCUPSReducer(state = initialState, action: any): ClientCupsState {
  switch (action.type) {
    case CLIENT_CUPS_ACTIONS.FETCH_CUPS:
      return {
        ...state,
        selectedCupsStatus: ProcessStatus.LOADING,
        selectedCups: null,
      };

    case CLIENT_CUPS_ACTIONS.FETCH_CUPS_OK: {
      const { payload } = action;
      return {
        ...state,
        selectedCups: payload,
        selectedCupsStatus: ProcessStatus.SUCCESS,
      };
    }

    case CLIENT_CUPS_ACTIONS.FETCH_CUPS_ERROR:
      return {
        ...state,
        selectedCups: null,
        selectedCupsStatus: ProcessStatus.ERROR,
      };

    case CLIENT_CUPS_ACTIONS.UPDATE_CUPS: {
      const { payload } = action;
      return {
        ...state,
        selectedCups: payload,
      };
    }

    case CLIENT_CUPS_ACTIONS.FETCH_CUPS_LIST:
      return {
        ...state,
        cupsList: null,
        cupsListStatus: ProcessStatus.LOADING,
      };

    case CLIENT_CUPS_ACTIONS.FETCH_CUPS_LIST_OK: {
      const { payload } = action;
      return {
        ...state,
        cupsList: payload,
        cupsListStatus: ProcessStatus.SUCCESS,
      };
    }

    case CLIENT_CUPS_ACTIONS.FETCH_CUPS_LIST_ERROR:
      return {
        ...state,
        cupsList: null,
        cupsListStatus: ProcessStatus.ERROR,
      };

    case CLIENT_CUPS_ACTIONS.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default ClientCUPSReducer;
