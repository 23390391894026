import configuration from 'config';
import { AxiosResponse } from 'axios';

import { BehaviorSubject, Observable } from 'rxjs';

import apiService from './ApiService';

import { CupsInfo, CupsInfoAPI } from '../../types/models';
import { CUPSINFO_MOCK, CUPSINFO_LIST_MOCK } from '../../mocks';

export enum CupsInfoValues {
  MINE = 'mine',
  COMMUNITY = 'community',
}

export class CupsService {
  private readonly CUPS_KEY = 'cupsKey';

  private readonly CUPSLIST_KEY = 'cupsListKey';

  private cupsSubject = new BehaviorSubject<CupsInfo | null>(null);

  readonly cups$: Observable<CupsInfo | null> = this.cupsSubject.asObservable();

  private cupsListSubject = new BehaviorSubject<CupsInfo[] | null>([]);

  readonly cupsList$: Observable<Array<CupsInfo> | null> = this.cupsListSubject.asObservable();

  private cupsShowInfoFromSubject = new BehaviorSubject<CupsInfoValues>(CupsInfoValues.MINE);

  readonly cupsShowInfoFrom$: Observable<CupsInfoValues> = this.cupsShowInfoFromSubject.asObservable();

  private apiUrl = configuration.apiUrl;

  readonly http = apiService;

  constructor() {
    this.init();
  }

  private init(): void {
    if (configuration.useMocks) {
      this.cups = new CupsInfo().deserialize(CUPSINFO_MOCK);
      this.cupsList = new CupsInfo().deserializeArray(CUPSINFO_LIST_MOCK);
    } else {
      this.cups = this.getStoredCups();
      this.cupsList = this.getStoredCupsList();
    }
  }

  private getStoredCups(): CupsInfo | null {
    let cups = null;
    const data = localStorage.getItem(this.CUPS_KEY);

    if (data) {
      try {
        cups = new CupsInfo().deserialize(JSON.parse(data));
      } catch (error) {
        cups = null;
      }
    }

    return cups;
  }

  private getStoredCupsList(): Array<CupsInfo> {
    let cupsList: Array<CupsInfo> = [];
    const data = localStorage.getItem(this.CUPSLIST_KEY);

    if (data) {
      try {
        cupsList = new CupsInfo().deserializeArray(JSON.parse(data));
      } catch (error) {
        cupsList = [];
      }
    }

    return cupsList;
  }

  get cups(): CupsInfo | null {
    return this.cupsSubject.getValue();
  }

  set cups(cups: CupsInfo | null) {
    if (cups) {
      this.cupsSubject.next(cups);
      this.cupsShowInfoFrom = CupsInfoValues.MINE;
      localStorage.setItem(this.CUPS_KEY, JSON.stringify(cups));
    } else {
      this.cupsSubject.next(null);
      localStorage.removeItem(this.CUPS_KEY);
    }
  }

  private setCups(cups: CupsInfo | null): void {
    this.cupsSubject.next(cups);
  }

  get cupsList(): CupsInfo[] | null {
    return this.cupsListSubject.getValue();
  }

  set cupsList(cupsList: CupsInfo[] | null) {
    if (cupsList) {
      this.cupsListSubject.next(cupsList);
      localStorage.setItem(this.CUPSLIST_KEY, JSON.stringify(cupsList));
    } else {
      localStorage.removeItem(this.CUPSLIST_KEY);
      this.cupsListSubject.next(null);
    }
  }

  get cupsShowInfoFrom(): CupsInfoValues {
    return this.cupsShowInfoFromSubject.getValue();
  }

  set cupsShowInfoFrom(cupsShowInfoFrom: CupsInfoValues) {
    this.cupsShowInfoFromSubject.next(cupsShowInfoFrom);
  }

  async getCupsFromUserId(userId: string): Promise<Array<CupsInfo>> {
    const url = `${this.apiUrl}/fv/puntos-de-suministro/${userId.trim().toUpperCase()}/`;

    const response: AxiosResponse<{
      datos: Array<CupsInfoAPI>;
    }> = await this.http.get(url);
    const userClients = new CupsInfo().deserializeArray(response.data.datos);
    return userClients;
  }
}

const cupsServiceInstance = new CupsService();

export default cupsServiceInstance;
