import { ChartDataI } from '../index';

export interface ProdEnergyProductionDataYearlyAPII {
  meses: Array<ProdEnergyProductionDataTotalMonthlyAPII>;
}

export interface ProdEnergyProductionDataTotalMonthlyAPII {
  mes: number;
  generacion: number;
  garantia: number;
  diferenciaGarantia: number;
  estimacion: number;
  diferenciaEstimacion: number;
  diferenciaGeneracion: number;
}

export interface ProdEnergyProductionDataTotalMonthly {
  month: number;
  generation: number;
  guarantee: number;
  diffGuarantee: number;
  estimate: number;
  diffEstimate: number;
  diffGeneration: number;
}

export default class ProdEnergyProductionDataYearly {
  months: Array<ProdEnergyProductionDataTotalMonthly>;

  generationData: Array<ChartDataI>;

  guaranteeData: Array<ChartDataI>;

  estimateData: Array<ChartDataI>;

  deserialize(input: ProdEnergyProductionDataYearlyAPII): ProdEnergyProductionDataYearly {
    if (!input) {
      return this;
    }

    this.months = input.meses.map(
      (data: ProdEnergyProductionDataTotalMonthlyAPII): ProdEnergyProductionDataTotalMonthly => ({
        month: data.mes,
        generation: data.generacion,
        guarantee: data.garantia,
        diffGuarantee: data.diferenciaGarantia,
        estimate: data.estimacion,
        diffEstimate: data.diferenciaEstimacion,
        diffGeneration: data.diferenciaGeneracion,
      }),
    );
    this.generationData = input.meses.map(
      (mes): ChartDataI => ({
        x: mes.mes,
        y: mes.generacion,
      }),
    );
    this.guaranteeData = input.meses.map(
      (mes): ChartDataI => ({
        x: mes.mes,
        y: mes.garantia,
      }),
    );
    this.estimateData = input.meses.map(
      (mes): ChartDataI => ({
        x: mes.mes,
        y: mes.estimacion,
      }),
    );

    return this;
  }
}
