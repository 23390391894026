import { Serializable } from 'types/interfaces';

export interface RolInfoPermisosAPI {
  id: number;
  nombre: string;
  codeNombre: string;
}

export interface RolInfoPermisosUpdateAPI {
  nombre: string;
}

export default class RolInfoPermisos implements Serializable<RolInfoPermisos> {
  id: number;

  nombre: string;

  codeNombre: string;

  deserialize(input: RolInfoPermisosAPI): RolInfoPermisos {
    if (!input) {
      return this;
    }
    this.id = input.id;

    this.nombre = input.nombre || '';
    this.codeNombre = input.codeNombre || '';
    return this;
  }

  deserializeArray(inputArray: Array<RolInfoPermisosAPI>): Array<RolInfoPermisos> {
    const Permisos: Array<RolInfoPermisos> = inputArray.map((input) => new RolInfoPermisos().deserialize(input));

    return Permisos;
  }
}
