/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/button-has-type */
import React from 'react';
import cx from 'classnames';
import styles from './Button.module.scss';

interface ButtonI {
  bgcolor?: string;
  block?: boolean;
  className?: string;
  color?: string;
  disabled?: boolean;
  islink?: boolean;
  href?: string;
  loading?: boolean;
  newtab?: boolean;
  onClick?: CallableFunction;
  text?: string;
  type: 'button' | 'submit' | 'reset' | 'link';
  variant: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'reddelete';
  children?: React.ReactNode;
  icon?: React.ReactNode;
}

const Button = (props: ButtonI): JSX.Element => {
  const { disabled, type, icon, text, className, onClick, variant, href, block, newtab, children } = props;

  if (type === 'link') {
    return (
      <a
        className={cx(className, styles.root, {
          [styles.block]: block,
          [styles.primary]: variant === 'primary',
          [styles.secondary]: variant === 'secondary',
          [styles.tertiary]: variant === 'tertiary',
          [styles.quaternary]: variant === 'quaternary',
          [styles.reddelete]: variant === 'reddelete',
        })}
        rel={newtab ? 'noopener noreferrer' : undefined}
        target={newtab ? '_blank' : undefined}
        href={href}
      >
        {text}
      </a>
    );
  }
  return (
    <button
      className={cx(className, styles.root, {
        [styles.block]: block,
        [styles.primary]: variant === 'primary',
        [styles.secondary]: variant === 'secondary',
        [styles.tertiary]: variant === 'tertiary',
        [styles.quaternary]: variant === 'quaternary',
        [styles.reddelete]: variant === 'reddelete',
      })}
      type={type}
      disabled={disabled}
      onClick={(): void => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {icon}
      {text}
      {children}
    </button>
  );
};

export default Button;
