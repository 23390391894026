import { DataTable, EunoiaImageInput, LoadingButton, SectionTitle, TableHeading } from 'components';
import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import Button from 'components/Button/Button';
import ControlledInput from 'components/Input/NewInputs';
import I18n from 'i18n-js';
import { FormGroup, FormGroupProps } from 'core';
import { LoggingService } from 'services';
import { Colors } from 'styles';
import { toast } from 'react-toastify';
import { ReactComponent as LandscapeIcon } from 'assets/icons/Landscape.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg';
import UpdateTarifaServiceInstance from 'services/remote/UpdateTarifaService';
import { TabSwitchIcons, TabSwitchOption } from 'components/TabSwitchIcons/TabSwitchIcons';
import { ReactComponent as IndustrialIcon } from 'assets/icons/industrial.svg';
import { ReactComponent as ParticularIcon } from 'assets/icons/particular.svg';
import { GlobalStoreState } from 'types/state';
import { useSelector } from 'react-redux';
import { navigate } from 'navigation';
import { SolicitudActualizacionTarifaResponse } from 'types/interfaces/IdSolicitud';
import { Documento } from 'types/models/Documentos';
import styles from './UpdateTarifaPage.module.scss';

const UpdateTarifaPage = (props: any): JSX.Element => {
  // Props
  const {
    match: {
      // eslint-disable-next-line no-empty-pattern
      params: { cupsID },
    },
  } = props;

  const cupsSelected = useSelector((state: GlobalStoreState) => state.clientCUPS.selectedCups);

  const TABS_AVAILABLES: TabSwitchOption[] = [
    {
      id: '1',
      icon: <IndustrialIcon />,
      value: 'domestico',
      label: I18n.t('UpdateTarifaPage.domestico'),
    },
    {
      id: '2',
      icon: <ParticularIcon />,
      value: 'industrial',
      label: `${I18n.t('UpdateTarifaPage.industrial')} (> 15kW)`,
    },
  ];

  const [tabSelected, setTabSelected] = useState('domestico');

  const handleTabClick = (option: TabSwitchOption) => {
    setTabSelected(option.value as string);
  };

  const [file, setFile] = useState<File>();
  const [actualizar, setActualizar] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [idSolicitudActualizacion, setIdSolicitudActualizacion] = useState<SolicitudActualizacionTarifaResponse>();
  const [arrayImages, setArrayImages] = useState<Array<Documento>>();

  const getImagenesSolicitudActualizacion = async () => {
    if (idSolicitudActualizacion?.datos.id) {
      try {
        const documents = await UpdateTarifaServiceInstance.getFiles(idSolicitudActualizacion?.datos.id);
        setArrayImages(documents);
        setActualizar(false);
      } catch (error) {
        setSubmitDisabled(false);
        setLoading(false);
        setFile(undefined);
        setActualizar(false);
      }
    }
  };

  const settingFile = (file1: File): void => {
    if (file1) {
      setFile(file1);
      setIsButtonDisabled(false);
    }
  };

  const validateForm = (): boolean => {
    if (!file) {
      setSubmitDisabled(true);
      setErrors({});
      return false;
    }
    setSubmitDisabled(false);
    setErrors({});
    return true;
  };

  const submitFormImage = async (type: string): Promise<boolean> => {
    setLoading(true);
    setSubmitDisabled(true);

    try {
      if (file && idSolicitudActualizacion) {
        const response = await UpdateTarifaServiceInstance.addGaleryImage(
          idSolicitudActualizacion?.datos.id,
          file,
          type,
        );
        if (response === 201) {
          setActualizar(true);
          setSubmitDisabled(false);
          setFile(undefined);
          getImagenesSolicitudActualizacion();
        }
      }
    } catch (error) {
      setSubmitDisabled(false);
      setActualizar(true);

      setFile(undefined);
    }

    setLoading(false);
    setFile(undefined);

    return true;
  };

  const deleteImage = async (imagedel: any): Promise<void> => {
    // eslint-disable-next-line no-alert
    const accept = window.confirm(I18n.t('Are you sure you want to delete the image?'));

    if (!accept) {
      return;
    }
    try {
      if (idSolicitudActualizacion)
        await UpdateTarifaServiceInstance.deleteImage(idSolicitudActualizacion?.datos.id, imagedel.id);

      setSubmitDisabled(false);
      getImagenesSolicitudActualizacion();
    } catch (error) {
      toast.error(`${I18n.t('Error deleting image')}.`);
    }
  };

  const onSubmit = (): void => {
    if (validateForm()) {
      LoggingService.log('handleSubmit :: ok', { file });
      submitFormImage('galeria');
    } else {
      LoggingService.log('handleSubmit :: errors', { file, errors });
    }
  };

  const headings: Array<TableHeading> = [
    { label: I18n.t('Documentos'), type: 'image', key: 'url' },

    {
      label: I18n.t('Actions'),
      type: 'actions',
      key: '',
      actions: [
        {
          label: I18n.t('Delete'),
          icon: <TrashIcon height={25} width={25} fill={Colors.COLOR_ELECSUMGREEN} />,
          onActionClick: deleteImage,
        },
      ],
    },
  ];

  const MARGEMENSUAL = [I18n.t('UpdateTarifaPage.margeMensual')];
  const MARGEKW = [I18n.t('UpdateTarifaPage.margeKw')];

  const COMPENSACION = [I18n.t('UpdateTarifaPage.compensacionImporte')];

  const POWERS_ARRAY_INDUSTRIAL = [
    `${I18n.t('UpdateTarifaPage.p1')}`,
    `${I18n.t('UpdateTarifaPage.p2')}`,
    `${I18n.t('UpdateTarifaPage.p3')}`,
    `${I18n.t('UpdateTarifaPage.p4')}`,
    `${I18n.t('UpdateTarifaPage.p5')}`,
    `${I18n.t('UpdateTarifaPage.p6')}`,
  ];

  const POWERS_ARRAY_DOMESTICO = [
    `${I18n.t('UpdateTarifaPage.consumPunta')}`,
    `${I18n.t('UpdateTarifaPage.consumPla')}`,
    `${I18n.t('UpdateTarifaPage.consumVall')}`,
  ];

  const [indexadoValue, setindexadoValue] = useState(false);

  const indexadoOptions = [
    {
      label: I18n.t('UpdateTarifaPage.indexado'),
      type: 'checkbox',
      id: 'indexado',
      path: 'indexado',
      name: 'indexado',
      checked: indexadoValue,
      value: indexadoValue,
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleCheckBox<T>(path: string, value: string, id: string, checked: boolean): Promise<unknown> {
    await setindexadoValue(checked);

    return true;
  }

  let powersArray: string[];
  if (!indexadoValue && tabSelected === 'domestico') {
    powersArray = POWERS_ARRAY_DOMESTICO;
  } else if (!indexadoValue && tabSelected === 'industrial') {
    powersArray = POWERS_ARRAY_INDUSTRIAL;
  }

  const postSolicitudActualizacion = async () => {
    try {
      await UpdateTarifaServiceInstance.putSolicitudActualizacionTarifa().then((idSolicitud) => {
        setIdSolicitudActualizacion(idSolicitud);
      });
    } catch (error) {
      setSubmitDisabled(false);
      setLoading(false);
      setFile(undefined);
    }
  };

  const handleSubmitAll = async (values: any) => {
    const accept = window.confirm(I18n.t('UpdateTarifaPage.enviarSeguro'));

    if (!accept) {
      return;
    }

    const newSolicitud = {
      cups: cupsID as string,
      indexada: indexadoValue === true ? 'si' : 'no',
      tipoSolicitante: tabSelected,
      terminoDeEnergiaP1: values.terminoDeEnergiaP1 as number,
      terminoDeEnergiaP2: values.terminoDeEnergiaP2 as number,
      terminoDeEnergiaP3: values.terminoDeEnergiaP3 as number,
      terminoDeEnergiaP4: values.terminoDeEnergiaP4 as number,
      terminoDeEnergiaP5: values.terminoDeEnergiaP5 as number,
      terminoDeEnergiaP6: values.terminoDeEnergiaP6 as number,
      precioCompensacion: values.compensacion as number,
      margenMensual: values.marge1 as number,
      margenKWH: values.marge2 as number,
    };

    try {
      if (idSolicitudActualizacion) {
        await UpdateTarifaServiceInstance.patchSolicitudActualizacion(
          idSolicitudActualizacion.datos.id,
          newSolicitud,
        ).then(async () => {
          await UpdateTarifaServiceInstance.enviarSolicitudActualizacionTarifa(idSolicitudActualizacion.datos.id).then(
            () => {
              window.location.reload();
            },
          );
        });
      }
    } catch (error) {
      toast.error(I18n.t('UpdateTarifaPage.toastFallido'));
      setSubmitDisabled(false);
      setLoading(false);
      setFile(undefined);
    }
  };

  // Detectar cambio de CUPS seleccionado para redirigir a URL correcta
  useEffect(() => {
    if (cupsSelected) {
      if (cupsSelected.cups !== cupsID) {
        navigate(`/client/${cupsSelected.cups}/updateTarifa`);
      }
    }
  }, [cupsSelected]);

  // Llamar al POST de solicitud de actualizar tarifa al activar pantalla y
  // get de las imagenes de la solicitud
  useEffect(() => {
    postSolicitudActualizacion();
    getImagenesSolicitudActualizacion();
  }, []);

  return (
    <div className={styles.root}>
      <SectionTitle className={styles.menuForm} text={I18n.t('UpdateTarifaPage.dades')} />
      <p className={styles.menuForm}>{I18n.t('UpdateTarifaPage.descripcion')}</p>
      <div className={styles.menuForm}>
        <TabSwitchIcons
          value={TABS_AVAILABLES.find((option) => option.value === tabSelected) as TabSwitchOption}
          values={TABS_AVAILABLES}
          onChange={(item) => {
            handleTabClick(item);
          }}
        />
      </div>

      <div className={styles.menuForm}>
        <div className={styles.title}>{I18n.t('UpdateTarifaPage.regulada')}</div>
        <div className={styles.checkBoxes}>
          {indexadoOptions.map((formGroup: FormGroupProps) => (
            <FormGroup
              key={formGroup.id}
              label={formGroup.label}
              type={formGroup.type}
              id={formGroup.id}
              path={formGroup.path}
              name={formGroup.name}
              value={formGroup.value}
              checked={formGroup.checked}
              onChange={handleCheckBox}
            />
          ))}
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          terminoDeEnergiaP1: 0,
          terminoDeEnergiaP2: 0,
          terminoDeEnergiaP3: 0,
          terminoDeEnergiaP4: 0,
          terminoDeEnergiaP5: 0,
          terminoDeEnergiaP6: 0,
          marge1: 0,
          marge2: 0,
          compensacion: 0,
        }}
        onSubmit={handleSubmitAll}
      >
        {({ resetForm }) => (
          <Form className={styles.menuForm}>
            {indexadoValue ? (
              <div className={styles.title}>{I18n.t('UpdateTarifaPage.margenes')}</div>
            ) : (
              <div className={styles.title}>{I18n.t('UpdateTarifaPage.preuTerme')}</div>
            )}
            {indexadoValue ? (
              <div className={styles.containerMarges}>
                {MARGEMENSUAL.map((item) => (
                  <Field name="marge1">
                    {({ field }: any) => (
                      <ControlledInput
                        type="number"
                        value={field.value}
                        placeholder="0"
                        label={item}
                        onChange={field.onChange}
                        id="marge1"
                        name={field.name}
                      />
                    )}
                  </Field>
                ))}
                <p className={styles.containerO}>o</p>
                {MARGEKW.map((item) => (
                  <Field name="marge2">
                    {({ field }: any) => (
                      <ControlledInput
                        type="number"
                        value={field.value}
                        placeholder="0"
                        label={item}
                        onChange={field.onChange}
                        id="marge2"
                        name={field.name}
                      />
                    )}
                  </Field>
                ))}
              </div>
            ) : null}
            <div className={styles.powers_wrapper}>
              {powersArray &&
                powersArray.map((item, index) => (
                  <Field name={`terminoDeEnergiaP${index + 1}`}>
                    {({ field }: any) => (
                      <ControlledInput
                        type="number"
                        value={field.value}
                        placeholder="0"
                        label={item}
                        onChange={field.onChange}
                        id={`terminoDeEnergiaP${index + 1}`}
                        name={field.name}
                      />
                    )}
                  </Field>
                ))}
            </div>
            {!indexadoValue ? (
              <div>
                <div style={{ paddingBottom: '1rem' }} className={styles.title}>
                  {I18n.t('UpdateTarifaPage.compensacion')}
                </div>
                <div className={styles.powers_wrapper}>
                  {COMPENSACION.map((item) => (
                    <Field name="compensacion">
                      {({ field }: any) => (
                        <ControlledInput
                          type="number"
                          value={field.value}
                          placeholder="0"
                          label={item}
                          onChange={field.onChange}
                          id="compensacion"
                          name={field.name}
                        />
                      )}
                    </Field>
                  ))}
                </div>
              </div>
            ) : null}
            <div className={styles.actions}>
              <Button
                className={styles.btn}
                type="button"
                text="Reset"
                variant="secondary"
                onClick={() => {
                  resetForm();
                }}
              />
            </div>

            <div>
              <div className={styles.title}>{I18n.t('UpdateTarifaPage.añadeImagen')}</div>
              <div className={styles.formImages}>
                <div className={styles.fields}>
                  {actualizar || (
                    <EunoiaImageInput
                      id="img-galeria"
                      label={I18n.t('add all')}
                      note={I18n.t('Peso Documento')}
                      fileName=""
                      icon={<LandscapeIcon height={20} width={20} fill={Colors.COLOR_GRAY_400} />}
                      type="all"
                      action={settingFile}
                    />
                  )}
                </div>
                <div className={styles.actions}>
                  <LoadingButton
                    type="submit"
                    text={I18n.t('add docu')}
                    disabled={isButtonDisabled || submitDisabled}
                    loading={loading}
                    onClick={onSubmit}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="table-news">
                {arrayImages && arrayImages.length > 0 ? (
                  <DataTable headings={headings} rows={arrayImages} loading={loading} />
                ) : null}
              </div>
            </div>
            <div className={styles.btnFinal}>
              <LoadingButton
                type="submit"
                text={I18n.t('UpdateTarifaPage.enviar')}
                disabled={submitDisabled}
                loading={loading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateTarifaPage;
