import { HomeRouteGuard, LoggedInGuard, NotLoggedInGuard } from 'navigation/guards';
import { RouteInterface } from 'types/interfaces';
import { AuthLayout, BaseLayout, NoSidenavNoGradientLayout } from 'layouts';
import {
  ChangePasswordPage,
  ElecsumGreenWebPage,
  ForgotPasswordPage,
  LoginPage,
  RecoverPasswordPage,
  SignUpPage,
  TestTable,
} from 'pages';

const routes: Array<RouteInterface> = [
  {
    path: '/',
    exact: true,
    guards: [HomeRouteGuard],
  },
  {
    path: '/profile',
    name: 'User',
    layout: NoSidenavNoGradientLayout,
    guards: [LoggedInGuard],
    exact: true,
    component: ChangePasswordPage,
  },
  {
    path: '/auth',
    name: 'Auth',
    layout: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'Login',
        component: LoginPage,
        guards: [NotLoggedInGuard],
      },
      {
        path: '/signup',
        name: 'Signup',
        component: SignUpPage,
        guards: [NotLoggedInGuard],
      },
      {
        path: '/forgot-password',
        name: 'Forgot Password',
        component: ForgotPasswordPage,
        guards: [NotLoggedInGuard],
      },
    ],
  },
  {
    path: '/recover-password/:token',
    name: 'RecoverPassword',
    component: RecoverPasswordPage,
  },
  {
    path: '/egreen/:identificator',
    name: 'ElecsumGreen',
    component: ElecsumGreenWebPage,
  },
  {
    path: '/testTable',
    name: 'TestTable',
    layout: BaseLayout,
    component: TestTable,
  },
];

export default routes;
