import { TranslatableJSON } from 'types/interfaces/polyglot';
import { Serializable } from '../interfaces';

export interface ComunityCaracteristicasAPI {
  id: string;
  categoria: TranslatableJSON<string>;
  texto: TranslatableJSON<string>;
  valor: TranslatableJSON<string>;
}
export const translatableMockString: TranslatableJSON<string> = {
  es: '',
  ca: '',
};
export default class ComunityCaracteristicasNew implements Serializable<ComunityCaracteristicasNew> {
  id: string;

  categoria: {
    es: string;
    ca: string;
  };

  texto: {
    es: string;
    ca: string;
  };

  valor: {
    es: string;
    ca: string;
  };

  constructor() {
    this.categoria = {
      es: '',
      ca: '',
    };
    this.texto = {
      es: '',
      ca: '',
    };
    this.valor = {
      es: '',
      ca: '',
    };
  }

  deserialize(input: ComunityCaracteristicasAPI): ComunityCaracteristicasNew {
    if (!input) {
      return this;
    }
    this.id = input.id || '';
    this.categoria = {
      es: input.categoria.es,
      ca: input.categoria.ca,
    };
    this.texto = {
      es: input.texto.es,
      ca: input.texto.ca,
    };
    this.valor = {
      es: input.valor.es,
      ca: input.valor.ca,
    };

    return this;
  }

  serialize(): ComunityCaracteristicasAPI {
    return {
      id: this.id,
      categoria: this.categoria,
      texto: this.texto,
      // imagenUrl: this.image,
      valor: this.valor,
    } as unknown as ComunityCaracteristicasAPI;
  }

  setAttr<T>(key: string, value: T): void {
    Object.assign(this, { [key]: value });
  }

  deserializeArray(inputArray: Array<ComunityCaracteristicasAPI>): Array<ComunityCaracteristicasNew> {
    const ComunityCaracteristicas: Array<ComunityCaracteristicasNew> = inputArray.map((input) =>
      new ComunityCaracteristicasNew().deserialize(input),
    );

    return ComunityCaracteristicas;
  }
}
