import React from 'react';
import { ReactComponent as SmartMeterIcon } from 'assets/icons/community_share.svg';
import { Colors } from 'styles';
import styles from './Display.module.scss';

interface CommunityDisplayProps {
  name: string;
  cau: string;
  address: string;
}

export const CommunityDisplay = (props: CommunityDisplayProps): JSX.Element => {
  const { name, cau, address } = props;
  return (
    <div className={styles.root}>
      <div className={styles.icon}>
        <div className={styles.logo}>
          <SmartMeterIcon height={25} width={25} fill={Colors.COLOR_NOTIFICATIONS} />
        </div>
      </div>
      <div className={styles.data}>
        <p className={styles.title}>{`${name}`}</p>
        <p className={styles.subtitle}>{cau}</p>
        <p className={styles.quote}>{`${address}`}</p>
      </div>
    </div>
  );
};
