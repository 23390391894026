import { Serializable } from 'types/interfaces';

export interface SolicitudesAPI {
  id: string;
  nombre: string;
  apellidos: string;
  nif: string;
  email: string;
  domicilio: string;
  poblacion: string;
  provincia: string;
  codigoPostal: string;
  direccionCompleta: string;
  telefono: string;
  razonSocialEmpresa: string;
  nifEmpresa: string;
  latitud: number;
  longitud: number;
  cups: string;
  tipoSolicitante: string;
  tipoPropiedad: string;
  tipoConsumo: string;
  tarifaPVPC: string;
  margenMensual: number;
  margenKwH: number;
  tarifa: string;
  modalidadSolicitante: string;
  precioCompensacion: number;
  potenciaContratadaP1: number;
  potenciaContratadaP2: number;
  potenciaContratadaP3: number;
  potenciaContratadaP4: number;
  potenciaContratadaP5: number;
  potenciaContratadaP6: number;
  terminoDeEnergiaP1: number;
  terminoDeEnergiaP2: number;
  terminoDeEnergiaP3: number;
  terminoDeEnergiaP4: number;
  terminoDeEnergiaP5: number;
  terminoDeEnergiaP6: number;
  idComunidad: number;
  codigoComercializadora: string;
  codigoPostalFiscal: string;
  domicilioFiscal: string;
  permisoDatadis: string;
  contratoOTC: string;
  nombreComunidad: string;
  OTC: string;
  pasoCompletado: number;
  comunidadPasosOnboarding: number;

  fechaAlta?: string;
}

export default class SolicitudesPendientesRegistro implements Serializable<SolicitudesPendientesRegistro> {
  id: string;

  nombre: string;

  apellidos: string;

  nif: string;

  email: string;

  domicilio: string;

  poblacion: string;

  provincia: string;

  codigoPostal: string;

  direccionCompleta: string;

  telefono: string;

  razonSocialEmpresa: string;

  nifEmpresa: string;

  latitud: number;

  longitud: number;

  cups: string;

  fechaAlta?: string;

  tipoSolicitante: string;

  tipoPropiedad: string;

  tipoConsumo: string;

  tarifaPVPC: string;

  margenMensual: number;

  margenKwH: number;

  tarifa: string;

  modalidadSolicitante: string;

  precioCompensacion: number;

  potenciaContratadaP1: number;

  potenciaContratadaP2: number;

  potenciaContratadaP3: number;

  potenciaContratadaP4: number;

  potenciaContratadaP5: number;

  potenciaContratadaP6: number;

  terminoDeEnergiaP1: number;

  terminoDeEnergiaP2: number;

  terminoDeEnergiaP3: number;

  terminoDeEnergiaP4: number;

  terminoDeEnergiaP5: number;

  terminoDeEnergiaP6: number;

  idComunidad: number;

  codigoComercializadora: string;

  codigoPostalFiscal: string;

  domicilioFiscal: string;

  permisoDatadis: string;

  contratoOTC: string;

  nombreComunidad: string;

  OTC: string;

  pasoCompletado: number;

  comunidadPasosOnboarding: number;

  deserialize(input: SolicitudesAPI): SolicitudesPendientesRegistro {
    if (!input) {
      return this;
    }

    this.id = input.id;
    this.nombre = input.nombre;
    this.apellidos = input.apellidos;
    this.nif = input.nif;
    this.email = input.email;
    this.domicilio = input.domicilio;
    this.poblacion = input.poblacion;
    this.provincia = input.provincia;
    this.codigoPostal = input.codigoPostal;
    this.direccionCompleta = input.direccionCompleta;
    this.telefono = input.telefono;
    this.razonSocialEmpresa = input.razonSocialEmpresa;
    this.nifEmpresa = input.nifEmpresa;
    this.latitud = input.latitud;
    this.longitud = input.longitud;
    this.cups = input.cups;
    this.tipoSolicitante = input.tipoSolicitante;
    this.tipoPropiedad = input.tipoPropiedad;
    this.tipoConsumo = input.tipoConsumo;
    this.tarifaPVPC = input.tarifaPVPC;
    this.margenMensual = input.margenMensual;
    this.margenKwH = input.margenKwH;
    this.tarifa = input.tarifa;
    this.modalidadSolicitante = input.modalidadSolicitante;
    this.precioCompensacion = input.precioCompensacion;
    this.potenciaContratadaP1 = input.potenciaContratadaP1;
    this.potenciaContratadaP2 = input.potenciaContratadaP2;
    this.potenciaContratadaP3 = input.potenciaContratadaP3;
    this.potenciaContratadaP4 = input.potenciaContratadaP4;
    this.potenciaContratadaP5 = input.potenciaContratadaP5;
    this.potenciaContratadaP6 = input.potenciaContratadaP6;
    this.terminoDeEnergiaP1 = input.terminoDeEnergiaP1;
    this.terminoDeEnergiaP2 = input.terminoDeEnergiaP2;
    this.terminoDeEnergiaP3 = input.terminoDeEnergiaP3;
    this.terminoDeEnergiaP4 = input.terminoDeEnergiaP4;
    this.terminoDeEnergiaP5 = input.terminoDeEnergiaP5;
    this.terminoDeEnergiaP6 = input.terminoDeEnergiaP6;
    this.idComunidad = input.idComunidad;
    this.codigoComercializadora = input.codigoComercializadora;
    this.codigoPostalFiscal = input.codigoPostalFiscal;
    this.domicilioFiscal = input.domicilioFiscal;
    this.permisoDatadis = input.permisoDatadis;
    this.contratoOTC = input.contratoOTC;
    this.nombreComunidad = input.nombreComunidad;
    this.OTC = input.OTC;
    this.pasoCompletado = input.pasoCompletado;
    this.comunidadPasosOnboarding = input.comunidadPasosOnboarding;
    this.fechaAlta = input.fechaAlta;
    return this;
  }

  deserializeArray(inputArray: Array<SolicitudesAPI>): Array<SolicitudesPendientesRegistro> {
    return inputArray.map((input) => new SolicitudesPendientesRegistro().deserialize(input));
  }
}
