import React, { ChangeEvent } from 'react';

import styles from './RadioButtonInput.module.scss';

export interface CheckboxInterface {
  id: string;
  type: string;
  name: string;
  path: string;
  label: string;
  checked?: boolean;
  onChange?: CallableFunction;
  value?: string;
}
const RadioButtonInput = (props: CheckboxInterface): JSX.Element => {
  const { name, label, checked, type } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { path, onChange } = props;
    const { value } = e.target;

    if (onChange) {
      onChange(path, value, e.target.checked);
    }
  };

  return (
    <div className={styles.root}>
      <label className={styles['checkbox-label']}>
        <input id={name} type={type} name={label} checked={checked} onChange={handleChange} value={name} />
        {label}
      </label>
    </div>
  );
};

export default RadioButtonInput;
