import configuration from 'config';
import { InvoicesObj, InvoiceObj } from 'types/models';
import { AxiosResponse } from 'axios';
import apiService from './ApiService';

export const dataFrequencies = {
  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year',
  HISTORIC: 'historic',
};

class InvoiceService {
  private readonly http = apiService;

  private apiUrl = configuration.apiUrl;

  public async getInvoicesData(cups: string): Promise<InvoicesObj> {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/facturas-lista/${cups}/`);

    return new InvoicesObj().deserialize(response.data.datos);
  }

  public async getInvoiceById(invoiceNumber: number): Promise<InvoiceObj> {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/fv/factura/${invoiceNumber}/`);
    return new InvoiceObj().deserialize(response.data.datos);
  }

  public downloadInvoice(invoiceNumber: number) {
    return this.http.get(`${this.apiUrl}/fv/factura/${invoiceNumber}/descargar/?formato=base64`);
  }

  public async getInvoicesDataContabilidad(cups: string): Promise<InvoicesObj> {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/ce/facturas-lista/${cups}/`);

    return new InvoicesObj().deserialize(response.data.datos);
  }

  public async getInvoiceByIdContabilidad(invoiceNumber: number): Promise<InvoiceObj> {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/ce/factura/${invoiceNumber}/`);
    return new InvoiceObj().deserialize(response.data.datos);
  }

  public downloadInvoiceContabilidad(invoiceNumber: number) {
    return this.http.get(`${this.apiUrl}/ce/factura/${invoiceNumber}/descargar/?formato=base64`);
  }
}

const invoiceService: InvoiceService = new InvoiceService();

export default invoiceService;
