import React from 'react';
import Tooltip from 'components/tooltip';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { I18n } from 'core';
import CupsHelper from 'assets/images/cups-helper.png';
import styles from './CupsTooltip.module.scss';

const TooltipContent = (): JSX.Element => (
  <div className={styles.content}>
    <img src={CupsHelper} alt="Cups Helper" />
    <p>{I18n.t('SignUpPage.form.cupsHelper.p1')}</p>
    <p>{I18n.t('SignUpPage.form.cupsHelper.p2')}</p>
  </div>
);

const CupsTooltip = (): JSX.Element => (
  <Tooltip cursor="help" direction="bottom" content={<TooltipContent />}>
    <div className={styles.root}>
      <InfoIcon />
      <p className={styles.label}>{I18n.t('SignUpPage.form.cupsHelper.title')}</p>
    </div>
  </Tooltip>
);

export default CupsTooltip;
