import configuration from 'config';
import { AxiosResponse } from 'axios';
import { CupsInfo, CupsInfoAPI, User, UserAPI, UserInfoObject } from 'types/models';
import { UserInfoAPI } from 'types/models/userinfo';
import UserInfoRole from 'types/models/userinforole';
import apiService from './ApiService';

class UsersService {
  private readonly http = apiService;

  private apiUrl = configuration.apiUrl;

  public async getUsers() {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/usuario/`);
    return new UserInfoObject().deserializeArray(response.data.datos);
  }

  public async getUsersCount(): Promise<number> {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/usuario/count/`);
    return response.data.datos.numeroUsuarios;
  }

  public async getUserById(id: string) {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/usuario/${id}/`);
    return new UserInfoObject().deserialize(response.data.datos);
  }

  public async exportUsersData() {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/usuario/exportar`);
    return response;
  }

  public async changeEmail(userInfoObject: User) {
    const url = `${this.apiUrl}/usuario/${userInfoObject.nif}/`;
    const userUpdate: UserAPI = {
      nif: userInfoObject.nif,
      email: userInfoObject.email,
      nombre: userInfoObject.nombre,
      apellidos: userInfoObject.apellidos,
      token: userInfoObject.token,
      tipoDeUsuario: userInfoObject.tipoDeUsuario,
    };

    const response: AxiosResponse<string> = await this.http.patch(url, userUpdate);

    return response.data;
  }

  public async changeEmailUserInfo(userInfoObject: UserInfoAPI) {
    const url = `${this.apiUrl}/usuario/${userInfoObject.nif}/`;
    const userUpdate: UserInfoAPI = {
      nif: userInfoObject.nif,
      email: userInfoObject.email,
      nombre: userInfoObject.nombre,
      apellidos: userInfoObject.apellidos,
      dispositivos: userInfoObject.dispositivos,
      tipoDeUsuario: userInfoObject.tipoDeUsuario,
    };

    const response: AxiosResponse<string> = await this.http.patch(url, userUpdate);

    return response.data;
  }

  public async updateRolPermisos(id: number, userInfoObject: any) {
    const url = `${this.apiUrl}/usuario/${id}/roles/`;
    const objectValue = {
      roles: userInfoObject,
    };

    const response: AxiosResponse<string> = await this.http.patch(url, objectValue);

    return response.data;
  }

  public async getRolUser(id: string) {
    const url = `${this.apiUrl}/usuario/${id}/roles/`;

    const response: AxiosResponse = await this.http.get(url);
    return new UserInfoRole().deserializeArray(response.data.datos);
  }

  public async getPermisos(id: string) {
    const url = `${this.apiUrl}/usuario/${id}/permisos/`;

    const response: AxiosResponse = await this.http.get(url);
    return response.data.datos;
  }

  public async getCupsAutorizados(id: string): Promise<Array<CupsInfo>> {
    const url = `${this.apiUrl}/usuario/${id}/cups-autorizados/`;

    const response: AxiosResponse<{
      datos: Array<CupsInfoAPI>;
    }> = await this.http.get(url);
    const userClients = new CupsInfo().deserializeArray(response.data.datos);
    return userClients;
  }

  public async addCupsAutorizados(id: string, cups: string[]) {
    const url = `${this.apiUrl}/usuario/${id}/cups-autorizados/`;
    const userUpdate = {
      cups,
    };
    const response: AxiosResponse = await this.http.post(url, userUpdate);
    return response.data;
  }

  public async updateCupsAutorizados(id: string, cups: string[]) {
    const url = `${this.apiUrl}/usuario/${id}/cups-autorizados/`;
    const userUpdate = {
      cups: [...cups],
    };
    const response: AxiosResponse = await this.http.post(url, userUpdate);
    return response.data;
  }
}

const usersService: UsersService = new UsersService();

export default usersService;
