import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import React, { createRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { I18n } from 'core';
import { navigate } from 'navigation';
import { toast } from 'react-toastify';
import { CommunityCustomOnboardingServiceInstance } from 'services';
import { LoadingButton } from 'components';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import Button from 'components/Button/Button';
import customToast from 'components/CustomToast/CustomToast';
import Tipodecontacto from 'types/models/tipodecontacto';
import { useFetchCommunity } from 'hooks';
import styles from './comunidades-custom-onboarding-process-page.module.scss';
import ComunidadesCustomOnboardingTipoDeContacto from './components/comunidades-custom-onboarding-tipodecontacto/comunidades-custom-onboarding-tipodecontacto';

export type SaveDataHandle = {
  getData: () => void;
};

export const ComunidadesCustomOnboardingProcessPageTipoDecontacto = (): JSX.Element => {
  const { communityID } = useParams<{ communityID: string }>();

  const [loading, setLoading] = useState<boolean>(false);

  const [communityTipodeContactoCustomOnboarding, setCommunityTipodeContactoCustomOnboarding] = useState<
    Tipodecontacto[] | undefined
  >(undefined);

  const customOnboardingRef = createRef<SaveDataHandle>();

  const getTipoDeContactoCustomOnboardingData = async (id: string): Promise<void> => {
    setLoading(true);
    try {
      const customOnboarding = await CommunityCustomOnboardingServiceInstance.getTipoDeContacto(id);
      if (customOnboarding) {
        setCommunityTipodeContactoCustomOnboarding(customOnboarding);
      }
    } catch (error) {
      toast.error(I18n.t('ComunidadesCustomOnboardingProcess.errors.errorGettingCustomOnboardingData'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!communityID) return;

    getTipoDeContactoCustomOnboardingData(communityID);
  }, []);

  const deletetipoDeContacto = async (idTipoDecontacto: any): Promise<void> => {
    // eslint-disable-next-line no-alert
    const accept = window.confirm(
      `${I18n.t('Are you sure you want to delete the tipoDeContacto?')}${idTipoDecontacto.emailContacto}?`,
    );

    if (!accept) {
      return;
    }

    try {
      await CommunityCustomOnboardingServiceInstance.deletetipoDeContacto(communityID, idTipoDecontacto.id);
      customToast.success(I18n.t('ComunidadesCustomOnboardingProcess.updateSuccess'));
      getTipoDeContactoCustomOnboardingData(communityID);
    } catch (error) {
      toast.error(`${I18n.t('Error deleting image')}.`);
    }
  };

  const AddTipodeContacto = async (contacto: any): Promise<void> => {
    if (contacto.emailContacto !== '') {
      try {
        await CommunityCustomOnboardingServiceInstance.AddtipoDeContacto(communityID, contacto);

        customToast.success(I18n.t('ComunidadesCustomOnboardingProcess.updateSuccess'));

        getTipoDeContactoCustomOnboardingData(communityID);
      } catch (error) {
        toast.error(I18n.t('ComunidadesCustomOnboardingProcess.errors.updating'));
      }
    }
  };

  const fetchCommunityData = useFetchCommunity(communityID);
  useEffect(() => {
    fetchCommunityData();
  }, [fetchCommunityData]);

  if (loading) return <FullPageSyncLoader icon />;

  return (
    <div className={styles.container}>
      <SectionWrapper>
        <>
          {communityTipodeContactoCustomOnboarding && (
            <ComunidadesCustomOnboardingTipoDeContacto
              ref={customOnboardingRef}
              communityCustomOnboarding={communityTipodeContactoCustomOnboarding}
              communityId={communityID}
              saveDetails={(customOnboarding) => AddTipodeContacto(customOnboarding)}
              deleteFaqs={(customOnboarding) => deletetipoDeContacto(customOnboarding)}
            />
          )}
        </>

        <div className={styles.positionBotonoes}>
          <div className={styles.divBoton}>
            <Button
              type="button"
              onClick={(): Promise<void> => navigate('/tecnic/communities')}
              variant="secondary"
              text={I18n.t('Cancel')}
            />
            <LoadingButton type="submit" text={I18n.t('Save')} onClick={() => customOnboardingRef.current?.getData()} />
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
};
