import React from 'react';
import { ElecsumCheckbox, ElecsumSelect } from 'components/NewInputs';
import { I18n } from 'core';
import { SelectItemI } from 'components/NewInputs/Select/ElecsumSelect';
import { FiltersStatusI } from '../../interfaces/FiltersStatus';
import SearchCupsInput from '../SearchCupsInput';

import styles from './CupsListFilters.module.scss';

interface CupsListFiltersPropsI {
  filtersValues: FiltersStatusI;
  handleChange(name: string, value: any): void;
  manteinanceOptions: Array<SelectItemI>;
}

const CupsListFilters = (props: CupsListFiltersPropsI): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { handleChange, filtersValues, manteinanceOptions = [] } = props;
  return (
    <div className={styles.container}>
      <div className={styles.left_wrapper}>
        <p className={styles.title}>Listado de Cups</p>
        <div className={styles.search_wrapper}>
          <SearchCupsInput
            placeholder={`${I18n.t('CUPSListPage.filters.searchPlaceholder')}...`}
            onChange={(event) => {
              handleChange('searchText', event.target.value);
            }}
          />
        </div>
      </div>
      <div className={styles.filters}>
        <ElecsumCheckbox
          onChange={(event) => {
            handleChange('autoconsumo', event.target.checked);
          }}
          checked={filtersValues.autoconsumo}
          label={I18n.t('CUPSListPage.filters.autoconsumo')}
        />
        <ElecsumCheckbox
          onChange={(event) => {
            handleChange('industrial', event.target.checked);
          }}
          checked={filtersValues.industrial}
          label={I18n.t('CUPSListPage.filters.industrial')}
        />
        <ElecsumCheckbox
          onChange={(event) => {
            handleChange('mantenimiento', event.target.checked);
          }}
          checked={filtersValues.mantenimiento}
          label={I18n.t('CUPSListPage.filters.mantenimiento')}
        />
        <ElecsumCheckbox
          onChange={(event) => {
            handleChange('garantia', event.target.checked);
          }}
          checked={filtersValues.garantia}
          label={I18n.t('CUPSListPage.filters.garantia')}
        />
        {filtersValues.garantia ? (
          <ElecsumSelect
            minWidth="15rem"
            values={manteinanceOptions}
            selected={filtersValues.añosGarantia}
            label={I18n.t('CUPSListPage.filters.years')}
            onChange={(el) => {
              handleChange('añosGarantia', el);
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CupsListFilters;
