import { Serializable } from 'types/interfaces';

export interface AlertAPI {
  id: number;
  idInstalacion: number;
  nombreInstalacion: string;
  descripcion: string;
  fecha: string;
  hora: string;
  estado: number;
}

export default class Alert implements Serializable<Alert> {
  id: string;

  idInstalation: number;

  client: string;

  description: string;

  date: string;

  hour: string;

  status: number;

  deserialize(input: AlertAPI): Alert {
    if (!input) {
      return this;
    }

    this.id = input.id.toString();
    this.idInstalation = input.idInstalacion;
    this.client = input.nombreInstalacion;
    this.description = input.descripcion;
    this.date = input.fecha;
    this.hour = input.hora.substring(0, 8);
    this.status = input.estado;

    return this;
  }

  deserializeArray(inputArray: Array<AlertAPI>): Array<Alert> {
    const Alerts: Array<Alert> = inputArray.map((input) => new Alert().deserialize(input));

    return Alerts;
  }
}
