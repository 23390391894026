import { AxiosResponse } from 'axios';
import configuration from 'config';
import ActualizarTarifa from 'types/models/updateTarifa';
import { SolicitudActualizarDTO } from 'types/models/SolicitudActualizar';
import { SolicitudActualizacionTarifaResponse } from 'types/interfaces/IdSolicitud';
import ActualizarTarifaAll, { ActualizarTarifaAllAPII } from 'types/models/updateTarifaAll';
import { Documento } from 'types/models/Documentos';
import apiService from './ApiService';

export class UpdateTarifaService {
  readonly http = apiService;

  private apiUrl = configuration.apiUrl;

  async putSolicitudActualizacionTarifa(): Promise<SolicitudActualizacionTarifaResponse> {
    const url = `${this.apiUrl}/ce/solicitud-de-actualizacion-de-tarifa/`;

    const response: AxiosResponse<SolicitudActualizacionTarifaResponse> = await this.http.post(url);

    return response.data;
  }

  async getAllSolicitudActualizacion(procesada?: string, validada?: string): Promise<Array<ActualizarTarifaAll>> {
    const url = `${this.apiUrl}/ce/solicitud-de-actualizacion-de-tarifa/?procesada=${procesada}&validada=${validada}`;

    const response: AxiosResponse<{ datos: ActualizarTarifaAllAPII[] }> = await this.http.get(url);
    const result = new ActualizarTarifaAll().deserializeArray(response.data.datos);
    return result;
  }

  async getSolicitudActualizacion(id: string): Promise<ActualizarTarifa> {
    const url = `${this.apiUrl}/ce/solicitud-de-actualizacion-de-tarifa/${id}/`;

    const response: AxiosResponse<{ datos: ActualizarTarifaAllAPII }> = await this.http.get(url);
    const result = new ActualizarTarifa().deserialize(response.data.datos);
    return result;
  }

  async patchSolicitudActualizacion(id: string, solicitudActualizacion: SolicitudActualizarDTO): Promise<string> {
    const url = `${this.apiUrl}/ce/solicitud-de-actualizacion-de-tarifa/${id}/`;

    const response: AxiosResponse<string> = await this.http.patch(url, solicitudActualizacion);

    return response.data;
  }

  async deleteImage(id: string, idimg: string): Promise<unknown> {
    const url = `${this.apiUrl}/ce/solicitud-de-actualizacion-de-tarifa/${id}/documento/${idimg}/`;
    const response: AxiosResponse<number> = await this.http.delete(url);

    return response;
  }

  async addGaleryImage(id: string, file: File, type: string): Promise<number> {
    const url = `${this.apiUrl}/ce/solicitud-de-actualizacion-de-tarifa/${id}/documento/`;

    const imageData = new window.FormData();
    imageData.append('nombre', type);
    imageData.append('documento', file);

    const response: AxiosResponse<{ status: number }> = await this.http.post(url, imageData);

    return response.status;
  }

  async getById(id: string): Promise<ActualizarTarifa> {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/ce/solicitud-de-actualizacion-de-tarifa/${id}`);

    return new ActualizarTarifa().deserialize(response.data.datos);
  }

  async validarSolicitud(id: string): Promise<string> {
    const response: AxiosResponse = await this.http.post(
      `${this.apiUrl}/ce/solicitud-de-actualizacion-de-tarifa/validar/${id}/`,
    );

    return response.data;
  }

  async enviarSolicitudActualizacionTarifa(id: string): Promise<void> {
    const url = `${this.apiUrl}/ce/solicitud-de-actualizacion-de-tarifa/enviar/${id}/`;

    const response: AxiosResponse<void> = await this.http.post(url);

    return response.data;
  }

  async getFiles(id: string): Promise<Documento[]> {
    const solicitud = await this.getById(id);
    const documentos = solicitud.documentos.map((doc) =>
      Documento.create({ id: doc.id, nombre: doc.nombre, url: doc.url }),
    );
    return documentos;
  }
}
const UpdateTarifaServiceInstance = new UpdateTarifaService();
export default UpdateTarifaServiceInstance;
