interface DocumentoProps {
  id: string;
  url: string;
  nombre: string;
}

export class Documento {
  id: string;

  url: string;

  nombre: string;

  private constructor(props: DocumentoProps) {
    this.id = props.id;
    this.url = props.url;
    this.nombre = props.nombre;
  }

  public static create(props: DocumentoProps): Documento {
    return new Documento(props);
  }
}
