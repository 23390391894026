import {
  AlertsWidget,
  ComunidadesDashboardWidget,
  RegisteredCUPSWidget,
  RegisteredUsersWidget,
} from 'components/widgets';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { I18n } from 'core';
import { authServiceInstance, clientServiceInstance, installationServiceInstance } from 'services';
import usersService from 'services/remote/UsersService';
import cupsAdminServiceInstance from 'services/remote/TecnicoCupsService';
import { CommunityModel } from 'types/models/community';
import CommunitiesServiceIntance from 'services/remote/communities-service';
import InscritosServicentance from 'services/remote/Inscritos';
import { TarifasDashboardWidget } from 'components/widgets/tarifas-dashboard-widget';
import UpdateTarifaServiceInstance from 'services/remote/UpdateTarifaService';
import ActualizarTarifaAll from 'types/models/updateTarifaAll';
import configuration from 'config';
import { MatcherDashboardWidget } from 'components/widgets/matcher-dashboard-widget';
import RegisteredInstallationWidget from 'components/widgets/RegisteredInstallationWidget';

import WidgetPermission from 'types/enums/WidgetPermission';
import styles from './DashboardPage.module.scss';

const ComponentDashbordPage = (): JSX.Element => {
  // const [pendingAlertsData, setPendingAlertsData] = useState<Array<Alert> | null>(null);
  const [pendingAlertsData, setPendingAlertsData] = useState<number | null>(null);
  const [usersData, setUsersData] = useState<number | null>(null);
  // const [usersData, setUsersData] = useState<Array<UserInfoObject> | null>(null);
  const [cupsRegistered, setCupsRegistered] = useState<number | null>(null);
  const [AllCommunities, setCommunities] = useState<Array<CommunityModel> | null>(null);
  const [AllInstallation, setInstallation] = useState<number | null>(null);
  const [AllCommunitiesEnrolled, setCommunitiesEnrolled] = useState<number | null>(null);
  const [AllSolicitudes, setSolicitudes] = useState<Array<ActualizarTarifaAll> | null>(null);
  const { user } = authServiceInstance;
  const [permisos, setPermisos] = useState<string[]>([]);

  const getPermisosUser = async () => {
    if (user) {
      const response = await usersService.getPermisos(user.nif);
      authServiceInstance.setUserPermisos(response);
      setPermisos(response);
    }
    return null; // Devolver null si no hay un usuario definido
  };

  const fetchPendingAlerts = async (): Promise<void> => {
    try {
      // const apiData: Array<Alert> = await clientServiceInstance.getAlerts();
      // const pending = apiData.filter((alert) => alert.status === 0);
      const apiData = await clientServiceInstance.getAlertsCount();
      setPendingAlertsData(apiData);
    } catch (error) {
      toast.error(I18n.t('DashboardPage.alerts_fetching_error'));
    }
  };

  const fetchAllUsers = async (): Promise<void> => {
    try {
      // const apiData: Array<UserInfoObject> = await usersService.getUsers();

      const apiData = await usersService.getUsersCount();
      setUsersData(apiData);
    } catch (error) {
      toast.error(I18n.t('DashboardPage.users_fetching_error'));
    }
  };

  const fetchAllCups = async (): Promise<void> => {
    try {
      const apiData = await cupsAdminServiceInstance.getFilteredCupsCount();
      setCupsRegistered(apiData);
    } catch (error) {
      toast.error(I18n.t('DashboardPage.cups_fetching_error'));
    }
  };

  const fetchAllComunity = async () => {
    try {
      const apiData = await CommunitiesServiceIntance.getCommunities();
      setCommunities(apiData);
    } catch (error) {
      toast.error(I18n.t('DashboardPage.comunity_fetching_error'));
    }
  };

  const fetchAllInstallation = async () => {
    try {
      const apiData = await installationServiceInstance.getAllInstallationCount();
      setInstallation(apiData);
    } catch (error) {
      setInstallation(0);
      toast.error(I18n.t('DashboardPage.comunity_fetching_error'));
    }
  };

  const fetchAllComunityPending = async () => {
    try {
      const apiData = await InscritosServicentance.getCommunitiesEnrolledCount();
      // const filterNumbers = apiData.filter((num) => num.validado !== 'si');
      setCommunitiesEnrolled(apiData);
    } catch (error) {
      toast.error(I18n.t('DashboardPage.comunity_fetching_error'));
    }
  };

  const fetchAllSolicitudActualizacion = async (): Promise<void> => {
    try {
      const apiData = await UpdateTarifaServiceInstance.getAllSolicitudActualizacion('si', 'no');
      setSolicitudes(apiData);
    } catch (error) {
      toast.error(I18n.t('DashboardPage.solicitud_fetching_error'));
    }
  };

  const hasAccessTo = (permission: WidgetPermission) =>
    (permisos && permisos.includes(permission)) || user?.tipoDeUsuario === 'admin';

  useEffect(() => {
    getPermisosUser();
  }, []);

  useEffect(() => {
    if (permisos.length >= 0) {
      const widgets = [
        { permission: WidgetPermission.ACCESO_WIDGET_ALERTAS, fetchFunction: fetchPendingAlerts },

        { permission: WidgetPermission.ACCESO_WIDGET_USUARIOS, fetchFunction: fetchAllUsers },
        // no me da el mismo resultado
        { permission: WidgetPermission.ACCESO_WIDGET_CUPS, fetchFunction: fetchAllCups },

        // no existe el count
        { permission: WidgetPermission.ACCESO_WIDGET_COMUNIDADES, fetchFunction: fetchAllComunity },
        // ok
        { permission: WidgetPermission.ACCESO_WIDGET_INSTALACIONES, fetchFunction: fetchAllInstallation },

        // no sale lo mismo
        { permission: WidgetPermission.ACCESO_WIDGET_INSTALACIONES, fetchFunction: fetchAllComunityPending },
        // no hay
        {
          permission: WidgetPermission.ACCESO_WIDGET_ACTUALIZACIONES_DE_TARIFA,
          fetchFunction: fetchAllSolicitudActualizacion,
        },
      ];

      widgets.forEach(({ permission, fetchFunction }) => {
        if (hasAccessTo(permission)) {
          fetchFunction();
        }
      });
    }
  }, [permisos]);

  const renderWidget = (permission: WidgetPermission, component: JSX.Element) =>
    hasAccessTo(permission) ? component : null;

  return (
    <div className={styles.root}>
      {renderWidget(
        WidgetPermission.ACCESO_WIDGET_ALERTAS,
        <div className={styles.alerts_widget}>
          <AlertsWidget alertsNumber={pendingAlertsData || -1} />
        </div>,
      )}
      {renderWidget(
        WidgetPermission.ACCESO_WIDGET_USUARIOS,
        <div className={styles.users_widget}>
          <RegisteredUsersWidget users={usersData || -1} />
        </div>,
      )}
      {renderWidget(
        WidgetPermission.ACCESO_WIDGET_CUPS,
        <div className={styles.users_widget}>
          <RegisteredCUPSWidget cupsNumber={cupsRegistered || -1} />
        </div>,
      )}
      {renderWidget(
        WidgetPermission.ACCESO_WIDGET_INSTALACIONES,
        <div className={styles.users_widget}>
          <RegisteredInstallationWidget cupsNumber={AllInstallation || -1} />
        </div>,
      )}
      {renderWidget(
        WidgetPermission.ACCESO_WIDGET_COMUNIDADES,
        <div className={styles.communities_widget}>
          <ComunidadesDashboardWidget
            communities={AllCommunities ? AllCommunities.length : -1}
            pending={AllCommunitiesEnrolled !== null ? AllCommunitiesEnrolled : -1}
          />
        </div>,
      )}
      {renderWidget(
        WidgetPermission.ACCESO_WIDGET_ACTUALIZACIONES_DE_TARIFA,
        <div className={styles.communities_widget}>
          <TarifasDashboardWidget solicitudes={AllSolicitudes ? AllSolicitudes.length : -1} />
        </div>,
      )}
      {configuration.matcher === true &&
        renderWidget(
          WidgetPermission.ACCESO_WIDGET_MATCHER,
          <div className={styles.alerts_widget}>
            <MatcherDashboardWidget />
          </div>,
        )}
    </div>
  );
};

export default ComponentDashbordPage;
