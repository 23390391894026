import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { Colors } from 'styles';
import cx from 'classnames';
import { useOutsideClick } from 'hooks';
import { I18n } from 'core';
import searchServiceInstance, { SearchItemI } from 'services/remote/SearchService';
import Spinner from 'components/Spinner';
import usersService from 'services/remote/UsersService';
import { toast } from 'react-toastify';
import styles from './HeaderSearchInputCupsAsociados.module.scss';
import CupsSearchItemAsociado from './SearchItems/CupsSearchItemAsociado';

const HeaderSearchInputCupsAsociados = ({
  userID,
  fetchData,
  cupsAsociados,
}: {
  userID: string;
  fetchData: Function;
  cupsAsociados: any;
}): JSX.Element => {
  // Constants
  const ICON_SIZE = 18;
  // References
  const searchInputRef = useRef(null);
  // State
  const [show, setShow] = useState<boolean>(false);
  const [inputText, setInputText] = useState('');
  const [results, setResults] = useState<Array<SearchItemI>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // Effects
  useOutsideClick(searchInputRef, () => {
    setShow(false);
  });

  useEffect(() => {
    setShow(inputText.length > 2);
  }, [inputText]);

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      if (show) {
        setShow(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress, true);
    return () => {
      document.removeEventListener('keydown', handleKeyPress, true);
    };
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (inputText.length > 2) {
        setLoading(true);
        searchServiceInstance.searchItems(inputText).then((response) => {
          setResults(response);
          setLoading(false);
        });
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [inputText]);

  // Handlers
  const handleInputChange = (evt: any) => {
    setInputText(evt.target.value);
  };

  const handleInputFocus = () => {
    if (inputText.length > 3) {
      setShow(true);
    }
  };
  const handleCupsClick = async (cupsValue: string): Promise<void> => {
    const cups = cupsAsociados.map((x: { cups: any }) => x.cups);
    const updatedCupsAsociados = [...cups, cupsValue];

    try {
      await usersService.addCupsAutorizados(userID, updatedCupsAsociados);
    } catch (error) {
      toast.error(I18n.t('DashboardPage.comunity_fetching_error'));
    } finally {
      fetchData();
    }
  };

  return (
    <div ref={searchInputRef} className={styles.root}>
      <div className={styles.header}>
        <SearchIcon height={ICON_SIZE} width={ICON_SIZE} fill={Colors.COLOR_GRAY_500} />
        <input
          onFocus={() => handleInputFocus()}
          value={inputText}
          placeholder={I18n.t('HeaderSearchInput.inputPlaceholderCupsAsociado')}
          onChange={handleInputChange}
          className={styles.input}
          type="text"
        />
      </div>
      <div className={cx(styles.dropdown_wrapper, { [styles.show]: show })}>
        <div className={styles.dropdown_items}>
          {loading ? (
            <div className={styles.loading}>
              <Spinner size={100} icon />
            </div>
          ) : (
            results
              .filter((item) => !cupsAsociados.includes(item.cups))
              .map((item) => {
                switch (item.tipo) {
                  case 'cups':
                    return (
                      <CupsSearchItemAsociado
                        nombre={item.nombre}
                        cups={item.cups}
                        direccion={item.domicilio}
                        ciudad={item.poblacion}
                        provincia={item.provincia}
                        cp={item.codigoPostal}
                        onClick={handleCupsClick} // Pasando la función como prop
                      />
                    );

                  default:
                    return null;
                }
              })
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(HeaderSearchInputCupsAsociados);
