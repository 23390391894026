import React, { useState, useEffect } from 'react';
import { I18n } from 'core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { VictoryChart, VictoryAxis, VictoryGroup, VictoryBar, VictoryLabel, VictoryContainer } from 'victory';
import { Colors } from 'styles';
import { ProdEnergyProductionDataTotalYearly, ChartDataExtI } from 'types/models';
import { ViewDate, ChartDataList } from 'components';
import { MutationChartInterface } from 'types/interfaces';
import { WattsConverterService } from 'services';
import { SizeMe } from 'react-sizeme';
import Constants from 'config/constants';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import styles from './ProductionChartsCommon.module.scss';

interface DateType {
  day: number;
  month: number;
  year: number;
}

interface ProductionEnergyChartI {
  generationData: Array<ChartDataExtI>;
  guaranteeData: Array<ChartDataExtI>;
  estimateData: Array<ChartDataExtI>;
  date: DateType;
  currentTab: string;
  yearlyData: Array<ProdEnergyProductionDataTotalYearly>;
  radius: number;
  isLoading?: boolean;
}

const guaranteeBarStyle = {
  NotSelected: Colors.COLOR_ELECSUMGREEN_NOT_SELECTED,
  Selected: Colors.COLOR_ELECSUMGREEN,
};

const generationBarStyle = {
  NotSelected: Colors.COLOR_GENERACIO_NOT_SELECTED,
  Selected: Colors.COLOR_GENERACIO,
};

const estimateBarStyle = {
  NotSelected: Colors.COLOR_CONSUM_NOT_SELECTED,
  Selected: Colors.COLOR_GRAY_500,
};

const ProductionEnergyHistoricChart = (props: ProductionEnergyChartI): JSX.Element => {
  const { generationData, guaranteeData, estimateData, date, currentTab, yearlyData, isLoading, radius } = props;

  const TOTAL_YEARS = yearlyData.length;
  const YEARS_TO_SHOW = 6;
  const TOTAL_TICKS = 5;
  const MAX_ARRAY = [
    Math.max(...generationData.map((data) => data.y)),
    Math.max(...guaranteeData.map((data) => data.y)),
    Math.max(...estimateData.map((data) => data.y)),
  ];
  const MAX = Math.max(...MAX_ARRAY);

  const [down, setDown] = useState(0);
  const [up, setUp] = useState(0);
  const [selectArrayElement, setSelectArrayElement] = useState(5);
  const [generationShow, setGenerationShow] = useState<Array<ChartDataExtI>>([]);
  const [guaranteeShow, setGuaranteeShow] = useState<Array<ChartDataExtI>>([]);
  const [estimateShow, setEstimateShow] = useState<Array<ChartDataExtI>>([]);
  const [selectedBar, setSelectedBar] = useState<{
    productionGuarantee: ChartDataExtI;
    generation: ChartDataExtI;
    estimate: ChartDataExtI;
  }>({
    productionGuarantee: guaranteeShow[5],
    generation: generationShow[5],
    estimate: estimateShow[5],
  });

  const dataTotals = (): ProdEnergyProductionDataTotalYearly => {
    let data: ProdEnergyProductionDataTotalYearly = {
      year: 0,
      generation: 0,
      guarantee: 0,
      diffGuarantee: 0,
      estimate: 0,
      diffEstimate: 0,
      diffGeneration: 0,
    };
    if (yearlyData.length > 0) {
      data =
        yearlyData.length >= YEARS_TO_SHOW
          ? yearlyData[selectArrayElement + down]
          : yearlyData[selectArrayElement - YEARS_TO_SHOW + TOTAL_YEARS];
    }
    return data;
  };

  const listItemsArray = [
    {
      color: 'bck-orange',
      name: I18n.t('generation'),
      data: dataTotals().generation,
    },
    {
      color: 'bck-green',
      name: I18n.t('productionGuarantee'),
      data: dataTotals().guarantee,
    },
    {
      color: '',
      name: I18n.t('diffGuarantee'),
      data: dataTotals().diffGuarantee,
    },
    {
      color: 'bck-dark-grey',
      name: I18n.t('estimateProject'),
      data: dataTotals().estimate,
    },
    {
      color: '',
      name: I18n.t('differenceEstimate'),
      data: dataTotals().diffEstimate,
    },
    {
      color: '',
      textColor: 'text-red',
      name: `${I18n.t('differenceInst')} ${radius}km`,
      data: dataTotals().diffGeneration,
    },
  ];

  const downYear = (): void => {
    setDown(down - 1);
    setUp(up + 1);
    setSelectArrayElement(selectArrayElement + 1);
  };

  const upYear = (): void => {
    setUp(up - 1);
    setDown(down + 1);
    setSelectArrayElement(selectArrayElement - 1);
  };

  useEffect(() => {
    const generation = generationData.slice(down, generationData.length - up);
    const newGeneration = generation.map((data, i): ChartDataExtI => ({ x: data.x, y: data.y, index: i }));
    setGenerationShow(newGeneration);
    const guarantee = guaranteeData.slice(down, generationData.length - up);
    const newGuarantee = guarantee.map((data, i): ChartDataExtI => ({ x: data.x, y: data.y, index: i }));
    setGuaranteeShow(newGuarantee);
    const estimate = estimateData.slice(down, generationData.length - up);
    const newEstimate = estimate.map((data, i): ChartDataExtI => ({ x: data.x, y: data.y, index: i }));
    setEstimateShow(newEstimate);
  }, [up, down, generationData, guaranteeData, estimateData]);

  useEffect(() => {
    if (TOTAL_YEARS === YEARS_TO_SHOW) {
      setGenerationShow(generationData);
      setGuaranteeShow(guaranteeData);
      setEstimateShow(estimateData);
      setSelectArrayElement(generationData[generationData.length - 1].index);
      setSelectedBar({
        productionGuarantee: guaranteeData[5],
        generation: generationData[5],
        estimate: estimateData[5],
      });
    }
    if (TOTAL_YEARS < YEARS_TO_SHOW && yearlyData.length > 0) {
      const startYear = yearlyData[0].year;
      const diffYears = YEARS_TO_SHOW - TOTAL_YEARS;
      for (let index = 0; index < diffYears; index += 1) {
        generationData.unshift({
          x: startYear - diffYears - index + 2,
          y: 0,
          index: 0,
        });
        guaranteeData.unshift({
          x: startYear - diffYears - index + 2,
          y: 0,
          index: 0,
        });
        estimateData.unshift({
          x: startYear - diffYears - index + 2,
          y: 0,
          index: 0,
        });
      }
      const newGeneration = generationData.map((data, i): ChartDataExtI => ({ x: data.x, y: data.y, index: i }));
      setGenerationShow(newGeneration);
      const newGuarantee = guaranteeData.map((data, i): ChartDataExtI => ({ x: data.x, y: data.y, index: i }));
      setGuaranteeShow(newGuarantee);
      const newEstimate = estimateData.map((data, i): ChartDataExtI => ({ x: data.x, y: data.y, index: i }));
      setEstimateShow(newEstimate);
      setSelectedBar({
        productionGuarantee: newGuarantee[5],
        generation: newGeneration[5],
        estimate: newEstimate[5],
      });
    }
    if (TOTAL_YEARS > YEARS_TO_SHOW) {
      const diffYears = TOTAL_YEARS - YEARS_TO_SHOW;
      setDown(diffYears);
      const generation = generationData.slice(diffYears, generationData.length);
      const newGeneration = generation.map((data, i): ChartDataExtI => ({ x: data.x, y: data.y, index: i }));
      setGenerationShow(newGeneration);
      const guarantee = guaranteeData.slice(diffYears, generationData.length);
      const newGuarantee = guarantee.map((data, i): ChartDataExtI => ({ x: data.x, y: data.y, index: i }));
      setGuaranteeShow(newGuarantee);
      const estimate = estimateData.slice(diffYears, generationData.length);
      const newEstimate = estimate.map((data, i): ChartDataExtI => ({ x: data.x, y: data.y, index: i }));
      setEstimateShow(newEstimate);
      setSelectedBar({
        productionGuarantee: newGuarantee[5],
        generation: newGeneration[5],
        estimate: newEstimate[5],
      });
    }
  }, [TOTAL_YEARS, generationData, guaranteeData, estimateData, yearlyData]);

  if (isLoading) {
    return <FullPageSyncLoader />;
  }

  return (
    <div className={styles.root}>
      {yearlyData.length > 0 ? (
        <>
          <SizeMe>
            {({ size }) => (
              <div>
                <svg viewBox={`0 0 ${size.width} 400`} height="100%" width="100%">
                  <VictoryChart
                    width={size.width || 500}
                    height={400}
                    standalone={false}
                    domainPadding={{ x: 0, y: 10 }}
                    containerComponent={<VictoryContainer responsive={false} />}
                  >
                    <VictoryLabel
                      style={{
                        fontSize: Constants.charts.AXIS_LABELS_FONTSIZE,
                      }}
                      text="kWh"
                      x={30}
                      y={30}
                      textAnchor="middle"
                    />
                    <VictoryAxis
                      style={{
                        axis: { stroke: Colors.COLOR_WHITE },
                        tickLabels: {
                          fontSize: Constants.charts.AXIS_VALUES_FONTSIZE,
                          fill: Colors.COLOR_CONSUM_NOT_SELECTED,
                        },
                      }}
                      tickFormat={(tick): string => tick}
                      offsetY={45}
                    />
                    <VictoryAxis
                      dependentAxis
                      style={{
                        axis: { stroke: Colors.COLOR_WHITE },
                        tickLabels: {
                          fontSize: Constants.charts.AXIS_VALUES_FONTSIZE,
                          fill: Colors.COLOR_CONSUM_NOT_SELECTED,
                        },
                      }}
                      domain={[0, MAX + 1000]}
                      tickCount={TOTAL_TICKS}
                      tickFormat={(tick): number => WattsConverterService.convertWattsToKwatts(tick)}
                      offsetX={45}
                    />
                    <VictoryGroup
                      colorScale={[Colors.COLOR_GENERACIO, Colors.COLOR_ELECSUMGREEN, Colors.COLOR_GENERACIO]}
                      offset={9}
                    >
                      <VictoryBar
                        barRatio={Constants.charts.BAR_RATIO_FILL}
                        cornerRadius={{
                          top: Constants.charts.BAR_CORNER_RADIUS,
                        }}
                        data={generationShow}
                        style={{
                          data: {
                            fill: ({ datum }) => {
                              if (selectedBar.productionGuarantee) {
                                if (datum.x === selectedBar.productionGuarantee.x) {
                                  return generationBarStyle.Selected;
                                }
                              }
                              return generationBarStyle.NotSelected;
                            },
                          },
                        }}
                        events={[
                          {
                            target: 'data',
                            eventHandlers: {
                              onClick: () => [
                                {
                                  // TODO tipar la funció
                                  target: 'data',
                                  mutation: (value: MutationChartInterface): void => {
                                    setSelectedBar({
                                      productionGuarantee: guaranteeShow[value.datum.index],
                                      generation: generationShow[value.datum.index],
                                      estimate: estimateShow[value.datum.index],
                                    });
                                    setSelectArrayElement(value.datum.index);
                                  },
                                },
                              ],
                            },
                          },
                        ]}
                      />
                      <VictoryBar
                        barRatio={Constants.charts.BAR_RATIO_FILL}
                        cornerRadius={{
                          top: Constants.charts.BAR_CORNER_RADIUS,
                        }}
                        data={guaranteeShow}
                        // x="x"
                        style={{
                          data: {
                            fill: ({ datum }) => {
                              if (selectedBar.productionGuarantee) {
                                if (datum.x === selectedBar.productionGuarantee.x) {
                                  return guaranteeBarStyle.Selected;
                                }
                              }

                              return guaranteeBarStyle.NotSelected;
                            },
                          },
                        }}
                        events={[
                          {
                            target: 'data',
                            eventHandlers: {
                              onClick: () => [
                                {
                                  // TODO tipar la funció
                                  target: 'data',
                                  mutation: (value: MutationChartInterface): void => {
                                    setSelectedBar({
                                      productionGuarantee: guaranteeShow[value.datum.index],
                                      generation: generationShow[value.datum.index],
                                      estimate: estimateShow[value.datum.index],
                                    });
                                    setSelectArrayElement(value.datum.index);
                                  },
                                },
                              ],
                            },
                          },
                        ]}
                      />
                      <VictoryBar
                        barRatio={Constants.charts.BAR_RATIO_FILL}
                        cornerRadius={{
                          top: Constants.charts.BAR_CORNER_RADIUS,
                        }}
                        data={estimateShow}
                        // x="x"
                        style={{
                          data: {
                            fill: ({ datum }) => {
                              if (selectedBar.productionGuarantee) {
                                if (datum.x === selectedBar.productionGuarantee.x) {
                                  return estimateBarStyle.Selected;
                                }
                              }
                              return estimateBarStyle.NotSelected;
                            },
                          },
                        }}
                        events={[
                          {
                            target: 'data',
                            eventHandlers: {
                              onClick: () => [
                                {
                                  // TODO tipar la funció
                                  target: 'data',
                                  mutation: (value: MutationChartInterface): void => {
                                    setSelectedBar({
                                      productionGuarantee: guaranteeShow[value.datum.index],
                                      generation: generationShow[value.datum.index],
                                      estimate: estimateShow[value.datum.index],
                                    });
                                    setSelectArrayElement(value.datum.index);
                                  },
                                },
                              ],
                            },
                          },
                        ]}
                      />
                    </VictoryGroup>
                  </VictoryChart>
                </svg>
              </div>
            )}
          </SizeMe>
          <div className={styles.pagination}>
            <button type="button" disabled={down === 0} onClick={downYear}>
              <FontAwesomeIcon icon={faChevronLeft} className="green" />
            </button>
            <button type="button" disabled={up === 0} onClick={upYear}>
              <FontAwesomeIcon icon={faChevronRight} className="green" />
            </button>
          </div>
          {generationShow.length > 0 ? (
            <div className={styles.data_footer}>
              <ViewDate
                date={date}
                currentTab={currentTab}
                currentMonthSelected={selectArrayElement}
                yearSelected={
                  yearlyData.length >= YEARS_TO_SHOW
                    ? yearlyData[selectArrayElement + down].year
                    : generationShow[selectArrayElement].x
                }
              />
              <div className={styles.data_footer_list}>
                {listItemsArray.map(
                  (item): JSX.Element => (
                    <ChartDataList
                      key={item.name}
                      color={item.color}
                      textColor={item.textColor}
                      name={item.name}
                      data={item.data}
                    />
                  ),
                )}
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <div className={styles.nodata}>{I18n.t('noDataObtained')}.</div>
      )}
    </div>
  );
};

export default ProductionEnergyHistoricChart;
