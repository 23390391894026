import { combineReducers } from 'redux';
import InstallationsReducer from './InstallationsReducer';
import CUPSReducer from './CUPSReducer';
import UsersReducer from './UsersReducer';
import ClientCUPSReducer from './ClientCUPSReducer';
import { CommunitiesReducer } from './communities-reducer';

const rootReducer = combineReducers({
  installations: InstallationsReducer,
  cups: CUPSReducer,
  users: UsersReducer,
  clientCUPS: ClientCUPSReducer,
  communities: CommunitiesReducer,
});

export default rootReducer;
