export default class PromotorSharedEnergyObj {
  totalGeneration: number;

  powerInstallation: number;

  members: Array<{
    nombre: string;
    porcentaje: number;
    generacion: number;
  }>;

  showWidget: boolean;

  deserialize(input: any): PromotorSharedEnergyObj {
    if (!input) {
      this.showWidget = false;
      return this;
    }

    this.totalGeneration = input.datos.generacionTotal;
    this.powerInstallation = input.datos.potenciaInstalacion || 0;
    this.members = input.datos.integrantes.map((integrante: { nombre: any; porcentaje: any; generacion: any }) => ({
      nombre: integrante.nombre,
      porcentaje: integrante.porcentaje,
      generacion: integrante.generacion,
    }));

    this.showWidget = input.habilitado === 'si';

    return this;
  }
}
