import React from 'react';
import { ToastContainer } from 'react-toastify';
import { EnvironmentReporter } from 'components';
import 'react-toastify/dist/ReactToastify.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Provider } from 'react-redux';
import store from 'redux/store';
import { Navigator } from './navigation';

const App = (): JSX.Element => (
  <>
    <Provider store={store}>
      <EnvironmentReporter />
      <ToastContainer />
      <Navigator />
    </Provider>
  </>
);

export default App;
