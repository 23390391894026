import React from 'react';
import styles from './ModalMatcher.module.scss';

const ModalRol = (props: any): JSX.Element => {
  const { title, body, onClick2 } = props;
  return (
    <div
      className={styles.modal}
      id="exampleModal"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className={styles.modaldialog} role="document">
        <div className={styles.modalcontent}>
          <div className={styles.modalheader}>
            <h5 className={styles.modaltitle} id="exampleModalLabel">
              {title}
            </h5>
            <button type="button" className={styles.close} data-dismiss="modal" aria-label="Close" onClick={onClick2}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className={styles.modalbody}>{body}</div>
        </div>
      </div>
    </div>
  );
};
export default ModalRol;
