import { ChartDataI } from '../index';

export interface ProdEnergyProductionDataMonthlyAPII {
  generacion: number;
  garantia: number;
  estimacion: number;
  diferenciaGeneracion: number;
  dias: Array<ProdEnergyProductionDataDailyAPII>;
}

export interface ProdEnergyProductionDataDailyAPII {
  dia: number;
  generacion: number;
  garantia: number;
  estimacion: number;
}

export interface ProdEnergyProductionDataDaily {
  day: number;
  generation: number;
  guarantee: number;
  estimate: number;
}

export default class ProdEnergyProductionDataMonthly {
  generation: number;

  guarantee: number;

  estimate: number;

  differenceGeneration: number;

  days: Array<ProdEnergyProductionDataDaily>;

  generationData: Array<ChartDataI>;

  guaranteeData: Array<ChartDataI>;

  estimateData: Array<ChartDataI>;

  deserialize(input: ProdEnergyProductionDataMonthlyAPII): ProdEnergyProductionDataMonthly {
    if (!input) {
      return this;
    }

    this.generation = input.generacion;
    this.guarantee = input.garantia;
    this.estimate = input.estimacion;
    this.differenceGeneration = input.diferenciaGeneracion;
    this.days = input.dias.map(
      (data: ProdEnergyProductionDataDailyAPII): ProdEnergyProductionDataDaily => ({
        day: data.dia,
        generation: data.generacion,
        guarantee: data.garantia,
        estimate: data.estimacion,
      }),
    );
    this.generationData = input.dias.map(
      (dia): ChartDataI => ({
        x: dia.dia,
        y: dia.generacion,
      }),
    );
    this.guaranteeData = input.dias.map(
      (dia): ChartDataI => ({
        x: dia.dia,
        y: dia.garantia,
      }),
    );
    this.estimateData = input.dias.map(
      (dia): ChartDataI => ({
        x: dia.dia,
        y: dia.estimacion,
      }),
    );

    return this;
  }
}
