const STUDIODATA_MOCK = {
  coeficienteCalculoDeGarantia: 0.9,
  mesInicioSeguimiento: 11,
  estimaciones: [
    {
      fecha: '2018-07',
      estimacion: 4519,
    },
    {
      fecha: '2018-08',
      estimacion: 4519,
    },
    {
      fecha: '2018-09',
      estimacion: 4519,
    },
    {
      fecha: '2018-10',
      estimacion: 4519,
    },
    {
      fecha: '2018-11',
      estimacion: 4519,
    },
    {
      fecha: '2018-12',
      estimacion: 4519,
    },
    {
      fecha: '2020-01',
      estimacion: 4519,
    },
    {
      fecha: '2020-02',
      estimacion: 4519,
    },
    {
      fecha: '2020-03',
      estimacion: 4519,
    },
    {
      fecha: '2020-04',
      estimacion: 4519,
    },
    {
      fecha: '2020-05',
      estimacion: 4519,
    },
    {
      fecha: '2020-06',
      estimacion: 4519,
    },
    {
      fecha: '2020-07',
      estimacion: 4519,
    },
    {
      fecha: '2020-08',
      estimacion: 4519,
    },
    {
      fecha: '2020-09',
      estimacion: 4519,
    },
    {
      fecha: '2020-10',
      estimacion: 4519,
    },
    {
      fecha: '2020-11',
      estimacion: 4519,
    },
    {
      fecha: '2020-12',
      estimacion: 4519,
    },
  ],
};

export default STUDIODATA_MOCK;
