import React from 'react';
import { VictoryAxis, VictoryChart, VictoryContainer, VictoryLine } from 'victory';
import { Colors } from 'styles';
import { I18n } from 'core';
import { UtilsService } from 'services';
import { SizeMe } from 'react-sizeme';
import { getMonthFromStringDate } from 'services/local/getMonthFromStringDate';
import styles from './SavingAgregateWidget.module.scss';
import Card from '../../Card/Card';

interface AcummulatedSavingDataI {
  x: number;
  y: number;
}

interface SavingDataPropsI {
  acumulatedSaving: number;
  savingArray: Array<AcummulatedSavingDataI>;
  startDate: string;
  finalDate: string;
}

const SavingAgregateCard = (props: SavingDataPropsI): JSX.Element => {
  const { acumulatedSaving, savingArray, startDate, finalDate } = props;

  // if (!isClient) {
  //   return <></>;
  // }

  return (
    <Card
      fullHeight
      title={[I18n.t('accumulatedSavings')]}
      subtitle={[I18n.t('totalAccumulated').toUpperCase()]}
      rightCorner={[`${UtilsService.formatTwoDecimal(acumulatedSaving)} €`]}
      body={[
        <div key={1} className={styles.container}>
          <div className={styles.chartWrapper}>
            <SizeMe>
              {({ size }) => (
                <div>
                  <svg viewBox={`0 0 ${size.width} 200`} height="100%" width="100%">
                    <VictoryChart
                      width={size.width || 400}
                      height={size.height || 200}
                      standalone={false}
                      padding={{ top: 20 }}
                      containerComponent={<VictoryContainer responsive={false} />}
                    >
                      <VictoryLine
                        data={savingArray}
                        style={{
                          data: { stroke: Colors.COLOR_ELECSUMGREEN, strokeWidth: 4 },
                        }}
                      />
                      <VictoryAxis
                        style={{
                          axis: { stroke: 'transparent' },
                          ticks: { stroke: 'transparent' },
                          tickLabels: { fill: 'transparent' },
                        }}
                      />
                    </VictoryChart>
                  </svg>
                </div>
              )}
            </SizeMe>
          </div>
          <div className={styles.cumulated}>
            <p>{getMonthFromStringDate(startDate, true)}</p>
            <p>{getMonthFromStringDate(finalDate, true)}</p>
          </div>
        </div>,
      ]}
    />
  );
};

export default SavingAgregateCard;
