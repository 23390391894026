import { Serializable } from 'types/interfaces';
import {  TranslatableJSON } from 'types/interfaces/polyglot';

export interface TipodecontactoAPII {
  tipoContacto: TranslatableJSON<string>;
  emailContacto: string;
}

export const translatableMockString: TranslatableJSON<string> = {
  es: '',
  ca: '',
};
export interface CaracteristicasComunidad {
  id: string;
  categoria: TranslatableJSON<string>;
  texto: TranslatableJSON<string>;
  valor: TranslatableJSON<string>;
}
export default class Tipodecontacto implements Serializable<Tipodecontacto> {


  tipoContacto: {
    es: string;
    ca: string;
  }

  emailContacto: string;

  constructor() {
    this.tipoContacto = {
      es: '',
      ca: '',
    };

  }

  deserialize(input: TipodecontactoAPII): Tipodecontacto {
    if (!input) {
      return this;
    }
    this.tipoContacto = {
      es: input.tipoContacto.es,
      ca: input.tipoContacto.ca,
    };
    this.emailContacto = input.emailContacto || '';

    return this;
  }



  setAttr<T>(key: string, value: T): void {
    Object.assign(this, { [key]: value });
  }
}
