import configuration from 'config';
import { AxiosResponse } from 'axios';
import SolicitudesPendientesRegistro from 'types/models/solicitudesPendiente';
import apiService from './ApiService';

class SolicitudesService {
  private readonly http = apiService;

  private apiUrl = configuration.apiUrl;

  async getSolicitudesByComunidad(id: string) {
    const response: AxiosResponse = await this.http.get(
      `${this.apiUrl}/ce/solicitud-de-registro/pendientes/comunidad/${id}/`,
    );
    const { datos } = response.data;

    const solicitudes = new SolicitudesPendientesRegistro().deserializeArray(datos);

    return solicitudes;
  }

  async getSolicitudesByPromotor(nif: string) {
    const response: AxiosResponse = await this.http.get(
      `${this.apiUrl}/ce/solicitud-de-registro/pendientes/promotor/${nif}/`,
    );
    const { datos } = response.data;

    const solicitudes = new SolicitudesPendientesRegistro().deserializeArray(datos);

    return solicitudes;
  }
}

const SolicitudesServiceRegistroPendiente: SolicitudesService = new SolicitudesService();

export default SolicitudesServiceRegistroPendiente;
