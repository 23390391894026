import { ProdEnergyProductionDataHistoricalAPII } from '../types/models/widgetsData/prodEnergyProductionDataHistorical';

const PROD_ENERGY_PRODUCTION_H_DATA_MOCK: ProdEnergyProductionDataHistoricalAPII = {
  años: [
    // {
    //   año: 2010,
    //   generacion: 4870000,
    //   garantia: 4495400,
    //   diferenciaGarantia: 8.33,
    //   estimacion: 4778300,
    //   diferenciaEstimacion: 1.92,
    //   diferenciaGeneracion: -0.5,
    // },
    // {
    //   año: 2011,
    //   generacion: 3870000,
    //   garantia: 3495400,
    //   diferenciaGarantia: 7.33,
    //   estimacion: 3778300,
    //   diferenciaEstimacion: 1.52,
    //   diferenciaGeneracion: -0.3,
    // },
    // {
    //   año: 2012,
    //   generacion: 4870000,
    //   garantia: 4495400,
    //   diferenciaGarantia: 8.33,
    //   estimacion: 4778300,
    //   diferenciaEstimacion: 1.92,
    //   diferenciaGeneracion: -0.5,
    // },
    // {
    //   año: 2013,
    //   generacion: 1000,
    //   garantia: 1500,
    //   diferenciaGarantia: 3.33,
    //   estimacion: 1000,
    //   diferenciaEstimacion: 0.92,
    //   diferenciaGeneracion: -0.3,
    // },
    // {
    //   año: 2014,
    //   generacion: 2000,
    //   garantia: 2500,
    //   diferenciaGarantia: 1.33,
    //   estimacion: 2000,
    //   diferenciaEstimacion: 0.32,
    //   diferenciaGeneracion: -0.2,
    // },
    // {
    //   año: 2015,
    //   generacion: 3000,
    //   garantia: 3500,
    //   diferenciaGarantia: 8.33,
    //   estimacion: 3000,
    //   diferenciaEstimacion: 1.92,
    //   diferenciaGeneracion: -0.5,
    // },
    // {
    //   año: 2016,
    //   generacion: 4000,
    //   garantia: 4500,
    //   diferenciaGarantia: 8.33,
    //   estimacion: 4000,
    //   diferenciaEstimacion: 1.92,
    //   diferenciaGeneracion: -0.5,
    // },
    // {
    //   año: 2017,
    //   generacion: 5000,
    //   garantia: 5500,
    //   diferenciaGarantia: 8.33,
    //   estimacion: 5000,
    //   diferenciaEstimacion: 1.92,
    //   diferenciaGeneracion: -0.5,
    // },
    {
      año: 2018,
      generacion: 4000,
      garantia: 3000,
      diferenciaGarantia: 8.33,
      estimacion: 4000,
      diferenciaEstimacion: 1.92,
      diferenciaGeneracion: -0.5,
    },
    {
      año: 2019,
      generacion: 1000,
      garantia: 2000,
      diferenciaGarantia: 8.33,
      estimacion: 3000,
      diferenciaEstimacion: 1.92,
      diferenciaGeneracion: -0.5,
    },
    {
      año: 2020,
      generacion: 5000,
      garantia: 2000,
      diferenciaGarantia: 3.33,
      estimacion: 3500,
      diferenciaEstimacion: 1.52,
      diferenciaGeneracion: -0.5,
    },
  ],
};

export default PROD_ENERGY_PRODUCTION_H_DATA_MOCK;
