import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { I18n } from 'core';
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryStack,
  VictoryBar,
  VictoryLabel,
  VictoryContainer,
} from 'victory';
import { Colors } from 'styles';
import { TeledetectionData } from 'types/models';
import wattsConverterService from 'services/local/WattsConverter';
import cx from 'classnames';
import { SizeMe } from 'react-sizeme';
import Constants from 'config/constants';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import styles from './TeledetectionChart.module.scss';

interface SelectorPropsI {
  name: string;
  currentTab: string;
  changeCurrentTab: () => void;
  value: string;
}

interface TeledetectionChartPropsI {
  teledetectionData: TeledetectionData;
  dateComponent: JSX.Element;
  currentTab: string;
  setCurrentTabFunction: Dispatch<SetStateAction<string>>;
  fotovoltaica: string;
  isLoading?: boolean;
}

const NAV_HISTORIC = 'historic';
const NAV_YEAR = 'year';
const NAV_MONTH = 'month';
const NAV_DAY = 'day';

const Selector = React.memo((props: SelectorPropsI): JSX.Element => {
  const { name, currentTab, value, changeCurrentTab } = props;

  const toogleCurrentTab = (): void => {
    changeCurrentTab();
  };

  return (
    <div>
      <button
        onClick={toogleCurrentTab}
        type="button"
        className={cx(styles.item, { [styles.active]: currentTab === value })}
      >
        {I18n.t(name)}
      </button>
    </div>
  );
});

const TeledetectionChart = (props: TeledetectionChartPropsI): JSX.Element => {
  const { teledetectionData, dateComponent, currentTab, setCurrentTabFunction, fotovoltaica, isLoading } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const initApiData = {
    totalGen: 0,
    totalShrink: 0,
    totalSelfCons: 0,
    totalNetwork: 0,
    units: 'H',
    labels: [1, 2, 3],
  };

  const [numberTicks, setNumberTicks] = useState<number>();
  const [barWidth, setBarWidth] = useState<number | undefined>(undefined);
  const [graphData, setGraphData] = useState({
    units: '',
    xLabels: [0],
    yLabels: [0],
    optMagnitude: 'Wh',
  });
  useEffect((): void => {
    let totalPower;
    if (teledetectionData.generationData !== undefined) {
      totalPower =
        teledetectionData.totals.generation +
        teledetectionData.totals.network +
        teledetectionData.totals.selfconsumption +
        teledetectionData.totals.shrink;
    } else {
      totalPower = 0;
    }

    let defaultYLabels = [0];
    if (totalPower > 0) {
      defaultYLabels = [];
    }

    setGraphData({
      units: 'H',
      xLabels: [],
      yLabels: defaultYLabels,
      optMagnitude: wattsConverterService.getOptimalMagnitude(totalPower),
    });

    if (currentTab === NAV_DAY) {
      setNumberTicks(12);
      setBarWidth(undefined);
      return;
    }
    if (currentTab === NAV_MONTH) {
      setNumberTicks(10);
      setBarWidth(undefined);
      return;
    }
    if (currentTab === NAV_YEAR) {
      setNumberTicks(12);
      setBarWidth(undefined);
      setGraphData({
        units: 'H',
        xLabels: Array.from(Array(12).keys(), (n) => n + 1),
        yLabels: defaultYLabels,
        optMagnitude: wattsConverterService.getOptimalMagnitude(totalPower),
      });
      return;
    }
    setBarWidth(20);
    setNumberTicks(teledetectionData.generationData.length / 2 + 1);
  }, [currentTab, teledetectionData]);

  const getTickLabel = (): string => {
    if (currentTab === 'day') return 'H';
    if (currentTab === 'month') return '';
    return '';
  };

  return (
    <>
      {teledetectionData.generationData !== undefined ? (
        <>
          <div className={styles['pick-container']}>
            <div className={styles.scale}>
              <Selector
                changeCurrentTab={(): void => setCurrentTabFunction(NAV_DAY)}
                name="day"
                value={NAV_DAY}
                currentTab={currentTab}
              />
              <Selector
                changeCurrentTab={(): void => setCurrentTabFunction(NAV_MONTH)}
                name="month"
                value={NAV_MONTH}
                currentTab={currentTab}
              />
              <Selector
                changeCurrentTab={(): void => setCurrentTabFunction(NAV_YEAR)}
                name="year"
                value={NAV_YEAR}
                currentTab={currentTab}
              />
              <Selector
                changeCurrentTab={(): void => setCurrentTabFunction(NAV_HISTORIC)}
                name="historic"
                value={NAV_HISTORIC}
                currentTab={currentTab}
              />
            </div>
            <div className={styles['date-component']}>{dateComponent}</div>
          </div>
          <SizeMe>
            {({ size }) => (
              <div>
                {!isLoading ? (
                  <svg viewBox={`0 0 ${size.width} 400`} height="100%" width="100%">
                    <VictoryChart
                      width={size.width || 500}
                      height={400}
                      standalone={false}
                      domainPadding={{ y: 10 }}
                      containerComponent={<VictoryContainer responsive={false} />}
                    >
                      <VictoryLabel
                        style={{ fontSize: Constants.charts.AXIS_LABELS_FONTSIZE }}
                        text={graphData.optMagnitude}
                        x={20}
                        y={30}
                        textAnchor="middle"
                      />
                      <VictoryAxis
                        style={{
                          axis: { stroke: 'transparent' },
                          tickLabels: {
                            fontSize: Constants.charts.AXIS_VALUES_FONTSIZE,
                            fill: Colors.COLOR_GRAY_500,
                          },
                        }}
                        tickLabelComponent={<VictoryLabel dx={10} />}
                        tickValues={graphData.xLabels}
                        tickCount={numberTicks}
                        tickFormat={(t): string => `${Math.round(t).toString()}${getTickLabel()}`}
                        offsetY={45}
                      />
                      <VictoryAxis
                        dependentAxis
                        style={{
                          axis: { stroke: 'transparent' },
                          tickLabels: {
                            fontSize: Constants.charts.AXIS_VALUES_FONTSIZE,
                            fill: Colors.COLOR_GRAY_500,
                          },
                        }}
                        offsetX={45}
                        tickValues={graphData.yLabels}
                        tickCount={6}
                        tickFormat={(tick): string =>
                          `${wattsConverterService.toSelectedMagnitude(tick, graphData.optMagnitude)}`
                        }
                      />
                      {teledetectionData.generationData.length !== 0 ||
                      teledetectionData.networkonData.length !== 0 ||
                      teledetectionData.selfConsumptionData.length !== 0 ||
                      teledetectionData.shirnkData.length !== 0 ? (
                        <VictoryStack
                          colorScale={
                            fotovoltaica === 'si'
                              ? [Colors.COLOR_EXCEDENTS, Colors.COLOR_ELECSUMGREEN, Colors.COLOR_GRAY_100]
                              : [Colors.COLOR_GRAY_100]
                          }
                        >
                          {fotovoltaica === 'si' && (
                            <VictoryBar
                              alignment="start"
                              barWidth={barWidth}
                              barRatio={Constants.charts.BAR_RATIO_FILL}
                              cornerRadius={{
                                top: Constants.charts.BAR_CORNER_RADIUS,
                              }}
                              data={teledetectionData.shirnkData}
                            />
                          )}
                          {fotovoltaica === 'si' && (
                            <VictoryBar
                              alignment="start"
                              barWidth={barWidth}
                              barRatio={Constants.charts.BAR_RATIO_FILL}
                              data={teledetectionData.selfConsumptionData}
                            />
                          )}
                          <VictoryBar
                            alignment="start"
                            barWidth={barWidth}
                            barRatio={Constants.charts.BAR_RATIO_FILL}
                            cornerRadius={{
                              top: Constants.charts.BAR_CORNER_RADIUS,
                            }}
                            data={teledetectionData.networkonData}
                          />
                        </VictoryStack>
                      ) : null}
                      {fotovoltaica === 'si' && (
                        <VictoryLine
                          interpolation="natural"
                          style={{
                            data: {
                              stroke: Colors.COLOR_GENERACIO,
                              strokeWidth: 2,
                            },
                          }}
                          data={teledetectionData.generationData}
                        />
                      )}
                    </VictoryChart>
                  </svg>
                ) : (
                  <div style={{ height: 400 }}>
                    <FullPageSyncLoader icon />
                  </div>
                )}
              </div>
            )}
          </SizeMe>
        </>
      ) : null}
    </>
  );
};

export default React.memo(TeledetectionChart);
