import React from 'react';

import { I18n } from 'core';
import { ReactComponent as GuaranteeIcon } from 'assets/icons/Guarantee.svg';
import { WattsConverterService } from 'services';

import { Colors } from 'styles';
import styles from './ProductionGuarantee.module.scss';

interface PGProps {
  totalGuarantee: number;
}
const ProductionGuarantee = ({ totalGuarantee }: PGProps): JSX.Element => (
  <div className={styles.root}>
    <div className={styles.img_wrapper}>
      <GuaranteeIcon width={40} height={40} fill={Colors.COLOR_WHITE} />
    </div>
    <div className={styles.title}>{I18n.t('Production guarantee')}</div>
    <div className={styles.data}>
      {WattsConverterService.convertWatts(totalGuarantee * 1000).num}
      <span className={styles.units}>{WattsConverterService.convertWatts(totalGuarantee * 1000).unitHour}</span>
    </div>
  </div>
);

export default ProductionGuarantee;
