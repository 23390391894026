import { SectionTitle } from 'components';

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { FormGroup, FormGroupProps, I18n } from 'core';
import { ReactComponent as DocumentsIcon } from 'assets/icons/Documents.svg';

import { CommunityCustomOnboarding } from 'types/models';
import { Colors } from 'styles';
import { LoggingService } from 'services';

import { SelectItemI } from 'components/NewInputs/Select/ElecsumSelect';
import { translatableLangs } from 'config/translatable-langs';
import { ToolsBarWithoutUrl } from 'pages/ModeTecnic/rol-comunidades/comunidades-detalle-page/tools-bar-without-url/tools-bar-without-url';
import { SaveDataHandle } from '../../comunidades-custom-onboarding-process-page';
import styles from './comunidades-custom-onboarding-tipodecontacto.module.scss';

interface ComunidadesCustomOnboardingFormPopUpProps {
  communityId: string;
  communityCustomOnboarding: CommunityCustomOnboarding;
  saveDetails: (customOnboarding: CommunityCustomOnboarding) => void;
}

const ComunidadesCustomOnboardingFormPopUp = forwardRef<SaveDataHandle, ComunidadesCustomOnboardingFormPopUpProps>(
  (props: ComunidadesCustomOnboardingFormPopUpProps, ref) => {
    const { communityCustomOnboarding, saveDetails } = props;
    const [customOnboarding, setCustomOnboarding] = useState<CommunityCustomOnboarding>();
    const [errors, setErrors] = useState({});
    const [currentLang, setCurrentLang] = useState<SelectItemI>(translatableLangs[0]);
    const customOnboardingData = JSON.parse(JSON.stringify(communityCustomOnboarding.serialize()));
    const validateForm = (): boolean => {
      setErrors({});
      return true;
    };

    function updateLanguageFields<T>(
      path: string,
      value: T,
      currentLangUp: { id: string },
      customOnboardingDataUp: any,
      communityCustomOnboardingUp: any,
    ) {
      let updatedValue: any = {};
      const { id: currentLangId } = currentLangUp;
      if (currentLangId === 'es') {
        updatedValue = {
          es: value as unknown as string,
          ca: customOnboardingDataUp[path]?.ca || '',
        };
      } else if (currentLangId === 'ca') {
        updatedValue = {
          es: customOnboardingDataUp[path]?.es || '',
          ca: value as unknown as string,
        };
      }

      const updatedCustomOnboardingData = {
        ...customOnboardingDataUp,
        [path]: updatedValue,
      };

      setCustomOnboarding(new CommunityCustomOnboarding().deserialize(updatedCustomOnboardingData));
      communityCustomOnboardingUp.setAttr(path, updatedValue);
    }

    function updateLanguageFieldsOnboarding<T>(
      path: string,
      value: T,
      currentLangUp: { id: string },
      customOnboardingDataUp: any,
      communityCustomOnboardingUp: any,
    ) {
      const currentLangId = currentLangUp.id;

      // Dividir la cadena `path` por el punto y obtener el segundo valor
      const pathSegments = path.split('.');
      const fieldName = pathSegments[1];

      const updatedValue = {
        ...(communityCustomOnboardingUp?.personalizacionOnboarding?.[fieldName] || {}),
        [currentLangId]: String(value), // Convertir a cadena de manera segura
      };

      const updatedPersonalizacionOnboarding = {
        ...communityCustomOnboardingUp?.personalizacionOnboarding,
        [fieldName]: updatedValue,
      };

      // Mantén todos los demás datos en `communityCustomOnboarding`
      const updatedCustomOnboardingData = {
        ...customOnboardingDataUp,
        personalizacionOnboarding: updatedPersonalizacionOnboarding,
      };

      // Actualiza `customOnboarding` con los datos actualizados
      setCustomOnboarding(new CommunityCustomOnboarding().deserialize(updatedCustomOnboardingData));
      communityCustomOnboardingUp.setAttr('personalizacionOnboarding', updatedPersonalizacionOnboarding);
    }
    function handleChange<T>(path: string, value: T): void {
      if (path === 'politicaDePrivacidad') {
        updateLanguageFields(path, value, currentLang, customOnboardingData, communityCustomOnboarding);
      } else if (path.includes('Onboarding')) {
        updateLanguageFieldsOnboarding(path, value, currentLang, customOnboardingData, communityCustomOnboarding);
      } else {
        const updatedCustomOnboardingData = {
          ...customOnboardingData,
          [path]: value,
        };
        setCustomOnboarding(new CommunityCustomOnboarding().deserialize(updatedCustomOnboardingData));
        communityCustomOnboarding.setAttr(path, value);
      }
    }

    const handleSubmit = (): void => {
      if (validateForm() && customOnboarding) {
        LoggingService.log('handleSubmit :: ok', { client: customOnboarding });
        saveDetails(customOnboarding);
      } else {
        LoggingService.log('handleSubmit :: errors', { client: customOnboarding, errors });
      }
    };

    const formFields: Array<FormGroupProps> = [
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.tituloPopUpInicial'),
        type: 'textarea',
        id: 'personalizacionOnboarding.tituloPopUpInicial',
        path: 'personalizacionOnboarding.tituloPopUpInicial',
        name: 'personalizacionOnboarding.tituloPopUpInicial',
        // placeholder: I18n.t('ComunidadesCustomOnboardingProcess.form.placeholder.personalizacionOnboarding.tituloOnboarding'),
        value:
          currentLang.id === 'es'
            ? communityCustomOnboarding?.personalizacionOnboarding.tituloPopUpInicial.es
            : communityCustomOnboarding?.personalizacionOnboarding.tituloPopUpInicial.ca,
        maxLenght: 500,
      },
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.descripcionPopUpInicial'),

        type: 'textarea',
        id: 'personalizacionOnboarding.descripcionPopUpInicial',
        path: 'personalizacionOnboarding.descripcionPopUpInicial',
        name: 'personalizacionOnboarding.descripcionPopUpInicial',
        // placeholder: I18n.t('ComunidadesCustomOnboardingProcess.form.placeholder.personalizacionOnboarding.tituloOnboarding'),
        value:
          currentLang.id === 'es'
            ? communityCustomOnboarding?.personalizacionOnboarding.descripcionPopUpInicial.es
            : communityCustomOnboarding?.personalizacionOnboarding.descripcionPopUpInicial.ca,
        maxLenght: 500,
      },
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.paso1PopUpInicial'),
        type: 'textarea',
        id: 'personalizacionOnboarding.paso1PopUpInicial',
        path: 'personalizacionOnboarding.paso1PopUpInicial',
        name: 'personalizacionOnboarding.paso1PopUpInicial',
        // placeholder: I18n.t('ComunidadesCustomOnboardingProcess.form.placeholder.personalizacionOnboarding.tituloOnboarding'),
        value:
          currentLang.id === 'es'
            ? communityCustomOnboarding?.personalizacionOnboarding.paso1PopUpInicial.es
            : communityCustomOnboarding?.personalizacionOnboarding.paso1PopUpInicial.ca,
        maxLenght: 500,
      },
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.paso2PopUpInicial'),
        type: 'textarea',
        id: 'personalizacionOnboarding.paso2PopUpInicial',
        path: 'personalizacionOnboarding.paso2PopUpInicial',
        name: 'personalizacionOnboarding.paso2PopUpInicial',
        // placeholder: I18n.t('ComunidadesCustomOnboardingProcess.form.placeholder.personalizacionOnboarding.tituloOnboarding'),
        value:
          currentLang.id === 'es'
            ? communityCustomOnboarding?.personalizacionOnboarding.paso2PopUpInicial.es
            : communityCustomOnboarding?.personalizacionOnboarding.paso2PopUpInicial.ca,
        maxLenght: 500,
      },

      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.paso3PopUpInicial'),
        type: 'textarea',
        id: 'personalizacionOnboarding.paso3PopUpInicial',
        path: 'personalizacionOnboarding.paso3PopUpInicial',
        name: 'personalizacionOnboarding.paso3PopUpInicial',
        // placeholder: I18n.t('ComunidadesCustomOnboardingProcess.form.placeholder.personalizacionOnboarding.tituloOnboarding'),
        value:
          currentLang.id === 'es'
            ? communityCustomOnboarding?.personalizacionOnboarding.paso3PopUpInicial.es
            : communityCustomOnboarding?.personalizacionOnboarding.paso3PopUpInicial.ca,
        maxLenght: 500,
      },
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.paso4PopUpInicial'),
        type: 'textarea',
        id: 'personalizacionOnboarding.paso4PopUpInicial',
        path: 'personalizacionOnboarding.paso4PopUpInicial',
        name: 'personalizacionOnboarding.paso4PopUpInicial',
        // placeholder: I18n.t('ComunidadesCustomOnboardingProcess.form.placeholder.personalizacionOnboarding.tituloOnboarding'),
        value:
          currentLang.id === 'es'
            ? communityCustomOnboarding?.personalizacionOnboarding.paso4PopUpInicial.es
            : communityCustomOnboarding?.personalizacionOnboarding.paso4PopUpInicial.ca,
        maxLenght: 500,
      },
    ];

    useImperativeHandle(ref, () => ({
      getData: () => {
        handleSubmit();
      },
    }));

    return (
      <div className={styles.root}>
        <div className={styles.flex}>
          <div className={styles.titulo}>
            <DocumentsIcon height={20} width={20} fill={Colors.COLOR_BLACK} className={styles.DocumentsIcon} />
            <SectionTitle text={I18n.t('ComunidadesCustomOnboardingProcess.form.tipodePopUpTitle')} />
          </div>
          <div>
            <ToolsBarWithoutUrl
              langs={translatableLangs}
              selectedLang={currentLang}
              onChangeLanguage={(x) => setCurrentLang(x)}
            />
          </div>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.containerForms}>
            <div className={styles.containerForm}>
              {formFields.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  placeholder={formGroup.placeholder}
                  value={formGroup.value}
                  required={formGroup.required}
                  onChange={handleChange}
                  readOnly={formGroup.readOnly}
                  className={formGroup.className}
                  maxLenght={20000}
                />
              ))}
            </div>
          </div>
        </form>
      </div>
    );
  },
);

export default ComunidadesCustomOnboardingFormPopUp;
