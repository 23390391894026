import { Serializable } from '../interfaces';

export interface ElecsumGreenAPI {
  identificador: string;
  nombreComercial: string;
  telefono: string;
  paginaWeb: string;
  email: string;
  tipoDeCliente: string;
  imagenes: Array<ElecsumGreenImage>;
  noticias: Array<GreenNews>;
  descripcion?: string;
}

export interface ElecsumGreenImage {
  id: string;
  tipo: string;
  url: string;
}

export interface GreenNews {
  id: string;
  titular: string;
  fecha: string;
  descripcion: string;
  imagenUrl: string;
}

export default class ElecsumGreen implements Serializable<ElecsumGreen> {
  identificador: string;

  comercialName: string;

  telephone: string;

  web: string;

  email: string;

  type: string;

  images: Array<ElecsumGreenImage>;

  news: Array<GreenNews>;

  description?: string;

  deserialize(input: ElecsumGreenAPI): ElecsumGreen {
    if (!input) {
      return this;
    }

    this.identificador = input.identificador;
    this.comercialName = input.nombreComercial || '';
    this.telephone = input.telefono || '';
    this.web = input.paginaWeb || '';
    this.email = input.email || '';
    this.type = input.tipoDeCliente || '';
    this.description = input.descripcion || '';
    this.images = input.imagenes;
    this.news = input.noticias;

    return this;
  }

  serialize(): ElecsumGreenAPI {
    return {
      identificador: this.identificador,
      nombreComercial: this.comercialName,
      telefono: this.telephone,
      paginaWeb: this.web,
      email: this.email,
      tipoDeCliente: this.type,
      descripcion: this.description,
      imagenes: this.images,
      noticias: this.news,
    } as ElecsumGreenAPI;
  }

  setAttr<T>(key: string, value: T): void {
    Object.assign(this, { [key]: value });
  }
}
