import environment from 'config/environment';

export default class LoggingService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static log(...args: any): void {
    if (environment.dev) {
      // eslint-disable-next-line no-console
      console.log(...args);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static error(...args: any): void {
    if (environment.dev) {
      // eslint-disable-next-line no-console
      console.error(...args);
    }
  }
}
