import React, { useEffect, useState } from 'react';
import { I18n } from 'core';
import { navigate } from 'navigation';
import { clientServiceInstance, ClientInfo, installationServiceInstance } from 'services';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { SectionTitle, Spinner, TableHeading } from 'components';
import { useDispatch } from 'react-redux';
import { fetchInstallation, fetchInstallationSuccess } from 'redux/actions/InstallationsActions';
import ClientsTable from './ClientsTable/ClientsTable';
import styles from './InstallationCupsPage.module.scss';

interface ClientDataI {
  id: string;
  cups: string;
  nombre: string;
  production: string;
  distribution: string;
  distributionP: string;
}

const InstallationCupsPage = (props: any): JSX.Element => {
  // Props
  const {
    match: {
      params: { installationID },
    },
  } = props;

  // Hooks
  const dispatch = useDispatch();

  // States

  const [clients, setClients] = useState<Array<ClientInfo>>([]);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  // Effects

  useEffect(() => {
    dispatch(fetchInstallation());
    installationServiceInstance.getInstallation(installationID).then((response) => {
      dispatch(fetchInstallationSuccess(response));
    });
  }, []);

  useEffect(() => {
    const getClients = async (): Promise<void> => {
      setLoadingData(true);
      if (!installationID) {
        return;
      }
      const apiClients = await clientServiceInstance.getClientsFromInstallation(installationID);

      setClients(apiClients);
      setLoadingData(false);
    };
    getClients();
  }, []);

  const headings: Array<TableHeading> = [
    { label: I18n.t('Client'), key: 'nombre' },
    { label: I18n.t('Production guarantee (kWh/year)'), key: 'production' },
    { label: I18n.t('Beta distribution (KWh)'), key: 'distribution' },
    { label: I18n.t('Beta distribution (%)'), key: 'distributionP' },
  ];
  const onRowClick = (client: ClientDataI): void => {
    // navigate(`/data/clients/${client.id}`);
    navigate(`/tecnic/cups/${client.cups}/detail`);
  };

  return (
    <div className={styles.root}>
      <SectionWrapper>
        <SectionTitle text={I18n.t('Clients List')} />
        {(() => {
          if (loadingData) {
            return (
              <div className={styles.loading_wrapper}>
                <Spinner icon />
              </div>
            );
          }
          return <ClientsTable headings={headings} onRowClick={onRowClick} rows={clients} />;
        })()}
      </SectionWrapper>
    </div>
  );
};

export default React.memo(InstallationCupsPage);
