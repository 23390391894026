import React from 'react';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
// import { I18n } from 'core';
import TooltipOferta from 'components/tooltipOferta';
import styles from './CupsTooltip.module.scss';

const TooltipContent = (props: { descripcion: string[] }): JSX.Element => {
  const { descripcion } = props;

  return (
    <div className={styles.contentInput}>
      <p>{descripcion[0]} </p>
      <ul>
        {descripcion[1] ? <li>{descripcion[1]}</li> : null}
        {descripcion[2] ? <li>{descripcion[2]}</li> : null}
        {descripcion[3] ? <li>{descripcion[3]}</li> : null}
      </ul>
    </div>
  );
};

const CupsTooltipInputMatcher = (props: {
  title: string;
  descripcion: string[];
  direction?: 'top' | 'right' | 'bottom' | 'left';
}): JSX.Element => {
  const { title, descripcion, direction = 'right' } = props;

  return (
    <TooltipOferta cursor="help" direction={direction} content={<TooltipContent descripcion={descripcion} />}>
      <div className={styles.root}>
        <InfoIcon />
        <p>{title}</p>
      </div>
    </TooltipOferta>
  );
};

export default CupsTooltipInputMatcher;
