import React from 'react';
import { I18n } from 'core';
import { Solicitudes } from 'types/models';
import styles from './SolicitudCupsTerceroPage.module.scss';

interface TableSolicitudProps {
  solicitudes: Solicitudes[];
}

const TableSolicitud = (props: TableSolicitudProps) => {
  const { solicitudes } = props;

  return (
    <div className={styles.table_wrapper}>
      <table>
        <thead>
          <tr>
            <th>{I18n.t('UsuarioCupsAssociarPage.headers.Cups')}</th>
          </tr>
        </thead>
        <tbody>
          {solicitudes && solicitudes.length > 0 ? (
            solicitudes.map((rol) => (
              <tr key={rol.cups}>
                <td>{rol.cups}</td>
                <td className={styles.checkboxTable}>
                  <input
                    id="seleccionado"
                    type="checkbox"
                    name="seleccionado"
                    disabled
                    defaultChecked={rol.acceptada === 'si'}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr key="no-data">
              <td className={styles.nodata}>{I18n.t('noData')}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableSolicitud;
