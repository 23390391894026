import configuration from 'config';
import { AxiosResponse } from 'axios';
import { ElecsumGreen, ElecsumGreenAPI, ElecsumGreenNew, ElecsumGreenWeb, ElecsumGreenWebAPI } from 'types/models';
import { ELECSUMGREENWEBPAGE_MOCK } from 'mocks';
import apiService from './ApiService';

export interface ElecsumGreenToAPI {
  paginaWeb: string;
  email: string;
  tipoDeCliente: string;
  descripcion: string;
}
export class ElecsumGreenService {
  private readonly http = apiService;

  private apiUrl = configuration.apiUrl;

  public async getElecsumGreenId(nif: string): Promise<string> {
    const url = `${this.apiUrl}/fv/elecsum-green/${nif}/identificador/`;

    const response: AxiosResponse<{
      datos: { identificador: string };
    }> = await this.http.get(url);

    return response.data.datos.identificador;
  }

  async getElecsumGreen(nif: string): Promise<ElecsumGreen> {
    const url = `${this.apiUrl}/fv/elecsum-green/${nif}/`;

    const response: AxiosResponse<{
      datos: ElecsumGreenAPI;
    }> = await this.http.get(url);

    const elecsumGreen = new ElecsumGreen().deserialize(response.data.datos);

    return elecsumGreen;
  }

  async addElecsumGreenNews(nif: string, newToAdd: ElecsumGreenNew, file: File): Promise<number> {
    const url = `${this.apiUrl}/fv/elecsum-green/${nif}/noticia/`;

    const newToAPI = newToAdd.serialize();

    // eslint-disable-next-line no-undef
    const newToPush = new FormData();
    newToPush.append('titular', newToAPI.titular);
    newToPush.append('fecha', newToAPI.fecha);
    newToPush.append('descripcion', newToAPI.descripcion);
    newToPush.append('imagen', file);

    const response: AxiosResponse<{ status: number }> = await this.http.post(url, newToPush);

    return response.status;
  }

  async deleteElecsumGreenNews(nif: string, id: string): Promise<unknown> {
    const url = `${this.apiUrl}/fv/elecsum-green/${nif}/noticia/${id}/`;

    const response: AxiosResponse<number> = await this.http.delete(url);

    return response;
  }

  async putElecsumGreen(nif: string, elecsumGreen: ElecsumGreen): Promise<unknown> {
    const url = `${this.apiUrl}/fv/elecsum-green/${nif}/`;

    const elecsumGreenToAPI = elecsumGreen.serialize();

    const response: AxiosResponse<string> = await this.http.put(url, elecsumGreenToAPI);

    return response;
  }

  async addGaleryImage(nif: string, file: File, type: string): Promise<number> {
    const url = `${this.apiUrl}/fv/elecsum-green/${nif}/imagen/`;
    const imageData = new window.FormData();

    imageData.append('tipo', type);
    imageData.append('imagen', file);

    const response: AxiosResponse<{ status: number }> = await this.http.post(url, imageData);

    return response.status;
  }

  async deleteElecsumGreenImage(nif: string, id: string): Promise<unknown> {
    const url = `${this.apiUrl}/fv/elecsum-green/${nif}/imagen/${id}/`;

    const response: AxiosResponse<number> = await this.http.delete(url);

    return response;
  }

  // async getStampMock(nif: string): Promise<GreenStamp> {
  //   const stamp: GreenStamp = new GreenStamp().deserialize({
  //     id: nif,
  //     ...GREEN_STAMP_MOCK,
  //   });

  //   await of([]).pipe(delay(1000)).toPromise();

  //   return stamp;
  // }

  // public getStampImage(size: string, stampId: string): Promise<File> {
  //   return this.http.get(`${this.apiUrl}/fv/elecsum-green/imagen/${size}/${stampId}/`);
  // }

  public getStampImage(size: string, stampId: string) {
    return this.http.get(`${this.apiUrl}/fv/elecsum-green/imagen-sello/${size}/${stampId}/`);
  }

  async getElecsumGreenWebPage(identificator: string): Promise<ElecsumGreenWeb> {
    const url = `${this.apiUrl}/fv/elecsum-green/web/${identificator}/`;

    if (configuration.useMocks) {
      const elecsumGreenWebPageData = new ElecsumGreenWeb().deserialize(ELECSUMGREENWEBPAGE_MOCK);
      return elecsumGreenWebPageData;
    }
    const response: AxiosResponse<{
      datos: ElecsumGreenWebAPI;
    }> = await this.http.get(url);

    const elecsumGreenWebPageData = new ElecsumGreenWeb().deserialize(response.data.datos);

    return elecsumGreenWebPageData;
  }
}

const greenStampServiceInstance = new ElecsumGreenService();

export default greenStampServiceInstance;
