// import { SustainabilityObj, EnergyObj } from '../models';
import configuration from 'config';
import { AxiosResponse } from 'axios';
import apiService from 'services/remote/ApiService';

export const dataFrequencies = {
  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year',
  HISTORIC: 'historic',
};

class FvService {
  private readonly http = apiService;

  private apiUrl = configuration.apiUrl;

  /* public async getSustainabilityData(cups: string): Promise<SustainabilityObj> {
    const response: unknown = await this.http.get(`${this.apiUrl}/fv/sostenibilidad/${cups}/`);

    return new SustainabilityObj().deserialize(response.data);
  } */

  /* public async getEnergyData(cups: string): Promise<EnergyObj> {
    const response: unknown = await this.http.get(`${this.apiUrl}/fv/widget/energia/${cups}/`);

    return new EnergyObj().deserialize(response.data);
  } */

  /* public async getSavingData(cups: string): Promise<EnergyObj> {
    const response: unknown = await this.http.get(`${this.apiUrl}/fv/widget/ahorro/${cups}/`);

    return response.data.datos;
  } */

  /* public async getAmortizationData(cups: string): Promise<EnergyObj> {
    const response: unknown = await this.http.get(`${this.apiUrl}/fv/widget/amortizacion/${cups}/`);

    return response.data.datos;
  } */

  /* public async getTeledetectionDayData(
    cups: string,
    year: number,
    month: number,
    day: number): Promise<unknown> {
    const response: unknown = await this.http.get(
      `${this.apiUrl}/fv/teledeteccion/diaria/${cups}/${year}/${month}/${day}/`,
    );

    return response.data.datos;
  } */

  /* public async getTeledetectionMonthData(
    cups: string,
    year: number,
    month: number): Promise<unknown> {

    const response: unknown =
    await this.http.get(`${this.apiUrl}/fv/teledeteccion/mensual/${cups}/${year}/${month}/`);

    return response.data.datos;
  } */

  /* public async getTeledetectionYearData(cups: string, year: number): Promise<unknown> {
    const response: unknown =
    await this.http.get(`${this.apiUrl}/fv/teledeteccion/anual/${cups}/${year}/`);

    return response.data.datos;
  } */

  /* public async getTeledetectionHistoricData(cups: string): Promise<unknown> {
    const response: unknown =
    await this.http.get(`${this.apiUrl}/fv/teledeteccion/historica/${cups}/`);

    return response.data.datos;
  } */

  /* public getTeledetectionData(
    freq: string,
    cups: string,
    year?: number,
    month?: number,
    day?: number,
  ): Promise<unknown> {
    if (dataFrequencies.MONTH === freq) {
      return this.getTeledetectionMonthData(cups, year, month);
    }

    if (dataFrequencies.YEAR === freq) {
      return this.getTeledetectionYearData(cups, year);
    }

    if (dataFrequencies.HISTORIC === freq) {
      return this.getTeledetectionHistoricData(cups);
    }

    return this.getTeledetectionDayData(cups, year, month, day);
  } */

  /* public async getInvoicesData(cups: string): Promise<unknown> {
    const response: unknown = await this.http.get(`${this.apiUrl}/fv/facturas-lista/${cups}/`);

    return response.data.datos;
  } */

  public async getStampId(nif: string): Promise<string> {
    const response: AxiosResponse<{
      datos: { id: string };
    }> = await this.http.get(`${this.apiUrl}/fv/sello-verde/${nif}`);

    return response.data.datos.id;
  }

  public getStampImage(size: string, stampId: string): Promise<string> {
    return this.http.get(`${this.apiUrl}/fv/sello-verde/imagen-sello/${size}/${stampId}`);
  }
}

const fvService: FvService = new FvService();

export default fvService;
