import { Serializable } from '../interfaces';

export interface ElecsumGreenWebAPI {
  imagenCabecera: string;
  imagenLogo: string;
  nombreComercial: string;
  telefono: string;
  email: string;
  paginaWeb: string;
  fechaActualizacion: string;
  produccion: ProduccionObjectIAPI;
  consumo: ConsumoObjectIAPI;
  empresa: EmpresaObjectIAPI;
  tipoDeCliente?: string;
  comercializado: string;
}
export interface ProduccionObjectIAPI {
  añoEnCurso: ProduccionIAPI;
  totalAcumulado: ProduccionIAPI;
}
export interface ConsumoObjectIAPI {
  añoEnCurso: ConsumoIAPI;
  totalAcumulado: ConsumoIAPI;
}
export interface ProduccionIAPI {
  produccion: number;
  co2: number;
  arboles: number;
}
export interface ConsumoIAPI {
  consumo: number;
  co2: number;
  arboles: number;
}
export interface EmpresaObjectIAPI {
  imagenes: Array<string>;
  descripcion: string;
  noticias: Array<NoticiaIAPI>;
}
export interface NoticiaIAPI {
  titular: string;
  fecha: string;
  descripcion: string;
  imagenUrl: string;
}

export interface ProductionI {
  production: number;
  co2: number;
  trees: number;
}

export interface ProductionObjectI {
  actualYear: ProductionI;
  totalProduction: ProductionI;
}

export interface ConsumptionI {
  consumption: number;
  co2: number;
  trees: number;
}
export interface ConsumptionObjectI {
  actualYear: ConsumptionI;
  totaConsumption: ConsumptionI;
}

export interface NewsObjectI {
  headline: string;
  date: string;
  description: string;
  image: string;
}
export interface CompanyObjectI {
  images: Array<string>;
  description: string;
  news: Array<NewsObjectI>;
}

export default class ElecsumGreenWeb implements Serializable<ElecsumGreenWeb> {
  headerImage: string;

  logoImage: string;

  comercialName: string;

  telephone: string;

  email: string;

  webPage: string;

  actualizationDate: string;

  production: ProductionObjectI;

  consumption: ConsumptionObjectI;

  company: CompanyObjectI;

  clientType?: string;

  comercializado: string;

  deserialize(input: ElecsumGreenWebAPI): ElecsumGreenWeb {
    if (!input) {
      return this;
    }

    this.headerImage = input.imagenCabecera;
    this.logoImage = input.imagenLogo;
    this.comercialName = input.nombreComercial;
    this.telephone = input.telefono;
    this.email = input.email;
    this.webPage = input.paginaWeb;
    this.actualizationDate = input.fechaActualizacion;
    this.clientType = input.tipoDeCliente;
    this.comercializado = input.comercializado;
    this.production = {
      actualYear: {
        production: input.produccion.añoEnCurso.produccion,
        co2: input.produccion.añoEnCurso.co2,
        trees: input.produccion.añoEnCurso.arboles,
      },
      totalProduction: {
        production: input.produccion.totalAcumulado.produccion,
        co2: input.produccion.totalAcumulado.co2,
        trees: input.produccion.totalAcumulado.arboles,
      },
    };
    this.consumption = {
      actualYear: {
        consumption: input.consumo.añoEnCurso.consumo,
        co2: input.consumo.añoEnCurso.co2,
        trees: input.consumo.añoEnCurso.arboles,
      },
      totaConsumption: {
        consumption: input.consumo.totalAcumulado.consumo,
        co2: input.consumo.totalAcumulado.co2,
        trees: input.consumo.totalAcumulado.arboles,
      },
    };

    if (input.empresa) {
      this.company = {
        images: input.empresa.imagenes || [],
        description: input.empresa.descripcion,
        news: input.empresa.noticias.map(
          (noticia: NoticiaIAPI): NewsObjectI => ({
            headline: noticia.titular,
            date: noticia.fecha,
            description: noticia.descripcion,
            image: noticia.imagenUrl,
          }),
        ),
      };
    }

    return this;
  }
}
