import React from 'react';

export interface TableHeading {
  label: string;
  key: string;
}
export interface TableItem {
  id: string;
  [key: string]: any;
}

interface TableProps {
  headings: Array<TableHeading>;
  rows: Array<TableItem>;
}
const Table = (props: TableProps): JSX.Element => {
  const { headings, rows } = props;
  return (
    <table>
      <thead>
        <tr>
          {headings.map((heading: TableHeading) => (
            <th key={heading.key}>{heading.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((tableItem) => (
          <tr key={tableItem.id}>
            {headings.map((heading: TableHeading) => (
              <td key={heading.key}>{tableItem[heading.key]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export default Table;
