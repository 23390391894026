interface SustainabilityDataAPII {
  individual: {
    c02: number;
    arboles: number;
  };
  comunidad: {
    c02: number;
    arboles: number;
  };
  contribucionElecsum: {
    numeroDeInstalaciones: number;
    c02: number;
    arboles: number;
  };
  paqueteEnergiaNetaUE2030: {
    objetivoUnionEuropea: number;
    objetivoEspaña: number;
  };
  emisiones: {
    miContribucion: number;
    porHabitante: number;
  };
}

export default class SustainabilityObject {
  individual: {
    co2: number;
    trees: number;
  };

  comunity: {
    co2: number;
    trees: number;
  };

  elecsumContributions: {
    instalationsNumber: number;
    co2: number;
    trees: number;
  };

  parisAgreement2015: {
    UEGoal: number;
    ESGoal: number;
  };

  emisions: {
    myContribution: number;
    perInhabitant: number;
  };

  deserialize(input: SustainabilityDataAPII): SustainabilityObject {
    if (!input) {
      return this;
    }

    this.individual = {
      co2: input.individual.c02 || 0,
      trees: input.individual.arboles || 0,
    };

    this.comunity = {
      co2: input.comunidad.c02 || 0,
      trees: input.comunidad.arboles || 0,
    };

    this.elecsumContributions = {
      co2: input.contribucionElecsum.c02 || 0,
      trees: input.contribucionElecsum.arboles || 0,
      instalationsNumber: input.contribucionElecsum.numeroDeInstalaciones || 0,
    };

    this.parisAgreement2015 = {
      UEGoal: input.paqueteEnergiaNetaUE2030.objetivoUnionEuropea || 0,
      ESGoal: input.paqueteEnergiaNetaUE2030.objetivoEspaña || 0,
    };

    this.emisions = {
      myContribution: input.emisiones.miContribucion || 0,
      perInhabitant: input.emisiones.porHabitante || 0,
    };

    return this;
  }
}
