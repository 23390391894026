import React from 'react';
import styles from './Toggle.module.scss';

export interface TogglePropsI {
  value: boolean;
  onChange(item: boolean): void;
}
const Toggle = (props: TogglePropsI) => {
  const { value, onChange } = props;

  return (
    <div className={`${styles.toggle} ${value ? styles.active : ''}`} onClick={() => onChange(!value)}>
      <span className={styles.toggleText}>{value ? 'Si' : 'No'}</span>
      <div className={styles.toggleSwitch}>
        <div className={styles.togglHandle} />
      </div>
    </div>
  );
};

export default Toggle;
