import I18n from 'i18n-js';
import React from 'react';
import ActualizarTarifaAll from 'types/models/updateTarifaAll';
import styles from './tarifas-list-table.module.scss';
import { TarifasListRow } from '../tarifas-list-row/tarifas-list-row';

interface TarifasListTableProps {
  data: ActualizarTarifaAll[];
  solicitudID: string;
}

export const TarifasListTable = (props: TarifasListTableProps): JSX.Element => {
  const { data, solicitudID } = props;
  const { t } = I18n;

  return (
    <table className={styles.table_wrapper}>
      {data.length > 0 ? (
        <>
          <thead>
            <tr>
              <th>{t(`TarifasListPage.headers.name`)}</th>
              <th>{t(`TarifasListPage.headers.nif`)}</th>
              <th>{t(`TarifasListPage.headers.CUPS`)}</th>
              <th>{t(`TarifasListPage.headers.comunidad`)}</th>
              <th>{t(`TarifasListPage.headers.fecha`)}</th>
              <th aria-label="Detail" />
            </tr>
          </thead>
          <tbody>
            {data.map((solicitud) => (
              <TarifasListRow key={solicitud.id} solicitud={solicitud} solicitudID={solicitudID} />
            ))}
          </tbody>
        </>
      ) : (
        <p>{t(`TarifasListPage.headers.notResult`)}</p>
      )}
    </table>
  );
};
