import React from 'react';
import { ReactComponent as InstalacionIcon } from 'assets/icons/Installation.svg';
import { Colors } from 'styles';
import { navigate } from 'navigation';
import styles from './SearchItems.module.scss';

interface InstallationSearchItemPropsI {
  id: string;
  nombre: string;
  cups: string;
  direccion: string;
  ciudad?: string;
  provincia?: string;
  cp?: string;
  pais?: string;
}

const InstallationSearchItem = (props: InstallationSearchItemPropsI): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, nombre, cups, direccion, ciudad, provincia, cp, pais } = props;
  const handleClick = (): void => {
    navigate(`/tecnic/installation/${id}/cups`);
  };
  const getFormattedAddress = (): string =>
    `${direccion}, ${ciudad} ${provincia ? ',' : ''} ${provincia} ${cp ? ',' : ''} ${cp}`;

  return (
    <button onClick={() => handleClick()} type="button" className={styles.root}>
      <div className={styles.icon_wrapper}>
        <InstalacionIcon height={25} width={25} fill={Colors.COLOR_ORANGE_STRONG} />
      </div>
      <div className={styles.info_wrapper}>
        <p className={styles.title}>{nombre}</p>
        <p className={styles.subtitle}>{cups}</p>
        <p className={styles.information}>{getFormattedAddress()}</p>
      </div>
    </button>
  );
};

export default React.memo(InstallationSearchItem);
