import { Serializable } from 'types/interfaces';

interface ContractsDataAPII {
  nombre: string;
  url: string;
}

export default class ContractsData implements Serializable<ContractsData> {
  name: string;

  url: string;

  deserialize(input: ContractsDataAPII): ContractsData {
    if (!input) {
      return this;
    }

    this.name = input.nombre;
    this.url = input.url;

    return this;
  }

  // eslint-disable-next-line class-methods-use-this
  deserializeArray(inputArray: Array<ContractsDataAPII>): Array<ContractsData | null> {
    // eslint-disable-next-line max-len
    const contracts: Array<ContractsData | null> = inputArray.map((input) => new ContractsData().deserialize(input));

    return contracts;
  }
}
