import React, { ChangeEvent } from 'react';
import { I18n } from 'core';
import cx from 'classnames';
import styles from './SelectInput.module.scss';

export interface OptionsPropsI {
  label: string;
  value: number | string;
}

export interface SelectInterface {
  id: string;
  type: string;
  name: string;
  path: string;
  label: string;
  checked?: boolean;
  onChange?: CallableFunction;
  value?: number;
  options?: Array<OptionsPropsI>;
  showDots?: boolean;
  disabled?: boolean;
  tabindex?: number;
}
const SelectInput = (props: SelectInterface): JSX.Element => {
  const { options, value, showDots, disabled, tabindex } = props;

  const handleChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    const { path, onChange, id } = props;

    if (onChange) {
      onChange(path, e.target.value, id);
    }
  };

  const getDotColor = (): string => {
    if (value === 0) {
      return 'bck-red';
    }
    if (value === 1) {
      return 'bck-yellow';
    }
    return 'bck-green';
  };

  return (
    <div className={styles.root}>
      {showDots ? <div className={cx(styles.dot, getDotColor())} /> : null}
      <select value={value} onChange={handleChange} disabled={disabled} tabIndex={tabindex}>
        {options?.map((item) => (
          <option value={item.value} key={item.value} className={styles.option}>
            {I18n.t(item.label)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;
