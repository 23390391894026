/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { I18n } from 'core';
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryStack,
  VictoryBar,
  VictoryLabel,
  VictoryContainer,
} from 'victory';
import { Colors } from 'styles';
import wattsConverterService from 'services/local/WattsConverter';
import cx from 'classnames';
import { SizeMe } from 'react-sizeme';
import Constants from 'config/constants';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import GraficaCurvaConsumoTeledectionData from 'types/models/graficaCurvateledetectionData';
import styles from './CurvaTeledetectionChart.module.scss';

interface SelectorPropsI {
  name: string;
  currentTab: string;
  changeCurrentTab: () => void;
  value: string;
}

interface CurvaTeledetectionChartPropsI {
  teledetectionData: GraficaCurvaConsumoTeledectionData;
  dateComponent: JSX.Element;
  currentTab: string;
  setCurrentTabFunction: (tab: string) => void;
  fotovoltaica: string;
  isLoading?: boolean;
}

const NAV_YEAR = 'year';
const NAV_MONTH = 'month';
const NAV_DAY = 'day';
const NAV_YEAR_HOUR = 'yearxhour';

const Selector = React.memo((props: SelectorPropsI): JSX.Element => {
  const { name, currentTab, value, changeCurrentTab } = props;

  const toogleCurrentTab = useCallback(() => {
    changeCurrentTab();
  }, [changeCurrentTab]);

  return (
    <div>
      <button
        onClick={toogleCurrentTab}
        type="button"
        className={cx(styles.item, { [styles.active]: currentTab === value })}
      >
        {I18n.t(name)}
      </button>
    </div>
  );
});

const CurvaTeledetectionChart = ({
  teledetectionData,
  dateComponent,
  currentTab,
  setCurrentTabFunction,
  fotovoltaica,
  isLoading,
}: CurvaTeledetectionChartPropsI): JSX.Element => {
  const [numberTicks, setNumberTicks] = useState<number>(12);
  const [barWidth, setBarWidth] = useState<number | undefined>(undefined);

  const graphData = useMemo(() => {
    const totalPower = teledetectionData?.totals?.consumption ?? 0;
    const defaultYLabels = totalPower > 0 ? [] : [0];
    return {
      units: 'H',
      xLabels: currentTab === NAV_YEAR ? Array.from(Array(12).keys(), (n) => n + 1) : [],
      yLabels: defaultYLabels,
      optMagnitude: wattsConverterService.getOptimalMagnitude(totalPower),
    };
  }, [currentTab, teledetectionData]);

  useEffect(() => {
    switch (currentTab) {
      case NAV_DAY:
        setNumberTicks(12);
        setBarWidth(undefined);
        break;
      case NAV_MONTH:
        setNumberTicks(10);
        setBarWidth(undefined);
        break;
      case NAV_YEAR:
        setNumberTicks(12);
        setBarWidth(undefined);
        break;
      case NAV_YEAR_HOUR:
        setNumberTicks(12);
        setBarWidth(undefined);
        break;
      default:
        setNumberTicks(teledetectionData?.consumptionData?.length / 2 + 1 || 12);
        setBarWidth(20);
        break;
    }
  }, [currentTab, teledetectionData]);

  const getTickLabel = useCallback((): string => {
    if (currentTab === NAV_DAY) return 'H';
    if (currentTab === NAV_MONTH) return '';
    return '';
  }, [currentTab]);
  if (isLoading) {
    return <FullPageSyncLoader icon />;
  }
  return (
    <>
      {teledetectionData?.consumptionData ? (
        <>
          <div className={styles['pick-container']}>
            <div className={styles.scale}>
              <Selector
                changeCurrentTab={(): void => setCurrentTabFunction(NAV_DAY)}
                name="day"
                value={NAV_DAY}
                currentTab={currentTab}
              />
              <Selector
                changeCurrentTab={(): void => setCurrentTabFunction(NAV_MONTH)}
                name="month"
                value={NAV_MONTH}
                currentTab={currentTab}
              />
              <Selector
                changeCurrentTab={(): void => setCurrentTabFunction(NAV_YEAR)}
                name="year"
                value={NAV_YEAR}
                currentTab={currentTab}
              />
              <Selector
                changeCurrentTab={(): void => setCurrentTabFunction(NAV_YEAR_HOUR)}
                name="yearsperhour"
                value={NAV_YEAR_HOUR}
                currentTab={currentTab}
              />
            </div>
            <div className={styles['date-component']}>{dateComponent}</div>
          </div>
          <SizeMe>
            {({ size }) => (
              <div>
                {!isLoading ? (
                  <svg viewBox={`0 0 ${size.width} 400`} height="100%" width="100%">
                    {currentTab === NAV_YEAR_HOUR ? (
                      <VictoryChart
                        width={size.width || 500}
                        height={400}
                        standalone={false}
                        domainPadding={{ y: 10 }}
                        containerComponent={<VictoryContainer responsive={false} />}
                      >
                        <VictoryLabel
                          style={{ fontSize: Constants.charts.AXIS_LABELS_FONTSIZE }}
                          text="kWh"
                          x={30}
                          y={30}
                          textAnchor="middle"
                        />
                        <VictoryAxis
                          style={{
                            axis: { stroke: 'transparent' },
                            tickLabels: {
                              fontSize: Constants.charts.AXIS_VALUES_FONTSIZE,
                              fill: Colors.COLOR_CONSUM_NOT_SELECTED,
                            },
                          }}
                          tickCount={12}
                          tickFormat={(t): string => {
                            const datePart = t.split(' ')[0]; // Obtiene "2024/12/30"
                            const [, month] = datePart.split('/'); // Extrae el mes "01", "02", etc.

                            return `${month}/01`; // Muestra el primer día  mes
                          }}
                          offsetY={45}
                        />
                        <VictoryAxis
                          dependentAxis
                          style={{
                            axis: { stroke: 'transparent' },
                            tickLabels: {
                              fontSize: Constants.charts.AXIS_VALUES_FONTSIZE,
                              fill: Colors.COLOR_CONSUM_NOT_SELECTED,
                            },
                          }}
                          tickCount={12}
                          tickFormat={(t): string => t.toString()}
                          offsetX={45}
                        />

                        <VictoryLine
                          interpolation="natural"
                          style={{
                            data: { stroke: Colors.COLOR_GENERACIO, strokeWidth: 1 },
                          }}
                          data={
                            teledetectionData?.consumptionData && teledetectionData.consumptionData.length > 0
                              ? (() => {
                                  // Definir el tipo para el acumulador (las claves son strings, los valores son arrays de objetos con 'x' y 'y')
                                  const groupedByDate = teledetectionData.consumptionData.reduce<{
                                    [key: string]: typeof teledetectionData.consumptionData;
                                  }>((acc, dataPoint) => {
                                    const datePart = dataPoint.x.split(' ')[0]; // Extraer la parte de la fecha (YYYY/MM/DD)

                                    // Verificar que el formato de la fecha sea válido (simplemente una precaución básica)
                                    if (!datePart || isNaN(new Date(datePart).getTime())) {
                                      console.warn(`Invalid date format encountered: ${dataPoint.x}`);
                                      return acc; // Saltar si la fecha es inválida
                                    }

                                    if (!acc[datePart]) {
                                      acc[datePart] = []; // Inicializar un array si no existe para esa fecha
                                    }

                                    acc[datePart].push(dataPoint); // Agregar el punto de datos al grupo correspondiente
                                    return acc;
                                  }, {});

                                  // Calcular la media para cada fecha
                                  return Object.entries(groupedByDate).map(([date, dataPoints]) => {
                                    // Verificar si hay puntos de datos antes de calcular la media
                                    if (dataPoints.length === 0) {
                                      console.warn(`No data points available for date: ${date}`);
                                      return { x: date, y: 0 }; // Devolver 0 si no hay datos
                                    }

                                    const totalConsumption = dataPoints.reduce(
                                      (sum, dataPoint) => sum + dataPoint.y,
                                      0,
                                    ); // Sumar todos los valores 'y'
                                    const averageConsumption = totalConsumption / dataPoints.length; // Calcular la media

                                    // Devolver un nuevo objeto con la fecha y la media de consumo
                                    return { x: date, y: averageConsumption };
                                  });
                                })()
                              : []
                          }
                        />
                      </VictoryChart>
                    ) : (
                      <VictoryChart
                        width={size.width || 500}
                        height={400}
                        standalone={false}
                        domainPadding={{ y: 10 }}
                        containerComponent={<VictoryContainer responsive={false} />}
                      >
                        <VictoryLabel
                          style={{ fontSize: Constants.charts.AXIS_LABELS_FONTSIZE }}
                          text={graphData.optMagnitude}
                          x={20}
                          y={30}
                          textAnchor="middle"
                        />
                        <VictoryAxis
                          style={{
                            axis: { stroke: 'transparent' },
                            tickLabels: {
                              fontSize: Constants.charts.AXIS_VALUES_FONTSIZE,
                              fill: Colors.COLOR_GRAY_500,
                            },
                          }}
                          tickLabelComponent={<VictoryLabel dx={10} />}
                          tickValues={graphData.xLabels}
                          tickCount={numberTicks}
                          tickFormat={(t): string => `${Math.round(t).toString()}${getTickLabel()}`}
                          offsetY={45}
                        />
                        <VictoryAxis
                          dependentAxis
                          style={{
                            axis: { stroke: 'transparent' },
                            tickLabels: {
                              fontSize: Constants.charts.AXIS_VALUES_FONTSIZE,
                              fill: Colors.COLOR_GRAY_500,
                            },
                          }}
                          offsetX={45}
                          tickValues={graphData.yLabels}
                          tickCount={6}
                        />
                        {teledetectionData.consumptionData.length !== 0 ? (
                          <VictoryStack
                            colorScale={
                              fotovoltaica === 'si'
                                ? [Colors.COLOR_EXCEDENTS, Colors.COLOR_ELECSUMGREEN, Colors.COLOR_GENERACIO]
                                : [Colors.COLOR_GENERACIO]
                            }
                          >
                            <VictoryBar
                              alignment="start"
                              barWidth={barWidth}
                              barRatio={Constants.charts.BAR_RATIO_FILL}
                              cornerRadius={{ top: Constants.charts.BAR_CORNER_RADIUS }}
                              data={teledetectionData.consumptionData}
                            />
                          </VictoryStack>
                        ) : null}
                      </VictoryChart>
                    )}
                  </svg>
                ) : (
                  <FullPageSyncLoader icon />
                )}
              </div>
            )}
          </SizeMe>
        </>
      ) : null}
    </>
  );
};

export default React.memo(CurvaTeledetectionChart);
