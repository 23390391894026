import React, { useState, useEffect } from 'react';
import { I18n } from 'core';
import { TeledetectionData } from 'types/models';
import teledetectionService from 'services/remote/TeledetectionService';
import { TeledetectionChart } from 'components';
import { toast } from 'react-toastify';
import wattsConverterService from 'services/local/WattsConverter';
import { ReactComponent as NetworkIcon } from 'assets/icons/Network.svg';
import { ReactComponent as GenerationIcon } from 'assets/icons/Generation.svg';
import { ReactComponent as ShrinkIcon } from 'assets/icons/Shrink.svg';
import { ReactComponent as SelfConsumptionIcon } from 'assets/icons/SelfConsumption.svg';
import cx from 'classnames';

import { clientServiceInstance, cupsServiceInstance, headerServiceInstance } from 'services';
import { Subscription } from 'rxjs';
import { Colors } from 'styles';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import { DayPicker, MonthPicker, YearPicker } from 'components/Input';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalStoreState } from 'types/state';
import { navigate } from 'navigation';
import {
  fetchClientCUPSListSuccessAdmin,
  fetchCUPSTestSuccess,
  updateClientCUPSadmin,
} from 'redux/actions/ClientCupsActions';

import styles from './ChartsPage.module.scss';

interface DataItemPropsI {
  icon: JSX.Element;
  name: string;
  data: number;
}

const DataItem = (props: DataItemPropsI): JSX.Element => {
  const { icon, name, data } = props;
  return (
    <div className={cx(styles.DataItem)}>
      <div className={styles.type}>
        {icon}
        <p className={styles.name}>{I18n.t(name)}</p>
      </div>
      <div className={styles.value}>
        <p className={styles.data}>{wattsConverterService.convertWatts(data).num}</p>
        <p className={styles.unit}>{wattsConverterService.convertWatts(data).unitHour}</p>
      </div>
    </div>
  );
};

const ChartsPageAdmin = (props: any): JSX.Element => {
  // Props
  const {
    match: {
      params: { cupsID },
    },
  } = props;

  const dispatch = useDispatch();

  const NAV_YEAR = 'year';
  const NAV_MONTH = 'month';
  const NAV_DAY = 'day';

  const ICON_SIZE = '25px';
  const getInitalDate = (): Date => {
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(yesterday.getDate() - 1);

    return yesterday;
  };

  const [client, setClient] = useState(clientServiceInstance.client);
  const cupsSelected = useSelector((state: GlobalStoreState) => state.clientCUPS.selectedCups);

  const [teledetectionData, setTeledetectionData] = useState(new TeledetectionData());
  const [date, setDate] = useState({
    day: getInitalDate().getDate(),
    month: getInitalDate().getMonth() + 1,
    year: getInitalDate().getFullYear(),
  });
  const [currentTab, setCurrentTab] = useState(NAV_DAY);
  const [dataIsLoad, setDataIsLoad] = useState(false);
  const [loadingDetailData, setLoadingDetailData] = useState(false);
  useEffect(() => {
    clientServiceInstance.getClient(cupsID).then((response) => {
      response.setAttr('cups', cupsID);
      dispatch(fetchCUPSTestSuccess(response));
      if (response?.nif !== '') {
        cupsServiceInstance.getCupsFromUserId(response?.nif).then((test) => {
          if (test.length > 0) {
            const [firstCups] = test;
            if (!cupsID) {
              navigate(`/tecnic/cups/${cupsID}/graficos`);
            }
            const cupsById = test.find((item) => item.cups === cupsID);
            dispatch(updateClientCUPSadmin(cupsById || firstCups));
            dispatch(fetchClientCUPSListSuccessAdmin(test));
          }
        });
      } else {
        setDataIsLoad(true);
      }
    });
  }, [cupsID]);
  // Detectar cambio de CUPS seleccionado para redirigir a URL correcta
  // useEffect(() => {
  //
  //   if (!adminCups?.nif) return;
  //   if (cupsSelected) {
  //     cupsServiceInstance.getCupsFromUserId(adminCups?.nif).then((response) => {
  //       if (response.length > 0) {
  //         const [firstCups] = response;
  //         if (!cupsID) {
  //           navigate(`/tecnic/cups/${cupsID}/graficos`);
  //         }
  //         const cupsById = response.find((item) => item.cups === cupsID);
  //         dispatch(updateClientCUPSadmin(cupsById || firstCups));
  //         dispatch(fetchClientCUPSListSuccessAdmin(response));
  //       }
  //     });
  //   }
  // }, [cupsID]);

  useEffect(() => {
    if (cupsSelected) {
      if (cupsSelected?.cups !== cupsID) {
        navigate(`/tecnic/cups/${cupsID}/graficos`);
      }
    }
  }, [cupsSelected]);

  useEffect(() => {
    const subscription: Subscription = clientServiceInstance.client$.subscribe(setClient);
    headerServiceInstance.props.hideCommunity = true;

    return (): void => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, []);

  // const fetchLastDateWithData = async () => {
  //   try {
  //     const yearData = await teledetectionService.getTeledetectionData('year', cupsID, date.year);

  //     let lastMonthWithData = 0;
  //     yearData.generationData.forEach((data) => {
  //       if (data.y !== 0) {
  //         lastMonthWithData = data.x;
  //       }
  //     });

  //     console.log('lastMonthWithData', yearData);

  //     const monthData = await teledetectionService.getTeledetectionData('month', cupsID, date.year, lastMonthWithData);

  //     let lastDayWithData = 0;
  //     monthData.generationData.forEach((data) => {
  //       if (data.y !== 0) {
  //         lastDayWithData = data.x;
  //       }
  //     });

  //     console.log('lastDayWithData', lastDayWithData);
  //     setDate({
  //       day: lastDayWithData,
  //       month: lastMonthWithData,
  //       year: date.year,
  //     });
  //   } catch (error) {
  //     toast.error('errorGettingTeledetectionData');
  //   }
  // };

  // useEffect(() => {
  //   if (cupsID) {
  //     console.log('cupsID', cupsID); teledetectionService.getFechaUltimosDatos(cupsID).then((response: any)
  //     // => {
  //     //   const { data } = response;
  //     //   const { datos } = data;
  //     //   const { fecha } = datos;
  //     //   const newDate = new Date(fecha);
  //     //   setDate({
  //     //     day: newDate.getDate(),
  //     //     month: newDate.getMonth() + 1,
  //     //     year: newDate.getFullYear(),
  //     //   });
  //     // });
  //     fetchLastDateWithData();
  //   }
  // }, [cupsID]);

  //
  // Función que encuentra el último dato no nulo en un array de datos
  const findLastNonZeroData = (data: { y: number; x: number }[]): number =>
    // Reduce el array de datos para encontrar el último valor 'x' cuyo 'y' es diferente de 0
    data.reduce((lastNonZero, current) => (current.y !== 0 ? Math.max(lastNonZero, current.x) : lastNonZero), 0);

  // Función asincrónica para obtener la última fecha con datos
  const fetchLastDateWithData = async () => {
    try {
      // Llamada al servicio de teledetección para obtener los datos del año
      const yearData = await teledetectionService.getTeledetectionData('year', cupsID, date.year);

      // Encuentra el último mes con datos no nulos combinando los resultados de varias series de datos
      const lastMonthWithData = Math.max(
        findLastNonZeroData(yearData.generationData),
        findLastNonZeroData(yearData.selfConsumptionData),
        findLastNonZeroData(yearData.shirnkData),
        findLastNonZeroData(yearData.networkonData),
      );

      // Llamada al servicio de teledetección para obtener los datos del mes identificado
      const monthData = await teledetectionService.getTeledetectionData('month', cupsID, date.year, lastMonthWithData);

      // Encuentra el último día con datos no nulos combinando los resultados de varias series de datos
      const lastDayWithData = Math.max(
        findLastNonZeroData(monthData.generationData),
        findLastNonZeroData(monthData.selfConsumptionData),
        findLastNonZeroData(monthData.shirnkData),
        findLastNonZeroData(monthData.networkonData),
      );

      // Actualiza el estado de la fecha con los últimos valores de mes y día encontrados
      setDate({
        day: lastDayWithData,
        month: lastMonthWithData,
        year: date.year,
      });
    } catch (error) {
      // Muestra un mensaje de error en caso de que falle la obtención de datos
      toast.error('Error getting teledetection data');
    }
  };

  // Ejecutamos la función fetchLastDateWithData cuando cambie el cupsID.
  useEffect(() => {
    fetchLastDateWithData();
  }, [cupsID]);

  const updateDate = (newDate: Date): void => {
    if (!newDate) {
      return;
    }
    setDate({
      day: newDate.getDate(),
      month: newDate.getMonth() + 1,
      year: newDate.getFullYear(),
    });
  };

  const getDateComponent = (): JSX.Element => {
    // const handleDayColor = (day: Date): string => (day.getMonth() + 1 === date.month
    // && day.getDate() === date.day ? 'selected-day' : '');

    if (currentTab === NAV_DAY) {
      return (
        <>
          <DayPicker
            date={
              Number.isNaN(date.year)
                ? new Date(
                    `${getInitalDate().getMonth() + 1}/${getInitalDate().getDate()}/${getInitalDate().getFullYear()}`,
                  )
                : new Date(`${date.month}/${date.day}/${date.year}`)
            }
            onChangeDate={updateDate}
          />
        </>
      );
    }
    if (currentTab === NAV_MONTH) {
      return (
        <>
          <MonthPicker
            anchorSelector="right"
            value={
              Number.isNaN(date.year)
                ? new Date(
                    `${getInitalDate().getMonth() + 1}/${getInitalDate().getDate()}/${getInitalDate().getFullYear()}`,
                  )
                : new Date(`${date.month}/${date.day}/${date.year}`)
            }
            onChange={updateDate}
          />
        </>
      );
    }
    if (currentTab === NAV_YEAR) {
      return (
        <>
          <YearPicker
            anchorSelector="right"
            value={
              Number.isNaN(date.year)
                ? new Date(
                    `${getInitalDate().getMonth() + 1}/${getInitalDate().getDate()}/${getInitalDate().getFullYear()}`,
                  )
                : new Date(`${date.month}/${date.day}/${date.year}`)
            }
            onChange={updateDate}
          />
        </>
      );
    }
    return <></>;
  };

  useEffect((): void => {
    const fetchData = async (): Promise<void> => {
      setLoadingDetailData(true);
      try {
        if (!cupsSelected) {
          return;
        }
        const teledetectionObj: TeledetectionData = await teledetectionService.getTeledetectionData(
          currentTab,
          cupsSelected.cups,
          date.year,
          date.month,
          date.day,
        );
        if (teledetectionObj.generationData !== undefined) {
          setTeledetectionData(teledetectionObj);
        }

        setDataIsLoad(true);
        setLoadingDetailData(false);
      } catch (error) {
        toast.error('errorGettingTeledetectionData');
      }
    };

    fetchData();
  }, [client, currentTab, date, cupsSelected]);

  // useEffect((): void => {
  //   const fetchData = async (): Promise<void> => {
  //     setLoadingDetailData(true);
  //     try {
  //       if (!adminCups2) {
  //         return;
  //       }
  //       const teledetectionObj: TeledetectionData = await teledetectionService.getTeledetectionData(
  //         currentTab,
  //         adminCups2.cups,
  //         date.year,
  //         date.month,
  //         date.day,
  //       );
  //       setTeledetectionData(teledetectionObj);
  //       setDataIsLoad(true);
  //       setLoadingDetailData(false);
  //     } catch (error) {
  //       toast.error('errorGettingTeledetectionData');
  //     }
  //   };

  //   fetchData();
  // }, [client, currentTab, date, adminCups2]);
  if (!dataIsLoad) {
    return <FullPageSyncLoader icon />;
  }

  return (
    <div className={styles.root}>
      {cupsSelected !== null ? (
        <div className={styles.chartWrapper}>
          <TeledetectionChart
            isLoading={loadingDetailData}
            fotovoltaica={cupsSelected?.fotovoltaica || 'no'}
            teledetectionData={teledetectionData}
            dateComponent={getDateComponent()}
            currentTab={currentTab}
            setCurrentTabFunction={setCurrentTab}
          />
          {!loadingDetailData ? (
            <div className={styles.grid_container}>
              {cupsSelected?.fotovoltaica === 'si' && (
                <>
                  <div className={styles.item}>
                    {teledetectionData.generationData !== undefined ? (
                      <DataItem
                        icon={<GenerationIcon fill={Colors.COLOR_GENERACIO} width={ICON_SIZE} height={ICON_SIZE} />}
                        name="generation"
                        data={teledetectionData.totals.generation}
                      />
                    ) : null}
                  </div>
                  <div className={styles.item}>
                    {teledetectionData.shirnkData !== undefined ? (
                      <DataItem
                        icon={<ShrinkIcon fill={Colors.COLOR_BLUE_200} width={ICON_SIZE} height={ICON_SIZE} />}
                        name="shrinking"
                        data={teledetectionData.totals.shrink}
                      />
                    ) : null}
                  </div>
                  <div className={styles.item}>
                    {teledetectionData.selfConsumptionData !== undefined ? (
                      <DataItem
                        icon={
                          <SelfConsumptionIcon fill={Colors.COLOR_ELECSUMGREEN} width={ICON_SIZE} height={ICON_SIZE} />
                        }
                        name="selfConsumption"
                        data={teledetectionData.totals.selfconsumption}
                      />
                    ) : null}
                  </div>
                </>
              )}
              <div
                className={cx(styles.item, {
                  [styles.expand]: cupsSelected?.fotovoltaica === 'no',
                })}
              >
                {teledetectionData.networkonData !== undefined && teledetectionData.totals !== undefined ? (
                  <DataItem
                    icon={<NetworkIcon fill={Colors.COLOR_GRAY_500} width={ICON_SIZE} height={ICON_SIZE} />}
                    name="network"
                    data={teledetectionData.totals.network}
                  />
                ) : null}
              </div>
            </div>
          ) : (
            <div style={{ height: 140 }} />
          )}
        </div>
      ) : (
        <div style={{ height: 140 }}>{I18n.t(`DashboardPage.nodata_grafic`)}</div>
      )}
    </div>
  );
};
export default ChartsPageAdmin;
