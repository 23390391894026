import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { Colors } from 'styles';
import { ReactComponent as UserIcon } from 'assets/icons/User.svg';
import { ReactComponent as CupsIcon } from 'assets/icons/SmartMeter.svg';
import { ReactComponent as InstallationIcon } from 'assets/icons/Installation.svg';
import { ReactComponent as CommunityIcon } from 'assets/icons/community_share.svg';
import cx from 'classnames';
import { useOutsideClick } from 'hooks';
import { I18n } from 'core';
import searchServiceInstance, { SearchItemI } from 'services/remote/SearchService';
import Spinner from 'components/Spinner';
import { authServiceInstance } from 'services';
import WidgetPermission from 'types/enums/WidgetPermission';
import { User } from 'types/models';
import styles from './HeaderSearchInput.module.scss';
import { UserSearchItem, InstallationSearchItem, CupsSearchItem, CommunitySearch } from './SearchItems';

interface FilterI {
  id: string;
  label: string;
  logo: JSX.Element;
  activeIcon: string;
  inactiveIcon: string;
  activeBackground: string;
  inactiveBackground: string;
  activeLabel: string;
  inactiveLabel: string;
}

const FILTER_ICON_SIZE = 18;

const FILTERS: Array<FilterI> = [
  {
    id: 'instalacion',
    label: I18n.t('HeaderSearchInput.filterInstallation'),
    activeIcon: Colors.COLOR_WHITE,
    inactiveIcon: Colors.COLOR_ORANGE_STRONG,
    activeBackground: Colors.COLOR_ORANGE_STRONG,
    inactiveBackground: Colors.COLOR_GRAY_000,
    activeLabel: Colors.COLOR_WHITE,
    inactiveLabel: Colors.COLOR_BLACK,
    logo: <InstallationIcon height={FILTER_ICON_SIZE} width={FILTER_ICON_SIZE} />,
  },
  {
    id: 'cups',
    label: I18n.t('HeaderSearchInput.filterCUPS'),
    activeIcon: Colors.COLOR_WHITE,
    inactiveIcon: Colors.COLOR_ELECSUMGREEN,
    activeBackground: Colors.COLOR_ELECSUMGREEN,
    inactiveBackground: Colors.COLOR_GRAY_000,
    activeLabel: Colors.COLOR_WHITE,
    inactiveLabel: Colors.COLOR_BLACK,
    logo: <CupsIcon height={FILTER_ICON_SIZE} width={FILTER_ICON_SIZE} />,
  },
  {
    id: 'usuario',
    label: I18n.t('HeaderSearchInput.filterUser'),
    activeIcon: Colors.COLOR_WHITE,
    inactiveIcon: Colors.COLOR_BLUE_150,
    activeBackground: Colors.COLOR_BLUE_150,
    inactiveBackground: Colors.COLOR_GRAY_000,
    activeLabel: Colors.COLOR_WHITE,
    inactiveLabel: Colors.COLOR_BLACK,
    logo: <UserIcon height={FILTER_ICON_SIZE} width={FILTER_ICON_SIZE} />,
  },
  {
    id: 'comunidad',
    label: I18n.t('HeaderSearchInput.filterCommunity'),
    activeIcon: Colors.COLOR_WHITE,
    inactiveIcon: Colors.COLOR_NOTIFICATIONS,
    activeBackground: Colors.COLOR_NOTIFICATIONS,
    inactiveBackground: Colors.COLOR_GRAY_000,
    activeLabel: Colors.COLOR_WHITE,
    inactiveLabel: Colors.COLOR_BLACK,
    logo: <CommunityIcon height={FILTER_ICON_SIZE} width={FILTER_ICON_SIZE} />,
  },
];

const HeaderSearchInput = (): JSX.Element => {
  // Constants
  const ICON_SIZE = 18;
  // References
  const searchInputRef = useRef(null);
  // State
  const [filterSelected, setFilterSelected] = useState<FilterI | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [inputText, setInputText] = useState('');
  const [results, setResults] = useState<Array<SearchItemI>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [permisos] = useState<string[]>(authServiceInstance.permisos);
  const [user] = useState<User | null>(authServiceInstance.user);

  // Effects
  useOutsideClick(searchInputRef, () => {
    setShow(false);
  });

  useEffect(() => {
    setShow(inputText.length > 2);
  }, [inputText]);

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      if (show) {
        setShow(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress, true);
    return () => {
      document.removeEventListener('keydown', handleKeyPress, true);
    };
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (inputText.length > 2) {
        setLoading(true);
        searchServiceInstance.searchItems(inputText).then((response) => {
          setResults(response);
          setLoading(false);
        });
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [inputText]);

  // Handlers
  const handleInputChange = (evt: any) => {
    setInputText(evt.target.value);
  };

  const handleFilterSelected = (filt: FilterI) => {
    if (filterSelected?.id === filt.id) {
      setFilterSelected(null);
    } else {
      setFilterSelected(filt);
    }
  };

  const handleInputFocus = () => {
    if (inputText.length > 3) {
      setShow(true);
    }
  };

  const isAdmin = (userProp: User | null) => userProp && userProp.tipoDeUsuario === 'admin';

  const filteredFilters = FILTERS.filter((filter) => {
    // Define your logic here to map user roles to filters
    switch (filter.id) {
      case 'instalacion':
        // Check if user has permission for instalacion or is admin
        return (permisos && permisos.includes(WidgetPermission.ACCESO_WIDGET_INSTALACIONES)) || isAdmin(user);
      case 'cups':
        // Check if user has permission for cups or is admin
        return (permisos && permisos.includes(WidgetPermission.ACCESO_WIDGET_CUPS)) || isAdmin(user);
      case 'usuario':
        // Check if user has permission for usuario or is admin
        return (permisos && permisos.includes(WidgetPermission.ACCESO_WIDGET_USUARIOS)) || isAdmin(user);
      case 'comunidad':
        // Check if user has permission for comunidad or is admin
        return (permisos && permisos.includes(WidgetPermission.ACCESO_WIDGET_COMUNIDADES)) || isAdmin(user);
      default:
        return false; // Exclude filter by default if no role mapping found
    }
  });
  // acceso_widget_instalaciones ,acceso_widget_cups,acceso_widget_usuarios,acceso_widget_comunidades

  return (
    <div ref={searchInputRef} className={styles.root}>
      <div className={styles.header}>
        <SearchIcon height={ICON_SIZE} width={ICON_SIZE} fill={Colors.COLOR_GRAY_500} />
        <input
          onFocus={() => handleInputFocus()}
          value={inputText}
          placeholder={I18n.t('HeaderSearchInput.inputPlaceholder')}
          onChange={handleInputChange}
          className={styles.input}
          type="text"
        />
      </div>
      <div className={cx(styles.dropdown_wrapper, { [styles.show]: show })}>
        <div className={styles.dropdown_filters}>
          {filteredFilters.map((filter) => (
            <button
              key={filter.id}
              type="button"
              onClick={() => handleFilterSelected(filter)}
              className={cx(styles.filter, {
                [styles.selected]: filter.id === filterSelected?.id,
              })}
              style={{
                backgroundColor: filter.id === filterSelected?.id ? filter.activeBackground : filter.inactiveBackground,
              }}
            >
              {React.cloneElement(filter.logo, {
                fill: filter.id === filterSelected?.id ? filter.activeIcon : filter.inactiveIcon,
              })}
              <span
                style={{
                  color: filter.id === filterSelected?.id ? filter.activeLabel : filter.inactiveLabel,
                }}
              >
                {filter.label}
              </span>
            </button>
          ))}
        </div>
        <div className={styles.dropdown_items}>
          {loading ? (
            <div className={styles.loading}>
              <Spinner size={100} icon />
            </div>
          ) : (
            results
              .filter((item) => {
                if (filterSelected === null) return true;
                return item.tipo === filterSelected.id;
              })
              .map((item) => {
                switch (item.tipo) {
                  case 'instalacion':
                    return (
                      (permisos && permisos.includes(WidgetPermission.ACCESO_WIDGET_INSTALACIONES)) ||
                      (isAdmin(user) && (
                        <InstallationSearchItem
                          id={item.instalacionID.toString()}
                          nombre={item.nombre}
                          cups={item.cau}
                          direccion={item.domicilio}
                          ciudad={item.poblacion}
                          provincia={item.provincia}
                          cp={item.codigoPostal}
                        />
                      ))
                    );
                  case 'cups':
                    return (
                      (permisos && permisos.includes(WidgetPermission.ACCESO_WIDGET_CUPS)) ||
                      (isAdmin(user) && (
                        <CupsSearchItem
                          nombre={item.nombre}
                          cups={item.cups}
                          direccion={item.domicilio}
                          ciudad={item.poblacion}
                          provincia={item.provincia}
                          cp={item.codigoPostal}
                        />
                      ))
                    );

                  case 'usuario':
                    return (
                      (permisos && permisos.includes(WidgetPermission.ACCESO_WIDGET_USUARIOS)) ||
                      (isAdmin(user) && (
                        <UserSearchItem email={item.email} nombre={item.nombre} identificador={item.nif} />
                      ))
                    );
                  case 'comunidad':
                    return (
                      (permisos && permisos.includes(WidgetPermission.ACCESO_WIDGET_COMUNIDADES)) ||
                      (isAdmin(user) && (
                        <CommunitySearch id={item.comunidadID} nombre={item.nombre} domicilio={item.domicilio} />
                      ))
                    );
                  default:
                    return null;
                }
              })
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(HeaderSearchInput);
