import React from 'react';

import PercentageFormatter from 'services/local/PercentageFormatter';
import styles from '../../AddMatcherPage.module.scss';

interface ListaCombinacionesRowProps {
  lista: any;
}

const ListaCombinacionesRow = ({ lista }: ListaCombinacionesRowProps): JSX.Element => (
  <tr key={lista.cups} style={{ textAlign: 'center' }}>
    <td>{lista.numeroMiembros}</td>
    <td>{lista.autoconsumoEuro ? lista?.autoconsumoEuro.toFixed(2) : 0} €</td>
    <td>{lista.excedenteEuro ? lista?.excedenteEuro.toFixed(2) : 0} €</td>
    <td>{lista.ahorrosEuro ? lista?.ahorrosEuro.toFixed(2) : 0} €</td>

    <td> {PercentageFormatter.formatValue(lista.autoconsumoPorcentaje)}%</td>
    <td>{PercentageFormatter.formatValue(lista.excedentePorcentaje)}%</td>
    <td className={styles.checkboxTable}>
      <input
        id="seleccionado"
        type="checkbox"
        name="seleccionado"
        disabled
        defaultChecked={lista.esLaOptima === true}
      />
    </td>
  </tr>
);

export default ListaCombinacionesRow;
