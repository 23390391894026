import React from 'react';
import { I18n } from 'core';

import cx from 'classnames';
import { VictoryAxis, VictoryPie, VictoryChart } from 'victory';
import { Colors } from 'styles';
import wattsConverterService from 'services/local/WattsConverter';
import styles from './EnergyWidget.module.scss';
import Card from '../../Card/Card';

interface ListItemsPropsI {
  color: string;
  name: string;
  data: string;
  unit: string;
}

interface EnergyCardPropsI {
  date: string;
  generationData: number;
  shrinkData: number;
  networkData: number;
  selfConsumptionData: number;
  consumptionData: number;
  fotovoltaic: string;
}

const ListItem = (props: ListItemsPropsI): JSX.Element => {
  const { color, name, data, unit } = props;

  return (
    <div className={styles.list_item}>
      <div className={styles.type_container}>
        <div className={cx(styles.dot, color)} />
        <p className={styles.text}>{name}</p>
      </div>
      <div className={styles.value_container}>
        <p className={styles.data}>{data}</p>
        <p className={styles.unit}>{unit}</p>
      </div>
    </div>
  );
};

const EnergyWidget = (props: EnergyCardPropsI): JSX.Element => {
  const { date, generationData, shrinkData, networkData, selfConsumptionData, consumptionData, fotovoltaic } = props;

  const bigestRadius = 150;

  const listItemsArray = [
    { color: 'bck-orange', name: 'generation', data: generationData },
    { color: 'bck-green', name: 'selfConsumption', data: generationData === 0 ? -1 : selfConsumptionData },
    { color: 'bck-blue', name: 'shrinking', data: shrinkData },
    { color: 'bck-grey', name: 'network', data: networkData },
    { color: 'bck-dark-grey', name: 'consumption', data: consumptionData },
  ];
  const listItemsArrayNoFV = [{ color: 'bck-grey', name: 'network', data: networkData }];

  const getMonth = (): string => {
    const monthArray = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const month = new Date(date).getMonth();
    const monthTranslated = I18n.t(monthArray[month]);
    return monthTranslated;
  };
  return (
    <Card
      className={styles.left_widget}
      title={I18n.t('energy')}
      subtitle={getMonth().toUpperCase()}
      body={[
        <div key={1} className={styles.root}>
          {fotovoltaic === 'si' && (
            <VictoryChart height={190}>
              <VictoryAxis
                style={{
                  axis: { stroke: 'transparent' },
                  ticks: { stroke: 'transparent' },
                  tickLabels: { fill: 'transparent' },
                }}
              />
              <VictoryAxis
                style={{
                  axis: { stroke: 'transparent' },
                  ticks: { stroke: 'transparent' },
                  tickLabels: { fill: 'transparent' },
                }}
              />
              <VictoryPie
                innerRadius={bigestRadius}
                radius={bigestRadius + 15}
                startAngle={90}
                endAngle={-90}
                labels={(): string => ''}
                colorScale={[Colors.COLOR_WHITE, Colors.COLOR_GENERACIO]}
                data={[
                  { x: 1, y: networkData },
                  { x: 2, y: generationData },
                ]}
                groupComponent={<g transform="translate(0 85)" />}
              />
              <VictoryPie
                innerRadius={bigestRadius - 30}
                radius={bigestRadius - 15}
                startAngle={90}
                endAngle={-90}
                labels={(): string => ''}
                colorScale={[Colors.COLOR_GRAY_100, Colors.COLOR_ELECSUMGREEN, Colors.COLOR_EXCEDENTS]}
                data={[
                  { x: 1, y: networkData },
                  { x: 2, y: selfConsumptionData },
                  { x: 3, y: shrinkData },
                ]}
                groupComponent={<g transform="translate(0 85)" />}
              />
              <VictoryPie
                innerRadius={bigestRadius - 60}
                radius={bigestRadius - 45}
                startAngle={90}
                endAngle={-90}
                labels={(): string => ''}
                colorScale={[Colors.COLOR_GRAY_500, Colors.COLOR_WHITE]}
                data={[
                  { x: 2, y: consumptionData },
                  { x: 1, y: shrinkData },
                ]}
                groupComponent={<g transform="translate(0 85)" />}
              />
            </VictoryChart>
          )}
          {fotovoltaic === 'si' ? (
            <div className={styles.list_item_container}>
              {listItemsArray.map(
                (item): JSX.Element => (
                  <ListItem
                    key={item.name}
                    color={item.color}
                    name={I18n.t(item.name)}
                    data={item.data >= 0 ? `${wattsConverterService.toSelectedMagnitudeString(item.data, 'kWh')}` : ''}
                    unit={item.data >= 0 ? 'kWh' : I18n.t('noData')}
                  />
                ),
              )}
            </div>
          ) : (
            <div className={styles.list_item_container}>
              {listItemsArrayNoFV.map(
                (item): JSX.Element => (
                  <ListItem
                    key={item.name}
                    color={item.color}
                    name={I18n.t(item.name)}
                    data={`${wattsConverterService.toSelectedMagnitudeString(item.data, 'kWh')}`}
                    unit="kWh"
                  />
                ),
              )}
            </div>
          )}
        </div>,
      ]}
    />
  );
};

export default React.memo(EnergyWidget);
