import { Tag } from 'components';
import { RoundedButton } from 'components/NewInputs';
import I18n from 'i18n-js';
import { navigate } from 'navigation';
import React from 'react';
import { Colors } from 'styles';
// import { InscritosModel } from 'types/models/inscritos';
import styles from './community-enrolled-list-row.module.scss';

interface CommunityListRowProps {
  row: any;
  communityID: string;
}

const ColorTags: any = {
  pendiente: Colors.COLOR_ELECSUMGREEN,
  Colors: Colors.COLOR_ORANGE_STRONG,
};

export const CommunityEnrolledListRow = (props: CommunityListRowProps): JSX.Element => {
  let url: string;
  const { row, communityID } = props;
  const { t } = I18n;

  if (communityID === undefined) {
    url = `/tecnic/inscritos/${row.id}/inscritos`;
  } else {
    url = `/tecnic/communities/${communityID}/${row.id}/inscritos`;
  }

  return (
    <tr className={styles.mainrow}>
      <td>
        {row.nombre} {row.apellidos}
      </td>
      <td>{`${row.direccionCompleta.slice(0, 20)}...`}</td>
      <td>{row.telefono}</td>
      <td>{row.nombreComunidad}</td>
      <td style={{ padding: '2rem' }}>
        <label className={styles.checkboxTable}>
          <input type="checkbox" defaultChecked={row.OTC} disabled />
        </label>
      </td>

      <td>{row.cups}</td>
      <td>{row.fechaInscripcion.split(' ')[0]}</td>
      <td>
        {row.validado === 'si' ? (
          <Tag color={ColorTags.Colors}>{t(`Validat`)}</Tag>
        ) : (
          <Tag color={ColorTags.pendiente}>{t(`ComunitiesEnrolledListPage.PendientaValidar`)}</Tag>
        )}
      </td>
      <td>
        <RoundedButton
          onClick={() => {
            navigate(url);
          }}
        >
          {t(`ComunitiesEnrolledListPage.detalles`)}
        </RoundedButton>
      </td>
    </tr>
  );
};
