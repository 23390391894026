import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Constants from 'config/constants';
import { Roles } from 'types/enums';

const useRole = () => {
  const [path, setPath] = useState<Roles>(Roles.DEFAULT);
  const router = useLocation();

  const extractRole = (pathname: string): Roles => {
    if (pathname.startsWith(Constants.roles.user.rule)) {
      return Roles.USER;
    }
    if (pathname.startsWith(Constants.roles.cups.rule)) {
      return Roles.CUPS;
    }
    if (pathname.startsWith(Constants.roles.promotor.rule)) {
      return Roles.PROMOTOR;
    }
    if (pathname.startsWith(Constants.roles.installation.rule)) {
      return Roles.INSTALLATION;
    }
    if (pathname.startsWith(Constants.roles.client.rule)) {
      return Roles.CLIENT;
    }
    if (pathname.startsWith(Constants.roles.community.rule)) {
      return Roles.COMMUNITY;
    }
    if (pathname.startsWith(Constants.roles.communityEnrolled.rule)) {
      return Roles.ROLE_COMMUNITYENROLLED;
    }
    if (pathname.startsWith(Constants.roles.matcher.rule)) {
      return Roles.MATCHER;
    }
    if (pathname.startsWith(Constants.roles.rolePermision.rule)) {
      return Roles.ROLE_PERMISIONS;
    }
    return Roles.DEFAULT;
  };

  useEffect(() => {
    const role = extractRole(router.pathname);

    setPath(role);
  }, [router]);

  return path;
};

export default useRole;
