import React, { ChangeEvent } from 'react';

import cx from 'classnames';
import styles from './NumberInput.module.scss';

export interface InputInterface {
  path: string;
  onChange?: CallableFunction;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  type: string;
  id: string;
  placeholder?: string;
  required?: boolean;
  name: string;
  readOnly?: boolean;
  min?: number;
  max?: number;
  step?: number;
}

const NumberInputBetas = (props: InputInterface): JSX.Element => {
  const { value, id, required, placeholder, name, readOnly, className = '', min, max, step } = props;
  const valueDecimal = 0.001;
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { path, onChange } = props;
    const inputValue = Number(e.target.value);

    let newValue;
    if (inputValue < 0) {
      newValue = 0;
    } else if (max && inputValue > max) {
      newValue = max;
    } else {
      newValue = e.target.value;
    }

    if (onChange) {
      onChange(path, newValue, id);
    }
  };
  const handleIncrement = (): void => {
    const { path, onChange } = props;
    const numericValue = parseFloat(value) || 0;

    const incrementedValue = (numericValue + valueDecimal).toFixed(3);

    const newValue = Math.min(max || 1, parseFloat(incrementedValue));

    if (onChange) {
      onChange(path, newValue, id);
    }
  };

  const handleDecrement = (): void => {
    const { path, onChange } = props;
    const numericValue = parseFloat(value) || 0;
    const decrementedValue = (numericValue - valueDecimal).toFixed(3);
    const newValue = Math.max(min || 0, parseFloat(decrementedValue));

    if (onChange) {
      onChange(path, newValue, id);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'ArrowUp') {
      e.preventDefault(); // Evita el comportamiento predeterminado de la flecha hacia arriba
      handleIncrement();
    } else if (e.key === 'ArrowDown') {
      e.preventDefault(); // Evita el comportamiento predeterminado de la flecha hacia abajo
      handleDecrement();
    }
  };

  const formatPlaceholder = (): string => {
    if (typeof placeholder === 'string') {
      return placeholder.charAt(0).toUpperCase() + placeholder.slice(1).toLowerCase();
    }
    return String(placeholder);
  };

  return (
    <input
      id={id}
      type="number"
      className={cx(styles.root, className)}
      value={value}
      onChange={handleChange}
      autoComplete="off"
      onKeyDown={handleKeyDown}
      placeholder={formatPlaceholder()}
      required={required}
      name={name}
      readOnly={readOnly}
      min={min}
      max={max}
      step={step}
    />
  );
};

export default NumberInputBetas;
