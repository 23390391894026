export type SupportedLocales = 'es' | 'ca';

interface Translatable<T> {
  translate(locale: string): T | undefined;
}

export type TranslatableJSON<T> = Record<SupportedLocales, T>;

export class TranslatableElement<T> implements Translatable<T> {
  constructor(public translations?: TranslatableJSON<T>) {}

  translate(locale: SupportedLocales): T | undefined {
    if (this.translations) {
      return this.translations[locale] || undefined;
    }
    return undefined;
  }
}
