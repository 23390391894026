import React from 'react';
import { ReactComponent as SolarPanelIcon } from 'assets/icons/SolarPanel.svg';
import { Colors } from 'styles';
import styles from './Display.module.scss';

interface InstallationDisplayPropsI {
  name: string;
  installation: string;
  address: string;
  city: string;
  cp?: string;
}

const InstallationDisplay = (props: InstallationDisplayPropsI): JSX.Element => {
  const { name, installation, address, city, cp } = props;
  return (
    <div className={styles.root}>
      <div className={styles.icon}>
        <div className={styles.logo}>
          <SolarPanelIcon height={25} width={25} fill={Colors.COLOR_GENERACIO} />
        </div>
      </div>
      <div className={styles.data}>
        <p className={styles.title}>{name}</p>
        <p className={styles.subtitle}>{installation}</p>
        <p className={styles.quote}>{`${address} · ${city} ${cp ? '·' : ''} ${cp || ''}`}</p>
      </div>
    </div>
  );
};

export default React.memo(InstallationDisplay);
