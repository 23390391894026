/* eslint-disable no-return-assign */
/* eslint-disable react/button-has-type */
import { RoundedButton } from 'components/NewInputs';
import I18n from 'i18n-js';
import React, { useEffect, useRef, useState } from 'react';
import Button from 'components/Button/Button';
import { navigate } from 'navigation';
import ModalMatcher from 'components/ModalMatcher/ModalMatcher';
import { useToggle } from 'hooks/useToogle';
import { Form, Formik } from 'formik';
import { FormGroup, FormGroupProps } from 'core';
import { SectionSubTitle } from 'components';
import MatcherConfiguracionInstance, {
  DuplicarConfiguracionMatcher,
} from 'services/remote/MatcherConfiguracionService';
import ConfiguracionMatcher from 'types/models/ConfiguracionMatcher';
import styles from './matcher-list-row.module.scss';
import stylesDropdown from './matcher-dropdown.module.scss';

interface MatcherListRowProps {
  configuracion: ConfiguracionMatcher;
  deleteOnChange: CallableFunction;
}

export const MatcherListRow = (props: MatcherListRowProps): JSX.Element => {
  const { configuracion, deleteOnChange } = props;

  const { t } = I18n;
  const [isSwitchOn, toggleSwitch] = useToggle();
  const [isSwitchOnDuplicar, toggleSwitchDuplicar] = useToggle();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean[]>([]); // Arreglo para controlar la apertura/cierre de los dropdowns

  const dropdownRefs = useRef<(HTMLElement | null)[]>([]); // Arreglo de referencias para los dropdowns

  const handleDocumentClick = (event: MouseEvent) => {
    dropdownRefs.current.forEach((ref, index) => {
      if (ref && !ref.contains(event.target as Node)) {
        setIsOpen((prevIsOpen) => {
          const newIsOpen = [...prevIsOpen];
          newIsOpen[index] = false;
          return newIsOpen;
        });
      }
    });
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, true);
    return () => {
      document.removeEventListener('click', handleDocumentClick, true);
    };
  }, []);

  const toggleDropdown = (index: number): void => {
    const newIsOpen = [...isOpen];
    newIsOpen[index] = !newIsOpen[index];
    setIsOpen(newIsOpen);
  };
  const [input, setInputs] = useState({
    nombre: '',
  });
  const formGroupUserDataLeft: Array<FormGroupProps> = [
    {
      label: I18n.t('Name'),
      type: 'text',
      id: 'nombre',
      path: 'nombre',
      name: 'nombre',
      value: input.nombre,
      placeholder: I18n.t('Name'),
      tabindex: 1,
    },
  ];

  const submitForm = async (): Promise<void> => {
    // cosas
  };
  function handleChange(path: string, value: string): void {
    setInputs((prev) => ({
      ...prev,
      [path]: value,
    }));
  }
  const deleteConfiguracion = async (idConfiguracion: number): Promise<void> => {
    // eslint-disable-next-line no-alert
    setLoading(true);
    try {
      await MatcherConfiguracionInstance.deleteConfiguracionMatcher(idConfiguracion);
    } catch (error) {
      toggleSwitch();
    }
    setLoading(false);

    deleteOnChange('DELETE');
    toggleSwitch();
  };

  const duplicarConfiguracion = async (
    idConfiguracion: number,
    nombre: DuplicarConfiguracionMatcher,
  ): Promise<void> => {
    setLoading(true);
    try {
      await MatcherConfiguracionInstance.duplicarConfiguracionMatcher(idConfiguracion, nombre);
    } catch (error) {
      toggleSwitchDuplicar();
    }
    setLoading(false);
    deleteOnChange('duplicar');

    toggleSwitchDuplicar();
  };

  const lastTableRowRef = useRef<HTMLTableRowElement | null>(null);

  return (
    <>
      <tr className={styles.mainrow} ref={lastTableRowRef}>
        <td>{configuracion.nombre}</td>
        <td>{configuracion.nombreComunidad}</td>
        <td>{configuracion.fechaCreacion}</td>
        <td>{configuracion.numeroMiembros}</td>
        <td>
          <label className={styles.checkboxTable}>
            <input type="checkbox" defaultChecked={configuracion.infoDeGeneracion.generacionAnual !== 0} disabled />
          </label>
        </td>

        <td>
          <div className={stylesDropdown.dropdown} ref={(ref) => (dropdownRefs.current[configuracion.id] = ref)}>
            <RoundedButton
              onClick={() => {
                toggleDropdown(configuracion.id);
              }}
            >
              {t(`Actions`)}
            </RoundedButton>

            {isOpen[configuracion.id] && (
              <div className={stylesDropdown.dropdownMenu}>
                <Button
                  className={styles.viewmore_button}
                  variant="tertiary"
                  type="button"
                  onClick={() => {
                    navigate(`/tecnic/matcher/${configuracion.id}/edit`);
                  }}
                  text={t(`PerfilListPage.actions.ver`)}
                />
                <Button
                  className={styles.viewmore_button}
                  variant="tertiary"
                  type="button"
                  onClick={() => {
                    toggleDropdown(configuracion.id);

                    toggleSwitchDuplicar();
                  }}
                  text="duplicar"
                />
                <Button
                  className={styles.viewmore_button}
                  variant="tertiary"
                  type="button"
                  // eslint-disable-next-line consistent-return
                  onClick={() => {
                    toggleDropdown(configuracion.id);
                    toggleSwitch();
                  }}
                  text={t(`PerfilListPage.actions.delete`)}
                />
              </div>
            )}
          </div>
        </td>
      </tr>
      {isSwitchOn && (
        <ModalMatcher
          title={`${t('MatcherListPage.mensajeModalConf')} ${
            configuracion.nombre === null ? '' : configuracion.nombre
          }`}
          body={`${t('MatcherListPage.mensajeModalConf')} ${configuracion.nombre === null ? '' : configuracion.nombre}`}
          onClick={() => deleteConfiguracion(configuracion.id)}
          testbutton={t('Confirmar')}
          loading={loading}
          onClick2={toggleSwitch}
        />
      )}
      {isSwitchOnDuplicar && (
        <ModalMatcher
          title={`${t('MatcherListPage.mensajeModalDuplicar')} "${
            configuracion.nombre === null ? '' : configuracion.nombre
          }" ?`}
          body={
            <>
              <SectionSubTitle
                text={`${t('MatcherListPage.mensajeModalDuplicarAdd')} ${
                  configuracion.nombre === null ? '' : configuracion.nombre
                }`}
              />

              <Formik
                enableReinitialize
                initialValues={{
                  nombre: '',
                }}
                onSubmit={submitForm}
              >
                {() => (
                  <Form className={styles.form}>
                    <div className={styles.margenesInputModal}>
                      {formGroupUserDataLeft.map((formGroup: FormGroupProps) => (
                        <div style={{ width: '100%' }}>
                          <FormGroup
                            key={formGroup.id}
                            label={formGroup.label}
                            type={formGroup.type}
                            id={formGroup.id}
                            path={formGroup.path}
                            name={formGroup.name}
                            placeholder={formGroup.placeholder}
                            value={formGroup.value}
                            onChange={handleChange}
                            options={formGroup.options}
                            disabled={formGroup.disabled}
                            tabindex={formGroup.tabindex}
                            error={formGroup.error}
                          />
                        </div>
                      ))}
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          }
          testbutton={I18n.t('Confirmar')}
          // eslint-disable-next-line no-alert
          loading={loading}
          onClick={() => duplicarConfiguracion(configuracion.id, input)}
          onClick2={toggleSwitchDuplicar}
        />
      )}
    </>
  );
};
