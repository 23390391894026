class MonthConverterService {
  convertDateToShortMonth(date: Date): string {
    const userLang = navigator.language;
    const monthShort = date.toLocaleString(userLang, { month: 'short' }).slice(0, -1).toUpperCase();

    return monthShort;
  }
}

const monthConverterService: MonthConverterService = new MonthConverterService();

export default monthConverterService;
