import { ProdEnergyProductionDataYearlyAPII } from '../types/models/widgetsData/prodEnergyProductionDataYearly';

const PROD_ENERGY_PRODUCTION_Y_DATA_MOCK: ProdEnergyProductionDataYearlyAPII = {
  meses: [
    {
      mes: 1,
      generacion: 4870000,
      garantia: 4495400,
      diferenciaGarantia: 8.33,
      estimacion: 4778300,
      diferenciaEstimacion: 1.92,
      diferenciaGeneracion: -0.5,
    },
    {
      mes: 2,
      generacion: 3870000,
      garantia: 3495400,
      diferenciaGarantia: 7.33,
      estimacion: 3778300,
      diferenciaEstimacion: 1.52,
      diferenciaGeneracion: -0.3,
    },
    {
      mes: 3,
      generacion: 4870000,
      garantia: 4495400,
      diferenciaGarantia: 8.33,
      estimacion: 4778300,
      diferenciaEstimacion: 1.92,
      diferenciaGeneracion: -0.5,
    },
    {
      mes: 4,
      generacion: 4870000,
      garantia: 4495400,
      diferenciaGarantia: 8.33,
      estimacion: 4778300,
      diferenciaEstimacion: 1.92,
      diferenciaGeneracion: -0.5,
    },
    {
      mes: 5,
      generacion: 4870000,
      garantia: 4495400,
      diferenciaGarantia: 8.33,
      estimacion: 4778300,
      diferenciaEstimacion: 1.92,
      diferenciaGeneracion: -0.5,
    },
    {
      mes: 6,
      generacion: 4870000,
      garantia: 4495400,
      diferenciaGarantia: 8.33,
      estimacion: 4778300,
      diferenciaEstimacion: 1.92,
      diferenciaGeneracion: -0.5,
    },
    {
      mes: 7,
      generacion: 4870000,
      garantia: 4495400,
      diferenciaGarantia: 8.33,
      estimacion: 4778300,
      diferenciaEstimacion: 1.92,
      diferenciaGeneracion: -0.5,
    },
    {
      mes: 8,
      generacion: 4870000,
      garantia: 4495400,
      diferenciaGarantia: 8.33,
      estimacion: 4778300,
      diferenciaEstimacion: 1.92,
      diferenciaGeneracion: -0.5,
    },
    {
      mes: 9,
      generacion: 4870000,
      garantia: 4495400,
      diferenciaGarantia: 8.33,
      estimacion: 4778300,
      diferenciaEstimacion: 1.92,
      diferenciaGeneracion: -0.5,
    },
    {
      mes: 10,
      generacion: 4870000,
      garantia: 4495400,
      diferenciaGarantia: 8.33,
      estimacion: 4778300,
      diferenciaEstimacion: 1.92,
      diferenciaGeneracion: -0.5,
    },
    {
      mes: 11,
      generacion: 4870000,
      garantia: 4495400,
      diferenciaGarantia: 8.33,
      estimacion: 4778300,
      diferenciaEstimacion: 1.92,
      diferenciaGeneracion: -0.5,
    },
    {
      mes: 12,
      generacion: 4870000,
      garantia: 4495400,
      diferenciaGarantia: 8.33,
      estimacion: 4778300,
      diferenciaEstimacion: 1.92,
      diferenciaGeneracion: -0.5,
    },
  ],
};

export default PROD_ENERGY_PRODUCTION_Y_DATA_MOCK;
