import React from 'react';
import { ReactComponent as CommunityIcon } from 'assets/icons/community_share.svg';
import { Colors } from 'styles';
import { navigate } from 'navigation';
import styles from './SearchItems.module.scss';

interface CommunitySearchPropsI {
  id: number;
  nombre: string;
  domicilio: string;
}

const CommunitySearch = (props: CommunitySearchPropsI): JSX.Element => {
  const { id, nombre, domicilio } = props;

  const handleClick = (): void => {
    navigate(`/tecnic/communities/${id}/detail`);
  };

  return (
    <button onClick={() => handleClick()} type="button" className={styles.root}>
      <div className={styles.icon_wrapper}>
        <CommunityIcon height={25} width={25} fill={Colors.COLOR_NOTIFICATIONS} />
      </div>
      <div className={styles.info_wrapper}>
        <p className={styles.title}>{nombre}</p>
        <p className={styles.subtitle}>{domicilio}</p>
      </div>
    </button>
  );
};

export default React.memo(CommunitySearch);
