import { Serializable } from 'types/interfaces';

interface ChartDataApiI {
  t: string; // Ahora es una cadena, no un número
  c: number; // Consumo en lugar de 'a', 'e', etc.
}

export interface ChartDataI {
  x: string; // Manteniendo como string para las fechas
  y: number; // Valor de consumo
}

interface TeledectionApiI {
  consumo: Array<ChartDataApiI>;
  acumulados: {
    consumo: number;
  };
}

export default class GraficaCurvaConsumoTeledectionData implements Serializable<GraficaCurvaConsumoTeledectionData> {
  consumptionData: Array<ChartDataI>; // Renombramos esta propiedad para reflejar el tipo de datos

  totals: {
    consumption: number;
  };

  deserialize(input: TeledectionApiI): GraficaCurvaConsumoTeledectionData {
    if (!input) {
      return this;
    }

    // Mapeamos el array 'consumo' a los datos que necesitamos
    this.consumptionData = input.consumo.map(
      (data): ChartDataI => ({
        x: data.t, // La fecha es una cadena
        y: data.c, // El valor del consumo
      }),
    );

    // Asignamos el total de consumo desde 'acumulados'
    this.totals = {
      consumption: input.acumulados.consumo || 0,
    };

    return this;
  }
}
